// These hooks are used by components which want to run asynchronous tasks
// with side effects, and also render the state of those tasks. For example,
// making a request to the back-end, and showing a loading spinner while
// the request is in-flight.

import {DependencyList, useCallback, useRef, useState} from "react";

// The current state of the task. This includes whether the task is currently
// running, the arguments that were passed in, the last error, etc. The
// calling component will be automatically re-rendered when these change.
export type PromiseState<R, P> =
  | {
      readonly inProgress: true;
      readonly lastArgs?: P;
      readonly lastError?: never;
      readonly lastResult?: never;
    }
  | {
      readonly inProgress: false;
      readonly lastArgs?: P;
      readonly lastError?: unknown;
      readonly lastResult?: R;
    };

export type PromiseStateReturnType<R> =
  | {
      ok: true;
      result: R;
    }
  | {
      ok: false;
      error: unknown;
    };
export type PromiseStateResult<R, P extends unknown[]> = [
  PromiseState<R, P>,
  (...args: P) => Promise<PromiseStateReturnType<R>>,
  () => void,
];

export class OperationInProgressError extends Error {
  constructor() {
    super("Operation already in progress");
  }
}

// Accepts an asynchronous callback and a standard react dependency list,
// and returns a wrapper which will automatically inform the calling
// component about the state of the callback.
export function usePromiseState<R, P extends unknown[]>(
  cb: (...args: P) => Promise<R> | R,
  inputs: DependencyList,
): PromiseStateResult<R, P> {
  // Initialize the promise state to "not running":
  const [state, setState] = useState<PromiseState<R, P>>({
    inProgress: false,
  });

  // The above state is only updated asynchronously, so we
  // can't use it to prevent the callback being called twice
  // in quick succession. Instead, define a ref for that.
  const inProgress = useRef(false);

  // Define our function which wraps the callback. We will
  // return this to the caller to use instead of the original.
  const fn = useCallback(async (...args: P) => {
    // We only allow one instance of the callback to be
    // running at a time, otherwise concepts like "last error"
    // get tricky to define...
    if (inProgress.current) {
      throw new OperationInProgressError();
    }

    // Update the ref and state to show that we are
    // entering the callback. This also clears the
    // "lastError" and "lastResult" fields.
    inProgress.current = true;
    setState({inProgress: true, lastArgs: args});

    try {
      // Now we can run the callback
      const result: R = await cb(...args);

      // The callback ran successfully, so just save
      // the result.
      setState(oldState => ({
        ...oldState,
        lastResult: result,
        inProgress: false,
      }));

      // And return it
      return {ok: true, result} as const;
    } catch (error) {
      // The callback threw an exception, so store the
      // error and re-raise it.
      setState(oldState => ({
        ...oldState,
        lastError: error,
        inProgress: false,
      }));
      return {ok: false, error} as const;
    } finally {
      // Regardless of whether the callback succeeded or
      // failed, clear the in-progress indicator.
      inProgress.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, inputs);

  // Sometimes the caller might want to clear the result of
  // previous calls. For example, when a form is reset.
  // This has no effect if the callback is currently running.
  const clearResult = useCallback(() => {
    setState(oldState => (oldState.inProgress ? oldState : {inProgress: false}));
  }, []);

  return [state, fn, clearResult];
}
