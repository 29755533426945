import {HStack} from "@chakra-ui/react";
import ProgressPie from "./ProgressPie";
import {Internal} from "../../../../../components/InternalMode";
import AiPie from "./AiPie";
import AiReviews from "./AiReviews";
import {useOutletContext} from "react-router-dom";
import {TabOutletContext} from "..";
import {LayerType} from "../../../../../Types";

export default function ProgressPage() {
  const {questionnaire} = useOutletContext<TabOutletContext>();
  return (
    <>
      <HStack align="flex-start" gap={8} p={8}>
        <ProgressPie questionnaire={questionnaire} />
        <Internal>
          <AiPie questionnaire={questionnaire} baseLayer={LayerType.Internal} />
          <AiPie questionnaire={questionnaire} baseLayer={LayerType.AI} />
        </Internal>
      </HStack>
      <Internal>
        <AiReviews questionnaire={questionnaire} />
      </Internal>
    </>
  );
}
