import {useState} from "react";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Link,
  Stack,
  Text,
} from "@chakra-ui/react";

import {usePromiseState} from "../hooks/promiseState";
import {Form, Link as RouterLink} from "react-router-dom";
import AuthError from "./AuthError";
import api, {HTTPError} from "../api";
import {getRedirectParams} from "../utils/auth";

const ResetRequest = () => {
  const [email, setEmail] = useState("");

  const [requestingReset, requestReset] = usePromiseState(async () => {
    try {
      const redirect = getRedirectParams().get("redirect") ?? undefined;
      return await api.loginMethod.requestReset({email, redirect});
    } catch (ex) {
      if (ex instanceof HTTPError) {
        switch (ex.response.status) {
          case 429:
            throw new Error("Too many reset requests for this email. Please wait for an hour before retrying.");
        }
      }
      console.error(ex);
      throw new Error("Error requesting login reset");
    }
  }, [email]);

  return (
    <Form
      onSubmit={e => {
        e.stopPropagation();
        e.preventDefault();
        requestReset();
      }}
    >
      <Heading fontSize="32" fontWeight="600">
        Request login reset
      </Heading>
      <Text fontSize="md" my="4">
        This will allow you to set a new password or select a different login method.
      </Text>

      <AuthError error={requestingReset.lastError?.toString()} mt={12} />
      {requestingReset.lastResult ? (
        <Alert status="info" borderRadius="md" mt={12}>
          <AlertIcon boxSize="40px" />
          <Box>
            <AlertTitle fontSize="md">Login reset link requested</AlertTitle>
            <AlertDescription fontSize="md">
              <Text>Your login reset request has been received.</Text>
              <Text>
                If an account with this email address exists in our system then an email will be sent with further
                instructions.
              </Text>
            </AlertDescription>
          </Box>
        </Alert>
      ) : null}

      <Stack spacing={8} mt="8">
        <FormControl>
          <FormLabel>Email</FormLabel>
          <Input type="email" placeholder="name@domain.com" value={email} onChange={e => setEmail(e.target.value)} />
        </FormControl>

        <Flex alignItems="center">
          <Box>
            <Link as={RouterLink} fontSize="md" color="blue.500" to={`/login?${getRedirectParams()}`}>
              Back to login
            </Link>
          </Box>
        </Flex>
      </Stack>

      <Button mt="12" colorScheme="blue" w="full" type="submit" isLoading={requestingReset.inProgress}>
        Request reset link
      </Button>
    </Form>
  );
};

export default ResetRequest;
