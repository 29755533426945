import {ArrowDownTrayIcon} from "@heroicons/react/20/solid";
import {RouteTab} from "../../../../../components/RouterTabs";
import ExportPage from "./ExportPage";

export default {
  title: "Export",
  path: "export",
  icon: ArrowDownTrayIcon,
  element: <ExportPage />,
} satisfies RouteTab;
