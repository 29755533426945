import {Icon, Menu, MenuButton} from "@chakra-ui/react";
import StyledNavLink from "../../Header/components/StyledNavLink";
import {useIsAdmin} from "../../hooks/roles";
import PortalMenuList from "../../components/PortalMenuList";
import StyledMenuItem from "../../Header/components/StyledMenuItem";
import {ChevronDownIcon, InformationCircleIcon} from "@heroicons/react/24/solid";

const Configuration = () => (
  <Menu placement="bottom-start">
    <MenuButton>
      <StyledNavLink to="configuration">
        Configuration <Icon as={ChevronDownIcon} ml="1" mt="1px" />
      </StyledNavLink>
    </MenuButton>
    <PortalMenuList py="0" overflow="hidden">
      <StyledMenuItem to="../account-settings" iconComp={InformationCircleIcon} help="Account-wide settings">
        Account settings
      </StyledMenuItem>
    </PortalMenuList>
  </Menu>
);

export default function NavLinks() {
  const isAdmin = useIsAdmin();
  return (
    <>
      <StyledNavLink to=".">Dashboard</StyledNavLink>
      {isAdmin && <Configuration />}
    </>
  );
}
