import {Stack, HStack, Icon, Tooltip, chakra, Text, IconButton} from "@chakra-ui/react";
import {ChatBubbleLeftRightIcon, TrashIcon, XMarkIcon} from "@heroicons/react/20/solid";
import api from "../../../../../../../api";
import {Question, CoreResponse} from "../../../../../../../Types";
import {useLayerType} from "../../../../../../../hooks/layerType";
import {usePromiseState} from "../../../../../../../hooks/promiseState";
import {ScopeBadges} from "../../../../../components/ScopeBadge";

/**
 * Notifies the user that the question is using a core response and provides an
 * option to unlink it.
 */
function UsingCoreResponse({question, coreResponse}: {question: Question; coreResponse: CoreResponse}) {
  const [layerType] = useLayerType();

  const [unlinking, unlink] = usePromiseState(async () => {
    await api.vendorToolkit.questions.updateLinkedCoreResponse(question.question_id, layerType, null);
  }, [layerType, question.question_id]);

  return (
    <Stack bg="blue.50" rounded="md" border="1px solid" borderColor="blue.200" fontSize="md" spacing="0">
      <HStack p="4" px="6" spacing="6" borderBottom="1px solid" borderColor="gray.300">
        <Icon as={ChatBubbleLeftRightIcon} color="blue.500" fontSize="lg" />
        <Text fontSize="md" flexGrow="1">
          This question has been answered with a core response. To edit the response text, remove the core response.
        </Text>
      </HStack>
      <HStack p="4" px="6" spacing="6" alignItems="flex-start">
        {coreResponse.deleted != null && (
          <Tooltip label="This core response has been deleted.">
            <Icon as={TrashIcon} color="gray.500" fontSize="lg" />
          </Tooltip>
        )}
        <Stack flexGrow="1">
          <Text fontWeight="500">
            <chakra.span {...(coreResponse.deleted != null ? {textDecoration: "line-through", color: "gray.500"} : {})}>
              {coreResponse.question_text}
            </chakra.span>
          </Text>
          <ScopeBadges scopes={coreResponse.limited_to_scopes} showGlobal={false} />
        </Stack>
        <Tooltip label="Remove core response">
          <IconButton
            flex="0 0 auto"
            aria-label="Remove core response"
            icon={<Icon fontSize="lg" as={XMarkIcon} />}
            colorScheme="blue"
            size="sm"
            onClick={unlink}
            isDisabled={unlinking.inProgress}
            isLoading={unlinking.inProgress}
          />
        </Tooltip>
      </HStack>
    </Stack>
  );
}

export default UsingCoreResponse;
