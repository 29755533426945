import {Avatar, HStack, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr} from "@chakra-ui/react";
import {memo, useMemo} from "react";
import {ActionBar} from "../../../../../components/ActionBar";
import AddPersonButton from "./AddPersonButton";
import {DeletePersonButton} from "./DeletePersonButton";
import {EditPersonButton} from "./EditPersonButton";
import {AssetId, EntityId, GetGraphQuery, SubGraph} from "../../../../../Types";
import {
  formatOrganizationRelationship,
  getPeopleFromEntityGraph,
  ORGANIZATION_RELATIONSHIPS,
  Person,
  PERSON_PROPERTIES,
} from "../../../graph/types/person";
import {getFirstRootEntity} from "../../../graph/util";
import {useQueryData} from "../../../../../state";
import {withSuspense} from "../../../../../state/withSuspense";
import {addAccountId} from "../../../../../api/jsonApi";

/**
 * Renders a user's avatar by querying the file ID from the asset ID. Having a
 * photo asset is optional, so the `useQueryData` hook needs to be called
 * conditionally, which is why this is a separate component.
 */
const AssetAvatar = withSuspense(
  ({assetId}: {assetId: AssetId}) => {
    const photoAsset = useQueryData({queryKey: ["vendorToolkit", "asset", assetId]});
    const photoDownloadUrl = addAccountId(`/api/files/${photoAsset.file.file_id}/download`);

    return <Avatar h="10" w="10" flexShrink="0" src={photoDownloadUrl} />;
  },
  <Avatar h="10" w="10" flexShrink="0" />,
);

const PersonRow = memo(
  ({graph, person, parentEntityId}: {graph: SubGraph; person: Person; parentEntityId: EntityId}) => {
    return (
      <Tr>
        <Td>
          <HStack align="center" fontSize="md" spacing={6}>
            {person.photoAssetId !== undefined ? (
              <AssetAvatar assetId={person.photoAssetId} />
            ) : (
              <Avatar h="10" w="10" name={person.name} flexShrink="0" />
            )}
            <Text fontSize="md" fontWeight="600">
              {person.name}
            </Text>
          </HStack>
        </Td>
        <Td>
          <Text fontSize="md">{person.jobTitle}</Text>
        </Td>
        <Td>
          <Text fontSize="md">{person.organizationRelationships.map(formatOrganizationRelationship).join(", ")}</Text>
        </Td>
        <Td>
          <HStack spacing="8px" justifyContent="flex-end">
            <EditPersonButton graph={graph} person={person} parentEntityId={parentEntityId} />
            <DeletePersonButton person={person} />
          </HStack>
        </Td>
      </Tr>
    );
  },
);

export function PopulatedPeoplePage({graph, rootLegalEntityId}: {graph: SubGraph; rootLegalEntityId: EntityId}) {
  const people = useMemo(() => getPeopleFromEntityGraph(graph, rootLegalEntityId), [graph, rootLegalEntityId]);

  return (
    <>
      <ActionBar>
        <AddPersonButton graph={graph} parentEntityId={rootLegalEntityId} />
      </ActionBar>
      <TableContainer maxWidth="100%" whiteSpace="normal" flex="1">
        <Table style={{borderCollapse: "separate", borderSpacing: "0"}} size="lg" layout="fixed">
          <Thead bg="white" borderBottom="10px solid" borderBottomColor="red.100">
            <Tr color="red">
              <Th color="gray.500">Name</Th>
              <Th color="gray.500">Job title</Th>
              <Th color="gray.500">Legal roles</Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {people.map(p => (
              <PersonRow key={p.id} graph={graph} person={p} parentEntityId={rootLegalEntityId} />
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </>
  );
}

const SELECT_PROPERTIES = ["legal_entity_type", ...PERSON_PROPERTIES, ...ORGANIZATION_RELATIONSHIPS];
const FILTER_PROPERTIES = ["is_root_legal_entity"];
const GRAPH_QUERY = {
  filter_properties: FILTER_PROPERTIES,
  selected_properties: SELECT_PROPERTIES,
} satisfies GetGraphQuery;

const PeoplePage = withSuspense(() => {
  const graph = useQueryData({queryKey: ["vendorToolkit", "graph", GRAPH_QUERY]});
  const rootEntity = getFirstRootEntity(graph)!;

  return <PopulatedPeoplePage graph={graph} rootLegalEntityId={rootEntity.id} />;
});

export default PeoplePage;
