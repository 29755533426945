import {UpdateQuestionSource} from "../../../../../Types.ts";
import {SlackQuestionSource} from "./SlackQuestionSource.tsx";

export type QuestionSourceConnectionProps = {
  connectorId: string;
  payload: UpdateQuestionSource | null;
  setPayload: (payload: UpdateQuestionSource | null) => void;
};

export const AnyQuestionSource = (props: QuestionSourceConnectionProps) => {
  switch (props.connectorId) {
    case "slack":
      return <SlackQuestionSource {...props} />;
    default:
      return null;
  }
};
