import {QuestionStatus} from "../../../../Types";
import {BoltIcon, ChatBubbleLeftEllipsisIcon, CheckIcon, ClipboardDocumentCheckIcon} from "@heroicons/react/20/solid";

type QuestionStatusInfo = {
  longText: string;
  shortText: string;
  icon: typeof CheckIcon;
  color: string;
  disableSetting?: boolean;
};

export const QUESTION_STATUS_MAP: {[status: string]: QuestionStatusInfo} = {
  [QuestionStatus.Complete]: {
    longText: "Complete",
    shortText: "Complete",
    icon: CheckIcon,
    color: "green",
  },
  [QuestionStatus.Review]: {
    longText: "Requires review",
    shortText: "Review",
    icon: ClipboardDocumentCheckIcon,
    color: "orange",
  },
  [QuestionStatus.Respond]: {
    longText: "Requires response",
    shortText: "Respond",
    icon: ChatBubbleLeftEllipsisIcon,
    color: "red",
  },
  [QuestionStatus.Automating]: {
    longText: "Automating",
    shortText: "Automating",
    icon: BoltIcon,
    color: "gray",
    disableSetting: true,
  },
};
