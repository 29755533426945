import {HStack, Img, Link, Text} from "@chakra-ui/react";
import ReviewItemsScreenshot from "../../../assets/new_features/review_items.png";
import {AccountFeature} from "../../Types";
import {Feature} from ".";

export default {
  heading: "Automatically detect conflicts in Graph",
  text: (
    <>
      <Text>
        Platformed can now automatically detect conflicts in your graph and flag them for review. Ask us for this
        feature and find it in your fact library.
      </Text>
      <Link
        href="https://platformed.com/blog/platformed-flags-potential-conflicts-in-your-fact-library-for-review"
        target="_blank"
        color="blue.500"
        fontWeight="semibold"
      >
        Find out more in our blog post.
      </Link>
    </>
  ),
  image: (
    <HStack
      bg="orange.200"
      bgGradient="linear(165deg, cyan.200, green.200, green.300)"
      alignItems="center"
      justifyContent="center"
      p={4}
    >
      <Img src={ReviewItemsScreenshot} rounded="md" />
    </HStack>
  ),
  publishDate: new Date(Date.parse("30 Jul 2024")),
  actionText: "Go to review items",
  actionRequiredAccountFeatures: [AccountFeature.ReviewItems],
  url: "/vendor-toolkit/library/facts/review-items",
} satisfies Feature;
