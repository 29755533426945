import {withSuspense} from "../../../../state/withSuspense.tsx";
import {useQueryData} from "../../../../state/index.ts";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  FormLabel,
  Heading,
  HStack,
  Icon,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Switch,
  useDisclosure,
} from "@chakra-ui/react";
import Page from "../../../../components/Page.tsx";
import FormSection, {FormQuestion} from "./FormSection.tsx";
import CommitInput from "../../../../components/CommitInput.tsx";
import ContentSelector from "./ContentSelector.tsx";
import {useCallback} from "react";
import api from "../../../../api/index.ts";
import AssetSelector from "./AssetSelector.tsx";
import {ArrowRightIcon, ArrowTopRightOnSquareIcon} from "@heroicons/react/24/solid";
import {getFrontendEnvironment} from "../../../../utils/environment.ts";
import {
  AssetId,
  ContentId,
  DocumentId,
  EntityId,
  QuestionnaireId,
  TrustCenterAccess,
  TrustCenterAiDataUseItemPii,
  TrustCenterTrustedPartneredType,
  WhoAmI,
} from "../../../../Types.ts";
import {usePromiseState} from "../../../../hooks/promiseState.ts";
import {DeleteModalIconButton} from "../../../../components/DeleteModalButton.tsx";
import DocumentSelector from "../../components/DocumentSelector.tsx";
import QuestionnaireSelector from "../../components/QuestionnaireSelector.tsx";
import SubprocessorSelector from "../../components/SubprocessorSelector.tsx";
import CommitTextarea from "../../../../components/CommitTextarea.tsx";
import {Internal} from "../../../../components/InternalMode.tsx";

function getTrustCenterUrl(whoami: WhoAmI) {
  const {trust_url} = getFrontendEnvironment();
  return `${trust_url}/${whoami.account?.slug ?? ""}`;
}

const DeleteAiDataUseItemButton = ({idx}: {idx: number}) => {
  const config = useQueryData({queryKey: ["vendorToolkit", "trustCenterConfig"]});
  const {isOpen, onOpen, onClose} = useDisclosure();

  const [deletingAiDataUseItem, deleteAiDataUseItem] = usePromiseState(async () => {
    await api.vendorToolkit.trustCenter.updateConfigMeta({
      ...config.meta,
      ai: {
        ...config.meta.ai,
        data_use_items: config.meta.ai?.data_use_items?.toSpliced(idx, 1),
      },
    });
    onClose();
  }, [config.meta, idx, onClose]);

  return (
    <DeleteModalIconButton
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      delete_={deleteAiDataUseItem}
      deleting={deletingAiDataUseItem.inProgress}
      modalHeader="Delete entry"
      modalBody="Are you sure you want to delete this entry?"
    />
  );
};

const DeleteAiCheckItemButton = ({idx}: {idx: number}) => {
  const config = useQueryData({queryKey: ["vendorToolkit", "trustCenterConfig"]});
  const {isOpen, onOpen, onClose} = useDisclosure();

  const [deletingAiCheckItem, deleteAiCheckItem] = usePromiseState(async () => {
    await api.vendorToolkit.trustCenter.updateConfigMeta({
      ...config.meta,
      ai: {
        ...config.meta.ai,
        check_items: config.meta.ai?.check_items?.toSpliced(idx, 1),
      },
    });
    onClose();
  }, [config.meta, idx, onClose]);

  return (
    <DeleteModalIconButton
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      delete_={deleteAiCheckItem}
      deleting={deletingAiCheckItem.inProgress}
      modalHeader="Delete entry"
      modalBody="Are you sure you want to delete this entry?"
    />
  );
};

const DeleteAiModelProviderButton = ({idx}: {idx: number}) => {
  const config = useQueryData({queryKey: ["vendorToolkit", "trustCenterConfig"]});
  const {isOpen, onOpen, onClose} = useDisclosure();

  const [deletingAiModelProvider, deleteAiModelProvider] = usePromiseState(async () => {
    await api.vendorToolkit.trustCenter.updateConfigMeta({
      ...config.meta,
      ai: {
        ...config.meta.ai,
        model_providers: config.meta.ai?.model_providers?.toSpliced(idx, 1),
      },
    });
    onClose();
  }, [config.meta, idx, onClose]);

  return (
    <DeleteModalIconButton
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      delete_={deleteAiModelProvider}
      deleting={deletingAiModelProvider.inProgress}
      modalHeader="Delete model provider"
      modalBody="Are you sure you want to delete this model provider entry?"
    />
  );
};

const DeleteAiRelatedDocumentButton = ({idx}: {idx: number}) => {
  const config = useQueryData({queryKey: ["vendorToolkit", "trustCenterConfig"]});
  const {isOpen, onOpen, onClose} = useDisclosure();

  const [deletingAiRelatedDocument, deleteAiRelatedDocument] = usePromiseState(async () => {
    await api.vendorToolkit.trustCenter.updateConfigMeta({
      ...config.meta,
      ai: {
        ...config.meta.ai,
        related_documents: config.meta.ai?.related_documents?.toSpliced(idx, 1),
      },
    });
    onClose();
  }, [config.meta, idx, onClose]);

  return (
    <DeleteModalIconButton
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      delete_={deleteAiRelatedDocument}
      deleting={deletingAiRelatedDocument.inProgress}
      modalHeader="Delete document"
      modalBody="Are you sure you want to remove this document from the AI page?"
    />
  );
};

const DeleteTrustedByButton = ({idx}: {idx: number}) => {
  const config = useQueryData({queryKey: ["vendorToolkit", "trustCenterConfig"]});
  const {isOpen, onOpen, onClose} = useDisclosure();
  const [deletingTrusted, deleteTrustedBy] = usePromiseState(async () => {
    await api.vendorToolkit.trustCenter.updateConfigMeta({
      ...config.meta,
      overview: {
        ...config.meta.overview,
        trusted_by: config.meta.overview?.trusted_by?.toSpliced(idx, 1),
      },
    });
    onClose();
  }, [config.meta, idx, onClose]);

  return (
    <DeleteModalIconButton
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      delete_={deleteTrustedBy}
      deleting={deletingTrusted.inProgress}
      modalHeader="Delete logo"
      modalBody="Are you sure you want to delete this logo?"
    />
  );
};

const DeleteBusinessContentButton = ({idx}: {idx: number}) => {
  const config = useQueryData({queryKey: ["vendorToolkit", "trustCenterConfig"]});
  const {isOpen, onOpen, onClose} = useDisclosure();
  const [deletingBusinessContent, deleteBusinessContent] = usePromiseState(async () => {
    await api.vendorToolkit.trustCenter.updateConfigMeta({
      ...config.meta,
      business_info: config.meta.business_info?.toSpliced(idx, 1),
    });
    onClose();
  }, [config.meta, idx, onClose]);

  return (
    <DeleteModalIconButton
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      delete_={deleteBusinessContent}
      deleting={deletingBusinessContent.inProgress}
      modalHeader="Delete content"
      modalBody="Are you sure you want to delete this content?"
    />
  );
};

const TrustedBy = ({idx}: {idx: number}) => {
  const config = useQueryData({queryKey: ["vendorToolkit", "trustCenterConfig"]});
  const trustedBy = config.meta.overview?.trusted_by?.[idx];
  const updateLogoAssetId = useCallback(
    async (logo_asset_id: AssetId | undefined) => {
      await api.vendorToolkit.trustCenter.updateConfigMeta({
        ...config.meta,
        overview: {
          ...config.meta.overview,
          trusted_by: config.meta.overview?.trusted_by?.toSpliced(idx, 1, {...trustedBy, logo_asset_id}),
        },
      });
    },
    [config.meta, idx, trustedBy],
  );

  const updateLogoLinkUrl = useCallback(
    async (logo_link_url: string) => {
      if (logo_link_url) {
        try {
          // Validate the URL
          new URL(logo_link_url);
        } catch {
          throw new Error("Not a valid URL");
        }
      }
      await api.vendorToolkit.trustCenter.updateConfigMeta({
        ...config.meta,
        overview: {
          ...config.meta.overview,
          trusted_by: config.meta.overview?.trusted_by?.toSpliced(idx, 1, {...trustedBy, logo_link_url}),
        },
      });
    },
    [config.meta, idx, trustedBy],
  );
  return (
    <HStack justify="space-between">
      <AssetSelector assetId={trustedBy?.logo_asset_id} onChangeAsset={updateLogoAssetId} />
      <CommitInput
        type="url"
        placeholder="https://example.com"
        value={trustedBy?.logo_link_url ?? ""}
        onCommit={updateLogoLinkUrl}
      />

      <DeleteTrustedByButton idx={idx} />
    </HStack>
  );
};

const BusinessContent = ({idx}: {idx: number}) => {
  const config = useQueryData({queryKey: ["vendorToolkit", "trustCenterConfig"]});
  const businessContent = config.meta.business_info?.[idx];
  const updateBusinessTitle = useCallback(
    async (title: string | undefined) => {
      await api.vendorToolkit.trustCenter.updateConfigMeta({
        ...config.meta,
        business_info: config.meta.business_info?.toSpliced(idx, 1, {...businessContent, title}),
      });
    },
    [config.meta, idx, businessContent],
  );
  const updateBusinessContent = useCallback(
    async (content_id: ContentId | undefined) => {
      await api.vendorToolkit.trustCenter.updateConfigMeta({
        ...config.meta,
        business_info: config.meta.business_info?.toSpliced(idx, 1, {...businessContent, content_id}),
      });
    },
    [businessContent, config.meta, idx],
  );
  return (
    <HStack justify="space-between">
      <CommitInput
        type="text"
        placeholder="Content heading"
        value={businessContent?.title ?? ""}
        onCommit={updateBusinessTitle}
      />
      <ContentSelector contentId={businessContent?.content_id} onChangeContent={updateBusinessContent} />
      <DeleteBusinessContentButton idx={idx} />
    </HStack>
  );
};

const AiCheckItem = ({idx}: {idx: number}) => {
  const config = useQueryData({queryKey: ["vendorToolkit", "trustCenterConfig"]});

  // ! justification: This component is only rendered if the item exists.
  const entry = config.meta.ai!.check_items![idx];

  const updateTitle = useCallback(
    async (title: string) => {
      await api.vendorToolkit.trustCenter.updateConfigMeta({
        ...config.meta,
        ai: {
          ...config.meta.ai,
          check_items: config.meta.ai?.check_items?.toSpliced(idx, 1, {...entry, title}),
        },
      });
    },
    [config.meta, entry, idx],
  );

  const updateDescription = useCallback(
    async (description: string) => {
      await api.vendorToolkit.trustCenter.updateConfigMeta({
        ...config.meta,
        ai: {
          ...config.meta.ai,
          check_items: config.meta.ai?.check_items?.toSpliced(idx, 1, {...entry, description}),
        },
      });
    },
    [config.meta, entry, idx],
  );

  return (
    <HStack justify="space-between" alignItems="flex-start">
      <Stack p={4} rounded="md" border="1px solid" borderColor="gray.200" flexGrow="1">
        <FormQuestion title="Title">
          <CommitInput value={entry.title} onCommit={updateTitle} />
        </FormQuestion>
        <FormQuestion title="Description">
          <CommitInput value={entry.description} onCommit={updateDescription} />
        </FormQuestion>
      </Stack>
      <DeleteAiCheckItemButton idx={idx} />
    </HStack>
  );
};

const AiDataUseItem = ({idx}: {idx: number}) => {
  const config = useQueryData({queryKey: ["vendorToolkit", "trustCenterConfig"]});

  // ! justification: This component is only rendered if the item exists.
  const entry = config.meta.ai!.data_use_items![idx];

  const updateTitle = useCallback(
    async (title: string) => {
      await api.vendorToolkit.trustCenter.updateConfigMeta({
        ...config.meta,
        ai: {
          ...config.meta.ai,
          data_use_items: config.meta.ai?.data_use_items?.toSpliced(idx, 1, {...entry, title}),
        },
      });
    },
    [config.meta, entry, idx],
  );

  const updateDescription = useCallback(
    async (description: string) => {
      await api.vendorToolkit.trustCenter.updateConfigMeta({
        ...config.meta,
        ai: {
          ...config.meta.ai,
          data_use_items: config.meta.ai?.data_use_items?.toSpliced(idx, 1, {...entry, description}),
        },
      });
    },
    [config.meta, entry, idx],
  );

  const updatePii = useCallback(
    async (pii: TrustCenterAiDataUseItemPii) => {
      await api.vendorToolkit.trustCenter.updateConfigMeta({
        ...config.meta,
        ai: {
          ...config.meta.ai,
          data_use_items: config.meta.ai?.data_use_items?.toSpliced(idx, 1, {...entry, pii}),
        },
      });
    },
    [config.meta, entry, idx],
  );

  const updateIncludesCustomerData = useCallback(
    async (includes_customer_data: boolean) => {
      await api.vendorToolkit.trustCenter.updateConfigMeta({
        ...config.meta,
        ai: {
          ...config.meta.ai,
          data_use_items: config.meta.ai?.data_use_items?.toSpliced(idx, 1, {...entry, includes_customer_data}),
        },
      });
    },
    [config.meta, entry, idx],
  );

  const updateIsAnonymized = useCallback(
    async (is_anonymized: boolean) => {
      await api.vendorToolkit.trustCenter.updateConfigMeta({
        ...config.meta,
        ai: {
          ...config.meta.ai,
          data_use_items: config.meta.ai?.data_use_items?.toSpliced(idx, 1, {...entry, is_anonymized}),
        },
      });
    },
    [config.meta, entry, idx],
  );

  const updateIsUsedForTraining = useCallback(
    async (used_for_training: boolean) => {
      await api.vendorToolkit.trustCenter.updateConfigMeta({
        ...config.meta,
        ai: {
          ...config.meta.ai,
          data_use_items: config.meta.ai?.data_use_items?.toSpliced(idx, 1, {...entry, used_for_training}),
        },
      });
    },
    [config.meta, entry, idx],
  );

  return (
    <HStack justify="space-between" alignItems="flex-start">
      <Stack spacing={4} p={4} rounded="md" border="1px solid" borderColor="gray.200" flexGrow="1">
        <FormQuestion title="Type of data">
          <CommitInput value={entry.title} onCommit={updateTitle} />
        </FormQuestion>
        <FormQuestion title="Description (optional)">
          <CommitInput value={entry.description} onCommit={updateDescription} />
        </FormQuestion>
        <FormQuestion title="Data classification">
          <RadioGroup value={entry.pii} onChange={v => updatePii(v as TrustCenterAiDataUseItemPii)}>
            <HStack spacing={8}>
              <Radio value="None">Normal</Radio>
              <Radio value="Pii">PII</Radio>
              <Radio value="SpecialCategory">Special category PII</Radio>
            </HStack>
          </RadioGroup>
        </FormQuestion>
        <FormQuestion title="Additional information">
          <HStack spacing={8} flexWrap="wrap" rowGap={2}>
            <Checkbox
              isChecked={entry.includes_customer_data}
              onChange={ev => updateIncludesCustomerData(ev.target.checked)}
            >
              Includes customer data
            </Checkbox>
            <Checkbox isChecked={entry.is_anonymized} onChange={ev => updateIsAnonymized(ev.target.checked)}>
              Is anonymized
            </Checkbox>
            <Checkbox isChecked={entry.used_for_training} onChange={ev => updateIsUsedForTraining(ev.target.checked)}>
              Is used for training
            </Checkbox>
          </HStack>
        </FormQuestion>
      </Stack>
      <DeleteAiDataUseItemButton idx={idx} />
    </HStack>
  );
};

const AiModelProvider = ({idx}: {idx: number}) => {
  const config = useQueryData({queryKey: ["vendorToolkit", "trustCenterConfig"]});

  // ! justification: This component is only rendered if the item exists.
  const entry = config.meta.ai!.model_providers![idx];

  const updateSubprocessor = useCallback(
    async (subprocessor_entity_id: EntityId | null) => {
      await api.vendorToolkit.trustCenter.updateConfigMeta({
        ...config.meta,
        ai: {
          ...config.meta.ai,
          model_providers: config.meta.ai?.model_providers?.toSpliced(idx, 1, {
            ...entry,
            subprocessor_entity_id: subprocessor_entity_id ?? undefined,
          }),
        },
      });
    },
    [config.meta, entry, idx],
  );

  const updateModelsUsed = useCallback(
    async (models_used: string) => {
      await api.vendorToolkit.trustCenter.updateConfigMeta({
        ...config.meta,
        ai: {
          ...config.meta.ai,
          model_providers: config.meta.ai?.model_providers?.toSpliced(idx, 1, {...entry, models_used}),
        },
      });
    },
    [config.meta, entry, idx],
  );

  const updatePurposes = useCallback(
    async (purposes: string) => {
      await api.vendorToolkit.trustCenter.updateConfigMeta({
        ...config.meta,
        ai: {
          ...config.meta.ai,
          model_providers: config.meta.ai?.model_providers?.toSpliced(idx, 1, {...entry, purposes}),
        },
      });
    },
    [config.meta, entry, idx],
  );

  return (
    <HStack justify="space-between" alignItems="flex-start">
      <Stack p={4} rounded="md" border="1px solid" borderColor="gray.200" flexGrow="1">
        <FormQuestion
          title="Subprocessor"
          description="Choose a subprocessor from your library as this AI model provider."
        >
          <SubprocessorSelector value={entry.subprocessor_entity_id ?? null} onChange={updateSubprocessor} />
        </FormQuestion>
        <FormQuestion title="Models used">
          <CommitInput value={entry.models_used} onCommit={updateModelsUsed} placeholder={`e.g. GPT-4, GPT-4 Turbo`} />
        </FormQuestion>
        <FormQuestion title="Purposes">
          <CommitTextarea value={entry.purposes} onCommit={updatePurposes} />
        </FormQuestion>
      </Stack>
      <DeleteAiModelProviderButton idx={idx} />
    </HStack>
  );
};

const ConfigPage = withSuspense(() => {
  const whoami = useQueryData({queryKey: ["whoAmI"]});
  const config = useQueryData({queryKey: ["vendorToolkit", "trustCenterConfig"]});
  const updateName = useCallback(
    async (name: string) => {
      await api.vendorToolkit.trustCenter.updateConfigMeta({...config.meta, name});
    },
    [config.meta],
  );
  const updateLogo = useCallback(
    async (logo_asset_id: AssetId | undefined) => {
      await api.vendorToolkit.trustCenter.updateConfigMeta({...config.meta, logo_asset_id});
    },
    [config.meta],
  );
  const updateFavicon = useCallback(
    async (favicon_asset_id: AssetId | undefined) => {
      await api.vendorToolkit.trustCenter.updateConfigMeta({...config.meta, favicon_asset_id});
    },
    [config.meta],
  );
  const updateHomepageUrl = useCallback(
    async (homepage_url: string) => {
      if (homepage_url) {
        try {
          // Validate the URL
          new URL(homepage_url);
        } catch {
          throw new Error("Not a valid URL");
        }
      }
      await api.vendorToolkit.trustCenter.updateConfigMeta({...config.meta, homepage_url});
    },
    [config.meta],
  );
  const updatePrivacyPolicyUrl = useCallback(
    async (url: string) => {
      if (url) {
        try {
          // Validate the URL
          new URL(url);
        } catch {
          throw new Error("Not a valid URL");
        }
      }
      await api.vendorToolkit.trustCenter.updateConfigMeta({...config.meta, privacy_policy: url});
    },
    [config.meta],
  );
  const updateDocumentRequestEmail = useCallback(async (email: string) => {
    await api.vendorToolkit.trustCenter.updateConfigDocumentRequestEmail(email);
  }, []);
  const updateHeroHeading = useCallback(
    async (heading: string) => {
      await api.vendorToolkit.trustCenter.updateConfigMeta({...config.meta, hero: {...config.meta.hero, heading}});
    },
    [config.meta],
  );
  const updateHeroSubheading = useCallback(
    async (subheading: string) => {
      await api.vendorToolkit.trustCenter.updateConfigMeta({...config.meta, hero: {...config.meta.hero, subheading}});
    },
    [config.meta],
  );
  const updateOverviewWelcome = useCallback(
    async (welcome_id: ContentId | undefined) => {
      await api.vendorToolkit.trustCenter.updateConfigMeta({
        ...config.meta,
        overview: {...config.meta.overview, welcome_id},
      });
    },
    [config.meta],
  );
  const updateOverviewContent = useCallback(
    async (content_id: ContentId | undefined) => {
      await api.vendorToolkit.trustCenter.updateConfigMeta({
        ...config.meta,
        overview: {...config.meta.overview, content_id},
      });
    },
    [config.meta],
  );

  const updateAiOverview = useCallback(
    async (overview_content_id: ContentId | undefined) => {
      await api.vendorToolkit.trustCenter.updateConfigMeta({
        ...config.meta,
        ai: {...config.meta.ai, overview_content_id},
      });
    },
    [config.meta],
  );

  const [addingTrustedBy, addTrustedBy] = usePromiseState(async () => {
    await api.vendorToolkit.trustCenter.updateConfigMeta({
      ...config.meta,
      overview: {...config.meta.overview, trusted_by: [...(config.meta.overview?.trusted_by ?? []), {}]},
    });
  }, [config.meta]);

  const [addingBusinessContent, addBusinessContent] = usePromiseState(async () => {
    await api.vendorToolkit.trustCenter.updateConfigMeta({
      ...config.meta,
      business_info: [...(config.meta.business_info ?? []), {title: "", content_id: undefined}],
    });
  }, [config.meta]);

  const toggleAiEnabled = useCallback(async () => {
    const enabled = !config.meta.ai?.enabled;
    await api.vendorToolkit.trustCenter.updateConfigMeta({...config.meta, ai: {...config.meta.ai, enabled}});
  }, [config.meta]);

  const toggleSubscribeEnabled = useCallback(async () => {
    const enabled = !config.meta.subscribe?.enabled;
    await api.vendorToolkit.trustCenter.updateConfigMeta({
      ...config.meta,
      subscribe: {...config.meta.subscribe, enabled},
    });
  }, [config.meta]);

  const [addingAiDataUseItem, addAiDataUseItem] = usePromiseState(async () => {
    await api.vendorToolkit.trustCenter.updateConfigMeta({
      ...config.meta,
      ai: {
        ...config.meta.ai,
        data_use_items: [
          ...(config.meta.ai?.data_use_items ?? []),
          {
            title: "",
            description: "",
            pii: TrustCenterAiDataUseItemPii.None,
            includes_customer_data: false,
            is_anonymized: false,
            used_for_training: false,
          },
        ],
      },
    });
  }, [config.meta]);

  const [updatingTrustedPartnered, updateTrustedPartnered] = usePromiseState(
    async (logo_type: string) => {
      await api.vendorToolkit.trustCenter.updateConfigMeta({
        ...config.meta,
        overview: {
          ...config.meta.overview,
          trusted_partnered:
            logo_type === "Partnered"
              ? TrustCenterTrustedPartneredType.Partnered
              : TrustCenterTrustedPartneredType.Trusted,
        },
      });
    },
    [config.meta],
  );

  const [addingAiCheckItem, addAiCheckItem] = usePromiseState(async () => {
    await api.vendorToolkit.trustCenter.updateConfigMeta({
      ...config.meta,
      ai: {
        ...config.meta.ai,
        check_items: [...(config.meta.ai?.check_items ?? []), {title: "", description: ""}],
      },
    });
  }, [config.meta]);

  const [addingAiRelatedDocument, addAiRelatedDocument] = usePromiseState(async () => {
    await api.vendorToolkit.trustCenter.updateConfigMeta({
      ...config.meta,
      ai: {
        ...config.meta.ai,
        related_documents: [...(config.meta.ai?.related_documents ?? []), {document_id: undefined}],
      },
    });
  }, [config.meta]);

  const [addingAiModelProvider, addAiModelProvider] = usePromiseState(async () => {
    await api.vendorToolkit.trustCenter.updateConfigMeta({
      ...config.meta,
      ai: {
        ...config.meta.ai,
        model_providers: [...(config.meta.ai?.model_providers ?? []), {models_used: "", purposes: ""}],
      },
    });
  }, [config.meta]);

  const [_updatingAiRelatedDocument, updateAiRelatedDocument] = usePromiseState(
    async (idx: number, document_id: DocumentId | null) => {
      await api.vendorToolkit.trustCenter.updateConfigMeta({
        ...config.meta,
        ai: {
          ...config.meta.ai,
          related_documents: config.meta.ai?.related_documents?.toSpliced(idx, 1, {
            document_id: document_id ?? undefined,
          }),
        },
      });
    },
    [config.meta],
  );

  const [_updatingAiFaqQuestionnaire, updateAiFaqQuestionnaire] = usePromiseState(
    async (questionnaireId: QuestionnaireId | null) => {
      await api.vendorToolkit.trustCenter.updateConfigMeta({
        ...config.meta,
        ai: {
          ...config.meta.ai,
          faq_questionnaire_id: questionnaireId ?? undefined,
        },
      });
    },
    [config.meta],
  );

  const [togglingIsPublic, toggleIsPublic] = usePromiseState(async () => {
    await api.vendorToolkit.trustCenter.updateIsPublic(!config.is_public);
  }, [config.is_public]);

  const trustCenterUrl = getTrustCenterUrl(whoami);

  return (
    <Page title="Assets" pb={12}>
      <Flex justifyContent="space-between" alignItems="center" p={8}>
        <Heading size="lg">Trust center configuration</Heading>
        <Button
          as="a"
          target="_blank"
          rightIcon={<Icon as={ArrowTopRightOnSquareIcon} />}
          cursor="pointer"
          colorScheme="green"
          size="sm"
          href={trustCenterUrl}
        >
          View trust center
        </Button>
      </Flex>
      <Stack divider={<Divider />} spacing={4} p={4}>
        <FormSection title="Main" description="">
          <FormQuestion title="Displayed name">
            <CommitInput value={config.meta.name ?? ""} onCommit={updateName} />
          </FormQuestion>
          <FormQuestion title="Logo" description="A company logo to show at the top of every page.">
            <AssetSelector assetId={config.meta.logo_asset_id} onChangeAsset={updateLogo} />
          </FormQuestion>
          <FormQuestion
            title="Favicon"
            description="A visual reminder of the site identity shown in the address bar or in tabs. Recommended size: 64x64px."
          >
            <AssetSelector assetId={config.meta.favicon_asset_id} onChangeAsset={updateFavicon} />
          </FormQuestion>
          <FormQuestion
            title="Homepage URL"
            description="The site users will be taken to when they click your company logo on the trust center."
          >
            <CommitInput value={config.meta.homepage_url ?? ""} onCommit={updateHomepageUrl} />
          </FormQuestion>
          <FormQuestion
            title="Document request email"
            description="Document access requests will be forwarded to this email address."
          >
            <CommitInput value={config.document_request_email ?? ""} onCommit={updateDocumentRequestEmail} />
          </FormQuestion>
        </FormSection>
        <FormSection title="Hero banner" description="Banner displayed at the top of every page">
          <FormQuestion title="Heading">
            <CommitInput value={config.meta.hero?.heading ?? ""} onCommit={updateHeroHeading} />
          </FormQuestion>
          <FormQuestion title="Subheading">
            <CommitInput value={config.meta.hero?.subheading ?? ""} onCommit={updateHeroSubheading} />
          </FormQuestion>
        </FormSection>
        <FormSection title="Overview" description="Configure the Overview page">
          <FormQuestion title="Welcome message" description="This content appears at the top of the Overview page">
            <ContentSelector contentId={config.meta.overview?.welcome_id} onChangeContent={updateOverviewWelcome} />
          </FormQuestion>
          <FormQuestion title="Trusted by/Partnered with" description="These logos appear after your welcome message">
            <Select
              value={config.meta.overview?.trusted_partnered ?? "Trusted"}
              onChange={e => updateTrustedPartnered(e.target.value)}
              disabled={updatingTrustedPartnered.inProgress}
            >
              <option value="Trusted">Trusted by</option>
              <option value="Partnered">Partnered with</option>
            </Select>
            {config.meta.overview?.trusted_by?.map((_trustedBy, idx) => <TrustedBy key={idx} idx={idx} />)}
            <Box>
              <Button
                onClick={addTrustedBy}
                isDisabled={addingTrustedBy.inProgress}
                isLoading={addingTrustedBy.inProgress}
                size="sm"
              >
                Add logo
              </Button>
            </Box>
          </FormQuestion>
          <FormQuestion title="Page content" description="This content populates the body of the Overview page">
            <ContentSelector contentId={config.meta.overview?.content_id} onChangeContent={updateOverviewContent} />
          </FormQuestion>
        </FormSection>
        <Internal>
          <FormSection title="Subscribe" description="Allow clients to subscribe to mailing list for updates">
            <Flex justifyContent="space-between" alignItems="center">
              <FormQuestion title="Enabled" description="Show subscribe button" aria-label={"toggle-tc-subscribe"}>
                <Switch isChecked={config.meta.subscribe?.enabled} onChange={toggleSubscribeEnabled} />
              </FormQuestion>
              <Stack>
                <Button
                  as="a"
                  target="_blank"
                  rightIcon={<Icon as={ArrowRightIcon} />}
                  cursor="pointer"
                  colorScheme="green"
                  size="sm"
                >
                  View mailing list
                </Button>
                <Button
                  as="a"
                  target="_blank"
                  rightIcon={<Icon as={ArrowRightIcon} />}
                  cursor="pointer"
                  colorScheme="green"
                  size="sm"
                >
                  View notification center
                </Button>
              </Stack>
            </Flex>
            <FormQuestion
              title="Privacy Policy URL"
              description="Your privacy policy link which users can view when subscribing."
            >
              <CommitInput value={config.meta.privacy_policy ?? ""} onCommit={updatePrivacyPolicyUrl} />
            </FormQuestion>
          </FormSection>
        </Internal>
        <FormSection
          title="Business Information"
          description="Additional content for the Business Information page. Any content you configure here will appear
          beneath the basic company information (company number, registered address, ...) provided under Graph."
        >
          <Flex justifyContent="end" alignItems="center">
            <Button
              as="a"
              target="_blank"
              rightIcon={<Icon as={ArrowTopRightOnSquareIcon} />}
              cursor="pointer"
              colorScheme="green"
              size="sm"
              href={trustCenterUrl + "/info"}
            >
              View business information
            </Button>
          </Flex>
          <FormQuestion
            title="Content"
            description="Configured content will appear after any legal information on the Business Information page"
          >
            {config.meta.business_info?.map((_businessContent, idx) => <BusinessContent key={idx} idx={idx} />)}
            <Box>
              <Button
                onClick={addBusinessContent}
                isDisabled={addingBusinessContent.inProgress}
                isLoading={addingBusinessContent.inProgress}
                size="sm"
              >
                Add content
              </Button>
            </Box>
          </FormQuestion>
        </FormSection>
        <FormSection title="AI" description="Configure the AI page">
          <Flex justifyContent="space-between" alignItems="center">
            <FormQuestion title="Enabled" description="Show the AI page" aria-label={"toggle-tc-ai"}>
              <Switch isChecked={config.meta.ai?.enabled} onChange={toggleAiEnabled} />
            </FormQuestion>
            <Button
              as="a"
              target="_blank"
              rightIcon={<Icon as={ArrowTopRightOnSquareIcon} />}
              cursor="pointer"
              colorScheme="green"
              size="sm"
              href={trustCenterUrl + "/ai"}
              isDisabled={!config.meta.ai?.enabled}
            >
              View AI
            </Button>
          </Flex>
          <FormQuestion title="Overview message" description="This content appears at the top of the AI page">
            <ContentSelector contentId={config.meta.ai?.overview_content_id} onChangeContent={updateAiOverview} />
          </FormQuestion>
          <FormQuestion title="Data use">
            <Stack>{config.meta.ai?.data_use_items?.map((_, idx) => <AiDataUseItem key={idx} idx={idx} />)}</Stack>
            <Box>
              <Button
                onClick={addAiDataUseItem}
                isDisabled={addingAiDataUseItem.inProgress}
                isLoading={addingAiDataUseItem.inProgress}
                size="sm"
              >
                Add entry
              </Button>
            </Box>
          </FormQuestion>
          <FormQuestion title="Trust and compliance">
            <Stack>{config.meta.ai?.check_items?.map((_, idx) => <AiCheckItem key={idx} idx={idx} />)}</Stack>
            <Box>
              <Button
                onClick={addAiCheckItem}
                isDisabled={addingAiCheckItem.inProgress}
                isLoading={addingAiCheckItem.inProgress}
                size="sm"
              >
                Add entry
              </Button>
            </Box>
          </FormQuestion>
          <FormQuestion title="AI model providers">
            {config.meta.ai?.model_providers?.map((_, idx) => <AiModelProvider key={idx} idx={idx} />)}
            <Box>
              <Button
                onClick={addAiModelProvider}
                isDisabled={addingAiModelProvider.inProgress}
                isLoading={addingAiModelProvider.inProgress}
                size="sm"
              >
                Add model provider
              </Button>
            </Box>
          </FormQuestion>
          <FormQuestion title="Related documents" description="Documents related to AI to show on the AI page">
            {config.meta.ai?.related_documents?.map((d, idx) => (
              <HStack justifyContent="space-between" key={idx}>
                <DocumentSelector
                  value={d.document_id ?? null}
                  onChange={id => updateAiRelatedDocument(idx, id)}
                  showCategoryPrefix
                  filter={d => d.trust_center_visibility === TrustCenterAccess.Allowed}
                />
                <DeleteAiRelatedDocumentButton idx={idx} />
              </HStack>
            ))}
            <Box>
              <Button
                onClick={addAiRelatedDocument}
                isDisabled={addingAiRelatedDocument.inProgress}
                isLoading={addingAiRelatedDocument.inProgress}
                size="sm"
              >
                Add document
              </Button>
            </Box>
          </FormQuestion>
          <FormQuestion
            title="Other questions"
            description={`Link a questionnaire to show in the "Other questions" section`}
          >
            <QuestionnaireSelector
              value={config.meta.ai?.faq_questionnaire_id ?? null}
              onChange={updateAiFaqQuestionnaire}
            />
          </FormQuestion>
        </FormSection>
        <FormSection title="Liveness" description="Is your trust center live and accessible to the public?">
          <Box aria-label={"trust-center-liveness"}>
            <FormLabel>Live?</FormLabel>
            <Switch isChecked={config.is_public} onChange={toggleIsPublic} isDisabled={togglingIsPublic.inProgress} />
          </Box>
        </FormSection>
      </Stack>
    </Page>
  );
});

export default ConfigPage;
