import {useEffect} from "react";

type Props = {
  title: string;
  platformedPrefix?: boolean;
};

/**
 * Set the document title.
 *
 * The reason this is a component rather than just a useEffect is because React
 * fires child useEffects before parents, which would cause the title "stack" to
 * be initialized in the wrong order if both a parent and child try to set a
 * document title.
 *
 * By using a component we can control the order effects run by placing this
 * component before children in the render tree.
 *
 * https://github.com/facebook/react/issues/15281#issuecomment-895546655
 */
function DocumentTitle(props: Props) {
  const platformedPrefix = props.platformedPrefix ?? true;

  let title = props.title;

  if (platformedPrefix) {
    title = `Platformed - ${title}`;
  }

  useEffect(() => {
    const originalTitle = document.title;
    document.title = title;

    return () => {
      document.title = originalTitle;
    };
  }, [title]);

  return null;
}

export default DocumentTitle;
