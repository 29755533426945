import {Icon, Text, Stack, HStack, Progress, Box, CloseButton} from "@chakra-ui/react";
import {ExclamationTriangleIcon} from "@heroicons/react/24/solid";
import {DocumentChartBarIcon, DocumentIcon, DocumentTextIcon, PhotoIcon} from "@heroicons/react/24/outline";
import {useFriendlyMimes} from "../../hooks/import";
import {formatFileSize} from ".";
import {withSuspense} from "../../state/withSuspense";

export const FileTypeIcon = ({ext}: {ext: string}) => {
  const icon =
    {
      JPEG: PhotoIcon,
      PNG: PhotoIcon,
      GIF: PhotoIcon,
      DOCX: DocumentTextIcon,
      DOC: DocumentTextIcon,
      XLSX: DocumentChartBarIcon,
      XLS: DocumentChartBarIcon,
    }[ext] || DocumentIcon;

  const color =
    {
      JPEG: "purple",
      PNG: "purple",
      GIF: "purple",
      DOCX: "blue",
      DOC: "blue",
      XLSX: "green",
      XLS: "green",
      PDF: "yellow",
    }[ext] || "gray";

  return (
    <Icon
      as={icon}
      w={10}
      h={10}
      color={`${color}.500`}
      bg="white"
      borderRadius="full"
      border="1px"
      borderColor={`${color}.100`}
      p={2}
    />
  );
};

const StyledFile = withSuspense(
  ({
    file,
    onDelete,
    progress,
    error,
  }: {
    file: {name: string; size: number; type: string};
    onDelete?: () => void;
    progress?: number;
    error?: unknown;
  }) => {
    const {resolveMime} = useFriendlyMimes();
    let fileExt;
    try {
      fileExt = resolveMime(file.type).fileType.slice(1).toUpperCase();
    } catch (ex) {
      fileExt = "UNKNOWN";
    }

    return (
      <Stack
        bg="gray.50"
        px={4}
        py={2}
        fontSize="md"
        border="1px solid"
        borderColor="gray.100"
        borderRadius="md"
        spacing={4}
        justify="center"
      >
        <HStack align="center" spacing={4} minH={12}>
          {error ? (
            <Icon as={ExclamationTriangleIcon} w={10} h={10} color="white" bg="red.500" borderRadius="full" p={2} />
          ) : (
            <FileTypeIcon ext={fileExt} />
          )}
          <Stack align="start" spacing={1} flex={1} minWidth="0">
            <HStack width="100%" justifyContent="space-between" fontWeight="600" color={error ? "red.500" : undefined}>
              <Text overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
                {file.name}
              </Text>
              <Text overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
                {error ? error.toString() : null}
              </Text>
              <Text />
            </HStack>
            <HStack
              color="gray.500"
              fontSize="sm"
              divider={
                <Text fontSize="4xl" lineHeight="0" mx={1} mb="2px" color="gray.400">
                  ·
                </Text>
              }
            >
              <Box>{formatFileSize(file.size)}</Box>
              <Box>{fileExt}</Box>
              {progress && <Box>{(progress * 100).toFixed(0)}%</Box>}
            </HStack>
          </Stack>
          {onDelete && <CloseButton onClick={onDelete} />}
        </HStack>
        {progress && <Progress value={progress} max={1} borderRadius="md" h={1} />}
      </Stack>
    );
  },
);

export default StyledFile;
