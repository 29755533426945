import BaseFactsPage from "../components/BaseFactsPage";
import {findScopeById, useHierarchy} from "../utility/hierarchy";
import {useMemo} from "react";
import {HTTPError} from "../../../../../api";
import {Type} from "../../../../../typing";
import {useTypedParams} from "../../../../../hooks/typedParams";

export type ScopePageProps = {
  /**
   * - If `true`, show all facts in the scope and its descendent sections.
   * - If `false`, only show facts at the top level (uncategorized)
   */
  recurse?: boolean;
};

export const ScopeParams = Type.Object({id: Type.Union([Type.ScopeId(), Type.Literal("global")])});

function ScopePage(props: ScopePageProps) {
  const {id} = useTypedParams(ScopeParams);
  const recurse = props.recurse ?? true;

  const hierarchy = useHierarchy();
  const scope = useMemo(() => findScopeById(hierarchy, id), [hierarchy, id]);

  if (scope === undefined) {
    throw new HTTPError(new Response(null, {status: 404}));
  }

  // !: We're not in the global scope because we would have matched a different
  // route.
  const name = scope.obj!.name;

  return <BaseFactsPage title={name} libraryContext={{hierarchy, parent: scope, recurse}} />;
}

export default ScopePage;
