import {ExternalAuthorizationId, QuestionSourceId} from "../../../Types";
import api from "../../../api";
import {param, typedQuery} from "../../typing";

export default [
  typedQuery(["vendorToolkit", "questionSourceConnectors"], async () => {
    return await api.vendorToolkit.questionSources.listConnectors();
  }),
  typedQuery(["vendorToolkit", "questionSourceConnector", param("questionSourceConnectorId")], async context => {
    return await api.vendorToolkit.questionSources.getConnector(context.queryKey[2]);
  }),
  typedQuery(["vendorToolkit", "questionSources"], async () => {
    return await api.vendorToolkit.questionSources.list();
  }),
  typedQuery(["vendorToolkit", "questionSource", param<QuestionSourceId>("questionSourceId")], async context => {
    return await api.vendorToolkit.questionSources.get(context.queryKey[2]);
  }),
  typedQuery(
    [
      "vendorToolkit",
      "questionSourceAccountInfo",
      param("questionSourceConnectorId"),
      param<ExternalAuthorizationId>("externalAuthorizationId"),
    ],
    async context => {
      return await api.vendorToolkit.questionSources.getAccountInfo(context.queryKey[2], context.queryKey[3]);
    },
  ),
];
