import {ReactNode, useCallback} from "react";
import {
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Radio,
  useDisclosure,
  Text,
  ModalFooter,
  Button,
  HStack,
  ModalHeader,
  Box,
  Tooltip,
  Icon,
  Image,
  FlexProps,
} from "@chakra-ui/react";
import {LoginMethodProps} from "./AnyLoginMethod";
import {ExclamationTriangleIcon} from "@heroicons/react/24/outline";

const LogoutWarningModal = ({confirm, onClose}: {confirm: () => void; onClose: () => void}) => {
  return (
    <ModalContent>
      <ModalHeader>Changing authentication method</ModalHeader>
      <ModalBody>
        <Text fontSize="md">
          If you change your authentication method, you will be logged out and returned to the login screen.
        </Text>
        <Text fontSize="md">Please log in again using the new method you have selected.</Text>
      </ModalBody>
      <ModalFooter>
        <HStack spacing={3}>
          <Button variant="ghost" onClick={onClose}>
            Cancel
          </Button>
          <Button colorScheme="blue" onClick={confirm}>
            Confirm
          </Button>
        </HStack>
      </ModalFooter>
    </ModalContent>
  );
};

type LoginMethodConfigurationProps = {
  icon?: string;
  title?: ReactNode;
  description?: ReactNode;
  rhs?: ReactNode;
  onConfigureAndSelect: () => void;
};

// Box that acts like a radio button and can be stacked
function RadioBox({isSelected, isDisabled, onClick, ...props}: FlexProps & {isSelected: boolean; isDisabled: boolean}) {
  return (
    <Flex
      align="center"
      border="1px solid"
      zIndex={isSelected ? "base" : undefined}
      py="4"
      px="6"
      mt="-1px"
      _first={{borderTopRadius: "md", mt: "0px"}}
      _last={{borderBottomRadius: "md"}}
      cursor={isDisabled ? "not-allowed" : "pointer"}
      onClick={isDisabled ? undefined : onClick}
      {...props}
    />
  );
}

export function LoginMethodBox({
  loginMethod,
  icon,
  title,
  description,
  rhs,
  isLoggedIn,
  isSelected,
  isDisabled,
  isConfigured,
  isRecommended,
  onConfigureAndSelect,
  onSelect,
}: LoginMethodConfigurationProps & LoginMethodProps) {
  // Modal state for logout warning
  const {isOpen, onOpen, onClose} = useDisclosure();

  const select = useCallback(async () => {
    if (!isConfigured) {
      // Login method has not been configured yet. Configure it, and then select it.
      onConfigureAndSelect();
    } else if (onSelect) {
      // Login method is already configured, we can just select it.
      onSelect(loginMethod);
    }
  }, [isConfigured, onSelect, loginMethod, onConfigureAndSelect]);

  return (
    <RadioBox
      isSelected={isSelected}
      isDisabled={isDisabled}
      borderColor={isSelected ? "blue.300" : "gray.100"}
      bg={isSelected ? "blue.50" : isRecommended ? "" : "gray.50"}
      // If user is logged in, show a warning that they'll be logged out
      onClick={isLoggedIn ? onOpen : select}
    >
      {/* Only show the radio button if selecting between login methods is possible */}
      {onSelect ? <Radio mr="4" value={loginMethod} isDisabled={isDisabled} /> : null}
      <Flex flex="1" align="center" opacity={isDisabled ? 0.5 : undefined}>
        {icon ? <Image h="7" src={icon} pr="3" /> : null}
        <Box flex="1">
          <Text fontSize="md" fontWeight="600" color={isSelected ? "blue.900" : "gray.900"}>
            {title}
          </Text>
          <Text fontSize="md" color={isSelected ? "blue.500" : "gray.500"}>
            {description}
          </Text>
        </Box>
      </Flex>
      {/* Only show the RHS button if selecting between login methods is possible */}
      {onSelect ? (
        <Box
          onClick={
            // Prevent clicks on the RHS button from bubbling up to our own `onClick` handler
            e => e.stopPropagation()
          }
        >
          {rhs}
        </Box>
      ) : null}
      {!isRecommended && (
        <Tooltip
          hasArrow
          placement="right"
          label="One or more of your accounts do not allow access via this login method."
        >
          <Icon zIndex={0} as={ExclamationTriangleIcon} fontSize="xl" />
        </Tooltip>
      )}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <LogoutWarningModal confirm={select} onClose={onClose} />
      </Modal>
    </RadioBox>
  );
}
