import {ButtonGroup, ButtonGroupProps} from "@chakra-ui/react";
import * as React from "react";

type SelectButtonGroupProps = {
  onChange: (newValue: string) => void;
  value: string;
} & Omit<ButtonGroupProps, "onChange">;

/**
 * @example (
 * <SelectButtonGroup onChange={handleChangeValue} value={value}>
 *  <Button value={value1}>label1</Button>
 *  <Button value={value2}>label2</Button>
 * </SelectButtonGroup>
 * )
 * @note
 * must have multiple child elements
 */
const SelectButtonGroup = React.forwardRef(
  ({onChange, value, children, ...groupProps}: SelectButtonGroupProps, ref) => {
    return (
      <ButtonGroup ref={ref} {...groupProps}>
        {React.Children.map(children as React.ReactElement[], child => {
          return (
            child &&
            React.cloneElement(child, {
              onClick: () => {
                if (value === child?.props?.value) return;
                onChange(child?.props?.value);
              },
              ...(value === child?.props?.value && {"data-active": true}),
            })
          );
        })}
      </ButtonGroup>
    );
  },
);

export default SelectButtonGroup;
