import {useEffect, useState} from "react";

const GoogleApi = Symbol("Google API");
type ApiName = keyof typeof window.google;

const apiLoadedState = new Map<typeof GoogleApi | ApiName, (() => void)[] | true>();

function loadApiInternal(name: typeof GoogleApi | ApiName, cb: () => void, loader: () => Promise<void>) {
  const state = apiLoadedState.get(name);
  if (state) {
    if (state === true) {
      cb();
    } else {
      state.push(cb);
    }
  } else {
    apiLoadedState.set(name, [cb]);
    setTimeout(async () => {
      await loader();
      const state = apiLoadedState.get(name);
      apiLoadedState.set(name, true);

      if (state && state !== true) {
        for (cb of state) {
          cb();
        }
      }
    }, 0);
  }
}

export function loadGoogleApi(name: ApiName): Promise<void> {
  return new Promise(resolve => {
    loadApiInternal(
      GoogleApi,
      () => {
        gapi.load(name, resolve);
      },
      () => {
        return new Promise((resolve2, reject2) => {
          const scriptTag = document.createElement("script");
          scriptTag.src = "https://apis.google.com/js/api.js";
          scriptTag.async = true;
          scriptTag.defer = true;
          scriptTag.onload = () => resolve2();
          scriptTag.onerror = e => reject2(e);

          document.body.appendChild(scriptTag);
        });
      },
    );
  });
}

export function useGoogleApi(name: ApiName): boolean {
  const [apiLoaded, setApiLoaded] = useState(false);

  useEffect(() => {
    loadGoogleApi(name).then(() => setApiLoaded(true));
  }, [name]);

  return apiLoaded;
}
