import {useQueryData} from "../../../../../state";
import {Type} from "../../../../../typing";
import {withTypedProps} from "../../../../../typing/typedProps";

export const DocumentSourceParams = Type.Object({documentSourceId: Type.DocumentSourceId()});

const Crumb = withTypedProps(DocumentSourceParams, ({documentSourceId}) => {
  return useQueryData({queryKey: ["vendorToolkit", "documentSource", documentSourceId]}).name;
});

export default Crumb;
