import {
  Button,
  Checkbox,
  CloseButton,
  FormControl,
  Grid,
  GridItem,
  HStack,
  Icon,
  Input,
  Radio,
  RadioGroup,
  Stack,
  Switch,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import WordCountInput from "../../components/WordCountInput.tsx";
import {AnswerOption, AnswerParts, QuestionStatus as QuestionStatusT} from "../../../../../Types.ts";
import {PlusIcon} from "@heroicons/react/24/outline";
import _ from "lodash";
import {InformationCircleIcon} from "@heroicons/react/20/solid";
import {Fragment} from "react";

function AnswerPartsEditor({
  answerParts,
  setAnswerParts,
}: {
  answerParts: AnswerParts;
  setAnswerParts: (parts: AnswerParts) => void;
}) {
  const NEW_SELECT_OPTION_VALUE = "New option";

  const addNewSelectOption = () => {
    updateSelectOptions([
      ...answerParts.select.config.options,
      {value: NEW_SELECT_OPTION_VALUE, label: NEW_SELECT_OPTION_VALUE, allow_other_text: false},
    ]);
  };

  const updateSelectOptions = (options: AnswerOption[]) => {
    setAnswerParts(_.set(_.cloneDeep(answerParts), ["select", "config", "options"], options));
  };

  const updateMaxSelect = (max: number | undefined) => {
    setAnswerParts(_.set(_.cloneDeep(answerParts), ["select", "config", "max_selected"], max));
  };

  return (
    <Tabs>
      <TabList border="1px solid" borderColor="gray.100">
        <Tab py={4}>
          <Checkbox
            isChecked={answerParts.yes_no.enabled.content}
            onChange={e =>
              setAnswerParts(_.set(_.cloneDeep(answerParts), ["yes_no", "enabled", "content"], e.target.checked))
            }
          >
            Yes/No
          </Checkbox>
        </Tab>
        <Tab>
          <Checkbox
            isChecked={answerParts.select.enabled.content}
            onChange={e =>
              setAnswerParts(_.set(_.cloneDeep(answerParts), ["select", "enabled", "content"], e.target.checked))
            }
          >
            Select
          </Checkbox>
        </Tab>
        <Tab>
          <Checkbox
            isChecked={answerParts.text.enabled.content}
            onChange={e =>
              setAnswerParts(_.set(_.cloneDeep(answerParts), ["text", "enabled", "content"], e.target.checked))
            }
          >
            Text
          </Checkbox>
        </Tab>
        <Tab>
          <Checkbox
            isChecked={answerParts.files.enabled.content}
            onChange={e =>
              setAnswerParts(_.set(_.cloneDeep(answerParts), ["files", "enabled", "content"], e.target.checked))
            }
          >
            {" "}
            Files{" "}
          </Checkbox>
        </Tab>
      </TabList>
      <TabPanels bg="gray.50" border="1px solid" borderTop="none" borderColor="gray.100">
        <TabPanel color="gray.500">No configuration</TabPanel>
        <TabPanel>
          <FormControl>
            <RadioGroup
              onChange={e => {
                if (e === "single-select") {
                  updateMaxSelect(1);
                } else {
                  updateMaxSelect(undefined);
                }
              }}
              value={answerParts.select.config.max_selected === 1 ? "single-select" : "multi-select"}
            >
              <HStack direction="row" gap={4}>
                <Radio value="single-select">Single-select</Radio>
                <Radio value="multi-select">Multi-select</Radio>
                {answerParts.select.config.max_selected !== 1 && (
                  <HStack width={"30%"}>
                    <Text fontSize={"smaller"}>Max select: </Text>
                    <Input
                      min={2}
                      type="number"
                      onChange={e => updateMaxSelect(parseInt(e.target.value))}
                      value={answerParts.select.config.max_selected}
                      autoFocus
                      size={"sm"}
                      width={"30%"}
                      height={"inherit"}
                    />
                  </HStack>
                )}
              </HStack>
            </RadioGroup>
          </FormControl>
          <FormControl>
            <Stack spacing={2}>
              <Grid templateColumns="1fr 50px 50px" gap={2}>
                <GridItem gridColumn={2} m="auto">
                  <Tooltip label={"Allow other text"} placement={"end"}>
                    <Icon marginX="1" as={InformationCircleIcon} color="gray.400" />
                  </Tooltip>
                </GridItem>
                <GridItem />
                {answerParts.select.config.options.map((x, n) => (
                  <Fragment key={n}>
                    <GridItem>
                      <Input
                        type={"text"}
                        bg="white"
                        isRequired
                        flex={1}
                        fontSize={"smaller"}
                        value={x.label}
                        onChange={e => {
                          const newOptions = [...answerParts.select.config.options];
                          newOptions[n] = {...x, value: e.target.value, label: e.target.value};
                          updateSelectOptions(newOptions);
                        }}
                      />
                    </GridItem>
                    <GridItem m="auto">
                      <Switch
                        isChecked={answerParts.select.config.options[n].allow_other_text}
                        onChange={e => {
                          const newOptions = [...answerParts.select.config.options];
                          newOptions[n] = {...x, allow_other_text: e.target.checked};
                          updateSelectOptions(newOptions);
                        }}
                      />
                    </GridItem>
                    <GridItem m="auto">
                      <CloseButton
                        size="sm"
                        variant={"ghost"}
                        color={"indianred"}
                        onClick={() => {
                          const newOptions = [...answerParts.select.config.options];
                          updateSelectOptions(newOptions.filter((_option, i) => i !== n));
                        }}
                      />
                    </GridItem>
                  </Fragment>
                ))}
              </Grid>
              <Button
                width={"fit-content"}
                onClick={addNewSelectOption}
                variant={"ghost"}
                size="sm"
                leftIcon={<Icon as={PlusIcon} />}
              >
                Add option
              </Button>
            </Stack>
          </FormControl>
        </TabPanel>
        <TabPanel>
          <FormControl paddingX={4}>
            <WordCountInput
              value={answerParts.text.config.word_count_limit}
              onCommit={async s =>
                setAnswerParts(_.set(_.cloneDeep(answerParts), ["text", "config", "word_count_limit"], s))
              }
              isDisabled={status === QuestionStatusT.Automating}
            />
          </FormControl>
        </TabPanel>
        <TabPanel color="gray.500">No configuration</TabPanel>
      </TabPanels>
    </Tabs>
  );
}

export default AnswerPartsEditor;
