import TabsOutlet from "../../../../components/RouterTabs/Outlet.tsx";
import {withSuspense} from "../../../../state/withSuspense.tsx";
import {Flex, Heading} from "@chakra-ui/react";
import Page from "../../../../components/Page.tsx";
import {useQueryData} from "../../../../state/index.ts";
import {useAccountFeatures} from "../../../../hooks/accountFeatures.ts";
import {AccountFeature} from "../../../../Types.ts";

const IntegrationsPage = withSuspense(() => {
  const whoami = useQueryData({queryKey: ["whoAmI"]});
  const {enabledFeatures} = useAccountFeatures();
  const enabledTabs = ["document-sources", "crm-connections", "notification-channels"];
  if (enabledFeatures[AccountFeature.TrustCenter]) {
    enabledTabs.push("esignature-providers");
  }
  if (whoami.internal_mode) {
    enabledTabs.push("question-sources");
  }

  return (
    <Page title="Integrations" display="flex" flexDirection="column">
      <Flex p="8" pb="6">
        <Heading size="md" p="0">
          Integrations
        </Heading>
      </Flex>
      <TabsOutlet enabledTabs={enabledTabs} />
    </Page>
  );
});

export default IntegrationsPage;
