import {Button, ButtonProps, Icon} from "@chakra-ui/react";
import {UserIcon} from "@heroicons/react/24/outline";
import {useCallback} from "react";

import {getFrontendEnvironment} from "../../utils/environment";

export const AuthButton = ({
  buttonType,
  ...props
}: {
  buttonType: "Login" | "Register";
} & ButtonProps) => {
  const {app_url} = getFrontendEnvironment();
  const doLogin = useCallback(() => {
    const params = new URLSearchParams({external_redirect: window.location.href});
    if (buttonType === "Register") {
      params.set("auth_action", "register");
    }
    window.location.assign(`${app_url}/consent?${params}`);
  }, [app_url, buttonType]);

  return <Button onClick={doLogin} leftIcon={<Icon as={UserIcon} />} fontSize="md" size="sm" {...props} />;
};
