import {redirect, Route} from "react-router-dom";
import Layout from "./Layout";
import AllFactsPage from "./pages/AllFacts";
import ScopePage from "./pages/Scope";
import SectionPage from "./pages/Section";
import GlobalScopePage from "./pages/GlobalScope";
import ReviewItemsPage from "./pages/ReviewItems";
import ReviewItemPage from "./pages/ReviewItem";
import ResolvedReviewItemsPage from "./pages/ResolvedReviewItems";
import IgnoredReviewItemsPage from "./pages/IgnoredReviewItems";

export default (
  <>
    <Route id="FactLibrary" path="facts" handle={{crumb: "Fact library"}} Component={Layout}>
      <Route index loader={() => redirect("all")} />
      <Route path="all" element={<AllFactsPage />} />
      <Route path="review-items" element={<ReviewItemsPage />} />
      <Route path="review-items/:resolutionId" element={<ReviewItemPage />} />
      <Route path="resolved-review-items" element={<ResolvedReviewItemsPage />} />
      <Route path="ignored-review-items" element={<IgnoredReviewItemsPage />} />
      <Route path="scope/global" element={<GlobalScopePage />} />
      <Route path="scope/global/uncategorized" element={<GlobalScopePage recurse={false} />} />
      <Route path="scope/:id" element={<ScopePage />} />
      <Route path="scope/:id/uncategorized" element={<ScopePage recurse={false} />} />
      <Route path="section/:id" element={<SectionPage />} />
      <Route path="section/:id/uncategorized" element={<SectionPage recurse={false} />} />
    </Route>
  </>
);
