import {useCallback, useRef} from "react";

// Committing a value is asynchronous, so if the user is quick they might make multiple
// changes before the first change has finished committing. This hook allows multiple changes
// to be queued up safely.
export function useSequential<T>(
  currentValue: T,
  commit: (newValue: T) => Promise<unknown>,
): (cb: (oldValue: T) => T) => Promise<void> {
  // The most recently committed value
  // We can't rely on `currentValue` since multiple changes can be
  // queued up without an intervening re-render.
  const value = useRef(currentValue);
  value.current = currentValue;
  // A promise which resolves when all currently queued changes have
  // been committed.
  const promise = useRef(Promise.resolve());

  // Return a function that can be called to apply a change
  return useCallback(
    async (cb: (oldValue: T) => T) => {
      // Replace the last promise with a new one for our current change
      const lastPromise = promise.current;
      promise.current = (async () => {
        // Wait for all previous changes to be committed
        try {
          await lastPromise;
        } catch {
          // Already handled
        }
        // Apply our new change
        const newValue = cb(value.current);
        await commit(newValue);
        value.current = newValue;
      })();

      // Wait for this change to be committed
      await promise.current;
    },
    [commit],
  );
}
