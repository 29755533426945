import {Box, Button, Checkbox, Icon, Link, Stack, Text, Tooltip, useToken} from "@chakra-ui/react";
import {Fragment} from "react";
import {ArrowDownTrayIcon} from "@heroicons/react/20/solid";
import {COLUMN_GROUPS, Column, ColumnHeading, TemplateState} from "./templateState";

const CELL_COLOR = "gray.100";
const CELL_BORDER = "gray.300";

function encodeString(string?: string | null): string {
  if (!string) return "";
  const escaped = string.replaceAll('"', '""');
  return `"${escaped}"`;
}

function encodeRow(values: string[]): string {
  return values.join(",") + "\n";
}

const templateExamples: {values: {[name in ColumnHeading]?: string}; requires?: ColumnHeading[]}[] = [
  {
    values: {
      SectionTitle: "Section A - Company information",
      Number: "A.1",
      Question: "Name of company?",
      Guidance: "Give the full legal company name.",
      Parts: "text",
      ResponseText: "ExampleCorp Ltd",
    },
  },
  {
    values: {
      SectionTitle: "Section A - Company information",
      Number: "A.2",
      Question: "Does the company have more than 100 employees?",
      Parts: "yesno",
      ResponseYesNo: "No",
    },
  },
  {
    values: {
      SectionTitle: "Section A - Company information",
      Number: "A.3",
      Question: "Type of company?",
      Parts: "single-select",
      SelectOptions: "Private Limited Company\nPublic Limited Company\nOther",
      ResponseSelect: "Other",
    },
    requires: ["SelectOptions"],
  },
  {
    values: {
      SectionTitle: "Section A - Company information",
      Number: "A.4",
      Question: "Which jurisdictions does the company operate in?",
      Parts: "multi-select",
      SelectOptions: "UK\nUS\nEU\nOther",
      ResponseSelect: "US\nOther",
    },
    requires: ["SelectOptions"],
  },
  {
    values: {
      SectionTitle: "Section B - Certifications",
      Number: "B.1",
      Question: "Does your company have an ISO27001 certification? If yes, please attach it.",
      Parts: "yesno\nfile",
    },
  },
];

const encodeCSV = (columns: Column[]) => {
  const enabledColumns = columns.filter(col => col.enabled);
  let csvContent = "\ufeff";
  csvContent += encodeRow(enabledColumns.map(col => encodeString(col.heading)));
  for (const example of templateExamples) {
    if (example.requires?.some(hd => !enabledColumns.find(c => c.heading === hd))) {
      // Skip examples where a required column is missing from the export
      continue;
    }
    const row = enabledColumns.map(col => encodeString(example.values[col.heading]));
    csvContent += encodeRow(row);
  }

  return `data:text/csv;charset=utf-8,${encodeURIComponent(csvContent)}`;
};

const TemplateStep = ({enabledColumns, setEnabledColumns}: TemplateState) => {
  const [cellColorHex, cellBorderHex] = useToken("colors", [CELL_COLOR, CELL_BORDER]);
  return (
    <>
      <Box fontSize="md">Select the columns you want to include in the template.</Box>
      <Box
        display="grid"
        border="1px solid"
        borderColor={CELL_BORDER}
        shadow="lg"
        gridAutoFlow="row"
        textAlign="center"
      >
        {COLUMN_GROUPS.map((group, i) => {
          const optionalColumns = group.columns.filter(col => !col.required);
          const allChecked = optionalColumns.every(col => enabledColumns.find(c => c.heading === col.heading)!.enabled);
          const anyChecked = optionalColumns.some(col => enabledColumns.find(c => c.heading === col.heading)!.enabled);
          return (
            <Fragment key={i}>
              <Tooltip hasArrow label={group.tooltip} placement="top" maxW="500px">
                <Stack
                  as="label"
                  gridRow={1}
                  gridColumn={`span ${group.columns.length}`}
                  align="center"
                  userSelect="none"
                  cursor="pointer"
                  p={2}
                  borderLeft={i > 0 ? "1px solid" : undefined}
                  borderColor={CELL_BORDER}
                >
                  <Text>{group.title}</Text>
                  <Checkbox
                    isChecked={anyChecked || allChecked}
                    isIndeterminate={anyChecked && !allChecked}
                    onChange={e =>
                      setEnabledColumns(prev =>
                        prev.map(col =>
                          optionalColumns.some(c => c.heading === col.heading)
                            ? {...col, enabled: e.target.checked}
                            : col,
                        ),
                      )
                    }
                  />
                </Stack>
              </Tooltip>
              {group.columns.map((col, j) => (
                <Tooltip hasArrow key={j} label={col.tooltip} gridRow={2}>
                  <Stack
                    as="label"
                    fontSize="sm"
                    align="center"
                    userSelect="none"
                    cursor="pointer"
                    bg={CELL_COLOR}
                    p={2}
                    borderLeft={i > 0 || j > 0 ? "1px solid" : undefined}
                    borderColor="gray.300"
                    sx={{
                      borderImage:
                        j > 0 ? `linear-gradient(to bottom, ${cellColorHex}, ${cellBorderHex}) 1 100%` : undefined,
                    }}
                  >
                    <Text>{col.heading}</Text>
                    <Checkbox
                      isChecked={enabledColumns.find(c => c.heading === col.heading)!.enabled}
                      isDisabled={col.required}
                      onChange={e =>
                        setEnabledColumns(prev =>
                          prev.map(c => (c.heading === col.heading ? {...c, enabled: e.target.checked} : c)),
                        )
                      }
                    />
                  </Stack>
                </Tooltip>
              ))}
            </Fragment>
          );
        })}
      </Box>
      <Box>
        <Button
          as={Link}
          leftIcon={<Icon as={ArrowDownTrayIcon} />}
          colorScheme="blue"
          href={encodeCSV(enabledColumns)}
          download={`platformed-template.csv`}
        >
          Download template
        </Button>
      </Box>
    </>
  );
};

export default TemplateStep;
