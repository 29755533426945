import {Button} from "@chakra-ui/react";

type Props = {
  expanded: boolean;
  onChange: (expanded: boolean) => void;
};

function ShowMoreLessToggle({expanded, onChange}: Props) {
  return (
    <Button
      size="sm"
      variant="link"
      onClick={() => onChange(!expanded)}
      textDecoration="underline"
      alignSelf="flex-start"
    >
      {expanded ? "Show less" : "Show more"}
    </Button>
  );
}

export default ShowMoreLessToggle;
