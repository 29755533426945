import {useDisclosure} from "@chakra-ui/react";
import {usePromiseState} from "../../../../hooks/promiseState";
import {DeleteModalIconButton} from "../../../../components/DeleteModalButton";
import {GraphOperationsBuilder} from "../../graph/operationsBuilder";
import api from "../../../../api";
import {Subprocessor} from "../../graph/types/subprocessor";

export const DeleteSubprocessorButton = ({subprocessor}: {subprocessor: Subprocessor}) => {
  const {isOpen, onOpen, onClose} = useDisclosure();

  const [deletingSubprocessor, deleteSubprocessor] = usePromiseState(async () => {
    const operations = new GraphOperationsBuilder().deleteEntity(subprocessor.id, {recursive: true}).build();

    await api.vendorToolkit.graph.update(operations);

    onClose();
  }, [subprocessor.id, onClose]);

  return (
    <DeleteModalIconButton
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      delete_={deleteSubprocessor}
      deleting={deletingSubprocessor.inProgress}
      modalHeader={`Delete subprocessor ${subprocessor.name}`}
      modalBody={`Are you sure you want to delete the subprocessor ${subprocessor.name}?`}
    />
  );
};
