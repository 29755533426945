import api from "../../../api";
import {AssetId, ListAssets} from "../../../Types";
import {param, typedQuery} from "../../typing";

export default [
  typedQuery(["vendorToolkit", "assets", param<ListAssets>("query")], async context => {
    return await api.vendorToolkit.assets.list(context.queryKey[2]);
  }),
  typedQuery(["vendorToolkit", "asset", param<AssetId>("assetId")], async context => {
    return await api.vendorToolkit.assets.get(context.queryKey[2]);
  }),
];
