import {Navigate, Route} from "react-router-dom";
import libraryRoutes from "./Library";
import configurationRoutes from "./Configuration";
import taskRoutes from "./Tasks";
import questionnaireRoutes from "./Questionnaires";
import questionRoutes from "./Questions";
import trustCenterRoutes from "./TrustCenter";
import ProductFrame from "../ProductFrame";
import NavLinks from "./NavLinks";
import {AccountFeature} from "../../Types";

export default (
  <Route
    path="vendor-toolkit"
    element={
      <ProductFrame bgBaseColor="gray" productFeature={AccountFeature.VendorToolkit}>
        <NavLinks />
      </ProductFrame>
    }
  >
    {configurationRoutes}
    {libraryRoutes}
    {taskRoutes}
    {questionnaireRoutes}
    {questionRoutes}
    {trustCenterRoutes}
    <Route index element={<Navigate to="tasks" replace />} handle={{crumb: "My tasks"}} />
  </Route>
);
