import {Box} from "@chakra-ui/react";
import SidePane from "../../../components/SidePane";
import {AdHocQuestion, QuestionId} from "../../../Types";
import {useQueriesData} from "../../../state";
import {useFilterState} from "../../../hooks/filterState";
import {AdHocQuestionFilters, facetedSearchAdHocQuestion} from "../../components/Filters";
import {useFacetedSearch} from "../../../hooks/search";
import {useMemo} from "react";
import QuestionNav from "./QuestionNav";
import {DEFAULT_FILTERS} from ".";
import {useLayerType} from "../../../hooks/layerType";

const nextQuestion = (
  reverse: boolean,
  baseQuestionId: QuestionId,
  filteredQuestions: AdHocQuestion[],
  unfilteredQuestions: AdHocQuestion[],
): AdHocQuestion | undefined => {
  const visibleQuestionIds = new Set(filteredQuestions.map(({question}) => question.question_id));

  const currentIdx = unfilteredQuestions.findIndex(({question}) => question.question_id === baseQuestionId);
  const candidateQuestions = reverse
    ? unfilteredQuestions.slice(0, currentIdx).reverse()
    : unfilteredQuestions.slice(currentIdx + 1);
  return candidateQuestions.find(({question}) => visibleQuestionIds.has(question.question_id));
};

export const QuestionSidePane = ({questionId}: {questionId: QuestionId}) => {
  const [layerType] = useLayerType();
  const [adhocQuestions, whoami] = useQueriesData({
    queries: [{queryKey: ["vendorToolkit", "adhocQuestions"]}, {queryKey: ["whoAmI"]}],
  });
  const {filters, clearFilters, filterCount} = useFilterState<AdHocQuestionFilters>(
    "adhocQuestionFilters",
    DEFAULT_FILTERS,
  );

  // Using the facetedSearch hook here as it ensures the logic is the same as the main question page
  const {filter} = useFacetedSearch(
    q => q.question.text,
    facetedSearchAdHocQuestion(filters, layerType, whoami.associated_owners),
    [filters],
  );

  const {result} = useMemo(() => filter(adhocQuestions), [adhocQuestions, filter]);
  const next = nextQuestion(false, questionId, result, adhocQuestions);
  const prev = nextQuestion(true, questionId, result, adhocQuestions);

  return (
    <SidePane>
      <Box overflow="hidden">
        <QuestionNav
          questionId={questionId}
          clearFilters={clearFilters}
          filterCount={filterCount}
          adhocQuestions={result}
          prev={prev}
          next={next}
        />
      </Box>
    </SidePane>
  );
};
