import {
  InputGroup,
  TextareaProps,
  Textarea,
  FormControl,
  FormErrorMessage,
  FormControlProps,
  InputGroupProps,
} from "@chakra-ui/react";
import {CommitTextProps, useCommitText} from "../hooks/commitText";
import {InputSpinner} from "./InputSpinner";
import {memo} from "react";

type CommitTextareaProps = CommitTextProps &
  TextareaProps & {formControlProps?: FormControlProps; inputGroupProps?: InputGroupProps};

const CommitTextarea = memo(
  ({onCommit, value, preCommit, inputGroupProps, formControlProps, ...props}: CommitTextareaProps) => {
    const {currentValue, inputRef, onChange, committing} = useCommitText(value, onCommit, preCommit);

    return (
      <FormControl isInvalid={!!committing.lastError} {...formControlProps}>
        <InputGroup {...inputGroupProps}>
          <Textarea ref={inputRef} value={currentValue} onChange={onChange} {...props} />
          <InputSpinner inProgress={committing.inProgress} />
        </InputGroup>
        <FormErrorMessage>{committing.lastError?.toString()}</FormErrorMessage>
      </FormControl>
    );
  },
);

export default CommitTextarea;
