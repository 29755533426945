import {Route} from "react-router-dom";
import ProductFrame from "../ProductFrame";
import NavLinks from "./NavLinks";
import {AccountFeature} from "../../Types";

export default (
  <Route
    path="tprm"
    element={
      <ProductFrame bgBaseColor="blue" productFeature={AccountFeature.TPRM}>
        <NavLinks />
      </ProductFrame>
    }
  ></Route>
);
