import {Box, Icon, Img} from "@chakra-ui/react";
import {DocumentIcon} from "@heroicons/react/20/solid";

import {File as FileT} from "../../../../Types";
import {addAccountId} from "../../../../api/jsonApi";
import PDF from "./Pdf";
import Docx from "./Docx";

export const PreviewFile = ({file}: {file: FileT}) => {
  const downloadUrl = addAccountId(`/api/files/${file.file_id}/download`);
  switch (file.mime_type) {
    case "application/pdf":
      // Prevent preview from appearing on top of other elements
      return <PDF downloadUrl={downloadUrl} position="relative" zIndex="base" />;
    case "image/jpeg":
    case "image/png":
      return (
        <Box>
          <Img src={downloadUrl} />
        </Box>
      );
    case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      // Prevent preview from appearing on top of other elements
      return <Docx downloadUrl={downloadUrl} position="relative" zIndex="base" />;
    default:
      return (
        <Box p={8} fontWeight="500" color="gray.500">
          <Icon as={DocumentIcon} display="block" fontSize="4xl" color="blue.300" mb={3} />
          No preview available.
        </Box>
      );
  }
};
