import {DependencyList, useCallback, useMemo, useState} from "react";

function escapeRegExp(s: string) {
  return new RegExp(s.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"), "i"); // $& means the whole matched string
}

export type Facet<T, V> = {
  extract: (item: T) => V;
  predicate: (value: V) => boolean;
  name: (value: V) => string | string[];
};

export type Facets<T> = {
  [key: string]: Facet<T, any>;
};

export type Counts = {
  [name: string]: number;
};

export type AllCounts = {
  [key: string]: Counts;
};

export function facetedSearch<T>(items: T[], facets: Facets<T>, counts: AllCounts = {}) {
  const result: T[] = [];

  for (const item of items) {
    const facetIncludes: {[key: string]: boolean} = {};
    Object.entries(facets).forEach(([facet, f]) => {
      facetIncludes[facet] = f.predicate(f.extract(item));
    });

    const fail = Object.keys(facetIncludes).filter(k => !facetIncludes[k]);
    if (fail.length === 0) {
      result.push(item);
    }
    if (fail.length <= 1) {
      Object.entries(facets).forEach(([facet, f]) => {
        // If nothing failed, or just the facet about to be counted failed, include in the count
        if (fail.length === 0 || facet === fail[0]) {
          const value = f.extract(item);
          let names = f.name(value);
          if (!Array.isArray(names)) {
            names = [names];
          }
          if (!Object.hasOwn(counts, facet)) {
            counts[facet] = {};
          }
          for (const name of names) {
            if (Object.hasOwn(counts[facet], name)) {
              counts[facet][name] += 1;
            } else {
              counts[facet][name] = 1;
            }
          }
        }
      });
    }
  }
  return {result, counts};
}

// Provides standardised string search functionality on generic objects T
// query/setQuery are strings to drive a search box
// queries can be used to drive highlights
// search should be used to .filter an iterable over T.
//
// getSearchKey - the string to search against for a given object
// secondaryMatcher - Additional filters (e.g. an "Owner" facet) should be handled in this fn
//
// IMPORTANT: You should explicitly pass any captured variables of getSearchKey and secondaryMatcher in deps
export default function useSearch<T>(
  getSearchKey: (item: T) => string,
  secondaryMatcher?: (item: T) => boolean,
  deps: DependencyList = [],
) {
  const [query, setQuery] = useState("");
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const queries = useMemo(() => query.split(/\s/), [query, ...deps]);

  const search = useMemo(() => {
    const queryRegexes = queries.map(escapeRegExp);
    return (item: T) =>
      (secondaryMatcher ? secondaryMatcher(item) : true) && queryRegexes.every(regex => regex.test(getSearchKey(item)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queries, ...deps]);

  return {query, queries, setQuery, search};
}

// Similar to useSearch above, but takes a list of search facets and returns a 'filter' function which itself
// returns both the result set and the counts of items eliminated by each facet, useful for displaying a filter selection dialog.
export function useFacetedSearch<T>(
  getSearchKey: ((item: T) => string) | null,
  facets: Facets<T>,
  deps: DependencyList = [],
) {
  const [query, setQuery] = useState("");
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const queries = useMemo(() => query.split(/\s/), [query, ...deps]);
  const queryRegexes = useMemo(() => queries.map(escapeRegExp), [queries]);

  const filter = useCallback(
    (items: T[]) => {
      // Pre-filter anything that doesn't match the regex
      const intermediate = getSearchKey
        ? items.filter(item => queryRegexes.every(regex => regex.test(getSearchKey(item))))
        : items;
      return facetedSearch(intermediate, facets);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [queryRegexes, ...deps],
  );

  const filterGroups = useCallback(
    <K extends string, U extends {[key in K]: T[]}>(groups: U[], key: K): {result: U[]; counts: AllCounts} => {
      const counts: AllCounts = {};
      const result = groups.map(group => {
        const items = group[key];
        // Pre-filter anything that doesn't match the regex
        const intermediate = getSearchKey
          ? items.filter(item => queryRegexes.every(regex => regex.test(getSearchKey(item))))
          : items;
        const filteredItems = facetedSearch(intermediate, facets, counts).result;
        return {
          ...group,
          [key]: filteredItems,
        };
      });
      return {result, counts};
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [queries, ...deps],
  );

  return {query, queries, setQuery, filter, filterGroups};
}
