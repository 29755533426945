import api from "../../../../api/index.ts";
import Page from "../../../../components/Page.tsx";
import {Flex, FormControl, FormLabel, Heading, Menu, Stack} from "@chakra-ui/react";
import {useQueryData} from "../../../../state/index.ts";
import {PromptModifiers} from "../../../../Types.ts";
import {usePromiseState} from "../../../../hooks/promiseState.ts";
import ModifiersMenuList from "../../components/PromptModifiers/ModifiersMenuList.tsx";

const AiPreferencesPage = () => {
  const whoami = useQueryData({queryKey: ["whoAmI"]});
  const promptModifiers = whoami.account!.prompt_modifiers;

  const [updatingPromptModifiers, updatePromptModifiers] = usePromiseState(async (promptModifiers: PromptModifiers) => {
    await api.config.updatePromptModifiers(promptModifiers);
  }, []);

  return (
    <Page title="AI preferences" display="flex" flexDirection="column">
      <Flex p="8" pb="6" justifyContent="space-between">
        <Heading size="md" p="0">
          AI preferences
        </Heading>
      </Flex>
      <Stack px="8" maxW="96" spacing={10} mb={10}>
        <FormControl width="130%">
          <FormLabel>Questionnaire answering preferences</FormLabel>
          <Menu isOpen closeOnSelect={false}>
            <ModifiersMenuList
              value={promptModifiers}
              onChange={updatePromptModifiers}
              isDisabled={updatingPromptModifiers.inProgress}
              staticLayout={true}
            />
          </Menu>
        </FormControl>
      </Stack>
    </Page>
  );
};

export default AiPreferencesPage;
