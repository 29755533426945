import {UserPlusIcon} from "@heroicons/react/24/solid";
import {memo} from "react";
import {AddOrEditPersonModal} from "./AddOrEditPersonModal";
import {Button, Icon, useDisclosure} from "@chakra-ui/react";
import {SubGraph, EntityId} from "../../../../../Types";

type Props = {
  graph: SubGraph;

  /** Parent entity to create relationships with. */
  parentEntityId: EntityId;
};

const AddPersonButton = memo((props: Props) => {
  const {isOpen, onOpen, onClose} = useDisclosure();

  return (
    <>
      <Button leftIcon={<Icon as={UserPlusIcon} />} onClick={onOpen} colorScheme="green">
        Add person
      </Button>
      <AddOrEditPersonModal
        kind="add"
        isOpen={isOpen}
        onClose={onClose}
        graph={props.graph}
        parentEntityId={props.parentEntityId}
      />
    </>
  );
});

export default AddPersonButton;
