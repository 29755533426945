import {NewCrmConnection} from "./NewCrmConnection.tsx";
import {ScaleIcon} from "@heroicons/react/20/solid";
import {Route} from "react-router-dom";
import {ConfigureCrmConnection} from "./ConfigureCrmConnection.tsx";
import CrmConnectionsPage from "./CrmConnectionsPage.tsx";
import Crumb from "./Crumb.tsx";

export const crmUrlFromUri = (uri: string) => {
  if (uri.startsWith("hubspot")) {
    return uri.replace("hubspot://", "https://app.hubspot.com/");
  }
  return null;
};

export default {
  title: "CRM connections",
  path: "crm-connections",
  icon: ScaleIcon,
  routes: (
    <>
      <Route path="" element={<CrmConnectionsPage />} />
      <Route path="new/:connectorId" element={<NewCrmConnection />} />
      <Route path="configure/:crmConnectionId" element={<ConfigureCrmConnection />} handle={{Crumb}} />
    </>
  ),
};
