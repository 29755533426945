import {useMemo} from "react";
import {useQueryData} from "../../../../../state";
import {LibrarySectionId, LibrarySectionMin, ScopeAxisId, ScopeAxisMin, ScopeId, ScopeMin} from "../../../../../Types";
import * as _ from "lodash-es";

export const GLOBAL_AXIS_ID = "global";
export const GLOBAL_SCOPE_ID = "global";

/**
 * A hierarchy of axes, scopes and sections.
 *
 * Each node type within the hierarchy stores the backend representation of the
 * object, as well as links to children and parent nodes.
 *
 * This data structure is used to render the side bar in the Fact library,
 * calculate the fully qualified names of sections, etc.
 */
export type Hierarchy = {
  axes: AxisNode[];
};

export type Node = AxisNode | ScopeNode | SectionNode;
export type SectionParentNode = ScopeNode | SectionNode;

export type AxisNode = {
  type: "axis";

  /** `null` for the global axis. */
  obj: ScopeAxisMin | null;

  children: ScopeNode[];
};

export type ScopeNode = {
  type: "scope";

  /** `null` for the global scope. */
  obj: ScopeMin | null;

  parent: AxisNode;
  children: SectionNode[];
};

export type SectionNode = {
  type: "section";
  obj: LibrarySectionMin;

  parent: SectionParentNode;
  children: SectionNode[];
};

type BuildHierarchyOpts = {
  scopeAxes: ScopeAxisMin[];
  scopes: ScopeMin[];
  librarySections: LibrarySectionMin[];
};

export type ScopeIdOrGlobal = ScopeId | typeof GLOBAL_SCOPE_ID;
export type AxisIdOrGlobal = ScopeAxisId | typeof GLOBAL_AXIS_ID;

/**
 * Build a data structure for the hierarchy of scope axes, scopes, and sections
 * that is consumed by the rest of the Fact library UI.
 */
export function buildHierarchy({scopeAxes, scopes, librarySections}: BuildHierarchyOpts): Hierarchy {
  const sectionsMap = new Map<LibrarySectionId, SectionNode>();
  const scopesMap = new Map<ScopeIdOrGlobal, ScopeNode>();
  const axesMap = new Map<AxisIdOrGlobal, AxisNode>();

  for (const section of librarySections) {
    sectionsMap.set(section.library_section_id, {
      type: "section",
      obj: section,
      children: [],
      parent: undefined!, // set in second iteration
    });
  }

  for (const scope of scopes) {
    scopesMap.set(scope.scope_id, {
      type: "scope",
      obj: scope,
      parent: undefined!, // set in second iteration
      children: [],
    });
  }

  for (const axis of scopeAxes) {
    axesMap.set(axis.axis_id, {
      type: "axis",
      obj: axis,
      children: [],
    });
  }

  // Add global axis and scope. These don't exist in the backend, but we assign
  // sections that have no parent scope to the global scope.
  const globalAxis: AxisNode = {
    type: "axis",
    obj: null,
    children: [],
  };

  const globalScope: ScopeNode = {
    type: "scope",
    obj: null,
    children: [],
    parent: globalAxis,
  };

  globalAxis.children.push(globalScope);

  scopesMap.set(GLOBAL_SCOPE_ID, globalScope);
  axesMap.set(GLOBAL_AXIS_ID, globalAxis);

  // Populate section parents
  for (const section of librarySections) {
    const self = sectionsMap.get(section.library_section_id)!;

    // Parent is section
    if (section.parent_section_id != null) {
      const parent = sectionsMap.get(section.parent_section_id)!;
      parent.children.push(self);
      self.parent = parent;
    }
    // Parent is scope
    else {
      const parent = section.parent_scope != null ? scopesMap.get(section.parent_scope.scope_id)! : globalScope;

      parent.children.push(self);
      self.parent = parent;
    }
  }

  // Populate scope parents
  for (const scope of scopes) {
    const self = scopesMap.get(scope.scope_id)!;
    const parent = axesMap.get(scope.axis.axis_id)!;
    parent.children.push(self);
    self.parent = parent;
  }

  const axes = [...axesMap.values()].sort((a, b) => {
    if (a.obj == null) return -1;
    if (b.obj == null) return 1;

    return a.obj.name.localeCompare(b.obj.name);
  });

  return {
    axes,
  };
}

/**
 * Find a scope by ID.
 */
export function findScopeById(hierarchy: Hierarchy, scopeId: ScopeIdOrGlobal): ScopeNode | undefined {
  for (const axis of hierarchy.axes) {
    for (const scope of axis.children) {
      if ((scopeId === GLOBAL_SCOPE_ID && scope.obj == null) || (scope.obj != null && scope.obj.scope_id === scopeId)) {
        return scope;
      }
    }
  }

  return undefined;
}

/**
 * Find a section by ID.
 */
export function findSectionById(hierarchy: Hierarchy, sectionId: LibrarySectionId): SectionNode | undefined {
  function recurseSections(sections: SectionNode[], sectionId: LibrarySectionId): SectionNode | undefined {
    for (const section of sections) {
      if (section.obj.library_section_id === sectionId) {
        return section;
      }

      const found = recurseSections(section.children, sectionId);
      if (found) return found;
    }

    return undefined;
  }

  for (const axis of hierarchy.axes) {
    for (const scope of axis.children) {
      const found = recurseSections(scope.children, sectionId);
      if (found) return found;
    }
  }

  return undefined;
}

/**
 * The fully qualified name of a section is the name of the section including
 * the names of all of its parents.
 *
 * e.g. "AcmeCorp Ltd > Engineering > Software"
 */
export function getSectionFullyQualifiedName(section: SectionNode): string {
  const parts = [section.obj.name];

  let current: SectionParentNode | undefined = section.parent;

  while (current !== undefined) {
    const name = getNodeName(current);
    parts.unshift(name);

    if (current.type === "section") {
      current = current.parent;
    } else {
      current = undefined;
    }
  }

  return parts.join(" > ");
}

export function getNodeKey(node: Node): string {
  switch (node.type) {
    case "axis":
      return getAxisId(node);
    case "scope":
      return getScopeId(node);
    case "section":
      return getSectionId(node);
  }
}

export function getAxisId(node: AxisNode): AxisIdOrGlobal {
  return node.obj?.axis_id ?? GLOBAL_AXIS_ID;
}

export function getScopeId(node: ScopeNode): ScopeIdOrGlobal {
  return node.obj?.scope_id ?? GLOBAL_SCOPE_ID;
}

export function getSectionId(node: SectionNode): LibrarySectionId {
  return node.obj.library_section_id;
}

export function getNodeName(node: Node): string {
  switch (node.type) {
    case "axis":
      return node.obj?.name ?? "Global";
    case "scope":
      return node.obj?.name ?? "Global";
    case "section":
      return node.obj.name;
  }
}

export function isGlobalAxis(axis: AxisNode): boolean {
  return axis.obj === null;
}

export function getParentScope(node: SectionParentNode): ScopeNode {
  let currentNode: SectionParentNode = node;

  while (currentNode.type !== "scope") {
    currentNode = currentNode.parent;
  }

  return currentNode;
}

export function useHierarchy() {
  const scopeAxes = useQueryData({queryKey: ["vendorToolkit", "scopeAxes"]});
  const scopes = useQueryData({queryKey: ["vendorToolkit", "scopes"]});
  const librarySections = useQueryData({queryKey: ["vendorToolkit", "librarySections"]});

  const hierarchy = useMemo(
    () => buildHierarchy({scopeAxes, scopes, librarySections}),
    [scopeAxes, scopes, librarySections],
  );

  return hierarchy;
}
