import {useContext, useEffect} from "react";
import {useEventCallback} from "usehooks-ts";
import {PusherCallback, pusherContext} from "./context";

export function usePusher() {
  return useContext(pusherContext);
}

export function useSubscription(channelName: string | null | undefined, eventName: string | null, cb: PusherCallback) {
  const pusher = usePusher();
  const ecb = useEventCallback(cb);
  useEffect(() => {
    if (channelName) {
      return pusher.subscribe(channelName, eventName, ecb);
    }
  }, [pusher, ecb, channelName, eventName]);
}
