import {useEventListener} from "usehooks-ts";

export const useKeyboardShortcuts = (
  shortcuts: {[key: string]: () => void},
  modifiers?: {meta?: boolean; ctrl?: boolean; alt?: boolean; shift?: boolean},
) => {
  useEventListener("keydown", e => {
    if ((modifiers?.alt ?? false) !== e.altKey) {
      return false;
    }
    if ((modifiers?.ctrl ?? false) !== e.ctrlKey) {
      return false;
    }
    if ((modifiers?.meta ?? false) !== e.metaKey) {
      return false;
    }
    if ((modifiers?.shift ?? false) !== e.shiftKey) {
      return false;
    }

    if (shortcuts[e.code]) {
      e.preventDefault();
      e.stopPropagation();
      shortcuts[e.code]();
      return true;
    }

    return false;
  });
};
