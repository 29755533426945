import {Task, GettingStartedChecklist} from "../../Types";
import jsonApi from "../jsonApi";

export async function list() {
  return await jsonApi.get<Task[]>("/vendor_toolkit/tasks");
}

export async function getGettingStartedChecklist() {
  return await jsonApi.get<GettingStartedChecklist>("/vendor_toolkit/getting_started_checklist");
}
