import {InputGroup, InputRightElement, Select, Spinner, TagProps} from "@chakra-ui/react";
import {useQueryData} from "../../../../state";
import {usePromiseState} from "../../../../hooks/promiseState";
import {ChangeEvent, useCallback} from "react";
import {AssetId, AssetOwner, nominate} from "../../../../Types";

const AssetSelector = ({
  assetId,
  onChangeAsset,
}: {
  assetId: AssetId | undefined;
  onChangeAsset?: (assetId: AssetId | undefined) => Promise<void>;
} & TagProps) => {
  const assets = useQueryData({queryKey: ["vendorToolkit", "assets", {owner: AssetOwner.TrustCenter}]});

  const [changing, change] = usePromiseState(
    async (value: string) => {
      if (onChangeAsset) {
        await onChangeAsset(value === "<none>" ? undefined : nominate("assetId", value));
      }
    },
    [onChangeAsset],
  );

  const changeWrapper = useCallback((e: ChangeEvent<HTMLSelectElement>) => change(e.target.value), [change]);

  return (
    <InputGroup>
      <Select
        isDisabled={changing.inProgress}
        value={(changing.inProgress ? changing.lastArgs![0] : assetId) || "<none>"}
        onChange={changeWrapper}
      >
        <option value="<none>">No asset</option>
        {assets.map(asset => (
          <option key={asset.asset_id} value={asset.asset_id}>
            {asset.title}
          </option>
        ))}
      </Select>
      {changing.inProgress && (
        <InputRightElement mr={6}>
          <Spinner color="gray.500" size="sm" />
        </InputRightElement>
      )}
    </InputGroup>
  );
};

export default AssetSelector;
