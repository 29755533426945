import {
  Button,
  HStack,
  Icon,
  MenuItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import {OwnerT, OwnerType, TeamMin} from "../../Types.ts";
import {memo} from "react";
import {TeamPicker, TeamPickerValue} from "../../components/TeamPicker.tsx";
import {useModalState} from "../../hooks/modalState.ts";
import {useDebounceValue} from "usehooks-ts";
import api from "../../api/index.ts";
import {UserGroupIcon} from "@heroicons/react/20/solid";
import {CreateEditTeamModal} from "../../Account/Settings/Teams/CreateEditTeamModal.tsx";
import {usePromiseState} from "../../hooks/promiseState.ts";

export const ReassignTeamModal = memo(
  ({onReassign, isReassigning, text}: {onReassign: (owner?: OwnerT) => void; isReassigning: boolean; text: string}) => {
    const {isOpen, onOpen, onClose} = useDisclosure();
    const {isOpen: isCreateOpen, onOpen: onCreateOpen, onClose: onCreateClose} = useDisclosure();
    const [teamOrCreate, setTeamOrCreate] = useModalState<TeamPickerValue | null>(isOpen, null);

    const [reassigning, reassign] = usePromiseState(async () => {
      if (teamOrCreate?.type === "Team") {
        onClose();
        const owner = await api.owners.resolve([{type: OwnerType.Team, content: teamOrCreate.team.team_id}]);
        onReassign(owner[0]);
      } else if (teamOrCreate?.type === "Create") {
        onCreateOpen();
      }
    }, [onReassign, onClose, onCreateOpen, teamOrCreate]);
    const [reassigningTeam, reassignTeam] = usePromiseState(
      async (team: TeamMin) => {
        onClose();
        const owner = await api.owners.resolve([{type: OwnerType.Team, content: team.team_id}]);
        onReassign(owner[0]);
      },
      [onReassign, onClose],
    );

    // Autofocus doesn't work properly unless we add a delay between closing and re-opening a modal
    const [isCreateOpenDebounce] = useDebounceValue(isCreateOpen, 200);

    return (
      <>
        <MenuItem onClick={onOpen}>
          <Icon as={UserGroupIcon} h="6" mr="2" color="gray.500" />
          {text}
        </MenuItem>
        <Modal isOpen={isOpen && !isCreateOpen && !isCreateOpenDebounce} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Reassign to</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <TeamPicker value={teamOrCreate} onSelect={setTeamOrCreate} onSubmit={reassign} autoFocus />
            </ModalBody>
            <ModalFooter>
              <HStack spacing="3">
                <Button variant="outline" onClick={onClose}>
                  Cancel
                </Button>
                <Button
                  colorScheme="blue"
                  onClick={reassign}
                  isDisabled={!teamOrCreate || reassigning.inProgress || reassigningTeam.inProgress || isReassigning}
                  isLoading={reassigning.inProgress || reassigningTeam.inProgress || isReassigning}
                >
                  {teamOrCreate?.type === "Create" ? "Create and reassign" : "Reassign"}
                </Button>
              </HStack>
            </ModalFooter>
          </ModalContent>
        </Modal>
        <CreateEditTeamModal
          type={"Create"}
          isOpen={isCreateOpenDebounce && isCreateOpen}
          onClose={onCreateClose}
          onCreate={reassignTeam}
        />
      </>
    );
  },
);
