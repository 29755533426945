import {useToken} from "@chakra-ui/react";
import {LayerType, Questionnaire} from "../../../../../Types";
import {ProgressChart} from "../../../components/ProgressChart";
import {AiResult, categorizeQuestionAiResult} from "./ai";
import {getResponseLayer, useLayerType} from "../../../../../hooks/layerType";

const layerOrder = [LayerType.AI, LayerType.Internal, LayerType.External];

const AiPie = ({questionnaire, baseLayer}: {questionnaire: Questionnaire; baseLayer: LayerType}) => {
  const [
    goodAnswerColor,
    goodOmissionColor,
    shouldHaveAnsweredColor,
    shouldNotHaveAnsweredColor,
    badAnswerColor,
    notAttemptedColor,
  ] = useToken("colors", ["green.600", "green.300", "orange.300", "red.300", "red.600", "gray.300"]);
  const [layerType] = useLayerType();
  const aiResults = questionnaire.sections
    .flatMap(section => section.questions)
    .map(q => ({
      currentLayer: getResponseLayer(q.response_layers, layerType),
      aiLayer: getResponseLayer(q.response_layers, baseLayer),
    }))
    .map(categorizeQuestionAiResult);

  if (layerOrder.indexOf(layerType) <= layerOrder.indexOf(baseLayer)) {
    return null;
  }

  return (
    <ProgressChart
      title={`${baseLayer} Performance`}
      groups={[
        {
          label: "Good answer",
          value: aiResults.filter(r => r === AiResult.GoodAnswer).length,
          color: goodAnswerColor,
        },
        {
          label: "Good omission",
          value: aiResults.filter(r => r === AiResult.GoodOmission).length,
          color: goodOmissionColor,
        },
        {
          label: "Should have answered",
          value: aiResults.filter(r => r === AiResult.ShouldHaveAnswered).length,
          color: shouldHaveAnsweredColor,
        },
        {
          label: "Should not have answered",
          value: aiResults.filter(r => r === AiResult.ShouldNotHaveAnswered).length,
          color: shouldNotHaveAnsweredColor,
        },
        {
          label: "Bad answer",
          value: aiResults.filter(r => r === AiResult.BadAnswer).length,
          color: badAnswerColor,
        },
        {
          label: "Not attempted",
          value: aiResults.filter(r => r === AiResult.NotAttempted).length,
          color: notAttemptedColor,
        },
      ]}
    />
  );
};

export default AiPie;
