import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Avatar,
  Badge,
  Flex,
  HStack,
  Img,
  Link,
  Stack,
  Text,
  TextProps,
} from "@chakra-ui/react";
import {memo, useMemo} from "react";
import {ActionBar} from "../../../../components/ActionBar";
import AddSubprocessorButton from "./AddSubprocessorButton";
import {DeleteSubprocessorButton} from "./DeleteSubprocessorButton";
import {EditSubprocessorButton} from "./EditSubprocessorButton";
import {SubGraph, EntityId} from "../../../../Types";
import {getSubprocessorsFromEntityGraph, Subprocessor} from "../../graph/types/subprocessor";
import _ from "lodash";

const Title = (props: TextProps) => <Text fontWeight="semibold" color="gray.600" minW="100" {...props} />;

const SubprocessorRow = memo(
  ({graph, subprocessor, parentEntityId}: {graph: SubGraph; subprocessor: Subprocessor; parentEntityId: EntityId}) => {
    return (
      <AccordionItem>
        <AccordionButton px={4} py={4} _hover={{bg: "gray.50"}} gap={2}>
          <HStack align="start" justifyContent="space-between" width="100%">
            <HStack fontSize="md" spacing={8}>
              <AccordionIcon />
              <Avatar
                h="10"
                w="10"
                name={subprocessor.name}
                flexShrink="0"
                src={`https://logo.clearbit.com/${subprocessor.websiteUrl}`}
              />
              <Text fontSize="md" fontWeight="600">
                {subprocessor.name}
              </Text>
            </HStack>
            <HStack spacing="8px" px={14}>
              <EditSubprocessorButton subprocessor={subprocessor} graph={graph} parentEntityId={parentEntityId} />
              <DeleteSubprocessorButton subprocessor={subprocessor} />
            </HStack>
          </HStack>
        </AccordionButton>
        <AccordionPanel px={8} py={8} borderTop="1px solid" borderTopColor="gray.200">
          <Stack width={"80%"}>
            <HStack fontSize="md" gap={14}>
              <Title>Website:</Title>
              <Link href={subprocessor.websiteUrl} color="blue.500" target="_blank">
                {subprocessor.websiteUrl}
              </Link>
            </HStack>
            <HStack fontSize="md" gap={14}>
              <Title>Description:</Title>
              <Text fontSize="md">{_.truncate(subprocessor.description, {length: 200})}</Text>
            </HStack>
            <HStack fontSize="md" gap={14} alignItems="flex-start">
              <Title>Shared data:</Title>
              <Stack>
                <Text fontSize="md">{_.truncate(subprocessor.sharedData, {length: 200})}</Text>
              </Stack>
            </HStack>
            <HStack fontSize="md" gap={14}>
              <Title>GDPR:</Title>
              <HStack gap={4}>
                {subprocessor.gdprSccs && <Badge colorScheme="green">SCCs</Badge>}
                {subprocessor.gdprBasedInEurope && <Badge colorScheme="blue">Based in Europe</Badge>}
                {subprocessor.gdprEquivalence && <Badge colorScheme="green">Equivalency ruling</Badge>}
              </HStack>
            </HStack>
            <HStack fontSize="md" gap={14}>
              <Title>Trust center URL:</Title>
              <Link href={subprocessor.trustCenterUrl} color="blue.500" target="_blank">
                {subprocessor.trustCenterUrl}
              </Link>
            </HStack>
            <Flex fontWeight="semibold" fontSize="md" color="gray.700" gap={2} align="center">
              <HStack fontSize="md" gap={14}>
                <Title>Location:</Title>
                {subprocessor.isoCountryCode && (
                  <Img
                    src={`/static/flags/${subprocessor.isoCountryCode.toLowerCase()}.svg`}
                    maxH={4}
                    maxW={7}
                    display="inline"
                  />
                )}
              </HStack>
            </Flex>
          </Stack>
        </AccordionPanel>
      </AccordionItem>
    );
  },
);

function SubPage({graph, rootLegalEntityId}: {graph: SubGraph; rootLegalEntityId: EntityId}) {
  const subprocessors = useMemo(
    () => getSubprocessorsFromEntityGraph(graph, rootLegalEntityId),
    [graph, rootLegalEntityId],
  );

  return (
    <>
      <ActionBar>
        <AddSubprocessorButton graph={graph} parentEntityId={rootLegalEntityId} />
      </ActionBar>
      <Accordion allowMultiple maxWidth="100%" whiteSpace="normal">
        {subprocessors.map(s => (
          <SubprocessorRow key={s.id} subprocessor={s} graph={graph} parentEntityId={rootLegalEntityId} />
        ))}
      </Accordion>
    </>
  );
}

export default SubPage;
