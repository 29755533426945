import {Button} from "@chakra-ui/react";
import {LoginMethodProps} from "./AnyLoginMethod";
import {LoginMethodBox} from "./LoginMethodBox";
import {LoginMethod, OAuthProvider} from "../../../Types";
import MicrosoftLogo from "../../../../assets/microsoft.svg";
import GoogleLogo from "../../../../assets/google.svg";
import {usePromiseState} from "../../../hooks/promiseState";
import {useOauthLogin} from "../../../oauth";
import {useCallback} from "react";

function oauthProviderInfo(loginMethod: LoginMethod) {
  switch (loginMethod) {
    case LoginMethod.GoogleOAuth:
      return {logo: GoogleLogo, name: "Google", provider: OAuthProvider.Google};
    case LoginMethod.MicrosoftOAuth:
      return {logo: MicrosoftLogo, name: "Microsoft", provider: OAuthProvider.Microsoft};
  }
}

export function OAuthLoginMethod(props: LoginMethodProps) {
  const {onUpsert, onSelect, onDelete, loginMethod} = props;
  const {logo, name, provider} = oauthProviderInfo(props.loginMethod)!;
  const oauthLogin = useOauthLogin();
  const [upserting, upsert] = usePromiseState(
    async (select: boolean) => {
      const accessToken = await oauthLogin.acquireToken(provider);
      await onUpsert(loginMethod, {type: "OAuth", content: accessToken});
      if (select && onSelect) {
        await onSelect(loginMethod);
      }
    },
    [provider, oauthLogin, onUpsert, onSelect, loginMethod],
  );
  const [deleting, delete_] = usePromiseState(async () => {
    onDelete && (await onDelete(loginMethod));
  }, [onDelete, loginMethod]);

  const configureAndSelect = useCallback(() => upsert(true), [upsert]);
  const configure = useCallback(() => upsert(false), [upsert]);

  return (
    <LoginMethodBox
      icon={logo}
      title={name}
      description={`Login with ${name}`}
      onConfigureAndSelect={configureAndSelect}
      rhs={
        <Button
          colorScheme={props.isSelected ? "blue" : "gray"}
          size="sm"
          onClick={props.isConfigured ? delete_ : configure}
          isLoading={upserting.inProgress || deleting.inProgress}
        >
          {props.isConfigured ? "Disconnect" : "Connect"}
        </Button>
      }
      {...props}
    />
  );
}
