import api from "../../api";
import {UserId} from "../../Types";
import {param, typedQuery} from "../typing";

export default [
  typedQuery(["user", param<UserId>("userId")], async context => {
    return await api.users.get(context.queryKey[1]);
  }),
  typedQuery(["registeredUsers"], async () => {
    return await api.users.listRegistered();
  }),
  typedQuery(["userTeams", param<UserId>("userId")], async context => {
    return await api.users.getTeams(context.queryKey[1]);
  }),
];
