import {Avatar, AvatarProps, Icon} from "@chakra-ui/react";
import {UserGroupIcon} from "@heroicons/react/20/solid";

type Size = "xs" | "sm" | "md" | "lg" | "xl" | "2xl" | "3xl";

const TeamAvatar = ({isMember, size, ...props}: {isMember?: boolean; size?: Size} & Omit<AvatarProps, "size">) => {
  const iconSize = {
    xs: "md",
    sm: "lg",
    md: "3xl",
    lg: "3xl",
    xl: "3xl",
    "2xl": "3xl",
    "3xl": "3xl",
  }[size ?? "md"];

  return (
    <Avatar
      icon={<Icon as={UserGroupIcon} fontSize={iconSize} />}
      bg={isMember ? "green.500" : undefined}
      size={size}
      {...props}
    />
  );
};

export default TeamAvatar;
