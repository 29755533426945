import {GridItem, Heading, HStack, Stack} from "@chakra-ui/react";

import Page from "../../../../components/Page.tsx";
import SearchableList from "../../../../components/VerticalNavList.tsx";
import ListItem from "./components/ListItem.tsx";
import {DocumentCategory} from "../../../../Types.ts";
import NewDocument from "./NewDocument.tsx";
import NewPolicy from "./NewPolicy.tsx";
import NewCertification from "./NewCertification.tsx";
import SidePane from "../../../../components/SidePane.tsx";
import {useQueryData} from "../../../../state/index.ts";
import {withSuspense} from "../../../../state/withSuspense.tsx";
import {useOutlet} from "react-router-dom";

const Empty = () => (
  <GridItem colSpan={8} p="6">
    <Heading size="md">No documents selected</Heading>
  </GridItem>
);

const CATEGORY_TITLES: {[category in DocumentCategory]: string} = {
  Policy: "Policies",
  Certification: "Certifications",
  Report: "Reports",
  Other: "Other documents",
};

const DocumentsPage = withSuspense(({category}: {category: DocumentCategory}) => {
  const documents = useQueryData({queryKey: ["vendorToolkit", "documents"]}).filter(doc => doc.category === category);
  const outlet = useOutlet() || <Empty />;
  let newButton;
  switch (category) {
    case DocumentCategory.Policy:
      newButton = <NewPolicy />;
      break;
    case DocumentCategory.Certification:
      newButton = <NewCertification />;
      break;
    case DocumentCategory.Report:
      throw new Error("Not implemented");
    case DocumentCategory.Other:
      newButton = <NewDocument />;
      break;
  }

  return (
    <Page title={CATEGORY_TITLES[category]}>
      <HStack alignItems="stretch" gap={0}>
        <SidePane>
          <SearchableList
            title={CATEGORY_TITLES[category]}
            items={documents}
            ItemComp={ListItem}
            getName={d => d.name}
            getKey={d => d.document_id}
            actionButtons={newButton}
          />
        </SidePane>
        <Stack flex="1" overflow="hidden">
          {outlet}
        </Stack>
      </HStack>
    </Page>
  );
});

export default DocumentsPage;
