import {Box, Button, Divider, HStack, Select, Stack, Text} from "@chakra-ui/react";

import Owner from "../../../../../components/Owner.tsx";
import FormSection, {FormQuestion} from "../components/FormSection.tsx";
import {useCallback} from "react";
import * as datefns from "date-fns";
import {parseISO} from "../../../../../utils/date.ts";
import {
  AccountFeature,
  ApiDateTime,
  CertificationType,
  DocumentCategory,
  DocumentId,
  EsignatureTemplateId,
  OwnerT,
  ScopeMin,
  SharingClassification,
  TrustCenterAccess,
} from "../../../../../Types.ts";
import SwitchableNumericInput from "../../../../../components/SwitchableNumericInput.tsx";
import api from "../../../../../api/index.ts";
import CommitInput from "../../../../../components/CommitInput.tsx";
import SharingClassificationComp from "../../../components/SharingClassification/Selector.tsx";
import DocumentCategoryComp from "../../../components/DocumentCategory/Selector.tsx";
import {useOutletContext} from "react-router-dom";
import {TabOutletContext} from "../Actions/Actions.tsx";
import {
  TrustCenterReadabilityComp,
  TrustCenterVisibilityComp,
} from "../../../components/TrustCenterAccess/Selector.tsx";
import {ScopeMultiSelector} from "../../../components/Scope.tsx";
import RequireAccountFeature from "../../../../../components/RequireAccountFeature.tsx";
import EsignatureTemplateSelector from "../../../components/EsignatureTemplateSelector/index.tsx";
import {useIsAdmin} from "../../../../../hooks/roles.ts";
import {
  CERTIFICATION_TYPE_OPTIONS,
  fromCertificationTypeOptionValue,
  toCertificationTypeOptionValue,
} from "../certification_helpers.ts";
import {DateUI} from "../../../../../components/Date.tsx";
import DocumentSelector from "../../../components/DocumentSelector.tsx";

const MetaPage = () => {
  const {document} = useOutletContext<TabOutletContext>();
  const last_update = parseISO(document.last_update);
  const last_review = parseISO(document.last_review);
  const next_review =
    document.review_period.type === "Days" && last_review
      ? datefns.add(last_review, {days: document.review_period.content})
      : null;

  const updateReviewPeriod = useCallback(
    async (value?: number) => {
      await api.vendorToolkit.documents.updateReviewPeriod(
        document.document_id,
        value === undefined ? {type: "None"} : {type: "Days", content: value},
      );
    },
    [document.document_id],
  );

  const updateName = useCallback(
    async (value: string) => {
      await api.vendorToolkit.documents.updateName(document.document_id, value);
    },
    [document.document_id],
  );

  const updateOwner = useCallback(
    async (value?: OwnerT) => {
      await api.vendorToolkit.documents.assign(document.document_id, value ? value.owner_id : null);
    },
    [document.document_id],
  );

  const updateSharing = useCallback(
    async (sharing: SharingClassification) => {
      await api.vendorToolkit.documents.updateSharingClassification(document.document_id, sharing);
    },
    [document.document_id],
  );

  const updateCategory = useCallback(
    async (category: DocumentCategory) => {
      await api.vendorToolkit.documents.updateCategory(document.document_id, category);
    },
    [document.document_id],
  );

  const updateLimitedToScopes = useCallback(
    async (scopes: ScopeMin[] | null) => {
      await api.vendorToolkit.documents.updateLimitedToScopes(
        document.document_id,
        scopes?.map(scope => scope.scope_id) ?? null,
      );
    },
    [document.document_id],
  );

  const updateTrustCenterVisibility = useCallback(
    async (visibility: TrustCenterAccess) => {
      await api.vendorToolkit.documents.updateTrustCenterVisibility(document.document_id, visibility);
    },
    [document.document_id],
  );

  const updateTrustCenterReadability = useCallback(
    async (readability: TrustCenterAccess) => {
      await api.vendorToolkit.documents.updateTrustCenterReadability(document.document_id, readability);
    },
    [document.document_id],
  );

  const updateTrustCenterEsignatureTemplate = useCallback(
    async (templateId: EsignatureTemplateId | null) => {
      await api.vendorToolkit.documents.updateTrustCenterEsignatureTemplate(document.document_id, templateId);
    },
    [document.document_id],
  );

  const updateCertificationType = useCallback(
    async (certificationType: CertificationType | null) => {
      await api.vendorToolkit.documents.updateDocumentCertificationType(document.document_id, certificationType);
    },
    [document.document_id],
  );

  const updateCertificationDateGranted = useCallback(
    async (date: ApiDateTime | null) => {
      await api.vendorToolkit.documents.updateDocumentCertificationDateGranted(document.document_id, date);
    },
    [document.document_id],
  );

  const updateCertificationDateOfExpiry = useCallback(
    async (date: ApiDateTime | null) => {
      await api.vendorToolkit.documents.updateDocumentCertificationDateExpires(document.document_id, date);
    },
    [document.document_id],
  );

  const updateCertificationAuditorUrl = useCallback(
    async (url: string) => {
      const maybeUrl = url.trim() === "" ? null : url;
      await api.vendorToolkit.documents.updateDocumentCertificationAuditorUrl(document.document_id, maybeUrl);
    },
    [document.document_id],
  );

  const updateCertificationAuditReport = useCallback(
    async (documentId: DocumentId | null) => {
      await api.vendorToolkit.documents.updateDocumentCertificationAuditReport(document.document_id, documentId);
    },
    [document.document_id],
  );

  const isAdmin = useIsAdmin();

  return (
    <Stack spacing={4} p={4}>
      <FormSection title="Main" description="">
        <FormQuestion title="Name">
          <CommitInput value={document.name} onCommit={updateName} />
        </FormQuestion>
        <FormQuestion title="Category">
          <Box>
            <DocumentCategoryComp category={document.category} onChangeCategory={updateCategory} />
          </Box>
        </FormQuestion>
        <RequireAccountFeature feature={AccountFeature.Scopes}>
          <FormQuestion
            title="Limited to scopes"
            description="Facts generated from this document will apply only to the selected scopes"
          >
            <Box>
              <ScopeMultiSelector value={document.limited_to_scopes ?? null} onChange={updateLimitedToScopes} />
            </Box>
          </FormQuestion>
        </RequireAccountFeature>
        <FormQuestion title="Sharing status">
          <Box>
            <SharingClassificationComp sharing={document.sharing_classification} onChangeSharing={updateSharing} />
          </Box>
        </FormQuestion>
        <FormQuestion title="Owner">
          <Box>
            <Owner owner={document.owner} onReassign={updateOwner} canUnassign={true} />
          </Box>
        </FormQuestion>
      </FormSection>
      <RequireAccountFeature feature={AccountFeature.TrustCenter}>
        {document.category === DocumentCategory.Certification && (
          <>
            <Divider />
            <FormSection title="Certification information" description="">
              <FormQuestion title="Certification type" description="We use this to show a logo in your Showcase.">
                <Select
                  value={toCertificationTypeOptionValue(document.certification_type)}
                  onChange={e => updateCertificationType(fromCertificationTypeOptionValue(e.target.value) ?? null)}
                >
                  {CERTIFICATION_TYPE_OPTIONS.map(option => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </Select>
              </FormQuestion>
              <FormQuestion title="Date granted">
                <HStack>
                  <DateUI date={document.certification_date_granted} onDateChange={updateCertificationDateGranted} />
                  <Button size="xs" variant="outline" onClick={() => updateCertificationDateGranted(null)}>
                    Unset
                  </Button>
                </HStack>
              </FormQuestion>
              <FormQuestion title="Date of expiry">
                <HStack>
                  <DateUI date={document.certification_date_expires} onDateChange={updateCertificationDateOfExpiry} />
                  <Button size="xs" variant="outline" onClick={() => updateCertificationDateOfExpiry(null)}>
                    Unset
                  </Button>
                </HStack>
              </FormQuestion>
              <FormQuestion title="Auditor URL">
                <CommitInput
                  value={document.certification_auditor_url ?? ""}
                  onCommit={updateCertificationAuditorUrl}
                />
              </FormQuestion>
              <FormQuestion title="Audit report">
                <DocumentSelector
                  value={document.certification_audit_report?.document_id ?? null}
                  onChange={updateCertificationAuditReport}
                  filter={d =>
                    d.document_id !== document.document_id && d.trust_center_visibility === TrustCenterAccess.Allowed
                  }
                  showCategoryPrefix
                />
                <Text fontSize="sm" color="gray.500">
                  Only documents visible in your Showcase can be selected.
                </Text>
              </FormQuestion>
            </FormSection>
          </>
        )}
      </RequireAccountFeature>
      <Divider />
      <FormSection title="Review" description="Document review settings" position="relative">
        <Box position="absolute" bg="rgba(255,255,255,0.5)" left={0} top={0} w="full" h="full" cursor="not-allowed" />
        <FormQuestion title="Last update">
          <Text fontSize="md">{datefns.format(last_update, "PP")}</Text>
        </FormQuestion>
        <FormQuestion title="Last review / sign off">
          <Text fontSize="md">{last_review ? datefns.format(last_review, "PP") : "n/a"}</Text>
        </FormQuestion>
        <SwitchableNumericInput
          value={document.review_period.content}
          minValue={3}
          maxValue={256}
          defaultValue="60"
          label={
            <Text fontSize="md" fontWeight="600">
              Review period
            </Text>
          }
          suffix="days"
          onCommit={updateReviewPeriod}
          isDisabled
        />
        <FormQuestion title="New review / sign off due">
          <Text fontSize="md">{next_review ? datefns.format(next_review, "PP") : "n/a"}</Text>
        </FormQuestion>
      </FormSection>
      {isAdmin && (
        <RequireAccountFeature feature={AccountFeature.TrustCenter}>
          <Divider />
          <FormSection title="Showcase" description="">
            <FormQuestion title="Visibility" description="Whether this is listed in your Showcase.">
              <Box>
                <TrustCenterVisibilityComp
                  pr="1"
                  size="lg"
                  access={document.trust_center_visibility}
                  onChangeAccess={updateTrustCenterVisibility}
                />
              </Box>
            </FormQuestion>
            {document.trust_center_visibility == "Allowed" && (
              <>
                <FormQuestion
                  title="Access rules"
                  description="Whether it is publicly readable or gated behind an access request."
                >
                  <Box>
                    <TrustCenterReadabilityComp
                      pr="1"
                      size="lg"
                      access={document.trust_center_readability}
                      onChangeAccess={updateTrustCenterReadability}
                    />
                  </Box>
                </FormQuestion>

                {document.trust_center_readability === "Denied" && (
                  <FormQuestion title="NDA template" description="Access requests will require signing this NDA.">
                    <Box>
                      <EsignatureTemplateSelector
                        selectedTemplateId={document.trust_center_esignature_template_id ?? null}
                        onChangeSelectedTemplateId={updateTrustCenterEsignatureTemplate}
                      />
                    </Box>
                  </FormQuestion>
                )}
              </>
            )}
          </FormSection>
        </RequireAccountFeature>
      )}
    </Stack>
  );
};

export default MetaPage;
