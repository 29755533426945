import {
  FormControl,
  FormErrorMessage,
  RadioGroup,
  Stack,
  Radio,
  Button,
  Box,
  CheckboxGroup,
  Checkbox,
} from "@chakra-ui/react";
import {memo, useCallback} from "react";
import {usePromiseState} from "../../../../hooks/promiseState.ts";
import {ResponseOption, SelectAnswerConfig} from "../../../../Types.ts";
import CommitInput from "../../../../components/CommitInput.tsx";
import {useSequential} from "../../../../hooks/sequential.ts";

type ResponseSelectProps = {
  value: ResponseOption[] | undefined;
  config: SelectAnswerConfig;
  onCommit: (value: ResponseOption[] | undefined) => Promise<void>;
  isDisabled?: boolean;
};

const ResponseSingleSelect = memo(({onCommit, value, config, isDisabled}: ResponseSelectProps) => {
  const [committing, commit] = usePromiseState(
    async (newValue: ResponseOption[] | undefined) => {
      await onCommit(newValue);
    },
    [onCommit],
  );
  const commitChange = useSequential(value ?? [], commit);
  const commitValue = useCallback(
    async (optionValue?: string) => {
      await commitChange(() => (optionValue == null ? [] : [{value: optionValue}]));
    },
    [commitChange],
  );
  const commitOtherText = useCallback(
    async (optionValue: string, optionText: string) => {
      await commitChange(
        oldValue => oldValue.map(o => (o.value === optionValue ? {...o, other_text: optionText} : o)) ?? [],
      );
    },
    [commitChange],
  );

  const latestValue = committing.inProgress && committing.lastArgs ? committing.lastArgs[0] : value;

  return (
    <FormControl isInvalid={!!committing.lastError} my={6}>
      <RadioGroup
        onChange={commitValue}
        value={(latestValue ? latestValue[0]?.value : undefined) ?? ""}
        size="lg"
        isDisabled={isDisabled}
      >
        <Stack gap={4}>
          {config.options.map(o => {
            const v = value?.find(v => v.value === o.value);
            return (
              <Box key={o.value}>
                <Radio value={o.value}>{o.label}</Radio>
                {o.allow_other_text && v && (
                  <CommitInput
                    isDisabled={isDisabled}
                    value={v?.other_text ?? ""}
                    onCommit={t => commitOtherText(o.value, t)}
                    size="sm"
                    ml={8}
                  />
                )}
              </Box>
            );
          })}
        </Stack>
        <Box>
          <Button
            mt={8}
            ml={1}
            colorScheme="black"
            variant="link"
            onClick={() => commit(undefined)}
            isDisabled={committing.inProgress || isDisabled}
            isLoading={committing.inProgress}
          >
            Reset
          </Button>
        </Box>
      </RadioGroup>
      <FormErrorMessage>{committing.lastError?.toString()}</FormErrorMessage>
    </FormControl>
  );
});

const ResponseMultiSelect = memo(({onCommit, value, config, isDisabled}: ResponseSelectProps) => {
  const [committing, commit] = usePromiseState(
    async (newValue: ResponseOption[] | undefined) => {
      await onCommit(newValue);
    },
    [onCommit],
  );
  const commitChange = useSequential(value ?? [], commit);
  const commitValue = useCallback(
    async (optionValues: string[]) => {
      await commitChange(oldValue =>
        optionValues.map(value => ({
          value,
          other_text: oldValue.find(o => o.value === value)?.other_text,
        })),
      );
    },
    [commitChange],
  );
  const commitOtherText = useCallback(
    async (optionValue: string, optionText: string) => {
      await commitChange(
        oldValue => oldValue.map(o => (o.value === optionValue ? {...o, other_text: optionText} : o)) ?? [],
      );
    },
    [commitChange],
  );

  const latestValue = committing.lastArgs ? committing.lastArgs[0] : value;
  const hasSelectedAll =
    config.max_selected != null && latestValue != null && latestValue.length >= config.max_selected;

  return (
    <FormControl isInvalid={!!committing.lastError} my={6}>
      <CheckboxGroup
        onChange={commitValue}
        value={latestValue ? latestValue.map(v => v.value) : []}
        size="lg"
        isDisabled={isDisabled}
      >
        <Stack gap={4}>
          {config.options.map(o => {
            const v = latestValue?.find(v => v.value === o.value);
            return (
              <Box key={o.value}>
                <Checkbox value={o.value} isDisabled={(!v && hasSelectedAll) || isDisabled}>
                  {o.label}
                </Checkbox>
                {o.allow_other_text && v && (
                  <CommitInput
                    isDisabled={isDisabled}
                    value={v?.other_text ?? ""}
                    onCommit={t => commitOtherText(o.value, t)}
                    size="sm"
                    ml={8}
                  />
                )}
              </Box>
            );
          })}
        </Stack>
        <Box>
          <Button
            mt={8}
            ml={1}
            colorScheme="black"
            variant="link"
            onClick={() => commit(undefined)}
            isDisabled={committing.inProgress || isDisabled}
            isLoading={committing.inProgress}
          >
            Reset
          </Button>
        </Box>
      </CheckboxGroup>
      <FormErrorMessage>{committing.lastError?.toString()}</FormErrorMessage>
    </FormControl>
  );
});

const ResponseSelect = memo((props: ResponseSelectProps) => {
  if (props.config.max_selected === 1) {
    return <ResponseSingleSelect {...props} />;
  } else {
    return <ResponseMultiSelect {...props} />;
  }
});

export default ResponseSelect;
