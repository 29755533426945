import {LayerType} from "../../../../Types";

type LayerTypeInfo = {
  text: string;
  color: string;
};

export const LAYER_SELECTOR_MAP: {[category: string]: LayerTypeInfo} = {
  [LayerType.External]: {
    text: "External",
    color: "green",
  },
  [LayerType.Internal]: {
    text: "Internal",
    color: "red",
  },
  [LayerType.AI]: {
    text: "AI",
    color: "purple",
  },
};
