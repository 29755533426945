import {ArrowRightIcon} from "@heroicons/react/20/solid";
import {useState} from "react";
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Image,
  Input,
  Link,
  SimpleGrid,
  Stack,
  Text,
} from "@chakra-ui/react";

import api, {HTTPError} from "../api";
import {LoginRequest, OAuthProvider, UnauthorizedError} from "../Types";
import {usePromiseState} from "../hooks/promiseState";
import {Form, Link as RouterLink} from "react-router-dom";
import AuthError from "./AuthError";
import {getRedirectParams, redirectRestoringUrl, shouldShowRegistrationLink} from "../utils/auth";
import {useOauthLogin} from "../oauth";
import {useCredentials} from "../hooks/credentials";

import MicrosoftLogo from "../../assets/microsoft.svg";
import GoogleLogo from "../../assets/google.svg";

const Login = () => {
  const [email, setEmail] = useState("");
  const {credentials, credentialFields, handleCredentialsError} = useCredentials(
    "Your email or password was entered incorrectly",
  );
  const [rememberMe, setRememberMe] = useState(false);

  const [loggingIn, login, clearError] = usePromiseState(
    async (loginRequest: LoginRequest) => {
      try {
        await api.auth.login(loginRequest);
        await redirectRestoringUrl().follow();
      } catch (e) {
        let errorMessage = "An unknown error occurred. Please contact support@platformed.com if this persists";
        if (e instanceof HTTPError && e.response.status === 403) {
          errorMessage = `Your account is configured to use a different authentication method`;
        } else {
          switch (loginRequest.type) {
            case "UsernamePassword":
              if (handleCredentialsError(e)) {
                return;
              }
              break;
            case "OAuth":
              if (e instanceof UnauthorizedError && e.data.type === "IncorrectEmailOrPassword") {
                errorMessage = `Your ${loginRequest.content.provider} account is not associated with a Platformed account`;
              }
          }
        }
        throw new Error(errorMessage);
      }
    },
    [handleCredentialsError],
  );

  const oauthLogin = useOauthLogin();
  const [loggingInWithOauth, loginWithOauth] = usePromiseState(
    async (provider: OAuthProvider) => {
      clearError();
      const accessToken = await oauthLogin.acquireToken(provider);
      await login({type: "OAuth", content: {provider, access_token: accessToken}});
    },
    [oauthLogin, clearError, login],
  );

  return (
    <Form
      onSubmit={e => {
        e.stopPropagation();
        e.preventDefault();
        login({
          type: "UsernamePassword",
          content: {email, credentials, remember_me: rememberMe},
        });
      }}
    >
      <Flex justifyContent="space-between" alignItems="center">
        <Heading fontSize="32" fontWeight="600">
          Welcome back
        </Heading>
        <Box>
          {shouldShowRegistrationLink() && (
            <Link as={RouterLink} fontSize="md" color="blue.500" to={`/register?${getRedirectParams()}`}>
              New user registration
            </Link>
          )}
        </Box>
      </Flex>

      <AuthError error={(loggingIn.lastError ?? loggingInWithOauth.lastError)?.toString()} mt={12} />

      <SimpleGrid mt="8" spacing={4} columns={2}>
        <Button
          isDisabled={oauthLogin.isDisabled.google}
          isLoading={
            loggingInWithOauth.inProgress &&
            loggingInWithOauth.lastArgs &&
            loggingInWithOauth.lastArgs[0] === OAuthProvider.Google
          }
          onClick={() => loginWithOauth(OAuthProvider.Google)}
          p="5"
          variant="outline"
          size="md"
          w="100%"
        >
          <Image h="4" mr="2" src={GoogleLogo} alt="Google" />
          Login with Google
        </Button>
        <Button
          isDisabled={oauthLogin.isDisabled.microsoft}
          isLoading={
            loggingInWithOauth.inProgress &&
            loggingInWithOauth.lastArgs &&
            loggingInWithOauth.lastArgs[0] === OAuthProvider.Microsoft
          }
          onClick={() => loginWithOauth(OAuthProvider.Microsoft)}
          p="5"
          variant="outline"
          size="md"
          w="100%"
        >
          <Image h="4" mr="2" src={MicrosoftLogo} alt="Microsoft" />
          Login with Microsoft
        </Button>
      </SimpleGrid>

      <Box mt={12} mb={8} position="relative">
        <Box bg="gray.200" position="absolute" w="100%" h="1px" top="50%" mt="1px" />
        <Flex justifyContent="center">
          <Text fontSize="md" color="gray.500" bg="white" zIndex="base" pl="5" pr="5">
            Or continue with
          </Text>
        </Flex>
      </Box>

      <Stack spacing={8}>
        <FormControl>
          <FormLabel>Email</FormLabel>
          <Input
            type="email"
            placeholder="name@domain.com"
            value={email}
            onChange={e => setEmail(e.target.value)}
            autoComplete="email"
          />
        </FormControl>

        {credentialFields}

        <Flex alignItems="center">
          <Checkbox checked={rememberMe} onChange={() => setRememberMe(!rememberMe)} flex="1">
            Remember me
          </Checkbox>
          <Box>
            <Link as={RouterLink} fontSize="md" color="blue.500" to={`/request-reset?${getRedirectParams()}`}>
              Forgotten your password?
            </Link>
          </Box>
        </Flex>
      </Stack>

      <Button
        mt="12"
        colorScheme="blue"
        rightIcon={<Icon as={ArrowRightIcon} h="6" />}
        w="full"
        type="submit"
        isLoading={loggingIn.inProgress}
      >
        Login
      </Button>
    </Form>
  );
};

export default Login;
