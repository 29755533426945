import {CounterpartyId, Counterparty as CounterpartyT, Deal} from "../../../Types.ts";
import {
  Avatar,
  Flex,
  FlexProps,
  HStack,
  Highlight,
  InputGroup,
  InputRightElement,
  Select,
  Spinner,
  Text,
  Box,
  TooltipProps,
} from "@chakra-ui/react";
import {usePromiseState} from "../../../hooks/promiseState.ts";
import {memo} from "react";
import NewCounterparty from "../Configuration/Counterparties/NewCounterparty.tsx";
import {useQueriesData, useQueryData} from "../../../state/index.ts";
import {CrmIcon} from "./CrmButton.tsx";
import {TooltipWithIcon} from "../../../components/TooltipWithIcon.tsx";

const BUTTON_WIDTH = "72px";
const Counterparty = ({
  counterparty,
  queries,
  ...props
}: {counterparty: CounterpartyT; queries?: string[]} & FlexProps) => (
  <Flex align="center" {...props}>
    <Avatar name={counterparty.name} size="xs" src={`https://logo.clearbit.com/${counterparty.url}`} mr={2} />
    <Text noOfLines={2}>
      <Highlight query={queries ?? ""} styles={{bg: "yellow.200"}}>
        {counterparty.name}
      </Highlight>
    </Text>
  </Flex>
);

export const CounterpartySelector = memo(
  ({value, onChange}: {value: CounterpartyT | undefined; onChange: (newValue: CounterpartyT) => Promise<void>}) => {
    const counterparties = useQueryData({queryKey: ["vendorToolkit", "counterparties"]});
    const [changing, change] = usePromiseState(
      async (e: React.ChangeEvent<HTMLSelectElement>) => {
        await onChange(counterparties.find(counterparty => counterparty.counterparty_id === e.target.value)!);
      },
      [onChange, counterparties],
    );
    return (
      <HStack>
        <InputGroup>
          <Select onChange={change} value={value?.counterparty_id ?? "none"} isDisabled={changing.inProgress}>
            <option disabled value={"none"}>
              {counterparties.length > 0 ? "Select an option" : "No options available"}
            </option>
            {counterparties.map(counterparty => (
              <option key={counterparty.counterparty_id} value={counterparty.counterparty_id}>
                {counterparty.name}
              </option>
            ))}
          </Select>
          {changing.inProgress && (
            <InputRightElement mr={6}>
              <Spinner color="gray.500" size="sm" />
            </InputRightElement>
          )}
        </InputGroup>
        <Box width={BUTTON_WIDTH}>
          <NewCounterparty colorScheme="blue" variant="ghost" onCreate={onChange}>
            New
          </NewCounterparty>
        </Box>
      </HStack>
    );
  },
);

export const DealSelector = ({
  counterparty_id,
  value,
  onChange,
}: {
  counterparty_id: CounterpartyId;
  value: Deal | undefined;
  onChange: (newValue?: Deal) => Promise<void>;
}) => {
  const [deals] = useQueriesData({
    queries: [{queryKey: ["vendorToolkit", "deals"]}],
  });
  const counterpartyDeals = deals.filter(d => d.counterparty.counterparty_id === counterparty_id);

  const [changing, change] = usePromiseState(
    async (e: React.ChangeEvent<HTMLSelectElement>) => {
      await onChange(counterpartyDeals.find(deal => deal.deal_id === e.target.value)!);
    },
    [onChange, counterpartyDeals],
  );

  return (
    <HStack>
      <InputGroup>
        <Select onChange={change} value={value?.deal_id ?? "none"} isDisabled={changing.inProgress}>
          <option value={"none"}>{counterpartyDeals.length > 0 ? "None" : "No options available"}</option>
          {counterpartyDeals.map(deal => (
            <option key={deal.deal_id} value={deal.deal_id}>
              {deal.name}
            </option>
          ))}
        </Select>
        {changing.inProgress && (
          <InputRightElement mr={6}>
            <Spinner color="gray.500" size="sm" />
          </InputRightElement>
        )}
      </InputGroup>
      <Box width={BUTTON_WIDTH} flexShrink="0">
        {value?.uri ? <CrmIcon uri={value.uri} /> : null}
      </Box>
    </HStack>
  );
};

export const CounterpartyHeading = (props: Omit<TooltipProps, "children">) => {
  return (
    <TooltipWithIcon
      placement="top"
      label="This is the organisation that sent you the questionnaire"
      fontSize="md"
      {...props}
    >
      <Text as="span">Counterparty</Text>
    </TooltipWithIcon>
  );
};

export default Counterparty;
