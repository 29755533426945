import {Box, Flex} from "@chakra-ui/react";
import {useMotionValueEvent, useScroll} from "framer-motion";
import {ReactNode, useState} from "react";
import {EXPANDED_NAV_HEIGHT, STICKY_ACTION_OFFSET} from "../utils/sticky";

const SidePane = ({children}: {children?: ReactNode}) => {
  const [navHeight, setNavHeight] = useState(EXPANDED_NAV_HEIGHT);
  const {scrollY} = useScroll();
  useMotionValueEvent(scrollY, "change", latest => {
    setNavHeight(Math.max(EXPANDED_NAV_HEIGHT - latest, STICKY_ACTION_OFFSET));
  });
  return (
    <Box as="aside" maxW="30%" minW="30%" bg="gray.50" borderLeft="1px solid" borderColor="gray.200">
      <Flex
        position="sticky"
        top={`${STICKY_ACTION_OFFSET}px`}
        height={`calc(100vh - ${navHeight}px)`}
        overflow="hidden"
        flexDir="column"
      >
        {children}
      </Flex>
    </Box>
  );
};

export default SidePane;
