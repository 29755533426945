import {ReactNode, useEffect} from "react";
import {Accept, FileRejection} from "react-dropzone";
import {
  Button,
  ButtonProps,
  HStack,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import {CloudArrowUpIcon, DocumentPlusIcon} from "@heroicons/react/20/solid";
import SingleFileUpload from "./SingleFileUpload";
import {useAbortableState, useAsyncOperation} from "../../hooks/asyncOperation";
import {FileUpload, FileUploadBase, FileUploadResult} from "../../hooks/fileUpload";
import {UploadOptions} from "../../Types";

const DEFAULT_OPTIONS: UploadOptions = {};

export function UploadButton<TFileUpload extends FileUploadBase = FileUpload>({
  children,
  onUpload,
  accept,
  Cls,
  onDropRejected,
  options = DEFAULT_OPTIONS,
  ...props
}: {
  children: string;
  accept?: Accept;
  onUpload: (upload: FileUploadResult<TFileUpload>) => void;
  Cls: {new (file: File, options: UploadOptions): TFileUpload};
  onDropRejected?: (files: FileRejection[]) => ReactNode;
  options?: UploadOptions;
} & Omit<ButtonProps, "onUpload" | "children" | "Cls" | "onDropRejected" | "accept">) {
  const {isOpen, onOpen, onClose} = useDisclosure();
  const [fileUpload, setFileUpload] = useAbortableState<TFileUpload | null>(null);
  const uploadState = useAsyncOperation(fileUpload);
  useEffect(() => {
    if (uploadState?.id === "Uploaded") {
      onUpload(uploadState.result);
      setFileUpload(null);
      onClose();
    }
  }, [uploadState, setFileUpload, onUpload, onClose]);

  return (
    <>
      <Button leftIcon={<Icon as={DocumentPlusIcon} />} onClick={onOpen} colorScheme="green" {...props}>
        {children}
      </Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{children}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing="8" mb="4">
              <SingleFileUpload
                Cls={Cls}
                value={fileUpload}
                accept={accept}
                onChange={setFileUpload}
                onDropRejected={onDropRejected}
                options={options}
              >
                {isDragActive => (
                  <HStack align="center" spacing={2}>
                    <Icon as={CloudArrowUpIcon} fontSize="xl" color={isDragActive ? "blue.500" : "gray.500"} />
                    <Text>Drag document here</Text>
                  </HStack>
                )}
              </SingleFileUpload>
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
