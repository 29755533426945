import {Navigate, Route} from "react-router-dom";
import counterpartiesRoutes from "./Counterparties/index.tsx";
import scopesRoutes from "./Scopes/index.tsx";
import integrationsRoutes from "./Integrations/index.tsx";
import aiPreferencesRoutes from "./AiPreferences/index.tsx";
import userManagementRoutes from "./UserManagement.tsx";
import organisationProfileRoutes from "./OrganisationProfile.tsx";
import AdminFrame from "../../components/AdminFrame.tsx";

export default (
  <Route path="configuration" Component={AdminFrame} handle={{crumb: "Configuration"}}>
    {organisationProfileRoutes}
    {userManagementRoutes}
    {aiPreferencesRoutes}
    {counterpartiesRoutes}
    {scopesRoutes}
    {integrationsRoutes}
    <Route index element={<Navigate to="ai-preferences" replace />} />
  </Route>
);
