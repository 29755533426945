import {
  Button,
  HStack,
  Icon,
  MenuItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import {ApiDateTime} from "../Types";
import {memo, useState} from "react";
import {CalendarDaysIcon} from "@heroicons/react/20/solid";
import DueDate from "./DueDate";

export const DueDateModal = memo(({onPick}: {onPick: (apiDate?: ApiDateTime) => void}) => {
  const {isOpen, onOpen, onClose} = useDisclosure();
  const [dueDate, setDueDate] = useState<ApiDateTime | undefined>(undefined);
  const pick = () => {
    onPick(dueDate);
    onClose();
  };

  return (
    <>
      <MenuItem onClick={onOpen}>
        <Icon as={CalendarDaysIcon} h="6" mr="2" color="gray.500" />
        Pick...
      </MenuItem>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Pick due date</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <DueDate dueDate={dueDate} onDateChange={async newDueDate => setDueDate(newDueDate)} complete={false} />
          </ModalBody>
          <ModalFooter>
            <HStack spacing="3">
              <Button variant="outline" onClick={onClose}>
                Cancel
              </Button>
              <Button colorScheme="blue" onClick={pick}>
                Pick
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
});
