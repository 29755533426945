import {Outlet, Route} from "react-router-dom";
import {KnockFeedProvider, KnockProvider} from "@knocklabs/react";

// Required CSS import, unless you're overriding the styling
import "@knocklabs/react/dist/index.css";

import {memo, useEffect, useRef} from "react";
import {getPublicCredentials} from "../utils/environment";
import PusherProvider from "../hooks/pusher/Provider";
import {useQueryData} from "../state";
import {withSuspense} from "../state/withSuspense";
import {InternalModeProvider} from "../components/InternalMode";
import accountRoutes from "../Account";
import accountSelectorRoutes from "./AccountSelector";
import consentScreenRoutes from "./ConsentScreen";
import settingsRoutes from "./Settings";
import UserFrame from "./Frame";
import {useDebounceValue} from "usehooks-ts";

const UserMain = withSuspense(
  memo(() => {
    const credentials = getPublicCredentials();
    // Retain the original JWT for 1 day as JWTs are valid for 2 weeks, and knock can't seem to cope
    // with the JWT changing...
    const whoami = useQueryData({queryKey: ["whoAmI"]});
    const [knockJwt] = useDebounceValue(whoami.knock_jwt, 24 * 60 * 60 * 1000);
    const originalJwt = useRef(knockJwt);
    useEffect(() => {
      if (originalJwt.current !== knockJwt) {
        window.location.reload();
      }
    }, [knockJwt]);
    return (
      <InternalModeProvider>
        <KnockProvider apiKey={credentials.knock_public_key} userId={whoami.user.user_id} userToken={knockJwt}>
          <KnockFeedProvider
            feedId={credentials.knock_feed_id}
            defaultFeedOptions={{tenant: whoami.account?.account_id}}
          >
            <PusherProvider
              userId={whoami.user.user_id}
              appKey={credentials.pusher_key}
              cluster={credentials.pusher_cluster}
            >
              <Outlet />
            </PusherProvider>
          </KnockFeedProvider>
        </KnockProvider>
      </InternalModeProvider>
    );
  }),
);

const UserRouter = (
  <Route path="/" element={<UserMain />} id="User">
    {accountRoutes}
    <Route path="/" element={<UserFrame />}>
      {accountSelectorRoutes}
      {consentScreenRoutes}
      {settingsRoutes}
    </Route>
  </Route>
);

export default UserRouter;
