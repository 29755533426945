import MarkdownRenderer from "react-markdown";
import {
  Box,
  Divider,
  Heading,
  HeadingProps,
  Image,
  Link,
  ListItem,
  OrderedList,
  Stack,
  Text,
  UnorderedList,
} from "@chakra-ui/react";
import {Link as RouterLink} from "react-router-dom";
import ScrollAnchor from "../ScrollAnchor/Anchor";
import {generateId} from ".";

const AnchorHeading = ({id, ...props}: HeadingProps & {id: string}) => (
  <ScrollAnchor id={id}>
    <Heading {...props} />
  </ScrollAnchor>
);

const COMPONENTS = {
  a: ({href, ...props}: {href?: string} & any) => (
    <Link
      to={href}
      target={href?.startsWith("http") ? "_blank" : "_self"}
      {...props}
      as={RouterLink}
      color="brand.500"
    />
  ),
  h1: (props: any) => (
    <>
      <Divider my={12} sx={{"&:first-of-type": {display: "none"}}} />
      <AnchorHeading size="xl" variant="content" fontWeight="500" id={generateId(props.children)} {...props} />
    </>
  ),
  h2: (props: any) => (
    <AnchorHeading id={generateId(props.children)} mt={8} size="lg" fontWeight="500" color="inherit" {...props} />
  ),
  h3: (props: any) => <Heading size="md" fontWeight="700" color="inherit" mt={4} {...props} />,
  strong: (props: any) => <Text as="strong" fontWeight="600" display="inline" {...props} />,
  li: ({ordered: _ordered, ...props}: any) => (
    <ListItem color="inherit" sx={{">ul": {mt: 4, ml: 6}}} pl={2} {...props} />
  ),
  ul: ({ordered: _ordered, ...props}: any) => (
    <UnorderedList color="inherit" spacing={4} mb={4} sx={{"ul, ol": {mt: 4}}} {...props} />
  ),
  ol: ({ordered: _ordered, ...props}: any) => <OrderedList color="inherit" spacing={4} mb={4} pl={2} {...props} />,
  img: (props: any) => <Image border="15px solid" borderColor="gray.800" shadow="md" mx="auto" {...props} />,
  blockquote: (props: any) => (
    <Text
      as="blockquote"
      borderLeft="3px solid"
      borderLeftColor="blue.500"
      bg="gray.50"
      color="gray.900"
      py={6}
      px={6}
      {...props}
    />
  ),
};

const MarkdownView = ({markdown}: {markdown: string}) => {
  return markdown ? (
    <Stack gap={4}>
      <MarkdownRenderer components={COMPONENTS}>{markdown}</MarkdownRenderer>
    </Stack>
  ) : (
    <Box>Loading</Box>
  );
};
export default MarkdownView;
