import {CreateCounterparty, Counterparty, CounterpartyId} from "../../Types";
import {invalidateQueries} from "../../state";
import jsonApi from "../jsonApi";

export async function list() {
  return await jsonApi.get<Counterparty[]>("/vendor_toolkit/counterparties");
}

export async function create(counterparty: CreateCounterparty) {
  const res = await jsonApi.post<Counterparty>("/vendor_toolkit/counterparties", counterparty);
  await invalidateQueries([{queryKey: ["vendorToolkit", "counterparties"]}]);
  return res;
}

export async function updateName(counterpartyId: CounterpartyId, name: string) {
  const res = await jsonApi.post<"ok">(`/vendor_toolkit/counterparties/${counterpartyId}/name`, name);
  await invalidateQueries([{queryKey: ["vendorToolkit", "counterparties"]}]);
  return res;
}

export async function updateUrl(counterpartyId: CounterpartyId, url: string) {
  const res = await jsonApi.post<"ok">(`/vendor_toolkit/counterparties/${counterpartyId}/url`, url);
  await invalidateQueries([{queryKey: ["vendorToolkit", "counterparties"]}]);
  return res;
}
