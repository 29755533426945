import {useCallback} from "react";
import CommitTextarea from "../../../../../components/CommitTextarea";
import {DocumentAuthority, Document} from "../../../../../Types";
import api from "../../../../../api";
import {Box, Button, Flex, Icon, Menu, MenuButton, MenuItem, MenuItemProps, useDisclosure} from "@chakra-ui/react";
import {ArrowDownTrayIcon, ChevronDownIcon, UserCircleIcon} from "@heroicons/react/20/solid";
import {ContactSupportModal} from "../../../../../components/ContactSupportModal";
import {useOutletContext} from "react-router-dom";
import {TabOutletContext} from "../Actions/Actions";
import PortalMenuList from "../../../../../components/PortalMenuList";

const StyledMenuItem = ({
  icon,
  children,
  description,
  ...props
}: {icon: React.FC; description: string} & Omit<MenuItemProps, "icon">) => (
  <MenuItem {...props}>
    <Flex py="1">
      <Icon as={icon} h="6" mr="2" color="gray.400" />
      <Box>
        <Box fontWeight={500}>{children}</Box>
        <Box fontWeight={400} fontSize="sm" color="gray.500" pr="4" maxW={220}>
          {description}
        </Box>
      </Box>
    </Flex>
  </MenuItem>
);

const ImportMenu = ({document}: {document: Document}) => {
  const {isOpen: supportIsOpen, onOpen: supportOnOpen, onClose: supportOnClose} = useDisclosure();

  return (
    <>
      <Menu>
        <MenuButton
          as={Button}
          rightIcon={<Icon as={ChevronDownIcon} />}
          colorScheme="green"
          leftIcon={<Icon as={ArrowDownTrayIcon} />}
        >
          Import
        </MenuButton>
        <PortalMenuList fontSize="md">
          <StyledMenuItem
            icon={UserCircleIcon}
            description="Have our support team handle importing from a different format"
            onClick={supportOnOpen}
          >
            Via support
          </StyledMenuItem>
        </PortalMenuList>
      </Menu>
      <ContactSupportModal
        isOpen={supportIsOpen}
        onClose={supportOnClose}
        title="Import via support"
        action="import-document"
        subject="Import document request"
        initialBody={"Hi Platformed,\nPlease import this document for me."}
        instructions={"Attach your document(s) to this email. You can write in any other instructions below."}
        appendToBody={`\n\n---\n\nReference: ${document.document_id}`}
      />
    </>
  );
};

const TemplatePage = () => {
  const {document} = useOutletContext<TabOutletContext>();
  const updatePolicyTemplate = useCallback(
    async (value: string) => {
      await api.vendorToolkit.documents.updateContent(document.document_id, {
        authority: DocumentAuthority.Template,
        authoring_config: document.authoring_config,
        authoring_template: value,
      });
    },
    [document.document_id, document.authoring_config],
  );
  return (
    <Flex direction="column" align="flex-end" gap={4} p={4}>
      <ImportMenu document={document} />
      <CommitTextarea
        minH="500px"
        value={document.authoring_template ?? ""}
        onCommit={updatePolicyTemplate}
        fontFamily="monospace !important"
        p="4"
      />
    </Flex>
  );
};

export default TemplatePage;
