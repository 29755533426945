import {useState} from "react";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Link,
  Stack,
  Text,
} from "@chakra-ui/react";

import {usePromiseState} from "../hooks/promiseState";
import {Form, Link as RouterLink} from "react-router-dom";
import AuthError from "./AuthError";
import api, {HTTPError} from "../api";
import {getRedirectParams} from "../utils/auth";

const Register = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  const [requestingReset, requestReset] = usePromiseState(async () => {
    try {
      const redirect = getRedirectParams().get("redirect") ?? undefined;
      return await api.auth.register({name, email, redirect});
    } catch (ex) {
      if (ex instanceof HTTPError) {
        switch (ex.response.status) {
          case 429:
            throw new Error("Too many registration requests for this email. Please wait for an hour before retrying.");
        }
      }
      console.error(ex);
      throw new Error("Error requesting registration");
    }
  }, [name, email]);

  return (
    <Form
      onSubmit={e => {
        e.stopPropagation();
        e.preventDefault();
        requestReset();
      }}
    >
      <Heading fontSize="32" fontWeight="600">
        Register
      </Heading>
      <Text fontSize="md" my="4">
        Sign up to Platformed as a new user.
      </Text>

      <AuthError error={requestingReset.lastError?.toString()} mt={12} />
      {requestingReset.lastResult ? (
        <Alert status="info" borderRadius="md" mt={12}>
          <AlertIcon boxSize="40px" />
          <Box>
            <AlertTitle fontSize="md">Registration requested</AlertTitle>
            <AlertDescription fontSize="md">
              <Text>Your registration request has been received.</Text>
              <Text>You have been sent a link to complete your registration.</Text>
            </AlertDescription>
          </Box>
        </Alert>
      ) : null}

      <Stack spacing={8} mt="8">
        <FormControl>
          <FormLabel>Name</FormLabel>
          <Input type="text" value={name} onChange={e => setName(e.target.value)} />
        </FormControl>
        <FormControl>
          <FormLabel>Email</FormLabel>
          <Input type="email" placeholder="name@domain.com" value={email} onChange={e => setEmail(e.target.value)} />
        </FormControl>

        <Flex alignItems="center">
          <Box>
            <Link as={RouterLink} fontSize="md" color="blue.500" to={`/login?${getRedirectParams()}`}>
              Back to login
            </Link>
          </Box>
        </Flex>
      </Stack>

      <Button mt="12" colorScheme="blue" w="full" type="submit" isLoading={requestingReset.inProgress}>
        Register
      </Button>
    </Form>
  );
};

export default Register;
