import {Link as RouterLink} from "react-router-dom";
import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  Heading,
  Icon,
  Stack,
  Tab as ChakraTab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  TabProps,
} from "@chakra-ui/react";
import {Question as QuestionT, QuestionStatus as QuestionStatusT} from "../../Types";
import {ArrowPathIcon, XMarkIcon} from "@heroicons/react/20/solid";
import {ActionBar, ActionBarSearch} from "../../components/ActionBar";
import Import from "./Import";
import {Question} from "../components/Question";
import {useFacetedSearch} from "../../hooks/search";
import {useQueryData} from "../../state";
import {withSuspense} from "../../state/withSuspense";
import {useLayerType} from "../../hooks/layerType";
import {facetedSearchQuestion} from "../../Products/components/Filters";
import {useTypedParams} from "../../hooks/typedParams";
import {QuestionnaireParams} from "../Crumb";

export const Tab = (props: TabProps & {to: string}) => (
  <ChakraTab as={RouterLink} _focusVisible={{boxShadow: "none"}} {...props} />
);

const Questionnaire = withSuspense(({index}: {index: number}) => {
  const [layerType] = useLayerType();
  const {questionnaireId} = useTypedParams(QuestionnaireParams);
  const whoami = useQueryData({queryKey: ["whoAmI"]});
  const questionnaire = useQueryData({queryKey: ["vendorToolkit", "questionnaire", questionnaireId]});
  const existingQuestionsByNumber: {[n in string]: QuestionT} = {};
  questionnaire.sections
    .flatMap(section => section.questions)
    .forEach(q => {
      if (q.question_number) {
        existingQuestionsByNumber[q.question_number] = q;
      }
    });

  const {query, queries, setQuery, filter} = useFacetedSearch(
    (q: QuestionT) => q.text,
    facetedSearchQuestion(
      {
        owners: ["ASSIGNED_TO_ME", "ASSIGNED_TO_OTHERS", "UNASSIGNED"],
        statuses: Object.keys(QuestionStatusT),
      },
      layerType,
      whoami.associated_owners,
    ),
    [whoami],
  );

  const {result} = filter(questionnaire.sections.flatMap(section => section.questions));

  return (
    <Stack>
      <Heading p={4} pb={0} size="md" mb={2}>
        {questionnaire.name}
      </Heading>
      <Box p={4} display="none">
        <ButtonGroup isAttached variant="outline" size="md" w="full">
          <Button leftIcon={<Icon as={ArrowPathIcon} />}>Export</Button>
          <Button leftIcon={<Icon as={XMarkIcon} />}>Unlink</Button>
        </ButtonGroup>
      </Box>
      <Tabs flex="1" display="flex" flexDirection="column" index={index} isLazy>
        <TabList>
          <Tab to="../questions">Questions</Tab>
          <Tab to="../import">Import</Tab>
          <Tab to="../export" isDisabled>
            Export
          </Tab>
        </TabList>
        <TabPanels flex="1">
          <TabPanel as={Stack} p={0} spacing={0}>
            <ActionBar px={4}>
              <ActionBarSearch value={query} onChange={e => setQuery(e.target.value)} />
            </ActionBar>
            <Stack spacing={0} flex={1} bg="white" divider={<Divider borderColor="gray.300" />}>
              {result.map(q => (
                <Question text={q.text} number={q.question_number} key={q.question_id} queries={queries} />
              ))}
            </Stack>
          </TabPanel>
          <TabPanel as={Stack} p={0} spacing={0}>
            <Import questionnaire={questionnaire} existingQuestionsByNumber={existingQuestionsByNumber} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Stack>
  );
});

export default Questionnaire;
