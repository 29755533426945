import {TimerId} from "../utils/time";
import api from "../api";
import {ExternalAuthorizationProvider} from "../Types";
import {usePromiseState} from "./promiseState";
import {getOAuthRedirectUrl, oAuthRedirectTargetFromProvider} from "../utils/environment";

// Copy what MSAL.js does
const POPUP_WIDTH = 483;
const POPUP_HEIGHT = 600;
const POLL_INTERVAL_MS = 30;

export type GenericOauthOptions = {
  authUrl: string;
  clientId: string;
  scope: string;
  extra: Record<string, string | undefined>;
  provider: ExternalAuthorizationProvider;
  purpose: string;
};

export class OauthError extends Error {}

function waitForPopupRedirect(wnd: WindowProxy): Promise<string> {
  let timerId: TimerId;
  return new Promise<string>((resolve, reject) => {
    timerId = setInterval(() => {
      try {
        const url = new URL(wnd.location.href);
        if (url.origin === window.origin && url.searchParams.has("code")) {
          resolve(url.searchParams.get("code")!);
        } else if (url.origin === window.origin && url.searchParams.has("error")) {
          let errorMessage = url.searchParams.get("error") ?? "";
          const errorDescription = url.searchParams.get("error_description");
          if (errorDescription) {
            errorMessage += "\n" + errorDescription;
          }
          reject(new OauthError(errorMessage));
        } else if (wnd.closed) {
          reject(new OauthError("Window closed"));
        }
      } catch (ex) {
        // Ignore exceptions caused by same-origin policy
      }
    }, POLL_INTERVAL_MS);
  }).finally(() => {
    clearInterval(timerId);
    if (!wnd.closed) {
      wnd.close();
    }
  });
}

function definedEntry(entry: [string, string | undefined]): entry is [string, string] {
  return entry[1] !== undefined;
}

export function useGenericOauth() {
  return usePromiseState(async (options: GenericOauthOptions) => {
    const winLeft = window.screenLeft ? window.screenLeft : window.screenX;
    const winTop = window.screenTop ? window.screenTop : window.screenY;
    const winWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    const winHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
    const top = Math.max(0, winHeight / 2 - POPUP_HEIGHT / 2 + winTop);
    const left = Math.max(0, winWidth / 2 - POPUP_WIDTH / 2 + winLeft);

    const args = Object.entries({
      response_type: "code",
      client_id: options.clientId,
      scope: options.scope,
      redirect_uri: getOAuthRedirectUrl(oAuthRedirectTargetFromProvider(options.provider)),
      ...options.extra,
    }).filter(definedEntry);
    const qs = new URLSearchParams(args);
    const url = `${options.authUrl}?${qs}`;
    const wnd = window.open(
      url,
      `popup.${options.clientId}.${options.scope}`,
      `width=${POPUP_WIDTH}, height=${POPUP_HEIGHT}, top=${top}, left=${left}, scrollbars=yes`,
    );
    if (!wnd) {
      throw new Error("Popup blocked!");
    }

    const code = await waitForPopupRedirect(wnd);

    return await api.externalAuthorizations.create({
      provider: options.provider,
      purpose: options.purpose,
      payload: {
        type: "Oauth",
        content: {
          code,
        },
      },
    });
  }, []);
}
