import {
  Button,
  Box,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalFooter,
  ModalBody,
  HStack,
  FormControl,
  FormLabel,
  Select,
  Stack,
} from "@chakra-ui/react";
import {ExternalAuthorizationProvider} from "../../../../../../Types";
import {useState} from "react";
import {DocumentSourcePayload, DocumentSourceProps} from "./AnyDocumentSource";
import {
  MicrosoftFilePickerResult,
  useMicrosoftFilePicker,
  useSharePointSiteCollections,
} from "../../../../../../hooks/microsoftFilePicker";
import {getPublicCredentials} from "../../../../../../utils/environment";
import {useGenericOauth} from "../../../../../../hooks/genericOauth";
import {useValidatedPromiseState} from "../../../../../../hooks/validationState";
import {SelectedFolder} from "../components/SelectedFolder";
import {usePromiseState} from "../../../../../../hooks/promiseState";
import AgreeToTerms from "../components/AgreeToTerms";

const SharePointPicker = ({
  onClose,
  setPayload,
}: {
  onClose: () => void;
  setPayload: (update: DocumentSourcePayload | null) => void;
}) => {
  const sharePointPicker = useMicrosoftFilePicker();
  const siteCollections = useSharePointSiteCollections();
  const [selectedSiteCollection, selectSiteCollection] = useState("<none>");
  const [_requestingAuth, requestAuth] = useGenericOauth();
  const [pickerResult, setPickerResult] = useState<MicrosoftFilePickerResult | null>(null);

  const [selecting, select] = usePromiseState(async () => {
    const result = await sharePointPicker(
      {type: "SharePoint", url: selectedSiteCollection, account: siteCollections.data?.account},
      {
        search: {
          enabled: true,
        },
        typesAndSources: {
          mode: "folders",
        },
      },
    );
    setPickerResult(result);
  }, [sharePointPicker, selectedSiteCollection, siteCollections]);

  const [authorizing, authorize] = useValidatedPromiseState(async () => {
    if (!pickerResult) {
      return;
    }
    const pickedItem = pickerResult.items[0];
    const requestAuthRes = await requestAuth({
      authUrl: `${pickerResult.authority}/oauth2/v2.0/authorize`,
      clientId: getPublicCredentials().ms_client_id,
      scope: "offline_access Files.Read.All",
      provider: ExternalAuthorizationProvider.Microsoft,
      purpose: "Sync documents from SharePoint",
      extra: {
        login_hint: pickerResult.account.username,
      },
    });
    if (requestAuthRes.ok) {
      const externalAuthorization = requestAuthRes.result;
      setPayload({
        external_authorization_id: externalAuthorization.external_authorization_id,
        config: {
          type: "Microsoft",
          content: {
            drive_id: pickedItem.parentReference.driveId,
            item_id: pickedItem.id,
            item_name: pickedItem.name,
          },
        },
      });
    } else {
      console.error(requestAuthRes.error);
    }
    onClose();
  }, [pickerResult, onClose, requestAuth, setPayload]);

  return (
    <ModalContent as="form" onSubmit={authorize}>
      <ModalHeader>Configure SharePoint</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <Stack spacing="4">
          <FormControl isRequired>
            <FormLabel>SharePoint site collection</FormLabel>
            <Select
              onChange={e => {
                setPickerResult(null);
                selectSiteCollection(e.target.value);
              }}
              value={selectedSiteCollection}
              isDisabled={siteCollections.isLoading}
            >
              <option value="<none>" disabled>
                Select a SharePoint site collection...
              </option>
              {siteCollections.data?.siteCollections.map(({name, url}) => (
                <option key={url} value={url}>
                  {name} - {url}
                </option>
              ))}
            </Select>
          </FormControl>
          <FormControl isRequired>
            <FormLabel>SharePoint folder</FormLabel>
            <SelectedFolder
              folder={pickerResult && {name: pickerResult.items[0].name}}
              isDisabled={selectedSiteCollection === "<none>"}
              isLoading={selecting.inProgress}
              onClick={select}
            >
              Select folder...
            </SelectedFolder>
          </FormControl>
        </Stack>
      </ModalBody>
      <ModalFooter>
        <HStack spacing="3">
          <Button variant="outline" onClick={onClose}>
            Cancel
          </Button>
          <Button type="submit" colorScheme="blue" isDisabled={!pickerResult} isLoading={authorizing.inProgress}>
            Authorize
          </Button>
        </HStack>
      </ModalFooter>
    </ModalContent>
  );
};

const SharePointDocumentSource = ({payload, setPayload}: DocumentSourceProps) => {
  const {isOpen, onOpen, onClose} = useDisclosure();
  const [agreedToTerms, setAgreedToTerms] = useState(!!payload);

  const config = payload?.config;
  if (config && config.type !== "Microsoft") {
    return null;
  }

  return (
    <Box w="full">
      <AgreeToTerms agreedToTerms={agreedToTerms} setAgreedToTerms={setAgreedToTerms} isDisabled={!!config} />
      <SelectedFolder
        folder={config ? {name: config.content.item_name} : null}
        onClick={onOpen}
        colorScheme="blue"
        isDisabled={!agreedToTerms}
      >
        Pick SharePoint folder...
      </SelectedFolder>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <SharePointPicker onClose={onClose} setPayload={setPayload} />
      </Modal>
    </Box>
  );
};

export default SharePointDocumentSource;
