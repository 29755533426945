import Page from "../../../../components/Page";
import {
  Heading,
  HStack,
  Icon,
  IconButton,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import {useQueryData} from "../../../../state";
import {withSuspense} from "../../../../state/withSuspense";
import {AccountFeature, CoreResponse} from "../../../../Types";
import {ActionBar} from "../../../../components/ActionBar";
import {CreateCoreResponseButton} from "./CreateCoreResponseButton";
import api from "../../../../api";
import {DeleteModalIconButton} from "../../../../components/DeleteModalButton";
import {usePromiseState} from "../../../../hooks/promiseState";
import {ScopeBadges} from "../../components/ScopeBadge";
import {PencilIcon} from "@heroicons/react/20/solid";
import {AddOrEditCoreResponseModal} from "./AddOrEditCoreResponseModal";
import RequireAccountFeature from "../../../../components/RequireAccountFeature";

const CoreResponseRow = ({coreResponse, index}: {coreResponse: CoreResponse; index: number}) => {
  const [deleting, delete_] = usePromiseState(async () => {
    await api.vendorToolkit.coreResponses.delete_(coreResponse.core_response_id);
  }, [coreResponse.core_response_id]);

  const editModal = useDisclosure();
  const deleteModal = useDisclosure();

  return (
    <>
      <Tr verticalAlign="top" fontSize="md">
        <Td w="auto" pr={0}>
          <Text color="gray.600" fontSize="md" whiteSpace="nowrap" fontWeight="500">
            #{index + 1}
          </Text>
        </Td>
        <Td pr={0} w="50%">
          <Stack>
            <Text fontWeight="500">{coreResponse.question_text}</Text>
            <RequireAccountFeature feature={AccountFeature.Scopes}>
              <ScopeBadges scopes={coreResponse.limited_to_scopes} />
            </RequireAccountFeature>
          </Stack>
        </Td>
        <Td pr={0} w="50%">
          <Text>{coreResponse.response_text}</Text>
        </Td>
        <Td w="auto">
          <HStack>
            <Tooltip label="Edit">
              <IconButton
                icon={<Icon as={PencilIcon} />}
                aria-label="Edit"
                bg="white"
                variant="outline"
                color="gray.500"
                onClick={editModal.onOpen}
              />
            </Tooltip>
            <DeleteModalIconButton
              {...deleteModal}
              delete_={delete_}
              deleting={deleting.inProgress}
              modalHeader="Delete core response"
              modalBody="Are you sure you want to delete this core response?"
              tooltip="Delete"
            />
          </HStack>
        </Td>
      </Tr>
      <AddOrEditCoreResponseModal coreResponse={coreResponse} {...editModal} />
    </>
  );
};

const CoreResponsesPage = withSuspense(() => {
  const coreResponses = useQueryData({queryKey: ["vendorToolkit", "coreResponses"]});

  return (
    <Page title="Core responses">
      <Stack h="full" spacing={0}>
        <Heading size="md" p={8}>
          Core responses
        </Heading>
        <Stack p={8} pt={0} fontSize="md" color="gray.600">
          <Text>
            Core responses are predefined answers to common questions. If a questionnaire question closely matches a
            core response question, Platformed will offer to use the core response as the answer.
          </Text>
        </Stack>
        <ActionBar
          actionButtons={
            <>
              <CreateCoreResponseButton />
            </>
          }
        />
        <Table style={{borderCollapse: "separate", borderSpacing: "0"}} size="lg">
          <Thead bg="white" borderBottom="10px solid" borderBottomColor="red.100">
            <Tr>
              <Th w="auto"></Th>
              <Th w="50%">Question</Th>
              <Th w="50%">Response</Th>
              <Th w="auto"></Th>
            </Tr>
          </Thead>
          <Tbody>
            {coreResponses.map((coreResponse, idx) => (
              <CoreResponseRow key={coreResponse.core_response_id} coreResponse={coreResponse} index={idx} />
            ))}
          </Tbody>
        </Table>
      </Stack>
    </Page>
  );
});

export default CoreResponsesPage;
