import {InputGroup, InputRightElement, Select, Spinner, TagProps} from "@chakra-ui/react";
import {useQueryData} from "../../../../state";
import {usePromiseState} from "../../../../hooks/promiseState";
import {ChangeEvent, useCallback} from "react";
import {ContentId, nominate} from "../../../../Types";

const ContentSelector = ({
  contentId,
  onChangeContent,
}: {
  contentId: ContentId | undefined;
  onChangeContent?: (contentId: ContentId | undefined) => Promise<void>;
} & TagProps) => {
  const contents = useQueryData({queryKey: ["vendorToolkit", "trustCenterContents"]});

  const [changing, change] = usePromiseState(
    async (value: string) => {
      if (onChangeContent) {
        await onChangeContent(value === "<none>" ? undefined : nominate("contentId", value));
      }
    },
    [onChangeContent],
  );

  const changeWrapper = useCallback((e: ChangeEvent<HTMLSelectElement>) => change(e.target.value), [change]);

  return (
    <InputGroup>
      <Select
        isDisabled={changing.inProgress}
        value={(changing.inProgress ? changing.lastArgs![0] : contentId) || "<none>"}
        onChange={changeWrapper}
      >
        <option value="<none>">No content</option>
        {contents.map(content => (
          <option key={content.content_id} value={content.content_id}>
            {content.title}
          </option>
        ))}
      </Select>
      {changing.inProgress && (
        <InputRightElement mr={6}>
          <Spinner color="gray.500" size="sm" />
        </InputRightElement>
      )}
    </InputGroup>
  );
};

export default ContentSelector;
