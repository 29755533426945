import {AccountUser, CreateRegisteredUser, InviteUser, RegisteredUser, TeamMin, UserId, UserMin} from "../Types";
import {invalidateQueries, setQueryData} from "../state";
import jsonApi from "./jsonApi";

export async function listRegistered() {
  const res = await jsonApi.get<AccountUser[]>("/registered_users");

  // Pre-populate users
  for (const {user} of res) {
    setQueryData(["user", user.user_id], {
      user_id: user.user_id,
      name: user.name,
      primary_email: user.primary_email,
      avatar_url: user.avatar_url,
    });
  }

  return res;
}

export async function get(userId: UserId) {
  return await jsonApi.get<UserMin>(`/users/${userId}`);
}

export async function getTeams(userId: UserId) {
  return await jsonApi.get<TeamMin[]>(`/users/${userId}/teams`);
}

export async function createRegistered(user: CreateRegisteredUser) {
  const res = await jsonApi.post<RegisteredUser>("/registered_users", user);
  await invalidateQueries([{queryKey: ["vendorToolkit", "gettingStartedChecklist"]}, {queryKey: ["registeredUsers"]}]);
  return res;
}

export async function invite(user: InviteUser) {
  const res = await jsonApi.post<RegisteredUser>("/registered_users/invite", user);
  await invalidateQueries([{queryKey: ["vendorToolkit", "gettingStartedChecklist"]}, {queryKey: ["registeredUsers"]}]);
  return res;
}

export async function remove(userId: UserId): Promise<"ok"> {
  const res = await jsonApi.delete_<"ok">(`/registered_users/${userId}`);
  await invalidateQueries([{queryKey: ["registeredUsers"]}, {queryKey: ["owner"]}, {queryKey: ["resolvedOwner"]}]);
  return res;
}
