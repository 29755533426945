import question from "./question.ts";
import adhocQuestion from "./adhocQuestion.ts";
import questionnaire from "./questionnaire.ts";
import counterparty from "./counterparty.ts";
import scope from "./scope.ts";
import document from "./document.ts";
import documentSource from "./documentSource.ts";
import task from "./task.ts";
import crmConnection from "./crmConnection.ts";
import questionSource from "./questionSource.ts";
import deal from "./deal.ts";
import fact from "./fact.ts";
import esignatureProvider from "./esignatureProvider.ts";
import esignatureTemplate from "./esignatureTemplate.ts";
import trustCenter from "./trustCenter.ts";
import asset from "./asset.ts";
import graph from "./graph.ts";
import librarySection from "./librarySection.ts";
import coreResponse from "./coreResponse.ts";

export default [
  ...question,
  ...adhocQuestion,
  ...questionnaire,
  ...counterparty,
  ...scope,
  ...document,
  ...documentSource,
  ...crmConnection,
  ...questionSource,
  ...task,
  ...deal,
  ...fact,
  ...esignatureProvider,
  ...esignatureTemplate,
  ...trustCenter,
  ...asset,
  ...graph,
  ...librarySection,
  ...coreResponse,
];
