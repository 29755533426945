import {HStack, Img, Link, Text} from "@chakra-ui/react";
import NdaFlowsScreenshot from "../../../assets/new_features/nda_flows.png";
import {AccountFeature} from "../../Types";
import {Feature} from ".";

export default {
  heading: "Introducing NDA flows for trust centers",
  text: (
    <>
      <Text>
        We've updated our trust center product, Showcase, to support NDA flows. You can now require your trust center
        visitors to sign an NDA before accessing sensitive documents.
      </Text>
      <Link
        href="https://platformed.com/blog/may-product-updates-nda-flows-for-trust-centers"
        target="_blank"
        color="blue.500"
        fontWeight="semibold"
      >
        Find out more in our blog post.
      </Link>
    </>
  ),
  image: (
    <HStack
      bg="orange.200"
      bgGradient="linear(165deg, cyan.200, blue.200, blue.300)"
      alignItems="center"
      justifyContent="center"
      p={4}
    >
      <Img src={NdaFlowsScreenshot} rounded="md" />
    </HStack>
  ),
  publishDate: new Date(Date.parse("23 Feb 2024")),
  actionText: "Go to e-signature providers",
  actionRequiredAccountFeatures: [AccountFeature.TrustCenter],
  url: "/vendor-toolkit/integrations/esignature-providers",
} satisfies Feature;
