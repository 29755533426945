import {useQueryData} from "../../../state/index.ts";
import {Avatar, Box, Flex, Heading, HStack, Icon, IconButton, Tooltip, Text} from "@chakra-ui/react";
import {RegisteredUser, Team} from "../../../Types.ts";
import groupBy from "../../../utils/groupBy.tsx";
import {usePromiseState} from "../../../hooks/promiseState.ts";
import api from "../../../api/index.ts";
import {MinusIcon} from "@heroicons/react/20/solid";
import {withSuspense} from "../../../state/withSuspense.tsx";

export const RemoveMemberButton = ({team, member}: {team: Team; member: RegisteredUser}) => {
  const [disablingTeam, disableTeam] = usePromiseState(async () => {
    await api.teams.removeMember(team.team_id, member.user_id);
  }, [member.user_id, team.team_id]);

  return (
    <Tooltip label={"Remove member from team"}>
      <IconButton
        flex="0 0 auto"
        icon={<Icon as={MinusIcon} />}
        onClick={disableTeam}
        aria-label="Disable"
        isDisabled={disablingTeam.inProgress}
        isLoading={disablingTeam.inProgress}
        bgColor={"white"}
        variant={"outline"}
        color="gray.500"
      />
    </Tooltip>
  );
};

const MemberRow = ({team, user}: {team: Team; user: RegisteredUser}) => {
  return (
    <HStack
      color="gray.700"
      _hover={{color: "black"}}
      transitionProperty="color"
      transitionDuration="200ms"
      gap={6}
      py={4}
      pl={24}
      pr={6}
    >
      <Flex flex={1} align="center" gap={6}>
        <Avatar h="10" w="10" name={user.name} flexShrink="0" />
        <Heading size="sm" noOfLines={1}>
          {user.name}
        </Heading>
      </Flex>
      <Box flex={2} color="gray.600" whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden" fontSize="md">
        {user.primary_email}
      </Box>
      <RemoveMemberButton team={team} member={user} />
    </HStack>
  );
};

const MembersList = withSuspense(({team: team}: {team: Team}) => {
  const members = useQueryData({queryKey: ["teamMembers", team.team_id]});

  const groupedUsers = groupBy(members, u => u.name.toUpperCase()[0]).sort((a, b) => a.key.localeCompare(b.key));

  return (
    <Box>
      {groupedUsers.length === 0 ? (
        <Text pl={24} color="gray.500" py={4}>
          No members
        </Text>
      ) : (
        groupedUsers.map(group => (
          <Box key={group.key}>
            {group.items.map(user => (
              <MemberRow key={user.user_id} team={team} user={user} />
            ))}
          </Box>
        ))
      )}
    </Box>
  );
});
export default MembersList;
