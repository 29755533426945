import {Alert, AlertIcon, AlertDescription, Text, useDisclosure, Stack} from "@chakra-ui/react";
import {usePromiseState} from "../../../../hooks/promiseState";
import {EsignatureProvider} from "../../../../Types";
import api from "../../../../api";
import {router} from "../../../../router";
import {DeleteModalButton} from "../../../../components/DeleteModalButton";

type Props = {
  esignatureProvider: EsignatureProvider;
};

export function DeleteEsignatureProviderModalButton({esignatureProvider}: Props) {
  const {onOpen, isOpen, onClose} = useDisclosure();

  const [deleting, delete_] = usePromiseState(async () => {
    await api.vendorToolkit.esignatureProviders.delete_(esignatureProvider.esignature_provider_id);
    onClose();
    await router!.navigate("/vendor-toolkit/configuration/integrations/esignature-providers");
  }, [esignatureProvider, onClose]);

  return (
    <DeleteModalButton
      onOpen={onOpen}
      isOpen={isOpen}
      onClose={onClose}
      delete_={delete_}
      deleting={deleting.inProgress}
      modalHeader="Delete e-signature provider"
      modalBody={
        <Stack>
          <Text>Are you sure you want to delete this e-signature provider?</Text>
          <Alert status="error">
            <AlertIcon />
            <AlertDescription>This action cannot be undone!</AlertDescription>
          </Alert>
        </Stack>
      }
    />
  );
}
