import {SelfEnrollmentDomain} from "../Types";
import {invalidateQueries} from "../state";
import jsonApi from "./jsonApi";

export async function list() {
  return await jsonApi.get<SelfEnrollmentDomain[]>("/self_enrollment_domains");
}

export async function create(selfEnrollmentDomain: SelfEnrollmentDomain) {
  const res = await jsonApi.post<SelfEnrollmentDomain>("/self_enrollment_domains", selfEnrollmentDomain);
  await invalidateQueries([{queryKey: ["selfEnrollmentDomains"]}]);
  return res;
}

export async function delete_(domain: string) {
  const res = await jsonApi.delete_<"ok">(`/self_enrollment_domains/${domain}`);
  await invalidateQueries([{queryKey: ["selfEnrollmentDomains"]}]);
  return res;
}
