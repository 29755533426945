import {ReactNode} from "react";
import {Navigate, PathRouteProps, Route} from "react-router-dom";
import RouterTabs from "./Tabs";
import DefaultPage from "./DefaultPage";

export type RouteTab = {
  title: ReactNode;
  path: string;
  icon?: React.ForwardRefExoticComponent<
    Omit<React.SVGProps<SVGSVGElement>, "ref"> & {
      title?: string | undefined;
      titleId?: string | undefined;
    } & React.RefAttributes<SVGSVGElement>
  >;
  element?: ReactNode;
  routes?: ReactNode;
};

type RouterTabsProps = {title?: string; tabs: RouteTab[]; defaultPath?: string};

export function tabbedRoute({title, tabs, element, handle, defaultPath, ...props}: PathRouteProps & RouterTabsProps) {
  return (
    <Route {...props} element={element ?? <DefaultPage title={title} />} handle={handle ?? {crumb: title}}>
      <Route element={<RouterTabs tabs={tabs} />}>
        {tabs.map(tab => (
          <Route key={tab.path} path={tab.path} element={tab.element} handle={{crumb: tab.title}}>
            {tab.routes}
          </Route>
        ))}
      </Route>
      <Route index element={<Navigate to={defaultPath ?? tabs[0].path} replace />} />
    </Route>
  );
}
