import {Link, chakra, Text} from "@chakra-ui/react";
import {Question, QuestionnaireId} from "../../../../../../../Types";
import {Link as RouterLink} from "react-router-dom";
import {getResponseLayer, useLayerType} from "../../../../../../../hooks/layerType";
import {useMemo} from "react";

/**
 * A small widget indicating that a question either has relevant core responses
 * or has a linked core response. This does not allow the user to edit the
 * state, but instead links to the more detailed question page.
 */
function CoreResponseMinimal({questionnaireId, question}: {questionnaireId: QuestionnaireId; question: Question}) {
  const [layerType] = useLayerType();
  const responseLayer = getResponseLayer(question.response_layers, layerType);

  const text = useMemo(() => {
    if (responseLayer.linked_core_response != null) {
      return "Answered using a core response";
    }

    const relevantCount = question.relevant_core_responses.length;

    if (relevantCount > 0) {
      return (
        <>
          <chakra.span fontWeight="500">{relevantCount}</chakra.span> core response
          {relevantCount === 1 ? "" : "s"} available
        </>
      );
    }

    return null;
  }, [question.relevant_core_responses.length, responseLayer.linked_core_response]);

  if (text == null) {
    return null;
  }

  return (
    <Link as={RouterLink} to={`/vendor-toolkit/questionnaires/${questionnaireId}/questions/${question.question_id}`}>
      <Text textDecoration="underline" whiteSpace="nowrap" fontSize="sm" color="blue.500">
        {text}
      </Text>
    </Link>
  );
}

export default CoreResponseMinimal;
