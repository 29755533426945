import {useOutlet} from "react-router-dom";
import {HTTPError} from "../../api/index.ts";
import {useIsAdmin} from "../../hooks/roles.ts";

const AdminFrame = () => {
  const outlet = useOutlet();
  const isAdmin = useIsAdmin();

  if (!isAdmin) {
    throw new HTTPError(
      new Response(null, {
        status: 403,
        statusText: "You need to be an admin to view this page.",
      }),
    );
  }

  return outlet;
};

export default AdminFrame;
