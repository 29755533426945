import {UserMin} from "../../../Types";

import {
  Button,
  Flex,
  Icon,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItemOption,
  MenuOptionGroup,
  Text,
} from "@chakra-ui/react";
import {ChevronDownIcon, UsersIcon} from "@heroicons/react/20/solid";
import {Counts} from "../../../hooks/search";
import Only from "./Only";
import PortalMenuList from "../../../components/PortalMenuList";

const FilterAskers = ({
  users,
  askers,
  setAskers,
  counts,
}: {
  users: UserMin[];
  askers: string[];
  setAskers: (a: string[]) => void;
  counts?: Counts;
}) => {
  const hasAll = askers.includes("ALL");
  const value = hasAll ? [...askers, ...users.map(user => user.user_id)] : askers;
  const count = hasAll ? value.length - 1 : value.length;

  const onChangeAskers = (v: string | string[]) => {
    const arr = typeof v === "string" ? [v] : v;
    let newAskers;
    // If a normal user was unchecked while "ASSIGNED" is checked
    if (arr.length < value.length && arr.includes("ALL")) {
      // Switch to precise filtering
      newAskers = arr.filter(item => item !== "ALL");
    } else if (arr.includes("ALL") || value.includes("ALL")) {
      // If ASSIGNED is or was checked, then filter out normal users
      newAskers = arr.filter(item => item === "ALL");
    } else {
      newAskers = arr;
    }
    if (newAskers.length !== 0) {
      setAskers(newAskers);
    }
  };

  const askerCount = (counts: Counts, asker: string): number => {
    if (asker === "ALL") {
      return Object.entries(counts).reduce((a, b) => a + b[1], 0);
    }
    return counts[asker];
  };

  const only = (user: string) => (e: any) => {
    e.stopPropagation();
    setAskers([user]);
  };

  return (
    <Menu placement="bottom-end" closeOnSelect={false}>
      <MenuButton
        as={Button}
        colorScheme="blue"
        rightIcon={<Icon as={ChevronDownIcon} />}
        leftIcon={<Icon as={UsersIcon} />}
      >
        Askers ({count})
      </MenuButton>
      <PortalMenuList fontSize="md" maxH="50vh" overflowY="auto">
        <MenuOptionGroup type="checkbox" value={value} onChange={onChangeAskers}>
          <MenuItemOption value="ALL" _focus={{".only": {visibility: "visible"}, bg: "gray.100"}} transition="0">
            <Flex align="center">
              <Text color="orange.600" flex={1} mr={5}>
                All
              </Text>
              {counts && <Text color="gray.400">{askerCount(counts, "ALL")}</Text>}
            </Flex>
          </MenuItemOption>
          <MenuDivider />
          {users.map(({user_id, name}) => (
            <MenuItemOption
              value={user_id}
              key={user_id}
              _focus={{".only": {visibility: "visible"}, bg: "gray.100"}}
              transition="0"
            >
              <Flex align="center">
                <Text flex={1} mr={5}>
                  {name}
                </Text>
                <Only onClick={only(user_id)} />
                {counts && <Text color="gray.400">{askerCount(counts, user_id)}</Text>}
              </Flex>
            </MenuItemOption>
          ))}
        </MenuOptionGroup>
      </PortalMenuList>
    </Menu>
  );
};

export default FilterAskers;
