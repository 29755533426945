import {Route, createRoutesFromElements, createBrowserRouter} from "react-router-dom";
import * as Sentry from "@sentry/react";

import trustCenterRoutes from "../TrustCenter";
import appRoutes from "../appRoutes";
import {getFrontendEnvironment} from "../utils/environment";
import {CustomDomainType} from "../Types";
import BubbleError from "../BubbleError";
import {setRouter} from ".";

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const domainType =
  getFrontendEnvironment().custom_domain?.domain_type ??
  (window.location.host.startsWith("trust.") ? CustomDomainType.TrustCenter : null);

export function initRouter() {
  const router = sentryCreateBrowserRouter(
    createRoutesFromElements(
      <Route path="/" errorElement={<BubbleError />}>
        {domainType === CustomDomainType.TrustCenter ? trustCenterRoutes : appRoutes}
      </Route>,
    ),
  );
  setRouter(router);
}
