import {useMemo} from "react";
import {useQueryData} from "../../../../state";
import {EntityId, GetGraphQuery, GraphOperation, KnownEntityProperty, SubGraph} from "../../../../Types";
import {GraphOperationsBuilder} from "../operationsBuilder";
import {getChildEntities, getFirstRootEntity} from "../util";

export type SubprocessorDescription = {
  name: string;

  websiteUrl: string;
  trustCenterUrl: string;
  description: string;
  sharedData: string;

  gdprSccs: boolean;
  gdprBasedInEurope: boolean;
  gdprEquivalence: boolean;

  isoCountryCode: string;
};

export type Subprocessor = {id: EntityId} & SubprocessorDescription;

export const SUBPROCESSOR_PROPERTIES = [
  "name",
  "website_url",
  "trust_center_url",
  "description",
  "shared_data",
  "gdpr_sccs",
  "gdpr_based_in_europe",
  "gdpr_equivalence",
  "iso_country_code",
] as const satisfies readonly KnownEntityProperty[];

const SUBPROCESSOR_STRING_FIELD_PROPERTY_MAP = [
  ["name", "name"],
  ["websiteUrl", "website_url"],
  ["trustCenterUrl", "trust_center_url"],
  ["description", "description"],
  ["sharedData", "shared_data"],
  ["isoCountryCode", "iso_country_code"],
] as const satisfies readonly (readonly [keyof Subprocessor, (typeof SUBPROCESSOR_PROPERTIES)[number]])[];

const SUBPROCESSOR_BOOLEAN_FIELD_PROPERTY_MAP = [
  ["gdprSccs", "gdpr_sccs"],
  ["gdprBasedInEurope", "gdpr_based_in_europe"],
  ["gdprEquivalence", "gdpr_equivalence"],
] as const satisfies readonly (readonly [keyof Subprocessor, (typeof SUBPROCESSOR_PROPERTIES)[number]])[];

const SELECT_PROPERTIES = ["legal_entity_type", ...SUBPROCESSOR_PROPERTIES, "subprocessor"];
const FILTER_PROPERTIES = ["is_root_legal_entity"];

export const SUBPROCESSORS_GRAPH_QUERY = {
  filter_properties: FILTER_PROPERTIES,
  selected_properties: SELECT_PROPERTIES,
} satisfies GetGraphQuery;

export function useSubprocessors(): Subprocessor[] {
  const graph = useQueryData({queryKey: ["vendorToolkit", "graph", SUBPROCESSORS_GRAPH_QUERY]});
  const rootEntity = getFirstRootEntity(graph)!;

  const subprocessors = useMemo(() => getSubprocessorsFromEntityGraph(graph, rootEntity.id), [graph, rootEntity]);

  return subprocessors;
}

export function getSubprocessorsFromEntityGraph(graph: SubGraph, parentEntityId: EntityId): Subprocessor[] {
  const subprocessors: {[k: string]: Subprocessor} = {};
  const childEntitiesAndRelationships = getChildEntities(graph, ["subprocessor"], parentEntityId);

  for (const {childEntity} of childEntitiesAndRelationships) {
    if (subprocessors[childEntity.id] !== undefined) {
      // Ignore duplicate subprocessors.
      continue;
    }

    if (childEntity.properties.legal_entity_type !== "organization") {
      continue;
    }

    const subprocessor = {
      id: childEntity.id,
      name: childEntity.properties.name ?? "",
      description: childEntity.properties.description ?? "",
      sharedData: childEntity.properties.shared_data ?? "",
      isoCountryCode: childEntity.properties.iso_country_code ?? "",
      trustCenterUrl: childEntity.properties.trust_center_url ?? "",
      websiteUrl: childEntity.properties.website_url ?? "",
      gdprBasedInEurope: childEntity.properties.gdpr_based_in_europe ?? false,
      gdprSccs: childEntity.properties.gdpr_sccs ?? false,
      gdprEquivalence: childEntity.properties.gdpr_equivalence ?? false,
    };

    subprocessors[childEntity.id] = subprocessor;
  }

  return Object.values(subprocessors).sort((a, b) => a.name.localeCompare(b.name));
}

/**
 * Given a subprocessor object, generate the list of operations required to
 * create the subprocessor entity and attach it to a parent.
 */
export function generateCreateOperationsForSubprocessor(
  parentEntityId: EntityId,
  subprocessor: SubprocessorDescription,
): GraphOperation[] {
  const builder = new GraphOperationsBuilder();

  const ref = builder.createEntity();

  builder.upsertStringProperty(ref, "legal_entity_type", "organization");

  for (const [field, property] of SUBPROCESSOR_STRING_FIELD_PROPERTY_MAP) {
    if (subprocessor[field].trim().length !== 0) {
      builder.upsertStringProperty(ref, property, subprocessor[field]);
    }
  }

  for (const [field, property] of SUBPROCESSOR_BOOLEAN_FIELD_PROPERTY_MAP) {
    if (subprocessor[field] === true) {
      builder.upsertBooleanProperty(ref, property, true);
    }
  }

  const relationshipRef = builder.createEntity();
  builder.upsertRelationshipProperty(relationshipRef, "subprocessor", parentEntityId, ref);

  return builder.build();
}

/**
 * Given an old and new subprocessor, generate the graph update operations
 * required to update the subprocessor.
 */
export function generateUpdateOperationsForSubprocessor(
  subprocessor: Subprocessor,
  newSubprocessorState: SubprocessorDescription,
): GraphOperation[] {
  const builder = new GraphOperationsBuilder();
  const ref = subprocessor.id;

  // Update changed properties

  for (const [field, property] of SUBPROCESSOR_STRING_FIELD_PROPERTY_MAP) {
    if (newSubprocessorState[field] !== subprocessor[field]) {
      if (newSubprocessorState[field].trim().length === 0) {
        builder.deleteProperty(ref, property);
      } else {
        builder.upsertStringProperty(ref, property, newSubprocessorState[field]);
      }
    }
  }

  for (const [field, property] of SUBPROCESSOR_BOOLEAN_FIELD_PROPERTY_MAP) {
    if (newSubprocessorState[field] !== subprocessor[field]) {
      if (newSubprocessorState[field] === false) {
        builder.deleteProperty(ref, property);
      } else {
        builder.upsertBooleanProperty(ref, property, true);
      }
    }
  }

  return builder.build();
}
