import {
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Stack,
  Textarea,
} from "@chakra-ui/react";
import {usePromiseState} from "../../../../../hooks/promiseState.ts";
import {
  CreateQuestion,
  nominate,
  QuestionId,
  Questionnaire,
  QuestionnaireId,
  QuestionStatus,
  SectionId,
} from "../../../../../Types.ts";
import api, {HTTPError} from "../../../../../api/index.ts";
import {useModalState} from "../../../../../hooks/modalState.ts";
import {AnsweringMode} from "./components/AnsweringMode/index.ts";
import SelectAnsweringMode from "./components/AnsweringMode/Select.tsx";

const AddQuestionModal = ({
  questionnaire,
  isOpen,
  onClose,
  onCreate,
}: {
  questionnaire: Questionnaire;
  isOpen: boolean;
  onClose: () => void;
  onCreate?: (questionIds: QuestionId[]) => void;
}) => {
  const [questionText, setQuestionText] = useModalState(isOpen, "");
  const [guidanceText, setGuidanceText] = useModalState(isOpen, "");
  const [answeringMode, setAnsweringMode] = useModalState(isOpen, AnsweringMode.Considered);
  const [sectionId, setSectionId] = useModalState<SectionId | null>(isOpen, null);
  const [newSectionTitle, setNewSectionTitle] = useModalState(isOpen, "");
  const [submitting, submit] = usePromiseState(
    async (questionnaireId: QuestionnaireId, question: CreateQuestion) => {
      let questionIds = [];
      try {
        questionIds = await api.vendorToolkit.questionnaires.importQuestions(questionnaireId, {
          questions: sectionId ? [{question, section_id: sectionId}] : [],
          sections: sectionId
            ? []
            : [
                {
                  title: newSectionTitle,
                  description: "",
                  questions: [question],
                },
              ],
          automate: answeringMode === AnsweringMode.Instant,
        });
      } catch (ex) {
        if (ex instanceof HTTPError) {
          if (ex.response.status === 422) {
            throw new Error("Question text must be between 1 and 512 characters.");
          }
        }
        throw ex;
      }
      setQuestionText("");
      onClose();
      if (onCreate) {
        onCreate(questionIds);
      }
    },
    [onClose, setQuestionText, onCreate, sectionId, newSectionTitle, answeringMode],
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add question</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing={4}>
            <FormControl>
              <FormLabel>Parent section</FormLabel>
              <Select
                value={sectionId ?? ""}
                onChange={e => setSectionId(e.target.value ? nominate("sectionId", e.target.value) : null)}
              >
                <option value="">New section</option>
                {questionnaire.sections.map(section => (
                  <option key={section.section_id} value={section.section_id}>
                    {section.title}
                  </option>
                ))}
              </Select>
            </FormControl>
            {!sectionId && (
              <FormControl>
                <FormLabel>New parent section title</FormLabel>
                <Input
                  data-testid="new-section-title"
                  value={newSectionTitle}
                  onChange={e => setNewSectionTitle(e.target.value)}
                  placeholder="New section title"
                />
              </FormControl>
            )}
            <FormControl isInvalid={submitting.lastError != null}>
              <FormLabel>Question text</FormLabel>
              <Textarea
                data-testid="new-question-text"
                value={questionText}
                onChange={e => setQuestionText(e.target.value)}
              />
              <FormErrorMessage>{submitting.lastError?.toString() ?? ""}</FormErrorMessage>
            </FormControl>
            <FormControl>
              <FormLabel>Guidance text</FormLabel>
              <Textarea
                data-testid="new-guidance-text"
                value={guidanceText}
                onChange={e => setGuidanceText(e.target.value)}
              />
              <FormHelperText>
                Additional information on how to answer the question. This is not used for AI answer generation.
              </FormHelperText>
            </FormControl>
            <FormControl>
              <SelectAnsweringMode value={answeringMode} onChange={setAnsweringMode} />
            </FormControl>
          </Stack>
        </ModalBody>

        <ModalFooter>
          <HStack spacing="3">
            <Button variant="ghost" onClick={onClose} isDisabled={submitting.inProgress}>
              Cancel
            </Button>
            <Button
              colorScheme="blue"
              onClick={() =>
                submit(questionnaire.questionnaire_id, {
                  text: questionText,
                  guidance: guidanceText,
                  response_layer: {
                    status: answeringMode !== AnsweringMode.None ? QuestionStatus.Automating : QuestionStatus.Respond,
                    owner_id: questionnaire.owner?.owner_id,
                    due_date: questionnaire.due_date,
                  },
                  parts: {
                    yes_no: {enabled: {type: "Literal", content: false}, config: {}},
                    select: {
                      enabled: {type: "Literal", content: false},
                      config: {
                        options: [],
                      },
                    },
                    text: {enabled: {type: "Literal", content: true}, config: {}},
                    files: {enabled: {type: "Literal", content: true}, config: {}},
                  },
                })
              }
              isDisabled={submitting.inProgress || questionText === "" || (!sectionId && newSectionTitle.length < 1)}
            >
              Submit
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddQuestionModal;
