import {HStack, Icon, Stack, Text} from "@chakra-ui/react";
import {ShieldCheckIcon} from "@heroicons/react/20/solid";
import {Internal} from "../../components/InternalMode";
import RequireAccountFeature from "../../components/RequireAccountFeature";
import {AccountFeature} from "../../Types";

type Props = {
  children?: React.ReactNode;
};

function AnsweringActionBar(props: Props) {
  return (
    <RequireAccountFeature feature={AccountFeature.ResponseWizard} showInternally>
      <Stack
        justifyContent="space-between"
        wrap="wrap"
        p="4"
        py="4"
        bg="gray.50"
        borderBottom="1px"
        borderColor="gray.200"
        spacing="4"
        rowGap="2"
      >
        <Internal>
          <HStack as="span" color="gray.600" alignItems="center">
            <Icon as={ShieldCheckIcon} />
            <Text fontSize="sm" fontWeight="600" textTransform="uppercase">
              Internal mode
            </Text>
          </HStack>
        </Internal>
        {props.children}
      </Stack>
    </RequireAccountFeature>
  );
}

export default AnsweringActionBar;
