import {Badge, HStack, Icon, Stack, Text} from "@chakra-ui/react";
import {Resolution} from "../../../../../../Types";
import {ChevronRightIcon} from "@heroicons/react/20/solid";
import {relativeDateView} from "../../../../../../utils/time";
import {useTime} from "../../../../../../hooks/time";
import {Link as RouterLink} from "react-router-dom";
import {formatResolutionReason, resolutionRenderInfo} from "../../utility/review";

type Props = {
  resolution: Resolution;
};

function ReviewItemRow({resolution}: Props) {
  const time = useTime(relativeDateView(resolution.created_at), [resolution.created_at]);

  const reason = formatResolutionReason(resolution.reason);
  const r = resolutionRenderInfo(resolution);

  return (
    <Stack
      as={RouterLink}
      to={`../review-items/${resolution.resolution_id}`}
      px={6}
      py={4}
      gap={4}
      borderBottom="1px solid"
      borderColor="gray.100"
      _hover={{bg: "gray.50"}}
    >
      <HStack gap={4} alignItems="center">
        <Text fontSize="sm" fontWeight="semibold">
          {resolution.review_necessity * 100}%
        </Text>
        <Badge fontSize="sm" colorScheme={r.badge.color}>
          {r.badge.label}
        </Badge>
        <Text fontSize="md" fontWeight={600} flexGrow={1}>
          {r.description}
        </Text>
        <Text fontSize="md" color="gray.500">
          {time}
        </Text>
        <Icon as={ChevronRightIcon} />
      </HStack>
      <Text fontSize="md">{reason}</Text>
    </Stack>
  );
}

export default ReviewItemRow;
