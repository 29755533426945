import {NotificationChannelProps} from "./AnyNotificationChannel.tsx";
import {useGenericOauth} from "../../../../hooks/genericOauth.ts";
import {useCallback} from "react";
import {NotificationChannelConnectorType} from "../../../../Types.ts";
import {Box, Button, Flex, Img, Text} from "@chakra-ui/react";
import Logo from "../../../../../assets/slack.svg";
import api from "../../../../api/index.ts";
import {SLACK_NOTIFICATION_OAUTH_OPTIONS} from "./index.tsx";

export const SlackNotificationChannel = ({payload, setPayload}: NotificationChannelProps) => {
  const [requestingAuth, requestAuth] = useGenericOauth();

  const doAuth = useCallback(async () => {
    const auth = await requestAuth(SLACK_NOTIFICATION_OAUTH_OPTIONS);
    if (!auth.ok) {
      return;
    }

    const defaultConfig = await api.notificationChannels.getDefaultConfig(
      NotificationChannelConnectorType.Slack,
      auth.result.external_authorization_id,
    );

    setPayload({
      config: defaultConfig,
      external_authorization_id: auth.result.external_authorization_id,
    });
  }, [requestAuth, setPayload]);

  const config = payload?.config;
  if (config && config.type !== "Slack") {
    return null;
  }

  return (
    <Box w="full">
      <Flex w="full">
        {config ? (
          <Flex
            flex={1}
            display="inline-flex"
            align="center"
            border="1px solid"
            borderRight="none"
            borderColor="gray.200"
            borderLeftRadius="md"
            px={4}
            gap={2}
          >
            <Img src={Logo} height="24px" aspectRatio={1} />
            <Text fontSize="md" fontWeight={500}>
              {config.content.team_name}
            </Text>
          </Flex>
        ) : (
          <Flex
            flex={1}
            display="inline-flex"
            align="center"
            border="1px solid"
            borderRight="none"
            borderColor="gray.200"
            borderLeftRadius="md"
            px={4}
            gap={2}
            fontSize="md"
            fontWeight={500}
            color="gray.500"
          >
            No account selected
          </Flex>
        )}
        <Button
          isLoading={requestingAuth.inProgress}
          isDisabled={requestingAuth.inProgress}
          onClick={doAuth}
          borderLeftRadius="none"
          colorScheme="blue"
        >
          Select Slack account...
        </Button>
      </Flex>
    </Box>
  );
};
