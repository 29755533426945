import {Button, ButtonProps, Flex, Img, Input, InputGroup, InputLeftElement} from "@chakra-ui/react";

type SelectedFolderDetail = {
  name: string;
  iconUrl?: string;
};

export function SelectedFolder({
  folder,
  placeholder,
  ...buttonProps
}: {
  folder: SelectedFolderDetail | null;
  placeholder?: string;
} & ButtonProps) {
  return (
    <Flex display="inline-flex" w="full">
      <InputGroup>
        {folder?.iconUrl && (
          <InputLeftElement pointerEvents="none" p="1">
            <Img src={folder.iconUrl} borderRadius="4px" />
          </InputLeftElement>
        )}
        <Input
          type="text"
          borderEndRadius={0}
          isReadOnly
          isDisabled={!folder}
          value={folder?.name ?? placeholder ?? "No folder selected"}
        />
      </InputGroup>
      <Button flex="none" borderStartRadius={0} {...buttonProps} />
    </Flex>
  );
}
