import {ReactNode, useCallback} from "react";
import {Accept, FileRejection} from "react-dropzone";
import {UploadingFile} from "./FileUploader";
import Dropzone from "./Dropzone";
import {Icon, Link, Stack, Text} from "@chakra-ui/react";
import {CloudArrowUpIcon} from "@heroicons/react/24/outline";
import {FileUpload, FileUploadBase} from "../../hooks/fileUpload";
import {UploadOptions} from "../../Types";

const DEFAULT_OPTIONS: UploadOptions = {};

export function MultiFileUpload<TFileUpload extends FileUploadBase = FileUpload>({
  value,
  onChange,
  accept,
  Cls,
  onDropRejected,
  options = DEFAULT_OPTIONS,
}: {
  value: TFileUpload[];
  accept?: Accept;
  onChange: (value: TFileUpload[]) => void;
  Cls: {new (file: File, options: UploadOptions): TFileUpload};
  onDropRejected?: (files: FileRejection[]) => ReactNode;
  options?: UploadOptions;
}) {
  const drop = useCallback(
    (acceptedFiles: File[]) => {
      onChange([...value, ...acceptedFiles.map(file => new Cls(file, options))]);
    },
    [value, Cls, onChange, options],
  );

  const delete_ = useCallback(
    (fileUpload: FileUploadBase) => {
      onChange(value.filter(f => f !== fileUpload));
    },
    [value, onChange],
  );

  return (
    <Stack>
      {value.map((fileUpload, i) => (
        <UploadingFile key={i} fileUpload={fileUpload} onDelete={delete_} />
      ))}
      <Dropzone onDropAccepted={drop} onDropRejected={onDropRejected} accept={accept}>
        {isDragActive => (
          <Stack spacing={2} align="center">
            <Icon
              as={CloudArrowUpIcon}
              display="block"
              fontSize="4xl"
              color={isDragActive ? "blue.500" : "gray.500"}
              mb="2"
            />
            <Text>
              Drag file(s) here or <Link color="blue.500">choose file</Link> to upload
            </Text>
          </Stack>
        )}
      </Dropzone>
    </Stack>
  );
}
