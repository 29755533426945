import {ActivitySummary} from "../../../../Types.ts";
import {Text} from "@chakra-ui/react";

const CommentIndicator = ({activitySummary}: {activitySummary: ActivitySummary}) => {
  const plural = activitySummary.counts.Comment > 1 ? "s" : "";
  return activitySummary.counts.Comment ? (
    <Text fontSize="md" color="yellow.500">
      {activitySummary.counts.Comment} internal comment{plural}
    </Text>
  ) : null;
};

export default CommentIndicator;
