import {ComponentType, Suspense, SuspenseProps, forwardRef} from "react";
import Loading from "../components/Loading";

export function withSuspense<P>(WrappedComponent: ComponentType<P>, fallback?: SuspenseProps["fallback"]) {
  fallback = fallback ?? <Loading m={8} />;
  return forwardRef((props: P, ref) => (
    <Suspense fallback={fallback}>
      <WrappedComponent ref={ref} {...props} />
    </Suspense>
  ));
}
