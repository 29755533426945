import {Button, ButtonGroup, ButtonProps, Icon, IconButton} from "@chakra-ui/react";
import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@heroicons/react/20/solid";
import {Link as RouterLink} from "react-router-dom";
import * as _ from "lodash-es";

const SIZE = 3;

export const PageNav = ({
  page,
  pageCount,
  setPage,
  buttonProps,
}: {
  page: number;
  pageCount: number;
  setPage: (page: number) => void;
  buttonProps: ButtonProps;
}) => {
  let minPage = Math.max(1, page - SIZE);
  let maxPage = minPage + SIZE * 2;
  if (maxPage > pageCount) {
    const diff = maxPage - pageCount;
    maxPage -= diff;
    minPage = Math.max(1, minPage - diff);
  }
  const pageNumbers = _.range(minPage, maxPage + 1);
  return (
    <ButtonGroup
      isAttached
      variant="ghost"
      colorScheme="blue"
      shadow="sm"
      border="1px solid"
      borderColor="gray.200"
      rounded="md"
    >
      <IconButton
        {...buttonProps}
        aria-label="First"
        isDisabled={page <= 1}
        icon={<Icon as={ChevronDoubleLeftIcon} />}
        onClick={() => setPage(1)}
      />
      <IconButton
        {...buttonProps}
        aria-label="Previous"
        isDisabled={page <= 1}
        icon={<Icon as={ChevronLeftIcon} />}
        onClick={() => setPage(page - 1)}
      />
      {pageNumbers.map(pageNo => (
        <Button
          key={pageNo}
          as={RouterLink}
          isDisabled={page === pageNo}
          onClick={() => setPage(pageNo)}
          {...buttonProps}
        >
          {pageNo}
        </Button>
      ))}
      <IconButton
        {...buttonProps}
        as={RouterLink}
        aria-label="Next"
        isDisabled={page >= pageCount}
        icon={<Icon as={ChevronRightIcon} />}
        onClick={() => setPage(page + 1)}
      />
      <IconButton
        {...buttonProps}
        as={RouterLink}
        aria-label="Last"
        isDisabled={page >= pageCount}
        icon={<Icon as={ChevronDoubleRightIcon} />}
        onClick={() => setPage(pageCount)}
      />
    </ButtonGroup>
  );
};
