import {RouteTab, tabbedRoute} from "../../../../../components/RouterTabs";
import factsTab from "../Facts";
import fileTab from "../File";
import metaTab from "../Meta";
import editorTab from "../Editor";
import templateTab from "../Template";
import aiTextTab from "../AiText";
import DocumentActionsPage from "./Actions";
import Crumb from "./Crumb";

const tabs: RouteTab[] = [fileTab, metaTab, aiTextTab, factsTab, editorTab, templateTab];

export default function documentActionsRoute(routeId: string) {
  return tabbedRoute({id: routeId, path: ":documentId", handle: {Crumb}, tabs, element: <DocumentActionsPage />});
}
