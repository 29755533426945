import {Accordion, Box, Text} from "@chakra-ui/react";
import {withSuspense} from "../../../../state/withSuspense";
import {useQueryData} from "../../../../state";
import {FactRow} from "../../Library/Facts/FactRow";
import {TooltipWithIcon} from "../../../../components/TooltipWithIcon";
import {QuestionId} from "../../../../Types";

const QuestionUsedFacts = withSuspense(({questionId}: {questionId: QuestionId}) => {
  const usedFacts = useQueryData({queryKey: ["vendorToolkit", "questionUsedFacts", questionId]});
  return usedFacts.length > 0 ? (
    <>
      <Text fontWeight="600">
        <TooltipWithIcon
          label="These are facts the AI thought were relevant when attempting to answer this question."
          placement="right"
        >
          Used facts
        </TooltipWithIcon>
      </Text>
      <Box border="1px solid" borderColor="gray.200" rounded="lg" maxH="400" overflowY="auto" overflowX="hidden">
        <Box overflow="hidden">
          <Accordion allowMultiple key={questionId} my="-1px">
            {usedFacts.map(usedFact => (
              <FactRow
                key={usedFact.fact.fact_id}
                fact={usedFact.fact}
                cosine_distance={
                  usedFact.answer_cosine_distance != null
                    ? usedFact.answer_cosine_distance + usedFact.question_cosine_distance
                    : undefined
                }
              />
            ))}
          </Accordion>
        </Box>
      </Box>
    </>
  ) : null;
});

export default QuestionUsedFacts;
