import {Alert, AlertIcon, AlertDescription, Text, Stack, useDisclosure} from "@chakra-ui/react";
import {usePromiseState} from "../../../../hooks/promiseState";
import {NotificationChannel} from "../../../../Types";
import api from "../../../../api";
import {router} from "../../../../router";
import {DeleteModalButton} from "../../../../components/DeleteModalButton";

type Props = {
  notificationChannel: NotificationChannel;
};

export function DeleteNotificationChannelModalButton({notificationChannel}: Props) {
  const {isOpen, onOpen, onClose} = useDisclosure();

  const [deleting, delete_] = usePromiseState(async () => {
    await api.notificationChannels.delete_(notificationChannel.notification_channel_id);
    onClose();
    await router!.navigate("/vendor-toolkit/configuration/integrations/notification-channels");
  }, [notificationChannel.notification_channel_id, onClose]);

  return (
    <DeleteModalButton
      onOpen={onOpen}
      isOpen={isOpen}
      onClose={onClose}
      delete_={delete_}
      deleting={deleting.inProgress}
      modalHeader="Delete notification channel"
      modalBody={
        <Stack>
          <Text>Are you sure you want to delete this notification channel?</Text>
          <Alert status="error">
            <AlertIcon />
            <AlertDescription>This action cannot be undone!</AlertDescription>
          </Alert>
        </Stack>
      }
    />
  );
}
