import {
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from "@chakra-ui/react";
import {AnsweringMode} from "./components/AnsweringMode";

export const ImportConfirmationModal = ({
  isOpen,
  onClose,
  answeringMode,
}: {
  isOpen: boolean;
  onClose: () => void;
  answeringMode: AnsweringMode;
}) => {
  const timeFrame = "one working day";

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Import confirmed</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing={4}>
            <Text fontSize="md">
              {answeringMode !== AnsweringMode.None ? (
                <>
                  Your questionnaire has been successfully uploaded! We're now automating the responses, and will return
                  them back to you within {timeFrame}.
                </>
              ) : (
                <>
                  Your questionnaire has been successfully uploaded! The import is in progress, and will complete within{" "}
                  {timeFrame}.
                </>
              )}
            </Text>
          </Stack>
        </ModalBody>
        <ModalFooter>
          <HStack spacing="3">
            <Button colorScheme="blue" onClick={onClose}>
              Ok
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
