import {Button, Icon, Menu, MenuButton, MenuItem} from "@chakra-ui/react";
import {CheckIcon, ChevronDownIcon, EyeIcon, EyeSlashIcon} from "@heroicons/react/20/solid";
import {usePromiseState} from "../../../hooks/promiseState";
import {withSuspense} from "../../../state/withSuspense";
import {useQueryData} from "../../../state";
import {useCallback} from "react";
import api from "../../../api";
import {QuestionId} from "../../../Types";
import PortalMenuList from "../../../components/PortalMenuList";

export function WatchingSelector({value, onChange}: {value: boolean; onChange: (value: boolean) => Promise<void>}) {
  const [changing, change] = usePromiseState(onChange, [onChange]);
  return (
    <Menu>
      <MenuButton
        as={Button}
        size="sm"
        leftIcon={<Icon as={value ? EyeIcon : EyeSlashIcon} />}
        rightIcon={<Icon as={ChevronDownIcon} />}
        isLoading={changing.inProgress}
        isDisabled={changing.inProgress}
        variant="ghost"
      >
        {value ? "Watching" : "Not watching"}
      </MenuButton>
      <PortalMenuList>
        <MenuItem icon={<Icon as={CheckIcon} visibility={value ? "visible" : "hidden"} />} onClick={() => change(true)}>
          Watching
        </MenuItem>
        <MenuItem
          icon={<Icon as={CheckIcon} visibility={value ? "hidden" : "visible"} />}
          onClick={() => change(false)}
        >
          Not watching
        </MenuItem>
      </PortalMenuList>
    </Menu>
  );
}

export const WatchingQuestionSelector = withSuspense(({questionId}: {questionId: QuestionId}) => {
  const value = useQueryData({queryKey: ["vendorToolkit", "watchingQuestion", questionId]});
  const change = useCallback(
    async (value: boolean) => {
      await api.vendorToolkit.questions.setWatching(questionId, value);
    },
    [questionId],
  );
  return <WatchingSelector value={value} onChange={change} />;
});
