import {useCallback} from "react";
import {CreateLoginMethod, LoginMethod} from "../../Types";
import api from "../../api";
import {useQueryData} from "../../state";
import {logout} from "../../api/auth.ts";

import {LoginMethodConfigurator} from "./components/LoginMethodConfigurator.tsx";

export const Authentication = () => {
  const whoami = useQueryData({queryKey: ["whoAmI"]});
  const loginMethods = useQueryData({queryKey: ["loginMethods"]});

  const upsert = useCallback(async (loginMethod: LoginMethod, payload: CreateLoginMethod) => {
    await api.loginMethod.upsert(loginMethod, payload);
  }, []);

  const delete_ = useCallback(async (loginMethod: LoginMethod) => {
    await api.loginMethod.delete_(loginMethod);
  }, []);

  const select = useCallback(async (loginMethod: LoginMethod) => {
    await api.loginMethod.set(loginMethod);
    await logout();
  }, []);

  return (
    <LoginMethodConfigurator
      isLoggedIn={true}
      currentMethod={whoami.user.login_method}
      enabled2fa={whoami.user.enabled_2fa}
      existingMethods={loginMethods}
      onUpsert={upsert}
      onDelete={delete_}
      onSelect={select}
    />
  );
};
