import {useQueryData} from "../../../../../state";
import {Type} from "../../../../../typing";
import {withTypedProps} from "../../../../../typing/typedProps";

export const QuestionnaireParams = Type.Object({questionnaireId: Type.QuestionnaireId()});

const Crumb = withTypedProps(QuestionnaireParams, ({questionnaireId}) => {
  return useQueryData({queryKey: ["vendorToolkit", "questionnaire", questionnaireId]}).name;
});

export default Crumb;
