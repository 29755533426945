import {CreateScope, ScopeMin, CreateScopeAxis, ScopeAxisMin, ScopeId, ScopeAxisId} from "../../Types";
import {invalidateQueries} from "../../state";
import jsonApi from "../jsonApi";

export async function list() {
  return await jsonApi.get<ScopeMin[]>("/vendor_toolkit/scopes");
}

export async function create(scope: CreateScope) {
  const res = await jsonApi.post<ScopeMin>("/vendor_toolkit/scopes", scope);
  await invalidateQueries([{queryKey: ["vendorToolkit", "scopes"]}]);
  return res;
}

export async function updateName(scopeId: ScopeId, name: string) {
  const res = await jsonApi.post<"ok">(`/vendor_toolkit/scopes/${scopeId}/name`, name);
  await invalidateQueries([{queryKey: ["vendorToolkit", "scopes"]}]);
  return res;
}

export async function listAxes() {
  return await jsonApi.get<ScopeAxisMin[]>("/vendor_toolkit/scope_axes");
}

export async function createAxis(axis: CreateScopeAxis) {
  const res = await jsonApi.post<ScopeAxisMin>("/vendor_toolkit/scope_axes", axis);
  await invalidateQueries([{queryKey: ["vendorToolkit", "scopeAxes"]}]);
  return res;
}

export async function updateAxisName(axisId: ScopeAxisId, name: string) {
  const res = await jsonApi.post<"ok">(`/vendor_toolkit/scope_axes/${axisId}/name`, name);
  await invalidateQueries([{queryKey: ["vendorToolkit", "scopeAxes"]}]);
  return res;
}

export async function updateAxisColor(axisId: ScopeAxisId, color: string) {
  const res = await jsonApi.post<"ok">(`/vendor_toolkit/scope_axes/${axisId}/color`, color);
  await invalidateQueries([{queryKey: ["vendorToolkit", "scopeAxes"]}]);
  return res;
}
