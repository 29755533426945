import {forwardRef, HStack, Icon, MenuButton, Spinner, Tag, TagLabel} from "@chakra-ui/react";
import {ChevronDownIcon} from "@heroicons/react/20/solid";
import {ReactNode} from "react";

type TagMenuButtonProps = {
  children?: ReactNode;
  colorScheme: string;
  isLoading?: boolean;
  isDisabled?: boolean;
};

const TagMenuButton = forwardRef<TagMenuButtonProps, "button">(
  ({children, colorScheme, isLoading, isDisabled, ...buttonProps}, ref) => (
    <Tag
      as={MenuButton}
      type="button"
      colorScheme={colorScheme}
      size="lg"
      borderRadius="full"
      _hover={{bg: `${colorScheme}.200`}}
      onClick={e => e.stopPropagation()}
      px={1}
      pointerEvents={isDisabled ? "none" : undefined}
      position="relative"
      textAlign="left"
      wordBreak="break-all"
      overflow="hidden"
      ref={ref}
      {...buttonProps}
    >
      <HStack justify="space-between" px={2} gap={2}>
        <HStack gap={2}>
          {isLoading ? (
            <>
              <Spinner color={`${colorScheme}.500`} size="sm" ml={-1} />
              <TagLabel>Loading</TagLabel>
            </>
          ) : (
            children
          )}
        </HStack>
        {!isDisabled && <Icon fontSize="xl" mr={-1} as={ChevronDownIcon} />}
      </HStack>
    </Tag>
  ),
);

export default TagMenuButton;
