// Required CSS import, unless you're overriding the styling
import "@knocklabs/react/dist/index.css";

import {Box, BoxProps, HStack, Img, Link} from "@chakra-ui/react";
import LogoGray from "../../assets/logo/logo-gray-text.svg";
import DocumentTitle from "./DocumentTitle";

const Page = ({title, children, ...props}: {title: string; children: React.ReactNode} & BoxProps) => {
  return (
    <>
      <DocumentTitle title={title} />
      <Box
        as="main"
        maxW="7xl"
        w="full"
        mx="auto"
        flex="1"
        borderLeft={[null, null, null, null, "1px solid"]}
        borderRight={[null, null, null, null, "1px solid"]}
        borderColor={[null, null, null, null, "gray.200"]}
        bg="white"
        roundedBottom="xl"
        // "clip" vs "hidden": Prevents content from overflowing (for those
        // sweet rounded corners), but does not create a new scrolling context
        // so "position: sticky" on children continue to stick them to the
        // document body.
        overflow="clip"
        {...props}
      >
        {children}
      </Box>
      <HStack as="footer" maxW="7xl" w="full" mx="auto" borderTop="1px solid" borderColor="gray.200" py={4} px={6}>
        <Box flex={1}>
          {/*This image is here to support our trademark. It must contain logo mark and text*/}
          <Img h="5" w="auto" src={LogoGray} alt="Platformed" />
        </Box>
        <HStack color="gray.500" fontSize="md" gap={6}>
          <Link target="_blank" href="https://platformed.com">
            Home
          </Link>
          <Link target="_blank" href="https://platformed.com/blog">
            Blog
          </Link>
          <Link href="mailto://support@platformed.com">Support</Link>
        </HStack>
      </HStack>
    </>
  );
};

export default Page;
