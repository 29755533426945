import {HStack, Img, Link, Text} from "@chakra-ui/react";
import FactsScreenshot from "../../../assets/new_features/facts.png";
import {Feature} from ".";

export default {
  heading: "Introducing Platformed Facts",
  text: (
    <>
      <Text>
        Platformed's AI-based response automation is based internally on a feature we call "Facts". Today, we're making
        these facts visible to you, the user.
      </Text>
      <Link
        href="https://platformed.com/blog/change_log_feb_24?utm_source=product&utm_medium=newfeatures&utm_campaign=facts"
        target="_blank"
        color="blue.500"
        fontWeight="semibold"
      >
        Find out more in our blog post.
      </Link>
    </>
  ),
  image: (
    <HStack
      bg="orange.200"
      bgGradient="linear(165deg, pink.200, purple.200, purple.300)"
      alignItems="center"
      justifyContent="center"
      p={4}
    >
      <Img src={FactsScreenshot} h={64} rounded="md" />
    </HStack>
  ),
  publishDate: new Date(Date.parse("23 Feb 2024")),
  actionText: "Go to facts",
  url: "/vendor-toolkit/library/facts",
} satisfies Feature;
