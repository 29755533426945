import {useEffect, useState} from "react";
import {DUMMY_PUSHER, Pusher, pusherContext} from "./context";
import {RealPusher} from "./wrappers";

type PusherProviderProps = {
  userId: string;
  appKey: string;
  cluster: string;
  children?: React.ReactNode;
};

const PusherProvider = ({userId, appKey, cluster, children}: PusherProviderProps) => {
  const [context, setContext] = useState<Pusher>(DUMMY_PUSHER);
  useEffect(() => {
    const pusher = new RealPusher(appKey, cluster);
    setContext(pusher);
    return () => {
      pusher.disconnect();
    };
  }, [setContext, userId, appKey, cluster]);

  return <pusherContext.Provider value={context}>{children}</pusherContext.Provider>;
};

export default PusherProvider;
