import {Icon, Stack, ButtonGroup, Button, Text, VStack} from "@chakra-ui/react";
import {MagnifyingGlassIcon, ArchiveBoxXMarkIcon, ChevronRightIcon} from "@heroicons/react/24/outline";
import {Link as RouterLink} from "react-router-dom";

type Props = {
  searchQuery: string;
  withinSection?: boolean;
};

function NoFactsSnippet(props: Props) {
  const fromSearch = props.searchQuery.length > 0;

  const icon = fromSearch ? MagnifyingGlassIcon : ArchiveBoxXMarkIcon;
  const title = fromSearch ? "No facts found" : "No facts here...";

  const content = (() => {
    if (fromSearch) {
      return (
        <>
          <Text fontSize="md" color="gray.500">
            No facts were found for the search query: <strong>"{props.searchQuery}"</strong>.
          </Text>
          <Text fontSize="md" color="gray.500">
            Check your spelling, or try rephrasing your query. Remember, you can search for keywords or ask questions!
          </Text>
        </>
      );
    }

    if (props.withinSection) {
      return (
        <>
          <Text fontSize="md" color="gray.500">
            No facts were found in this section.
          </Text>
          <Text fontSize="md" color="gray.500">
            You can assign facts to this section by clicking the drop down menu next to a fact.
          </Text>
        </>
      );
    }

    return (
      <>
        <VStack alignItems="flex-start">
          <Text fontSize="md" color="gray.500">
            Facts are generated from documentation and historical questionnaires in your library. Platformed uses facts
            to power its AI and generate you automated answers.
          </Text>
          <Text fontSize="md" color="gray.500">
            To generate some facts, you need to add documentation or questionnaires to the library.
          </Text>
        </VStack>
        <ButtonGroup size="sm" colorScheme="green">
          <Button
            as={RouterLink}
            to="/vendor-toolkit/library/other-documents"
            rightIcon={<Icon as={ChevronRightIcon} />}
          >
            Upload documents
          </Button>
          <Button
            as={RouterLink}
            to="/vendor-toolkit/library/historical-questionnaires"
            rightIcon={<Icon as={ChevronRightIcon} />}
          >
            Upload historical questionnaires
          </Button>
        </ButtonGroup>
      </>
    );
  })();

  return (
    <Stack spacing={4} p={8}>
      <VStack>
        <Icon boxSize={8} color="gray.400" as={icon} />
        <Text fontWeight="semibold" color="gray.700">
          {title}
        </Text>
      </VStack>
      {content}
    </Stack>
  );
}

export default NoFactsSnippet;
