import {createContext} from "react";

export type StickyHeaderContextType = {
  offset: number;
};

const stickyHeaderContext = createContext<StickyHeaderContextType>({
  offset: 0,
});

export default stickyHeaderContext;
