import {withSuspense} from "../../../../../state/withSuspense.tsx";
import {useQueryData} from "../../../../../state";
import SubscriberList from "../SubscriberList.tsx";
import {Box, Tag, HStack, Divider} from "@chakra-ui/react";

const MailingList = withSuspense(() => {
  const mailingList = useQueryData({
    queryKey: ["vendorToolkit", "trustCenterMailingList"],
  });
  return (
    <Box flex={1} padding={5}>
      <HStack padding={5} justifyContent={"flex-end"}>
        <Tag colorScheme={"blue"}>{"Active (" + mailingList.active_count + ")"}</Tag>

        <Tag colorScheme={"blue"}>{"Inactive (" + mailingList.inactive_count + ")"}</Tag>
      </HStack>
      <Divider />
      <SubscriberList subscribers={mailingList.subscribers}></SubscriberList>
    </Box>
  );
});

export default MailingList;
