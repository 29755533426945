import {memo} from "react";
import {ContactSupportModal, ContactSupportModalProps} from "../../../../components/ContactSupportModal.tsx";
import {RateUsModal} from "./RateUsModal.tsx";
import {useDisclosure} from "@chakra-ui/react";
import {QuestionnaireId} from "../../../../Types.ts";

export const ExportFileModal = memo(
  ({questionnaireId, ...props}: {questionnaireId: QuestionnaireId} & ContactSupportModalProps) => {
    const {isOpen: isRateUsOpen, onClose: closeRateUs, onOpen: openRateUs} = useDisclosure();

    return (
      <>
        <ContactSupportModal {...props} onSubmit={() => openRateUs()} />
        <RateUsModal isOpen={isRateUsOpen} onClose={closeRateUs} questionnaireId={questionnaireId} />
      </>
    );
  },
);
