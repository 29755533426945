import {Route, Navigate} from "react-router-dom";
import factRoutes from "./Facts";
import legalRoutes from "./Legal";
import subprocessorRoutes from "./Subprocessors";
import historicalQuestionnaireRoutes from "./HistoricalQuestionnaires";
import documentRoutes from "./Documents";
import factLibraryRoutes from "./FactLibrary";
import coreResponsesRoutes from "./CoreResponses";

export default (
  <Route path="library" handle={{crumb: "Library"}}>
    {documentRoutes}
    {historicalQuestionnaireRoutes}
    {factRoutes}
    {factLibraryRoutes}
    {legalRoutes}
    {subprocessorRoutes}
    {coreResponsesRoutes}
    <Route index element={<Navigate to="policies" replace />} />
  </Route>
);
