import {
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import type {RegisteredUser, UserMin} from "../../../Types";
import {memo, useCallback} from "react";
import {UserPicker, UserPickerValue} from "../../../components/UserPicker";
import {InviteUserModal} from "../Users/InviteUser";
import {useModalState} from "../../../hooks/modalState";
import {useDebounceValue} from "usehooks-ts";

export const AddMemberModal = memo(({onAdd}: {onAdd: (user: UserMin) => void}) => {
  const {isOpen, onOpen, onClose} = useDisclosure();
  const {isOpen: isInviteOpen, onOpen: onInviteOpen, onClose: onInviteClose} = useDisclosure();
  const [userOrInvite, setUserOrInvite] = useModalState<UserPickerValue>(isOpen, {type: "Invite", nameOrEmail: ""});

  const add = useCallback(() => {
    if (userOrInvite?.type === "User") {
      onClose();
      onAdd(userOrInvite.user);
    } else if (userOrInvite?.type === "Invite") {
      onInviteOpen();
    }
  }, [onAdd, onClose, onInviteOpen, userOrInvite]);
  const addUser = useCallback(
    (user: RegisteredUser) => {
      onClose();
      onAdd(user);
    },
    [onAdd, onClose],
  );

  // Autofocus doesn't work properly unless we add a delay between closing and re-opening a modal
  const [isInviteOpenDebounce] = useDebounceValue(isInviteOpen, 200);

  const initialInviteName =
    userOrInvite?.type === "Invite" && !userOrInvite.nameOrEmail.includes("@") ? userOrInvite.nameOrEmail : "";
  const initialInviteEmail =
    userOrInvite?.type === "Invite" && userOrInvite.nameOrEmail.includes("@") ? userOrInvite.nameOrEmail : "";

  return (
    <>
      <Button colorScheme="blue" size="sm" onClick={onOpen}>
        Add member
      </Button>
      <Modal isOpen={isOpen && !isInviteOpen && !isInviteOpenDebounce} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add member</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <UserPicker value={userOrInvite} onSelect={setUserOrInvite} onSubmit={add} autoFocus />
          </ModalBody>
          <ModalFooter>
            <HStack spacing="3">
              <Button variant="outline" onClick={onClose}>
                Cancel
              </Button>
              <Button colorScheme="blue" onClick={add} isDisabled={!userOrInvite}>
                {userOrInvite?.type === "Invite" ? "Invite and add" : "Add"}
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <InviteUserModal
        initialName={initialInviteName}
        initialEmail={initialInviteEmail}
        isOpen={isInviteOpenDebounce && isInviteOpen}
        onInvite={addUser}
        onClose={onInviteClose}
      />
    </>
  );
});
