import {Route} from "react-router-dom";
import ScopesPage from "./ScopesPage.tsx";
import {PaletteEntry} from "../../../components/ColorPicker.tsx";

export const COLOR_PALETTE: PaletteEntry[] = [
  {value: "gray.500", label: "Grey"},
  {value: "red.500", label: "Red"},
  {value: "gray.700", label: "Black"},
  {value: "green.500", label: "Green"},
  {value: "blue.500", label: "Blue"},
  {value: "blue.800", label: "Navy"},
  {value: "yellow.500", label: "Yellow"},
  {value: "orange.500", label: "Orange"},
  {value: "purple.500", label: "Purple"},
  {value: "pink.500", label: "Pink"},
];

export const GLOBAL_COLOR = "gray.300";

export default <Route path="scopes" element={<ScopesPage />} handle={{crumb: "Scopes"}} />;
