import {Link, Text, Box} from "@chakra-ui/react";
import {useGoogleApi} from "../../../../../../hooks/googleApi";
import {ExternalAuthorization, ExternalAuthorizationProvider} from "../../../../../../Types";
import {useCallback, useState} from "react";
import {DocumentSourceProps} from "./AnyDocumentSource";
import AgreeToTerms from "../components/AgreeToTerms";
import {SelectedFolder} from "../components/SelectedFolder";
import {getPublicCredentials} from "../../../../../../utils/environment";
import {GenericOauthOptions, useGenericOauth} from "../../../../../../hooks/genericOauth";

const GDRIVE_OAUTH_OPTIONS: GenericOauthOptions = {
  authUrl: "https://accounts.google.com/o/oauth2/v2/auth",
  clientId: getPublicCredentials().google_client_id,
  scope: "https://www.googleapis.com/auth/drive.readonly",
  extra: {
    access_type: "offline",
    prompt: "consent",
  },
  provider: ExternalAuthorizationProvider.Google,
  purpose: "Sync documents from GDrive",
};

function accessToken(auth: ExternalAuthorization) {
  if (auth.payload.type !== "Oauth") {
    throw new Error("Must use Oauth");
  }
  return auth.payload.content.access_token;
}

const GDriveDocumentSource = ({payload, setPayload}: DocumentSourceProps) => {
  const pickerLoaded = useGoogleApi("picker");
  const [agreedToTerms, setAgreedToTerms] = useState(!!payload);

  const pickerCallback = useCallback(
    (result: google.picker.ResponseObject, externalAuthorization: ExternalAuthorization) => {
      if (result.action === google.picker.Action.PICKED) {
        const doc = result.docs[0];
        setPayload({
          external_authorization_id: externalAuthorization.external_authorization_id,
          config: {
            type: "GDrive",
            content: {folder_id: doc.id, folder_name: doc.name, folder_icon_url: doc.iconUrl},
          },
        });
      }
    },
    [setPayload],
  );

  // Once authorization is done, we show the actual google drive picker
  const showPicker = useCallback(
    (externalAuthorization: ExternalAuthorization) => {
      // This is the magical incantation to allow selecting a folder...
      const folderView = new google.picker.DocsView()
        .setSelectFolderEnabled(true)
        .setIncludeFolders(true)
        .setMode(google.picker.DocsViewMode.LIST)
        .setMimeTypes("application/vnd.google-apps.folder");
      const picker = new google.picker.PickerBuilder()
        .addView(folderView)
        .setTitle("Select folder")
        .setSelectableMimeTypes("application/vnd.google-apps.folder")
        .setOAuthToken(accessToken(externalAuthorization))
        .setCallback(result => pickerCallback(result, externalAuthorization))
        .build();
      // Show the picker
      picker.setVisible(true);
    },
    [pickerCallback],
  );

  const [_requestingAuth, requestAuth] = useGenericOauth();

  // Begin the "Pick folder" flow by authenticating to google
  const googleAuth = useCallback(async () => {
    const res = await requestAuth(GDRIVE_OAUTH_OPTIONS);
    if (res.ok) {
      showPicker(res.result);
    }
  }, [requestAuth, showPicker]);

  const config = payload?.config;
  if (config && config.type !== "GDrive") {
    return null;
  }

  return (
    <Box w="full">
      <AgreeToTerms agreedToTerms={agreedToTerms} setAgreedToTerms={setAgreedToTerms} isDisabled={!!config} />
      <SelectedFolder
        folder={config ? {name: config.content.folder_name, iconUrl: config.content.folder_icon_url} : null}
        onClick={googleAuth}
        colorScheme="blue"
        isLoading={!pickerLoaded}
        isDisabled={!agreedToTerms || !pickerLoaded}
      >
        Pick Google Drive folder...
      </SelectedFolder>
      <Text fontStyle="italic" fontSize="sm" my={4} mx={2}>
        Platformed's use and transfer of information received from Google APIs to any other app will adhere to{" "}
        <Link href="https://developers.google.com/terms/api-services-user-data-policy" fontWeight="600" target="_blank">
          Google API Services User Data Policy
        </Link>
        , including the Limited Use requirements.
      </Text>
    </Box>
  );
};

export default GDriveDocumentSource;
