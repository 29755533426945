import {
  FormControl,
  Switch,
  InputGroup,
  NumberInput,
  NumberInputField,
  FormErrorMessage,
  InputRightAddon,
  Flex,
  Box,
  InputGroupProps,
} from "@chakra-ui/react";
import {InputSpinner} from "./InputSpinner";
import {useCommitText} from "../hooks/commitText";
import {memo, useCallback} from "react";

// A numeric input which has a "null"/"disabled" state

type SwitchableNumericInputProps = {
  value?: number;
  minValue?: number;
  maxValue?: number;
  defaultValue: string;
  label?: React.ReactNode;
  suffix: string;
  onCommit: (value?: number) => Promise<void>;
  alignment?: "left" | "right";
  inputGroupProps?: InputGroupProps;
  isDisabled?: boolean;
};

const SwitchableNumericInput = memo(
  ({
    value,
    minValue = -Infinity,
    maxValue = Infinity,
    defaultValue,
    onCommit,
    label,
    suffix,
    alignment,
    inputGroupProps,
    isDisabled: propIsDisabled,
  }: SwitchableNumericInputProps) => {
    // When editing we're working with text, but we want to expose a numeric value
    // to the caller, so do the conversion on commit.
    const commitText = useCallback(
      (newText: string) => onCommit(newText === "" ? undefined : parseInt(newText)),
      [onCommit],
    );
    // Before committing a value, we should clamp it to the allowed range. The
    // HTML numeric input only clamps when the up/down buttons are used :(
    const preCommit = useCallback(
      (newText: string) => {
        const newValue = parseInt(newText);
        if (isNaN(newValue)) {
          return value?.toString() ?? "";
        }
        const clampedValue = Math.max(Math.min(newValue, maxValue), minValue);
        return clampedValue.toString();
      },
      [value, minValue, maxValue],
    );
    const {currentValue, inputRef, onChange, committing, commit} = useCommitText(
      value?.toString() ?? "",
      commitText,
      preCommit,
    );
    const isDisabled = propIsDisabled || value == null || committing.inProgress;
    const displayValue = isDisabled ? currentValue || defaultValue : currentValue;
    return (
      <FormControl isInvalid={!!committing.lastError}>
        <Flex mb="2" gap="2" justify={alignment === "right" ? "space-between" : ""} w="full">
          {label}
          <Switch
            isChecked={!!value}
            onChange={e => commit(e.target.checked ? defaultValue : "")}
            isDisabled={propIsDisabled}
          />
        </Flex>
        <InputGroup
          display="flex"
          justifyContent={alignment === "right" ? "flex-end" : "flex-start"}
          {...inputGroupProps}
        >
          <Box position="relative" left="100px">
            <InputSpinner inProgress={committing.inProgress} />
          </Box>
          <NumberInput
            w="100px"
            value={displayValue}
            min={minValue}
            max={maxValue}
            isDisabled={isDisabled}
            onChange={onChange}
          >
            <NumberInputField ref={inputRef} borderRightRadius="none" />
          </NumberInput>
          <InputRightAddon>{suffix}</InputRightAddon>
        </InputGroup>
        <FormErrorMessage>{committing.lastError?.toString()}</FormErrorMessage>
      </FormControl>
    );
  },
);

export default SwitchableNumericInput;
