import {TrustCenterAccess as TrustCenterAccess} from "../../../../Types";

import {Menu, MenuItemOption, MenuOptionGroup, TagLabel, TagProps} from "@chakra-ui/react";
import {usePromiseState} from "../../../../hooks/promiseState";
import {TRUST_CENTER_READABILITY_MAP, TRUST_CENTER_VISIBILITY_MAP, TrustCenterAccessMap} from ".";
import TagMenuButton from "../../../../components/TagMenuButton";
import PortalMenuList from "../../../../components/PortalMenuList";

type TrustCenterAccessCompProps = {
  access: TrustCenterAccess;
  onChangeAccess?: (access: TrustCenterAccess) => Promise<void>;
} & TagProps;

const TrustCenterAccessComp = ({
  map,
  access,
  onChangeAccess,
}: TrustCenterAccessCompProps & {map: TrustCenterAccessMap}) => {
  const {color, text} = map[access];

  const [changing, change] = usePromiseState(
    async (val: string | string[]) => {
      if (onChangeAccess) {
        await onChangeAccess(val as TrustCenterAccess);
      }
    },
    [onChangeAccess],
  );

  return (
    <Menu isLazy>
      <TagMenuButton colorScheme={color} isLoading={changing.inProgress} isDisabled={!onChangeAccess}>
        <TagLabel>{text}</TagLabel>
      </TagMenuButton>
      <PortalMenuList fontSize="md" onClick={e => e.stopPropagation()}>
        <MenuOptionGroup type="radio" onChange={change} value={access}>
          {Object.keys(TrustCenterAccess)
            .filter(c => map[c])
            .map(c => (
              <MenuItemOption key={c} value={c}>
                {map[c].text}
              </MenuItemOption>
            ))}
        </MenuOptionGroup>
      </PortalMenuList>
    </Menu>
  );
};

export const TrustCenterVisibilityComp = (props: TrustCenterAccessCompProps) => (
  <TrustCenterAccessComp map={TRUST_CENTER_VISIBILITY_MAP} {...props} />
);

export const TrustCenterReadabilityComp = (props: TrustCenterAccessCompProps) => (
  <TrustCenterAccessComp map={TRUST_CENTER_READABILITY_MAP} {...props} />
);
