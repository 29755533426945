import {ImportQuestions, File, QuestionStatus, Questionnaire} from "../../../../../../Types";
import {useAbortableModalState, useAsyncOperation} from "../../../../../../hooks/asyncOperation";
import {FileUpload, FileUploadBaseState} from "../../../../../../hooks/fileUpload";
import {PromiseState, PromiseStateReturnType, usePromiseState} from "../../../../../../hooks/promiseState";
import {textToQuestions} from "./importCsv";
import {useModalState} from "../../../../../../hooks/modalState";
import api, {HTTPError} from "../../../../../../api";
import {Dispatch, SetStateAction} from "react";
import {useQueryData} from "../../../../../../state";
import {formatISO} from "../../../../../../utils/date";
import {AnsweringMode} from "../components/AnsweringMode";

export type UploadState = {
  fileQuestions: ImportQuestions;
  setFileQuestions: Dispatch<SetStateAction<ImportQuestions>>;
  fileUpload: FileUpload | null;
  setFileUpload: Dispatch<SetStateAction<FileUpload | null>>;
  uploadState: FileUploadBaseState<File> | null;
  answeringMode: AnsweringMode;
  setAnsweringMode: Dispatch<SetStateAction<AnsweringMode>>;
  parsingFile: PromiseState<void, [reader: FileReader, setFileQuestions: Dispatch<SetStateAction<ImportQuestions>>]>;
  parseFile: (
    reader: FileReader,
    setFileQuestions: Dispatch<SetStateAction<ImportQuestions>>,
  ) => Promise<PromiseStateReturnType<void>>;
  clearParseFile: () => void;
  submitting: PromiseState<void, []>;
  submit: () => Promise<PromiseStateReturnType<void>>;
  clearSubmit: () => void;
};

const formatErr = (e: any) =>
  Object.values(e)
    .flatMap((errs: any) => errs.map((err: any) => err.message))
    .join(", ");

export default function useUploadState(
  questionnaire: Questionnaire,
  isOpen: boolean,
  onClose: () => void,
  onCreate?: (questionIds: string[]) => void,
): UploadState {
  const whoami = useQueryData({queryKey: ["whoAmI"]});
  const [fileQuestions, setFileQuestions] = useModalState<ImportQuestions>(isOpen, {
    questions: [],
    sections: [],
  });
  const [fileUpload, setFileUpload] = useAbortableModalState<FileUpload | null>(isOpen, null);
  const uploadState = useAsyncOperation(fileUpload);
  const [answeringMode, setAnsweringMode] = useModalState(isOpen, AnsweringMode.Considered);
  const [parsingFile, parseFile, clearParseFile] = usePromiseState(
    async (reader: FileReader, setFileQuestions: React.Dispatch<React.SetStateAction<ImportQuestions>>) => {
      // Invoked through readAsText so `as string` is infallible
      const questions = textToQuestions(reader.result as string, {
        defaultDueDate: questionnaire.due_date,
      });
      setFileQuestions({...questions, automate: answeringMode === AnsweringMode.Instant});
    },
    [questionnaire.due_date, answeringMode],
  );
  const [submitting, submit, clearSubmit] = usePromiseState(async () => {
    if (uploadState?.id !== "Uploaded") return;
    if (answeringMode !== AnsweringMode.None) {
      for (const {question} of fileQuestions.questions) {
        if (question.response_layer.status === QuestionStatus.Respond) {
          question.response_layer.status = QuestionStatus.Automating;
        }
      }
      for (const section of fileQuestions.sections) {
        for (const question of section.questions) {
          if (question.response_layer.status === QuestionStatus.Respond) {
            question.response_layer.status = QuestionStatus.Automating;
          }
        }
      }
    }
    try {
      const questionIds = await api.vendorToolkit.questionnaires.importQuestions(
        questionnaire.questionnaire_id,
        fileQuestions,
      );
      await api.vendorToolkit.questionnaires.createOriginalFile(
        questionnaire.questionnaire_id,
        uploadState.result.file_id,
      );
      if (!whoami.internal_mode) {
        await api.vendorToolkit.questionnaires.updateOriginalFileImportedAt(
          questionnaire.questionnaire_id,
          uploadState.result.file_id,
          formatISO(new Date()),
        );
      }
      onClose();
      if (onCreate) {
        onCreate(questionIds);
      }
    } catch (ex) {
      if (ex instanceof HTTPError && ex.response.status === 422) {
        const validationErrors = await ex.response.json();
        let errorList = Object.entries(validationErrors.questions ?? {}).map(
          ([questionIndex, err]) => `Question ${parseInt(questionIndex) + 1}: ${formatErr(err)}`,
        );
        errorList.push(
          ...Object.entries(validationErrors.sections ?? {}).flatMap(([sectionIndex, section]) =>
            Object.entries((section as any).questions ?? {}).map(
              ([questionIndex, err]) =>
                `Section ${parseInt(sectionIndex) + 1} Question ${parseInt(questionIndex) + 1}: ${formatErr(err)}`,
            ),
          ),
        );
        if (errorList.length > 5) {
          errorList = [...errorList.slice(0, 5), `... and ${errorList.length - 5} more error(s).`];
        }
        throw new Error("Invalid CSV.\n" + errorList.join("\n"));
      }
    }
  }, [
    onClose,
    onCreate,
    uploadState,
    answeringMode,
    fileQuestions,
    questionnaire.questionnaire_id,
    whoami.internal_mode,
  ]);
  if (!isOpen && (parsingFile.lastError || submitting.lastError)) {
    clearParseFile();
    clearSubmit();
  }

  return {
    fileQuestions,
    setFileQuestions,
    fileUpload,
    setFileUpload,
    uploadState,
    answeringMode,
    setAnsweringMode,
    parsingFile,
    parseFile,
    clearParseFile,
    submitting,
    submit,
    clearSubmit,
  };
}
