import {Button, Flex, Icon} from "@chakra-ui/react";
import {Question} from "../../Types";
import {ArrowLeftIcon, ArrowRightIcon} from "@heroicons/react/24/outline";
import {Link as RouterLink} from "react-router-dom";

export const StepNav = ({next, previous}: {next?: Question; previous?: Question}) => {
  return (
    <Flex
      bg="white"
      flexDir="row"
      px={4}
      py={2}
      borderBottom="1px solid"
      borderColor="gray.200"
      justifyContent="space-between"
    >
      <Button
        as={RouterLink}
        variant="ghost"
        isDisabled={!previous}
        colorScheme="blue"
        leftIcon={<Icon as={ArrowLeftIcon} boxSize="5" />}
        to={previous ? `../${previous.question_id}` : undefined}
      >
        Previous
      </Button>
      <Button
        variant="ghost"
        as={RouterLink}
        colorScheme="blue"
        isDisabled={!next}
        rightIcon={<Icon as={ArrowRightIcon} boxSize="5" />}
        to={next ? `../${next.question_id}` : undefined}
      >
        Next
      </Button>
    </Flex>
  );
};
