import {Box, HStack, Icon, Img, Text, Flex} from "@chakra-ui/react";
import {HeartIcon} from "@heroicons/react/24/solid";
import PlatformedIcon from "../../../assets/logo/logo.svg";
import SlackIcon from "../../../assets/new_features/slack.svg";
import {Feature} from ".";

export default {
  heading: "Get notifications from Platformed in Slack",
  text: (
    <>
      <Text>Get notified about what's happening in Platformed from within your Slack account.</Text>
      <Text>Setup takes just 2 minutes.</Text>
    </>
  ),
  image: (
    <HStack
      bg="orange.200"
      bgGradient="linear(165deg, yellow.200, orange.200, orange.300)"
      alignItems="center"
      justifyContent="center"
      py={12}
      gap={4}
    >
      <Box bg="white" rounded="full" p={4} shadow="sm">
        <Img src={PlatformedIcon} h={16} />
      </Box>
      <Flex bg="red.500" rounded="full" w={14} h={14} align="center" justify="center" shadow="sm">
        <Icon as={HeartIcon} color="white" fontSize="5xl" />
      </Flex>
      <Box bg="white" rounded="full" p={4} shadow="sm">
        <Img src={SlackIcon} h={16} />
      </Box>
    </HStack>
  ),
  publishDate: new Date(Date.parse("19 Jan 2024")),
  actionText: "Set up Slack notifications",
  url: "/vendor-toolkit/integrations/notification-channels",
} satisfies Feature;
