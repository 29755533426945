import {Link, LinkProps} from "@chakra-ui/react";
import {NavLink, NavLinkProps} from "react-router-dom";

const StyledNavLink = (props: LinkProps & NavLinkProps) => (
  <Link
    as={NavLink}
    rounded="md"
    py="2"
    px="3"
    fontSize="md"
    color="white"
    fontWeight="semibold"
    _hover={{bg: "gray.700"}}
    _activeLink={{bg: "gray.700"}}
    display="flex"
    alignItems="center"
    {...props}
  />
);

export default StyledNavLink;
