import {
  DocumentCategory,
  Documentation as DocumentationT,
  SharingClassification as SharingClassificationT,
} from "../../../../Types";
import api from "../../../../api";

export type DocumentationDetails = {
  type: string;
  name: string;
  sharing: SharingClassificationT;
  format: string;
  formatTitle: string;
  url: string;
  downloadFn: () => Promise<void>;
};

export function extractDocumentationDetails(
  {attachment}: DocumentationT,
  resolveMime: (mimeType: string) => {
    mime: string;
    name: string;
    fileType: string;
  },
): DocumentationDetails {
  let type, name: string, mimeType, sharing, url, downloadFn;
  switch (attachment.type) {
    case "Document":
      name = attachment.content.name;
      mimeType = attachment.content.file!.mime_type;
      sharing = attachment.content.sharing_classification;
      switch (attachment.content.category) {
        case DocumentCategory.Policy:
          type = "Policy";
          url = `/vendor-toolkit/library/policies/${attachment.content.document_id}`;
          break;
        case DocumentCategory.Certification:
          type = "Certification";
          url = `/vendor-toolkit/library/certifications/${attachment.content.document_id}`;
          break;
        case DocumentCategory.Report:
          type = "Report";
          url = `/vendor-toolkit/library/reports/${attachment.content.document_id}`;
          break;
        case DocumentCategory.Other:
          type = "Other document";
          url = `/vendor-toolkit/library/other-documents/${attachment.content.document_id}`;
          break;
      }
      downloadFn = async () => await api.files.download(attachment.content.file!.file_id, {override_name: name});
      break;
    case "StandaloneFile":
      type = "Uploaded file";
      name = attachment.content.file.name;
      mimeType = attachment.content.file.mime_type;
      sharing = attachment.content.sharing_classification;
      url = "";
      downloadFn = async () => await api.files.download(attachment.content.file.file_id);
      break;
  }

  let format, formatTitle;
  try {
    const mimeTypeInfo = resolveMime(mimeType);
    format = mimeTypeInfo.fileType.slice(1).toUpperCase();
    formatTitle = mimeTypeInfo.name;
  } catch (ex) {
    format = "Unknown";
    formatTitle = "The document format was not recognised";
  }
  return {type, name, sharing, format, formatTitle, url, downloadFn};
}
