import {NotificationChannelConnectorType, UpdateNotificationChannel} from "../../../../Types.ts";
import {SlackNotificationChannel} from "./SlackNotificationChannel.tsx";

export type NotificationChannelProps = {
  connectorType: NotificationChannelConnectorType;
  payload: UpdateNotificationChannel | null;
  setPayload: (payload: UpdateNotificationChannel | null) => void;
};

export const AnyNotificationChannel = (props: NotificationChannelProps) => {
  switch (props.connectorType) {
    case NotificationChannelConnectorType.Slack:
      return <SlackNotificationChannel {...props} />;
    default:
      return null;
  }
};
