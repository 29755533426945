import {
  Text,
  Button,
  Icon,
  MenuDivider,
  MenuOptionGroup,
  MenuItemOption,
  HStack,
  Tooltip,
  MenuListProps,
} from "@chakra-ui/react";
import {Fragment, useCallback} from "react";
import {SparklesIcon} from "@heroicons/react/20/solid";
import {DialectModifier, PovModifier, PromptModifierType, PromptModifiers, VerbosityModifier} from "../../../../Types";
import PortalMenuList from "../../../../components/PortalMenuList";
import StaticMenuList from "../../../../components/StaticMenuList";

export type ModifiersMenuProps = {
  value: PromptModifiers;
  usedValue?: PromptModifiers;
  defaultValue?: PromptModifiers;
  onChange: (newValue: PromptModifiers) => void;
  isDisabled?: boolean;
  parentLabel?: string;
  staticLayout?: boolean;
};

type ModifierSection = {
  [K in PromptModifierType]: {
    type: K;
    label: string;
    options: {
      label: string;
      value: Required<PromptModifiers>[K];
    }[];
  };
}[PromptModifierType];

type ModifierSections = ModifierSection[];

const sections: ModifierSections = [
  {
    type: PromptModifierType.Dialect,
    label: "Language",
    options: [
      {label: "American English", value: DialectModifier.US},
      {label: "British English", value: DialectModifier.GB},
    ],
  },
  {
    type: PromptModifierType.Pov,
    label: "Point of view",
    options: [
      {label: "First person", value: PovModifier.FirstPerson},
      {label: "Third person", value: PovModifier.ThirdPerson},
    ],
  },
  {
    type: PromptModifierType.Verbosity,
    label: "Length",
    options: [
      {label: "Descriptive", value: VerbosityModifier.Descriptive},
      {label: "Balanced", value: VerbosityModifier.Balanced},
      {label: "Concise", value: VerbosityModifier.Concise},
    ],
  },
];

const ModifiersMenuList = ({
  value,
  usedValue,
  defaultValue,
  onChange,
  isDisabled,
  parentLabel,
  staticLayout,
  menuListProps,
}: {menuListProps?: MenuListProps} & ModifiersMenuProps) => {
  const change = useCallback(
    <K extends PromptModifierType>(type: K, option: PromptModifiers[K]) => {
      onChange({...value, [type]: option});
    },
    [onChange, value],
  );

  const sectionFragment = sections.map((section, sectionIdx) => {
    const sectionUsedValue = usedValue && usedValue[section.type];
    const sectionDefaultValue = defaultValue && defaultValue[section.type];
    const sectionValue = value[section.type];
    const sectionEffectiveValue = sectionValue ?? sectionDefaultValue;
    return (
      <Fragment key={sectionIdx}>
        {sectionIdx !== 0 && <MenuDivider my={4} />}
        <MenuOptionGroup
          value={sectionEffectiveValue}
          type="radio"
          onChange={newValue => change(section.type, newValue as PromptModifiers[PromptModifierType])}
        >
          <HStack mx="4" my="2">
            <Text fontSize="sm" fontWeight="semibold" flex="1">
              {section.label}
            </Text>
            {sectionDefaultValue &&
              (sectionValue ? (
                <Button
                  size="sm"
                  variant="link"
                  colorScheme="blue"
                  isDisabled={isDisabled}
                  onClick={() => change(section.type, undefined)}
                >
                  Reset
                </Button>
              ) : (
                <Text fontSize="sm" color="gray.500">
                  From {parentLabel?.toLowerCase()}
                </Text>
              ))}
          </HStack>
          {section.options.map((option, optionIdx) => (
            <MenuItemOption
              key={optionIdx}
              fontSize="md"
              value={option.value}
              // Grey out the checkmark itself if this value is inherited
              color={sectionValue ? undefined : "gray.400"}
              isDisabled={isDisabled}
            >
              <HStack align="center" color="chakra-body-text">
                <Text flex={1}>{option.label}</Text>
                {sectionValue && option.value === sectionDefaultValue && (
                  <Text fontSize="sm" color="gray.500">
                    Default
                  </Text>
                )}
                {option.value === sectionUsedValue && (
                  <Tooltip label="This option was used to generate the most recent answer">
                    <Icon as={SparklesIcon} color="purple.500" />
                  </Tooltip>
                )}
              </HStack>
            </MenuItemOption>
          ))}
        </MenuOptionGroup>
      </Fragment>
    );
  });

  return staticLayout ? (
    <StaticMenuList minW="300px" {...menuListProps}>
      {sectionFragment}
    </StaticMenuList>
  ) : (
    <PortalMenuList minW="300px" {...menuListProps}>
      {sectionFragment}
    </PortalMenuList>
  );
};

export default ModifiersMenuList;
