import {AccountUser, LoginMethod, RegisteredUser} from "../../../Types.ts";
import {Avatar, Box, Flex, Heading, Img, Link, Stack, StackDivider, Tag, Text, useDisclosure} from "@chakra-ui/react";

import groupBy from "../../../utils/groupBy.tsx";
import {ActionBar} from "../../../components/ActionBar.tsx";
import CreateUser from "./CreateUser.tsx";
import InviteUser from "./InviteUser.tsx";
import {useQueryData} from "../../../state/index.ts";
import {withSuspense} from "../../../state/withSuspense.tsx";

import GoogleLogo from "../../../../assets/google.svg";
import MicrosoftLogo from "../../../../assets/microsoft.svg";
import {UsersIcon} from "@heroicons/react/20/solid";
import {usePromiseState} from "../../../hooks/promiseState.ts";
import api from "../../../api/index.ts";
import {DeleteModalIconButton} from "../../../components/DeleteModalButton.tsx";

const SsoAuthMethodTag = ({iconUrl, name}: {iconUrl: string; name: string}) => (
  <Tag colorScheme="gray">
    <Img h="3" mr="2" src={iconUrl} alt={name} aspectRatio={1} /> Login with {name}
  </Tag>
);

const AuthMethodTag = ({user}: {user: RegisteredUser}) => {
  switch (user.login_method) {
    case LoginMethod.GoogleOAuth:
      return <SsoAuthMethodTag iconUrl={GoogleLogo} name="Google" />;
    case LoginMethod.MicrosoftOAuth:
      return <SsoAuthMethodTag iconUrl={MicrosoftLogo} name="Microsoft" />;
    case LoginMethod.UsernamePassword:
      if (user.enabled_2fa) {
        return <Tag colorScheme="green">Password & 2FA</Tag>;
      } else {
        return <Tag colorScheme="blue">Password</Tag>;
      }
  }
};

export const DeleteUserFromAccountButton = ({user}: {user: RegisteredUser}) => {
  const {isOpen, onOpen, onClose} = useDisclosure();
  const [deletingUser, deleteUser] = usePromiseState(async () => {
    await api.users.remove(user.user_id);
    onClose();
  }, [onClose, user.user_id]);

  return (
    <DeleteModalIconButton
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      delete_={deleteUser}
      deleting={deletingUser.inProgress}
      modalHeader="Delete user from account"
      modalBody={`Are you sure you want to remove ${user.primary_email}?`}
    />
  );
};

const UserRow = ({user: {user, roles}}: {user: AccountUser}) => {
  return (
    <Flex align="center" px="6" py="5" fontSize="md" gap={6}>
      <Flex w="25%" align="center" gap={6}>
        <Avatar h="10" w="10" name={user.name} flexShrink="0" />
        <Heading size="sm" noOfLines={1}>
          {user.name}
        </Heading>
      </Flex>
      <Box flex={2} color="gray.600" whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
        {user.primary_email}
      </Box>
      <Flex align="center" gap={2}>
        {roles.map(role => (
          <Tag key={role.role_id} colorScheme="blue">
            {role.name}
          </Tag>
        ))}
      </Flex>
      <Box color="gray.700" textAlign="right">
        <AuthMethodTag user={user} />
      </Box>
      <DeleteUserFromAccountButton user={user} />
    </Flex>
  );
};

const UserList = ({users}: {users: AccountUser[]}) => {
  const groupedUsers = groupBy(users, u => u.user.name.toUpperCase()[0]).sort((a, b) => a.key.localeCompare(b.key));

  return (
    <Box as="nav" minH="0" flex="1">
      {groupedUsers.map(group => (
        <Box key={group.key} position="relative">
          <Heading
            size="sm"
            borderTop="1px solid"
            borderBottom="1px solid"
            borderColor="gray.200"
            px="6"
            py="1"
            color="gray.500"
            bg="gray.50"
          >
            {group.key}
          </Heading>
          <Stack divider={<StackDivider borderColor="gray.200" />} spacing={0}>
            {group.items.map(user => (
              <UserRow key={user.user.user_id} user={user} />
            ))}
          </Stack>
        </Box>
      ))}
    </Box>
  );
};

const Users = withSuspense(() => {
  const users = useQueryData({queryKey: ["registeredUsers"]});
  return (
    <Box>
      <ActionBar
        position="static"
        actionButtons={
          <Text fontSize="md" color="gray.500" fontStyle="italic" maxWidth="300px" textAlign="right">
            Users can be edited by{" "}
            <Link href="mailto:support@platformed.com" color="blue.500">
              creating a support ticket.
            </Link>
          </Text>
        }
      >
        <CreateUser />
        <InviteUser />
      </ActionBar>
      <UserList users={users} />
    </Box>
  );
});

export default {
  title: "Users",
  path: "users",
  icon: UsersIcon,
  element: <Users />,
};
