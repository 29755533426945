import {FormControl, FormLabel, Stack} from "@chakra-ui/react";
import {withSuspense} from "../../../state/withSuspense";
import CommitInput from "../../../components/CommitInput";
import {useCallback} from "react";
import {useQueryData} from "../../../state";
import api from "../../../api";

const ProfilePage = withSuspense(() => {
  const whoami = useQueryData({queryKey: ["whoAmI"]});
  const updateAccountName = useCallback(async (newValue: string) => {
    await api.config.changeName(newValue);
  }, []);

  return (
    <Stack my={8} mx={4} maxW="96" gap={4}>
      <FormControl>
        <FormLabel>Organisation name</FormLabel>
        <CommitInput value={whoami.account!.display_name} onCommit={updateAccountName} />
      </FormControl>
    </Stack>
  );
});

export default ProfilePage;
