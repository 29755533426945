import {useEffect, useMemo, useState} from "react";
import {TIMER_WHEEL, TimeView, ceilTime} from "../utils/time";

export function useTime<T>(view: TimeView<T>, inputs?: React.DependencyList): T {
  const [timestamp, setTimestamp] = useState(ceilTime);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const {current, expires} = useMemo(() => view(timestamp), [timestamp, ...(inputs ?? [])]);

  useEffect(
    () =>
      TIMER_WHEEL.add(expires, ts => {
        setTimestamp(ts);
      }),
    [expires],
  );

  return current;
}
