import {Icon, IconButton, Menu, MenuButton} from "@chakra-ui/react";
import {EllipsisHorizontalIcon} from "@heroicons/react/24/outline";
import React from "react";
import PortalMenuList from "../../../../../../components/PortalMenuList";

type Props = {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;

  children: React.ReactNode;
};

function MoreButton(props: Props) {
  return (
    <Menu isOpen={props.isOpen} onOpen={props.onOpen} onClose={props.onClose} isLazy>
      <MenuButton
        size="sm"
        p="1"
        as={IconButton}
        icon={<Icon as={EllipsisHorizontalIcon} width="100%" height="100%" />}
        variant="ghost"
        _hover={{
          bg: "blackAlpha.50",
        }}
      />
      <PortalMenuList>{props.children}</PortalMenuList>
    </Menu>
  );
}

export default MoreButton;
