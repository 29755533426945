import Papa from "papaparse";
import {
  AnswerOption,
  ApiDateTime,
  CreateQuestion,
  ImportQuestions,
  OwnerId,
  QuestionStatus,
  ResponseOption,
} from "../../../../../../Types";

const COLUMNS = [
  "Number",
  "Question",
  "Guidance",
  "Parts",
  "SelectOptions",
  "SelectMax",
  "ResponseYesNo",
  "ResponseSelect",
  "ResponseText",
  "SectionTitle",
] as const;
const REQUIRED_COLUMNS = ["Question"] as const;
const VALID_PARTS = ["text", "single-select", "multi-select", "yesno", "file"];
export const OPTION_SEPARATOR = "¦";

type CsvImportRow = Readonly<Partial<Record<(typeof COLUMNS)[number], string>>>;

type CsvImportOptions = {
  defaultOwnerId?: OwnerId;
  defaultDueDate?: ApiDateTime;
};

function decodeBoolean(errors: FieldErrorReporter, value?: string | null): boolean | undefined {
  if (value == null || value.trim() === "") return undefined;
  switch (value.toLowerCase().trim()) {
    case "y":
    case "yes":
    case "true":
    case "t":
    case "1":
    case "on":
      return true;
    case "n":
    case "no":
    case "false":
    case "f":
    case "0":
    case "off":
      return false;
    default:
      errors.report({severity: "Error", message: "Could not interpret value in Yes/No column."});
      return undefined;
  }
}

function decodeSelect(options?: string | null): ResponseOption[] | undefined {
  if (options == null) {
    return undefined;
  }
  return options
    .split("\n")
    .filter(o => o != "")
    .map(o => {
      const parts = o.split(OPTION_SEPARATOR, 2);
      return {
        value: parts[0] || "other",
        other_text: parts[1],
      };
    });
}

function decodeSelectOptions(options: string): AnswerOption[] {
  return options
    .split("\n")
    .filter(o => o !== "")
    .map(o => {
      const parts = o.split(OPTION_SEPARATOR, 2);
      if (parts.length === 1) {
        return {
          value: parts[0],
          label: parts[0],
          allow_other_text: false,
        };
      } else {
        const allow_other_text = parts[0].endsWith("*");
        return {
          value: allow_other_text ? parts[0].slice(0, parts[0].length - 1) : parts[0],
          label: parts[1],
          allow_other_text,
        };
      }
    });
}

function inferSelectOptions(options: ResponseOption[] | undefined): AnswerOption[] {
  const optionsOrEmpty = options ?? [];
  return optionsOrEmpty.map(option => ({
    value: option.value,
    label: option.value,
    allow_other_text: !!option.other_text,
  }));
}

const rowToQuestion = (
  errors: RowErrorReporter,
  row: CsvImportRow,
  options: CsvImportOptions,
): CreateQuestion | null => {
  if (!row.Question) {
    errors.report(null, {
      severity: "Warning",
      message: "This row was ignored because it did not contain a question",
    });
    return null;
  }

  const response_yes_no = decodeBoolean(new FieldErrorReporter(errors, "ResponseYesNo"), row.ResponseYesNo);
  const response_select = decodeSelect(row.ResponseSelect);
  const selectOptions = row.SelectOptions
    ? decodeSelectOptions(row.SelectOptions)
    : inferSelectOptions(response_select);

  if (response_select) {
    for (const responseOption of response_select) {
      if (!selectOptions.find(opt => opt.value === responseOption.value)) {
        errors.report("ResponseSelect", {
          severity: "Error",
          message: "Selected option does not exist in the SelectOptions column.",
        });
      }
    }
  }

  const seenOptionValues = new Set();
  for (const selectOption of selectOptions) {
    if (seenOptionValues.has(selectOption.value)) {
      errors.report("SelectOptions", {
        severity: "Error",
        message: `Option '${selectOption.value}' appears more than once.`,
      });
    }
    seenOptionValues.add(selectOption.value);
  }

  let selectMax = row.SelectMax ? parseInt(row.SelectMax) : undefined;
  if (selectMax !== undefined && isNaN(selectMax)) {
    errors.report("SelectMax", {
      severity: "Error",
      message: "Must be a whole number.",
    });
    selectMax = undefined;
  }

  const defaultParts = new Set<string>();
  defaultParts.add("text");
  defaultParts.add("file");
  if (row.ResponseYesNo != null) {
    defaultParts.add("yesno");
  }
  if ((response_select && response_select.length > 0) || selectOptions.length > 0 || selectMax != null) {
    defaultParts.add(
      (response_select && response_select.length > 1) || (selectMax && selectMax > 1)
        ? "multi-select"
        : "single-select",
    );
  }
  if (row.Parts === "") {
    errors.report("Parts", {
      severity: "Error",
      message: "Must specify at least on question part.",
    });
  }
  const parts = row.Parts
    ? new Set(
        row.Parts.trim()
          .toLowerCase()
          .split("\n")
          .map(part => part.trim()),
      )
    : defaultParts;

  const invalidParts = Array.from(parts).filter(p => !VALID_PARTS.includes(p as string));
  for (const invalidPart of invalidParts) {
    if (invalidPart.indexOf(",") !== -1) {
      errors.report("Parts", {
        severity: "Error",
        message: `Unrecognised value '${invalidPart}'. Multiple parts must be separated by new-lines.`,
      });
    } else {
      errors.report("Parts", {
        severity: "Error",
        message: `Unrecognised value '${invalidPart}'.`,
      });
    }
  }
  if (parts.has("single-select") && parts.has("multi-select")) {
    errors.report("Parts", {
      severity: "Error",
      message: `Cannot have both a single-select and multi-select on the same question.`,
    });
  }
  if (parts.has("single-select") || parts.has("multi-select")) {
    if (selectOptions.length === 0) {
      errors.report("SelectOption", {
        severity: "Error",
        message: `Must provide at least one select option.`,
      });
    } else if (selectOptions.length === 1) {
      errors.report("SelectOption", {
        severity: "Warning",
        message: `Only one select option was provided. Separate options must be separated by new-lines.`,
      });
    }
  } else if (selectOptions.length > 0) {
    errors.report("Parts", {
      severity: "Error",
      message: "Select options were provided but neither the 'single-select' or 'multi-select' parts were enabled.",
    });
  }

  const hasAnswer =
    (parts.has("yesno") && response_yes_no != null) ||
    (parts.has("single-select") && row.ResponseSelect) ||
    (parts.has("multi-select") && row.ResponseSelect) ||
    (parts.has("text") && row.ResponseText);

  return {
    question_number: row.Number,
    text: row.Question,
    guidance: row.Guidance,
    parts: {
      yes_no: {enabled: {type: "Literal", content: parts.has("yesno")}, config: {}},
      select: {
        enabled: {type: "Literal", content: parts.has("single-select") || parts.has("multi-select")},
        config: {
          options: selectOptions,
          max_selected: parts.has("single-select") ? 1 : selectMax,
        },
      },
      text: {enabled: {type: "Literal", content: parts.has("text")}, config: {}},
      files: {enabled: {type: "Literal", content: parts.has("file")}, config: {}},
    },
    response_layer: {
      response_yes_no,
      response_select,
      response_text: row.ResponseText,
      status: hasAnswer ? QuestionStatus.Review : QuestionStatus.Respond,
      owner_id: options.defaultOwnerId,
      due_date: options.defaultDueDate,
    },
  };
};

export type ErrorMessage = {
  severity: "Error" | "Warning";
  message: string;
};

export type CellErrors = {
  value?: string;
  cellMessages: ErrorMessage[];
};

export type RowErrors = {
  row: number;
  rowMessages: ErrorMessage[];
  cells: CellErrors[];
};

export class FileErrors {
  columns: string[];
  rows: RowErrors[];

  constructor(columns?: string[]) {
    this.columns = columns ?? [];
    this.rows = [{row: 0, rowMessages: [], cells: this.columns.map(col => ({value: col, cellMessages: []}))}];
  }

  get hasErrors() {
    return this.rows.some(
      row =>
        row.rowMessages.some(m => m.severity === "Error") ||
        row.cells.some(cell => cell.cellMessages.some(m => m.severity === "Error")),
    );
  }

  throw_if_has_errors(data: any[]) {
    if (this.hasErrors) {
      // Populate the cell values for any rows with errors
      this.rows.forEach((row, i) => {
        if (i > 0) {
          const rowData = data[i - 1];
          if (rowData) {
            row.cells.forEach((cell, j) => {
              const columnName = this.columns[j];
              if (columnName) {
                cell.value = rowData[columnName];
              }
            });
          }
        } else {
          row.cells.forEach((cell, j) => {
            cell.value = this.columns[j];
          });
        }
      });
      throw this;
    }
  }

  report(row: number, column: string | null, message: ErrorMessage) {
    if (!this.rows[row]) {
      this.rows[row] = {row, rowMessages: [], cells: this.columns.map(_col => ({cellMessages: []}))};
    }
    const rowErrors = this.rows[row];

    // Try to record the error on a specific column
    if (column != null && this.columns) {
      const columnIdx = this.columns.indexOf(column);
      if (columnIdx !== -1) {
        rowErrors.cells[columnIdx].cellMessages.push(message);
        return;
      }
    }

    // Otherwise just record it for the whole row
    rowErrors.rowMessages.push(message);
  }
}

class RowErrorReporter {
  errors: FileErrors;
  row: number;
  constructor(errors: FileErrors, row: number) {
    this.errors = errors;
    this.row = row;
  }
  report(column: string | null, message: ErrorMessage) {
    this.errors.report(this.row, column, message);
  }
}

class FieldErrorReporter {
  errors: RowErrorReporter;
  column: string | null;
  constructor(errors: RowErrorReporter, column: string | null) {
    this.errors = errors;
    this.column = column;
  }
  report(message: ErrorMessage) {
    this.errors.report(this.column, message);
  }
}

export const textToQuestions = (csv: string, options: CsvImportOptions = {}): ImportQuestions => {
  const papaResult = Papa.parse(csv, {header: true, skipEmptyLines: true});
  const errors: FileErrors = new FileErrors(papaResult.meta.fields);

  for (const error of papaResult.errors) {
    errors.report(error.row, null, {severity: "Error", message: error.message});
  }

  // Early exit on errors
  errors.throw_if_has_errors(papaResult.data);

  REQUIRED_COLUMNS.forEach(c => {
    if (!papaResult.meta.fields!.includes(c)) {
      errors.report(0, null, {
        severity: "Error",
        message: `Missing required column header '${c}'.`,
      });
    }
  });
  papaResult.meta.fields!.forEach(c => {
    if (!(COLUMNS as readonly string[]).includes(c)) {
      errors.report(0, c, {
        severity: "Warning",
        message: `Unrecognised column header '${c}'. Values in this column will be ignored.`,
      });
    }
  });

  const result = papaResult.data as readonly CsvImportRow[];
  if (result.length === 0) {
    errors.report(0, null, {
      severity: "Error",
      message: `File did not contain any questions.`,
    });
  }

  // Early exit on errors
  errors.throw_if_has_errors(papaResult.data);

  let lastSectionTitle = "";
  const importQuestions: ImportQuestions = {
    questions: [],
    sections: [],
  };

  for (let i = 0; i < result.length; ++i) {
    const row = result[i];
    if ((row.SectionTitle && row.SectionTitle !== lastSectionTitle) || !lastSectionTitle) {
      lastSectionTitle = row.SectionTitle || "Untitled section";
      importQuestions.sections.push({
        title: lastSectionTitle,
        description: "",
        questions: [],
      });
    }
    const q = rowToQuestion(new RowErrorReporter(errors, i + 1), row, options);
    if (q !== null) {
      importQuestions.sections[importQuestions.sections.length - 1].questions.push(q);
    }
  }

  // Early exit on errors
  errors.throw_if_has_errors(papaResult.data);
  return importQuestions;
};
