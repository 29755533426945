import {useState} from "react";

export function useModalState<S>(
  isOpen: boolean,
  initialValue: S | (() => S),
): [S, React.Dispatch<React.SetStateAction<S>>] {
  const [wasOpen, setWasOpen] = useState(isOpen);
  const [value, setValue] = useState(initialValue);
  if (isOpen !== wasOpen) {
    setWasOpen(isOpen);
    if (isOpen) {
      setValue(initialValue);
    }
  }
  return [value, setValue];
}
