import {
  Box,
  Button,
  ButtonProps,
  HStack,
  Icon,
  IconButton,
  IconButtonProps,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tooltip,
} from "@chakra-ui/react";
import {TrashIcon} from "@heroicons/react/24/solid";
import React from "react";

type DeleteModalProps = {
  isOpen: boolean;
  onClose: () => void;

  delete_: () => void;
  deleting: boolean;

  modalHeader: React.ReactNode;
  modalBody: React.ReactNode;
};

type DeleteModalButtonProps = {
  onOpen: () => void;
  buttonProps?: ButtonProps;
} & DeleteModalProps;

export const DeleteModalButton = ({onOpen, ...props}: DeleteModalButtonProps) => {
  return (
    <>
      <Button colorScheme="red" type="button" onClick={onOpen} {...props.buttonProps}>
        Delete
      </Button>
      <DeleteModal {...props} />
    </>
  );
};

type DeleteModalIconButtonProps = {
  onOpen: () => void;
  tooltip?: string;
  buttonProps?: IconButtonProps;
} & DeleteModalProps;

export const DeleteModalIconButton = ({onOpen, tooltip, ...props}: DeleteModalIconButtonProps) => {
  return (
    <>
      <Tooltip label={tooltip}>
        <IconButton
          aria-label="Delete"
          icon={<Icon as={TrashIcon} />}
          onClick={onOpen}
          bgColor="white"
          variant="outline"
          color="gray.500"
          {...props.buttonProps}
        />
      </Tooltip>
      <DeleteModal {...props} />
    </>
  );
};

export const DeleteModal = ({isOpen, onClose, delete_, deleting, modalHeader, modalBody}: DeleteModalProps) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{modalHeader}</ModalHeader>
        <ModalCloseButton />
        <ModalBody fontSize="md">{modalBody}</ModalBody>
        <ModalFooter>
          <HStack spacing="3">
            <Button variant="ghost" onClick={onClose}>
              Cancel
            </Button>
            <Button colorScheme="red" isDisabled={deleting} onClick={delete_} isLoading={deleting}>
              Delete
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export const DeletedItem = ({children}: {children: React.ReactNode}) => {
  return (
    <Box
      bg="gray.50"
      fontWeight="500"
      fontSize="md"
      marginY="4"
      padding="4"
      borderRadius="md"
      borderColor="gray.200"
      borderWidth="1px"
    >
      {children}
    </Box>
  );
};
