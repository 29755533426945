import {InputGroup, FormControl, FormErrorMessage, Text, Flex, Box} from "@chakra-ui/react";
import {CommitTextProps, useCommitText} from "../../../../hooks/commitText.ts";
import {InputSpinner} from "../../../../components/InputSpinner.tsx";
import {memo, useCallback, useEffect} from "react";
import {AutoResizingTextarea} from "../../../../components/AutoResizingTextarea.tsx";

type ResponseTextareaProps = CommitTextProps & {
  wordCountLimit?: number;
  bottomAttachedComponent?: React.ReactNode;
  isDisabled?: boolean;
  bottomLeftContent?: React.ReactNode;
};

function countWords(value: string): number {
  const trimmed = value.trim();
  return trimmed === "" ? 0 : trimmed.split(/\s+/).length;
}

class WordCountExceededError extends Error {
  constructor() {
    super("Word count limit exceeded.");
  }
}

const ResponseTextarea = memo(
  ({
    onCommit,
    value,
    wordCountLimit,
    bottomLeftContent,
    bottomAttachedComponent,
    isDisabled,
  }: ResponseTextareaProps) => {
    const commitWithinLimits = useCallback(
      async (newValue: string) => {
        if (wordCountLimit) {
          const numWords = countWords(newValue);
          if (numWords > wordCountLimit) {
            throw new WordCountExceededError();
          }
        }
        await onCommit(newValue);
      },
      [onCommit, wordCountLimit],
    );
    const {currentValue, inputRef, onChange, committing, commit} = useCommitText(value, commitWithinLimits);
    const wordCount = countWords(currentValue);
    const limitText = wordCountLimit ? ` / ${wordCountLimit}` : "";
    const wordCountRatio = wordCountLimit ? wordCount / wordCountLimit : 0;
    const wordCountColor = wordCountRatio < 0.9 ? "gray.600" : wordCountRatio <= 1.0 ? "orange.600" : "red.500";
    const meetsWordCount = !wordCountLimit ? "white" : wordCount <= wordCountLimit ? "white" : "red.200";

    // Automatically retry commit if previous commit failed due to word count and conditions have changed
    useEffect(() => {
      if (committing.lastError instanceof WordCountExceededError && (!wordCountLimit || wordCount <= wordCountLimit)) {
        commit(currentValue);
      }
    }, [commit, currentValue, wordCountLimit, wordCount, committing.lastError]);

    return (
      <FormControl isInvalid={!!committing.lastError}>
        <InputGroup>
          <AutoResizingTextarea
            ref={inputRef}
            value={currentValue}
            onChange={onChange}
            minRows={2}
            borderBottom={bottomAttachedComponent ? "none" : undefined}
            borderBottomRadius={bottomAttachedComponent ? "none" : undefined}
            backgroundColor={meetsWordCount}
            isDisabled={isDisabled}
            data-testid="response-textbox"
            data-committing={committing.inProgress || undefined}
          />
          <InputSpinner inProgress={committing.inProgress} />
        </InputGroup>
        {bottomAttachedComponent}
        <Flex flexWrap="wrap" justifyContent="space-between" alignItems="center" mt="1">
          {committing.lastError != null ? (
            <FormErrorMessage m={0}>{committing.lastError.toString()}</FormErrorMessage>
          ) : (
            <Box>{bottomLeftContent}</Box>
          )}
          <Text color={wordCountColor} fontSize="sm" whiteSpace="nowrap">{`${wordCount}${limitText} words`}</Text>
        </Flex>
      </FormControl>
    );
  },
);

export default ResponseTextarea;
