import {Accordion, Box, Divider, Stack, Text} from "@chakra-ui/react";
import {withSuspense} from "../../../../state/withSuspense";
import {useQueryData} from "../../../../state";
import {TooltipWithIcon} from "../../../../components/TooltipWithIcon";
import {QuestionId, SourcedFact} from "../../../../Types";
import SourcedFactRow from "../../components/SourcedFactRow";

const QuestionGeneratedFacts = withSuspense(({questionId}: {questionId: QuestionId}) => {
  const generatedFacts = useQueryData({queryKey: ["vendorToolkit", "questionGeneratedFacts", questionId]});

  return generatedFacts.length > 0 ? (
    <>
      <Text fontWeight="600">
        <TooltipWithIcon label="This question is a source of the following facts." placement="right">
          Generated facts
        </TooltipWithIcon>
      </Text>
      <Box border="1px solid" borderColor="gray.200" rounded="lg" maxH="400" overflowY="auto" overflowX="hidden">
        <Box overflow="hidden">
          <Accordion allowMultiple key={questionId} my="-1px">
            <Stack gap={0} py={2} divider={<Divider />}>
              {generatedFacts.map((f: SourcedFact) => (
                <SourcedFactRow key={f.type === "Fact" ? f.content.fact_id : f.content.fact_text_id} sourcedFact={f} />
              ))}
            </Stack>
          </Accordion>
        </Box>
      </Box>
    </>
  ) : null;
});

export default QuestionGeneratedFacts;
