import {Divider, Stack} from "@chakra-ui/react";
import {SourcedFact} from "../../../../../Types";
import {withSuspense} from "../../../../../state/withSuspense";
import {useQueryData} from "../../../../../state";
import {useOutletContext} from "react-router-dom";
import {TabOutletContext} from "../Actions/Actions";
import SourcedFactRow from "../../../components/SourcedFactRow";

const FactsPage = withSuspense(() => {
  const {document} = useOutletContext<TabOutletContext>();
  const facts = useQueryData({queryKey: ["vendorToolkit", "documentFacts", document.document_id]});

  return (
    <Stack gap={0} py={2} divider={<Divider />}>
      {facts.map((f: SourcedFact) => (
        <SourcedFactRow key={f.type === "Fact" ? f.content.fact_id : f.content.fact_text_id} sourcedFact={f} />
      ))}
    </Stack>
  );
});

export default FactsPage;
