import {QuestionId} from "../../../Types";
import api from "../../../api";
import {param, typedQuery} from "../../typing";

export default [
  typedQuery(["vendorToolkit", "adhocQuestion", param<QuestionId>("questionId")], async context => {
    return await api.vendorToolkit.adhocQuestions.get(context.queryKey[2]);
  }),
  typedQuery(["vendorToolkit", "adhocQuestions"], async _context => {
    return await api.vendorToolkit.adhocQuestions.list();
  }),
];
