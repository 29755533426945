import {Box, Stack, Icon, Text, ChakraProps, Badge} from "@chakra-ui/react";
import {ApiDateTime, FactSourceDocument, FactSourceQuestion, FactSourceEntity, Fact} from "../../../../Types.ts";
import {ChevronRightIcon} from "@heroicons/react/20/solid";
import {Link as RouterLink} from "react-router-dom";
import {
  questionResponse,
  sourceDocumentLink,
  sourceQuestionLink,
  sourceSubgraphLink,
  sourceSubgraphName,
} from "./index.tsx";
import {DateUI} from "../../../../components/Date.tsx";
import FirstLineCenter from "../../../components/FirstLineCenter.tsx";
import {useQueryData} from "../../../../state/index.ts";
import {withSuspense} from "../../../../state/withSuspense.tsx";

// Props to add to intervening elements to prevent them from disrupting the grid layout
const subgridProps = {
  display: "grid",
  gridTemplateColumns: "subgrid",
  gridColumn: "1 / -1",
  padding: 0,
} satisfies ChakraProps;

// Props to add to grid cell elements to ensure consistent padding and alignment
const gridCellProps = {} satisfies ChakraProps;

const FactSource = ({
  source,
  children,
  created_at,
}: {
  source: "Document" | "Question" | "Entity";
  children: React.ReactNode;
  created_at?: ApiDateTime;
}) => (
  <Box {...subgridProps}>
    <FirstLineCenter {...gridCellProps}>
      <Badge colorScheme={source === "Question" ? "blue" : "green"}>{source}</Badge>
    </FirstLineCenter>
    <Box {...gridCellProps}>{children}</Box>
    <FirstLineCenter color="gray.500" justifyContent="right" {...gridCellProps}>
      {created_at && <DateUI date={created_at} />}
    </FirstLineCenter>
  </Box>
);

const DocumentFactSource = ({source}: {source: FactSourceDocument}) => (
  <FactSource created_at={source.document_revision} source="Document">
    <Text fontWeight="semibold" as={RouterLink} _hover={{color: "blue.500"}} to={sourceDocumentLink(source)}>
      {source.document_name}
      <Icon as={ChevronRightIcon} verticalAlign="middle" mx={1} />
    </Text>
  </FactSource>
);

const QuestionFactSource = ({source}: {source: FactSourceQuestion}) => (
  <FactSource
    created_at={
      source.parent.type === "Section" ? source.parent.content.questionnaire_date : source.parent.content.question_date
    }
    source="Question"
  >
    <Stack gap={1}>
      <Text
        as={RouterLink}
        fontWeight="semibold"
        _hover={{color: "blue.500"}}
        to={sourceQuestionLink(source)}
        whiteSpace="pre-wrap"
      >
        {source.question_text}
        <Icon as={ChevronRightIcon} verticalAlign="middle" mx={1} />
      </Text>
      <Text whiteSpace="pre-wrap">{questionResponse(source)}</Text>
    </Stack>
  </FactSource>
);

const EntityFactSource = withSuspense(({source}: {source: FactSourceEntity}) => {
  const subgraph = useQueryData({
    queryKey: ["vendorToolkit", "graph", {filter_entity_ids: [source.entity_id], recursion_depth: 2}],
  });
  return (
    <FactSource source="Entity">
      <Stack gap={1}>
        <Text as={RouterLink} fontWeight="semibold" _hover={{color: "blue.500"}} to={sourceSubgraphLink(subgraph)}>
          {sourceSubgraphName(subgraph)}
          <Icon as={ChevronRightIcon} verticalAlign="middle" mx={1} />
        </Text>
      </Stack>
    </FactSource>
  );
});

export function FactSources({fact}: {fact: Fact}) {
  return (
    <Box
      borderTop="1px solid"
      borderTopColor="gray.200"
      fontSize="md"
      display="grid"
      gridTemplateColumns="auto 1fr 100px"
      gridAutoFlow="row"
      gap={6}
      py={4}
      px={6}
    >
      {fact.document_sources.map((ds, i) => (
        <DocumentFactSource key={i} source={ds} />
      ))}
      {fact.question_sources.map((qs, i) => (
        <QuestionFactSource key={i} source={qs} />
      ))}
      {fact.subgraph_sources.map((es, i) => (
        <EntityFactSource key={i} source={es} />
      ))}
    </Box>
  );
}
