import {QuestionStatus} from "../../../Types";

import {Button, Flex, Icon, Menu, MenuButton, MenuItemOption, MenuOptionGroup, Text} from "@chakra-ui/react";
import {ChevronDownIcon, FunnelIcon} from "@heroicons/react/20/solid";
import {useState} from "react";
import {QUESTION_STATUS_MAP} from "../../VendorToolkit/components/QuestionStatus";
import {Counts} from "../../../hooks/search";
import Only from "./Only";
import PortalMenuList from "../../../components/PortalMenuList";

const FilterStatuses = ({
  statuses,
  setStatuses,
  counts,
}: {
  statuses: string[];
  setStatuses: (a: string[]) => void;
  items?: {status: string}[];
  counts?: Counts;
}) => {
  const [originalStatuses] = useState(statuses);

  const onChangeStatus = (v: string | string[]) =>
    setStatuses(typeof v === "string" ? [v] : v.length === 0 ? originalStatuses : v);

  const statusCount = (counts: Counts, status: string) => {
    return counts[status] ? counts[status] : 0;
  };

  const only = (v: string) => (e: any) => {
    e.stopPropagation();
    setStatuses([v]);
  };

  return (
    <Menu placement="bottom-end" closeOnSelect={false}>
      <MenuButton
        as={Button}
        colorScheme="blue"
        rightIcon={<Icon as={ChevronDownIcon} />}
        leftIcon={<Icon as={FunnelIcon} />}
      >
        Status ({statuses.length})
      </MenuButton>
      <PortalMenuList fontSize="md" maxH="50vh" overflowY="auto">
        <MenuOptionGroup type="checkbox" onChange={onChangeStatus} value={statuses}>
          {Object.keys(QuestionStatus).map(s => (
            <MenuItemOption key={s} value={s} _focus={{".only": {visibility: "visible"}, bg: "gray.100"}}>
              <Flex align="center">
                <Text flex={1}>{QUESTION_STATUS_MAP[s].shortText}</Text>
                <Only onClick={only(s)} />
                {counts && <Text color="gray.400">{statusCount(counts, s)}</Text>}
              </Flex>
            </MenuItemOption>
          ))}
        </MenuOptionGroup>
      </PortalMenuList>
    </Menu>
  );
};

export default FilterStatuses;
