import {Icon, MenuItem, useDisclosure} from "@chakra-ui/react";
import BasePageSideBar from "../../../../../../components/BasePageSideBar.tsx";
import {PencilIcon, TrashIcon} from "@heroicons/react/20/solid";
import CreateOrEditSectionModal from "../../modals/CreateOrEditSectionModal";
import {Hierarchy, SectionNode} from "../../utility/hierarchy";
import AddSectionButton from "../actions/AddSectionButton";
import MoreButton from "../actions/MoreButton";
import DeleteSectionModal from "../../modals/DeleteSectionModal";

type Props = {
  hierarchy: Hierarchy;
  section: SectionNode;
  indent: number;
};

function SectionSideBarItem(props: Props) {
  const addModal = useDisclosure();
  const editModal = useDisclosure();
  const deleteModal = useDisclosure();
  const moreMenu = useDisclosure();

  return (
    <BasePageSideBar
      indent={props.indent}
      label={props.section.obj.name}
      href={`section/${props.section.obj.library_section_id}`}
      forceActionsVisible={moreMenu.isOpen || addModal.isOpen || editModal.isOpen || deleteModal.isOpen}
      menuActions={
        <>
          <MoreButton {...moreMenu}>
            <MenuItem icon={<Icon as={PencilIcon} />} onClick={editModal.onOpen} fontSize="md">
              Edit
            </MenuItem>
            <MenuItem icon={<Icon as={TrashIcon} />} onClick={deleteModal.onOpen} fontSize="md">
              Delete
            </MenuItem>
          </MoreButton>
          <AddSectionButton {...addModal} hierarchy={props.hierarchy} defaultParent={props.section} />

          {editModal.isOpen && (
            <CreateOrEditSectionModal hierarchy={props.hierarchy} type="edit" section={props.section} {...editModal} />
          )}

          {deleteModal.isOpen && <DeleteSectionModal section={props.section} {...deleteModal} />}
        </>
      }
    />
  );
}

export default SectionSideBarItem;
