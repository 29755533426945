import {Box, BoxProps} from "@chakra-ui/react";
import {ReactNode, Suspense, useContext, useMemo} from "react";
import {useElementSize} from "../../../hooks/elementSize";
import Loading from "../../../components/Loading";
import stickyHeaderContext from "./context";

export default function StickyHeader({
  header,
  children,
  props,
}: {
  header: ReactNode;
  children: ReactNode;
  props?: BoxProps;
}) {
  const {offset} = useContext(stickyHeaderContext);
  const [ref, size] = useElementSize();
  const context = useMemo(() => ({offset: offset + (size.height ?? 0)}), [size.height, offset]);
  return (
    <stickyHeaderContext.Provider value={context}>
      <Box ref={ref} position="sticky" top={`${offset}px`} zIndex="banner" {...props}>
        {header}
      </Box>
      <Suspense fallback={<Loading />}>{size.height !== undefined ? children : null}</Suspense>
    </stickyHeaderContext.Provider>
  );
}
