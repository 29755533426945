import {
  Input,
  InputGroup,
  InputProps,
  FormControl,
  FormErrorMessage,
  FormControlProps,
  InputGroupProps,
} from "@chakra-ui/react";
import {CommitTextProps, useCommitText} from "../hooks/commitText";
import {InputSpinner} from "./InputSpinner";
import {forwardRef, memo} from "react";

type CommitInputProps = CommitTextProps &
  InputProps & {formControlProps?: FormControlProps; inputGroupProps?: InputGroupProps; leftAddon?: React.ReactNode};

const CommitInput = memo(
  forwardRef(
    ({onCommit, value, preCommit, inputGroupProps, formControlProps, leftAddon, ...props}: CommitInputProps, ref) => {
      const {currentValue, inputRef, onChange, committing} = useCommitText(value, onCommit, preCommit);

      return (
        <FormControl isInvalid={!!committing.lastError} {...formControlProps}>
          <InputGroup ref={ref} {...inputGroupProps}>
            {leftAddon}
            <Input ref={inputRef} value={currentValue} onChange={onChange} {...props} />
            <InputSpinner inProgress={committing.inProgress} />
          </InputGroup>
          <FormErrorMessage>{committing.lastError?.toString()}</FormErrorMessage>
        </FormControl>
      );
    },
  ),
);

export default CommitInput;
