import {PlusIcon} from "@heroicons/react/20/solid";
import {useCallback} from "react";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Checkbox,
  Flex,
  HStack,
  Heading,
  Highlight,
  Icon,
  Input,
  LinkBox,
  ListItem,
  Stack,
  Text,
  Tooltip,
  UnorderedList,
} from "@chakra-ui/react";
import {Link as RouterLink, Route} from "react-router-dom";
import {Account, WhoAmI, AccountAccessGrant, LoginMethod} from "../Types";
import {LockClosedIcon, ChevronRightIcon} from "@heroicons/react/24/solid";
import {redirectRestoringUrl} from "../utils/auth";
import AccountTypeBadge from "../components/AccountTypeBadge";
import LinkOverlay from "../components/LinkOverlay";
import {resetQueries, useQueryData} from "../state";
import {withSuspense} from "../state/withSuspense";
import {useLocalStorage} from "usehooks-ts";
import {Internal} from "../components/InternalMode";

type ExtendedAccount = Account;

const DotDivider = () => (
  <Text fontSize="4xl" lineHeight="0" mx={1} mb="2px" color="gray.400">
    ·
  </Text>
);

function isAccessGrantApplicable(accessGrant: AccountAccessGrant, whoAmI: WhoAmI) {
  return (
    whoAmI.internal_mode ||
    ((whoAmI.used_2fa || !accessGrant.require_2fa) && accessGrant.login_method === whoAmI.user.login_method)
  );
}

function accessGrantDescription(accessGrant: AccountAccessGrant) {
  switch (accessGrant.login_method) {
    case LoginMethod.UsernamePassword:
      return accessGrant.require_2fa ? "Username, password & second factor" : "Username & password";
    case LoginMethod.GoogleOAuth:
      return "Login with Google";
    case LoginMethod.MicrosoftOAuth:
      return "Login with Microsoft";
  }
}

const AccountListItem = ({account, searchTerms}: {account: ExtendedAccount; searchTerms: string[]}) => {
  const whoAmI = useQueryData({queryKey: ["whoAmI"]});

  const restrictions = account.access_restrictions
    ? account.access_restrictions.map(accessGrant => ({
        applicable: isAccessGrantApplicable(accessGrant, whoAmI),
        accessGrant,
      }))
    : null;

  const accessible = restrictions ? restrictions.some(r => r.applicable) : true;

  const selectAccount = useCallback(async () => {
    sessionStorage.setItem("account_id", account.account_id);
    resetQueries();
    redirectRestoringUrl().follow();
  }, [account.account_id]);

  const accountInner = (
    <Flex p={4} gap={4} borderBottom="1px solid" borderColor="gray.100" align="center" fontSize="md">
      <Box flex={1}>
        <Text fontWeight="700">
          <Highlight query={searchTerms} styles={{bg: "yellow.100"}}>
            {whoAmI.internal_mode ? account.name : account.display_name}
          </Highlight>
        </Text>
        <HStack fontSize="sm" mt={1} divider={<DotDivider />}>
          <AccountTypeBadge account={account} />
        </HStack>
      </Box>
      {restrictions && !accessible ? <Icon zIndex={0} as={LockClosedIcon} fontSize="xl" /> : null}
      <Icon as={ChevronRightIcon} fontSize="xl" />
    </Flex>
  );

  return accessible ? (
    <LinkBox color="gray.700" _hover={{bg: "blue.50", color: "blue.500"}} cursor="pointer">
      <LinkOverlay onClick={selectAccount} />

      {accountInner}
    </LinkBox>
  ) : (
    <Tooltip
      hasArrow
      placement="right"
      label={
        restrictions && (
          <Box p={1}>
            <Text>Only the following login method(s) may be used to access this account:</Text>
            <UnorderedList>
              {restrictions.length === 0 && <ListItem>None</ListItem>}
              {restrictions.map((item, i) => (
                <ListItem key={i}>{accessGrantDescription(item.accessGrant)}</ListItem>
              ))}
            </UnorderedList>
          </Box>
        )
      }
    >
      <Box color="gray.700" opacity={0.5} cursor="not-allowed">
        {accountInner}
      </Box>
    </Tooltip>
  );
};

export const AccountList = withSuspense(() => {
  const whoAmI = useQueryData({queryKey: ["whoAmI"]});
  const accounts = useQueryData({queryKey: ["accounts"]});
  const anyAccountInaccessible = accounts.some(
    account =>
      account.access_restrictions &&
      !account.access_restrictions.some(accessGrant => isAccessGrantApplicable(accessGrant, whoAmI)),
  );
  const [searchTerm, setSearchTerm] = useLocalStorage("accountSelectorSearchTerm", "");
  const [showSandbox, setShowSandbox] = useLocalStorage("accountSelectorShowSandbox", true);
  const searchTerms = searchTerm.toLowerCase().split(" ");
  const filteredAccounts = accounts
    .filter(a => searchTerms.find(st => a.display_name.toLowerCase().indexOf(st) >= 0) !== undefined)
    .filter(a => a.type_ !== "Sandbox" || showSandbox);

  return (
    <Box>
      <Flex
        align="center"
        bg="gray.50"
        p={2}
        border="1px solid"
        borderBottom="none"
        borderColor="gray.200"
        borderTopRadius="md"
        gap={4}
      >
        <Input placeholder="Search..." bg="white" onChange={e => setSearchTerm(e.target.value)} value={searchTerm} />
        <Internal>
          <Checkbox flex="none" mr={2} isChecked={showSandbox} onChange={() => setShowSandbox(!showSandbox)}>
            Show sandbox accounts
          </Checkbox>
        </Internal>
      </Flex>

      <Stack
        borderBottomRadius="md"
        border="1px solid"
        borderColor="gray.200"
        spacing={0}
        h="30vh"
        minH="200px"
        overflowY="auto"
        data-testid="account-selector"
      >
        {anyAccountInaccessible && (
          <Alert status="warning" fontSize="md" flex="none">
            <AlertIcon />
            <Flex direction="column">
              <AlertTitle mr={8}>You need to use a different login method to access some of your accounts.</AlertTitle>
              <AlertDescription>
                Account security policies can restrict which login methods may be used.
              </AlertDescription>
            </Flex>
            <Button ml="auto" as={RouterLink} size="sm" to="/user-settings/authentication" bg="white">
              Change my login method
            </Button>
          </Alert>
        )}
        {filteredAccounts.map(a => (
          <AccountListItem key={a.account_id} account={a} searchTerms={searchTerms} />
        ))}
      </Stack>
    </Box>
  );
});

const AccountSelector = withSuspense(() => {
  return (
    <>
      <Heading fontSize="32" fontWeight="600" mb="12">
        Select account
      </Heading>

      <AccountList />

      <Box mt="12">
        <Button colorScheme="gray" leftIcon={<Icon as={PlusIcon} h="6" />} w="full" isDisabled>
          Create new account
        </Button>
      </Box>
    </>
  );
});

export default <Route path="/account-selector" element={<AccountSelector />} />;
