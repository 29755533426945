import {Editable, EditablePreview, EditableProps, EditableTextarea, HStack, Icon, Spinner} from "@chakra-ui/react";
import {Question} from "../../../../Types.ts";
import api from "../../../../api/index.ts";
import {usePromiseState} from "../../../../hooks/promiseState.ts";
import {useEffect, useState} from "react";
import {AutoResizingTextarea} from "../../../../components/AutoResizingTextarea.tsx";

const QuestionGuidance = ({question, ...props}: {question: Question} & EditableProps) => {
  const [guidance, setGuidance] = useState(question.guidance);

  useEffect(() => {
    setGuidance(question.guidance);
  }, [question.guidance]);

  const [editingGuidance, editGuidance] = usePromiseState(
    async (newGuidance: string) => {
      if (newGuidance !== question.guidance) {
        await api.vendorToolkit.questions.updateGuidance(question.question_id, newGuidance);
      }
    },
    [question],
  );

  return guidance ? (
    <Editable
      key={question.question_id}
      {...props}
      value={guidance}
      onChange={e => setGuidance(e)}
      onSubmit={e => editGuidance(e)}
      isDisabled={editingGuidance.inProgress}
    >
      <HStack align="flex-start" justify="space-between">
        <EditablePreview data-testid="question-guidance" whiteSpace={"pre-wrap"} />
        {editingGuidance.inProgress && <Icon as={Spinner} color="gray.300" mt={1} />}
      </HStack>
      <EditableTextarea as={AutoResizingTextarea} p={2} py={1} border="none" mx={-2} my="2.5px" />
    </Editable>
  ) : null;
};
export default QuestionGuidance;
