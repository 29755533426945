import {Textarea, TextareaProps} from "@chakra-ui/react";
import ResizeTextarea from "react-textarea-autosize";
import React from "react";

export const AutoResizingTextarea = React.forwardRef<
  HTMLTextAreaElement,
  {
    minRows?: number;
    maxRows?: number;
    cacheMeasurements?: boolean;
    onHeightChange?: (h: number, opts: any) => void;
  } & TextareaProps
>((props, ref) => (
  <Textarea
    minH="unset"
    overflow="hidden"
    w="100%"
    resize="none"
    ref={ref}
    minRows={1}
    lineHeight="inherit"
    as={ResizeTextarea}
    {...props}
  />
));
