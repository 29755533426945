import {UpdateCrmConnection} from "../../../../../Types.ts";
import {HubspotCrmConnection} from "./HubspotCrmConnection.tsx";

export type CrmConnectionProps = {
  connectorId: string;
  payload: UpdateCrmConnection | null;
  setPayload: (payload: UpdateCrmConnection | null) => void;
};

export const AnyCrmConnection = (props: CrmConnectionProps) => {
  switch (props.connectorId) {
    case "hubspot":
      return <HubspotCrmConnection {...props} />;
    default:
      return null;
  }
};
