import {memo, useEffect, useState} from "react";
import {extensionContext, extensionClient} from "./provider";

const ExtensionProvider = memo(({children}: {children?: React.ReactNode}) => {
  const [state, setState] = useState(extensionClient.state);

  useEffect(() => {
    return extensionClient.subscribe(() => setState(extensionClient.state));
  }, []);

  return <extensionContext.Provider value={state}>{children}</extensionContext.Provider>;
});

export default ExtensionProvider;
