import {HStack, Box} from "@chakra-ui/react";
import {useMotionValueEvent, useScroll} from "framer-motion";
import {useState} from "react";

import type {Account} from "../../Types";
import Notifications from "../Notifications";
import {getAccountId} from "../../utils/auth";
import AccountTypeBadge from "../../components/AccountTypeBadge";
import {useQueryData} from "../../state";
import {NewFeatures} from "../../Account/NewFeatures";
import {useElementSize} from "../../hooks/elementSize";
import LayerSelector from "../../Products/VendorToolkit/components/LayerSelector/Selector";
import {topRightNavContext} from "./context";
import UserMenu from "./UserMenu";

export default function TopRightNav({account, bgColor}: {account: Account; bgColor: string}) {
  const internalMode = useQueryData({queryKey: ["whoAmI"]}).internal_mode;
  const [rightNavRef, {width, height}] = useElementSize<HTMLDivElement>();
  const [mt, setMt] = useState(0);
  const {scrollY} = useScroll();
  useMotionValueEvent(scrollY, "change", latest => {
    setMt(-Math.min(latest / 2, 16));
  });

  const bg = internalMode ? undefined : bgColor;

  return (
    <Box position="relative" width={`${width}px`} height={`${height}px`} mt={`${mt}px`}>
      <HStack pl="4" ref={rightNavRef} alignItems="center" spacing={4} position="fixed" zIndex="banner" bg={bg}>
        <HStack>
          <LayerSelector />
          <AccountTypeBadge account={account} fontSize="sm" display={getAccountId() ? undefined : "none"} />
        </HStack>
        <HStack spacing={2}>
          <topRightNavContext.Provider value={{mode: "dark"}}>
            <NewFeatures />
            <Notifications />
            <UserMenu />
          </topRightNavContext.Provider>
        </HStack>
      </HStack>
    </Box>
  );
}
