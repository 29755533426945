import {Avatar, Box, Flex, Heading, Tag, TagCloseButton, TagLabel, TagProps} from "@chakra-ui/react";
import {withSuspense} from "../../../state/withSuspense";
import {useQueryData} from "../../../state";
import {AccountUser, Role as RoleT, UserMin} from "../../../Types";
import {usePromiseState} from "../../../hooks/promiseState";
import api from "../../../api";
import {AddMemberModal} from "./AddMemberModal";
import {useCallback} from "react";

export const OwnerTag = (props: TagProps) => (
  <Tag size="lg" borderRadius="full" paddingInline={1} onClick={e => e.stopPropagation()} {...props} />
);

function RoleMember({role, member: {user}}: {role: RoleT; member: AccountUser}) {
  const whoAmI = useQueryData({queryKey: ["whoAmI"]});
  const [removing, remove] = usePromiseState(async () => {
    await api.roles.removeMember(role.role_id, user.user_id);
  }, [role, user]);
  const canRemove = !removing.inProgress && whoAmI.user.user_id !== user.user_id;
  return (
    <OwnerTag title={user.name}>
      <Avatar src={user.avatar_url} name={user.name ?? user.primary_email} size="xs" />
      <TagLabel ml={2} mr={canRemove ? 0 : 2} lineHeight={2}>
        {user.name ?? user.primary_email}
      </TagLabel>
      {canRemove && <TagCloseButton onClick={remove} mr={2} />}
    </OwnerTag>
  );
}

const Role = withSuspense(({role}: {role: RoleT}) => {
  const members = useQueryData({queryKey: ["roleMembers", role.role_id]});
  const add = useCallback(
    async (user: UserMin) => {
      await api.roles.addMember(role.role_id, user.user_id);
    },
    [role],
  );
  return (
    <Box>
      <Flex alignItems="center">
        <Heading p={4} size="md">
          {role.name}
        </Heading>
        <AddMemberModal onAdd={add} />
      </Flex>
      <Flex gap={4} p={4} wrap="wrap">
        {members.map(member => (
          <RoleMember key={member.user.user_id} role={role} member={member} />
        ))}
      </Flex>
    </Box>
  );
});

const RolesPage = withSuspense(() => {
  const roles = useQueryData({queryKey: ["roles"]});
  return (
    <Flex p={4} gap={4} direction="column">
      {roles.map(role => (
        <Role key={role.role_id} role={role} />
      ))}
    </Flex>
  );
});

export default RolesPage;
