import {AssetId, GraphOperation, GraphOperationEntityRef} from "../../../Types";

export class GraphOperationsBuilder {
  private operations: GraphOperation[] = [];

  /**
   * Create an entity
   * @returns an index used to reference the entity in future method calls.
   */
  createEntity(): number {
    this.operations.push({op: "CreateEntity"});
    return this.operations.length - 1;
  }

  /**
   * Upsert a string property.
   *
   * @param to Entity to act on.
   * @param name Name of the property.
   * @param value New value of the property.
   */
  upsertStringProperty(entityRef: GraphOperationEntityRef, name: string, value: string): this {
    this.validateEntityRef(entityRef);

    this.operations.push({
      op: "UpsertProperty",
      content: {
        entity_ref: entityRef,
        name,
        value: {type: "String", content: value},
      },
    });

    return this;
  }

  /**
   * Upsert a boolean property.
   *
   * @param to Entity to act on.
   * @param name Name of the property.
   * @param value New value of the property.
   */
  upsertBooleanProperty(entityRef: GraphOperationEntityRef, name: string, value: boolean): this {
    this.validateEntityRef(entityRef);

    this.operations.push({
      op: "UpsertProperty",
      content: {
        entity_ref: entityRef,
        name,
        value: {type: "Boolean", content: value},
      },
    });

    return this;
  }

  /**
   * Upsert an integer property.
   *
   * @param to Entity to act on.
   * @param name Name of the property.
   * @param value New value of the property.
   */
  upsertIntegerProperty(entityRef: GraphOperationEntityRef, name: string, value: number): this {
    this.validateEntityRef(entityRef);

    this.operations.push({
      op: "UpsertProperty",
      content: {
        entity_ref: entityRef,
        name,
        value: {type: "Integer", content: value},
      },
    });

    return this;
  }

  /**
   * Upsert an asset property.
   *
   * @param to Entity to act on.
   * @param name Name of the property.
   * @param value New value of the property.
   */
  upsertAssetProperty(entityRef: GraphOperationEntityRef, name: string, assetId: AssetId): this {
    this.validateEntityRef(entityRef);

    this.operations.push({
      op: "UpsertProperty",
      content: {
        entity_ref: entityRef,
        name,
        value: {type: "Asset", content: assetId},
      },
    });

    return this;
  }

  /**
   * Upsert a relationship property.
   *
   * @param to Entity to act on.
   * @param name Name of the property.
   * @param parent Parent entity for the relationship.
   * @param child Child entity for the relationship.
   */
  upsertRelationshipProperty(
    relationshipEntityRef: GraphOperationEntityRef,
    name: string,
    parentEntityRef: GraphOperationEntityRef,
    childEntityRef: GraphOperationEntityRef,
  ): this {
    this.validateEntityRef(relationshipEntityRef);
    this.validateEntityRef(parentEntityRef);
    this.validateEntityRef(childEntityRef);

    this.operations.push({
      op: "UpsertProperty",
      content: {
        entity_ref: relationshipEntityRef,
        name,
        value: {
          type: "Relationship",
          content: {
            parent_entity_ref: parentEntityRef,
            child_entity_ref: childEntityRef,
          },
        },
      },
    });

    return this;
  }

  /**
   * Delete a property from an entity.
   *
   * @param from Entity to act on.
   * @param name Property name to delete.
   */
  deleteProperty(from: GraphOperationEntityRef, name: string): this {
    this.validateEntityRef(from);

    this.operations.push({
      op: "DeleteProperty",
      content: {
        entity_ref: from,
        name,
      },
    });

    return this;
  }

  /**
   * Delete an entity.
   *
   * @param entityRef Entity to act on.
   * @param opts Additional options.
   */
  deleteEntity(
    entityRef: GraphOperationEntityRef,
    opts?: {
      /** Also delete relationship entities where this entity is the parent or child. (Default: false) */
      recursive?: boolean;
    },
  ): this {
    this.validateEntityRef(entityRef);
    const recursive = opts?.recursive ?? false;

    this.operations.push({
      op: "DeleteEntity",
      content: {
        entity_ref: entityRef,
        recursive,
      },
    });

    return this;
  }

  /**
   * Finish building and return the operation list.
   */
  build(): GraphOperation[] {
    return this.operations;
  }

  private validateEntityRef(entityRef: GraphOperationEntityRef) {
    if (typeof entityRef === "number") {
      if (entityRef >= this.operations.length) {
        throw new Error(`Entity ref ${entityRef} out of bounds.`);
      }

      if (this.operations[entityRef].op !== "CreateEntity") {
        throw new Error(`Invalid entity ref ${entityRef}.`);
      }
    }
  }
}
