import {AccountId} from "../../../Types";
import externalApi from "../../../api/external";
import {param, typedQuery} from "../../typing";

export default [
  typedQuery(["external", "account", param<AccountId>("accountId")], async context => {
    return await externalApi.accounts.get(context.queryKey[2]);
  }),
  typedQuery(["external", "accountBySlug", param("accountSlug")], async context => {
    return await externalApi.accounts.getBySlug(context.queryKey[2]);
  }),
];
