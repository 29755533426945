import {Route} from "react-router-dom";
import viewerRoutes from "./QuestionnaireViewer";
import QuestionnaireList from "./QuestionnaireList";
import NewQuestionnaire from "./NewQuestionnaire";

export default (
  <Route path="questionnaires" handle={{crumb: "Questionnaires"}}>
    <Route id="Questionnaires" index element={<QuestionnaireList />} />
    <Route handle={{crumb: "New"}} path="new" element={<NewQuestionnaire />} />
    {viewerRoutes}
  </Route>
);
