import {Icon, IconButton, Tooltip} from "@chakra-ui/react";
import {PlusCircleIcon} from "@heroicons/react/24/outline";
import {Hierarchy, SectionParentNode} from "../../utility/hierarchy";
import CreateOrEditSectionModal from "../../modals/CreateOrEditSectionModal";

type Props = {
  hierarchy: Hierarchy;
  defaultParent?: SectionParentNode;

  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
};

function AddSectionButton(props: Props) {
  return (
    <>
      <Tooltip label="Add a section inside">
        <IconButton
          size="sm"
          p="1"
          icon={<Icon as={PlusCircleIcon} width="100%" height="100%" />}
          aria-label="Add"
          variant="ghost"
          onClick={props.onOpen}
          _hover={{
            bg: "blackAlpha.50",
          }}
        />
      </Tooltip>
      {props.isOpen && <CreateOrEditSectionModal type="create" {...props} />}
    </>
  );
}

export default AddSectionButton;
