import {Icon, Menu, MenuButton, MenuDivider, MenuGroup, MenuItem, Stack, Text} from "@chakra-ui/react";
import {resetQueries, useQueryData} from "../../state";
import {redirectToAccountSelector} from "../../utils/auth";
import {RoundButton} from "./RoundButton";
import PortalMenuList from "../../components/PortalMenuList";
import {Link as RouterLink} from "react-router-dom";
import {UserIcon} from "@heroicons/react/24/outline";

const UserMenu = () => {
  const {user, account, multi_account} = useQueryData({queryKey: ["whoAmI"]});
  const userNavigation = [
    {name: "Settings", to: "/user-settings"},
    {
      name: "Switch accounts",
      onClick: () => {
        sessionStorage.clear();
        resetQueries();
        redirectToAccountSelector().follow();
      },
      display: multi_account ? undefined : "none",
    },
    {name: "Sign out", to: "/logout"},
  ];

  return (
    <Menu placement="bottom-end">
      <RoundButton as={MenuButton} data-testid="top-right-user-button">
        <Icon as={UserIcon} mx="auto" h="6" w="6" display="block" position="relative" />
      </RoundButton>
      <PortalMenuList py="2">
        <MenuGroup>
          <MenuItem fontSize="md" cursor="auto" _focus={{bg: "transparent"}} _hover={{bg: "transparent"}}>
            <Stack data-testid="signed-in-as" spacing={1}>
              <Text fontWeight="medium">{user.primary_email}</Text>
              <Text
                fontSize="sm"
                fontWeight="medium"
                textOverflow="ellipsis"
                maxW="250px"
                whiteSpace="nowrap"
                overflow="hidden"
                color="gray.500"
              >
                {account ? account.display_name : "No account selected"}
              </Text>
            </Stack>
          </MenuItem>
        </MenuGroup>
        <MenuDivider />
        {userNavigation.map(({name, ...item}) => (
          <MenuItem key={name} as={RouterLink} w="full" _hover={{textDecoration: "none"}} {...item}>
            <Text fontSize="md">{name}</Text>
          </MenuItem>
        ))}
      </PortalMenuList>
    </Menu>
  );
};
export default UserMenu;
