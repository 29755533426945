import {TeamId} from "../../Types";
import api from "../../api";
import {param, typedQuery} from "../typing";

export default [
  typedQuery(["team", param<TeamId>("teamId")], async context => {
    return await api.teams.get(context.queryKey[1]);
  }),
  typedQuery(["teams"], async () => {
    return await api.teams.list();
  }),
  typedQuery(["deletedTeams"], async () => {
    return await api.teams.listDeleted();
  }),
  typedQuery(["teamMembers", param<TeamId>("teamId")], async context => {
    return await api.teams.listMembers(context.queryKey[1]);
  }),
  typedQuery(["teamPermissions", param<TeamId>("teamId")], async context => {
    return await api.teams.listPermissions(context.queryKey[1]);
  }),
];
