import {useMemo} from "react";
import {getNodeKey, SectionParentNode} from "../../utility/hierarchy";
import BasePageSideBar from "../../../../../../components/BasePageSideBar.tsx";

type Props = {
  indent: number;
  parentNode: SectionParentNode;
};

function UncategorizedSideBarItem({indent, parentNode}: Props) {
  const href = useMemo(() => {
    const key = getNodeKey(parentNode);
    const prefix = parentNode.type === "scope" ? "scope" : "section";

    return `${prefix}/${key}/uncategorized`;
  }, [parentNode]);

  return <BasePageSideBar indent={indent} label="Uncategorized" gray href={href} />;
}

export default UncategorizedSideBarItem;
