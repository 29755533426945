import {MagnifyingGlassIcon} from "@heroicons/react/20/solid";
import {Icon, Input, InputGroup, InputLeftElement} from "@chakra-ui/react";
import {useState} from "react";

export default function Search() {
  const [searchTerm, setSearchTerm] = useState("");

  return (
    <InputGroup color="white" _focusWithin={{color: "gray.400"}} maxW="xs">
      <InputLeftElement pointerEvents="none">
        <Icon as={MagnifyingGlassIcon} h="5" w="5" />
      </InputLeftElement>
      <Input
        placeholder="Search"
        type="search"
        border="0"
        bg="blue.500"
        w="full"
        color="white"
        value={searchTerm}
        onChange={e => setSearchTerm(e.target.value)}
        _placeholder={{color: "white"}}
        _focus={{bg: "white", color: "gray.700", _placeholder: {color: "gray.600"}}}
        _focusVisible={{borderColor: "transparent"}}
      />
    </InputGroup>
  );
}
