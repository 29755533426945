import {Link as RouterLink} from "react-router-dom";
import {withSuspense} from "../../../../state/withSuspense.tsx";
import {useQueryData} from "../../../../state/index.ts";
import {
  Stack,
  Heading,
  Button,
  ButtonGroup,
  Flex,
  Icon,
  Text,
  HStack,
  Tbody,
  Thead,
  Tr,
  Th,
  Table,
  TableContainer,
  Checkbox,
  Td,
  IconButton,
} from "@chakra-ui/react";
import {ChevronRightIcon, EllipsisVerticalIcon} from "@heroicons/react/20/solid";
import {ArchiveBoxXMarkIcon} from "@heroicons/react/24/outline";
import Page from "../../../../components/Page.tsx";
import {FactMin} from "../../../../Types.ts";
import {useSubClusterTitle} from "./hooks.ts";
import {Type} from "../../../../typing";
import {useTypedParams} from "../../../../hooks/typedParams";

const EmptyState = () => (
  <Flex justifyContent="center" flex={1}>
    <Flex gap={4} maxW="50%" mt="10%">
      <Icon fontSize="7xl" color="gray.300" as={ArchiveBoxXMarkIcon} />
      <Stack spacing={4}>
        <Stack spacing={2}>
          <Text fontWeight="semibold" color="gray.700">
            No facts here...
          </Text>
          <Text color="gray.500" fontSize="md">
            Facts are generated from documentation and historical questionnaires in your library. Platformed uses facts
            to power its AI and generate you automated answers.
          </Text>
          <Text color="gray.500" fontSize="md">
            To generate some facts, you need to add documentation or questionnaires to the library.
          </Text>
        </Stack>
        <ButtonGroup size="sm" colorScheme="green">
          <Button
            as={RouterLink}
            to="/vendor-toolkit/library/other-documents"
            rightIcon={<Icon as={ChevronRightIcon} />}
          >
            Upload documents
          </Button>
          <Button
            as={RouterLink}
            to="/vendor-toolkit/library/historical-questionnaires"
            rightIcon={<Icon as={ChevronRightIcon} />}
          >
            Upload historical questionnaires
          </Button>
        </ButtonGroup>
      </Stack>
    </Flex>
  </Flex>
);

const FactEquivalenceRow = ({factEquivalence}: {factEquivalence: FactMin}) => {
  return (
    <Tr>
      <Td p={0}>
        <Checkbox px="8" py="5" />
      </Td>
      <Td fontSize="md">
        <Text>{factEquivalence.representative_text.content}</Text>
      </Td>
      <Td>
        <HStack>
          <IconButton
            variant="ghost"
            aria-label="More actions"
            icon={<Icon fontSize="xl" as={EllipsisVerticalIcon} />}
          />
          <Icon as={ChevronRightIcon} fontSize={24} color="gray.500" />
        </HStack>
      </Td>
    </Tr>
  );
};

export const FactClusterParams = Type.Object({clusterId: Type.FactClusterId()});

const FactClusterPage = withSuspense(() => {
  const {clusterId} = useTypedParams(FactClusterParams);
  const title = useSubClusterTitle(clusterId);
  const factEquivalences = useQueryData({
    queryKey: ["vendorToolkit", "factEquivalences", clusterId],
  });
  return (
    <Page title="Facts" display="flex" flexDirection="column" position="relative">
      <Stack flex="1" spacing={0}>
        <Heading size="md" p="8">
          {title}
        </Heading>
        <TableContainer maxWidth="100%" whiteSpace="normal" flex="1">
          <Table style={{borderCollapse: "separate", borderSpacing: "0"}} size="lg" layout="fixed">
            <Thead bg="white" borderBottom="10px solid" borderBottomColor="red.100">
              <Tr>
                <Th width="5%" color="gray.500" p={0}>
                  <Checkbox px="8" py="5" />
                </Th>
                <Th width="70%" color="gray.500">
                  Fact
                </Th>
                <Th width="10%" color="gray.500" />
              </Tr>
            </Thead>
            {factEquivalences.length ? (
              <Tbody>
                {factEquivalences.map(factEquivalence => (
                  <FactEquivalenceRow key={factEquivalence.fact_id} factEquivalence={factEquivalence} />
                ))}
              </Tbody>
            ) : (
              <EmptyState />
            )}
          </Table>
        </TableContainer>
      </Stack>
    </Page>
  );
});

export default FactClusterPage;
