import {
  Button,
  Center,
  Input,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  SimpleGrid,
} from "@chakra-ui/react";

export type PaletteEntry = {
  value: string;
  label: string;
};

export type ColorPickerProps = {
  palette: PaletteEntry[];
  value: string;
  onChange: (newValue: string) => void;
  allowOther?: boolean;
};

export function ColorPicker({palette, value, onChange, allowOther}: ColorPickerProps) {
  const label = palette.find(x => x.value === value)?.label ?? value;
  return (
    <Popover variant="picker">
      <PopoverTrigger>
        <Button
          aria-label={label}
          background={value}
          height="22px"
          width="22px"
          padding={0}
          minWidth="unset"
          borderRadius={3}
        ></Button>
      </PopoverTrigger>
      <PopoverContent width="170px">
        <PopoverArrow bg={value} />
        <PopoverCloseButton color="white" />
        <PopoverHeader
          height="100px"
          backgroundColor={value}
          borderTopLeftRadius={5}
          borderTopRightRadius={5}
          color="white"
        >
          <Center height="100%">{label}</Center>
        </PopoverHeader>
        <PopoverBody height="120px">
          <SimpleGrid columns={5} spacing={2}>
            {palette.map(paletteEntry => (
              <Button
                key={paletteEntry.value}
                aria-label={paletteEntry.label}
                background={paletteEntry.value}
                height="22px"
                width="22px"
                padding={0}
                minWidth="unset"
                borderRadius={3}
                _hover={{background: paletteEntry.value}}
                onClick={() => {
                  onChange(paletteEntry.value);
                }}
              ></Button>
            ))}
          </SimpleGrid>
          {allowOther && (
            <Input
              borderRadius={3}
              marginTop={3}
              size="sm"
              value={value}
              onChange={e => {
                onChange(e.target.value);
              }}
            />
          )}
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
}
