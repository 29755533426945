import {Route} from "react-router-dom";
import {RouteTab, tabbedRoute} from "../../../../../components/RouterTabs/index.tsx";
import Question from "../../../Questionnaires/Question/index.tsx";
import questionsTab from "../Questions/index.tsx";
import exportTab from "../../../Questionnaires/QuestionnaireViewer/Export/index.tsx";
import metaTab from "../../../Questionnaires/QuestionnaireViewer/Meta/index.tsx";
import QuestionnaireViewerPage from "./Viewer.tsx";
import Crumb from "./Crumb.tsx";

const tabs: RouteTab[] = [metaTab, questionsTab, exportTab];

export default (
  <Route path=":questionnaireId" handle={{Crumb}}>
    {tabbedRoute({tabs, element: <QuestionnaireViewerPage />})}
    <Route key="questions" path="questions" handle={{crumb: "Questions"}}>
      {Question}
    </Route>
  </Route>
);
