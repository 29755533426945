import {Box, Divider, Flex, FormControl, FormLabel, Icon, Stack, Text, Tooltip} from "@chakra-ui/react";
import {
  AccountFeature,
  ApiDateTime,
  Counterparty,
  Deal,
  OwnerT,
  Questionnaire,
  ScopeMatrix,
  TrustCenterAccess,
} from "../../../../../Types.ts";
import {useCallback} from "react";
import DueDate from "../../../../../components/DueDate.tsx";
import api, {HTTPError} from "../../../../../api/index.ts";
import CommitInput from "../../../../../components/CommitInput.tsx";
import {CounterpartyHeading, CounterpartySelector, DealSelector} from "../../../components/Counterparty.tsx";
import * as datefns from "date-fns";
import {parseISO} from "../../../../../utils/date.ts";
import QuestionnaireStatus from "../../../components/QuestionnaireStatus/QuestionnaireStatus.tsx";
import OriginalFiles from "../../../components/OriginalFiles/OriginalFiles.tsx";
import {InformationCircleIcon} from "@heroicons/react/24/outline";
import {DateUI} from "../../../../../components/Date.tsx";
import OriginalSources from "../../../components/OriginalSources.tsx";
import {useOutletContext} from "react-router-dom";
import {TrustCenterVisibilityComp} from "../../../components/TrustCenterAccess/Selector.tsx";
import {useAccountFeatures} from "../../../../../hooks/accountFeatures.ts";
import FormSection from "../../../Library/Documents/components/FormSection.tsx";
import {TooltipWithIcon} from "../../../../../components/TooltipWithIcon.tsx";
import RequireAccountFeature from "../../../../../components/RequireAccountFeature.tsx";
import {useIsAdmin} from "../../../../../hooks/roles.ts";
import {FormQuestion} from "../../../TrustCenter/Config/FormSection.tsx";
import ScopeMatrixEditor from "../../../components/ScopeMatrixEditor.tsx";
import Owner from "../../../../../components/Owner.tsx";

const QuestionnaireDateLabel = () => {
  return (
    <Flex flexDirection="row" alignItems="center" marginBottom="2" gap="1">
      <FormLabel margin="0">Questionnaire date</FormLabel>
      <Tooltip placement="top-start" label="The date that responses in this questionnaire were valid on" fontSize="md">
        <Icon marginX="1" as={InformationCircleIcon} />
      </Tooltip>
    </Flex>
  );
};
const MetaPage = () => {
  const {enabledFeatures} = useAccountFeatures();
  const isAdmin = useIsAdmin();
  const {questionnaire} = useOutletContext<{questionnaire: Questionnaire}>();
  const {
    questionnaire_id,
    name,
    counterparty,
    deal,
    owner,
    archived_at,
    questionnaire_date,
    status,
    due_date,
    original_files,
    original_sources,
    scope_matrix,
  } = questionnaire;

  const onReassign = useCallback(
    async (owner?: OwnerT) => {
      await api.vendorToolkit.questionnaires.assign(questionnaire_id, owner?.owner_id ?? null);
    },
    [questionnaire_id],
  );
  const onNudge = useCallback(async () => {
    await api.vendorToolkit.questionnaires.nudge(questionnaire_id);
  }, [questionnaire_id]);
  const onSetDueDate = useCallback(
    async (dueDate?: ApiDateTime) => {
      await api.vendorToolkit.questionnaires.updateDueDate(questionnaire_id, dueDate ?? null);
    },
    [questionnaire_id],
  );
  const onSetQuestionnaireDate = useCallback(
    async (questionnaireDate?: ApiDateTime) => {
      await api.vendorToolkit.questionnaires.updateQuestionnaireDate(questionnaire_id, questionnaireDate ?? null);
    },
    [questionnaire_id],
  );
  const onSetName = useCallback(
    async (name: string) => {
      try {
        await api.vendorToolkit.questionnaires.updateName(questionnaire_id, name);
      } catch (ex) {
        if (ex instanceof HTTPError) {
          if (ex.response.status === 422) {
            throw new Error("Name must be between 1 and 256 characters.");
          } else if (ex.response.status === 409) {
            throw new Error(await ex.response.json());
          }
        }
        throw ex;
      }
    },
    [questionnaire_id],
  );
  const onSetCounterparty = useCallback(
    async (counterparty: Counterparty) => {
      await api.vendorToolkit.questionnaires.updateCounterparty(questionnaire_id, counterparty.counterparty_id);
    },
    [questionnaire_id],
  );
  const onSetScopeMatrix = useCallback(
    async (scopeMatrix: ScopeMatrix) => {
      await api.vendorToolkit.questionnaires.updateScopeMatrix(questionnaire_id, scopeMatrix);
    },
    [questionnaire_id],
  );
  const onSetDeal = useCallback(
    async (deal?: Deal) => {
      const deal_id = deal ? deal.deal_id : null;
      await api.vendorToolkit.questionnaires.updateDeal(questionnaire_id, deal_id);
    },
    [questionnaire_id],
  );

  const updateTrustCenterVisibility = useCallback(
    async (visibility: TrustCenterAccess) => {
      await api.vendorToolkit.questionnaires.updateTrustCenterVisibility(questionnaire_id, visibility);
    },
    [questionnaire_id],
  );

  return (
    <Stack p={4} spacing={8} divider={<Divider />}>
      <FormSection title="Basic details" description="">
        <FormControl>
          <FormLabel>Questionnaire name</FormLabel>
          <CommitInput value={name} onCommit={onSetName} />
        </FormControl>
        <FormControl>
          <FormLabel>
            <CounterpartyHeading />
          </FormLabel>
          <CounterpartySelector value={counterparty} onChange={onSetCounterparty} />
        </FormControl>
        {counterparty.crm_connection_id ? (
          <FormControl>
            <FormLabel>Deal</FormLabel>
            <DealSelector value={deal} counterparty_id={counterparty.counterparty_id} onChange={onSetDeal} />
          </FormControl>
        ) : null}
        <RequireAccountFeature feature={AccountFeature.Scopes}>
          <FormControl>
            <FormLabel>
              <TooltipWithIcon
                placement="top"
                label="Include facts from these scopes in addition to global facts when answering this questionnaire."
                fontSize="md"
              >
                <Text as="span">Scopes</Text>
              </TooltipWithIcon>
            </FormLabel>
            <ScopeMatrixEditor value={scope_matrix} onChange={async newValue => onSetScopeMatrix(newValue)} />
          </FormControl>
        </RequireAccountFeature>
        <FormControl>
          <FormLabel>Owner</FormLabel>
          <Owner owner={owner} onNudge={onNudge} onReassign={onReassign} />
        </FormControl>
        {archived_at ? (
          <>
            {questionnaire_date && (
              <FormControl>
                <QuestionnaireDateLabel />
                <Box>
                  <Text fontSize="md">{datefns.format(parseISO(questionnaire_date), "PP")}</Text>
                </Box>
              </FormControl>
            )}
            <FormControl>
              <FormLabel>Archived at</FormLabel>
              <Box>
                <Text fontSize="md">{datefns.format(parseISO(archived_at), "PP")}</Text>
              </Box>
            </FormControl>
          </>
        ) : (
          <>
            <FormControl>
              <FormLabel>Status</FormLabel>
              <QuestionnaireStatus status={status} />
            </FormControl>
            {status === "Complete" ? (
              <FormControl>
                <QuestionnaireDateLabel />
                <DateUI date={questionnaire_date} onDateChange={onSetQuestionnaireDate} />
              </FormControl>
            ) : (
              <FormControl>
                <FormLabel>Due date</FormLabel>
                <DueDate dueDate={due_date} complete={false} onDateChange={onSetDueDate} />
              </FormControl>
            )}
          </>
        )}
      </FormSection>
      <FormSection title="Files" description="">
        <FormControl paddingTop="8" flex={1}>
          <FormLabel>Original files</FormLabel>
          <OriginalFiles files={original_files} questionnaireId={questionnaire_id} />
        </FormControl>
        <FormControl paddingTop="8">
          <FormLabel>Original sources</FormLabel>
          <OriginalSources sources={original_sources} questionnaireId={questionnaire_id} />
        </FormControl>
      </FormSection>
      {isAdmin && enabledFeatures[AccountFeature.TrustCenter] && (
        <FormSection title="Showcase" description="">
          <FormQuestion title={"Visibility"} description={"Whether this questionnaire appears in your Showcase FAQ"}>
            <Box>
              <TrustCenterVisibilityComp
                pr="1"
                size="lg"
                access={questionnaire.trust_center_visibility}
                onChangeAccess={updateTrustCenterVisibility}
              />
            </Box>
          </FormQuestion>
        </FormSection>
      )}
    </Stack>
  );
};

export default MetaPage;
