import {invalidateQueries} from "../../state";
import {GetGraphQuery, GraphOperation, SubGraph} from "../../Types";
import jsonApi from "../jsonApi";

export async function get(query: GetGraphQuery) {
  return await jsonApi.get<SubGraph>(`/vendor_toolkit/graph?${new URLSearchParams(query as any)}`);
}

export async function update(operations: GraphOperation[]) {
  const res = await jsonApi.post("/vendor_toolkit/graph", operations);
  await invalidateQueries([{queryKey: ["vendorToolkit", "graph"]}]);
  return res;
}
