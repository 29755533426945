import {BoltIcon, CheckIcon, ClipboardDocumentCheckIcon} from "@heroicons/react/20/solid";
import {CloudArrowUpIcon} from "@heroicons/react/24/outline";
import {QuestionnaireStatus} from "../../../../Types";

type QuestionnaireStatusInfo = {
  text: string;
  icon: typeof CheckIcon;
  color: string;
  disableSetting?: boolean;
};

export const QUESTIONNAIRE_STATUS_MAP: {[status: string]: QuestionnaireStatusInfo} = {
  [QuestionnaireStatus.Complete]: {
    text: "Complete",
    icon: CheckIcon,
    color: "green",
  },
  [QuestionnaireStatus.InProgress]: {
    text: "In progress",
    icon: ClipboardDocumentCheckIcon,
    color: "orange",
  },
  [QuestionnaireStatus.Automating]: {
    text: "Automating",
    icon: BoltIcon,
    color: "gray",
    disableSetting: true,
  },
  [QuestionnaireStatus.Importing]: {
    text: "Importing",
    icon: CloudArrowUpIcon,
    color: "blue",
    disableSetting: true,
  },
};
