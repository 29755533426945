import {Link as RouterLink} from "react-router-dom";
import {useQueryData} from "../../../../state/index.ts";
import {
  Button,
  Heading,
  HStack,
  Icon,
  LinkBox,
  LinkOverlay,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import {ChevronRightIcon, ExclamationCircleIcon} from "@heroicons/react/20/solid";
import Page from "../../../../components/Page.tsx";
import {FactCluster} from "../../../../Types.ts";

function FactSection({cluster}: {cluster: FactCluster}) {
  return (
    <Tr>
      <Td>
        <Stack align="flex-start">
          <Text fontWeight="semibold" fontSize="md">
            {cluster.title}
          </Text>
          <Text color="gray.500" fontSize="sm">
            {cluster.children?.length} Subsection{(cluster.children?.length ?? 0) > 1 && "s"}
          </Text>
        </Stack>
      </Td>
      <Td fontSize="md">
        <Text fontWeight="semibold">{cluster.equivalence_count} Facts</Text>
      </Td>
      <Td></Td>
    </Tr>
  );
}

function FactSubSection({cluster}: {cluster: FactCluster}) {
  return (
    <LinkBox as={Tr} _hover={{bg: "gray.100"}} bg="gray.50">
      <Td>
        <LinkOverlay as={RouterLink} to={cluster.cluster_id} />
        <Stack ml={8}>
          <Text fontWeight="semibold" fontSize="md">
            {cluster.title}
          </Text>
        </Stack>
      </Td>
      <Td fontSize="md">
        <Text fontWeight="semibold">{cluster.equivalence_count} Facts</Text>
      </Td>
      <Td>
        <HStack>
          <Icon as={ChevronRightIcon} fontSize={24} color="gray.500" />
        </HStack>
      </Td>
    </LinkBox>
  );
}

function FactSections() {
  const resolutions = useQueryData({queryKey: ["vendorToolkit", "resolutions"]});
  const clusters = useQueryData({queryKey: ["vendorToolkit", "topLevelFactClusters"]});

  return (
    <Page title="Facts" display="flex" flexDirection="column" position="relative">
      <Stack flex="1" spacing={0}>
        <HStack p="8">
          <Heading size="md" flex={1}>
            Facts
          </Heading>
          {resolutions.length > 0 && (
            <Button
              as={RouterLink}
              to="../resolutions"
              size="sm"
              colorScheme="red"
              leftIcon={<Icon as={ExclamationCircleIcon} />}
              rightIcon={<Icon as={ChevronRightIcon} />}
            >
              {resolutions.length} resolution{resolutions.length !== 1 && "s"} available
            </Button>
          )}
        </HStack>
        <TableContainer maxWidth="100%" whiteSpace="normal" flex="1" borderTop="1px solid" borderTopColor="gray.100">
          <Table style={{borderCollapse: "separate", borderSpacing: "0"}} size="lg" layout="fixed">
            <Thead bg="white" borderBottom="10px solid" borderBottomColor="red.100">
              <Tr>
                <Th width="30%" color="gray.500">
                  Section
                </Th>
                <Th width="15%" color="gray.500">
                  Facts
                </Th>
                <Th width="10%" color="gray.500" />
              </Tr>
            </Thead>
            <Tbody>
              {clusters.flatMap(cluster => {
                return [
                  <FactSection key={cluster.cluster_id} cluster={cluster} />,
                  ...(cluster.children ?? []).map(subCluster => (
                    <FactSubSection key={subCluster.cluster_id} cluster={subCluster} />
                  )),
                ];
              })}
            </Tbody>
          </Table>
        </TableContainer>
      </Stack>
    </Page>
  );
}

export default FactSections;
