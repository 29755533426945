import {withSuspense} from "../../../../state/withSuspense.tsx";
import {useQueryData} from "../../../../state/index.ts";
import {Heading, Flex, FormControl, FormLabel} from "@chakra-ui/react";
import Page from "../../../../components/Page.tsx";
import CommitTextarea from "../../../../components/CommitTextarea.tsx";
import {useCallback, useMemo} from "react";
import api from "../../../../api/index.ts";

const ThemePage = withSuspense(() => {
  const config = useQueryData({queryKey: ["vendorToolkit", "trustCenterConfig"]});
  const themeJson = useMemo(() => JSON.stringify(config.theme, undefined, "    "), [config.theme]);
  const updateTheme = useCallback(async (newTheme: string) => {
    await api.vendorToolkit.trustCenter.updateConfigTheme(JSON.parse(newTheme));
  }, []);
  return (
    <Page title="Theme">
      <Flex direction="column" flex={1} p={8} gap={4}>
        <Heading flex="1" size="lg">
          Theme
        </Heading>

        <FormControl>
          <FormLabel>Json</FormLabel>
          <CommitTextarea onCommit={updateTheme} value={themeJson} rows={20} fontFamily="monospace" />
        </FormControl>
      </Flex>
    </Page>
  );
});

export default ThemePage;
