import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  VStack,
} from "@chakra-ui/react";
import {MultiSelect} from "chakra-multiselect";
import {useModalState} from "../../../../../hooks/modalState";
import {useQueryData} from "../../../../../state";
import {useModalValidationState, useValidatedPromiseState} from "../../../../../hooks/validationState";
import api from "../../../../../api";
import {ScopeNode} from "../utility/hierarchy";
import {ScopeAxisId} from "../../../../../Types";

type Props = ({type: "create"} | {type: "edit"; scope: ScopeNode}) & {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
};

type FormState = {
  name: string;
  axis: ScopeAxisId;
};

function CreateOrEditScopeModal(props: Props) {
  if (props.type === "edit") {
    if (props.scope.obj == null) {
      throw new Error("Cannot edit the global scope.");
    }
  }

  const scopeAxes = useQueryData({queryKey: ["vendorToolkit", "scopeAxes"]});

  const [formState, setFormState] = useModalState<FormState>(props.isOpen, () =>
    props.type === "edit"
      ? {
          name: props.scope.obj!.name,
          axis: props.scope.obj!.axis.axis_id,
        }
      : {
          name: "",
          axis: scopeAxes[0].axis_id,
        },
  );

  const [validationErrors, setValidationErrors] = useModalValidationState(props.isOpen, {name: formState.name});

  const [creating, create] = useValidatedPromiseState(
    async () => {
      if (props.type === "create") {
        await api.vendorToolkit.scopes.create({
          name: formState.name,
          axis_id: formState.axis,
        });
      } else if (props.type === "edit") {
        await api.vendorToolkit.scopes.updateName(props.scope.obj!.scope_id, formState.name);
      }

      props.onClose();
    },
    [formState.name, formState.axis, props],
    setValidationErrors,
  );

  const title = props.type === "create" ? "Create new scope" : "Edit scope";
  const buttonText = props.type === "create" ? "Create new scope" : "Save changes";
  const buttonColorScheme = props.type === "create" ? "green" : "blue";

  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose}>
      <ModalOverlay />
      <ModalContent as="form" onSubmit={create}>
        <ModalHeader>{title}</ModalHeader>
        <ModalBody>
          <VStack spacing="4">
            <FormControl isRequired isInvalid={validationErrors.name !== undefined}>
              <FormLabel>Name</FormLabel>
              <Input value={formState.name} onChange={ev => setFormState(f => ({...f, name: ev.target.value}))} />
              {validationErrors.name && validationErrors.name[0] && (
                <FormErrorMessage>{validationErrors.name[0].message}</FormErrorMessage>
              )}
            </FormControl>
            <FormControl isDisabled={props.type === "edit"}>
              <FormLabel>Scope type</FormLabel>
              <MultiSelect
                disabled={props.type === "edit"}
                options={scopeAxes.map(a => ({label: a.name, value: a.axis_id}))}
                value={formState.axis}
                onChange={v => setFormState(f => ({...f, axis: v as unknown as ScopeAxisId}))}
                single
              />
            </FormControl>
            {creating.lastError ? (
              <Alert status="error" mt="12">
                <AlertIcon boxSize="40px" />
                <Box>
                  <AlertTitle fontSize="md">Error creating scope</AlertTitle>
                  <AlertDescription fontSize="md">{`${creating.lastError}`}</AlertDescription>
                </Box>
              </Alert>
            ) : null}
          </VStack>
        </ModalBody>
        <ModalFooter gap="2">
          <Button variant="outline" onClick={props.onClose} isDisabled={creating.inProgress}>
            Cancel
          </Button>
          <Button
            type="submit"
            colorScheme={buttonColorScheme}
            isLoading={creating.inProgress}
            isDisabled={creating.inProgress}
          >
            {buttonText}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default CreateOrEditScopeModal;
