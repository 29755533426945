import {Alert, AlertDescription, AlertIcon, AlertProps, AlertTitle, Box} from "@chakra-ui/react";

const AuthError = ({error, ...props}: {error?: string} & AlertProps) =>
  error ? (
    <Alert status="error" borderRadius="md" {...props}>
      <AlertIcon boxSize="40px" />
      <Box>
        <AlertTitle fontSize="md">Login error</AlertTitle>
        <AlertDescription fontSize="md">{error}</AlertDescription>
      </Box>
    </Alert>
  ) : null;

export default AuthError;
