import {unified} from "unified";
import remarkParse from "remark-parse";
import type {Content as ContentNode, Heading as HeadingNode} from "mdast";

export function generateId(input: string | string[]) {
  if (typeof input == "string") {
    input = [input];
  }

  // Replace any sequence of whitespace characters with a single hyphen
  let compatibleId = input[0].replace(/\s+/g, "-");

  // Remove invalid characters
  compatibleId = compatibleId.replace(/[^a-zA-Z0-9\-_:.]/g, "");

  // Ensure the first character is a letter
  if (!/^[a-zA-Z]/.test(compatibleId)) {
    compatibleId = "id" + compatibleId;
  }

  return compatibleId;
}

type HeadingT = {text: string; id: string; children: HeadingT[]};

export function toPlainText(n: ContentNode): string {
  if (n.type === "text") {
    return n.value;
  } else if (n.type === "list") {
    return n.children.map(c => `• ${toPlainText(c)}`).join("\n");
  } else if ("children" in n) {
    return n.children.map(toPlainText).join("");
  } else {
    return "";
  }
}

export function isAnchorableHeading(node: ContentNode): node is HeadingNode {
  return node.type === "heading" && node.depth <= 2;
}

export function parseHeading(node: HeadingNode): {id: string; text: string} {
  const text = toPlainText(node);
  const id = generateId(text);

  return {id, text};
}

export const getHeaders = (markdown: string): HeadingT[] => {
  const processor = unified().use(remarkParse);
  const ast = processor.parse(markdown);

  const headings: HeadingT[] = [];

  for (const child of ast.children) {
    // Ignore anything that's not an H1 or H2
    if (!isAnchorableHeading(child)) {
      continue;
    }

    const heading: HeadingT = {...parseHeading(child), children: []};

    if (child.depth === 1) {
      headings.push(heading);
    } else if (headings.length > 0) {
      headings[headings.length - 1].children.push(heading);
    }
  }
  return headings;
};
