import {useQueriesData} from "../../../state";
import {Flex, Img, Link, LinkBox, LinkBoxProps} from "@chakra-ui/react";
import {crmUrlFromUri} from "../Configuration/Integrations/CrmConnections";

export const CrmIcon = ({uri, ...props}: {uri: string} & LinkBoxProps) => {
  const [connectors] = useQueriesData({
    queries: [{queryKey: ["vendorToolkit", "crmConnectors"]}],
  });
  const connector_id = uri.split("://")[0];
  const connector = connectors.find(c => c.connector_id === connector_id);
  if (!connector) {
    return null;
  }

  return (
    <LinkBox
      as={Link}
      target="_blank"
      href={crmUrlFromUri(uri)!}
      width="40px"
      display="flex"
      alignItems="center"
      justifyContent="center"
      _hover={{bg: "gray.100", textDecoration: "none"}}
      px="2"
      py="2"
      rounded="full"
      {...props}
    >
      <Flex align="center" fontSize="md">
        <Img src={connector.logo_url} aspectRatio={1} />
      </Flex>
    </LinkBox>
  );
};
export const CrmButton = ({uri, ...props}: {uri: string} & LinkBoxProps) => {
  const [connectors] = useQueriesData({
    queries: [{queryKey: ["vendorToolkit", "crmConnectors"]}],
  });
  const connector_id = uri.split("://")[0];
  const connector = connectors.find(c => c.connector_id === connector_id);
  if (!connector) {
    return null;
  }

  return (
    <LinkBox
      as={Link}
      target="_blank"
      href={crmUrlFromUri(uri)!}
      display="flex"
      alignItems="center"
      justifyContent="center"
      bg="gray.50"
      _hover={{bg: "gray.100", textDecoration: "none"}}
      px="2"
      py="3"
      borderRadius="md"
      {...props}
    >
      <Flex align="center" fontSize="md">
        <Img src={connector.logo_url} h={4} aspectRatio={1} mr={2} />
        {connector.name}
      </Flex>
    </LinkBox>
  );
};
