import {Config, PasswordManager, Sso} from "../../../../../Types";

import PasswordPolicyURL from "./password_policy.md.liquid";
import NoTemplateURL from "./no_template.md.liquid";

export type Template = {
  name: string;
  url: string;
  config: Config;
};

const templates: Template[] = [
  {
    name: "Password Policy",
    url: PasswordPolicyURL,
    config: {
      type: "Password",
      content: {
        tools: {
          password_manager: PasswordManager.OnePassword,
          password_manager_instructions: "",
          sso: Sso.GSuite,
          sso_instructions: "",
        },
        passwords: {
          unique: true,
          random: true,
          characters: {
            lowercase: true,
            uppercase: true,
            number: true,
            symbol: true,
          },
        },
        exceptions: {
          compromised_password_process: "",
          non_compliance:
            "Requests for an exception to this policy must be submitted to the policy owner for approval. You are not permitted to circumvent the policy until approval is granted.",
        },
      },
    },
  },
];

export const NoTemplate: Template = {
  name: "No Template",
  url: NoTemplateURL,
  config: {type: "None"},
};

export default templates;
