import {lazy} from "react";
import {Box, Circle, Flex, Heading, Text} from "@chakra-ui/react";
import {handleImportError} from "../../../utils/import";

type ProgressChartGroup = {
  value: number;
  label: string;
  color: string;
};

type ProgressChartProps = {
  title: string;
  groups: ProgressChartGroup[];
  onGroupClick?: (label: string) => Promise<void>;
};

const ReactApexChart = lazy(() => import("react-apexcharts").catch(handleImportError));

export const ProgressChart = ({title, groups, onGroupClick}: ProgressChartProps) => {
  const series = groups.map(g => g.value);
  const total = series.reduce((a, b) => a + b, 0);
  const options = {
    chart: {
      type: "donut",
      width: 380,
      events: {
        dataPointSelection: function (_event: any, _chartContext: any, config: any) {
          if (onGroupClick) {
            const label = config.w.config.labels[config.dataPointIndex];
            onGroupClick(label);
          }
        },
      },
    },
    colors: groups.map(g => g.color),
    labels: groups.map(g => g.label),
    legend: {
      show: false,
      position: "bottom",
    },

    plotOptions: {
      pie: {
        donut: {
          size: "65%",
          background: "transparent",
        },
      },
    },

    dataLabels: {
      enabled: false,
    },
  } as const;

  return (
    <Box p="8" border="2px solid" borderColor="gray.100" borderRadius="md" maxWidth="400px">
      <Heading size="md">{title}</Heading>

      <Box my={2}>
        <Flex mx="auto" justify="center">
          <Box position="relative">
            <ReactApexChart options={options} series={series} type="donut" width={300} height={300} />
            <Flex
              position="absolute"
              left="0px"
              top="0px"
              w="full"
              h="full"
              align="center"
              justify="center"
              pointerEvents="none"
            >
              <Flex direction="column" align="center">
                <Text fontSize="x-large" fontWeight="bold">
                  {total}
                </Text>
                <Text>Questions</Text>
              </Flex>
            </Flex>
          </Box>
        </Flex>
      </Box>

      <Flex wrap="wrap" alignItems="center" justify="center">
        {groups.map((g, i) => (
          <AnalyticsItem key={i} title={g.label} percent={(g.value * 100) / total} color={g.color} />
        ))}
      </Flex>
    </Box>
  );
};

const AnalyticsItem = ({title, percent, color}: {title: string; percent: number; color: string}) => {
  return (
    <Flex align="center" w="full" p={1} gap={1}>
      <Circle size="12px" bgColor={color} />
      <Flex w="full" justifyContent="space-between">
        <Text>{title}</Text>
        <Text>{isNaN(percent) ? "-" : `${percent.toFixed(0)}%`}</Text>
      </Flex>
    </Flex>
  );
};
