import {useMemo} from "react";
import {useQueryData} from "../../../state";
import {nominate, QuestionnaireId, QuestionnaireMin} from "../../../Types";
import {Select} from "@chakra-ui/react";
import {withSuspense} from "../../../state/withSuspense";
import SelectFallback from "../../../components/SelectFallback";

type Props = {
  value: QuestionnaireId | null;
  onChange: (questionnaireId: QuestionnaireId | null) => void;

  /** Only show questionnaires that match this filter. By default, all questionnaires are listed. */
  filter?: (questionnaire: QuestionnaireMin) => boolean;
};

const NONE_OPTION_KEY = "<none>";

function toOption(value: QuestionnaireId | null): string {
  if (value === null) return NONE_OPTION_KEY;
  return value;
}

function fromOption(option: string): QuestionnaireId | null {
  if (option === NONE_OPTION_KEY) return null;
  return nominate("questionnaireId", option);
}

const QuestionnaireSelector = withSuspense(
  ({value, onChange, filter = () => true}: Props) => {
    const questionnaires = useQueryData({queryKey: ["vendorToolkit", "questionnaires", {historical: false}]})
      .filter(filter)
      .sort((a, b) => a.name.localeCompare(b.name));

    const options = useMemo(
      () => [
        <option key={NONE_OPTION_KEY} value={NONE_OPTION_KEY}>
          None
        </option>,
        ...questionnaires.map(q => (
          <option key={q.questionnaire_id} value={q.questionnaire_id}>
            {q.name}
          </option>
        )),
      ],
      [questionnaires],
    );

    return (
      <Select value={toOption(value)} onChange={e => onChange(fromOption(e.target.value))}>
        {options}
      </Select>
    );
  },
  <SelectFallback />,
);

export default QuestionnaireSelector;
