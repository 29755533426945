import {HStack, Icon, Img, Link, Text} from "@chakra-ui/react";
import {AccountFeature} from "../../Types";
import {MagnifyingGlassCircleIcon} from "@heroicons/react/24/outline";
import Background from "../../../assets/new_features/showcase_search_bg.png";
import {Feature} from ".";

export default {
  heading: "Introducing search for Showcase",
  text: (
    <>
      <Text>
        We've added a dedicated search function to Showcase, making it easier for your visitors to find the information
        they need.
      </Text>
      <Link
        href="https://platformed.com/blog/platformed-adds-search-function-to-its-trust-center-product-platformed-showcase"
        target="_blank"
        color="blue.500"
        fontWeight="semibold"
      >
        Find out more in our blog post.
      </Link>
    </>
  ),
  image: (
    <HStack
      bg="orange.300"
      bgGradient="linear(165deg, yellow.200, orange.200, orange.300)"
      p={4}
      display="grid"
      gridTemplateColumns="auto"
      gridTemplateRows="auto"
      placeItems="center"
    >
      <Img src={Background} rounded="md" gridRow="1/2" gridColumn="1/2" />
      <Icon
        as={MagnifyingGlassCircleIcon}
        w={24}
        h={24}
        gridRow="1/2"
        gridColumn="1/2"
        bg="white"
        borderRadius="100%"
      />
    </HStack>
  ),
  publishDate: new Date(Date.parse("23 Jul 2024")),
  actionText: "Go to Showcase",
  actionRequiredAccountFeatures: [AccountFeature.TrustCenter],
  url: "/vendor-toolkit/trust-center/config",
} satisfies Feature;
