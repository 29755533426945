import {PencilSquareIcon} from "@heroicons/react/20/solid";
import EditorPage from "./EditorPage";
import {RouteTab} from "../../../../../components/RouterTabs";

export default {
  title: "Edit",
  path: "config",
  icon: PencilSquareIcon,
  element: <EditorPage />,
} satisfies RouteTab;
