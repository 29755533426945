import {Heading, Highlight, Icon, Stack, Table, Tbody, Td, Text, Th, Thead, Tr} from "@chakra-ui/react";
import {ChevronRightIcon} from "@heroicons/react/20/solid";
import * as datefns from "date-fns";

import {ActionBar, ActionBarSearch} from "../../../../components/ActionBar.tsx";
import {QuestionnaireMin} from "../../../../Types.ts";
import Counterparty from "../../components/Counterparty.tsx";
import {useFacetedSearch} from "../../../../hooks/search.ts";
import ImportButton from "./ImportButton.tsx";
import {router} from "../../../../router/index.tsx";
import {useQueryData} from "../../../../state/index.ts";
import {withSuspense} from "../../../../state/withSuspense.tsx";
import Page from "../../../../components/Page.tsx";
import {
  facetedSearchQuestionnaires,
  getQuestionnaireRelevantCounterparties,
  getQuestionnaireRelevantOwners,
  QuestionnaireFilters,
} from "../../../components/Filters/index.tsx";
import {useFilterState} from "../../../../hooks/filterState.ts";
import FilterOwners from "../../../components/Filters/FilterOwners.tsx";
import FilterCounterparties from "../../../components/Filters/FilterCounterparties.tsx";
import FilterBanner from "../../../components/Filters/FilterBanner.tsx";

const DEFAULT_FILTERS = {
  owners: ["ASSIGNED_TO_ME", "ASSIGNED_TO_OTHERS", "UNASSIGNED"],
  counterparties: ["ALL"],
};

const QuestionnaireRow = ({questionnaire, queries}: {questionnaire: QuestionnaireMin; queries: string[]}) => {
  const {questionnaire_id, counterparty, name, questionnaire_date} = questionnaire;

  return (
    <Tr
      fontSize="md"
      _hover={{bg: "gray.50", cursor: "pointer"}}
      onClick={() => router!.navigate(`/vendor-toolkit/library/historical-questionnaires/${questionnaire_id}`)}
    >
      <Td>
        <Text color="gray.700" fontWeight="600" noOfLines={2}>
          <Highlight query={queries} styles={{bg: "yellow.200"}}>
            {name}
          </Highlight>
        </Text>
      </Td>
      <Td>
        <Counterparty counterparty={counterparty} queries={queries} />
      </Td>
      <Td>{questionnaire_date && datefns.format(datefns.parseISO(questionnaire_date), "PP")}</Td>
      <Td>
        <Icon as={ChevronRightIcon} fontSize="24" color="gray.500" />
      </Td>
    </Tr>
  );
};

const HistoricalQuestionnairesPage = withSuspense(() => {
  const whoami = useQueryData({queryKey: ["whoAmI"]});
  const questionnaires = useQueryData({queryKey: ["vendorToolkit", "questionnaires", {historical: true}]});

  const {filters, setFilter, clearFilters, filterCount} = useFilterState<QuestionnaireFilters>(
    "historicalQuestionnaireFilters",
    DEFAULT_FILTERS,
  );
  const relevantUsers = getQuestionnaireRelevantOwners(questionnaires, whoami.user_owner!);
  const relevantCounterparties = getQuestionnaireRelevantCounterparties(questionnaires);

  const {query, queries, setQuery, filter} = useFacetedSearch(
    q => q.name,
    facetedSearchQuestionnaires(filters, whoami.associated_owners),
    [filters, whoami.associated_owners],
  );
  const {result, counts} = filter(questionnaires);

  return (
    <Page title="Historical questionnaires">
      <Stack h="full" spacing={0}>
        <Heading size="md" p="8">
          Historical questionnaires
        </Heading>
        <ActionBar
          actionButtons={
            <>
              <FilterOwners
                owners={filters.owners}
                setOwners={newOwners => setFilter("owners", newOwners)}
                relevantOwners={relevantUsers}
                counts={counts.owner}
              />
              <FilterCounterparties
                counterparties={filters.counterparties}
                setCounterparties={newCounterparties => setFilter("counterparties", newCounterparties)}
                allCounterparties={relevantCounterparties}
                counts={counts.counterparty}
              />
              <ImportButton />
            </>
          }
        >
          <ActionBarSearch value={query} onChange={e => setQuery(e.target.value)} />
        </ActionBar>
        <FilterBanner filterCount={filterCount} clearFilters={clearFilters} />
        <Table style={{borderCollapse: "separate", borderSpacing: "0"}} size="lg" layout="fixed">
          <Thead bg="white" borderBottom="10px solid" borderBottomColor="red.100">
            <Tr>
              <Th width="35%">Name</Th>
              <Th width="30%">Counterparty</Th>
              <Th width="30%">Date</Th>
              <Th />
            </Tr>
          </Thead>
          <Tbody>
            {result.map((questionnaire, i) => (
              <QuestionnaireRow questionnaire={questionnaire} queries={queries} key={i} />
            ))}
          </Tbody>
        </Table>
      </Stack>
    </Page>
  );
});

export default HistoricalQuestionnairesPage;
