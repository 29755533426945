import {Button, ButtonProps, forwardRef} from "@chakra-ui/react";
import {useContext} from "react";
import {topRightNavContext} from "./context";

export const RoundButton = forwardRef<ButtonProps, "button">((props, ref) => {
  const {mode} = useContext(topRightNavContext);
  const buttonProps =
    mode === "dark"
      ? {
          bg: "transparent",
          color: "gray.100",
          border: "2px",
          borderColor: "transparent",
          _hover: {color: "white", bg: "gray.700", borderColor: "whiteAlpha.500"},
          _active: {bg: "gray.700", outline: "none", borderColor: "white"},
        }
      : {
          border: "1px",
          color: "blue.500",
          borderColor: "transparent",
          _hover: {
            borderColor: "blue.300",
            backgroundColor: "gray.100",
          },
        };
  return <Button ref={ref} p={1} rounded="full" align="center" {...buttonProps} {...props} />;
});
