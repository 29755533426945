import React from "react";
import * as _ from "lodash-es";
import SectionSideBarItem from "./SectionSideBarItem";
import SideBarAxisHeading from "./SideBarAxisHeading";
import UncategorizedSideBarItem from "./UncategorizedSideBarItem";
import ScopeSideBarItem from "./ScopeSideBarItem";
import {
  getNodeKey,
  getNodeName,
  Hierarchy,
  isGlobalAxis,
  ScopeNode,
  SectionNode,
  useHierarchy,
} from "../../utility/hierarchy";

function SideBarScopeHierarchy() {
  const hierarchy = useHierarchy();

  return (
    <>
      {hierarchy.axes
        .filter(axis => axis.children.length > 0)
        .map(axis => (
          <React.Fragment key={getNodeKey(axis)}>
            {!isGlobalAxis(axis) && <SideBarAxisHeading mt={8}>{getNodeName(axis)}</SideBarAxisHeading>}
            {axis.children.map(scope => (
              <React.Fragment key={getNodeKey(scope)}>
                <ScopeSideBarItem hierarchy={hierarchy} scope={scope} />
                <SectionHierarchy hierarchy={hierarchy} parentScope={scope} sections={scope.children} indent={1} />
                {scope.children.length > 0 && <UncategorizedSideBarItem parentNode={scope} indent={1} />}
              </React.Fragment>
            ))}
          </React.Fragment>
        ))}
    </>
  );
}

/** Recursively render side bar items for sections and their descendants. */
function SectionHierarchy({
  hierarchy,
  parentScope,
  sections,
  indent,
}: {
  hierarchy: Hierarchy;
  parentScope: ScopeNode;
  sections: SectionNode[];
  indent: number;
}) {
  return sections.map(s => (
    <React.Fragment key={s.obj.library_section_id}>
      <SectionSideBarItem hierarchy={hierarchy} indent={indent} section={s} />
      <SectionHierarchy hierarchy={hierarchy} parentScope={parentScope} sections={s.children} indent={indent + 1} />
      {s.children.length > 0 && <UncategorizedSideBarItem parentNode={s} indent={indent + 1} />}
    </React.Fragment>
  ));
}

export default SideBarScopeHierarchy;
