import {Route} from "react-router-dom";

import Login from "./Login";
import ResetRequest from "./ResetRequest";
import ResetLogin from "./ResetLogin";
import api, {HTTPError} from "../api";
import Register from "./Register";
import {getOneTimeToken} from "../utils/auth";
import {ResetLoginInfo} from "../Types";
import UnauthenticatedRoute from "./UnauthenticatedRoute";

export type TokenData = {
  token: string;
  info: ResetLoginInfo;
};

const resetLoginLoader = async (): Promise<TokenData | null> => {
  const token = getOneTimeToken();
  if (!token) {
    return null;
  }
  try {
    const info = await api.loginMethod.validateResetToken(token);
    return {token, info};
  } catch (ex) {
    if (ex instanceof HTTPError && ex.response.status === 401) {
      return null;
    } else {
      throw ex;
    }
  }
};

export default (
  <>
    <Route path="/" element={<UnauthenticatedRoute />}>
      <Route path="/login" index element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/request-reset" element={<ResetRequest />} />
      <Route path="/reset-login" element={<ResetLogin />} loader={resetLoginLoader} />
    </Route>
    <Route path="/logout" loader={api.auth.logout} />
  </>
);
