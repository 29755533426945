import {Route} from "react-router-dom";
import documentActionsRoute from "./Actions";
import DocumentsPage from "./DocumentsPage";
import {DocumentCategory} from "../../../../Types";

const documentRoutes = (
  <>
    <Route
      id="Policies"
      path="policies"
      handle={{crumb: "Policies"}}
      element={<DocumentsPage category={DocumentCategory.Policy} />}
    >
      {documentActionsRoute("Policy")}
    </Route>
    <Route
      id="Certifications"
      path="certifications"
      handle={{crumb: "Certifications"}}
      element={<DocumentsPage category={DocumentCategory.Certification} />}
    >
      {documentActionsRoute("Certification")}
    </Route>
    <Route
      id="Other documents"
      path="other-documents"
      handle={{crumb: "Other documents"}}
      element={<DocumentsPage category={DocumentCategory.Other} />}
    >
      {documentActionsRoute("Other document")}
    </Route>
  </>
);

export default documentRoutes;
