import {ApiDateTime} from "../../../../Types.ts";
import {Tag, TagLabel, TagLeftIcon} from "@chakra-ui/react";
import {CheckIcon} from "@heroicons/react/24/solid";
import {CloudArrowUpIcon} from "@heroicons/react/24/outline";

export const ImportStatus = ({importedAt}: {importedAt?: ApiDateTime}) => {
  return importedAt ? (
    <Tag colorScheme="green" px="2" size="md">
      <TagLeftIcon as={CheckIcon} boxSize="14px" />
      <TagLabel>Imported</TagLabel>
    </Tag>
  ) : (
    <Tag colorScheme="blue" px="2">
      <TagLeftIcon as={CloudArrowUpIcon} boxSize="14px" />
      <TagLabel>Importing</TagLabel>
    </Tag>
  );
};
