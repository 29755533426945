import {Text, useDisclosure} from "@chakra-ui/react";
import {usePromiseState} from "../../../../hooks/promiseState.ts";
import {File as FileT, QuestionnaireId} from "../../../../Types.ts";
import api from "../../../../api/index.ts";
import {DeletedItem, DeleteModalIconButton} from "../../../../components/DeleteModalButton.tsx";

export const DeleteOriginalFileModal = ({file, questionnaireId}: {file: FileT; questionnaireId: QuestionnaireId}) => {
  const {isOpen, onOpen, onClose} = useDisclosure();
  const [deleting, delete_] = usePromiseState(async () => {
    await api.vendorToolkit.questionnaires.deleteOriginalFile(questionnaireId, file.file_id);
    onClose();
  }, [onClose, questionnaireId, file]);

  return (
    <DeleteModalIconButton
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      delete_={delete_}
      deleting={deleting.inProgress}
      modalHeader="Delete original file"
      modalBody={
        <>
          <Text>Are you sure you want to delete this original file? This action cannot be undone.</Text>
          <DeletedItem>{file.name}</DeletedItem>
        </>
      }
    />
  );
};
