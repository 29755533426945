import {DocumentSourceConfig, ExternalAuthorizationId} from "../../../../../../Types";
import ConfluenceDocumentSource from "./ConfluenceDocumentSource";
import GDriveDocumentSource from "./GDriveDocumentSource";
import OneDriveDocumentSource from "./OneDriveDocumentSource";
import SharePointDocumentSource from "./SharePointDocumentSource";
import NotionDocumentSource from "./NotionDocumentSource";
import WebDocumentSource from "./WebDocumentSource";

export type DocumentSourcePayload = {
  external_authorization_id?: ExternalAuthorizationId;
  config: DocumentSourceConfig;
};

export type DocumentSourceProps = {
  connectorId: string;
  payload: DocumentSourcePayload | null;
  setPayload: (payload: DocumentSourcePayload | null) => void;
};

export const AnyDocumentSource = (props: DocumentSourceProps) => {
  switch (props.connectorId) {
    case "gdrive":
      return <GDriveDocumentSource {...props} />;
    case "confluence":
      return <ConfluenceDocumentSource {...props} />;
    case "onedrive":
      return <OneDriveDocumentSource {...props} />;
    case "sharepoint":
      return <SharePointDocumentSource {...props} />;
    case "notion":
      return <NotionDocumentSource {...props} />;
    case "web":
      return <WebDocumentSource {...props} />;
    default:
      return null;
  }
};
