import api from "../../../api";
import {typedQuery} from "../../typing";

export default [
  typedQuery(["vendorToolkit", "scopes"], async () => {
    return await api.vendorToolkit.scopes.list();
  }),
  typedQuery(["vendorToolkit", "scopeAxes"], async () => {
    return await api.vendorToolkit.scopes.listAxes();
  }),
];
