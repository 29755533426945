import {CertificationType} from "../../Types";

import ImageCrest from "../assets/images/crest.png";
import ImageCsaStar from "../assets/images/csa_star.png";
import ImageCyberEssentials from "../assets/images/cyber_essentials.png";
import ImageSoc from "../assets/images/soc.png";
import ImageIso27001 from "../assets/images/iso27001.png";
import ImageIso27017 from "../assets/images/iso27017.png";
import ImageIso27701 from "../assets/images/iso27701.png";
import ImageIso27018 from "../assets/images/iso27018.png";

export function certificationImageFromType(certificationType: CertificationType | undefined): string | undefined {
  if (certificationType === undefined) {
    return undefined;
  }

  switch (certificationType) {
    case CertificationType.CsaStar:
      return ImageCsaStar;
    case CertificationType.Crest:
      return ImageCrest;
    case CertificationType.CyberEssentials:
      return ImageCyberEssentials;
    case CertificationType.Iso27001:
      return ImageIso27001;
    case CertificationType.Iso27017:
      return ImageIso27017;
    case CertificationType.Iso27018:
      return ImageIso27018;
    case CertificationType.Iso27701:
      return ImageIso27701;
    case CertificationType.Soc2:
      return ImageSoc;
    case CertificationType.Soc3:
      return ImageSoc;
  }
}
