import {getFirstRootEntity} from "../../graph/util";
import {useQueryData} from "../../../../state";
import {withSuspense} from "../../../../state/withSuspense";
import Page from "../../../../components/Page";
import {Heading} from "@chakra-ui/react";
import SubPage from "./SubPage";
import {SUBPROCESSORS_GRAPH_QUERY} from "../../graph/types/subprocessor";

const SubprocessorsPage = withSuspense(() => {
  const graph = useQueryData({queryKey: ["vendorToolkit", "graph", SUBPROCESSORS_GRAPH_QUERY]});
  const rootEntity = getFirstRootEntity(graph)!;

  return (
    <Page title="Subprocessors" display="flex" flexDirection="column" position="relative">
      <Heading size="md" p="8">
        Subprocessors
      </Heading>
      {<SubPage graph={graph} rootLegalEntityId={rootEntity.id} />}
    </Page>
  );
});

export default SubprocessorsPage;
