import {
  Button,
  HStack,
  Icon,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import type {RegisteredUser, Team} from "../../../Types.ts";
import {memo} from "react";
import {UserPicker, UserPickerValue} from "../../../components/UserPicker.tsx";
import {useModalState} from "../../../hooks/modalState.ts";
import {UserPlusIcon} from "@heroicons/react/20/solid";
import api from "../../../api/index.ts";
import {usePromiseState} from "../../../hooks/promiseState.ts";
import {InviteUserModal} from "../Users/InviteUser.tsx";
import {useDebounceValue} from "usehooks-ts";

export const AddMemberModal = memo(({team}: {team: Team}) => {
  const {isOpen, onOpen, onClose} = useDisclosure();
  const [userOrInvite, setUserOrInvite] = useModalState<UserPickerValue>(isOpen, {type: "Invite", nameOrEmail: ""});
  const {isOpen: isInviteOpen, onOpen: onInviteOpen, onClose: onInviteClose} = useDisclosure();

  const [addingMember, addMember] = usePromiseState(async () => {
    if (userOrInvite?.type === "User") {
      await api.teams.addMember(team.team_id, userOrInvite.user.user_id);
    } else if (userOrInvite?.type === "Invite") {
      onInviteOpen();
    }
    onClose();
  }, [userOrInvite, onClose, team.team_id, onInviteOpen]);

  const [_, addUser] = usePromiseState(
    async (user: RegisteredUser) => {
      onClose();
      await api.teams.addMember(team.team_id, user.user_id);
    },
    [onClose, team.team_id],
  );

  // Autofocus doesn't work properly unless we add a delay between closing and re-opening a modal
  const [isInviteOpenDebounce] = useDebounceValue(isInviteOpen, 200);

  const initialInviteName =
    userOrInvite?.type === "Invite" && !userOrInvite.nameOrEmail.includes("@") ? userOrInvite.nameOrEmail : "";
  const initialInviteEmail =
    userOrInvite?.type === "Invite" && userOrInvite.nameOrEmail.includes("@") ? userOrInvite.nameOrEmail : "";

  return (
    <>
      <Tooltip label="Add member">
        <IconButton
          flex="0 0 auto"
          icon={<Icon as={UserPlusIcon} />}
          onClick={onOpen}
          aria-label="add-member"
          bgColor={"white"}
          variant={"outline"}
          color="gray.500"
        />
      </Tooltip>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add member</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <UserPicker value={userOrInvite} onSelect={setUserOrInvite} autoFocus />
          </ModalBody>
          <ModalFooter>
            <HStack spacing="3">
              <Button variant="outline" onClick={onClose}>
                Cancel
              </Button>
              <Button
                colorScheme="blue"
                onClick={addMember}
                isDisabled={!userOrInvite || addingMember.inProgress}
                isLoading={addingMember.inProgress}
              >
                Add
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <InviteUserModal
        initialName={initialInviteName}
        initialEmail={initialInviteEmail}
        isOpen={isInviteOpenDebounce && isInviteOpen}
        onInvite={addUser}
        onClose={onInviteClose}
      />
    </>
  );
});
