import {InformationCircleIcon} from "@heroicons/react/20/solid";
import MetaPage from "./MetaPage";
import {RouteTab} from "../../../../../components/RouterTabs";

export default {
  title: "Meta",
  path: "meta",
  icon: InformationCircleIcon,
  element: <MetaPage />,
} satisfies RouteTab;
