import {ScopeMin} from "../../../Types.ts";
import {
  Flex,
  FlexProps,
  HStack,
  Highlight,
  InputGroup,
  InputRightElement,
  Select,
  Spinner,
  Text,
  Box,
  useDisclosure,
  Button,
  Stack,
  Icon,
} from "@chakra-ui/react";
import {usePromiseState} from "../../../hooks/promiseState.ts";
import {memo, useCallback, useMemo} from "react";
import NewScopeModal from "../Configuration/Scopes/NewScopeModal.tsx";
import {useQueryData} from "../../../state/index.ts";
import {Option} from "chakra-multiselect";
import {MultiSelect} from "../../../components/MultiSelect.tsx";
import {PlusIcon} from "@heroicons/react/20/solid";
import {withSuspense} from "../../../state/withSuspense.tsx";

const BUTTON_WIDTH = "72px";
const Scope = ({scope, queries, ...props}: {scope: ScopeMin; queries?: string[]} & FlexProps) => (
  <Flex align="center" {...props}>
    <Text noOfLines={2}>
      <Highlight query={queries ?? ""} styles={{bg: "yellow.200"}}>
        {scope.name}
      </Highlight>
    </Text>
  </Flex>
);

export const ScopeSelector = memo(
  ({value, onChange}: {value: ScopeMin | undefined; onChange: (newValue: ScopeMin) => Promise<void>}) => {
    const {isOpen, onOpen, onClose} = useDisclosure();
    const scopes = useQueryData({queryKey: ["vendorToolkit", "scopes"]});
    const [changing, change] = usePromiseState(
      async (e: React.ChangeEvent<HTMLSelectElement>) => {
        await onChange(scopes.find(scope => scope.scope_id === e.target.value)!);
      },
      [onChange, scopes],
    );
    return (
      <HStack>
        <InputGroup>
          <Select onChange={change} value={value?.scope_id ?? "none"} isDisabled={changing.inProgress}>
            <option value={"none"}>Global only</option>
            {scopes.map(scope => (
              <option key={scope.scope_id} value={scope.scope_id}>
                {scope.axis.name}: {scope.name}
              </option>
            ))}
          </Select>
          {changing.inProgress && (
            <InputRightElement mr={6}>
              <Spinner color="gray.500" size="sm" />
            </InputRightElement>
          )}
        </InputGroup>
        <Box width={BUTTON_WIDTH}>
          <Button colorScheme="blue" variant="ghost" onClick={onOpen}>
            New
          </Button>
          <NewScopeModal isOpen={isOpen} onClose={onClose} onCreate={onChange} />
        </Box>
      </HStack>
    );
  },
);

export const ScopeMultiSelector = withSuspense(
  ({value, onChange}: {value: ScopeMin[] | null; onChange: (newValue: ScopeMin[] | null) => Promise<void> | void}) => {
    const {isOpen, onOpen, onClose} = useDisclosure();
    const scopes = useQueryData({queryKey: ["vendorToolkit", "scopes"]});
    const options = useMemo(
      () => scopes.map(scope => ({label: `${scope.axis.name}: ${scope.name}`, value: scope.scope_id})),
      [scopes],
    );
    const valueOptions = useMemo(
      () =>
        value?.map(scope => ({
          label: `${scope.axis.name}: ${scope.name}`,
          value: scope.scope_id,
          tagProps: {backgroundColor: scope.axis.color},
        })) ?? [],
      [value],
    );
    const [changing, change] = usePromiseState(onChange, [onChange]);
    const changeWrapper = useCallback(
      (newValue: Option | Option[]) => {
        const arr = Array.isArray(newValue) ? newValue : [newValue];
        change(arr.length === 0 ? null : arr.map(item => scopes.find(scope => scope.scope_id === item.value)!));
      },
      [change, scopes],
    );
    const addWrapper = useCallback(
      (scope: ScopeMin) => {
        change([...(value ?? []), scope]);
      },
      [change, value],
    );
    return (
      <Stack>
        <MultiSelect
          options={options}
          value={valueOptions}
          onChange={changeWrapper}
          multiple
          disabled={changing.inProgress}
          placeholder="Global"
        />
        <Button
          leftIcon={<Icon as={PlusIcon} />}
          variant="link"
          colorScheme="blue"
          onClick={onOpen}
          size="sm"
          alignSelf="flex-start"
        >
          New scope
        </Button>
        <NewScopeModal isOpen={isOpen} onClose={onClose} onCreate={addWrapper} />
      </Stack>
    );
  },
  <>
    <Stack>
      <MultiSelect
        options={[]}
        value={[]}
        onChange={() => {}}
        placeholder="Loading..."
        disabled
        controlProps={{disabled: true}}
      />
      <Button
        leftIcon={<Icon as={PlusIcon} />}
        variant="link"
        colorScheme="blue"
        size="sm"
        alignSelf="flex-start"
        isDisabled
      >
        New scope
      </Button>
    </Stack>
  </>,
);

export default Scope;
