import {
  DocumentCategory,
  DocumentExternal,
  DocumentId,
  nominate,
  TrustCenterAiCheckItemConfig,
  TrustCenterAiDataUseItemConfig,
  TrustCenterAiModelProviderConfig,
  TrustCenterFaq,
  TrustCenterSubscribeConfig,
} from "../Types";
import {useQueriesData, useQueryData} from "../state";
import externalApi from "../api/external";
import {useClientAccount} from "./hooks/clientAccount";
import {getFirstRootEntity} from "../Products/VendorToolkit/graph/util";
import {useMemo} from "react";
import {getSubprocessorsFromEntityGraph} from "../Products/VendorToolkit/graph/types/subprocessor";
import {getPeopleFromEntityGraph, OrganizationRelationship} from "../Products/VendorToolkit/graph/types/person";
import {getAssetUrl} from "../api/external/trustCenters";
import {parseNaiveDate} from "../utils/date";

export type DocumentT = {
  name: string;
  requiredPermission?: string;
};

export type SubprocessorT = {
  id: string;
  name: string;
  location?: string;
  gdpr?: ("SCC" | "EUR" | "EQUIVALENCE")[];
  url?: string;
  description?: string;
  trust_center_url?: string;
  logo_src?: string;
  shared_data?: string[];
};

export type PersonT = {
  name: string;
  role?: string;
  src?: string;
  url?: string;
};

export type KybEntry =
  | {key: string; type: "ENTITIES"; value: PersonT[]}
  | {key: string; type: "STRING"; value?: string}
  | {key: string; type: "URL"; value?: string[]};

export type KybT = KybEntry[];

export type CertificationT = {
  image_url: string;
};

type Json = null | boolean | number | string | readonly Json[] | JsonObject;
type JsonObject = {readonly [key: string]: Json};

export type TrustCenterOverview = {
  welcome?: string;
  content?: string;
  trusted_partnered?: string;
  trusted_by?: {image_url: string; link_url: string}[];
};

export type BusinessContent = {
  title?: string;
  content?: string;
};

/** `TrustCenterAiModelProviderConfig` with the related subprocessor materialized. */
export type AiModelProvider = Omit<TrustCenterAiModelProviderConfig, "subprocessor_entity_id"> & {
  subprocessor: SubprocessorT;
};

export type TrustCenterAi = {
  overview?: string;
  related_documents: DocumentId[];
  check_items: TrustCenterAiCheckItemConfig[];
  data_use_items: TrustCenterAiDataUseItemConfig[];
  model_providers: AiModelProvider[];
  faq: TrustCenterFaq;
};

export type ConfigT = {
  name: string;
  logo_url?: string;
  homepage_url?: string;
  favicon_url?: string;
  subprocessors: SubprocessorT[];
  kyb: KybT;
  chakraTheme?: JsonObject;
  hero: {heading: string; subheading?: string};
  overview: TrustCenterOverview;
  business_info?: BusinessContent[];
  ai?: TrustCenterAi;
  faq: TrustCenterFaq;
  is_public: boolean;
  subscribe?: TrustCenterSubscribeConfig;
  privacy_policy?: string;
};

export function useTrustCenterConfig(): ConfigT {
  const account = useClientAccount();
  const config = useQueryData({
    queryKey: ["external", "trustCenterConfig", account.account_id],
  });
  const faq = useQueryData({
    queryKey: ["external", "trustCenterFaq", account.account_id],
  });
  const aiFaq = useQueryData({queryKey: ["external", "trustCenterAiFaq", account.account_id]});
  const graph = useQueryData({
    queryKey: [
      "external",
      "trustCenterGraph",
      account.account_id,
      {
        filter_properties: ["is_root_legal_entity"],
      },
    ],
  });

  // Build a list of all the content we need to load for this trust center
  const contentIds = [];
  if (config.meta.overview?.welcome_id) {
    contentIds.push(config.meta.overview?.welcome_id);
  }
  if (config.meta.overview?.content_id) {
    contentIds.push(config.meta.overview?.content_id);
  }
  if (config.meta.business_info) {
    for (const c of config.meta.business_info) {
      if (c.content_id) {
        contentIds.push(c.content_id);
      }
    }
  }
  if (config.meta.ai?.overview_content_id) {
    contentIds.push(config.meta.ai?.overview_content_id);
  }

  const contents = useQueriesData({
    queries: contentIds.map(contentId => ({
      queryKey: ["external", "trustCenterContent", account.account_id, contentId],
    })),
  });

  // Build legal and subprocessor information from entity graph

  const legalEntity = useMemo(() => getFirstRootEntity(graph), [graph]);

  const kyb = useMemo((): KybT => {
    if (!legalEntity) {
      return [];
    }
    const graphPeople = getPeopleFromEntityGraph(graph, legalEntity.id);

    const peopleEntries: {[K in OrganizationRelationship]: PersonT[]} = {
      beneficial_owner: [],
      board_member: [],
      senior_leadership: [],
    };

    for (const p of graphPeople) {
      const person: PersonT = {
        name: p.name,
        role: p.jobTitle,
        url: p.websiteUrl.length === 0 ? undefined : p.websiteUrl,
        src: p.photoAssetId ? getAssetUrl(account.account_id, p.photoAssetId) : undefined,
      };

      for (const r of p.organizationRelationships) {
        peopleEntries[r].push(person);
      }
    }

    // A hardcoded list of properties on the trust center. In the future, we may
    // want to populate this dynamically using the /schema endpoint to get the
    // list of properties and their labels.
    return (
      [
        {type: "STRING", key: "Name", value: legalEntity.properties["name"]},
        {type: "STRING", key: "Country of registration", value: legalEntity.properties["country_of_registration"]},
        {type: "STRING", key: "Companies House number", value: legalEntity.properties["companies_house_number"]},
        {type: "STRING", key: "D-U-N-S number", value: legalEntity.properties["duns_number"]},
        {type: "STRING", key: "VAT number", value: legalEntity.properties["vat_number"]},
        {type: "STRING", key: "ICO registration", value: legalEntity.properties["ico_registration"]},
        {type: "STRING", key: "Registered address", value: legalEntity.properties["registered_address"]},
        {
          type: "STRING",
          key: "Incorporation date",
          value: legalEntity.properties["incorporation_date"]
            ? parseNaiveDate(nominate("date", legalEntity.properties["incorporation_date"]))?.toLocaleDateString(
                undefined,
                {
                  dateStyle: "long",
                },
              )
            : undefined,
        },
        {
          type: "STRING",
          key: "Number of employees",
          value: legalEntity.properties["number_of_employees"]
            ? legalEntity.properties["number_of_employees"].toString()
            : undefined,
        },
        {type: "STRING", key: "Industry", value: legalEntity.properties["industry_type"]},
        {type: "STRING", key: "Growth plan/strategic direction", value: legalEntity.properties["growth_plan"]},
        {
          type: "STRING",
          key: "Legal entity type",
          value:
            legalEntity.properties["official_legal_entity_type"] === "Other (please specify)"
              ? ""
              : legalEntity.properties["official_legal_entity_type"],
        },

        {type: "ENTITIES", key: "Beneficial owners", value: peopleEntries.beneficial_owner},
        {type: "ENTITIES", key: "Board members", value: peopleEntries.board_member},
        {type: "ENTITIES", key: "Senior leadership", value: peopleEntries.senior_leadership},
        {
          type: "URL",
          key: "Website",
          value: legalEntity.properties["website_url"] ? [legalEntity.properties["website_url"]] : undefined,
        },
      ] satisfies KybT
    ).filter(r => r.value !== undefined && r.value.length !== 0);
  }, [account.account_id, graph, legalEntity]);

  const subprocessors = useMemo(
    (): SubprocessorT[] =>
      legalEntity
        ? getSubprocessorsFromEntityGraph(graph, legalEntity.id).map(s => ({
            id: s.id,
            name: s.name,
            description: s.description,
            location: s.isoCountryCode,
            url: s.websiteUrl,
            trust_center_url: s.trustCenterUrl,
            shared_data: s.sharedData.length === 0 ? undefined : s.sharedData.split("\n"),
            gdpr: [
              ...(s.gdprSccs ? (["SCC"] as const) : []),
              ...(s.gdprBasedInEurope ? (["EUR"] as const) : []),
              ...(s.gdprEquivalence ? (["EQUIVALENCE"] as const) : []),
            ],
            logo_src: undefined,
          }))
        : [],
    [graph, legalEntity],
  );

  // Filter only model providers with an associated existing subprocessor.
  const aiModelProviders = useMemo(
    () =>
      config.meta.ai?.model_providers?.flatMap(modelProvider => {
        if (modelProvider.subprocessor_entity_id == null) {
          return [];
        }

        const subprocessor = subprocessors.find(s => s.id === modelProvider.subprocessor_entity_id);

        if (subprocessor == null) {
          return [];
        }

        return {
          subprocessor,
          purposes: modelProvider.purposes,
          models_used: modelProvider.models_used,
        } satisfies AiModelProvider;
      }) ?? [],
    [config.meta.ai?.model_providers, subprocessors],
  );

  return {
    name: config.meta.name ?? "",
    logo_url:
      config.meta.logo_asset_id && externalApi.trustCenters.getAssetUrl(account.account_id, config.meta.logo_asset_id),
    homepage_url: config.meta.homepage_url ?? "",
    favicon_url: config.meta.favicon_asset_id
      ? externalApi.trustCenters.getAssetUrl(account.account_id, config.meta.favicon_asset_id)
      : undefined,
    subprocessors,
    kyb,
    chakraTheme: config.theme,
    hero: {heading: config.meta.hero?.heading ?? "", subheading: config.meta.hero?.subheading},
    overview: {
      content: contents.find(content => content.content_id === config.meta.overview?.content_id)?.content ?? "",
      welcome: contents.find(content => content.content_id === config.meta.overview?.welcome_id)?.content ?? "",
      trusted_partnered: config.meta.overview?.trusted_partnered,
      trusted_by: config.meta.overview?.trusted_by?.map(trustedBy => ({
        image_url: trustedBy.logo_asset_id
          ? externalApi.trustCenters.getAssetUrl(account.account_id, trustedBy.logo_asset_id)
          : "",
        link_url: trustedBy.logo_link_url ?? "",
      })),
    },
    business_info: config.meta.business_info?.map(businessContent => ({
      title: businessContent.title ?? "",
      content: contents.find(content => content.content_id === businessContent.content_id)?.content ?? "",
    })),
    ai:
      config.meta.ai != null
        ? {
            overview:
              contents.find(content => content.content_id === config.meta.ai!.overview_content_id)?.content ?? "",
            related_documents:
              config.meta.ai.related_documents?.flatMap(d => (d.document_id ? [d.document_id] : [])) ?? [],
            check_items: config.meta.ai.check_items ?? [],
            data_use_items: config.meta.ai.data_use_items ?? [],
            model_providers: aiModelProviders,
            faq: aiFaq,
          }
        : undefined,
    faq,
    is_public: config.is_public,
    subscribe: config.meta.subscribe,
    privacy_policy: config.meta.privacy_policy,
  };
}

export function useTrustCenterDocuments(): DocumentExternal[] {
  const clientAccount = useClientAccount();
  const allDocuments = useQueryData({queryKey: ["external", "trustCenterDocuments", clientAccount.account_id]});

  const documents = allDocuments
    .filter(
      // Certifications are shown on the Certifications page
      d => d.category !== DocumentCategory.Certification,
    )
    .sort((a, b) => a.name.localeCompare(b.name));

  return documents;
}

export function useTrustCenterCertifications(): DocumentExternal[] {
  const clientAccount = useClientAccount();
  const allDocuments = useQueryData({queryKey: ["external", "trustCenterDocuments", clientAccount.account_id]});

  const certifications = allDocuments
    .filter(d => d.category === DocumentCategory.Certification)
    .sort((a, b) => a.name.localeCompare(b.name));

  return certifications;
}

export type TrustCenterState = {
  config: ConfigT;
  documents: DocumentExternal[];
  certifications: DocumentExternal[];
};

export function useTrustCenterState(): TrustCenterState {
  const config = useTrustCenterConfig();
  const documents = useTrustCenterDocuments();
  const certifications = useTrustCenterCertifications();

  return {config, documents, certifications};
}
