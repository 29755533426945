import {NewESignatureProvider} from "./NewESignatureProvider.tsx";
import {DocumentTextIcon} from "@heroicons/react/24/outline";
import {Route} from "react-router-dom";
import {GenericOauthOptions} from "../../../../hooks/genericOauth.ts";
import {getPublicCredentials} from "../../../../utils/environment.ts";
import {ExternalAuthorizationProvider} from "../../../../Types.ts";
import ESignatureProvidersPage from "./ESignatureProvidersPage.tsx";
import {ConfigureEsignatureProvider} from "./ConfigureEsignatureProvider.tsx";

export const DOCUSIGN_ENDPOINT = getPublicCredentials().docusign_endpoint;

export const DOCUSIGN_OAUTH_OPTIONS: GenericOauthOptions = {
  authUrl: `${DOCUSIGN_ENDPOINT}oauth/auth`,
  clientId: getPublicCredentials().docusign_client_id,
  scope: "signature",
  provider: ExternalAuthorizationProvider.Docusign,
  extra: {},
  purpose: "Sign documents with Docusign",
};

export default {
  title: "E-signature providers",
  path: "esignature-providers",
  icon: DocumentTextIcon,
  routes: (
    <>
      <Route path="" element={<ESignatureProvidersPage />} />
      <Route path="new/:connectorType" element={<NewESignatureProvider />} />
      <Route path="configure/:esignatureProviderId" element={<ConfigureEsignatureProvider />} />
    </>
  ),
};
