import {
  Button,
  Checkbox,
  HStack,
  Icon,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Tooltip,
  UnorderedList,
  useDisclosure,
} from "@chakra-ui/react";
import {ArchiveBoxIcon} from "@heroicons/react/20/solid";
import {usePromiseState} from "../../../../hooks/promiseState.ts";
import api from "../../../../api/index.ts";
import {LayerType, Questionnaire, QuestionnaireStatus, QuestionStatus} from "../../../../Types.ts";
import {useState} from "react";
import {getExternalLayer} from "../../../../hooks/layerType.ts";

const ArchiveModal = ({questionnaire}: {questionnaire: Questionnaire}) => {
  const {isOpen, onOpen, onClose} = useDisclosure();
  const [completeQuestions, setCompleteQuestions] = useState(false);

  const [archiving, archive] = usePromiseState(async () => {
    if (completeQuestions) {
      await api.vendorToolkit.questions.bulkUpdate({
        items: questionnaire.sections
          .flatMap(s => s.questions)
          .filter(q => getExternalLayer(q).status !== QuestionStatus.Complete)
          .map(q => ({
            question_id: q.question_id,
            action: {type: "SetStatus", content: QuestionStatus.Complete},
          })),
        layer_type: LayerType.External,
      });
    }
    await api.vendorToolkit.questionnaires.updateArchived(questionnaire.questionnaire_id, true);
  }, [questionnaire, completeQuestions]);

  const archiveOrOpen = async () => {
    questionnaire.status == QuestionnaireStatus.Complete ? await archive() : onOpen();
  };

  if (!isOpen) {
    // Reset state when modal is closed
    if (completeQuestions) setCompleteQuestions(false);
  }

  const canArchive =
    questionnaire.status != QuestionnaireStatus.Importing && questionnaire.status != QuestionnaireStatus.Automating;

  const willQuestionsBeComplete =
    questionnaire.question_stats.completed === questionnaire.question_stats.count || completeQuestions;

  return (
    <>
      <Tooltip
        placement="top"
        label="You cannot archive a questionnaire while it is Importing or Automating"
        isDisabled={canArchive}
      >
        <Button
          leftIcon={<Icon as={ArchiveBoxIcon} />}
          onClick={archiveOrOpen}
          isLoading={archiving.inProgress}
          isDisabled={!canArchive}
        >
          Archive
        </Button>
      </Tooltip>
      <Modal isOpen={isOpen} onClose={onClose} size="lg">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Archive questionnaire</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text fontSize={"md"}>Only completed questions will be used for automated response generation.</Text>
            <UnorderedList fontSize={"md"} mt={2}>
              <ListItem>
                <Text fontWeight="semibold">
                  {questionnaire.question_stats.completed} / {questionnaire.question_stats.count} questions are
                  complete.
                </Text>
                {questionnaire.question_stats.completed < questionnaire.question_stats.count && (
                  <Checkbox checked={completeQuestions} m={2} onChange={e => setCompleteQuestions(e.target.checked)}>
                    Complete remaining questions.
                  </Checkbox>
                )}
              </ListItem>
            </UnorderedList>
          </ModalBody>
          <ModalFooter>
            <HStack spacing="3">
              <Button variant="ghost" onClick={onClose}>
                Cancel
              </Button>
              <Button
                colorScheme={completeQuestions ? "green" : "gray"}
                variant={willQuestionsBeComplete || !completeQuestions ? "solid" : "outline"}
                onClick={archive}
                isLoading={archiving.inProgress}
              >
                {completeQuestions ? "Complete and archive" : "Archive anyway"}
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ArchiveModal;
