import {useMemo} from "react";
import {useQueryData} from "../../../state";
import {DocumentCategory, DocumentId, DocumentMin, nominate} from "../../../Types";
import {Select} from "@chakra-ui/react";
import {DOCUMENT_CATEGORY_MAP} from "./DocumentCategory";
import {withSuspense} from "../../../state/withSuspense";
import SelectFallback from "../../../components/SelectFallback";

type Props = {
  value: DocumentId | null;
  onChange: (documentId: DocumentId | null) => void;

  /** Only show documents that match this filter. By default, all documents are listed. */
  filter?: (document: DocumentMin) => boolean;

  /** Show category prefixes before document names. */
  showCategoryPrefix?: boolean;
};

const NONE_OPTION_KEY = "<none>";

function toOption(value: DocumentId | null): string {
  if (value === null) return NONE_OPTION_KEY;
  return value;
}

function fromOption(option: string): DocumentId | null {
  if (option === NONE_OPTION_KEY) return null;
  return nominate("documentId", option);
}

const DocumentSelector = withSuspense(
  ({value, onChange, filter = () => true, showCategoryPrefix = false}: Props) => {
    const documents = useQueryData({queryKey: ["vendorToolkit", "documents"]})
      .filter(filter)
      .sort((a, b) => {
        if (showCategoryPrefix) {
          // "Other" category should always be last
          if (a.category === DocumentCategory.Other && b.category !== DocumentCategory.Other) return 1;
          if (a.category !== DocumentCategory.Other && b.category === DocumentCategory.Other) return -1;

          // Compare by category label otherwise
          const aCategory = DOCUMENT_CATEGORY_MAP[a.category].text;
          const bCategory = DOCUMENT_CATEGORY_MAP[b.category].text;
          if (aCategory !== bCategory) return aCategory.localeCompare(bCategory);
        }

        return a.name.localeCompare(b.name);
      });

    const options = useMemo(
      () => [
        <option key={NONE_OPTION_KEY} value={NONE_OPTION_KEY}>
          None
        </option>,
        ...documents.map(d => (
          <option key={d.document_id} value={d.document_id}>
            {showCategoryPrefix && `[${DOCUMENT_CATEGORY_MAP[d.category].text}] `}
            {d.name}
          </option>
        )),
      ],
      [documents, showCategoryPrefix],
    );

    return (
      <Select value={toOption(value)} onChange={e => onChange(fromOption(e.target.value))}>
        {options}
      </Select>
    );
  },
  <SelectFallback />,
);

export default DocumentSelector;
