import {Box, Img, Link, Text} from "@chakra-ui/react";
import {AccountFeature} from "../../Types";
import Background from "../../../assets/new_features/showcase_ai_posture.png";
import {Feature} from ".";

export default {
  heading: "Showcase your AI posture",
  text: (
    <>
      <Text>You can now display your organisation's AI posture in Showcase with our newly added AI page.</Text>
      <Link
        href="https://platformed.com/blog/showcase-your-ai-transparency-with-platformeds-latest-trust-center-update"
        target="_blank"
        color="blue.500"
        fontWeight="semibold"
      >
        Find out more in our blog post.
      </Link>
    </>
  ),
  image: (
    <Box bg="orange.300" bgGradient="linear(165deg, yellow.200, orange.200, orange.300)" p={4}>
      <Img src={Background} rounded="md" gridRow="1/2" gridColumn="1/2" />
    </Box>
  ),
  publishDate: new Date(Date.parse("6 Aug 2024")),
  actionText: "Go to Showcase",
  actionRequiredAccountFeatures: [AccountFeature.TrustCenter],
  url: "/vendor-toolkit/trust-center/config",
} satisfies Feature;
