import {ChartPieIcon} from "@heroicons/react/20/solid";
import ProgressPage from "./ProgressPage";
import {RouteTab} from "../../../../../components/RouterTabs";

export default {
  title: "Progress",
  path: "progress",
  icon: ChartPieIcon,
  element: <ProgressPage />,
} satisfies RouteTab;
