import {NavLink, Params, useMatches} from "react-router-dom";
import {HomeIcon} from "@heroicons/react/20/solid";
import {Breadcrumb, BreadcrumbItem, BreadcrumbLink, Icon, Spinner, Text} from "@chakra-ui/react";
import {ComponentType, ReactNode, Suspense} from "react";
import {useLayerColor} from "./headerColor";
import {ErrorBoundary} from "react-error-boundary";

const breadcrumbStyles = (color: string) => ({
  _hover: {decoration: "none", bg: `${color}.500`},
  py: "1",
  px: "2",
  rounded: "md",
  maxWidth: "200px",
  overflow: "hidden",
  textOverflow: "ellipsis",
});

export const StyledBreadcrumbLink = ({color, ...props}: {children: React.ReactNode; to: string; color: string}) => (
  <BreadcrumbLink as={NavLink} {...breadcrumbStyles(color)} whiteSpace="nowrap" {...props} />
);

export type PathSegmentBreadcrumbProps = {
  id: string;
  pathname: string;
  params: Params<string>;
  data: object | null | undefined;
  color: string;
  handle?: {
    crumb?: ReactNode;
    Crumb?: ComponentType<Params<string>>;
  };
};

export function PathSegmentBreadcrumb({params, pathname, handle, color}: PathSegmentBreadcrumbProps) {
  const {crumb, Crumb} = handle ?? {};
  return crumb ?? Crumb ? (
    <Suspense fallback={<Spinner size="xs" color={`${color}.300`} />}>
      <StyledBreadcrumbLink to={pathname} color={color}>
        {Crumb ? <Crumb {...params} /> : crumb}
      </StyledBreadcrumbLink>
    </Suspense>
  ) : null;
}

function ErrorBreadcrumb() {
  return "???";
}

export default function BreadcrumbNav() {
  const matches = useMatches() as PathSegmentBreadcrumbProps[];
  const color = useLayerColor();

  return (
    <Breadcrumb
      spacing="8px"
      separator={<Text color={`${color}.200`}>/</Text>}
      py="2"
      color={`${color}.50`}
      px="4"
      fontSize="md"
      fontWeight="medium"
      h="12"
    >
      <BreadcrumbItem>
        <StyledBreadcrumbLink to="." color={color}>
          <Icon as={HomeIcon} display="block" my="1" />
        </StyledBreadcrumbLink>
      </BreadcrumbItem>

      {matches.map(
        m =>
          (m?.handle?.crumb || m?.handle?.Crumb) && (
            <BreadcrumbItem key={m.pathname}>
              <ErrorBoundary fallback={<ErrorBreadcrumb />}>
                <PathSegmentBreadcrumb {...(m as PathSegmentBreadcrumbProps)} />
              </ErrorBoundary>
            </BreadcrumbItem>
          ),
      )}
    </Breadcrumb>
  );
}
