import {Box, GridItem, Text, RadioGroup, Stack} from "@chakra-ui/react";
import {CreateLoginMethod, LoginMethod} from "../../../Types";
import {AnyLoginMethod} from "./AnyLoginMethod";

type LoginMethodConfiguratorProps = {
  isLoggedIn: boolean;
  currentMethod?: LoginMethod;
  existingMethods?: LoginMethod[];
  recommendedMethods?: LoginMethod[];
  enabled2fa: boolean;
  onUpsert: (loginMethod: LoginMethod, payload: CreateLoginMethod) => Promise<void>;
  // If omitted, the configurator will only allow you to pick a new login method,
  // not configure existing ones.
  onDelete?: (loginMethod: LoginMethod) => Promise<void>;
  onSelect?: (loginMethod: LoginMethod) => Promise<void>;
};

export function LoginMethodConfigurator({
  isLoggedIn,
  currentMethod,
  existingMethods,
  recommendedMethods,
  enabled2fa,
  onUpsert,
  onDelete,
  onSelect,
}: LoginMethodConfiguratorProps) {
  const allMethods = [
    ...(recommendedMethods ?? []),
    ...Object.values(LoginMethod).filter(loginMethod => !recommendedMethods?.includes(loginMethod)),
  ];

  return (
    <GridItem colSpan={9}>
      <Box py="6" px="4" pb="8">
        <Text fontSize="md" mt="1">
          Choose how you will log in to your account going forward.
        </Text>
        {enabled2fa && (
          <Text fontSize="md" mt="1" color="red.500">
            Cannot change login method when 2FA is enabled.
          </Text>
        )}
        <RadioGroup as={Stack} mt="8" spacing="0" value={currentMethod ?? "<none>"}>
          {allMethods.map(loginMethod => (
            <AnyLoginMethod
              key={loginMethod}
              isLoggedIn={isLoggedIn}
              loginMethod={loginMethod}
              isSelected={loginMethod === currentMethod}
              isDisabled={enabled2fa && loginMethod !== LoginMethod.UsernamePassword}
              isRecommended={recommendedMethods?.includes(loginMethod) ?? true}
              isConfigured={existingMethods?.includes(loginMethod) ?? false}
              onUpsert={onUpsert}
              onDelete={onDelete}
              onSelect={onSelect}
            />
          ))}
        </RadioGroup>
      </Box>
    </GridItem>
  );
}
