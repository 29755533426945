import React from "react";
import {Text} from "@chakra-ui/react";

const CodeSpan = ({children}: {children: React.ReactNode}) => {
  return (
    <Text display="inline-block" fontFamily="monospace" backgroundColor="gray.50" paddingX="2px" color="red.400">
      {children}
    </Text>
  );
};

export default CodeSpan;
