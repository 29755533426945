import {
  Box,
  BoxProps,
  Button,
  HStack,
  Icon,
  Link,
  LinkProps,
  Stack,
  StackProps,
  useStyleConfig,
} from "@chakra-ui/react";
import {ChevronDownIcon} from "@heroicons/react/20/solid";
import ContentWrapper from "./components/ContentWrapper";
import {NavLink, Link as RouterLink} from "react-router-dom";
import {PageT} from "./Pages";
import {useTrustCenterConfig, useTrustCenterState} from "./config";
import {MagnifyingGlassIcon} from "@heroicons/react/24/solid";

const StyledTabLink = ({to, variant = "nav", ...props}: {to: string} & LinkProps) => {
  return (
    <Link
      as={NavLink}
      to={to}
      py={3}
      px={4}
      fontWeight="semibold"
      display="inline-flex"
      alignItems="center"
      fontSize="md"
      gap={2}
      _hover={{textDecoration: "none"}}
      variant={variant}
      {...props}
    />
  );
};

const HoverMenu = (props: StackProps) => <Stack position="absolute" top="100%" left={0} w="full" {...props} />;

const HoverMenuList = ({boxProps, ...props}: {boxProps: BoxProps} & StackProps) => {
  // We wrap the menu in a larger box to give less aggressive mouseout-closing behaviour.
  const mouseOutPadding = 4;
  return (
    <Box
      p={mouseOutPadding}
      pt={mouseOutPadding - 2}
      mx={-mouseOutPadding}
      mt={4 - mouseOutPadding}
      {...boxProps}
      display="none"
      _groupHover={{display: "block"}}
    >
      <Stack
        border="1px"
        fontSize="md"
        shadow="lg"
        overflow="hidden"
        spacing={0}
        py={4}
        bg="white"
        color="gray.700"
        borderColor="gray.200"
        rounded="lg"
        {...props}
      />
    </Box>
  );
};

const HoverMenuItem = (props: {to: string} & BoxProps) => (
  <Box as={RouterLink} _hover={{color: "brand.500"}} py={2} px={4} {...props} />
);

const NavTab = ({page, children}: LinkProps & {page: PageT}) => {
  const config = useTrustCenterConfig();

  return (
    <Box position="relative" role="group">
      {page.subpages?.length ? (
        <>
          <StyledTabLink to={page.to || `./${page.path}`}>
            {children} <Icon as={ChevronDownIcon} />
          </StyledTabLink>
          <HoverMenu>
            <HoverMenuList boxProps={{w: "250px"}}>
              <HoverMenuItem to={page.to || `./${page.path}`} fontWeight="bold">
                {page.text}
              </HoverMenuItem>
              {page.subpages(config).map((s, i) => (
                <HoverMenuItem to={s.to || `./${s.path}`} key={i} pl={10}>
                  {s.text}
                </HoverMenuItem>
              ))}
            </HoverMenuList>
          </HoverMenu>
        </>
      ) : (
        <StyledTabLink to={`./${page.path}`}>{children}</StyledTabLink>
      )}
    </Box>
  );
};

export const NavTabs = ({pages, onClickSearch}: {pages: PageT[]; onClickSearch: () => void}) => {
  const state = useTrustCenterState();
  const styles = useStyleConfig("NavTabs");

  return (
    <Box __css={styles}>
      <ContentWrapper>
        <HStack gap={0}>
          {pages
            .filter(page => page.visible?.(state) ?? true)
            .filter(page => !page.right)
            .map(page => (
              <NavTab key={page.path} page={page}>
                {page.text}
              </NavTab>
            ))}
          <Box flex={1} minWidth={4} />
          <Button
            leftIcon={<Icon as={MagnifyingGlassIcon} />}
            variant="nav"
            onClick={onClickSearch}
            flex="0 0 auto"
            rounded="none"
            alignSelf="stretch"
            height="auto"
          >
            Search
          </Button>
          {pages
            .filter(page => page.visible?.(state) ?? true)
            .filter(page => page.right)
            .map(page => (
              <NavTab key={page.path} page={page}>
                {page.text}
              </NavTab>
            ))}
        </HStack>
      </ContentWrapper>
    </Box>
  );
};
