import {LayerType, Question, ResponseLayer} from "../Types";
import {useAccountLocalStorage} from "./accountLocalStorage";

export function useLayerType() {
  return useAccountLocalStorage(`questionLayerType`, LayerType.External);
}

const LAYER_TYPES = [LayerType.External, LayerType.Internal, LayerType.AI];

type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;
export type OptionalResponseLayer = PartialBy<ResponseLayer, "activity_stream">;

export function getResponseLayer(
  responseLayers: Question["response_layers"],
  layerType: LayerType,
): OptionalResponseLayer {
  let responseLayer = responseLayers[layerType];
  if (responseLayer) {
    return responseLayer;
  }
  for (let idx = LAYER_TYPES.indexOf(layerType) - 1; idx >= 0; --idx) {
    responseLayer = responseLayers[LAYER_TYPES[idx]];
    if (responseLayer) {
      return {
        ...responseLayer,
        owner: undefined,
        documentation_count: 0,
        activity_stream: undefined,
      };
    }
  }
  throw new Error("Unreachable");
}

export function getExternalLayer(question: Question): ResponseLayer {
  return question.response_layers[LayerType.External]!;
}
