import {useGenericOauth} from "../../../../hooks/genericOauth.ts";
import {useCallback, useState} from "react";
import {DocusignEsignatureProviderConfig, ExternalAuthorization} from "../../../../Types.ts";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Img,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import Logo from "../../../../../assets/docusign.svg";
import {DOCUSIGN_ENDPOINT, DOCUSIGN_OAUTH_OPTIONS} from "./index.tsx";
import {EsignatureProviderProps} from "./AnyEsignatureProvider.tsx";
import {HTTPError} from "../../../../api/index.ts";
import {useSuspenseQuery} from "@tanstack/react-query";
import {withSuspense} from "../../../../state/withSuspense.tsx";

type DocusignUserInfo = {
  accounts: DocusignAccount[];
};

type DocusignAccount = {
  account_id: string;
  is_default: boolean;
  account_name: string;
  base_uri: string;
};

async function jsonFetch<T>(input: RequestInfo | URL, init?: RequestInit & {bearer?: string}): Promise<T> {
  const headers: HeadersInit = {
    Accept: "application/json",
  };
  if (init?.bearer) {
    headers["Authorization"] = `Bearer ${init.bearer}`;
  }
  const resp = await fetch(
    new Request(input, {
      headers,
    }),
    init,
  );
  if (!resp.ok) {
    throw new HTTPError(resp);
  }
  return await resp.json();
}

type ConfigureDocusignForm = {
  account_id: string;
} & DocusignEsignatureProviderConfig;

function accessToken(auth: ExternalAuthorization) {
  if (auth.payload.type !== "Oauth") {
    throw new Error("Must use Oauth");
  }
  return auth.payload.content.access_token;
}

const ConfigureDocusignModalContent = withSuspense(
  ({
    auth,
    onClose,
    onForm,
  }: {
    auth: ExternalAuthorization;
    onClose: () => void;
    onForm: (onForm: ConfigureDocusignForm) => void;
  }) => {
    const accounts = useSuspenseQuery({
      queryKey: ["docusign", "oauth/userinfo", auth.external_authorization_id],
      queryFn: async () => {
        const res: DocusignUserInfo = await jsonFetch(`${DOCUSIGN_ENDPOINT}oauth/userinfo`, {
          bearer: accessToken(auth),
        });

        return res.accounts;
      },
    });

    const [form, setForm] = useState<ConfigureDocusignForm>({
      account_id: accounts.data[0].account_id,
      account_name: accounts.data[0].account_name,
      base_uri: accounts.data[0].base_uri,
    });

    const updateAccountInfo = useCallback(
      (account_id: string) => {
        const account = accounts.data.find(a => a.account_id === account_id);

        if (account === undefined) {
          return;
        }

        setForm(f => ({
          ...f,
          account_id,
          account_name: account.account_name,
          base_uri: account.base_uri,
        }));
      },
      [accounts.data, setForm],
    );

    return (
      <>
        <ModalBody>
          <FormControl>
            <FormLabel>Account</FormLabel>
            <Select value={form.account_id} onChange={e => updateAccountInfo(e.target.value)}>
              {accounts.data.map(a => (
                <option key={a.account_id} value={a.account_id}>
                  {a.account_name}
                </option>
              ))}
            </Select>
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <HStack spacing="3">
            <Button variant="outline" onClick={onClose}>
              Cancel
            </Button>
            <Button
              colorScheme="blue"
              onClick={() => {
                onForm(form);
                onClose();
              }}
            >
              Configure
            </Button>
          </HStack>
        </ModalFooter>
      </>
    );
  },
);

const ConfigureDocusignModal = ({
  isOpen,
  onClose,
  auth,
  onForm,
}: {
  isOpen: boolean;
  onClose: () => void;
  auth: ExternalAuthorization | undefined;
  onForm: (form: ConfigureDocusignForm) => void;
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Configure Docusign</ModalHeader>
        <ModalCloseButton />
        {auth !== undefined && <ConfigureDocusignModalContent auth={auth} onClose={onClose} onForm={onForm} />}
      </ModalContent>
    </Modal>
  );
};

export const DocusignEsignatureProvider = ({payload, setPayload}: EsignatureProviderProps) => {
  const {isOpen, onOpen, onClose} = useDisclosure();
  const [requestingAuth, requestAuth] = useGenericOauth();

  const handleClickSelectAccount = useCallback(async () => {
    const auth = await requestAuth(DOCUSIGN_OAUTH_OPTIONS);

    if (!auth.ok) {
      return;
    }

    onOpen();
  }, [onOpen, requestAuth]);

  const handleForm = useCallback(
    (form: ConfigureDocusignForm) => {
      setPayload({
        config: {
          type: "Docusign",
          content: {
            account_name: form.account_name,
            base_uri: form.base_uri,
          },
        },
        external_authorization_id: requestingAuth.lastResult!.external_authorization_id,
        external_account_id: form.account_id,
      });
    },
    [requestingAuth.lastResult, setPayload],
  );

  const config = payload?.config;
  if (config && config.type !== "Docusign") {
    return null;
  }

  return (
    <>
      <Box w="full">
        <Flex w="full">
          {config ? (
            <Flex
              flex={1}
              display="inline-flex"
              align="center"
              border="1px solid"
              borderRight="none"
              borderColor="gray.200"
              borderLeftRadius="md"
              px={4}
              gap={2}
            >
              <Img src={Logo} height="24px" aspectRatio={1} />
              <Text fontSize="md" fontWeight={500}>
                Account: {config.content.account_name}
              </Text>
            </Flex>
          ) : (
            <Flex
              flex={1}
              display="inline-flex"
              align="center"
              border="1px solid"
              borderRight="none"
              borderColor="gray.200"
              borderLeftRadius="md"
              px={4}
              gap={2}
              fontSize="md"
              fontWeight={500}
              color="gray.500"
            >
              No account selected
            </Flex>
          )}
          <Button
            isLoading={requestingAuth.inProgress}
            isDisabled={requestingAuth.inProgress}
            onClick={handleClickSelectAccount}
            borderLeftRadius="none"
            colorScheme="blue"
          >
            Select Docusign account...
          </Button>
        </Flex>
      </Box>
      <ConfigureDocusignModal isOpen={isOpen} onClose={onClose} auth={requestingAuth.lastResult} onForm={handleForm} />
    </>
  );
};
