import {
  Account,
  LinkedNotificationChannelMin,
  NotificationChannelConnectorType,
  NotificationChannelMin,
} from "../../../Types.ts";
import {useQueryData} from "../../../state";
import {Box, Flex, Icon, IconButton, Text} from "@chakra-ui/react";
import groupBy from "../../../utils/groupBy.tsx";
import {LinkSlackButton} from "./LinkSlack.tsx";
import {CheckCircleIcon, XMarkIcon} from "@heroicons/react/20/solid";
import {usePromiseState} from "../../../hooks/promiseState.ts";
import api from "../../../api";

const LinkButton = ({channel}: {channel: Channel}) => {
  const [unlinking, unlink] = usePromiseState(async () => {
    await api.notificationChannels.deleteLinked(channel.notification_channel_id);
  }, [channel]);
  if (channel.linked) {
    return (
      <Flex gap={1} alignItems="center">
        <Icon boxSize={5} as={CheckCircleIcon} color="green" />
        <Text>Connected</Text>
        <IconButton
          variant="ghost"
          colorScheme="red"
          aria-label="Unlink"
          icon={<Icon boxSize={5} as={XMarkIcon} />}
          onClick={unlink}
          isDisabled={unlinking.inProgress}
          isLoading={unlinking.inProgress}
        />
      </Flex>
    );
  } else {
    switch (channel.connector.connector_type) {
      case NotificationChannelConnectorType.Slack:
        return <LinkSlackButton channel={channel} />;
      default:
        return null;
    }
  }
};
const AccountGroup = ({account, channels}: {account: Account; channels: Channel[]}) => {
  return (
    <Flex fontSize="md" gap={2} flexDir="column">
      <Text fontWeight="700">{account.display_name}</Text>
      {channels.map(c => (
        <Flex key={c.notification_channel_id} flexDir="row" justifyContent="space-between" alignItems="center">
          <Text>{c.name}</Text>
          <LinkButton channel={c} />
        </Flex>
      ))}
    </Flex>
  );
};

export type Channel = NotificationChannelMin & {linked: boolean};
const LinkedChannels = ({
  linked,
  available,
}: {
  linked: LinkedNotificationChannelMin[];
  available: NotificationChannelMin[];
}) => {
  const all: Channel[] = [
    ...linked.map(l => ({...l.notification_channel, linked: true})),
    ...available.map(a => ({...a, linked: false})),
  ];
  const accounts = useQueryData({queryKey: ["accounts"]});
  const grouped = groupBy(all, channel => channel.account_id);

  return (
    <Box pt={4} borderTop="1px solid" borderColor="gray.100">
      <Text fontWeight={700} mb={4}>
        Connected apps
      </Text>
      {grouped.map(g => (
        <AccountGroup key={g.key} account={accounts.find(a => a.account_id === g.key)!} channels={g.items} />
      ))}
    </Box>
  );
};

export default LinkedChannels;
