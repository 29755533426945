import {
  Button,
  Checkbox,
  Flex,
  Heading,
  Highlight,
  Icon,
  LinkBox,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import {Link as RouterLink} from "react-router-dom";
import {
  AdHocQuestion,
  OwnerT,
  OwnerType,
  Question,
  QuestionId,
  QuestionStatus as QuestionStatusT,
  UserMin,
} from "../../../Types.ts";
import Page from "../../../components/Page.tsx";
import {ChevronRightIcon, PlusIcon} from "@heroicons/react/20/solid";
import {ActionBar, ActionBarSearch} from "../../../components/ActionBar.tsx";
import {forwardRef, Fragment, memo, useCallback, useEffect, useMemo, useState} from "react";
import {
  AdHocQuestionFilters,
  facetedSearchAdHocQuestion,
  getAdHocQuestionsRelevantAskers,
  getAdHocQuestionsRelevantUsers,
} from "../../components/Filters/index.tsx";
import {useFacetedSearch} from "../../../hooks/search.ts";
import {useFilterState} from "../../../hooks/filterState.ts";
import LinkOverlay from "../../../components/LinkOverlay.tsx";
import {useQueriesData} from "../../../state/index.ts";
import {router} from "../../../router/index.tsx";
import {withSuspense} from "../../../state/withSuspense.tsx";
import {STICKY_SECTION_HEADER_OFFSET} from "../../../utils/sticky.ts";
import {useWindowVirtualizer} from "@tanstack/react-virtual";
import {useEventCallback} from "usehooks-ts";
import QuestionNumber from "../Questionnaires/components/QuestionNumber.tsx";
import api from "../../../api/index.ts";
import QuestionStatus from "../components/QuestionStatus/Selector.tsx";
import DueDate from "../../../components/DueDate.tsx";
import {getResponseLayer, useLayerType} from "../../../hooks/layerType.ts";
import LayerSelector from "../components/LayerSelector/Selector.tsx";
import {DEFAULT_FILTERS} from "./index.tsx";
import FilterOwners from "../../components/Filters/FilterOwners.tsx";
import FilterStatuses from "../../components/Filters/FilterStatuses.tsx";
import FilterAskers from "../../components/Filters/FilterAskers.tsx";
import FilterBanner from "../../components/Filters/FilterBanner.tsx";
import {BulkActions} from "../Questionnaires/QuestionnaireViewer/Questions/BulkActions.tsx";
import Owner from "../../../components/Owner.tsx";

const AdHocQuestionRowActions = memo(({question, asker}: {question: Question; asker: UserMin | undefined}) => {
  const [layerType] = useLayerType();
  const {question_id, response_layers} = question;
  const {owner, status, due_date} = getResponseLayer(response_layers, layerType);
  const [askerOwner] = useQueriesData({
    queries: asker
      ? [
          {
            queryKey: ["resolvedOwner", {type: OwnerType.User, content: asker?.user_id}],
          },
        ]
      : [],
  });

  const onNudge = useCallback(async () => {
    await api.vendorToolkit.questions.nudge(question_id, layerType);
  }, [question_id, layerType]);

  const onReassign = useCallback(
    async (owner?: OwnerT) => {
      await api.vendorToolkit.questions.assign(question_id, layerType, owner?.owner_id ?? null);
    },
    [question_id, layerType],
  );

  const onSetStatus = useCallback(
    async (status: QuestionStatusT) => {
      await api.vendorToolkit.questions.updateStatus(question_id, layerType, status);
    },
    [question_id, layerType],
  );

  return (
    <>
      <Td>
        <Owner owner={owner} onReassign={onReassign} onNudge={onNudge} />
      </Td>
      <Td>
        <QuestionStatus status={status} onChangeStatus={onSetStatus} />
      </Td>
      <Td>
        <DueDate dueDate={due_date} complete={status === QuestionStatusT.Complete} />
      </Td>
      <Td>
        <Owner owner={askerOwner} />
      </Td>
      <Td>
        <Icon as={ChevronRightIcon} fontSize="24" color="gray.500" />
      </Td>
    </>
  );
});

const AdHocQuestionRow = memo(
  forwardRef<
    HTMLTableRowElement,
    {
      index: number;
      value: AdHocQuestion;
      queries: string[];
      isChecked: boolean;
      onCheck: (checkId: CheckId, checked: boolean) => void;
    }
  >(({index, value, queries, isChecked, onCheck}, ref) => {
    const {text, question_id} = value.question;

    const checkQuestion = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        onCheck({type: "question", questionId: question_id}, e.target.checked);
        e.stopPropagation();
      },
      [onCheck, question_id],
    );

    return (
      <LinkBox
        ref={ref}
        data-index={index}
        as={Tr}
        transform="scale(1)"
        _hover={{bg: "gray.50", cursor: "pointer"}}
        height={`${ESTIMATED_QUESTION_HEIGHT}px`}
      >
        <Td p="0" onClick={e => e.stopPropagation()}>
          <LinkOverlay as={RouterLink} to={`/vendor-toolkit/questions/${question_id}`} />
          <Checkbox px="8" py="5" onChange={checkQuestion} isChecked={isChecked} />
        </Td>
        <Td>
          <Text fontSize="md" fontWeight="600" overflowWrap="break-word" whiteSpace="pre-wrap">
            <QuestionNumber question={value.question} />
            <Highlight query={queries} styles={{bg: "yellow.200"}}>
              {text}
            </Highlight>
          </Text>
        </Td>
        <AdHocQuestionRowActions question={value.question} asker={value.asker} />
      </LinkBox>
    );
  }),
);

type CheckId =
  | {
      type: "all";
    }
  | {
      type: "question";
      questionId: QuestionId;
    };

const ESTIMATED_QUESTION_HEIGHT = 73;
const TBODY_POSITION_AT_SCROLL0 = 361;

const QuestionList = withSuspense(
  memo(() => {
    const [layerType] = useLayerType();
    const [whoami, adhocQuestions] = useQueriesData({
      queries: [{queryKey: ["whoAmI"]}, {queryKey: ["vendorToolkit", "adhocQuestions"]}],
    });
    const relevantUsers = getAdHocQuestionsRelevantUsers(adhocQuestions, whoami.user_owner!, layerType);
    const relevantAskers = getAdHocQuestionsRelevantAskers(adhocQuestions, whoami);

    const {filters, setFilter, clearFilters, filterCount} = useFilterState<AdHocQuestionFilters>(
      "adhocQuestionFilters",
      DEFAULT_FILTERS,
    );

    const {query, queries, setQuery, filter} = useFacetedSearch(
      q => q.question.text,
      facetedSearchAdHocQuestion(filters, layerType, whoami.associated_owners),
      [filters, whoami.associated_owners],
    );
    const {result, counts} = filter(adhocQuestions);

    // Manage state for question selection
    const [selectedIds, setSelectedIds] = useState(new Set<QuestionId>());
    const allQuestionIds = useMemo(() => result.map(q => q.question.question_id), [result]);
    useEffect(() => {
      // Sometimes selected questions will become filtered out - in that
      // case we want to remove them from the selection.
      const possibleIds = new Set(allQuestionIds);
      const filteredIds = [...selectedIds].filter(id => possibleIds.has(id));
      if (filteredIds.length !== selectedIds.size) {
        setSelectedIds(new Set(filteredIds));
      }
    }, [selectedIds, result, allQuestionIds]);

    // Callback for updating question selection
    const check = useEventCallback((checkId: CheckId, checked: boolean) => {
      let relevantQuestionsIds: Set<QuestionId>;
      switch (checkId.type) {
        case "all":
          relevantQuestionsIds = new Set(allQuestionIds);
          break;
        case "question":
          relevantQuestionsIds = new Set([checkId.questionId]);
          break;
      }
      setSelectedIds(
        oldIds =>
          new Set(
            checked ? [...oldIds, ...relevantQuestionsIds] : [...oldIds].filter(id => !relevantQuestionsIds.has(id)),
          ),
      );
    });

    // Callback for when the top-level checkbox was changed
    const checkAll = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        check({type: "all"}, e.target.checked);
        e.stopPropagation();
      },
      [check],
    );

    const getItemKey = useCallback(
      (idx: number) => {
        const item = result[idx];
        return item.question.question_id;
      },
      [result],
    );

    const virtualizer = useWindowVirtualizer({
      estimateSize: () => ESTIMATED_QUESTION_HEIGHT,
      count: result.length,
      overscan: 5,
      scrollMargin: TBODY_POSITION_AT_SCROLL0 - STICKY_SECTION_HEADER_OFFSET,
      getItemKey,
    });

    // When virtual scrolling we need to insert extra "blank" rows to take up the space of
    // non-rendered elements. This variable tracks where the last row ended so we know whether
    // we need to insert a blank row before the next row starts.
    let lastPosition = 0;
    return (
      <Page title="Questions">
        <Stack spacing={0}>
          <Flex p={8} justifyContent="space-between" align="center">
            <Heading size="md" p="0">
              Questions
            </Heading>
            <Flex ml="auto" gap="4">
              <LayerSelector />
            </Flex>
          </Flex>
          <ActionBar
            actionButtons={
              <>
                <FilterOwners
                  owners={filters.owners}
                  setOwners={newOwners => setFilter("owners", newOwners)}
                  relevantOwners={relevantUsers}
                  counts={counts.owner}
                />
                <FilterStatuses
                  counts={counts.status}
                  statuses={filters.statuses}
                  setStatuses={newStatuses => setFilter("statuses", newStatuses)}
                />
                <FilterAskers
                  askers={filters.askers}
                  setAskers={newAskers => setFilter("askers", newAskers)}
                  users={relevantAskers}
                  counts={counts.asker}
                />
                <Button
                  colorScheme="green"
                  leftIcon={<Icon as={PlusIcon} />}
                  onClick={() => router!.navigate("/questions/new")}
                >
                  Ask a new question
                </Button>
              </>
            }
          >
            <BulkActions selectedIds={selectedIds} />
            <ActionBarSearch value={query} onChange={e => setQuery(e.target.value)} />
          </ActionBar>
          <FilterBanner filterCount={filterCount} clearFilters={clearFilters} />
          <TableContainer maxWidth="100%" whiteSpace="normal" flex="1">
            <Table style={{borderCollapse: "separate", borderSpacing: "0"}} size="lg" layout="fixed">
              <Thead bg="white" borderBottom="10px solid" borderBottomColor="red.100">
                <Tr color="red">
                  <Th width="5%" color="gray.500" p="0">
                    <Checkbox
                      px="8"
                      py="5"
                      onChange={checkAll}
                      isChecked={selectedIds.size === allQuestionIds.length}
                      isIndeterminate={selectedIds.size > 0 && selectedIds.size < allQuestionIds.length}
                    />
                  </Th>
                  <Th width="25%" color="gray.500">
                    Question
                  </Th>
                  <Th width="15%" color="gray.500">
                    Assigned to
                  </Th>
                  <Th width="15%" color="gray.500">
                    Status
                  </Th>
                  <Th width="15%" color="gray.500">
                    Due
                  </Th>
                  <Th width="15%" color="gray.500">
                    Asker
                  </Th>
                  <Th />
                </Tr>
              </Thead>
              <Tbody height={`${virtualizer.getTotalSize()}px`}>
                {virtualizer.getVirtualItems().map(item => {
                  const adhocQuestion = result[item.index];
                  const position = item.start - virtualizer.options.scrollMargin;
                  const offset = position - lastPosition;
                  lastPosition = position + item.size;
                  return (
                    <Fragment key={item.key}>
                      {offset > 0 && (
                        <Tr height={`${offset}px`}>
                          <Td></Td>
                        </Tr>
                      )}
                      <AdHocQuestionRow
                        ref={virtualizer.measureElement}
                        index={item.index}
                        value={adhocQuestion}
                        queries={queries}
                        isChecked={selectedIds.has(adhocQuestion.question.question_id)}
                        onCheck={check}
                      />
                    </Fragment>
                  );
                })}
                <Tr>
                  <Td border="none"></Td>
                </Tr>
              </Tbody>
            </Table>
          </TableContainer>
        </Stack>
      </Page>
    );
  }),
);

export default QuestionList;
