import * as datefns from "date-fns";
import {ApiDate, ApiDateTime, nominate} from "../Types";

type CopyNullability<A, B> = null extends A ? B | null : B;
type CopyUndefinedness<A, B> = undefined extends A ? B | undefined : B;

export function parseISO<T extends ApiDateTime | null | undefined>(
  date: T,
): CopyNullability<T, CopyUndefinedness<T, Date>>;
export function parseISO(date: ApiDateTime | null | undefined): Date | null | undefined {
  if (date == null) {
    return date;
  } else {
    return datefns.parseISO(date);
  }
}

export function formatISO<T extends Date | null | undefined>(
  date: T,
): CopyNullability<T, CopyUndefinedness<T, ApiDateTime>>;
export function formatISO(date: Date | null | undefined): ApiDateTime | null | undefined {
  if (date == null) {
    return date;
  } else {
    return nominate("datetime", date.toISOString());
  }
}

/**
 * Parse an `ApiDate` into a JS `Date`.
 *
 * The date is interpreted as midnight in the local timezone.
 *
 * Use this for dates that are not considered to change with timezone.
 * For example, a birthday, or the date of incorporation of a company.
 */
export function parseNaiveDate<T extends ApiDate | null | undefined>(
  date: T,
): CopyNullability<T, CopyUndefinedness<T, Date>>;
export function parseNaiveDate(date: ApiDate | null | undefined): Date | null | undefined {
  if (date == null) {
    return date;
  }

  const [year, month, day] = date.split("-");
  const parsedDate = new Date(parseInt(year), parseInt(month) - 1, parseInt(day));

  if (Number.isNaN(parsedDate.valueOf())) {
    throw new Error("Failed to parse date");
  }

  return parsedDate;
}

/**
 * Convert a JS `Date` into an naive `ApiDate`.
 *
 * The time and timezone portion of the date are ignored.
 *
 * Use this for dates that are not considered to change with timezone.
 * For example, a birthday, or the date of incorporation of a company.
 */
export function formatNaiveDate<T extends Date | null | undefined>(
  date: T,
): CopyNullability<T, CopyUndefinedness<T, ApiDate>>;
export function formatNaiveDate(date: Date | null | undefined): ApiDate | null | undefined {
  if (date == null) {
    return date;
  }

  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");

  const formattedDate = `${year}-${month}-${day}`;
  return nominate("date", formattedDate);
}
