import {
  CrmConnector,
  CrmConnection,
  CreateCrmConnection,
  CrmConnectionMin,
  CrmAccountInfo,
  UpdateCrmConnection,
  CrmConnectionId,
  ExternalAuthorizationId,
} from "../../Types";
import jsonApi from "../jsonApi";
import {invalidateQueries} from "../../state";

export async function listConnectors(): Promise<CrmConnector[]> {
  return await jsonApi.get<CrmConnector[]>("/vendor_toolkit/crm_connectors");
}

export async function getConnector(connectorId: string): Promise<CrmConnector> {
  return await jsonApi.get<CrmConnector>(`/vendor_toolkit/crm_connectors/${connectorId}`);
}

export async function getAccountInfo(
  connectorId: string,
  externalAuthorizationId: ExternalAuthorizationId,
): Promise<CrmAccountInfo> {
  return await jsonApi.post<CrmAccountInfo>(
    `/vendor_toolkit/crm_connectors/${connectorId}/account_info`,
    externalAuthorizationId,
  );
}

export async function list(): Promise<CrmConnectionMin[]> {
  return await jsonApi.get<CrmConnection[]>("/vendor_toolkit/crm_connections");
}

export async function get(connectionId: CrmConnectionId): Promise<CrmConnection> {
  return await jsonApi.get<CrmConnection>(`/vendor_toolkit/crm_connections/${connectionId}`);
}

export async function create(connection: CreateCrmConnection): Promise<CrmConnection> {
  const res = await jsonApi.post<CrmConnection>(`/vendor_toolkit/crm_connections`, connection);
  await invalidateQueries([{queryKey: ["vendorToolkit", "crmConnections"]}]);
  return res;
}

export async function updateName(connectionId: CrmConnectionId, name: string) {
  const res = await jsonApi.post<"ok">(`/vendor_toolkit/crm_connections/${connectionId}/name`, name);
  await invalidateQueries([
    {queryKey: ["vendorToolkit", "crmConnection", connectionId]},
    {queryKey: ["vendorToolkit", "crmConnections"]},
  ]);
  return res;
}
export async function update(connectionId: CrmConnectionId, update: UpdateCrmConnection) {
  const res = await jsonApi.post<"ok">(`/vendor_toolkit/crm_connections/${connectionId}`, update);
  await invalidateQueries([
    {queryKey: ["vendorToolkit", "crmConnection", connectionId]},
    {queryKey: ["vendorToolkit", "crmConnections"]},
  ]);
  return res;
}

export async function sync(connectionId: CrmConnectionId) {
  const res = await jsonApi.post<"ok">(`/vendor_toolkit/crm_connections/${connectionId}/sync`);
  await invalidateQueries([
    {queryKey: ["vendorToolkit", "crmConnection", connectionId]},
    {queryKey: ["vendorToolkit", "crmConnections"]},
  ]);
  return res;
}
