import {Navigate, Route} from "react-router-dom";
import Questionnaires from "./Questionnaires";
import Questionnaire from "./Questionnaire";
import NewQuestionnaire from "./NewQuestionnaire";
import LoginError from "./LoginError";
import {AccountList} from "../User/AccountSelector";
import ExtensionPage from "./ExtensionPage";
import Crumb from "./Crumb";

export default (
  <Route>
    <Route path="extension">
      <Route path="login" element={<LoginError />} />
      <Route path="account-selector" element={<AccountList />} />
      <Route path="" element={<ExtensionPage />}>
        <Route path="questionnaires" element={<Questionnaires />}>
          <Route handle={{crumb: "New"}} path="new" element={<NewQuestionnaire />} />
          <Route path=":questionnaireId" id="ExtensionQuestionnaire" handle={{Crumb}}>
            <Route path="questions" element={<Questionnaire index={0} />} handle={{crumb: "Questions"}} />
            <Route path="import" element={<Questionnaire index={1} />} handle={{crumb: "Import"}} />
            <Route index element={<Navigate to="questions" replace />} handle={{crumb: "Questions"}} />
          </Route>
        </Route>
        <Route index element={<Navigate to="questionnaires" replace />} />
      </Route>
    </Route>
    <Route index element={<Navigate to="extension" replace />} />
  </Route>
);
