import {useLayoutEffect} from "react";

export function useSideBarHeight(ref: React.RefObject<HTMLDivElement>) {
  // Ensure the side bar fills the height of the screen, taking into account the
  // sticky header. Bit jank, but I'm not sure there's a better way.
  useLayoutEffect(() => {
    function listener() {
      const el = ref.current!;

      const bcr = el.getBoundingClientRect();
      const parentBcr = el.parentElement!.getBoundingClientRect();

      const distToBottomOfScreen = window.innerHeight - bcr.top;
      const distToBottomOfParent = parentBcr.bottom - bcr.top;

      const height = Math.min(distToBottomOfScreen, distToBottomOfParent);

      el.style.height = `${height}px`;
    }

    window.addEventListener("scroll", listener);
    window.addEventListener("resize", listener);

    listener();

    return () => {
      window.removeEventListener("scroll", listener);
      window.removeEventListener("resize", listener);
    };
  }, [ref]);
}
