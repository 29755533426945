import {useQueryData} from "../../../state";
import {Type} from "../../../typing";
import {withTypedProps} from "../../../typing/typedProps";

export const QuestionParams = Type.Object({questionId: Type.QuestionId()});

const Crumb = withTypedProps(QuestionParams, ({questionId}) => {
  return useQueryData({queryKey: ["vendorToolkit", "adhocQuestion", questionId]}).question.text;
});

export default Crumb;
