import {Text, Icon, Progress, Tooltip} from "@chakra-ui/react";
import {InformationCircleIcon} from "@heroicons/react/24/outline";
import {useEffect, useState} from "react";
import {ZXCVBNResult} from "zxcvbn";
import {handleImportError} from "../utils/import";

const ComplexityGauge = ({value}: {value: string}) => {
  const [zxcvbn, setZxcvbn] = useState<((password: string, userInputs?: string[]) => ZXCVBNResult) | null>(null);
  useEffect(() => {
    const importer = async () => {
      const zxcvbn = (await import("zxcvbn").catch(handleImportError)).default;
      // Function that returns zxcvbn, since useState treats functions as a generator
      setZxcvbn(() => zxcvbn);
    };
    importer();
  });

  if (zxcvbn == null) {
    return null;
  }

  const complexity_result = zxcvbn(value);
  let color;
  switch (complexity_result.score) {
    case 1:
      color = "red";
      break;
    case 2:
      color = "yellow";
      break;
    case 3:
    case 4:
      color = "green";
      break;
  }

  return (
    <>
      <Progress min={0} max={4} colorScheme={color} value={complexity_result.score} />
      {value !== "" && complexity_result.score < 3 ? (
        <Text fontSize="sm" color="red.500">
          Password is weak{" "}
          <Tooltip label={complexity_result.feedback.suggestions[0]}>
            <Icon as={InformationCircleIcon} />
          </Tooltip>
        </Text>
      ) : null}
    </>
  );
};

export default ComplexityGauge;
