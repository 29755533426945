import {DocumentArrowUpIcon} from "@heroicons/react/20/solid";
import {RouteTab} from "../../../../../components/RouterTabs";
import FilePage from "./FilePage";

export default {
  title: "File",
  path: "file",
  icon: DocumentArrowUpIcon,
  element: <FilePage />,
} satisfies RouteTab;
