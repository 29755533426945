import {Editable, EditableInput, EditablePreview, EditableProps, HStack, Icon, Spinner} from "@chakra-ui/react";
import {Question} from "../../../../Types.ts";
import api from "../../../../api/index.ts";
import {usePromiseState} from "../../../../hooks/promiseState.ts";
import {useEffect, useState} from "react";

const QuestionNumber = ({question, ...props}: {question: Question} & EditableProps) => {
  const [number, setNumber] = useState(question.question_number);

  useEffect(() => {
    setNumber(question.question_number);
  }, [question.question_number]);

  const [editingNumber, editNumber] = usePromiseState(
    async (newNumber: string) => {
      if (newNumber !== question.question_number) {
        await api.vendorToolkit.questions.updateNumber(question.question_id, newNumber);
      }
    },
    [question],
  );

  // Use the query state view of `question_number` here rather than `number` so the element
  // doesn't disappear if you remove all the text while editing
  return question.question_number ? (
    <Editable
      as="span"
      display="inline-block"
      color="gray.500"
      fontSize="sm"
      mr={2}
      {...props}
      value={number}
      onChange={e => setNumber(e)}
      onSubmit={e => editNumber(e)}
      isDisabled={props.isDisabled ?? true}
    >
      <HStack as="span">
        <EditablePreview />
        {editingNumber.inProgress && <Icon as={Spinner} color="gray.300" />}
      </HStack>
      <EditableInput py={1} mb="-1px" maxW={12} />
    </Editable>
  ) : null;
};

export default QuestionNumber;
