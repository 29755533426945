import {Badge, HStack} from "@chakra-ui/react";
import {ScopeMin} from "../../../Types";

export function ScopeBadge({scope}: {scope?: ScopeMin}) {
  return scope ? (
    <Badge as="span" width="min-content" bg={scope.axis.color} color="white" overflow="hidden" textOverflow="ellipsis">
      {scope.axis.name}: {scope.name}
    </Badge>
  ) : (
    <Badge as="span" width="min-content">
      Global
    </Badge>
  );
}

export function ScopeBadges({scopes, showGlobal = true}: {scopes?: ScopeMin[]; showGlobal?: boolean}) {
  return scopes == null || scopes.length === 0 ? (
    showGlobal ? (
      <ScopeBadge />
    ) : null
  ) : (
    <HStack>
      {scopes.map(scope => (
        <ScopeBadge key={scope.scope_id} scope={scope} />
      ))}
    </HStack>
  );
}
