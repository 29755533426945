import {Box, HStack, Img, Text} from "@chakra-ui/react";
import PlatformedIcon from "../../../assets/logo/logo.svg";
import OneDriveIcon from "../../../assets/new_features/onedrive.svg";
import GDriveIcon from "../../../assets/new_features/gdrive.png";
import SharepointIcon from "../../../assets/new_features/sharepoint.svg";
import ConfluenceIcon from "../../../assets/new_features/confluence.png";
import {Feature} from ".";

export default {
  heading: "Sync documents to Platformed automatically",
  text: (
    <>
      <Text>Sync documents from Sharepoint, Google Drive and Confluence into your Platformed library.</Text>
      <Text>
        Automatically updates from your source documentation so that Platformed responses are always up to date.
      </Text>
    </>
  ),
  image: (
    <HStack
      bg="orange.200"
      bgGradient="linear(165deg, cyan.200, blue.200, blue.300)"
      alignItems="center"
      justifyContent="center"
      py={12}
      gap={4}
      position="relative"
    >
      <Box bg="white" p={1} shadow="sm" position="absolute" top="15%" left="25%" rounded="full">
        <Img src={SharepointIcon} h={7} />
      </Box>
      <Box bg="white" p={1} shadow="sm" position="absolute" bottom="15%" right="25%" rounded="full">
        <Img src={GDriveIcon} h={7} />
      </Box>
      <Box bg="white" p={1} shadow="sm" position="absolute" top="15%" right="25%" rounded="full">
        <Img src={ConfluenceIcon} h={7} />
      </Box>
      <Box bg="white" p={1} shadow="sm" position="absolute" bottom="15%" left="25%" rounded="full">
        <Img src={OneDriveIcon} h={7} maxW={7} />
      </Box>
      <Box bg="white" rounded="full" p={4} shadow="sm">
        <Img src={PlatformedIcon} h={16} />
      </Box>
    </HStack>
  ),
  publishDate: new Date(Date.parse("01 Jan 2024")),
  actionText: "Setup document sync",
  url: "/vendor-toolkit/configuration/integrations/document-sources",
} satisfies Feature;
