import {Box} from "@chakra-ui/react";
import {Children, ReactNode} from "react";

const ResponsePreview = ({children}: {children: ReactNode}) => {
  return (
    <Box display="flex" gap={4} m={2}>
      {Children.map(children, child => (
        <Box px={8} py={4} bg="white" color="chakra-body-text" border="1px solid" borderColor="blue.200">
          {child}
        </Box>
      ))}
    </Box>
  );
};
export default ResponsePreview;
