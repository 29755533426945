import {
  Accordion,
  AccordionItem,
  AccordionButton,
  Stack,
  HStack,
  Icon,
  chakra,
  AccordionIcon,
  AccordionPanel,
  Text,
  IconButton,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import {ChatBubbleLeftRightIcon, CheckIcon, InformationCircleIcon} from "@heroicons/react/20/solid";
import {Question, RelevantCoreResponse} from "../../../../../../../Types";
import {useState} from "react";
import ApplyCoreResponseModal from "../../ApplyCoreResponseModal";
import ShowMoreLessToggle from "../../../../../../../components/ShowMoreLessToggle";
import CosineDistanceBadge from "../../../../../../../components/CosineDistanceBadge";
import {ScopeBadges} from "../../../../../components/ScopeBadge";

function Item({question, relevantCoreResponse}: {question: Question; relevantCoreResponse: RelevantCoreResponse}) {
  const coreResponse = relevantCoreResponse.core_response;

  const confirmationModal = useDisclosure();
  const [expanded, setExpanded] = useState(false);

  return (
    <>
      <HStack
        p="4"
        px="6"
        _notLast={{
          borderBottom: "1px solid",
          borderColor: "gray.300",
        }}
        spacing="8"
      >
        <Stack spacing="2" fontSize="md" flexGrow="1">
          <Text fontWeight="500" noOfLines={expanded ? 0 : 2}>
            {coreResponse.question_text}
          </Text>
          <ScopeBadges scopes={coreResponse.limited_to_scopes} showGlobal={false} />
          <Text noOfLines={expanded ? 0 : 2} whiteSpace="pre-wrap">
            {coreResponse.response_text}
          </Text>
          <HStack spacing={4}>
            <CosineDistanceBadge
              distance={relevantCoreResponse.question_cosine_distance}
              closeThreshold={0.11}
              nearThreshold={0.15}
            />
            <ShowMoreLessToggle expanded={expanded} onChange={setExpanded} />
          </HStack>
        </Stack>
        <Tooltip label="Apply this core response">
          <IconButton
            icon={<Icon fontSize="md" as={CheckIcon} />}
            aria-label="Apply"
            colorScheme="blue"
            size="sm"
            onClick={confirmationModal.onOpen}
            alignSelf="flex-start"
            mt="2"
          />
        </Tooltip>
      </HStack>
      {confirmationModal.isOpen && (
        <ApplyCoreResponseModal {...confirmationModal} question={question} coreResponse={coreResponse} />
      )}
    </>
  );
}

/**
 * Shows a list of relevant core responses for a question and allows the user to
 * apply them.
 */
function CoreResponseSelector({question}: {question: Question}) {
  const {relevant_core_responses} = question;

  if (relevant_core_responses.length === 0) {
    return null;
  }

  const count = relevant_core_responses.length;

  return (
    <Accordion allowToggle bg="blue.50" rounded="md" border="1px solid" borderColor="blue.200">
      <AccordionItem border="none" padding="0">
        <AccordionButton p="4" px="6" _expanded={{borderBottom: "1px solid", borderColor: "gray.300"}} gap="6">
          <Stack flexGrow={1} alignItems="flex-start">
            <HStack spacing="6">
              <Icon as={ChatBubbleLeftRightIcon} color="blue.500" fontSize="lg" />
              <Text fontSize="md" fontWeight="500" color="gray.600" textAlign="left">
                There {count === 1 ? "is" : "are"} <chakra.strong>{count}</chakra.strong> core response
                {count === 1 ? "" : "s"} that may answer this question.{" "}
              </Text>
            </HStack>
          </Stack>
          <HStack>
            <Text color="gray.500">Expand to view</Text>
            <AccordionIcon />
          </HStack>
        </AccordionButton>
        <AccordionPanel p="0">
          <HStack
            p="4"
            px="6"
            spacing="6"
            borderBottom="1px solid"
            borderColor="gray.300"
            bg="gray.50"
            color="gray.500"
          >
            <Icon as={InformationCircleIcon} fontSize="xl" />
            <Text fontSize="md" fontWeight="400">
              If you would like to use a core response for this question, click the check mark. This will replace the
              response text with the core response.
            </Text>
          </HStack>
          {relevant_core_responses.map(c => (
            <Item key={c.core_response.core_response_id} question={question} relevantCoreResponse={c} />
          ))}
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
}

export default CoreResponseSelector;
