import {HStack, Img, Link, Text} from "@chakra-ui/react";
import ScopeTypeScreenshot from "../../../assets/new_features/scope_types.png";
import {Feature} from ".";
import {AccountFeature} from "../../Types.ts";

export default {
  heading: "Introducing Scope Types",
  text: (
    <>
      <Text>
        It is now possible to configure your own scopes in Platformed. Ask us for this feature and find it under
        Configuration.
      </Text>
      <Link
        href="https://platformed.com/blog/enterprise-organizations-with-multiple-products-can-now-sort-data-into-custom-scopes-with-platformed-respond"
        target="_blank"
        color="blue.500"
        fontWeight="semibold"
      >
        Find out more in our blog post.
      </Link>
    </>
  ),
  image: (
    <HStack
      bg="orange.200"
      bgGradient="linear(165deg, pink.200, purple.200, purple.300)"
      alignItems="center"
      justifyContent="center"
      p={4}
    >
      <Img src={ScopeTypeScreenshot} h={64} rounded="md" />
    </HStack>
  ),
  publishDate: new Date(Date.parse("14 Aug 2024")),
  actionText: "Go to scopes",
  actionRequiredAccountFeatures: [AccountFeature.Scopes],
  url: "/vendor-toolkit/configuration/scopes",
} satisfies Feature;
