import {RouteTab, tabbedRoute} from "../../components/RouterTabs/index.tsx";
import Tabs from "./Tabs.tsx";
import Profile from "./Profile/index.tsx";
import Users from "./Users/index.tsx";
import Roles from "./Roles/index.tsx";
import SelfEnrollment from "./SelfEnrollment/index.tsx";
import Teams from "./Teams/index.tsx";
import Features from "./Features/index.tsx";

export const tabs: RouteTab[] = [Profile, Features, Users, Roles, SelfEnrollment, Teams];

const routes = tabbedRoute({
  path: "account-settings",
  title: "Account settings",
  tabs,
  element: <Tabs />,
});

export default routes;
