import {useEffect} from "react";

export function useFavicon(url: string | undefined) {
  useEffect(() => {
    if (url) {
      const link = document.head.querySelector<HTMLLinkElement>("link[rel='icon']")!;
      const originalUrl = link.href;
      link.href = url;
      return () => {
        link.href = originalUrl;
      };
    }
  }, [url]);
}
