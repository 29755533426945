import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Checkbox,
  Highlight,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useDisclosure,
} from "@chakra-ui/react";
import {usePromiseState} from "../../../../hooks/promiseState.ts";
import api from "../../../../api/index.ts";
import {useEffect, useMemo, useState} from "react";
import Loading from "../../../../components/Loading.tsx";
import SharingClassification from "../../components/SharingClassification/Selector.tsx";
import {parseISO} from "../../../../utils/date.ts";
import * as datefns from "date-fns";
import {PlusIcon} from "@heroicons/react/24/outline";
import {
  CreateDocumentationAttachment,
  Documentation as DocumentationT,
  DocumentCategory,
  DocumentMin,
  LayerType,
  QuestionId,
  QuestionnaireId,
} from "../../../../Types.ts";
import {withErrorBoundary} from "react-error-boundary";
import {withSuspense} from "../../../../state/withSuspense.tsx";
import {useQueryData} from "../../../../state/index.ts";
import {useAbortableState, useAsyncOperations} from "../../../../hooks/asyncOperation.ts";
import {StandaloneFileUpload} from "../../../../hooks/fileUpload.ts";
import {MultiFileUpload} from "../../../../components/fileUploads/MultiFileUpload.tsx";
import {useLayerType} from "../../../../hooks/layerType.ts";
import {MagnifyingGlassIcon} from "@heroicons/react/20/solid";
import useSearch from "../../../../hooks/search.ts";

const Documents = withErrorBoundary(
  withSuspense(
    ({
      category,
      newAttachments,
      setNewAttachments,
      attachedDocuments,
    }: {
      category: DocumentCategory;
      newAttachments: CreateDocumentationAttachment[];
      setNewAttachments: (attachments: CreateDocumentationAttachment[]) => void;
      attachedDocuments: string[];
    }) => {
      const documents = useQueryData({queryKey: ["vendorToolkit", "documents"]});
      const filteredAndSortedDocuments = useMemo(
        () =>
          documents
            .filter(doc => doc.category === category)
            .sort((p, q) => (p.name === q.name ? 0 : p.name > q.name ? 1 : -1)),
        [documents, category],
      );
      const {query, setQuery, search} = useSearch((d: DocumentMin) => d.name);
      const searchFilteredDocs = filteredAndSortedDocuments.filter(search);

      return (
        <Stack spacing={8}>
          <InputGroup flex="1">
            <InputLeftElement>
              <Icon as={MagnifyingGlassIcon} />
            </InputLeftElement>
            <Input
              name="search"
              type="search"
              placeholder="Search"
              value={query}
              onChange={e => setQuery(e.target.value)}
            />
          </InputGroup>
          {searchFilteredDocs.map(p => (
            <HStack key={p.document_id} spacing={8} fontSize="md">
              <Checkbox
                fontWeight="600"
                color="gray.700"
                flex="1"
                overflow="hidden"
                whiteSpace="nowrap"
                textOverflow="ellipsis"
                isChecked={
                  !!newAttachments.find(a => a.type === "Document" && a.content.document_id === p.document_id) ||
                  attachedDocuments.includes(p.document_id)
                }
                isDisabled={attachedDocuments.includes(p.document_id)}
                onChange={e => {
                  if (e.target.checked) {
                    setNewAttachments([...newAttachments, {type: "Document", content: {document_id: p.document_id}}]);
                  } else {
                    setNewAttachments(
                      newAttachments.filter(a => a.type !== "Document" || a.content.document_id !== p.document_id),
                    );
                  }
                }}
                sx={{"& > span": {overflow: "hidden", textOverflow: "ellipsis"}}}
              >
                <Highlight query={query} styles={{bg: "yellow.200"}}>
                  {p.name}
                </Highlight>
              </Checkbox>
              <Box width="15%">
                <SharingClassification sharing={p.sharing_classification} />
              </Box>
              <Box width="15%">{datefns.format(parseISO(p.last_update), "PP")}</Box>
            </HStack>
          ))}
        </Stack>
      );
    },
    <Loading mt={8} />,
  ),
  {
    fallbackRender: ({error}) => (
      <Alert status="error" mt="12">
        <AlertIcon boxSize="40px" />
        <Box>
          <AlertTitle fontSize="md">Error</AlertTitle>
          <AlertDescription fontSize="md">{error?.toString()}</AlertDescription>
        </Box>
      </Alert>
    ),
  },
);

type Blocker = "uploading";

const Files = ({
  setNewAttachments,
  setBlockers,
}: {
  setNewAttachments: (
    attachmentsFn: (attachments: CreateDocumentationAttachment[]) => CreateDocumentationAttachment[],
  ) => void;
  setBlockers: (f: (blockers: Blocker[]) => Blocker[]) => void;
}) => {
  // Manage the set of in-flight file uploads
  const [uploadingFiles, setUploadingFiles] = useAbortableState<StandaloneFileUpload[]>([]);
  const uploadStates = useAsyncOperations(uploadingFiles);
  const uploadedFiles = useMemo(() => {
    const res = [];
    for (const uploadState of uploadStates) {
      if (uploadState.id === "Uploaded") {
        res.push({
          type: "StandaloneFile" as const,
          content: {standalone_file_id: uploadState.result.standalone_file_id},
        });
      }
    }
    return res;
  }, [uploadStates]);

  useEffect(() => {
    setNewAttachments(newAttachments => [...newAttachments.filter(a => a.type !== "StandaloneFile"), ...uploadedFiles]);
  }, [uploadedFiles, setNewAttachments]);

  // Make sure "attach" button is disabled if there are in-flight uploads
  const isUploading = !uploadStates.every(uploadState => uploadState.id === "Uploaded");
  useEffect(() => {
    setBlockers(blockers => (isUploading ? [...blockers, "uploading"] : blockers.filter(b => b !== "uploading")));
  }, [isUploading, setBlockers]);

  return <MultiFileUpload Cls={StandaloneFileUpload} value={uploadingFiles} onChange={setUploadingFiles} />;
};

export type DocumentationTarget =
  | {
      type: "Question";
      questionId: QuestionId;
    }
  | {
      type: "Questionnaire";
      questionnaireId: QuestionnaireId;
    };

type AttachSupportingDocumentationModalProps = {
  isOpen: boolean;
  onClose: () => void;
  target: DocumentationTarget;
  attachedDocs: DocumentationT[];
};

export const AttachSupportingDocumentationModal = ({
  isOpen,
  onClose,
  target,
  attachedDocs,
}: AttachSupportingDocumentationModalProps) => {
  const [newAttachments, setNewAttachments] = useState<CreateDocumentationAttachment[]>([]);
  const [blockers, setBlockers] = useState<Blocker[]>([]);
  const [layerType] = useLayerType();

  const attachedDocumentIds = attachedDocs.map(d =>
    d.attachment.type === "Document" ? d.attachment.content.document_id : d.attachment.content.file.file_id,
  );

  const [attaching, attach] = usePromiseState(async () => {
    if (target.type === "Question") {
      await api.vendorToolkit.questions.createDocumentation(
        target.questionId,
        layerType,
        newAttachments.map(attachment => ({
          attachment,
        })),
      );
    } else {
      await api.vendorToolkit.questionnaires.createDocumentation(
        target.questionnaireId,
        newAttachments.map(attachment => ({
          attachment,
          linked_to_questionnaire: true,
          linked_question_ids: [],
        })),
      );
    }
    onClose();
  }, [newAttachments, onClose, target, layerType]);

  // Make sure attachment gets cleared if the modal is closed
  useEffect(() => {
    if (!isOpen) {
      setNewAttachments([]);
    }
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent minW="500px" w="60%" maxW="800px" maxH="80%" overflow="hidden">
        <ModalHeader>Attach supporting documentation</ModalHeader>
        <ModalCloseButton />
        <ModalBody overflow="auto">
          <Tabs>
            <TabList>
              <Tab>Policies</Tab>
              <Tab>Certifications</Tab>
              <Tab>Other documents</Tab>
              <Tab>Upload file</Tab>
            </TabList>
            <TabPanels>
              <TabPanel pt={6}>
                <Documents
                  category={DocumentCategory.Policy}
                  newAttachments={newAttachments}
                  setNewAttachments={setNewAttachments}
                  attachedDocuments={attachedDocumentIds}
                />
              </TabPanel>
              <TabPanel pt={6}>
                <Documents
                  category={DocumentCategory.Certification}
                  newAttachments={newAttachments}
                  setNewAttachments={setNewAttachments}
                  attachedDocuments={attachedDocumentIds}
                />
              </TabPanel>
              <TabPanel pt={6}>
                <Documents
                  category={DocumentCategory.Other}
                  newAttachments={newAttachments}
                  setNewAttachments={setNewAttachments}
                  attachedDocuments={attachedDocumentIds}
                />
              </TabPanel>
              <TabPanel px={0}>
                <Files setNewAttachments={setNewAttachments} setBlockers={setBlockers} />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </ModalBody>

        <ModalFooter>
          <HStack spacing="3">
            <Button variant="ghost" onClick={onClose}>
              Close
            </Button>
            <Button
              colorScheme="green"
              onClick={attach}
              isLoading={attaching.inProgress}
              isDisabled={newAttachments.length === 0 || blockers.length > 0}
            >
              Attach
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const AttachSupportingDocumentation = ({
  target,
  isDisabled,
  attachedDocs,
}: {
  target: DocumentationTarget;
  isDisabled?: boolean;
  attachedDocs: DocumentationT[];
}) => {
  const {isOpen, onOpen, onClose} = useDisclosure();
  const [layerType] = useLayerType();
  return (
    <>
      <Button
        colorScheme="gray"
        onClick={onOpen}
        leftIcon={<Icon as={PlusIcon} />}
        isDisabled={isDisabled || layerType !== LayerType.External}
      >
        Add documentation
      </Button>
      <AttachSupportingDocumentationModal
        isOpen={isOpen}
        onClose={onClose}
        target={target}
        attachedDocs={attachedDocs}
      />
    </>
  );
};

export default AttachSupportingDocumentation;
