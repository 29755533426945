import {
  Box,
  ButtonGroup,
  Icon,
  IconButton,
  Link,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import api from "../../../api/index.ts";
import {usePromiseState} from "../../../hooks/promiseState.ts";
import {ImportStatus} from "./OriginalFiles/ImportStatus.tsx";
import {OriginalSource, QuestionnaireId} from "../../../Types.ts";
import {formatISO} from "../../../utils/date.ts";
import {CheckIcon} from "@heroicons/react/24/solid";
import {Internal} from "../../../components/InternalMode.tsx";
import {DeletedItem, DeleteModalIconButton} from "../../../components/DeleteModalButton.tsx";

export const DeleteOriginalSourceModal = ({
  sourceUrl,
  questionnaireId,
}: {
  sourceUrl: string;
  questionnaireId: QuestionnaireId;
}) => {
  const {isOpen, onOpen, onClose} = useDisclosure();
  const [deleting, delete_] = usePromiseState(async () => {
    await api.vendorToolkit.questionnaires.deleteOriginalSource(questionnaireId, sourceUrl);
    onClose();
  }, [onClose, questionnaireId, sourceUrl]);
  return (
    <DeleteModalIconButton
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      delete_={delete_}
      deleting={deleting.inProgress}
      modalHeader="Delete original source"
      modalBody={
        <>
          <Text>Are you sure you want to delete this original source? This action cannot be undone.</Text>
          <DeletedItem>{sourceUrl}</DeletedItem>
        </>
      }
    />
  );
};

const ToggleImportedButton = ({
  source,
  questionnaireId,
}: {
  source: OriginalSource;
  questionnaireId: QuestionnaireId;
}) => {
  const [toggling, toggle] = usePromiseState(async () => {
    const value = source.imported_at ? null : formatISO(new Date());
    await api.vendorToolkit.questionnaires.updateOriginalSourceImportedAt(questionnaireId, source.url, value);
  }, [source, questionnaireId]);

  const color = source.imported_at ? "green.400" : "white";

  return (
    <IconButton
      aria-label="Imported"
      bgColor={color}
      variant="outline"
      icon={<Icon as={CheckIcon} />}
      isDisabled={toggling.inProgress}
      isLoading={toggling.inProgress}
      onClick={toggle}
    />
  );
};

const OriginalSourceRow = ({source, questionnaireId}: {source: OriginalSource; questionnaireId: QuestionnaireId}) => {
  return (
    <>
      <Tr>
        <Td overflow="hidden" text-overflow="ellipsis" fontWeight="600">
          <Box maxWidth="600px" overflow="hidden" text-overflow="ellipsis">
            <Link href={source.url} target="_blank" color="blue.500">
              {source.url}
            </Link>
          </Box>
        </Td>
        <Td>
          <ImportStatus importedAt={source.imported_at} />
        </Td>
        <Td textAlign="center">
          <ButtonGroup bgColor="white" isAttached variant="outline">
            <Internal>
              <ToggleImportedButton source={source} questionnaireId={questionnaireId} />
            </Internal>
            <DeleteOriginalSourceModal sourceUrl={source.url} questionnaireId={questionnaireId} />
          </ButtonGroup>
        </Td>
      </Tr>
    </>
  );
};

const OriginalSources = ({sources, questionnaireId}: {sources: OriginalSource[]; questionnaireId: QuestionnaireId}) => {
  return (
    <>
      <Stack mb="3" alignItems="flex-start">
        {sources.length && (
          <TableContainer bgColor="gray.50" borderColor="gray.200" borderWidth="1px" borderRadius="md" maxWidth="960px">
            <Table layout="auto" style={{borderCollapse: "separate", borderSpacing: "0"}} size="lg">
              <Thead bgColor="white">
                <Tr>
                  <Th color="gray.500">Url</Th>
                  <Th color="gray.500" textAlign="center">
                    Status
                  </Th>
                  <Th color="gray.500" textAlign="center">
                    Actions
                  </Th>
                </Tr>
              </Thead>
              <Tbody fontSize="md">
                {sources.map(source => (
                  <OriginalSourceRow key={source.url} source={source} questionnaireId={questionnaireId} />
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        )}
      </Stack>
    </>
  );
};

export default OriginalSources;
