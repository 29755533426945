import {Button, HStack, Heading, Stack, useDisclosure} from "@chakra-ui/react";
import {DocumentSource, UpdateDocumentSource} from "../../../../../Types.ts";
import api from "../../../../../api/index.ts";
import {useCallback} from "react";
import {useQueryData} from "../../../../../state/index.ts";
import {DeleteDocumentSourceModal} from "./DeleteDocumentSourceModal.tsx";
import {DocumentSourceEditor} from "./DocumentSourceEditor.tsx";
import DocumentTitle from "../../../../../components/DocumentTitle.tsx";
import {DocumentSourceParams} from "./Crumb";
import {useTypedParams} from "../../../../../hooks/typedParams";

export const ConfigureDocumentSource = () => {
  const {documentSourceId} = useTypedParams(DocumentSourceParams);
  const source = useQueryData({queryKey: ["vendorToolkit", "documentSource", documentSourceId]}) as DocumentSource;
  const save = useCallback(
    async (update: UpdateDocumentSource) => {
      await api.vendorToolkit.documentSources.update(source.document_source_id, update);
    },
    [source.document_source_id],
  );
  const {onClose: onDeleteClose, onOpen: onDeleteOpen, isOpen: isDeleteOpen} = useDisclosure();

  return (
    <>
      <DocumentTitle title={"Configure document source"} />
      <Stack p={8} spacing={8} align="flex-start" w="600px">
        <HStack w="full">
          <Heading size="md" p="0" display="flex" alignItems="center" gap={2}>
            Configure document source - {source.name}
          </Heading>
          <Button colorScheme="red" type="button" ml="auto" onClick={onDeleteOpen}>
            Delete
          </Button>
        </HStack>
        <DeleteDocumentSourceModal isOpen={isDeleteOpen} onClose={onDeleteClose} documentSource={source} />
        <DocumentSourceEditor initialValue={source} onSave={save} />
      </Stack>
    </>
  );
};
