import {HStack, Img, Link, Text} from "@chakra-ui/react";
import TeamsScreenshot from "../../../assets/new_features/teams.png";
import {Feature} from ".";

export default {
  heading: "Introducing user teams",
  text: (
    <>
      <Text>We’ve introduced teams to Platformed. You can now assign or tag teams just as you can with users.</Text>
      <Link
        href="https://platformed.com/blog/platformed-introduces-teams-to-simplify-collaboration"
        target="_blank"
        color="blue.500"
        fontWeight="semibold"
      >
        Find out more in our blog post.
      </Link>
    </>
  ),
  image: (
    <HStack
      bg="orange.200"
      bgGradient="linear(165deg, cyan.200, green.200, green.300)"
      alignItems="center"
      justifyContent="center"
      p={4}
    >
      <Img src={TeamsScreenshot} rounded="md" />
    </HStack>
  ),
  publishDate: new Date(Date.parse("3 Jul 2024")),
  actionText: "Go to teams",
  url: "/vendor-toolkit/configuration/user-management/teams",
} satisfies Feature;
