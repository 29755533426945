import {Flex, Heading, Highlight, Icon, LinkBox, Stack, Table, Tbody, Td, Text, Th, Thead, Tr} from "@chakra-ui/react";
import {Link as RouterLink} from "react-router-dom";
import Page from "../../../components/Page.tsx";
import {ChevronRightIcon} from "@heroicons/react/20/solid";
import DueDate from "../../../components/DueDate.tsx";
import {ActionBar, ActionBarSearch} from "../../../components/ActionBar.tsx";
import {Question, QuestionnaireMin, QuestionStatus as QuestionStatusT, Task} from "../../../Types.ts";
import QuestionStatus from "../components/QuestionStatus/Selector.tsx";
import Counterparty from "../components/Counterparty.tsx";
import {
  facetedSearchTasks,
  getQuestionnaireRelevantCounterparties,
  QuestionFilters,
  TaskFilters,
} from "../../components/Filters/index.tsx";
import {useFacetedSearch} from "../../../hooks/search.ts";
import {encodeFiltersParam, useFilterState} from "../../../hooks/filterState.ts";
import GettingStarted from "./GettingStarted.tsx";
import LinkOverlay from "../../../components/LinkOverlay.tsx";
import {withSuspense} from "../../../state/withSuspense.tsx";
import {useQueriesData, useQueryData} from "../../../state/index.ts";
import QuestionNumber from "../Questionnaires/components/QuestionNumber.tsx";
import {getResponseLayer, useLayerType} from "../../../hooks/layerType.ts";
import {useMemo} from "react";
import FilterCounterparties from "../../components/Filters/FilterCounterparties.tsx";
import FilterStatuses from "../../components/Filters/FilterStatuses.tsx";
import FilterBanner from "../../components/Filters/FilterBanner.tsx";

const QuestionTaskRow = ({
  question,
  questionnaire,
  queries,
  filters,
}: {
  question: Question;
  questionnaire: QuestionnaireMin;
  queries: string[];
  filters: TaskFilters;
}) => {
  const [layerType] = useLayerType();
  const responseLayer = getResponseLayer(question.response_layers, layerType);

  // Create a `QuestionFilters` object from the current user and their selected status filters, and pass that
  // to the manage pane when they click through.
  const whoami = useQueryData({queryKey: ["whoAmI"]});
  const filtersParam = useMemo(() => {
    return encodeFiltersParam<QuestionFilters>({owners: [whoami.user_owner!.owner_id], statuses: filters.statuses});
  }, [filters.statuses, whoami.user_owner]);
  return (
    // `transform="scale(1)"` is a dirty hack to work around the fact that the CSS standard doesn't actually require `<tr>`
    // to support `position: relative` - which `LinkBox` requires to create the overlaid `<a>`.
    //
    // See https://github.com/w3c/csswg-drafts/issues/1899 and https://github.com/chakra-ui/chakra-ui/issues/3832 for
    // further details.
    <LinkBox as={Tr} transform="scale(1)" _hover={{bg: "gray.50", cursor: "pointer"}}>
      <Td fontSize="md">
        <LinkOverlay
          as={RouterLink}
          to={`/vendor-toolkit/questionnaires/${questionnaire.questionnaire_id}/questions/${question.question_id}?filters=${filtersParam}`}
        />
        <Counterparty counterparty={questionnaire.counterparty} queries={queries} />
      </Td>
      <Td>
        <Text fontSize="md" color="gray.700" fontWeight="600" noOfLines={2}>
          <QuestionNumber question={question} />
          <Highlight query={queries} styles={{bg: "yellow.200"}}>
            {question.text.trim()}
          </Highlight>
        </Text>
      </Td>
      <Td fontSize="md">
        <QuestionStatus status={responseLayer.status} />
      </Td>
      <Td>
        <DueDate dueDate={responseLayer.due_date} complete={responseLayer.status === QuestionStatusT.Complete} />
      </Td>
      <Td>
        <Icon as={ChevronRightIcon} fontSize="24" color="gray.500" />
      </Td>
    </LinkBox>
  );
};

const TaskRow = ({task, queries, filters}: {task: Task; queries: string[]; filters: TaskFilters}) => {
  switch (task.type) {
    case "QuestionnaireQuestion":
      return (
        <QuestionTaskRow
          question={task.content.question}
          questionnaire={task.content.questionnaire}
          queries={queries}
          filters={filters}
        />
      );
  }
};

const DEFAULT_FILTERS = {
  counterparties: ["ALL"],
  statuses: Object.keys(QuestionStatusT),
};

const TasksPage = withSuspense(() => {
  const [layerType] = useLayerType();
  const [tasks, checklist] = useQueriesData({
    queries: [
      {
        queryKey: ["vendorToolkit", "tasks"],
      },
      {
        queryKey: ["vendorToolkit", "gettingStartedChecklist"],
      },
    ],
  });
  const relevantCounterparties = getQuestionnaireRelevantCounterparties(
    tasks
      .filter(
        (task => task.type === "QuestionnaireQuestion") as (
          task: Task,
        ) => task is Task & {type: "QuestionnaireQuestion"},
      )
      .map(task => task.content.questionnaire),
  );
  const {filters, setFilter, clearFilters, filterCount} = useFilterState<TaskFilters>("taskFilters", DEFAULT_FILTERS);
  const {query, queries, setQuery, filter} = useFacetedSearch(
    t => (t.type === "QuestionnaireQuestion" ? t.content.question.text : t.content.adhoc_question.question.text),
    facetedSearchTasks(filters, layerType),
    [filters],
  );
  const {result, counts} = filter(tasks);

  return (
    <Page title="My tasks">
      <Stack h="full" spacing={0}>
        <GettingStarted checklist={checklist} />
        <Heading size="md" p="8">
          My tasks ({result.length})
        </Heading>
        <ActionBar
          actionButtons={
            <>
              <FilterCounterparties
                counts={counts.counterparty}
                counterparties={filters.counterparties}
                setCounterparties={newCounterparties => setFilter("counterparties", newCounterparties)}
                allCounterparties={relevantCounterparties}
              />
              <FilterStatuses
                counts={counts.status}
                statuses={filters.statuses}
                setStatuses={newStatuses => setFilter("statuses", newStatuses)}
              />
            </>
          }
        >
          <ActionBarSearch value={query} onChange={e => setQuery(e.target.value)} />
        </ActionBar>
        <FilterBanner filterCount={filterCount} clearFilters={clearFilters} />
        <Table style={{borderCollapse: "separate", borderSpacing: "0"}} size="lg" layout="fixed">
          <Thead bg="white" borderBottom="10px solid" borderBottomColor="red.100">
            <Tr>
              <Th width="15%" color="gray.500">
                Counterparty
              </Th>
              <Th width="50%" color="gray.500">
                Question
              </Th>
              <Th color="gray.500" width="15%">
                Status
              </Th>
              <Th color="gray.500" width="15%">
                Due
              </Th>
              <Th width="6%" />
            </Tr>
          </Thead>
          <Tbody>
            {result.map((task, i) => (
              <TaskRow task={task} queries={queries} filters={filters} key={i} />
            ))}
          </Tbody>
        </Table>
        {result.length === 0 && (
          <Flex minH="48" align="center" justify="center" fontWeight="600" color="gray.400" fontSize="md">
            All of your tasks are complete! Relax and enjoy your day.
          </Flex>
        )}
      </Stack>
    </Page>
  );
});

export default TasksPage;
