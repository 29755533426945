import {useCallback, useMemo, useState} from "react";

type ValidatedField<T> = {
  value: T;
  error: string | null;
  setValue: (newValue: T) => void;
  setError: (newError: string) => void;
};

export function useValidatedField<T>(initialValue: T): ValidatedField<T> {
  const [value, setRawValue] = useState(initialValue);
  const [error, setError] = useState<string | null>(null);
  const setValue = useCallback(
    (newValue: T) => {
      setRawValue(newValue);
      setError(null);
    },
    [setError],
  );
  return useMemo(() => ({value, error, setValue, setError}), [value, error, setValue, setError]);
}
