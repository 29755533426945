import {NavLink, useOutlet} from "react-router-dom";
import {withSuspense} from "../../../../state/withSuspense.tsx";
import {useQueryData} from "../../../../state/index.ts";
import {Box, Stack, Heading, Flex, GridItem, LinkBox, Highlight} from "@chakra-ui/react";
import Page from "../../../../components/Page.tsx";
import SidePane from "../../../../components/SidePane.tsx";
import SearchableList from "../../../../components/VerticalNavList.tsx";
import {AssetOwner, AssetMin} from "../../../../Types.ts";
import NewAsset from "./NewAsset.tsx";

const Empty = () => (
  <GridItem colSpan={8} p="6">
    <Heading size="md">No asset selected</Heading>
  </GridItem>
);

const ListItem = ({item, highlight}: {item: AssetMin; highlight: string[]}) => (
  <LinkBox
    as={NavLink}
    to={item.asset_id}
    display="flex"
    alignItems="center"
    _hover={{bg: "gray.50"}}
    px="6"
    py="5"
    _activeLink={{bg: "gray.50", color: "blue.500"}}
  >
    <Box flex="1">
      <Heading size="sm" noOfLines={1}>
        <Highlight query={highlight} styles={{bg: "yellow.100"}}>
          {item.title}
        </Highlight>
      </Heading>
    </Box>
  </LinkBox>
);

const AssetsPage = withSuspense(() => {
  const outlet = useOutlet() || <Empty />;
  const items = useQueryData({queryKey: ["vendorToolkit", "assets", {owner: AssetOwner.TrustCenter}]});

  return (
    <Page title="Assets">
      <Flex direction="row" alignItems="stretch">
        <SidePane>
          <SearchableList
            title="Assets"
            items={items}
            ItemComp={ListItem}
            getName={d => d.title}
            getKey={d => d.asset_id}
            actionButtons={<NewAsset />}
          />
        </SidePane>
        <Stack flex="1">{outlet}</Stack>
      </Flex>
    </Page>
  );
});

export default AssetsPage;
