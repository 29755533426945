import {
  ChakraProps,
  Box,
  Badge,
  Icon,
  Stack,
  AccordionItem,
  AccordionButton,
  Switch,
  AccordionIcon,
  AccordionPanel,
  Text,
  Accordion,
} from "@chakra-ui/react";
import {ChevronRightIcon} from "@heroicons/react/20/solid";
import api from "../../../../../../api";
import {DateUI} from "../../../../../../components/Date";
import {ApiDateTime, FactSourceDocument, FactSourceQuestion, FactSourceEntity, Fact} from "../../../../../../Types";
import {usePromiseState} from "../../../../../../hooks/promiseState";
import {useQueryData} from "../../../../../../state";
import {withSuspense} from "../../../../../../state/withSuspense";
import FirstLineCenter from "../../../../../components/FirstLineCenter";
import {
  sourceDocumentLink,
  sourceQuestionLink,
  questionResponse,
  sourceSubgraphLink,
  sourceSubgraphName,
} from "../../../Facts";
import {Link as RouterLink} from "react-router-dom";

// Props to add to intervening elements to prevent them from disrupting the grid layout
const subgridProps = {
  display: "grid",
  gridTemplateColumns: "subgrid",
  gridColumn: "1 / -1",
  padding: 0,
} satisfies ChakraProps;

// Props to add to grid cell elements to ensure consistent padding and alignment
const gridCellProps = {px: 6, py: 4} satisfies ChakraProps;

// Version of peer-hover that doesn't depend on element ordering
const peerHover = "[role=group]:has(>[data-peer]:hover)>&";

const FactSource = ({
  source,
  children,
  created_at,
}: {
  source: "Document" | "Question" | "Entity";
  children: React.ReactNode;
  created_at?: ApiDateTime;
}) => (
  <Box {...subgridProps}>
    <FirstLineCenter {...gridCellProps}>
      <Badge colorScheme={source === "Question" ? "blue" : "green"}>{source}</Badge>
    </FirstLineCenter>
    <Box {...gridCellProps}>{children}</Box>
    <FirstLineCenter color="gray.500" {...gridCellProps}>
      {created_at && <DateUI date={created_at} />}
    </FirstLineCenter>
  </Box>
);

const DocumentFactSource = ({source}: {source: FactSourceDocument}) => (
  <FactSource created_at={source.document_revision} source="Document">
    <Text fontWeight="semibold" as={RouterLink} _hover={{color: "blue.500"}} to={sourceDocumentLink(source)}>
      {source.document_name}
      <Icon as={ChevronRightIcon} verticalAlign="middle" mx={1} />
    </Text>
  </FactSource>
);

const QuestionFactSource = ({source}: {source: FactSourceQuestion}) => (
  <FactSource
    created_at={
      source.parent.type === "Section" ? source.parent.content.questionnaire_date : source.parent.content.question_date
    }
    source="Question"
  >
    <Stack gap={1}>
      <Text as={RouterLink} fontWeight="semibold" _hover={{color: "blue.500"}} to={sourceQuestionLink(source)}>
        {source.question_text}
        <Icon as={ChevronRightIcon} verticalAlign="middle" mx={1} />
      </Text>
      <Text>{questionResponse(source)}</Text>
    </Stack>
  </FactSource>
);

const EntityFactSource = withSuspense(({source}: {source: FactSourceEntity}) => {
  const subgraph = useQueryData({
    queryKey: ["vendorToolkit", "graph", {filter_entity_ids: [source.entity_id], recursion_depth: 2}],
  });
  return (
    <FactSource source="Entity">
      <Stack gap={1}>
        <Text as={RouterLink} fontWeight="semibold" _hover={{color: "blue.500"}} to={sourceSubgraphLink(subgraph)}>
          {sourceSubgraphName(subgraph)}
          <Icon as={ChevronRightIcon} verticalAlign="middle" mx={1} />
        </Text>
      </Stack>
    </FactSource>
  );
});

const FactRow = ({fact}: {fact: Fact}) => {
  const [togglingDisabled, toggleDisabled] = usePromiseState(async () => {
    api.vendorToolkit.facts.updateDisabled(fact.fact_id, !fact.disabled);
  }, [fact.fact_id, fact.disabled]);

  const isDeleted = fact.deleted != null;
  const isDisabled = fact.disabled || isDeleted;

  return (
    <AccordionItem borderColor="gray.100" fontSize="md" {...subgridProps} isDisabled={isDeleted}>
      <Box {...subgridProps} role="group">
        <AccordionButton
          {...gridCellProps}
          gridArea="auto / span 2"
          textAlign="left"
          _hover={{}}
          // Revert Chakra's disabled styling, we style the content manually
          // instead.
          _disabled={{cursor: "not-allowed"}}
          fontWeight="semibold"
          data-peer
          whiteSpace="pre-line"
        >
          <Stack alignItems="flex-start">
            <Text textDecoration={isDisabled ? "line-through" : undefined} color={isDisabled ? "gray.500" : "black"}>
              {fact.representative_text.content}
            </Text>
            {isDeleted && (
              <Badge fontSize="sm" colorScheme="red">
                Deleted
              </Badge>
            )}
          </Stack>
        </AccordionButton>
        <Box {...gridCellProps}>
          <DateUI date={fact.representative_text.created_at} />
        </Box>
        <FirstLineCenter {...gridCellProps}>
          <Switch
            isChecked={!isDisabled}
            onChange={toggleDisabled}
            isDisabled={isDeleted || togglingDisabled.inProgress}
          />
        </FirstLineCenter>
        <AccordionButton {...gridCellProps} _hover={{}} data-peer sx={{[peerHover]: {color: "black"}}} mb="auto">
          <FirstLineCenter>
            <AccordionIcon />
          </FirstLineCenter>
        </AccordionButton>
      </Box>
      <AccordionPanel
        borderTop="1px solid"
        borderColor="gray.100"
        bg="gray.50"
        color="gray.600"
        p={0}
        {...subgridProps}
        motionProps={{
          style: subgridProps,
          /* needed as display:none breaks the grid layout during animation */ unmountOnExit: true,
        }}
      >
        {fact.document_sources.map((ds, i) => (
          <DocumentFactSource key={i} source={ds} />
        ))}
        {fact.question_sources.map((qs, i) => (
          <QuestionFactSource key={i} source={qs} />
        ))}
        {fact.subgraph_sources.map((qs, i) => (
          <EntityFactSource key={i} source={qs} />
        ))}
      </AccordionPanel>
    </AccordionItem>
  );
};

export type ReviewItemFactTableProps = {
  involvedFacts: Fact[];
};

export function ReviewItemFactTable({involvedFacts}: ReviewItemFactTableProps) {
  return (
    <Stack gap={4}>
      <Box display="grid" gridTemplateColumns="120px 12fr 3fr 2fr auto" gridAutoFlow="row">
        <Box
          borderColor="gray.100"
          color="gray.500"
          textTransform="uppercase"
          letterSpacing="wider"
          fontSize="sm"
          fontWeight="bold"
          {...subgridProps}
        >
          <Text gridArea="auto / span 2" {...gridCellProps}>
            Fact
          </Text>
          <Text {...gridCellProps}>Age</Text>
          <Text {...gridCellProps}>Enabled</Text>
        </Box>
        <Accordion allowMultiple {...subgridProps}>
          {involvedFacts.map((f, i) => (
            <FactRow fact={f} key={i} />
          ))}
        </Accordion>
      </Box>
    </Stack>
  );
}
