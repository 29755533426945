import {PasswordConfig, PasswordPolicyConfig, Sso, PasswordManager, ToolsConfig} from "../../../../../Types";

import {
  Box,
  Divider,
  Select,
  Stack,
  Text,
  Textarea,
  FormControl,
  FormLabel,
  Switch,
  StackProps,
} from "@chakra-ui/react";

import FormSection, {FormQuestion} from "../components/FormSection";
import SwitchableNumericInput from "../../../../../components/SwitchableNumericInput";

const PasswordManagerQns = ({
  config,
  setTools,
}: {
  config: PasswordPolicyConfig;
  setTools: (config: ToolsConfig) => void;
}) => {
  const setPasswordManager = (password_manager: PasswordManager) => setTools({...config.tools, password_manager});
  const setPasswordManagerInstructions = (password_manager_instructions: string) =>
    setTools({...config.tools, password_manager_instructions});

  return (
    <Stack spacing="4">
      <FormQuestion
        title="Password manager"
        description="Select the password manager that you use. We advise that you just use 1 password manager across the organisation. If you are not using a password manager as part of your policy, then go and sort your lives out, you cretins."
      >
        <Select
          value={config.tools.password_manager}
          onChange={e => setPasswordManager(e.target.value as PasswordManager)}
        >
          <option value={PasswordManager.OnePassword}>1Password</option>
          <option value={PasswordManager.LastPass}>LastPass</option>
          <option value={PasswordManager.Keeper}>Keeper Password Manager</option>
        </Select>
      </FormQuestion>

      <FormQuestion title="Password manager instructions" description="Compatible with markdown">
        <Textarea
          value={config.tools.password_manager_instructions}
          onChange={e => setPasswordManagerInstructions(e.target.value)}
        />
      </FormQuestion>
    </Stack>
  );
};

const SsoQns = ({config, setTools}: {config: PasswordPolicyConfig; setTools: (config: ToolsConfig) => void}) => {
  const setSSOProvider = (sso: Sso) => setTools({...config.tools, sso});
  const setSSOProviderInstructions = (sso_instructions: string) => setTools({...config.tools, sso_instructions});

  return (
    <Stack spacing="4">
      <FormQuestion title="SSO provider" description="Select the preferred single sign-on method">
        <Select value={config.tools.sso} onChange={e => setSSOProvider(e.target.value as Sso)}>
          <option value={Sso.GSuite}>Google Workspace SSO</option>
          <option value={Sso.Okta}>Okta</option>
          <option value={Sso.AzureAD}>Microsoft Azure Active Directory</option>
          <option value={Sso.None}>None</option>
        </Select>
      </FormQuestion>

      <FormQuestion title="SSO instructions" description="Compatible with markdown">
        <Textarea value={config.tools.sso_instructions} onChange={e => setSSOProviderInstructions(e.target.value)} />
      </FormQuestion>
    </Stack>
  );
};

const Tools = ({
  config,
  setConfig,
}: {
  config: PasswordPolicyConfig;
  setConfig: (config: PasswordPolicyConfig) => void;
}) => {
  const setTools = (tools: ToolsConfig) => setConfig({...config, tools});

  return (
    <FormSection title="Tools" description="">
      <PasswordManagerQns config={config} setTools={setTools} />
      <SsoQns config={config} setTools={setTools} />
    </FormSection>
  );
};

const PasswordLengthQns = ({
  config,
  setPasswords,
}: {
  config: PasswordPolicyConfig;
  setPasswords: (config: PasswordConfig) => void;
}) => {
  const setPasswordLength = async (length_requirement: number | undefined) =>
    setPasswords({...config.passwords, length_requirement});

  return (
    <Box>
      <SwitchableNumericInput
        label={<FormLabel>Password length requirement</FormLabel>}
        value={config.passwords.length_requirement}
        minValue={5}
        maxValue={64}
        suffix="characters"
        onCommit={setPasswordLength}
        defaultValue="12"
        alignment="right"
        inputGroupProps={{size: "sm"}}
      />
      <Text fontSize="sm" color="gray.500" mt="1">
        Passwords should be automatically generated using {config.tools.password_manager} and should be at least 12
        characters long. Longer passwords than 12 characters are unlikely to be worth the extra burden for users.
      </Text>
    </Box>
  );
};

const PasswordUniqueQns = ({
  config,
  setPasswords,
}: {
  config: PasswordPolicyConfig;
  setPasswords: (config: PasswordConfig) => void;
}) => {
  const setUnique = (unique: boolean) => setPasswords({...config.passwords, unique});

  return (
    <Box>
      <FormControl display="flex" alignItems="center">
        <FormLabel htmlFor="passwords-unique" mb="0" flex="1">
          All passwords must be unique
        </FormLabel>
        <Switch
          isChecked={config.passwords.unique}
          onChange={() => setUnique(!config.passwords.unique)}
          id="passwords-unique"
        />
      </FormControl>
      <Text fontSize="sm" color="gray.500" mt="1">
        Passwords should never be reused.
      </Text>
    </Box>
  );
};

const PasswordRandomQns = ({
  config,
  setPasswords,
}: {
  config: PasswordPolicyConfig;
  setPasswords: (config: PasswordConfig) => void;
}) => {
  const setRandom = (random: boolean) => setPasswords({...config.passwords, random});

  return (
    <Box>
      <FormControl display="flex" alignItems="center">
        <FormLabel htmlFor="passwords-random" mb="0" flex="1">
          All passwords must appear random
        </FormLabel>
        <Switch
          isChecked={config.passwords.random}
          onChange={() => setRandom(!config.passwords.random)}
          id="passwords-random"
        />
      </FormControl>
      <Text fontSize="sm" color="gray.500" mt="1">
        All passwords must appear random. This means that they do not form words and are not disguised words.
      </Text>
      <Text fontSize="sm" color="gray.500" mt="1">
        Password managers automatically generate random passwords. The only passwords not automatically generated should
        be the password manager master password and users' device unlock passwords, which need to be memorable. However
        these should still be random."
      </Text>
    </Box>
  );
};

const PasswordCharacterQns = ({
  config,
  setPasswords,
}: {
  config: PasswordPolicyConfig;
  setPasswords: (config: PasswordConfig) => void;
}) => {
  const setCharacters = (k: "lowercase" | "uppercase" | "number" | "symbol", v: boolean) =>
    setPasswords({...config.passwords, characters: {...config.passwords.characters, [k]: v}});

  return (
    <FormQuestion
      title="Character requirements"
      description="Passwords should contain at least one letter, one number and one special character to make them harder to crack in a brute force attack."
    >
      <Stack spacing="4" py="2">
        <FormControl display="flex" alignItems="center">
          <FormLabel htmlFor="password-characters-lowercase" mb="0" flex="1" fontWeight="400">
            Lowercase characters
          </FormLabel>
          <Switch
            isChecked={config.passwords.characters.lowercase}
            onChange={() => setCharacters("lowercase", !config.passwords.characters.lowercase)}
            id="password-characters-lowercase"
          />
        </FormControl>
        <FormControl display="flex" alignItems="center">
          <FormLabel htmlFor="passwords-characters-uppercase" mb="0" flex="1" fontWeight="400">
            Uppercase characters
          </FormLabel>
          <Switch
            isChecked={config.passwords.characters.uppercase}
            onChange={() => setCharacters("uppercase", !config.passwords.characters.uppercase)}
            id="passwords-characters-uppercase"
          />
        </FormControl>
        <FormControl display="flex" alignItems="center">
          <FormLabel htmlFor="password-characters-number" mb="0" flex="1" fontWeight="400">
            Numbers
          </FormLabel>
          <Switch
            isChecked={config.passwords.characters.number}
            onChange={() => setCharacters("number", !config.passwords.characters.number)}
            id="password-characters-number"
          />
        </FormControl>
        <FormControl display="flex" alignItems="center">
          <FormLabel htmlFor="passwords-characters-symbol" mb="0" flex="1" fontWeight="400">
            Special characters
          </FormLabel>
          <Switch
            isChecked={config.passwords.characters.symbol}
            onChange={() => setCharacters("symbol", !config.passwords.characters.symbol)}
            id="passwords-characters-symbol"
          />
        </FormControl>
      </Stack>
    </FormQuestion>
  );
};

const PasswordExpiryQns = ({
  config,
  setPasswords,
}: {
  config: PasswordPolicyConfig;
  setPasswords: (config: PasswordConfig) => void;
}) => {
  const setPasswordExpiry = async (expiry: number | undefined) => setPasswords({...config.passwords, expiry});

  return (
    <Box>
      <SwitchableNumericInput
        label={<FormLabel>Password expiry</FormLabel>}
        value={config.passwords.expiry}
        minValue={30}
        maxValue={365}
        suffix="days"
        onCommit={setPasswordExpiry}
        defaultValue="90"
        alignment="right"
        inputGroupProps={{size: "sm"}}
      />
      <Text fontSize="sm" color="gray.500" mt="1">
        Best practice states that passwords should not expire. If a password is compromised, it is important that the
        compromised password policy is followed.
      </Text>
    </Box>
  );
};

const Passwords = ({
  config,
  setConfig,
}: {
  config: PasswordPolicyConfig;
  setConfig: (config: PasswordPolicyConfig) => void;
}) => {
  const setPasswords = (passwords: PasswordConfig) => setConfig({...config, passwords});

  return (
    <FormSection title="Passwords" description="">
      <PasswordLengthQns config={config} setPasswords={setPasswords} />
      <PasswordUniqueQns config={config} setPasswords={setPasswords} />
      <PasswordRandomQns config={config} setPasswords={setPasswords} />
      <PasswordCharacterQns config={config} setPasswords={setPasswords} />
      <PasswordExpiryQns config={config} setPasswords={setPasswords} />
    </FormSection>
  );
};

const Exceptions = ({
  config,
  setConfig,
}: {
  config: PasswordPolicyConfig;
  setConfig: (config: PasswordPolicyConfig) => void;
}) => {
  const setCompromisedPasswordProcess = (compromised_password_process: string) =>
    setConfig({
      ...config,
      exceptions: {
        ...config.exceptions,
        compromised_password_process,
      },
    });

  const setNonCompliance = (non_compliance: string) =>
    setConfig({
      ...config,
      exceptions: {
        ...config.exceptions,
        non_compliance,
      },
    });

  return (
    <FormSection title="Exceptions" description="Handling edge cases to this policy">
      <FormQuestion title="Compromised password process" description="Compatible with markdown">
        <Textarea
          value={config.exceptions.compromised_password_process}
          onChange={e => setCompromisedPasswordProcess(e.target.value)}
        />
      </FormQuestion>
      <FormQuestion title="Non compliance" description="Compatible with markdown">
        <Textarea value={config.exceptions.non_compliance} onChange={e => setNonCompliance(e.target.value)} />
      </FormQuestion>
    </FormSection>
  );
};

const PasswordPolicy = ({
  config,
  setConfig,
  ...props
}: {config: PasswordPolicyConfig; setConfig: (config: PasswordPolicyConfig) => void} & StackProps) => {
  return (
    <Stack divider={<Divider />} spacing="8" {...props} p="4">
      <Tools config={config} setConfig={setConfig} />
      <Passwords config={config} setConfig={setConfig} />
      <Exceptions config={config} setConfig={setConfig} />
    </Stack>
  );
};

export default PasswordPolicy;
