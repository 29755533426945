import {useQueryData} from "../../../../../state";
import {Type} from "../../../../../typing";
import {withTypedProps} from "../../../../../typing/typedProps";

export const CrmConnectionParams = Type.Object({crmConnectionId: Type.CrmConnectionId()});

const Crumb = withTypedProps(CrmConnectionParams, ({crmConnectionId}) => {
  return useQueryData({queryKey: ["vendorToolkit", "crmConnection", crmConnectionId]}).name;
});
export default Crumb;
