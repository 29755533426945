import {Icon} from "@chakra-ui/react";
import {RoundButton} from "../../Header/TopRightNav/RoundButton";
import {Cog8ToothIcon} from "@heroicons/react/24/outline";
import {Link as RouterLink} from "react-router-dom";

export default function SettingsButton() {
  return (
    <RoundButton as={RouterLink} to="account-settings">
      <Icon as={Cog8ToothIcon} mx="auto" h="6" w="6" display="block" position="relative" />
    </RoundButton>
  );
}
