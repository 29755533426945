import {Icon, Tab, TabList, Tabs} from "@chakra-ui/react";
import {Outlet, Link as RouterLink, useOutletContext} from "react-router-dom";
import {RouteTab} from ".";
import {TabOutletContext} from "./context";
import {useRelativeLocation} from "../../hooks/relativeLocation";

const RouterTabs = ({tabs}: {tabs: RouteTab[]}) => {
  const {enabledTabs, banner, context} = useOutletContext<TabOutletContext>();
  const relativePath = useRelativeLocation();
  const filteredTabs = enabledTabs ? tabs.filter(tab => enabledTabs.includes(tab.path)) : tabs;
  return (
    <Tabs index={filteredTabs.findIndex(tab => tab.path === relativePath || relativePath.startsWith(tab.path + "/"))}>
      <TabList px="4">
        {filteredTabs.map(tab => (
          <Tab key={tab.path} as={RouterLink} to={tab.path} _focusVisible={{boxShadow: "none"}} cursor="pointer">
            {tab.icon && <Icon as={tab.icon} mr="2" />} {tab.title}
          </Tab>
        ))}
      </TabList>
      {banner}
      <Outlet context={context} />
    </Tabs>
  );
};

export default RouterTabs;
