import CrmConnections from "./CrmConnections";
import DocumentSources from "./DocumentSources";
import QuestionSources from "./QuestionSources";
import {RouteTab, tabbedRoute} from "../../../../components/RouterTabs";
import NotificationChannels from "../NotificationChannels";
import IntegrationsPage from "./IntegrationsPage";
import ESignatureProviders from "../ESignatureProviders";

const tabs: RouteTab[] = [DocumentSources, CrmConnections, QuestionSources, NotificationChannels, ESignatureProviders];

const routes = tabbedRoute({path: "integrations", title: "Integrations", tabs, element: <IntegrationsPage />});

export default routes;
