import {Counterparty} from "../../../../Types";
import {
  Box,
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  useDisclosure,
  InputGroup,
  InputLeftAddon,
  ButtonProps,
  HStack,
} from "@chakra-ui/react";
import {memo} from "react";
import api from "../../../../api";
import {SquaresPlusIcon} from "@heroicons/react/20/solid";
import {useModalState} from "../../../../hooks/modalState";
import {useModalValidationState, useValidatedPromiseState} from "../../../../hooks/validationState";

const NewCounterparty = memo(({onCreate, ...props}: {onCreate?: (c: Counterparty) => void} & ButtonProps) => {
  const {isOpen, onOpen, onClose} = useDisclosure();
  const [name, setName] = useModalState(isOpen, "");
  const [url, setUrl] = useModalState(isOpen, "");
  const [validationErrors, setValidationErrors] = useModalValidationState(isOpen, {name, url});
  const [creating, create] = useValidatedPromiseState(
    async () => {
      const counterparty = await api.vendorToolkit.counterparties.create({
        name,
        url,
      });
      onClose();
      if (onCreate) {
        onCreate(counterparty);
      }
    },
    [name, url, onClose, onCreate],
    setValidationErrors,
  );

  return (
    <>
      <Button
        leftIcon={<Icon as={SquaresPlusIcon} />}
        onClick={onOpen}
        colorScheme="green"
        children="New counterparty"
        {...props}
      />
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent as="form" onSubmit={create}>
          <ModalHeader>Create new counterparty</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing="8" mb="4">
              <FormControl isRequired isInvalid={validationErrors.name !== undefined}>
                <FormLabel>Name</FormLabel>
                <Input onChange={e => setName(e.target.value)} value={name} placeholder="Example Ltd" autoFocus />
                {validationErrors.name && validationErrors.name[0] && (
                  <FormErrorMessage>{validationErrors.name[0].message}</FormErrorMessage>
                )}
              </FormControl>

              <FormControl isInvalid={validationErrors.url !== undefined}>
                <FormLabel>Domain</FormLabel>
                <InputGroup>
                  <InputLeftAddon>https://</InputLeftAddon>
                  <Input onChange={e => setUrl(e.target.value)} value={url} placeholder="example.org" />
                </InputGroup>
                {validationErrors.url && validationErrors.url[0] && (
                  <FormErrorMessage>{validationErrors.url[0].message}</FormErrorMessage>
                )}
              </FormControl>

              {creating.lastError ? (
                <Alert status="error" mt="12">
                  <AlertIcon boxSize="40px" />
                  <Box>
                    <AlertTitle fontSize="md">Error creating counterparty</AlertTitle>
                    <AlertDescription fontSize="md">{`${creating.lastError}`}</AlertDescription>
                  </Box>
                </Alert>
              ) : null}
            </Stack>
          </ModalBody>

          <ModalFooter>
            <HStack spacing="3">
              <Button variant="ghost" onClick={onClose} isDisabled={creating.inProgress}>
                Cancel
              </Button>
              <Button colorScheme="blue" type="submit" isLoading={creating.inProgress}>
                Create
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
});

export default NewCounterparty;
