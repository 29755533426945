import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
} from "@chakra-ui/react";
import {Questionnaire} from "../../../../../../Types.ts";
import {useModalState} from "../../../../../../hooks/modalState.ts";
import UploadStep from "./UploadStep.tsx";
import TemplateStep from "./TemplateStep.tsx";
import PopulateStep from "./PopulateStep.tsx";
import useUploadState from "./uploadState.ts";
import useTemplateState from "./templateState.tsx";

const steps = [
  {title: "Prepare", description: "Download template"},
  {title: "Populate", description: "Fill out"},
  {title: "Upload", description: "Upload the CSV"},
];

const ImportCsvModal = ({
  questionnaire,
  isOpen,
  onClose,
  onCreate,
}: {
  questionnaire: Questionnaire;
  isOpen: boolean;
  onClose: () => void;
  onCreate?: (questionIds: string[]) => void;
}) => {
  const [step, setStep] = useModalState(isOpen, 0);
  const uploadState = useUploadState(questionnaire, isOpen, onClose, onCreate);
  const templateState = useTemplateState(isOpen);

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onClose();
      }}
    >
      <ModalOverlay />
      <ModalContent minW="900px" minH="530px">
        <ModalHeader>Upload questions</ModalHeader>
        <ModalCloseButton />
        <Alert status="warning">
          <AlertIcon />
          <Box>
            <AlertDescription fontSize="md">
              CSVs uploaded here must use the template provided. Use the more powerful "Import from file" option instead
              for other files or CSVs which don't follow the prescribed format.
            </AlertDescription>
          </Box>
        </Alert>
        <ModalBody>
          <Stack spacing={4}>
            <Stepper index={step} my={4}>
              {steps.map((step, index) => (
                <Step key={index}>
                  <StepIndicator>
                    <StepStatus complete={<StepIcon />} incomplete={<StepNumber />} active={<StepNumber />} />
                  </StepIndicator>

                  <Box flexShrink="0">
                    <StepTitle>{step.title}</StepTitle>
                    <StepDescription>{step.description}</StepDescription>
                  </Box>

                  <StepSeparator />
                </Step>
              ))}
            </Stepper>
            {step === 0 && <TemplateStep {...templateState} />}
            {step === 1 && <PopulateStep />}
            {step === 2 && <UploadStep {...uploadState} />}
          </Stack>
        </ModalBody>

        <ModalFooter>
          <HStack spacing="3">
            <Button
              variant="ghost"
              onClick={() => {
                onClose();
              }}
              isDisabled={uploadState.submitting.inProgress}
            >
              Cancel
            </Button>
            {step !== 0 && (
              <Button onClick={() => setStep(v => v - 1)} isDisabled={uploadState.submitting.inProgress}>
                Back
              </Button>
            )}
            {step !== 2 && <Button onClick={() => setStep(v => v + 1)}>Next</Button>}
            {step === 2 && (
              <Button
                colorScheme="blue"
                isLoading={uploadState.submitting.inProgress}
                isDisabled={
                  uploadState.uploadState?.id !== "Uploaded" ||
                  !!uploadState.submitting.lastError ||
                  uploadState.fileQuestions.sections.length === 0
                }
                onClick={uploadState.submit}
              >
                Upload
              </Button>
            )}
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ImportCsvModal;
