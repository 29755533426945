import {Icon, MenuItem, Tooltip, useDisclosure} from "@chakra-ui/react";
import BasePageSideBar from "../../../../../../components/BasePageSideBar.tsx";
import {PencilIcon, TrashIcon} from "@heroicons/react/20/solid";
import CreateOrEditScopeModal from "../../modals/CreateOrEditScopeModal";
import AddSectionButton from "../actions/AddSectionButton";
import MoreButton from "../actions/MoreButton";
import {CubeIcon} from "@heroicons/react/24/outline";
import {getNodeName, getScopeId, GLOBAL_SCOPE_ID, Hierarchy, ScopeNode} from "../../utility/hierarchy";

type Props = {
  hierarchy: Hierarchy;
  scope: ScopeNode;
};

function ScopeSideBarItem(props: Props) {
  const scopeId = getScopeId(props.scope);
  const isGlobalScope = scopeId === GLOBAL_SCOPE_ID;

  const moreMenu = useDisclosure();
  const addModal = useDisclosure();
  const editModal = useDisclosure();

  const editTooltip = isGlobalScope ? "Cannot edit the global scope." : undefined;

  const deleteTooltip = isGlobalScope
    ? "Cannot delete the global scope."
    : "To delete a scope, please contact support.";

  return (
    <BasePageSideBar
      label={getNodeName(props.scope)}
      forceActionsVisible={moreMenu.isOpen || addModal.isOpen || editModal.isOpen}
      icon={CubeIcon}
      menuActions={
        <>
          <MoreButton {...moreMenu}>
            <Tooltip label={editTooltip} isDisabled={editTooltip === undefined}>
              <MenuItem
                icon={<Icon as={PencilIcon} />}
                onClick={editModal.onOpen}
                fontSize="md"
                isDisabled={isGlobalScope}
              >
                Edit
              </MenuItem>
            </Tooltip>
            <Tooltip label={deleteTooltip}>
              <MenuItem icon={<Icon as={TrashIcon} />} fontSize="md" isDisabled>
                Delete
              </MenuItem>
            </Tooltip>
          </MoreButton>

          <AddSectionButton hierarchy={props.hierarchy} defaultParent={props.scope} {...addModal} />

          {editModal.isOpen && <CreateOrEditScopeModal type="edit" scope={props.scope} {...editModal} />}
        </>
      }
      href={`scope/${scopeId}`}
    />
  );
}

export default ScopeSideBarItem;
