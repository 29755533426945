import {useQueryData} from "../../../../../state";
import {Type} from "../../../../../typing";
import {withTypedProps} from "../../../../../typing/typedProps";

export const DocumentParams = Type.Object({documentId: Type.DocumentId()});

const Crumb = withTypedProps(DocumentParams, ({documentId}) => {
  return useQueryData({queryKey: ["vendorToolkit", "document", documentId]}).name;
});

export default Crumb;
