import {Circle, Indicator, IndicatorProps} from "@chakra-ui/react";

const IndicatorBadge = ({count, ...indicatorProps}: {count?: number} & IndicatorProps) => {
  return (
    // There are a lot of magic numbers here. Please ignore them, they are set to match the pre-styled bubble
    // in Knock's notification widget.
    <Indicator offsetX="4px" offsetY="7px" {...indicatorProps}>
      <Circle fontSize="9px" fontWeight="500" size="15px" bg="rgb(235, 87, 87)" color="white">
        {count}
      </Circle>
    </Indicator>
  );
};

export default IndicatorBadge;
