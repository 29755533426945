import {
  Box,
  ButtonProps,
  Drawer,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  useDisclosure,
} from "@chakra-ui/react";
import React, {ReactElement} from "react";

const HelpDrawer = ({
  open,
  children,
  title,
}: {
  open: ReactElement<ButtonProps>;
  children: React.ReactNode;
  title: string;
}) => {
  const {isOpen, onOpen, onClose} = useDisclosure();
  const trigger = React.cloneElement(open, {
    onClick: onOpen,
  });
  return (
    <>
      {trigger}
      <Drawer isOpen={isOpen} onClose={onClose} placement="right" size={{base: "md", "2xl": "lg"}}>
        <DrawerContent overflow="auto" paddingBottom="8">
          <DrawerHeader padding={8}>{title}</DrawerHeader>
          <DrawerCloseButton />
          <Box paddingX={8}>{children}</Box>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default HelpDrawer;
