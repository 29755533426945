import {NewDocumentSource} from "./NewDocumentSource";
import {ArrowDownOnSquareStackIcon} from "@heroicons/react/20/solid";
import {ConfigureDocumentSource} from "./ConfigureDocumentSource";
import {Route} from "react-router-dom";
import DocumentSourcesPage from "./DocumentSourcesPage";
import Crumb from "./Crumb";
import {DiagnoseDocumentSource} from "./DiagnoseDocumentSource";

export default {
  title: "Document sources",
  path: "document-sources",
  icon: ArrowDownOnSquareStackIcon,
  routes: (
    <>
      <Route path="" element={<DocumentSourcesPage />} />
      <Route path="new/:connectorId" element={<NewDocumentSource />} />
      <Route path="configure/:documentSourceId" element={<ConfigureDocumentSource />} handle={{Crumb}} />
      <Route path="diagnose/:documentSourceId" element={<DiagnoseDocumentSource />} handle={{Crumb}} />
    </>
  ),
};
