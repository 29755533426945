import authRoutes from "./Auth";
import ExtensionRouter from "./Extension";

import isExtension from "./isExtension";
import userRoutes from "./User";

const appRoutes = isExtension ? (
  ExtensionRouter
) : (
  <>
    {userRoutes}
    {authRoutes}
  </>
);

export default appRoutes;
