import {Alert, AlertDescription, AlertIcon, AlertProps, AlertTitle, Box, Button, Icon} from "@chakra-ui/react";
import {router} from "../../../../router/index.tsx";
import {Questionnaire} from "../../../../Types.ts";
import {Link as RouterLink} from "react-router-dom";
import {ChevronRightIcon} from "@heroicons/react/20/solid";
import AddQuestionButton from "./Questions/AddQuestionButton.tsx";

const AlertBanner = ({color, ...props}: {color: string} & AlertProps) => (
  <Alert fontSize="md" bg={`${color}.500`} color="white" px={8} borderY="1px" borderColor={`${color}.600`} {...props} />
);

export const QuestionnaireCreatedBanner = ({questionnaire}: {questionnaire: Questionnaire}) => {
  return (
    <AlertBanner status="success" color="green">
      <AlertIcon h={8} w={8} color="inherit" />
      <Box flex={1}>
        <AlertTitle>Questionnaire successfully created</AlertTitle>
        <AlertDescription>Import some questions to get started.</AlertDescription>
      </Box>
      <AddQuestionButton
        bg="white"
        color="green.500"
        colorScheme="gray"
        questionnaire={questionnaire}
        onCreate={() => router!.navigate(`/vendor-toolkit/questionnaires/${questionnaire.questionnaire_id}/questions`)}
      />
    </AlertBanner>
  );
};

export const ExportReadyBanner = () => {
  return (
    <AlertBanner status="success" color="green">
      <AlertIcon h={8} w={8} color="inherit" />
      <Box flex={1}>
        <AlertTitle>Questionnaire ready for export</AlertTitle>
        <AlertDescription>
          Head to the export pane to download the questionnaire in it's original format
        </AlertDescription>
      </Box>
      <Button
        as={RouterLink}
        to="./export"
        rightIcon={<Icon as={ChevronRightIcon} />}
        bg="white"
        color="green.500"
        colorScheme="gray"
      >
        Export
      </Button>
    </AlertBanner>
  );
};

export const QuestionnaireImportingBanner = () => {
  return (
    <AlertBanner status="info" color="gray">
      <AlertIcon h={8} w={8} color="inherit" />
      <Box flex={1}>
        <AlertTitle>Questionnaire import in progress</AlertTitle>
        <AlertDescription>Questions are being imported from an original source file or portal.</AlertDescription>
      </Box>
    </AlertBanner>
  );
};

export const QuestionnaireAutomatingBanner = () => {
  return (
    <AlertBanner status="info" color="gray">
      <AlertIcon h={8} w={8} color="inherit" />
      <Box flex={1}>
        <AlertTitle>Questionnaire automation in progress</AlertTitle>
        <AlertDescription>Questions are being automatically answered by the Platformed AI.</AlertDescription>
      </Box>
    </AlertBanner>
  );
};

export const HistoricalQuestionnaireImportingBanner = () => {
  return (
    <AlertBanner status="info" color="gray">
      <AlertIcon h={8} w={8} color="inherit" />
      <Box flex={1}>
        <AlertTitle>Historical questionnaire import in progress</AlertTitle>
        <AlertDescription>Questions are being imported from an original source file.</AlertDescription>
      </Box>
    </AlertBanner>
  );
};

export const ArchivedQuestionnaireBanner = () => {
  return (
    <Alert status="warning" fontSize="md">
      <AlertIcon />
      <AlertTitle>This questionnaire has been archived</AlertTitle>
    </Alert>
  );
};
