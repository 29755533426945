import {memo, useState} from "react";
import {
  Button,
  ButtonProps,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import {usePromiseState} from "../../../hooks/promiseState.ts";
import api from "../../../api/index.ts";
import {
  BulkQuestionUpdateItem,
  nominate,
  OwnerId,
  OwnerType,
  QuestionId,
  QuestionStatus,
  TeamId,
  UserId,
} from "../../../Types.ts";
import {ChevronRightIcon, PaperAirplaneIcon} from "@heroicons/react/20/solid";
import {useQueryData} from "../../../state/index.ts";
import {useLayerType} from "../../../hooks/layerType.ts";
import {useNavigate} from "react-router-dom";
import {useModalState} from "../../../hooks/modalState.ts";

export const SendToReviewButton = memo(
  ({
    questionId,
    nextQuestionId,
    buttonProps,
  }: {
    questionId: QuestionId;
    nextQuestionId?: QuestionId;
    buttonProps?: ButtonProps;
  }) => {
    const [layerType] = useLayerType();
    const navigate = useNavigate();
    const users = useQueryData({queryKey: ["registeredUsers"]});
    const teams = useQueryData({queryKey: ["teams"]});
    const {isOpen, onOpen, onClose} = useDisclosure();
    const [assigneeType, setAssigneeType] = useModalState(isOpen, "user");
    const [userId, setUserId] = useState<UserId | null>(null);
    const [teamId, setTeamId] = useState<TeamId | null>(null);
    const [sendingToReview, sendToReview] = usePromiseState(
      async (ownerId: OwnerId) => {
        const updateStatus: BulkQuestionUpdateItem = {
          question_id: questionId,
          action: {type: "SetStatus", content: QuestionStatus.Review},
        };
        const assign: BulkQuestionUpdateItem = {
          question_id: questionId,
          action: {type: "Assign", content: ownerId},
        };
        await api.vendorToolkit.questions.bulkUpdate({items: [updateStatus, assign], layer_type: layerType});
        onClose();

        if (nextQuestionId !== undefined) {
          navigate(`../${nextQuestionId}`);
        }
      },
      [navigate, nextQuestionId, onClose, questionId, layerType],
    );
    const [sendingToUser, sendToUser] = usePromiseState(
      async (userId: UserId) => {
        const owner = await api.owners.resolve([{type: OwnerType.User, content: userId}]);
        await sendToReview(owner[0].owner_id);
      },
      [sendToReview],
    );
    const [sendingToTeam, sendToTeam] = usePromiseState(
      async (teamId: TeamId) => {
        const owner = await api.owners.resolve([{type: OwnerType.Team, content: teamId}]);
        await sendToReview(owner[0].owner_id);
      },
      [sendToReview],
    );
    return (
      <>
        <Button
          leftIcon={<Icon as={PaperAirplaneIcon} />}
          rightIcon={nextQuestionId !== undefined ? <Icon as={ChevronRightIcon} /> : undefined}
          isLoading={sendingToReview.inProgress || sendingToUser.inProgress || sendingToTeam.inProgress}
          colorScheme="orange"
          onClick={onOpen}
          overflow="hidden"
          {...buttonProps}
        >
          <Text overflow="hidden">Send to review...</Text>
        </Button>
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Send to review</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Stack spacing="8" mb="4">
                <FormControl>
                  <FormLabel>Category</FormLabel>
                  <RadioGroup onChange={e => setAssigneeType(e)} value={assigneeType}>
                    <HStack gap={4}>
                      <Radio value={"user"}>User</Radio>
                      <Radio value={"team"}>Team</Radio>
                    </HStack>
                  </RadioGroup>
                </FormControl>
                <FormControl>
                  <FormLabel>Reviewer</FormLabel>
                  {assigneeType === "user" && (
                    <Select
                      onChange={e => setUserId(e.target.value === "<none>" ? null : nominate("userId", e.target.value))}
                      value={userId ?? "<none>"}
                    >
                      <option key={"<none>"} value={"<none>"}>
                        --- Please select user ---
                      </option>
                      {users.map(({user: {user_id, name}}) => (
                        <option key={user_id} value={user_id}>
                          {name}
                        </option>
                      ))}
                    </Select>
                  )}
                  {assigneeType === "team" &&
                    (teams.length > 0 ? (
                      <Select
                        onChange={e =>
                          setTeamId(e.target.value === "<none>" ? null : nominate("teamId", e.target.value))
                        }
                        value={teamId ?? "<none>"}
                      >
                        <option key={"<none>"} value={"<none>"}>
                          --- Please select team ---
                        </option>
                        {teams.map(({team_id, name}) => (
                          <option key={team_id} value={team_id}>
                            {name}
                          </option>
                        ))}
                      </Select>
                    ) : (
                      <Text fontSize={"medium"}>There are no teams on this account.</Text>
                    ))}
                </FormControl>
              </Stack>
            </ModalBody>
            <ModalFooter>
              <HStack spacing="3">
                <Button variant="outline" onClick={onClose}>
                  Cancel
                </Button>
                <Button
                  colorScheme="blue"
                  onClick={() => (assigneeType === "user" ? sendToUser(userId!) : sendToTeam(teamId!))}
                  isDisabled={assigneeType === "user" ? !userId : !teamId}
                >
                  Send to review
                </Button>
              </HStack>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    );
  },
);
