import {useQueryData} from "../../state";
import {getFrontendEnvironment} from "../../utils/environment";
import {Type} from "../../typing";
import {useTypedParams} from "../../hooks/typedParams";

const {custom_domain} = getFrontendEnvironment();

export const ClientAccountSlugParams = Type.Object({clientAccountSlug: Type.String()});

export const useClientAccount = custom_domain
  ? () => {
      return useQueryData({queryKey: ["external", "account", custom_domain.account_id]});
    }
  : () => {
      const {clientAccountSlug} = useTypedParams(ClientAccountSlugParams);
      return useQueryData({queryKey: ["external", "accountBySlug", clientAccountSlug]});
    };
