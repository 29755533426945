import {Navigate, Route} from "react-router-dom";
import FactClusterPage from "./FactClusterPage.tsx";
import FactSections from "./FactSections.tsx";
import {ExploreCrumb, ResolutionCrumb} from "./Crumb.tsx";
import ResolutionsPage from "./Resolutions/ResolutionsPage.tsx";
import Resolution from "./Resolutions/ResolutionPage.tsx";
import {
  DocumentCategory,
  FactSourceDocument,
  FactSourceQuestion,
  KNOWN_ENTITY_PROPERTIES_BY_TYPE,
  SubGraph,
} from "../../../../Types.ts";
import {getParentEntities, getRelationship} from "../../graph/util.ts";

export function sourceQuestionnaireLink(sourceQuestion: FactSourceQuestion): string | null {
  if (sourceQuestion.parent.type === "AdHoc") {
    return null;
  }

  return sourceQuestion.parent.content.questionnaire_archived_at
    ? `/vendor-toolkit/library/historical-questionnaires/${sourceQuestion.parent.content.questionnaire_id}/questions`
    : `/vendor-toolkit/questionnaires/${sourceQuestion.parent.content.questionnaire_id}/questions`;
}

export function sourceQuestionLink(sourceQuestion: FactSourceQuestion): string {
  if (sourceQuestion.parent.type === "AdHoc") {
    return `/vendor-toolkit/questions/${sourceQuestion.question_id}`;
  }

  return sourceQuestion.parent.content.questionnaire_archived_at
    ? `/vendor-toolkit/library/historical-questionnaires/${sourceQuestion.parent.content.questionnaire_id}/questions/${sourceQuestion.question_id}`
    : `/vendor-toolkit/questionnaires/${sourceQuestion.parent.content.questionnaire_id}/questions/${sourceQuestion.question_id}`;
}

export function sourceDocumentLink(sourceDocument: FactSourceDocument): string {
  const mapping: {[category in DocumentCategory]: string} = {
    [DocumentCategory.Policy]: "policies",
    [DocumentCategory.Certification]: "certifications",
    [DocumentCategory.Other]: "other-documents",
    [DocumentCategory.Report]: "reports",
  };
  return `/vendor-toolkit/library/${mapping[sourceDocument.document_category]}/${sourceDocument.document_id}/file`;
}

export function sourceSubgraphName(sourceSubgraph: SubGraph): string {
  const rootEntityId = sourceSubgraph.root_entity_ids[0];
  const relationship = getRelationship(
    sourceSubgraph.entities[rootEntityId],
    KNOWN_ENTITY_PROPERTIES_BY_TYPE["Relationship"],
  );
  const entityId = relationship ? relationship.child_entity_id : rootEntityId;
  return sourceSubgraph.entities[entityId].properties.name ?? "Unnamed";
}

export function sourceSubgraphLink(sourceSubgraph: SubGraph): string {
  const rootEntityId = sourceSubgraph.root_entity_ids[0];
  const relationship = getRelationship(
    sourceSubgraph.entities[rootEntityId],
    KNOWN_ENTITY_PROPERTIES_BY_TYPE["Relationship"],
  );
  const entityId = relationship ? relationship.child_entity_id : rootEntityId;
  const isSubprocessor = getParentEntities(sourceSubgraph, ["subprocessor"], entityId).length > 0;
  const isPerson =
    getParentEntities(sourceSubgraph, ["beneficial_owner", "board_member", "senior_leadership"], entityId).length > 0;
  if (isSubprocessor) {
    return "/vendor-toolkit/library/subprocessors";
  } else if (isPerson) {
    return "/vendor-toolkit/library/company/people";
  } else {
    return "/vendor-toolkit/library/company/basic";
  }
}

export function questionResponse(question: FactSourceQuestion): string {
  const parts = [];
  if (question.response_parts.yes_no.enabled.content && question.response_yes_no != null) {
    parts.push(question.response_yes_no ? "Yes" : "No");
  }
  if (question.response_parts.select.enabled.content && question.response_select != null) {
    for (const {value, other_text} of question.response_select) {
      const opt = question.response_parts.select.config.options.find(opt => opt.value === value);
      const valueText = opt?.label ?? value;
      const hasOtherText = opt ? opt.allow_other_text && other_text : !!other_text;
      if (hasOtherText) {
        parts.push(`${valueText}: ${other_text}`);
      } else {
        parts.push(valueText);
      }
    }
  }
  if (question.response_parts.text.enabled.content && question.response_text != "") {
    parts.push(question.response_text);
  }
  return parts.join(". ");
}

export default (
  <>
    <Route id="NewFacts" path="clustered-facts" handle={{crumb: "Facts"}}>
      <Route index element={<Navigate to="explore" replace />} handle={{crumb: "Explore"}} />
      <Route id="Explore" path="explore" handle={{crumb: "Explore"}}>
        <Route index Component={FactSections} />
        <Route handle={{Crumb: ExploreCrumb}} path=":clusterId" Component={FactClusterPage} />
      </Route>
      <Route id="Resolutions" path="resolutions" handle={{crumb: "Resolutions"}}>
        <Route index Component={ResolutionsPage} />
        <Route handle={{Crumb: ResolutionCrumb}} path=":resolutionId" Component={Resolution} />
      </Route>
    </Route>
  </>
);
