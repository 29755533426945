import {useCallback, useRef, useState} from "react";
import {usePromiseState} from "./promiseState";
import {useEventListener} from "usehooks-ts";
import useLayoutUnmount from "./unmount";

export type CommitTextProps = {
  value: string;
  onCommit: (value: string) => Promise<void>;
  preCommit?: (newValue: string) => string;
};

export function useCommitText(
  value: string,
  onCommit: (newValue: string) => Promise<void>,
  preCommit?: (newValue: string) => string,
) {
  const inputRef = useRef<HTMLInputElement & HTMLTextAreaElement>(null);
  const isCommitting = useRef(false);
  const [overlayValue, setOverlayValue] = useState<string | null>(null);
  const [committing, commit, clearError] = usePromiseState(
    async (newValue: string) => {
      await onCommit(newValue);
      setOverlayValue(null);
    },
    [onCommit],
  );
  const currentValue = overlayValue ?? (committing.inProgress ? committing.lastArgs?.[0] : null) ?? value;
  const onChange = useCallback(() => {
    setOverlayValue(inputRef.current!.value);
  }, [inputRef]);

  const commitWrapper = useCallback(async () => {
    if (isCommitting.current) {
      return;
    }
    try {
      isCommitting.current = true;
      if (overlayValue != null) {
        let newValue = overlayValue;
        if (newValue !== value) {
          if (preCommit) {
            newValue = preCommit(newValue);
          }
          await commit(newValue);
        } else {
          clearError();
        }
      }
    } finally {
      isCommitting.current = false;
    }
  }, [commit, preCommit, value, clearError, overlayValue]);

  useEventListener("change", commitWrapper, inputRef);
  // Sometimes react breaks the `onchange` event for textareas by
  // assigning to `value` even when it hasn't changed...
  useEventListener("blur", commitWrapper, inputRef);
  // If the element is unmounted, commit any outstanding changes
  useLayoutUnmount(commitWrapper);

  return {inputRef, currentValue, onChange, committing, commit};
}
