import {Heading, Stack, Table, Tbody, Td, Th, Thead, Tr} from "@chakra-ui/react";
import {useQueriesData, useQueryData} from "../../../../../state/index.ts";
import DocumentTitle from "../../../../../components/DocumentTitle.tsx";
import {DocumentSourceParams} from "./Crumb";
import {useTypedParams} from "../../../../../hooks/typedParams";

export const DiagnoseDocumentSource = () => {
  const {documentSourceId} = useTypedParams(DocumentSourceParams);
  const source = useQueryData({queryKey: ["vendorToolkit", "documentSource", documentSourceId]});
  const [errors] = useQueriesData({
    queries: source.last_error_catalog
      ? [
          {
            queryKey: ["reportedErrorsInCatalog", source.last_error_catalog.catalog_id],
          },
        ]
      : [],
  });

  return (
    <>
      <DocumentTitle title="Diagnose document source" />
      <Stack p={8} spacing={8} align="flex-start">
        <Heading size="md" p="0" display="flex" alignItems="center" gap={2}>
          Diagnose document source - {source.name}
        </Heading>
        <Table>
          <Thead>
            <Tr>
              <Th w="18%">Title</Th>
              <Th w="20%">Description</Th>
              <Th w="15%">URL</Th>
            </Tr>
          </Thead>
          <Tbody>
            {(errors ?? []).map((error, i) => (
              <Tr key={i}>
                <Td>{error.title}</Td>
                <Td>{error.description}</Td>
                <Td>{error.link_url}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Stack>
    </>
  );
};
