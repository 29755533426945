import {useDisclosure, IconButton, Icon} from "@chakra-ui/react";
import {PencilIcon} from "@heroicons/react/20/solid";
import {AddOrEditSubprocessorModal} from "./AddOrEditSubprocessorModal";
import {SubGraph, EntityId} from "../../../../Types";
import {Subprocessor} from "../../graph/types/subprocessor";

type Props = {
  graph: SubGraph;
  subprocessor: Subprocessor;

  /** Parent entity to add or remove relationships with. */
  parentEntityId: EntityId;
};

export const EditSubprocessorButton = (props: Props) => {
  const {isOpen, onOpen, onClose} = useDisclosure();

  return (
    <>
      <IconButton
        aria-label="Edit"
        icon={<Icon as={PencilIcon} />}
        onClick={onOpen}
        bgColor="white"
        variant="outline"
        color="gray.500"
      />
      <AddOrEditSubprocessorModal
        kind="edit"
        subprocessor={props.subprocessor}
        isOpen={isOpen}
        onClose={onClose}
        graph={props.graph}
        parentEntityId={props.parentEntityId}
      />
    </>
  );
};
