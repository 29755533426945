import {param, typedQuery} from "../../typing.ts";
import api from "../../../api/index.ts";
import {EsignatureProviderConnectorType, EsignatureProviderId} from "../../../Types.ts";

export default [
  typedQuery(["vendorToolkit", "esignatureProviderConnectors"], async () => {
    return await api.vendorToolkit.esignatureProviders.listConnectors();
  }),
  typedQuery(
    [
      "vendorToolkit",
      "esignatureProviderConnector",
      param<EsignatureProviderConnectorType>("esignatureProviderConnectorType"),
    ],
    async context => {
      return await api.vendorToolkit.esignatureProviders.getConnector(context.queryKey[2]);
    },
  ),
  typedQuery(["vendorToolkit", "esignatureProviders"], async () => {
    return await api.vendorToolkit.esignatureProviders.list();
  }),
  typedQuery(
    ["vendorToolkit", "esignatureProvider", param<EsignatureProviderId>("esignatureProviderId")],
    async context => {
      return await api.vendorToolkit.esignatureProviders.get(context.queryKey[2]);
    },
  ),
];
