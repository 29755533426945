import {Link as RouterLink, useMatches} from "react-router-dom";
import {Box, Breadcrumb, BreadcrumbItem, Icon, IconButton, LinkBox, Text} from "@chakra-ui/react";
import {ArrowsRightLeftIcon, HomeIcon} from "@heroicons/react/20/solid";
import {PathSegmentBreadcrumb, PathSegmentBreadcrumbProps} from "../Header/BreadcrumbNav";
import {resetQueries, useQueryData} from "../state";

function switchAccounts() {
  sessionStorage.clear();
  resetQueries();
}

const BreadcrumbNav = () => {
  const {multi_account} = useQueryData({queryKey: ["whoAmI"]});
  const matches = useMatches() as PathSegmentBreadcrumbProps[];
  return (
    <Box
      borderBottom="1px solid"
      borderBottomColor="blue.700"
      bg="blue.600"
      color="white"
      display="flex"
      alignItems="center"
      justifyContent="space-between"
    >
      <Breadcrumb
        separator={<Text color="blue.200">/</Text>}
        spacing="8px"
        color="blue.50"
        px="4"
        py="3"
        fontSize="md"
        fontWeight="medium"
      >
        <BreadcrumbItem h="29px">
          <LinkBox as={RouterLink} to="/extension">
            <Icon as={HomeIcon} display="block" />
          </LinkBox>
        </BreadcrumbItem>
        {matches.map(
          m =>
            (m?.handle?.crumb || m?.handle?.Crumb) && (
              <BreadcrumbItem key={m.pathname}>
                <PathSegmentBreadcrumb {...(m as PathSegmentBreadcrumbProps)} />
              </BreadcrumbItem>
            ),
        )}
      </Breadcrumb>
      {multi_account && (
        <IconButton
          aria-label="Switch accounts"
          icon={<Icon as={ArrowsRightLeftIcon} />}
          onClick={switchAccounts}
          variant="ghost"
          colorScheme="white"
        />
      )}
    </Box>
  );
};

export default BreadcrumbNav;
