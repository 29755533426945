import {Highlight, Icon, LinkBox, Table, Tbody, Td, Text, Th, Thead, Tr} from "@chakra-ui/react";
import {Question, QuestionnaireId, QuestionStatus} from "../../../../../Types.ts";
import {ActionBar, ActionBarSearch} from "../../../../../components/ActionBar.tsx";
import {memo} from "react";
import {
  facetedSearchQuestion,
  getQuestionsRelevantOwners,
  QuestionFilters,
} from "../../../../components/Filters/index.tsx";
import {useFacetedSearch} from "../../../../../hooks/search.ts";
import {useFilterState} from "../../../../../hooks/filterState.ts";
import {QuestionResponse} from "../../../Questionnaires/QuestionnaireViewer/Export/ExportPage.tsx";
import {useQueryData} from "../../../../../state/index.ts";
import {useLayerType} from "../../../../../hooks/layerType.ts";
import {Link as RouterLink, useOutletContext} from "react-router-dom";
import {ChevronRightIcon} from "@heroicons/react/20/solid";
import {TabOutletContext} from "../../../Questionnaires/QuestionnaireViewer/index.tsx";
import LinkOverlay from "../../../../../components/LinkOverlay.tsx";
import FilterOwners from "../../../../components/Filters/FilterOwners.tsx";
import FilterBanner from "../../../../components/Filters/FilterBanner.tsx";

const QuestionRow = memo(
  ({value, queries, questionnaireId}: {value: Question; queries: string[]; questionnaireId: QuestionnaireId}) => {
    const [layerType] = useLayerType();
    return (
      <LinkBox as={Tr} transform="scale(1)" fontSize="md" _hover={{bg: "gray.50", cursor: "pointer"}}>
        <Td>
          <LinkOverlay
            as={RouterLink}
            to={`/vendor-toolkit/library/historical-questionnaires/${questionnaireId}/questions/${value.question_id}`}
          />
          <Text fontSize="md" fontWeight="600">
            <Highlight query={queries} styles={{bg: "yellow.200"}}>
              {value.text}
            </Highlight>
          </Text>
        </Td>
        <Td>
          <QuestionResponse question={value} layerType={layerType} />
        </Td>
        <Td>
          <Icon as={ChevronRightIcon} fontSize="24" color="gray.500" />
        </Td>
      </LinkBox>
    );
  },
);

const DEFAULT_FILTERS = {
  owners: ["ASSIGNED_TO_ME", "ASSIGNED_TO_OTHERS", "UNASSIGNED"],
  statuses: [QuestionStatus.Respond, QuestionStatus.Review, QuestionStatus.Complete],
};

const QuestionsPage = memo(() => {
  const {questionnaire} = useOutletContext<TabOutletContext>();
  const [layerType] = useLayerType();
  const whoami = useQueryData({queryKey: ["whoAmI"]});

  const {filters, setFilter, clearFilters, filterCount} = useFilterState<QuestionFilters>(
    "historicalQuestionFilters",
    DEFAULT_FILTERS,
  );
  const {query, queries, setQuery, filterGroups} = useFacetedSearch(
    q => q.text,
    facetedSearchQuestion(filters, layerType, whoami.associated_owners),
    [filters, whoami.associated_owners],
  );

  const {result, counts} = filterGroups(questionnaire.sections, "questions");

  const relevantUsers = getQuestionsRelevantOwners(questionnaire, whoami.user_owner!, layerType);
  const actionButtons = (
    <FilterOwners
      counts={counts.owner}
      owners={filters.owners}
      setOwners={newOwners => setFilter("owners", newOwners)}
      relevantOwners={relevantUsers}
    />
  );

  return (
    <>
      <ActionBar actionButtons={actionButtons}>
        <ActionBarSearch value={query} onChange={e => setQuery(e.target.value)} />
      </ActionBar>
      <FilterBanner filterCount={filterCount} clearFilters={clearFilters} />
      <Table style={{borderCollapse: "separate", borderSpacing: "0"}} size="lg" layout="fixed">
        <Thead bg="white" borderBottom="10px solid" borderBottomColor="red.100">
          <Tr>
            <Th width="40%" color="gray.500">
              Question
            </Th>
            <Th width="55%" color="gray.500">
              Response
            </Th>
            <Th />
          </Tr>
        </Thead>
        <Tbody>
          {result.flatMap(section =>
            section.questions.map(question => (
              <QuestionRow
                key={question.question_id}
                questionnaireId={questionnaire.questionnaire_id}
                value={question}
                queries={queries}
              />
            )),
          )}
        </Tbody>
      </Table>
    </>
  );
});

export default QuestionsPage;
