import {Box, Heading, Img, Stack, Text, Flex, Link, Show, useStyleConfig, useDisclosure} from "@chakra-ui/react";
import ContentWrapper from "./components/ContentWrapper";
import {Outlet, ScrollRestoration} from "react-router-dom";

import {useTrustCenterConfig} from "./config";
import {NavTabs} from "./Nav";
import TopNav from "./TopNav";

import LogoWhite from "../../assets/logo/logo-white-text.svg";
import Pages from "./Pages";
import StickyHeader from "./components/StickyHeader/Header";
import {useTrustCenterContext} from "./hooks/trustCenterContext";
import SearchModal from "./components/SearchModal";

const Hero = () => {
  const {hero} = useTrustCenterConfig();
  const styles = useStyleConfig("Hero");
  return (
    <Box __css={styles}>
      <ContentWrapper>
        <Stack py={12} maxW={["100%", "100%", "50%"]}>
          <Heading variant="hero">{hero.heading}</Heading>
          {hero.subheading && <Text>{hero.subheading}</Text>}
        </Stack>
      </ContentWrapper>
    </Box>
  );
};

const PoweredByPlatformed = () => (
  <Box
    bg="gray.800"
    color="white"
    borderTop="1px"
    borderTopColor="blackAlpha.50"
    py={2}
    position="fixed"
    bottom="0"
    w="full"
  >
    <ContentWrapper>
      <Flex as={Link} href="https://platformed.com" target="_blank" align="center" gap={2} justify="center">
        Powered by <Img src={LogoWhite} h="5" />
      </Flex>
    </ContentWrapper>
  </Box>
);

const Page = () => {
  const context = useTrustCenterContext();
  const searchModal = useDisclosure();

  return (
    <>
      <Box minH="100vh" position="relative" pb="41px">
        <StickyHeader header={<TopNav onClickSearch={searchModal.onOpen} />}>
          <Hero />
          <StickyHeader
            header={
              <Show above="md">
                <NavTabs pages={Pages} onClickSearch={searchModal.onOpen} />
              </Show>
            }
          >
            <ScrollRestoration />
            <Outlet context={context} />
            <PoweredByPlatformed />
          </StickyHeader>
        </StickyHeader>
      </Box>
      {searchModal.isOpen ? <SearchModal isOpen={searchModal.isOpen} onClose={searchModal.onClose} /> : null}
    </>
  );
};

export default Page;
