import {Box, Button, ButtonProps, Flex, Highlight, Input, Text, Textarea} from "@chakra-ui/react";

export const Question = ({
  editable,
  skip,
  number,
  text,
  setNumber,
  setText,
  leftActions,
  rightActions,
  queries = [],
}: {
  editable?: boolean;
  skip?: boolean;
  number?: string;
  text: string;
  setNumber?: (number: string) => void;
  setText?: (text: string) => void;
  leftActions?: React.ReactElement;
  rightActions?: React.ReactElement;
  queries?: string[];
}) => (
  <Box bg="white">
    {editable && setNumber && setText ? (
      <Box p={4} fontSize="md">
        <Input
          mb={4}
          value={number}
          maxW="50%"
          color="blue.500"
          size="sm"
          fontWeight="700"
          onChange={e => setNumber(e.target.value)}
        />
        <Textarea h="40" p={4} value={text} onChange={e => setText(e.target.value)} fontWeight="500" color="gray.900" />
      </Box>
    ) : (
      <Box p={4} fontSize="md" py={6} opacity={skip ? 0.3 : 1}>
        <Text fontWeight="700" color="blue.400" mb={2} fontSize="sm">
          {number ?? ""}
        </Text>
        <Text fontWeight="500" color="gray.900" whiteSpace="pre-wrap">
          <Highlight query={queries} styles={{bg: "yellow.200"}}>
            {text}
          </Highlight>
        </Text>
      </Box>
    )}
    {(leftActions || rightActions) && (
      <Flex align="center" bg="gray.50" roundedBottom="md" borderTop="1px solid" borderColor="gray.200" px={2}>
        <Box flex={1}>{leftActions}</Box>
        <Box>{rightActions}</Box>
      </Flex>
    )}
  </Box>
);

export const QuestionActionButton = (props: ButtonProps) => (
  <Button flex={1} rounded="none" size="sm" variant="ghost" {...props} />
);
