import {NewNotificationChannel} from "./NewNotificationChannel.tsx";
import {BellIcon} from "@heroicons/react/24/outline";
import {Route} from "react-router-dom";
import {ConfigureNotificationChannel} from "./ConfigureNotificationChannel.tsx";
import {GenericOauthOptions} from "../../../../hooks/genericOauth.ts";
import {getPublicCredentials} from "../../../../utils/environment.ts";
import {ExternalAuthorizationProvider} from "../../../../Types.ts";
import NotificationChannelsPage from "./NotificationChannelsPage.tsx";

export const SLACK_NOTIFICATION_OAUTH_OPTIONS: GenericOauthOptions = {
  authUrl: "https://slack.com/oauth/v2/authorize",
  clientId: getPublicCredentials().slack_client_id,
  scope: "chat:write,im:history,im:read,im:write,team:read,users:read,users:read.email",
  provider: ExternalAuthorizationProvider.Slack,
  extra: {},
  purpose: "Send notifications to Slack",
};

export default {
  title: "Notification channels",
  path: "notification-channels",
  icon: BellIcon,
  routes: (
    <>
      <Route path="" element={<NotificationChannelsPage />} />
      <Route path="new/:connectorType" element={<NewNotificationChannel />} />
      <Route path="configure/:notificationChannelId" element={<ConfigureNotificationChannel />} />
    </>
  ),
};
