import {useCallback} from "react";
import {Document, DocumentAuthority, File as FileT} from "../../../../../Types.ts";
import api from "../../../../../api/index.ts";
import {useOutletContext} from "react-router-dom";
import {TabOutletContext} from "../Actions/Actions.tsx";
import {ActionBar} from "../../../../../components/ActionBar.tsx";
import {UploadButton} from "../../../../../components/fileUploads/UploadButton.tsx";
import {FileUpload} from "../../../../../hooks/fileUpload.ts";
import {SUPPORTED_DOCUMENT_FORMATS} from "../../../../../components/fileUploads/index.tsx";
import {PreviewFile} from "../PreviewFile.tsx";
import {Flex, useDisclosure} from "@chakra-ui/react";
import {usePromiseState} from "../../../../../hooks/promiseState.ts";
import {useIsAdmin} from "../../../../../hooks/roles.ts";
import {useQueryData} from "../../../../../state/index.ts";
import {router} from "../../../../../router/index.tsx";
import {DeleteModalIconButton} from "../../../../../components/DeleteModalButton.tsx";

export const DeleteFileButton = ({doc}: {doc: Document}) => {
  const {isOpen, onOpen, onClose} = useDisclosure();
  const [deletingFile, deleteFile] = usePromiseState(async () => {
    await api.vendorToolkit.documents.deleteDocument(doc.document_id);
    router!.navigate("../../");
  }, [doc.document_id]);

  return (
    <DeleteModalIconButton
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      delete_={deleteFile}
      deleting={deletingFile.inProgress}
      modalHeader="Delete file"
      modalBody={`Are you sure you want to delete ${doc.name}?`}
    />
  );
};

const FilePage = () => {
  const {document} = useOutletContext<TabOutletContext>();
  const isAdmin = useIsAdmin();
  const whoami = useQueryData({queryKey: ["whoAmI"]});
  const canDelete = whoami.user_owner!.owner_id === document.owner?.owner_id;
  const updateFile = useCallback(
    async (file?: FileT) => {
      if (file) {
        await api.vendorToolkit.documents.updateContent(document.document_id, {
          authority: DocumentAuthority.File,
          file_id: file.file_id,
        });
      }
    },
    [document.document_id],
  );

  const isUploadedFile = document.authority === DocumentAuthority.File;

  return (
    <>
      <ActionBar>
        <Flex flex={1} align="center" justifyContent={isUploadedFile ? "space-between" : "end"}>
          {isUploadedFile && (
            <UploadButton Cls={FileUpload} onUpload={updateFile} accept={SUPPORTED_DOCUMENT_FORMATS}>
              Upload new version
            </UploadButton>
          )}
          {(isAdmin || canDelete) && <DeleteFileButton doc={document} />}
        </Flex>
      </ActionBar>
      {document.file && <PreviewFile file={document.file} />}
    </>
  );
};

export default FilePage;
