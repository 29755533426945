import {BellIcon, DevicePhoneMobileIcon, KeyIcon, UserCircleIcon} from "@heroicons/react/24/outline";

import {Authentication} from "./Authentication";
import Profile from "./Profile";
import Notifications from "./Notifications";
import {SecondFactor} from "./SecondFactor";
import {RouteTab, tabbedRoute} from "../../components/RouterTabs/index.tsx";
import Page from "../../components/Page.tsx";
import {Heading} from "@chakra-ui/react";
import TabsOutlet from "../../components/RouterTabs/Outlet.tsx";

const tabs: RouteTab[] = [
  {title: "Profile", path: "profile", icon: UserCircleIcon, element: <Profile />},
  {title: "Authentication", path: "authentication", icon: KeyIcon, element: <Authentication />},
  {title: "Second factor", path: "two-fa", icon: DevicePhoneMobileIcon, element: <SecondFactor />},
  {title: "Notifications", path: "notifications", icon: BellIcon, element: <Notifications />},
];

const routes = tabbedRoute({
  path: "user-settings",
  title: "User settings",
  tabs,
  element: (
    <Page title={"User settings"} display="flex" flexDirection="column" borderLeft="none" borderRight="none">
      <Heading fontSize="32" fontWeight="600" mb="10">
        User settings
      </Heading>
      <TabsOutlet />
    </Page>
  ),
});

export default routes;
