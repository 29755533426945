import {Box, Flex, FlexProps, Text} from "@chakra-ui/react";
import {ReactNode, useCallback, useState} from "react";
import {Accept, DropzoneOptions, ErrorCode, FileRejection, useDropzone} from "react-dropzone";

function formatFileSize(size: number) {
  let exp = 0;
  while (size > 1024) {
    exp += 1;
    size /= 1024;
  }
  const exps = ["B", "kB", "MB", "GB", "TB"];
  return `${size.toPrecision(3)} ${exps[exp]}`;
}

const errorMessages: {[code in ErrorCode]: (opts: DropzoneOptions) => string} = {
  "file-invalid-type": () => "Unsupported file type.",
  "file-too-large": opts => `File is too large (max ${formatFileSize(opts.maxSize!)}).`,
  "file-too-small": opts => `File is too small (min ${formatFileSize(opts.minSize!)}).`,
  "too-many-files": opts => `Too many files (max ${opts.maxFiles}).`,
};

const Dropzone = ({
  children,
  onDropAccepted,
  onDropRejected,
  accept,
  maxFiles,
  minSize,
  maxSize,
  ...props
}: {
  children: React.ReactNode | ((isDragActive: boolean) => React.ReactNode);
  accept?: Accept;
  maxFiles?: number;
  minSize?: number;
  maxSize?: number;
  onDropRejected?: (files: FileRejection[]) => ReactNode;
  onDropAccepted: (files: File[]) => void;
} & Omit<FlexProps, "children" | "onDrop">) => {
  const [errorNode, setErrorNode] = useState<ReactNode>(null);
  const dropAccepted = useCallback(
    (files: File[]) => {
      setErrorNode(null);
      onDropAccepted(files);
    },
    [onDropAccepted],
  );
  const dropRejected = useCallback(
    (files: FileRejection[]) => {
      const errors = files
        .flatMap(file =>
          file.errors.map(
            error =>
              `${file.file.name}: ${
                errorMessages[error.code as ErrorCode]({minSize, maxSize, maxFiles}) ?? "Unknown error."
              }`,
          ),
        )
        .map((e, i) => (
          <Text key={i} color="red.500" fontSize="sm">
            {e}
          </Text>
        ));
      const extraError = onDropRejected && onDropRejected(files);
      setErrorNode(
        <Box mt="2">
          {errors}
          {extraError}
        </Box>,
      );
    },
    [onDropRejected, minSize, maxSize, maxFiles],
  );
  const {getRootProps, getInputProps, isDragActive} = useDropzone({
    onDropRejected: dropRejected,
    onDropAccepted: dropAccepted,
    accept,
    maxFiles,
    multiple: maxFiles !== 1,
    maxSize,
    minSize,
  });
  return (
    <Box>
      <Flex
        role="group"
        cursor="pointer"
        borderWidth="3px"
        borderStyle={isDragActive ? "solid" : "dotted"}
        borderColor={isDragActive ? "blue.500" : "gray.200"}
        borderRadius="lg"
        bg={isDragActive ? "blue.50" : "gray.50"}
        py={2}
        px={4}
        align="center"
        justify="center"
        direction="column"
        fontWeight="600"
        fontSize="md"
        color="gray.600"
        {...getRootProps()}
        {...props}
      >
        <input {...getInputProps()} />
        {typeof children === "function" ? children(isDragActive) : children}
      </Flex>
      {errorNode}
    </Box>
  );
};

export default Dropzone;
