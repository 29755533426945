import {Box, Spinner, Text} from "@chakra-ui/react";
import {SingleDatepicker, SingleDatepickerProps} from "../lib/chakra-dayzed-datepicker/src";
import {formatNaiveDate, formatISO, parseNaiveDate, parseISO} from "../utils/date";
import {ApiDate, ApiDateTime} from "../Types.ts";
import {usePromiseState} from "../hooks/promiseState.ts";
import * as datefns from "date-fns";
import {useMemo} from "react";

const StyledSingleDatepicker = (props: SingleDatepickerProps) => (
  <SingleDatepicker
    propsConfigs={{
      dayOfMonthBtnProps: {
        defaultBtnProps: {
          color: "gray.700",
          sx: {"&:nth-of-type(7n-6), &:nth-of-type(7n)": {color: "gray.500"}},
          _hover: {bg: "blue.400", color: "white"},
        },
        todayBtnProps: {bg: "gray.100", fontWeight: "700"},
        selectedBtnProps: {backgroundColor: "blue.500", color: "white !important", fontWeight: "700"},
      },
      popoverCompProps: {
        popoverContentProps: {border: "none"},
        popoverBodyProps: {p: 0},
      },
      dateNavBtnProps: {
        colorScheme: "blue",
      },
      weekdayLabelProps: {
        sx: {"&:nth-of-type(7n-6), &:nth-of-type(7n)": {color: "gray.500"}},
        py: 1,
      },
    }}
    configs={{
      dateFormat: "dd MMM yyyy",
    }}
    {...props}
  />
);
const EditableDate = ({
  date,
  onDateChange,
  loading,
  textPrefix,
  missingDateText,
  maxDate,
}: {
  date?: Date;
  onDateChange: (date: Date) => void;
  loading?: boolean;
  textPrefix?: string;
  missingDateText?: string;
  maxDate?: Date;
}) => (
  <>
    <StyledSingleDatepicker
      name="date-input"
      date={date}
      onDateChange={onDateChange}
      textPrefix={textPrefix}
      missingDateText={missingDateText}
      maxDate={maxDate}
    />
    {loading && (
      <Box position="absolute" right="2" top="2">
        <Spinner color="gray.500" size="sm" m="1" />
      </Box>
    )}
  </>
);

const UneditableDate = ({date}: {date?: Date}) => <Text fontSize="md">{date ? datefns.format(date, "PP") : "-"}</Text>;

export const NaiveDateUI = ({
  date,
  onDateChange,
  textPrefix,
  missingDateText,
  maxDate,
}: {
  date?: ApiDate;
  onDateChange?: (date: ApiDate) => Promise<void> | void;
  textPrefix?: string;
  missingDateText?: string;
  maxDate?: Date;
}) => {
  const parsedDate = useMemo(() => parseNaiveDate(date), [date]);

  const [changingDate, changeDate] = usePromiseState(
    async (date: Date) => {
      const formattedDate = formatNaiveDate(date);

      if (onDateChange) {
        await onDateChange(formattedDate);
      }
    },
    [onDateChange],
  );

  if (onDateChange) {
    return (
      <EditableDate
        onDateChange={changeDate}
        date={parsedDate}
        loading={changingDate.inProgress}
        textPrefix={textPrefix}
        missingDateText={missingDateText}
        maxDate={maxDate}
      />
    );
  } else {
    return <UneditableDate date={parsedDate} />;
  }
};

// 'DateUI' so as not to overlap with the commonly used type
export const DateUI = ({
  date,
  onDateChange,
  textPrefix,
  missingDateText,
  maxDate,
}: {
  date?: ApiDateTime;
  onDateChange?: (date: ApiDateTime) => Promise<void> | void;
  textPrefix?: string;
  missingDateText?: string;
  maxDate?: Date;
}) => {
  const parsedDate = useMemo(() => parseISO(date), [date]);

  const [changingDate, changeDate] = usePromiseState(
    async (date: Date) => {
      const formattedDate = formatISO(date);

      if (onDateChange) {
        await onDateChange(formattedDate);
      }
    },
    [onDateChange],
  );

  if (onDateChange) {
    return (
      <EditableDate
        onDateChange={changeDate}
        date={parsedDate}
        loading={changingDate.inProgress}
        textPrefix={textPrefix}
        missingDateText={missingDateText}
        maxDate={maxDate}
      />
    );
  } else {
    return <UneditableDate date={parsedDate} />;
  }
};
