import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Textarea,
} from "@chakra-ui/react";
import {memo} from "react";
import {useModalState} from "../../../hooks/modalState.ts";
import api from "../../../api/index.ts";
import {Team, TeamMin} from "../../../Types.ts";
import {useValidatedPromiseState, useValidationState} from "../../../hooks/validationState.ts";

export const CreateEditTeamModal = memo(
  ({
    type,
    team,
    isOpen,
    onClose,
    onCreate,
  }: {
    type: string;
    isOpen: boolean;
    onClose: () => void;
    team?: Team;
    onCreate?: (team: TeamMin) => void;
  }) => {
    const create = type === "Create" && !team;

    const [name, setName] = useModalState(isOpen, create ? "" : team!.name);
    const [description, setDescription] = useModalState(isOpen, create ? "" : team!.description);
    const [validationErrors, setValidationErrors] = useValidationState({name});

    const [creatingOrUpdating, createOrUpdate] = useValidatedPromiseState(
      async () => {
        if (create) {
          const team = await api.teams.create({
            name,
            description,
          });
          onCreate && onCreate(team);
        } else {
          await api.teams.updateName(team!.team_id, name);
          if (description) {
            await api.teams.updateDescription(team!.team_id, description);
          }
        }

        onClose();
      },
      [create, onClose, name, description, onCreate, team],
      setValidationErrors,
    );

    return (
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent as="form" onSubmit={createOrUpdate}>
          <ModalHeader>{(create ? "Create" : "Edit") + " Team"}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing={6}>
              <FormControl isRequired isInvalid={validationErrors.name !== undefined}>
                <FormLabel>Name</FormLabel>
                <Input value={name} onChange={e => setName(e.target.value)} />
                {validationErrors.name && validationErrors.name[0] && (
                  <FormErrorMessage>{validationErrors.name[0].message}</FormErrorMessage>
                )}
              </FormControl>
              <FormControl>
                <FormLabel>Description</FormLabel>
                <Textarea value={description} onChange={e => setDescription(e.target.value)} />
              </FormControl>
              {creatingOrUpdating.lastError ? (
                <Alert status="error" mt="12">
                  <AlertIcon boxSize="40px" />
                  <Box>
                    <AlertTitle fontSize="md">Error creating team</AlertTitle>
                    <AlertDescription fontSize="md">{`${creatingOrUpdating.lastError}`}</AlertDescription>
                  </Box>
                </Alert>
              ) : null}
            </Stack>
          </ModalBody>
          <ModalFooter>
            <HStack spacing="3">
              <Button variant="outline" onClick={onClose}>
                Cancel
              </Button>
              <Button
                colorScheme="blue"
                type={"submit"}
                isDisabled={creatingOrUpdating.inProgress}
                isLoading={creatingOrUpdating.inProgress}
              >
                {create ? "Create" : "Save"}
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  },
);
