import {FormControl, FormErrorMessage, RadioGroup, Stack, Radio, Button, Box} from "@chakra-ui/react";
import {memo} from "react";
import {usePromiseState} from "../../../../hooks/promiseState.ts";

type ResponseYesNoProps = {
  value: boolean | undefined;
  onCommit: (value: boolean | undefined) => Promise<void>;
  isDisabled?: boolean;
};

const ResponseYesNo = memo(({onCommit, value, isDisabled}: ResponseYesNoProps) => {
  const [committing, commit] = usePromiseState(
    async (newValue: string) => {
      await onCommit(newValue === "unset" ? undefined : newValue === "yes");
    },
    [onCommit],
  );
  return (
    <FormControl isInvalid={!!committing.lastError}>
      <RadioGroup
        onChange={commit}
        value={value == null ? "unset" : value ? "yes" : "no"}
        size="lg"
        isDisabled={committing.inProgress || isDisabled}
      >
        <Stack direction="row" gap={4}>
          <Radio value="yes">Yes</Radio>
          <Radio value="no">No</Radio>
          <Box>
            <Button
              mt={1}
              ml={8}
              colorScheme="black"
              variant="link"
              onClick={() => commit("unset")}
              isDisabled={committing.inProgress || isDisabled}
              isLoading={committing.inProgress}
            >
              Reset
            </Button>
          </Box>
        </Stack>
      </RadioGroup>
      <FormErrorMessage>{committing.lastError?.toString()}</FormErrorMessage>
    </FormControl>
  );
});

export default ResponseYesNo;
