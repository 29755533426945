import {QueryFilters, resetQueries} from "..";
import api from "../../api";
import {RedirectError} from "../../api/jsonApi";
import {RedirectTarget, redirectRestoringUrl} from "../../utils/auth";
import {param, typedQuery} from "../typing";

function authenticatedQueries(): QueryFilters[] {
  return [
    {
      predicate(query) {
        return query.queryKey.length !== 1 || query.queryKey[0] !== "unauthenticated";
      },
    },
  ];
}

export default [
  typedQuery(["whoAmI"], async () => {
    // Important to use "reset" as "invalidate" doesn't reset error states
    await resetQueries([{queryKey: ["unauthenticated"]}]);
    return await api.auth.whoAmI();
  }),
  typedQuery(["unauthenticated"], async () => {
    try {
      // Important to use "reset" as "invalidate" doesn't reset error states
      await resetQueries(authenticatedQueries());
      await api.auth.whoAmI();

      throw new RedirectError(redirectRestoringUrl());
    } catch (ex) {
      if (ex instanceof RedirectError && ex.response.target === RedirectTarget.Login) {
        return {};
      } else {
        throw ex;
      }
    }
  }),
  typedQuery(["accounts"], async () => {
    return await api.auth.listAccounts();
  }),
  typedQuery(["consent", param("externalRedirect")], async context => {
    return await api.accessTokens.consent({external_redirect: context.queryKey[1]});
  }),
];
