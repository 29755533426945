import {Divider, Heading, HStack, Icon, Stack} from "@chakra-ui/react";
import {ClipboardDocumentListIcon, FolderIcon, InboxIcon, PaperAirplaneIcon} from "@heroicons/react/24/outline";
import {useRef} from "react";
import BasePageSideBar from "../../../../components/BasePageSideBar.tsx";
import {useSideBarHeight} from "../../../../hooks/useSideBarHeight.ts";

function SideBar() {
  const ref = useRef<HTMLDivElement | null>(null);

  useSideBarHeight(ref);

  return (
    <Stack
      ref={ref}
      flex="0 0 auto"
      position="sticky"
      width="360px"
      alignItems="stretch"
      borderRight="1px solid"
      borderColor="gray.100"
      gap="0"
      overflow="hidden"
      top={12}
      background="white"
    >
      <HStack height="64px" px="6" spacing="4" flex="0 0 auto">
        <Icon boxSize={6} as={InboxIcon} />
        <Heading size="md">Notification center</Heading>
      </HStack>
      <Divider mb="4" />
      <BasePageSideBar href="pending-updates" label="Pending" icon={FolderIcon} />
      <BasePageSideBar href="sent-updates" label="Sent" icon={PaperAirplaneIcon} />
      <Divider mt="4" />
      <BasePageSideBar href="mailing-list" label="Mailing list" icon={ClipboardDocumentListIcon} />
    </Stack>
  );
}

export default SideBar;
