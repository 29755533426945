import {QueryFunctionContext, QueryKey, hashKey} from "@tanstack/react-query";
import {ParamSymbol, AnyQueryReturnType, TypedQuery} from "./typing";

const Eof = Symbol();

type QueryMap = {
  [arg: string]: QueryMap | undefined;
  [ParamSymbol]?: QueryMap;
  [Eof]?: TypedQuery;
};

function hashQuerySegment(segment: unknown): string {
  return hashKey([segment]);
}

function addToQueryMap(map: QueryMap, queryKeyPattern: QueryKey, query: TypedQuery): QueryMap {
  if (queryKeyPattern.length === 0) {
    map[Eof] = query;
  } else if (queryKeyPattern[0] === ParamSymbol) {
    map[ParamSymbol] = addToQueryMap({}, queryKeyPattern.slice(1), query);
  } else {
    const k = hashQuerySegment(queryKeyPattern[0]);
    map[k] = addToQueryMap(map[k] ?? {}, queryKeyPattern.slice(1), query);
  }
  return map;
}

function findInQueryMap(map: QueryMap | undefined, queryKey: QueryKey): TypedQuery | undefined {
  if (map === undefined) {
    return undefined;
  } else if (queryKey.length === 0) {
    return map[Eof];
  } else {
    const k = hashQuerySegment(queryKey[0]);
    const rest = queryKey.slice(1);
    return findInQueryMap(map[k], rest) ?? findInQueryMap(map[ParamSymbol], rest);
  }
}

export function buildRouterQuery<TAnyQuery extends TypedQuery>(queries: readonly TAnyQuery[]) {
  // Build a tree of queries so can quickly find one which matches a target query key
  const queryMap: QueryMap = {};
  for (const query of queries) {
    addToQueryMap(queryMap, query.queryKeyPattern, query);
  }

  return function routerQuery<TQueryKey extends QueryKey>(
    context: QueryFunctionContext<TQueryKey, never>,
  ): AnyQueryReturnType<TAnyQuery, TQueryKey> {
    const query = findInQueryMap(queryMap, context.queryKey);
    if (query) {
      return query.query(context) as AnyQueryReturnType<TAnyQuery, TQueryKey>;
    } else {
      throw new Error(`No query matches: ${context.queryKey}`);
    }
  };
}
