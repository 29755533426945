import api from "../api";
import {AbortableAsyncOperation, useAsyncOperations} from "./asyncOperation";
import {File as FileT, SharingClassification, StandaloneFile, UploadOptions} from "../Types";
import {useMemo} from "react";

const FILE_UPLOAD_LIMIT_MB = 30;

export type FileUploadBaseState<TComplete> =
  | {
      id: "Uploading";
      progress: number;
    }
  | {
      id: "Uploaded";
      result: TComplete;
    }
  | {id: "Error"; error: unknown};

export abstract class FileUploadBase<TComplete = any> extends AbortableAsyncOperation<FileUploadBaseState<TComplete>> {
  #file: File;
  #options: UploadOptions;
  constructor(file: File, options: UploadOptions) {
    super({id: "Uploading", progress: 0});
    this.#file = file;
    this.#options = options;
  }
  get file() {
    return this.#file;
  }
  async run() {
    if (this.#file.size > FILE_UPLOAD_LIMIT_MB * 1024 * 1024) {
      throw new Error(`File too large (max ${FILE_UPLOAD_LIMIT_MB}MB)`);
    }
    const file = await api.files.upload(
      this.#file,
      this.#options,
      progress => {
        this.state = {id: "Uploading", progress};
      },
      this.signal,
    );
    const result = await this.complete(file);
    this.state = {id: "Uploaded", result};
  }
  protected abstract complete(file: FileT): Promise<TComplete>;
  protected errorState(error: unknown): FileUploadBaseState<TComplete> {
    return {id: "Error", error};
  }
}

export class FileUpload extends FileUploadBase<FileT> {
  protected async complete(file: FileT): Promise<FileT> {
    return file;
  }
}

export class StandaloneFileUpload extends FileUploadBase<StandaloneFile> {
  protected async complete(file: FileT): Promise<StandaloneFile> {
    return await api.files.createStandalone({
      file_id: file.file_id,
      sharing_classification: SharingClassification.External,
    });
  }
}

export type FileUploadResult<TFileUpload> = TFileUpload extends FileUploadBase<infer TResult> ? TResult : never;

// Wrap the in-progress file upload and update the uploaded item as they complete
export function useFileUploads(uploads: FileUploadBase[]): [FileT[], boolean] {
  const uploadStates = useAsyncOperations(uploads);
  const uploaded = useMemo(() => {
    const res = [];
    for (const uploadState of uploadStates) {
      if (uploadState.id === "Uploaded") {
        res.push(uploadState.result);
      }
    }
    return res;
  }, [uploadStates]);
  const isUploading = !uploadStates.every(s => s.id === "Uploaded");

  return [uploaded, isUploading];
}
