import {useMemo} from "react";
import BaseFactsPage from "../components/BaseFactsPage";
import {findScopeById, getNodeName, GLOBAL_SCOPE_ID, useHierarchy} from "../utility/hierarchy";

export type GlobalScopePageProps = {
  /**
   * - If `true`, show all facts in the scope and its descendent sections.
   * - If `false`, only show facts at the top level (uncategorized)
   */
  recurse?: boolean;
};

function GlobalScopePage(props: GlobalScopePageProps) {
  const recurse = props.recurse ?? true;
  const hierarchy = useHierarchy();
  const scope = useMemo(() => findScopeById(hierarchy, GLOBAL_SCOPE_ID)!, [hierarchy]);

  return <BaseFactsPage title={getNodeName(scope)} libraryContext={{hierarchy, parent: scope, recurse}} />;
}

export default GlobalScopePage;
