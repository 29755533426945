import {AccountUser, Role, RoleId, UserId} from "../Types";
import {invalidateQueries} from "../state";
import jsonApi from "./jsonApi";

export async function list() {
  return await jsonApi.get<Role[]>("/roles");
}

export async function listMembers(roleId: RoleId) {
  return await jsonApi.get<AccountUser[]>(`/roles/${roleId}/members`);
}

export async function addMember(roleId: RoleId, userId: UserId) {
  const res = await jsonApi.post<"ok">(`/roles/${roleId}/members`, userId);
  await invalidateQueries([
    {queryKey: ["roles"]},
    {queryKey: ["roleMembers", roleId]},
    {queryKey: ["registeredUsers"]},
  ]);
  return res;
}

export async function removeMember(roleId: RoleId, userId: UserId) {
  const res = await jsonApi.delete_<"ok">(`/roles/${roleId}/members/${userId}`);
  await invalidateQueries([
    {queryKey: ["roles"]},
    {queryKey: ["roleMembers", roleId]},
    {queryKey: ["registeredUsers"]},
  ]);
  return res;
}
