import {DocumentDuplicateIcon} from "@heroicons/react/20/solid";
import DocumentationPage from "./DocumentationPage.tsx";
import {RouteTab} from "../../../../../components/RouterTabs/index.tsx";

export default {
  title: "Supporting documentation",
  path: "documentation",
  icon: DocumentDuplicateIcon,
  element: <DocumentationPage />,
} satisfies RouteTab;
