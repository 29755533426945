import {Activity, ActivityStreamId, CreateComment, ListActivitiesArgs} from "../Types";
import {invalidateQueries} from "../state";
import jsonApi from "./jsonApi";

export async function list(activityStreamId: ActivityStreamId, args: ListActivitiesArgs) {
  return await jsonApi.get<Activity[]>(`/activity_streams/${activityStreamId}/activities?${jsonApi.encodeQuery(args)}`);
}

export async function comment(activityStreamId: ActivityStreamId, comment: CreateComment) {
  const res = await jsonApi.post<"ok">(`/activity_streams/${activityStreamId}/comment`, comment);
  await invalidateQueries([{queryKey: ["vendorToolkit", "watchingQuestion"]}]);
  return res;
}
