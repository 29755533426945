import api from "../../../api/index.ts";
import {param, typedQuery} from "../../typing.ts";
import {LayerType, QuestionnaireId} from "../../../Types.ts";

export default [
  typedQuery(["vendorToolkit", "questionnaire", param<QuestionnaireId>("questionnaireId")], async context => {
    return await api.vendorToolkit.questionnaires.get(context.queryKey[2]);
  }),
  typedQuery(["vendorToolkit", "questionnaires", {historical: true}], async () => {
    return await api.vendorToolkit.questionnaires.listHistorical();
  }),
  typedQuery(["vendorToolkit", "questionnaires", {historical: false}], async () => {
    return await api.vendorToolkit.questionnaires.list();
  }),
  typedQuery(
    ["vendorToolkit", "questionnaireDocumentation", param<QuestionnaireId>("questionnaireId"), param<LayerType>()],
    async context => {
      return await api.vendorToolkit.questionnaires.listDocumentation(context.queryKey[2], context.queryKey[3]);
    },
  ),
];
