import {Heading} from "@chakra-ui/react";
import Page from "../../components/Page";
import {withSuspense} from "../../state/withSuspense";
import TabsOutlet from "../../components/RouterTabs/Outlet";
import {useQueryData} from "../../state";
import {tabs} from ".";

const Tabs = withSuspense(() => {
  const whoami = useQueryData({queryKey: ["whoAmI"]});
  const enabledTabs = tabs.map(tab => tab.path).filter(p => whoami.internal_mode || p !== "features");
  return (
    <Page
      title={"Account settings"}
      display="flex"
      flexDirection="column"
      borderLeft="none"
      borderRight="none"
      overflow="visible"
    >
      <Heading fontSize="32" fontWeight="600" mb="10">
        Account settings
      </Heading>
      <TabsOutlet enabledTabs={enabledTabs} />
    </Page>
  );
});

export default Tabs;
