import {withSuspense} from "../../../../../state/withSuspense";
import {useQueryData} from "../../../../../state";
import {getFirstRootEntity} from "../../../graph/util";
import {Form} from "./Form";
import {GetGraphQuery, KnownEntityProperty} from "../../../../../Types";

const ORGANIZATION_ENTITY_PROPERTIES = [
  "name",
  "country_of_registration",
  "companies_house_number",
  "duns_number",
  "vat_number",
  "ico_registration",
  "registered_address",
  "website_url",
  "incorporation_date",
  "number_of_employees",
  "industry_type",
  "growth_plan",
  "description",
  "official_legal_entity_type",
] as const satisfies KnownEntityProperty[];

const FILTER_PROPERTIES = ["is_root_legal_entity"] as const satisfies KnownEntityProperty[];
const GRAPH_QUERY = {
  filter_properties: FILTER_PROPERTIES,
  selected_properties: ORGANIZATION_ENTITY_PROPERTIES,
} satisfies GetGraphQuery;

const InfoPage = withSuspense(() => {
  const graph = useQueryData({queryKey: ["vendorToolkit", "graph", GRAPH_QUERY]});
  const entity = getFirstRootEntity(graph)!;

  return <Form entity={entity} />;
});

export default InfoPage;
