import api from "../../../api";
import {SelfEnrollmentDomain} from "../../../Types";
import {Box, Button, Flex} from "@chakra-ui/react";
import {usePromiseState} from "../../../hooks/promiseState";
import {ActionBar} from "../../../components/ActionBar";
import {useQueryData} from "../../../state";
import {withSuspense} from "../../../state/withSuspense";

const SelfEnrollmentDomainComp = withSuspense(({domain}: SelfEnrollmentDomain) => {
  const [deletingAutoEnrollmentDomain, deleteAutoEnrollmentDomain] = usePromiseState(async () => {
    await api.selfEnrollmentDomains.delete_(domain);
  }, [domain]);
  return (
    <Flex justifyContent="space-between" w="full">
      <Box>
        <h3>{domain}</h3>
      </Box>
      <Button
        onClick={deleteAutoEnrollmentDomain}
        isDisabled={deletingAutoEnrollmentDomain.inProgress}
        isLoading={deletingAutoEnrollmentDomain.inProgress}
      >
        Delete
      </Button>
    </Flex>
  );
});

const NewSelfEnrollmentDomain = ({selfEnrollmentDomains}: {selfEnrollmentDomains: SelfEnrollmentDomain[]}) => {
  const whoami = useQueryData({queryKey: ["whoAmI"]});
  const domain = whoami.user.primary_email.split("@", 2)[1];
  const [creatingAutoEnrollmentDomain, createAutoEnrollmentDomain] = usePromiseState(async () => {
    await api.selfEnrollmentDomains.create({domain});
  }, [domain]);
  return (
    <Button
      colorScheme="blue"
      isDisabled={
        creatingAutoEnrollmentDomain.inProgress ||
        !!selfEnrollmentDomains.find(d => d.domain.toLowerCase() === domain.toLowerCase())
      }
      isLoading={creatingAutoEnrollmentDomain.inProgress}
      onClick={createAutoEnrollmentDomain}
    >
      Enable self-enrollment for {domain}
    </Button>
  );
};

const SelfEnrollmentPage = () => {
  const selfEnrollmentDomains = useQueryData({queryKey: ["selfEnrollmentDomains"]});
  return (
    <>
      <ActionBar position="static">
        <NewSelfEnrollmentDomain selfEnrollmentDomains={selfEnrollmentDomains} />
      </ActionBar>
      <Flex p={8} gap={8}>
        {selfEnrollmentDomains.map(selfEnrollmentDomain => (
          <SelfEnrollmentDomainComp key={selfEnrollmentDomain.domain} {...selfEnrollmentDomain} />
        ))}
      </Flex>
    </>
  );
};

export default SelfEnrollmentPage;
