import {Alert, AlertIcon, AlertProps, Button, Text} from "@chakra-ui/react";

const FilterBanner = ({
  clearFilters,
  filterCount,
  ...props
}: {clearFilters: () => void; filterCount: number} & AlertProps) => {
  const s = filterCount === 1 ? "" : "s";
  return filterCount ? (
    <Alert status="warning" flexShrink="0" px={6} display="flex" {...props}>
      <AlertIcon />
      <Text color="orange.600" fontWeight={600} fontSize="md" flex={1}>
        {`${filterCount} filter${s} applied.`}
      </Text>
      <Button onClick={clearFilters} colorScheme="orange" size="md" my={-1} variant="ghost">
        Clear filters
      </Button>
    </Alert>
  ) : null;
};

export default FilterBanner;
