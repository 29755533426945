import {Link as RouterLink, useOutlet} from "react-router-dom";
import {Box, Button, Highlight, HStack, Icon, LinkBox, List, ListItem, Stack, Text} from "@chakra-ui/react";
import {ActionBar, ActionBarSearch} from "./components/ActionBar";
import {useContext} from "react";
import {ChevronRightIcon} from "@heroicons/react/24/solid";
import QuestionnaireStatus from "../Products/VendorToolkit/components/QuestionnaireStatus/QuestionnaireStatus";
import {TimeRemaining} from "../components/DueDate";
import {parseISO} from "../utils/date";
import {useFacetedSearch} from "../hooks/search";
import {facetedSearchQuestionnaires} from "../Products/components/Filters";
import LinkOverlay from "../components/LinkOverlay";
import {withSuspense} from "../state/withSuspense";
import {useQueriesData} from "../state";
import {extensionContext} from "./provider";

const Questionnaires = withSuspense(() => {
  const [whoami, questionnaires] = useQueriesData({
    queries: [{queryKey: ["whoAmI"]}, {queryKey: ["vendorToolkit", "questionnaires", {historical: false}]}],
  });
  const outlet = useOutlet();
  const {fingerprint} = useContext(extensionContext);
  const {query, queries, setQuery, filter} = useFacetedSearch(
    q => q.name,
    facetedSearchQuestionnaires(
      {
        owners: ["ASSIGNED_TO_ME", "ASSIGNED_TO_OTHERS", "UNASSIGNED"],
        counterparties: ["ALL"],
      },
      whoami.associated_owners,
    ),
    [whoami.associated_owners],
  );

  if (outlet) {
    return outlet;
  }

  const {result} = filter(questionnaires);

  return (
    <Stack flex={1} spacing={0}>
      <ActionBar px={4}>
        <ActionBarSearch value={query} onChange={e => setQuery(e.target.value)} />
      </ActionBar>
      {fingerprint?.providerType && (
        <Button
          as={RouterLink}
          to="new"
          colorScheme="green"
          rounded="none"
          py={6}
          bgGradient="linear(175deg, green.400, green.500, teal.500)"
          borderTop="1px solid"
          borderTopColor="green.500"
        >
          Create new questionnaire from
          <Box ml={1} as="span" fontWeight="700">
            {fingerprint.providerType}
          </Box>
        </Button>
      )}
      <List flex={1} overflowY="auto">
        {result.map(q => (
          <LinkBox
            key={q.questionnaire_id}
            as={ListItem}
            p={4}
            borderBottom="1px solid"
            borderBottomColor="gray.100"
            display="flex"
            alignItems="center"
          >
            <LinkOverlay as={RouterLink} to={q.questionnaire_id} />
            <Box flex={1}>
              <HStack spacing={2} align="center">
                <Text fontWeight="600" fontSize="md">
                  <Highlight query={queries} styles={{bg: "yellow.200"}}>
                    {q.name}
                  </Highlight>
                </Text>
                <QuestionnaireStatus status={q.status} size="md" />
              </HStack>
              <HStack
                mt={2}
                spacing={4}
                align="center"
                fontSize="sm"
                color="gray.500"
                divider={
                  <Text display="inline-block" mx={2}>
                    •
                  </Text>
                }
              >
                {q.due_date && (
                  <Text noOfLines={1}>
                    <TimeRemaining dueDate={parseISO(q.due_date)} />
                  </Text>
                )}
                <Text noOfLines={1} flex={1}>
                  {q.owner ? `Assigned to ${q.owner.owner_payload.content.name}` : "Unassigned"}
                </Text>
              </HStack>
            </Box>
            <Icon as={ChevronRightIcon} ml={4} fontSize="2xl" color="gray.500" />
          </LinkBox>
        ))}
      </List>
    </Stack>
  );
});

export default Questionnaires;
