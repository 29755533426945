import * as questions from "./questions.ts";
import * as adhocQuestions from "./adhocQuestions.ts";
import * as questionnaires from "./questionnaires.ts";
import * as documents from "./documents.ts";
import * as tasks from "./tasks.ts";
import * as counterparties from "./counterparties.ts";
import * as scopes from "./scopes.ts";
import * as documentSources from "./documentSources.ts";
import * as crmConnections from "./crmConnections.ts";
import * as questionSources from "./questionSources.ts";
import * as deals from "./deals.ts";
import * as facts from "./facts.ts";
import * as esignatureProviders from "./esignatureProviders.ts";
import * as esignatureTemplates from "./esignatureTemplates.ts";
import * as trustCenter from "./trustCenter.ts";
import * as assets from "./assets.ts";
import * as graph from "./graph.ts";
import * as librarySections from "./librarySections.ts";
import * as coreResponses from "./coreResponses.ts";

const vendorToolkit = {
  questions,
  adhocQuestions,
  questionnaires,
  documents,
  tasks,
  counterparties,
  scopes,
  documentSources,
  crmConnections,
  questionSources,
  deals,
  facts,
  esignatureProviders,
  esignatureTemplates,
  trustCenter,
  assets,
  graph,
  librarySections,
  coreResponses,
};

export default vendorToolkit;
