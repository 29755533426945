import {Box, Heading, Highlight, LinkBox} from "@chakra-ui/react";
import {NavLink} from "react-router-dom";
import {DocumentMin} from "../../../../../Types";

const ListItem = ({item, highlight}: {item: DocumentMin; highlight: string[]}) => (
  <LinkBox
    as={NavLink}
    to={item.document_id}
    display="flex"
    alignItems="center"
    _hover={{bg: "gray.50"}}
    px="6"
    py="5"
    _activeLink={{bg: "gray.50", color: "blue.500"}}
  >
    <Box flex="1" overflow="hidden" textOverflow="ellipsis">
      <Heading size="sm" noOfLines={1}>
        <Highlight query={highlight} styles={{bg: "yellow.100"}}>
          {item.name}
        </Highlight>
      </Heading>
    </Box>
  </LinkBox>
);

export default ListItem;
