import {Button, Heading, Icon, Img, Menu, MenuButton, MenuItem, Stack, Text} from "@chakra-ui/react";
import {DocumentSourceConnector, SharingClassification, UpdateDocumentSource} from "../../../../../Types.ts";
import {Link as RouterLink} from "react-router-dom";
import {ChevronDownIcon} from "@heroicons/react/20/solid";
import api from "../../../../../api/index.ts";
import {useCallback, useMemo} from "react";
import {useQueriesData} from "../../../../../state/index.ts";
import {withSuspense} from "../../../../../state/withSuspense.tsx";
import {DocumentSourceEditor, DocumentSourceInitialValue} from "./DocumentSourceEditor.tsx";
import DocumentTitle from "../../../../../components/DocumentTitle.tsx";
import PortalMenuList from "../../../../../components/PortalMenuList.tsx";
import {Type} from "../../../../../typing";
import {useTypedParams} from "../../../../../hooks/typedParams";

export const NewDocumentSourceButton = ({connectors}: {connectors: DocumentSourceConnector[]}) => {
  return (
    <Menu placement="bottom-end" closeOnSelect={false}>
      <MenuButton as={Button} colorScheme="green" rightIcon={<Icon as={ChevronDownIcon} />}>
        Add document source
      </MenuButton>
      <PortalMenuList fontSize="md" maxH="50vh" overflowY="auto">
        {connectors.map(({connector_id, name, logo_url}) => (
          <MenuItem
            key={name}
            as={RouterLink}
            w="full"
            _hover={{textDecoration: "none"}}
            to={`new/${connector_id}`}
            gap={4}
          >
            <Img w={8} src={logo_url} aspectRatio={1} />
            <Text fontSize="md">{name}</Text>
          </MenuItem>
        ))}
      </PortalMenuList>
    </Menu>
  );
};

export const DocumentSourceConnectorParams = Type.Object({connectorId: Type.String()});

export const NewDocumentSource = withSuspense(() => {
  const {connectorId} = useTypedParams(DocumentSourceConnectorParams);
  const [connector, whoami] = useQueriesData({
    queries: [{queryKey: ["vendorToolkit", "documentSourceConnector", connectorId]}, {queryKey: ["whoAmI"]}],
  });
  const source = useMemo<DocumentSourceInitialValue>(
    () => ({
      name: connector.name,
      default_owner: whoami.user_owner!,
      default_sharing_classification: SharingClassification.Internal,
      connector,
    }),
    [connector, whoami.user_owner],
  );

  const save = useCallback(
    async (update: UpdateDocumentSource) => {
      await api.vendorToolkit.documentSources.create({
        connector_id: connector.connector_id,
        ...update,
      });
    },
    [connector.connector_id],
  );

  return (
    <>
      <DocumentTitle title="New document source" />
      <Stack p={8} spacing={8} align="flex-start" w="600px">
        <Heading size="md" p="0" display="flex" alignItems="center" gap={2}>
          New document source
        </Heading>
        <DocumentSourceEditor initialValue={source} onSave={save} />
      </Stack>
    </>
  );
});
