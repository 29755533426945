import {Badge} from "@chakra-ui/react";
import {DocumentTextIcon} from "@heroicons/react/20/solid";
import AiTextPage from "./AiTextPage";
import {RouteTab} from "../../../../../components/RouterTabs";

export default {
  title: (
    <>
      AI Text
      <Badge mt="1px" ml={2} colorScheme="orange">
        Advanced
      </Badge>
    </>
  ),
  path: "ai-text",
  icon: DocumentTextIcon,
  element: <AiTextPage />,
} satisfies RouteTab;
