import {Button, ButtonGroup, Heading, Img, Radio, RadioGroup, Text} from "@chakra-ui/react";
import VendorToolkitLogo from "../../../assets/vendor-toolkit.png";
import TPRMLogo from "../../../assets/tprm.png";
import {useState} from "react";
import {usePromiseState} from "../../hooks/promiseState";
import api from "../../api";
import {router} from "../../router";
import {AccountFeature} from "../../Types";
import {useAccountFeatures} from "../../hooks/accountFeatures";
import {Navigate} from "react-router-dom";
import {useQueryData} from "../../state";
import Page from "../../components/Page";

type Product = {
  label: string;
  path: string;
  feature: AccountFeature;
  logoUrl: string;
};

const products = [
  {
    label: "Vendor Toolkit",
    path: "vendor-toolkit",
    feature: AccountFeature.VendorToolkit,
    logoUrl: VendorToolkitLogo,
  },
  {
    label: "Third Party Risk Management",
    path: "tprm",
    feature: AccountFeature.TPRM,
    logoUrl: TPRMLogo,
  },
] satisfies Product[];

export default function ProductSelector() {
  const whoami = useQueryData({queryKey: ["whoAmI"]});
  const [product, setProduct] = useState<string | undefined>(undefined);
  const [rememberingProduct, rememberProduct] = usePromiseState(
    async (remember: boolean) => {
      await api.auth.updateLandingPage(remember ? product! : null);
      await router?.navigate(`/${product!}`);
    },
    [product],
  );
  const {enabledFeatures} = useAccountFeatures();
  const enabledProducts = products.filter(p => enabledFeatures[p.feature]);
  if (enabledProducts.length == 1) {
    return <Navigate to={`/${enabledProducts[0].path}`} replace />;
  }
  return (
    <Page
      title={"Select Product"}
      display="flex"
      flexDirection="column"
      borderLeft="none"
      borderRight="none"
      gap={8}
      pb={8}
    >
      <Heading>{whoami.account!.display_name}</Heading>
      <Heading size="md" textAlign="center">
        Select Product
      </Heading>
      <RadioGroup
        value={product}
        onChange={setProduct}
        display="flex"
        justifyContent="center"
        gap={8}
        __css={{
          "&>label": {
            border: "1px solid",
            borderColor: "gray.300",
            rounded: "lg",
            p: 4,
            display: "flex",
            flexDir: "column-reverse",
            w: "400px",
            h: "400px",
            justifyContent: "space-between",
            gap: 4,
          },
          '&>label:has(input[type="radio"]:checked)': {
            bg: "blue.50",
          },
          "&>label:hover": {
            borderColor: "blue.300",
          },
          "&>label>*:last-child": {
            m: 0,
            display: "flex",
            flexDir: "column",
            gap: 4,
            minW: 0,
            minH: 0,
            textAlign: "center",
          },
        }}
      >
        {enabledProducts.map(p => (
          <Radio key={p.path} value={p.path}>
            <Text fontSize="lg" fontWeight="semibold">
              {p.label}
            </Text>
            <Img src={p.logoUrl} minW={0} minH={0} objectFit="scale-down" />
          </Radio>
        ))}
      </RadioGroup>
      <ButtonGroup justifyContent="center" size="lg" isDisabled={!product}>
        <Button
          w="150px"
          onClick={() => rememberProduct(false)}
          isLoading={rememberingProduct.lastArgs && !rememberingProduct.lastArgs[0] && rememberingProduct.inProgress}
          isDisabled={rememberingProduct.lastArgs && !rememberingProduct.lastArgs[0] && rememberingProduct.inProgress}
        >
          Just once
        </Button>
        <Button
          w="150px"
          colorScheme="blue"
          onClick={() => rememberProduct(true)}
          isLoading={rememberingProduct.lastArgs && rememberingProduct.lastArgs[0] && rememberingProduct.inProgress}
          isDisabled={rememberingProduct.lastArgs && rememberingProduct.lastArgs[0] && rememberingProduct.inProgress}
        >
          Always
        </Button>
      </ButtonGroup>
    </Page>
  );
}
