import {Route} from "react-router-dom";
import RedirectAll from "./redirect/RedirectAll";

export function redirectRoute(from: string, to: string) {
  return (
    <Route path={from} element={<RedirectAll to={to} />}>
      <Route path="*" />
    </Route>
  );
}
