import {Navigate} from "react-router-dom";
import {useQueryData} from "../state";
import {withSuspense} from "../state/withSuspense";

const LandingPage = withSuspense(() => {
  const whoami = useQueryData({queryKey: ["whoAmI"]});

  return <Navigate to={whoami.landing_page || "product-selector"} replace />;
});
export default LandingPage;
