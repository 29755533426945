import {Form} from "react-router-dom";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Img,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  PinInput,
  PinInputField,
  Stack,
  Switch,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import {LoginMethod, GenerateTotpResponse, UnauthorizedError} from "../../Types";
import {usePromiseState} from "../../hooks/promiseState";
import api from "../../api";
import {useEffect, useState} from "react";
import {useCredentials} from "../../hooks/credentials";
import Loading from "../../components/Loading";
import {useValidatedField} from "../../hooks/validatedField";
import {useQueryData} from "../../state";

const DisableSecondFactorModal = ({close}: {close: () => void}) => {
  const {credentials, credentialFields, handleCredentialsError} = useCredentials();
  const [disabling2FA, disable2FA, clearError] = usePromiseState(async () => {
    try {
      await api.auth.update2FA({credentials});
      close();
    } catch (e) {
      if (handleCredentialsError(e)) {
        return;
      }
      let errorMessage = "An unknown error occurred. Please contact support@platformed.com if this persists";
      if (e instanceof UnauthorizedError) {
        switch (e.data.type) {
          case "IncorrectEmailOrPassword":
            errorMessage = "Your current password was entered incorrectly";
            break;
          case "Failed2FA":
            errorMessage = "Incorrect 2FA code";
            break;
        }
      } else {
        console.error(e);
      }
      throw new Error(errorMessage);
    }
  }, [credentials, close, handleCredentialsError]);

  useEffect(clearError, [clearError, credentials]);

  return (
    <Form
      onSubmit={e => {
        e.stopPropagation();
        e.preventDefault();
        disable2FA();
      }}
    >
      <ModalContent>
        <ModalHeader>Disable 2FA</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing={4}>
            {disabling2FA.lastError ? (
              <Alert status="error" borderRadius="md">
                <AlertIcon boxSize="40px" />
                <Box>
                  <AlertTitle fontSize="md">Error enabling 2FA</AlertTitle>
                  <AlertDescription fontSize="md">{disabling2FA.lastError.toString()}</AlertDescription>
                </Box>
              </Alert>
            ) : null}
            {credentialFields}
          </Stack>
        </ModalBody>
        <ModalFooter>
          <HStack spacing="3">
            <Button variant="ghost" onClick={close} isDisabled={disabling2FA.inProgress}>
              Cancel
            </Button>
            <Button
              isLoading={disabling2FA.inProgress}
              isDisabled={disabling2FA.inProgress || !credentials.password}
              colorScheme="red"
              type="submit"
            >
              Disable
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Form>
  );
};

const EnableSecondFactorModal = ({close}: {close: () => void}) => {
  const [generatedTotp, setGeneratedTotp] = useState<GenerateTotpResponse | null>(null);
  useEffect(() => {
    api.auth.generateTotp().then(setGeneratedTotp);
  }, []);
  const {credentials, credentialFields, handleCredentialsError} = useCredentials();
  const totpField = useValidatedField("");
  const [enabling2FA, enable2FA] = usePromiseState(async () => {
    try {
      await api.auth.update2FA({
        credentials,
        enable: {
          type: "Totp",
          content: {
            secret: generatedTotp!.secret,
            code: totpField.value,
          },
        },
      });
      close();
    } catch (e) {
      if (handleCredentialsError(e)) {
        return;
      }
      const errorMessage = "An unknown error occurred. Please contact support@platformed.com if this persists";
      if (e instanceof UnauthorizedError) {
        switch (e.data.type) {
          case "Failed2FA":
            totpField.setError("Incorrect 2FA code");
            return;
        }
      } else {
        console.error(e);
      }
      throw new Error(errorMessage);
    }
  }, [totpField, generatedTotp, credentials, close, handleCredentialsError]);

  return (
    <Form
      onSubmit={e => {
        e.stopPropagation();
        e.preventDefault();
        enable2FA();
      }}
    >
      <ModalContent>
        <ModalHeader>Enable 2FA</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing={4}>
            {enabling2FA.lastError ? (
              <Alert status="error" borderRadius="md">
                <AlertIcon boxSize="40px" />
                <Box>
                  <AlertTitle fontSize="md">Error enabling 2FA</AlertTitle>
                  <AlertDescription fontSize="md">{enabling2FA.lastError.toString()}</AlertDescription>
                </Box>
              </Alert>
            ) : null}
            <Box w="300px" aspectRatio="1" mx="auto">
              {generatedTotp ? (
                <Img
                  src={generatedTotp.qr_code}
                  sx={{imageRendering: "pixelated"}}
                  border="2px solid"
                  borderRadius="25px"
                  borderColor="green.500"
                />
              ) : (
                <Loading w="full" />
              )}
            </Box>
            <Text>
              Scan the QR code using a compatible authenticator app. Then enter your password and the code from the app.
            </Text>
            {credentialFields}
            <FormControl isInvalid={!!totpField.error}>
              <FormLabel>Code</FormLabel>
              <HStack>
                <PinInput otp value={totpField.value} onChange={totpField.setValue}>
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                </PinInput>
              </HStack>
              <FormErrorMessage>{totpField.error}</FormErrorMessage>
            </FormControl>
          </Stack>
        </ModalBody>
        <ModalFooter>
          <HStack spacing="3">
            <Button variant="ghost" onClick={close} isDisabled={enabling2FA.inProgress}>
              Cancel
            </Button>
            <Button
              type="submit"
              isLoading={enabling2FA.inProgress}
              isDisabled={
                enabling2FA.inProgress || !generatedTotp || !credentials.password || totpField.value.length != 6
              }
              colorScheme="green"
            >
              Enable
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Form>
  );
};

export const SecondFactor = () => {
  const {isOpen, onOpen, onClose} = useDisclosure();
  const whoami = useQueryData({queryKey: ["whoAmI"]});
  const authenticationMethod = whoami.user.login_method;

  if (authenticationMethod !== LoginMethod.UsernamePassword) {
    return (
      <Box py="6" px="4" pb="8">
        <Text fontSize="md" mt="1" color="gray.500">
          Second factor can only be configured when the authentication method is username and password.
        </Text>
      </Box>
    );
  }

  return (
    <Box py="6" px="4" pb="8">
      <FormControl display="flex" alignItems="center">
        <FormLabel htmlFor="email-alerts" mb="0">
          Two-factor authentication
        </FormLabel>
        <Switch isChecked={whoami.user.enabled_2fa} onChange={onOpen} isDisabled={isOpen} />
      </FormControl>
      <Modal isOpen={isOpen} onClose={onClose} size="lg">
        <ModalOverlay />
        {whoami.user.enabled_2fa ? (
          <DisableSecondFactorModal close={onClose} />
        ) : (
          <EnableSecondFactorModal close={onClose} />
        )}
      </Modal>
    </Box>
  );
};
