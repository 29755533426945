import {useEffect, useState} from "react";
import {BackgroundTask, BackgroundTaskId} from "../Types";
import {PromiseStateReturnType, usePromiseState} from "./promiseState";
import {useSubscription} from "./pusher";
import {UseToastOptions, useToast} from "@chakra-ui/react";
import {QueryFilters, invalidateQueries, setQueryData, useQueriesData} from "../state";

// Hook to receive live updates about a resource which an ongoing background task is updating
export function useBackgroundTask(
  // The background task which is updating the resource
  backgroundTask: BackgroundTask | undefined | null,
  // A query filter to invalidate when the task progresses
  filters: QueryFilters[],
) {
  useSubscription(backgroundTask?.channel_name, null, eventName => {
    switch (eventName) {
      case "pusher:subscription_succeeded":
      case "progress":
      case "complete":
        invalidateQueries(filters);
        break;
    }
  });
}

// Similar to `useBackgroundTask` but for when the background task is not tied to
// any particular resource. For customer-facing UI we should always aim to tie
// a background task to a resource, so that refreshing the page doesn't cause us
// to "forget" that a task is in progress. For that reason, this hook should
// only be used on internal (Platformed-staff) facing UI.
export function useBackgroundTaskState<P extends unknown[]>(
  // The callback which returns a newly created background task
  f: (...args: P) => Promise<BackgroundTask>,
  // Dependency list for the callback
  deps: React.DependencyList,
  toastOptions?: UseToastOptions,
): [
  {
    // True if the callback is running *or* if the background task it returned
    // is still in progress.
    inProgress: boolean;
    // Current background task state.
    data: BackgroundTask | null;
    // Last error
    error?: unknown;
  },
  (...args: P) => Promise<PromiseStateReturnType<BackgroundTask>>,
] {
  const [taskId, setTaskId] = useState<BackgroundTaskId | null>(null);
  const [state, cb] = usePromiseState(async (...args: P) => {
    const task = await f(...args);
    setTaskId(task.background_task_id);
    setQueryData(["backgroundTask", task.background_task_id], task);
    return task;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
  const toast = useToast();
  const [task] = useQueriesData({
    queries: taskId
      ? [
          {
            queryKey: ["backgroundTask", taskId],
          },
        ]
      : [],
  });
  useBackgroundTask(task, [{queryKey: ["backgroundTask", taskId ?? "<none>"]}]);
  useEffect(() => {
    if (taskId && !task) {
      toast(toastOptions);
      setTaskId(null);
    }
  }, [taskId, task, toast, toastOptions]);

  return [
    {
      inProgress: state.inProgress || !!taskId,
      error: state.lastError,
      data: task ?? null,
    },
    cb,
  ];
}
