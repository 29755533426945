import {
  Box,
  FormControl,
  FormErrorMessage,
  InputGroup,
  InputGroupProps,
  NumberInput,
  NumberInputField,
} from "@chakra-ui/react";
import {memo, useCallback} from "react";
import {useCommitText} from "../hooks/commitText.ts";
import {InputSpinner} from "./InputSpinner.tsx";

type CommitNumberInputProps = {
  value?: number;
  onCommit: (value?: number) => Promise<void>;
};
type NumericInputProps = {
  value?: number;
  onCommit: (value?: number) => Promise<void>;
  alignment?: "left" | "right";
  inputGroupProps?: InputGroupProps;
};

const NumericInput = memo(({value, onCommit, alignment, inputGroupProps}: NumericInputProps) => {
  // When editing we're working with text, but we want to expose a numeric value
  // to the caller, so do the conversion on commit.
  const commitText = useCallback(
    (newText: string) => onCommit(newText === "" ? undefined : parseInt(newText)),
    [onCommit],
  );
  // Before committing a value, we should clamp it to the allowed range. The
  // HTML numeric input only clamps when the up/down buttons are used :(
  const preCommit = useCallback(
    (newText: string) => {
      const newValue = parseInt(newText);
      if (isNaN(newValue)) {
        return value?.toString() ?? "";
      }
      return newValue.toString();
    },
    [value],
  );
  const {currentValue, inputRef, onChange, committing} = useCommitText(value?.toString() ?? "", commitText, preCommit);
  return (
    <FormControl isInvalid={!!committing.lastError}>
      <InputGroup
        display="flex"
        justifyContent={alignment === "right" ? "flex-end" : "flex-start"}
        {...inputGroupProps}
      >
        <Box position="relative" left="100px">
          <InputSpinner inProgress={committing.inProgress} />
        </Box>
        <NumberInput w="100px" value={currentValue} onChange={onChange}>
          <NumberInputField ref={inputRef} />
        </NumberInput>
      </InputGroup>
      <FormErrorMessage>{committing.lastError?.toString()}</FormErrorMessage>
    </FormControl>
  );
});

const CommitNumberInput = ({value, onCommit}: CommitNumberInputProps) => (
  <NumericInput value={value} onCommit={onCommit} />
);
export default CommitNumberInput;
