import {Flex} from "@chakra-ui/react";
import {Navigate, Outlet} from "react-router-dom";
import Header from "../Header";
import {useQueryData} from "../state";
import {HighlightedNewFeature} from "../Account/NewFeatures";
import ErrorRoot from "../Error";
import {AccountFeature} from "../Types";
import {ReactNode} from "react";
import {useAccountFeatures} from "../hooks/accountFeatures";

/// Frame used by pages within a specific product
const ProductFrame = ({
  children,
  bgBaseColor,
  productFeature,
}: {
  children?: ReactNode;
  bgBaseColor: string;
  productFeature: AccountFeature;
}) => {
  const whoami = useQueryData({queryKey: ["whoAmI"]});
  const {enabledFeatures} = useAccountFeatures();

  if (!enabledFeatures[productFeature]) {
    return <Navigate to="/" replace />;
  }

  return (
    <Flex bg="gray.100" minH="100vh" direction="column">
      <Header account={whoami.account!} bgBaseColor={bgBaseColor}>
        {children}
      </Header>
      <ErrorRoot>
        <Outlet />
      </ErrorRoot>
      <HighlightedNewFeature />
    </Flex>
  );
};

export default ProductFrame;
