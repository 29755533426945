import {Icon, Menu, MenuItemOption, MenuOptionGroup, TagLabel, TagProps, Tooltip} from "@chakra-ui/react";
import {QuestionnaireStatus} from "../../../../Types";
import {usePromiseState} from "../../../../hooks/promiseState";
import {QUESTIONNAIRE_STATUS_MAP} from ".";
import TagMenuButton from "../../../../components/TagMenuButton";
import PortalMenuList from "../../../../components/PortalMenuList";

const QuestionnaireStatusComp = ({
  status,
  onChangeStatus,
}: {
  status: QuestionnaireStatus;
  onChangeStatus?: (arg0: QuestionnaireStatus) => Promise<void>;
} & TagProps) => {
  const {color, icon, text} = QUESTIONNAIRE_STATUS_MAP[status];
  const isChangeable = !(status === QuestionnaireStatus.Automating || status === QuestionnaireStatus.Importing);

  const [changing, change] = usePromiseState(
    async (val: string | string[]) => {
      if (onChangeStatus) {
        await onChangeStatus(val as QuestionnaireStatus);
      }
    },
    [onChangeStatus],
  );

  let statusLabel = null;
  if (status === QuestionnaireStatus.Automating) {
    statusLabel = "One or more questions are being automatically completed.";
  }
  if (status === QuestionnaireStatus.Importing) {
    statusLabel = "A file or external source is being automatically imported.";
  }

  return (
    <Menu isLazy>
      <Tooltip label={statusLabel} placement="right">
        <TagMenuButton
          colorScheme={color}
          isLoading={changing.inProgress}
          isDisabled={!onChangeStatus || !isChangeable}
        >
          <Icon as={icon} />
          <TagLabel>{text}</TagLabel>
        </TagMenuButton>
      </Tooltip>
      <PortalMenuList fontSize="md" onClick={e => e.stopPropagation()}>
        <MenuOptionGroup type="radio" onChange={change} value={status}>
          {Object.keys(QuestionnaireStatus)
            .filter(s => !QUESTIONNAIRE_STATUS_MAP[s].disableSetting)
            .map(s => (
              <MenuItemOption key={s} value={s}>
                {QUESTIONNAIRE_STATUS_MAP[s].text}
              </MenuItemOption>
            ))}
        </MenuOptionGroup>
      </PortalMenuList>
    </Menu>
  );
};

export default QuestionnaireStatusComp;
