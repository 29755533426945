import {CreateTeam, RegisteredUser, RoleId, RoleMin, Team, TeamId, TeamMin, UserId} from "../Types";
import {invalidateQueries} from "../state";
import jsonApi from "./jsonApi";

export async function create(team: CreateTeam) {
  const res = await jsonApi.post<TeamMin>("/teams", team);
  await invalidateQueries([{queryKey: ["teams"]}, {queryKey: ["userTeams"]}]);
  return res;
}

export async function get(teamId: TeamId) {
  return await jsonApi.get<TeamMin>(`/teams/${teamId}`);
}

export async function updateName(teamId: TeamId, name: string) {
  const res = await jsonApi.post<"ok">(`/teams/${teamId}/name`, name);
  await invalidateQueries([
    {queryKey: ["teams"]},
    {queryKey: ["teamMembers", teamId]},
    {queryKey: ["userTeams"]},
    {queryKey: ["registeredUsers"]},
    {queryKey: ["owner"]},
    {queryKey: ["resolvedOwner"]},
  ]);
  return res;
}

export async function updateDescription(teamId: TeamId, description: string) {
  const res = await jsonApi.post<"ok">(`/teams/${teamId}/description`, description);
  await invalidateQueries([
    {queryKey: ["teams"]},
    {queryKey: ["teamMembers", teamId]},
    {queryKey: ["userTeams"]},
    {queryKey: ["registeredUsers"]},
    {queryKey: ["owner"]},
    {queryKey: ["resolvedOwner"]},
  ]);
  return res;
}

export async function delete_(teamId: TeamId) {
  const res = await jsonApi.delete_<"ok">(`/teams/${teamId}`);
  await invalidateQueries([
    {queryKey: ["teams"]},
    {queryKey: ["deletedTeams"]},
    {queryKey: ["userTeams"]},
    {queryKey: ["teamMembers", teamId]},
    {queryKey: ["registeredUsers"]},
    {queryKey: ["owner"]},
    {queryKey: ["resolvedOwner"]},
    {queryKey: ["whoAmI"]},
  ]);
  return res;
}

export async function restore(teamId: TeamId) {
  const res = await jsonApi.post<"ok">(`/teams/${teamId}/restore`);
  await invalidateQueries([
    {queryKey: ["teams"]},
    {queryKey: ["deletedTeams"]},
    {queryKey: ["userTeams"]},
    {queryKey: ["teamMembers", teamId]},
    {queryKey: ["registeredUsers"]},
    {queryKey: ["owner"]},
    {queryKey: ["resolvedOwner"]},
    {queryKey: ["whoAmI"]},
  ]);
  return res;
}

export async function list() {
  return await jsonApi.get<Team[]>("/teams");
}

export async function listDeleted() {
  const deleted = true;
  return await jsonApi.get<Team[]>(`/teams?deleted=${deleted}`);
}

export async function listMembers(teamId: TeamId) {
  return await jsonApi.get<RegisteredUser[]>(`/teams/${teamId}/members`);
}

export async function listPermissions(teamId: TeamId) {
  return await jsonApi.get<RoleMin[]>(`/teams/${teamId}/permissions`);
}

export async function addMember(teamId: TeamId, userId: UserId) {
  const res = await jsonApi.post<"ok">(`/teams/${teamId}/members`, userId);
  await invalidateQueries([
    {queryKey: ["teams"]},
    {queryKey: ["userTeams", userId]},
    {queryKey: ["teamMembers", teamId]},
    {queryKey: ["registeredUsers"]},
    {queryKey: ["whoAmI"]},
  ]);
  return res;
}

export async function removeMember(teamId: TeamId, userId: UserId) {
  const res = await jsonApi.delete_<"ok">(`/teams/${teamId}/members/${userId}`);
  await invalidateQueries([
    {queryKey: ["teams"]},
    {queryKey: ["userTeams", userId]},
    {queryKey: ["teamMembers", teamId]},
    {queryKey: ["registeredUsers"]},
    {queryKey: ["whoAmI"]},
  ]);
  return res;
}

export async function addPermission(teamId: TeamId, permissionId: RoleId) {
  const res = await jsonApi.post<"ok">(`/teams/${teamId}/permissions`, permissionId);
  await invalidateQueries([
    {queryKey: ["teams"]},
    {queryKey: ["teamPermissions", teamId]},
    {queryKey: ["teamMembers", teamId]},
    {queryKey: ["registeredUsers"]},
  ]);
  return res;
}

export async function removePermission(teamId: TeamId, permissionId: RoleId) {
  const res = await jsonApi.delete_<"ok">(`/teams/${teamId}/permissions/${permissionId}`);
  await invalidateQueries([
    {queryKey: ["teams"]},
    {queryKey: ["teamPermissions", teamId]},
    {queryKey: ["teamMembers", teamId]},
    {queryKey: ["registeredUsers"]},
  ]);
  return res;
}
