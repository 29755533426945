import {Outlet} from "react-router-dom";
import {Box, Stack} from "@chakra-ui/react";
import BreadcrumbNav from "./BreadcrumbNav";
import {withSuspense} from "../state/withSuspense";
import ExtensionProvider from "./ExtensionProvider";

const ExtensionPage = withSuspense(() => {
  return (
    <ExtensionProvider>
      <Stack spacing={0} h="100vh" overflow="hidden">
        <BreadcrumbNav />
        <Box overflow="hidden" flex="1">
          <Outlet />
        </Box>
      </Stack>
    </ExtensionProvider>
  );
});

export default ExtensionPage;
