import {HStack, Img, Link, Text} from "@chakra-ui/react";
import WordLimitScreenshot from "../../../assets/new_features/word_limit.png";
import {Feature} from ".";

export default {
  heading: "Configure word limit for your text responses",
  text: (
    <>
      <Text>
        You can now configure word limits for the AI to follow when filling out responses to your questionnaires.
      </Text>
      <Link
        href="https://platformed.com/blog/platformed-adds-word-limit-feature-to-platformed-respond"
        target="_blank"
        color="blue.500"
        fontWeight="semibold"
      >
        Find out more in our blog post.
      </Link>
    </>
  ),
  image: (
    <HStack
      bg="orange.200"
      bgGradient="linear(165deg, cyan.200, green.200, green.300)"
      alignItems="center"
      justifyContent="center"
      p={4}
    >
      <Img src={WordLimitScreenshot} rounded="md" />
    </HStack>
  ),
  publishDate: new Date(Date.parse("12 Aug 2024")),
  actionText: "Go to Respond",
  url: "/vendor-toolkit/questionnaires",
} satisfies Feature;
