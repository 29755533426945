import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Textarea,
  VStack,
} from "@chakra-ui/react";
import {MultiSelect} from "chakra-multiselect";
import {useModalState} from "../../../../../hooks/modalState";
import {Hierarchy, SectionNode, SectionParentNode} from "../utility/hierarchy";
import {useMemo} from "react";
import {useModalValidationState, useValidatedPromiseState} from "../../../../../hooks/validationState";
import api from "../../../../../api";
import {flattenHierarchyToOptions, fromOptionValue, toOptionValue} from "../utility/parentDropdown";

type Props = ({type: "create"} | {type: "edit"; section: SectionNode}) & {
  hierarchy: Hierarchy;
  defaultParent?: SectionParentNode;

  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
};

type FormState = {
  name: string;
  description: string;
  parent: string;
};

function CreateOrEditSectionModal(props: Props) {
  const options = useMemo(() => flattenHierarchyToOptions(props.hierarchy), [props.hierarchy]);

  const [formState, setFormState] = useModalState<FormState>(props.isOpen, () =>
    props.type === "edit"
      ? {
          name: props.section.obj.name,
          description: props.section.obj.description ?? "",
          parent: toOptionValue(props.section.parent),
        }
      : {
          name: "",
          parent: props.defaultParent ? toOptionValue(props.defaultParent) : options[0].value,
          description: "",
        },
  );

  const [validationErrors, setValidationErrors] = useModalValidationState(props.isOpen, {name: formState.name});

  const [creating, create] = useValidatedPromiseState(
    async () => {
      const parent = fromOptionValue(props.hierarchy, formState.parent);

      if (props.type === "create") {
        await api.vendorToolkit.librarySections.create({
          name: formState.name,
          description: formState.description,

          parent_scope_id: parent.type === "section" ? parent.obj.parent_scope?.scope_id : parent.obj?.scope_id,
          parent_section_id: parent.type === "section" ? parent.obj.library_section_id : undefined,
        });
      } else if (props.type === "edit") {
        await api.vendorToolkit.librarySections.update(props.section.obj.library_section_id, {
          name: formState.name,
          description: formState.description,
        });
      }

      props.onClose();
    },
    [formState.description, formState.name, formState.parent, props],
    setValidationErrors,
  );

  const title = props.type === "create" ? "Create new section" : "Edit section";
  const buttonText = props.type === "create" ? "Create new section" : "Save changes";
  const buttonColorScheme = props.type === "create" ? "green" : "blue";
  const errorTitle = props.type === "create" ? "Error creating section" : "Error updating section";

  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose} size="lg">
      <ModalOverlay />
      <ModalContent as="form" onSubmit={create}>
        <ModalHeader>{title}</ModalHeader>
        <ModalBody>
          <VStack spacing="4">
            <FormControl isRequired isInvalid={validationErrors.name !== undefined}>
              <FormLabel>Name</FormLabel>
              <Input value={formState.name} onChange={ev => setFormState(f => ({...f, name: ev.target.value}))} />
              {validationErrors.name && validationErrors.name[0] && (
                <FormErrorMessage>{validationErrors.name[0].message}</FormErrorMessage>
              )}
            </FormControl>
            <FormControl isDisabled={props.type === "edit"}>
              <FormLabel>Parent</FormLabel>
              <MultiSelect
                disabled={props.type === "edit"}
                options={options}
                value={formState.parent}
                onChange={v => setFormState(f => ({...f, parent: v as unknown as string}))}
                single
              />
            </FormControl>
            <FormControl>
              <FormLabel>Description</FormLabel>
              <Textarea
                value={formState.description}
                onChange={ev => setFormState(f => ({...f, description: ev.target.value}))}
              />
            </FormControl>
            {creating.lastError ? (
              <Alert status="error" mt="12">
                <AlertIcon boxSize="40px" />
                <Box>
                  <AlertTitle fontSize="md">{errorTitle}</AlertTitle>
                  <AlertDescription fontSize="md">{`${creating.lastError}`}</AlertDescription>
                </Box>
              </Alert>
            ) : null}
          </VStack>
        </ModalBody>
        <ModalFooter gap="2">
          <Button variant="outline" onClick={props.onClose} isDisabled={creating.inProgress}>
            Cancel
          </Button>
          <Button
            type="submit"
            colorScheme={buttonColorScheme}
            isLoading={creating.inProgress}
            isDisabled={creating.inProgress}
          >
            {buttonText}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default CreateOrEditSectionModal;
