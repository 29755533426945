import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  HStack,
  Input,
  Stack,
} from "@chakra-ui/react";
import {AccountFeature, DocumentSource, UpdateDocumentSource} from "../../../../../Types.ts";
import {useState} from "react";
import {AnyDocumentSource, DocumentSourcePayload} from "./Payload/AnyDocumentSource.tsx";
import {router} from "../../../../../router/index.tsx";
import {useValidatedPromiseState, useValidationState} from "../../../../../hooks/validationState.ts";
import SharingClassificationComp from "../../../components/SharingClassification/Selector.tsx";
import {ScopeMultiSelector} from "../../../components/Scope.tsx";
import RequireAccountFeature from "../../../../../components/RequireAccountFeature.tsx";
import Owner from "../../../../../components/Owner.tsx";

type MakePartial<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

export type DocumentSourceInitialValue = MakePartial<
  DocumentSource,
  "document_source_id" | "num_documents" | "sync" | "last_sync_at" | "config"
>;

type DocumentSourceEditorProps = {
  initialValue: DocumentSourceInitialValue;
  onSave: (value: UpdateDocumentSource) => Promise<void>;
};

export const DocumentSourceEditor = ({initialValue, onSave}: DocumentSourceEditorProps) => {
  const [name, setName] = useState(initialValue.name);
  const [defaultOwner, setDefaultOwner] = useState(initialValue.default_owner);
  const [defaultSharingClassification, setDefaultSharingClassification] = useState(
    initialValue.default_sharing_classification,
  );
  const [defaultLimitedToScopes, setDefaultLimitedToScopes] = useState(initialValue.default_limited_to_scopes);
  const [payload, setPayload] = useState<DocumentSourcePayload | null>(
    initialValue.config
      ? {
          external_authorization_id: initialValue.external_authorization?.external_authorization_id,
          config: initialValue.config,
        }
      : null,
  );
  const [validationErrors, setValidationErrors] = useValidationState({name});
  const [saving, save] = useValidatedPromiseState(
    async () => {
      if (!payload) return;
      await onSave({
        name,
        ...payload,
        default_owner_id: defaultOwner.owner_id,
        default_sharing_classification: defaultSharingClassification,
        default_limited_to_scope_ids: defaultLimitedToScopes && defaultLimitedToScopes.map(scope => scope.scope_id),
      });

      router!.navigate(`/vendor-toolkit/configuration/integrations/document-sources`);
    },
    [payload, name, defaultOwner, defaultSharingClassification, defaultLimitedToScopes, onSave],
    setValidationErrors,
  );

  return (
    <Box as="form" onSubmit={save} w="full">
      <Stack spacing={4}>
        <FormControl isRequired isInvalid={validationErrors.name !== undefined}>
          <FormLabel>Name</FormLabel>
          <Input
            onChange={e => setName(e.target.value)}
            value={name}
            isDisabled={saving.inProgress}
            placeholder={initialValue.connector.name}
          />
          {validationErrors.name && validationErrors.name[0] && (
            <FormErrorMessage>{validationErrors.name[0].message}</FormErrorMessage>
          )}
        </FormControl>
        <AnyDocumentSource
          connectorId={initialValue.connector.connector_id}
          payload={payload}
          setPayload={setPayload}
        />
        <Card my={4}>
          <CardHeader>
            <Heading size="sm">Defaults for new documents</Heading>
          </CardHeader>
          <CardBody as={Stack} gap={4}>
            <FormControl isRequired>
              <FormLabel>Owner</FormLabel>
              <Owner owner={defaultOwner} onReassign={async owner => setDefaultOwner(owner!)} canUnassign={false} />
            </FormControl>
            <FormControl>
              <FormLabel>Sharing status</FormLabel>
              <SharingClassificationComp
                sharing={defaultSharingClassification}
                onChangeSharing={async sharing => setDefaultSharingClassification(sharing)}
              />
            </FormControl>
            <RequireAccountFeature feature={AccountFeature.Scopes}>
              <FormControl>
                <FormLabel>Limited to scopes</FormLabel>
                <ScopeMultiSelector
                  value={defaultLimitedToScopes ?? null}
                  onChange={async scopes => setDefaultLimitedToScopes(scopes ?? undefined)}
                />
              </FormControl>
            </RequireAccountFeature>
          </CardBody>
        </Card>

        {saving.lastError ? (
          <Alert status="error" mt="12">
            <AlertIcon boxSize="40px" />
            <Box>
              <AlertTitle fontSize="md">Error saving document source</AlertTitle>
              <AlertDescription fontSize="md">{`${saving.lastError}`}</AlertDescription>
            </Box>
          </Alert>
        ) : null}
        <HStack>
          <Button
            colorScheme="green"
            type="submit"
            isLoading={saving.inProgress}
            isDisabled={validationErrors.name !== undefined || !payload}
          >
            Save
          </Button>
        </HStack>
      </Stack>
    </Box>
  );
};
