import {RegisteredUser, QuestionnaireId} from "../../../../../Types.ts";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  FormControl,
  FormLabel,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import {useAbortableModalState, useAsyncOperations} from "../../../../../hooks/asyncOperation.ts";
import {FileUpload} from "../../../../../hooks/fileUpload.ts";
import {FormEvent, useMemo} from "react";
import {usePromiseState} from "../../../../../hooks/promiseState.ts";
import api from "../../../../../api/index.ts";
import {MultiFileUpload} from "../../../../../components/fileUploads/MultiFileUpload.tsx";
import {useModalState} from "../../../../../hooks/modalState.ts";
import {ImportConfirmationModal} from "./ImportConfirmationModal.tsx";
import {AnsweringMode} from "./components/AnsweringMode/index.ts";
import SelectAnsweringMode from "./components/AnsweringMode/Select.tsx";

const UploadQuestionnaireModal = ({
  isOpen,
  onClose,
  user,
  questionnaireId,
}: {
  isOpen: boolean;
  onClose: () => void;
  user: RegisteredUser;
  questionnaireId: QuestionnaireId;
}) => {
  const {isOpen: isConfirmationOpen, onClose: closeConfirmation, onOpen: openConfirmation} = useDisclosure();
  const [answeringMode, setAnsweringMode] = useModalState(isOpen, AnsweringMode.Considered);
  const [fileUploads, setFileUploads] = useAbortableModalState<FileUpload[]>(isOpen, []);
  const uploadStates = useAsyncOperations(fileUploads);
  const uploads = useMemo(() => {
    const res = [];
    for (const uploadState of uploadStates) {
      if (uploadState.id === "Uploaded") {
        res.push(uploadState.result);
      }
    }
    return res;
  }, [uploadStates]);
  const isUploading = !uploadStates.every(s => s.id === "Uploaded");

  const [sending, send] = usePromiseState(
    async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      e.stopPropagation();
      const body = `${user.name} requested a questionnaire import on ${questionnaireId}.\n\nAnswering mode: ${answeringMode}`;
      for (const upload of uploads) {
        await api.vendorToolkit.questionnaires.createOriginalFile(questionnaireId, upload.file_id);
      }

      await api.supportEmails.send({
        action: "import",
        subject: "Questionnaire import request",
        body,
        attachments: uploads.map(u => u.file_id),
        cc_user: false,
        express: false,
      });
      openConfirmation();
      onClose();
    },
    [user.name, questionnaireId, answeringMode, uploads, openConfirmation, onClose],
  );
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent as="form" onSubmit={send}>
          <ModalHeader>Upload file</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing={4}>
              <Text fontSize="md" fontStyle="italic">
                Drag your questionnaire files here and hit upload. Your files will be imported and automated responses
                will be available within one working day.
              </Text>
              <FormControl isRequired>
                <FormLabel>Files</FormLabel>
                <MultiFileUpload Cls={FileUpload} value={fileUploads} onChange={setFileUploads} />
              </FormControl>
              <FormControl>
                <SelectAnsweringMode value={answeringMode} onChange={setAnsweringMode} />
              </FormControl>
              {sending.lastError ? (
                <Alert status="error">
                  <AlertIcon boxSize="40px" />
                  <Box>
                    <AlertTitle fontSize="md">Error uploading files</AlertTitle>
                    <AlertDescription fontSize="md">{`${sending.lastError}`}</AlertDescription>
                  </Box>
                </Alert>
              ) : null}
            </Stack>
          </ModalBody>
          <ModalFooter>
            <HStack spacing="3">
              <Button variant="outline" onClick={onClose}>
                Cancel
              </Button>
              <Button
                colorScheme="blue"
                isLoading={sending.inProgress}
                isDisabled={isUploading || fileUploads.length == 0}
                type="submit"
              >
                Upload
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <ImportConfirmationModal isOpen={isConfirmationOpen} onClose={closeConfirmation} answeringMode={answeringMode} />
    </>
  );
};

export default UploadQuestionnaireModal;
