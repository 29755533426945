import SwitchableNumericInput from "../../../../components/SwitchableNumericInput";
import {Text} from "@chakra-ui/react";

type WordCountInputProps = {
  value?: number;
  onCommit: (value?: number) => Promise<void>;
  isDisabled?: boolean;
};

const MIN_WORD_COUNT_LIMIT = 10;
const MAX_WORD_COUNT_LIMIT = 5000;

const WordCountInput = ({value, onCommit, isDisabled}: WordCountInputProps) => (
  <SwitchableNumericInput
    value={value}
    defaultValue="500"
    onCommit={onCommit}
    minValue={MIN_WORD_COUNT_LIMIT}
    maxValue={MAX_WORD_COUNT_LIMIT}
    label={
      <Text fontSize="md" fontWeight={500}>
        Word count limit
      </Text>
    }
    suffix="words"
    isDisabled={isDisabled}
  />
);
export default WordCountInput;
