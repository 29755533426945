import {RouteTab} from "../../../../../components/RouterTabs";
import {QuestionMarkCircleIcon} from "@heroicons/react/20/solid";
import QuestionsPage from "./QuestionsPage";

export default {
  title: "Questions",
  path: "questions",
  icon: QuestionMarkCircleIcon,
  element: <QuestionsPage />,
} satisfies RouteTab;
