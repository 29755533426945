import {
  Box,
  FormControl,
  FormLabel,
  HStack,
  InputGroup,
  InputRightElement,
  Select,
  Spinner,
  Stack,
} from "@chakra-ui/react";
import CommitInput from "../../../../../components/CommitInput.tsx";
import {useGraphIntegerProperty, useGraphStringProperty} from "../../../graph/hooks.ts";
import {GraphEntity, nominate} from "../../../../../Types.ts";
import CommitTextarea from "../../../../../components/CommitTextarea.tsx";
import {NaiveDateUI} from "../../../../../components/Date.tsx";
import CommitNumberInput from "../../../../../components/CommitNumberInput.tsx";
import {usePromiseState} from "../../../../../hooks/promiseState.ts";
import {endOfToday} from "date-fns";

const BUTTON_WIDTH = "72px";

const LEGAL_ENTITY_TYPES = [
  "Public limited company",
  "Limited company",
  "Limited liability partnership",
  "Other partnership",
  "Sole trader",
  "Other (please specify)",
];

export function Form({entity}: {entity: GraphEntity}) {
  const [name, updateName] = useGraphStringProperty(entity, "name");
  const [countryOfRegistration, updateCountryOfRegistration] = useGraphStringProperty(
    entity,
    "country_of_registration",
  );
  const [companiesHouseNumber, updateCompaniesHouseNumber] = useGraphStringProperty(entity, "companies_house_number");
  const [dunsNumber, updateDunsNumber] = useGraphStringProperty(entity, "duns_number");
  const [vatNumber, updateVatNumber] = useGraphStringProperty(entity, "vat_number");
  const [icoRegistration, updateIcoRegistration] = useGraphStringProperty(entity, "ico_registration");
  const [registeredAddress, updateRegisteredAddress] = useGraphStringProperty(entity, "registered_address");
  const [website, updateWebsite] = useGraphStringProperty(entity, "website_url");
  const [incorporationDate, updateIncorporationDate] = useGraphStringProperty(entity, "incorporation_date");
  const [industry, updateIndustry] = useGraphStringProperty(entity, "industry_type");
  const [growthPlan, updateGrowthPlan] = useGraphStringProperty(entity, "growth_plan");
  const [description, updateDescription] = useGraphStringProperty(entity, "description");
  const [legalEntityType, updateLegalEntityType] = useGraphStringProperty(entity, "official_legal_entity_type");

  const [numberOfEmployees, updateNumberOfEmployees] = useGraphIntegerProperty(entity, "number_of_employees");

  return (
    <Stack flex="1" spacing={0}>
      <Stack p="8" maxW="96" spacing={8}>
        <FormControl>
          <FormLabel>Company name</FormLabel>
          <CommitInput value={name ?? ""} onCommit={updateName} />
        </FormControl>
        <FormControl>
          <FormLabel>Country of registration</FormLabel>
          <CommitInput value={countryOfRegistration ?? ""} onCommit={updateCountryOfRegistration} />
        </FormControl>
        <FormControl>
          <FormLabel>Registered address</FormLabel>
          <CommitInput value={registeredAddress ?? ""} onCommit={updateRegisteredAddress} />
        </FormControl>
        <FormControl>
          <FormLabel>Date of incorporation</FormLabel>
          <NaiveDateUI
            date={incorporationDate !== undefined ? nominate("date", incorporationDate) : undefined}
            onDateChange={updateIncorporationDate}
            maxDate={endOfToday()}
          />
        </FormControl>
        <FormControl>
          <FormLabel>Legal entity type</FormLabel>
          <Stack spacing={2}>
            <LegalEntityTypeSelector value={legalEntityType ?? ""} onChange={updateLegalEntityType} />
            {(legalEntityType === "Other (please specify)" ||
              (!LEGAL_ENTITY_TYPES.includes(legalEntityType ?? "") && legalEntityType)) && (
              <CommitInput
                value={LEGAL_ENTITY_TYPES.includes(legalEntityType ?? "") ? "" : legalEntityType ?? ""}
                onCommit={updateLegalEntityType}
              />
            )}
          </Stack>
        </FormControl>
        <FormControl>
          <FormLabel>Companies House number</FormLabel>
          <CommitInput value={companiesHouseNumber ?? ""} onCommit={updateCompaniesHouseNumber} />
        </FormControl>
        <FormControl>
          <FormLabel>D-U-N-S number</FormLabel>
          <CommitInput value={dunsNumber ?? ""} onCommit={updateDunsNumber} />
        </FormControl>
        <FormControl>
          <FormLabel>VAT number</FormLabel>
          <CommitInput value={vatNumber ?? ""} onCommit={updateVatNumber} />
        </FormControl>
        <FormControl>
          <FormLabel>ICO registration</FormLabel>
          <CommitInput value={icoRegistration ?? ""} onCommit={updateIcoRegistration} />
        </FormControl>
        <FormControl>
          <FormLabel>Website</FormLabel>
          <CommitInput value={website ?? ""} onCommit={updateWebsite} />
        </FormControl>
        <FormControl>
          <FormLabel>Number of employees</FormLabel>
          <CommitNumberInput value={numberOfEmployees} onCommit={updateNumberOfEmployees} />
        </FormControl>
        <FormControl>
          <FormLabel>Industry</FormLabel>
          <CommitInput value={industry ?? ""} onCommit={updateIndustry} />
        </FormControl>
        <FormControl>
          <FormLabel>Summary of services</FormLabel>
          <CommitTextarea value={description ?? ""} onCommit={updateDescription} />
        </FormControl>
        <FormControl>
          <FormLabel>Growth plan/strategic direction</FormLabel>
          <CommitTextarea value={growthPlan ?? ""} onCommit={updateGrowthPlan} />
        </FormControl>
      </Stack>
    </Stack>
  );
}

const LegalEntityTypeSelector = ({
  value,
  onChange,
}: {
  value: string | undefined;
  onChange: (newValue?: string) => Promise<void>;
}) => {
  const [changing, change] = usePromiseState(
    async (e: React.ChangeEvent<HTMLSelectElement>) => {
      await onChange(LEGAL_ENTITY_TYPES.find(legal => legal === e.target.value)!);
    },
    [onChange],
  );

  return (
    <HStack>
      <InputGroup>
        <Select
          onChange={change}
          value={value ? (!LEGAL_ENTITY_TYPES.includes(value) ? "Other (please specify)" : value) : "none"}
          isDisabled={changing.inProgress}
        >
          <option value={"none"}>{"--- None selected ---"}</option>
          {LEGAL_ENTITY_TYPES.map(legal => (
            <option key={legal} value={legal}>
              {legal}
            </option>
          ))}
        </Select>
        {changing.inProgress && (
          <InputRightElement mr={6}>
            <Spinner color="gray.500" size="sm" />
          </InputRightElement>
        )}
      </InputGroup>
      <Box width={BUTTON_WIDTH} flexShrink="0" />
    </HStack>
  );
};
