import {ArrowDownTrayIcon, TrashIcon, XMarkIcon} from "@heroicons/react/20/solid";
import {
  ButtonGroup,
  Flex,
  Icon,
  IconButton,
  Link,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import {Documentation as DocumentationT, LayerType, QuestionId, Questionnaire} from "../../../../../Types.ts";
import {ActionBar} from "../../../../../components/ActionBar.tsx";
import {Link as RouterLink, useOutletContext} from "react-router-dom";
import SharingClassification from "../../../components/SharingClassification/Selector.tsx";
import AttachSupportingDocumentation from "../../components/AttachSupportingDocumentation.tsx";
import {extractDocumentationDetails} from "../../Question/Documentation.tsx";
import api from "../../../../../api/index.ts";
import {usePromiseState} from "../../../../../hooks/promiseState.ts";
import {withSuspense} from "../../../../../state/withSuspense.tsx";
import {useQueryData} from "../../../../../state/index.ts";
import {TabOutletContext} from "../index.tsx";
import {useLayerType} from "../../../../../hooks/layerType.ts";
import {useFriendlyMimes} from "../../../../../hooks/import.ts";

const DocumentationRow = ({
  documentation,
  questionnaire,
}: {
  documentation: DocumentationT;
  questionnaire: Questionnaire;
}) => {
  const [layerType] = useLayerType();
  const {resolveMime} = useFriendlyMimes();
  const {type, name, sharing, format, formatTitle, url, downloadFn} = extractDocumentationDetails(
    documentation,
    resolveMime,
  );
  const [downloading, download] = usePromiseState(downloadFn, [documentation.documentation_id, downloadFn]);
  const questionMap = new Map(
    questionnaire.sections.flatMap(section => section.questions).map(q => [q.question_id, q]),
  );
  const [deleting, delete_] = usePromiseState(
    async (questionId?: QuestionId | null) => {
      if (questionId === undefined) {
        await api.vendorToolkit.questionnaires.deleteDocumentation(
          questionnaire.questionnaire_id,
          layerType,
          documentation.documentation_id,
        );
      } else if (questionId === null) {
        await api.vendorToolkit.questionnaires.updateDocumentationLinkedToQuestionnaire(
          questionnaire.questionnaire_id,
          documentation.documentation_id,
          false,
        );
      } else {
        await api.vendorToolkit.questions.deleteDocumentation(questionId, layerType, documentation.documentation_id);
      }
    },
    [questionnaire.questionnaire_id, layerType, documentation.documentation_id],
  );
  return (
    <Tr>
      <Td fontWeight="600">
        {url !== "" ? (
          <Link as={RouterLink} to={url} flex="1" fontWeight="600" color="gray.700">
            {name}
          </Link>
        ) : (
          <Text flex="1" fontWeight="600" color="gray.700">
            {name}
          </Text>
        )}
      </Td>
      <Td>{type}</Td>
      <Td title={formatTitle}>{format}</Td>
      <Td>
        <SharingClassification sharing={sharing} />
      </Td>
      <Td>
        <Stack>
          {documentation.linked_to_questionnaire && layerType === LayerType.External && (
            <Flex bg="blue.100" py={1} px={3} borderRadius="md" align="center" justifyContent="space-between">
              <Text noOfLines={1} color="blue.600" fontWeight="600" fontSize="sm">
                Attached to questionnaire
              </Text>
              <IconButton
                aria-label="Delete"
                w="4"
                minW="4"
                h="4"
                p="0"
                borderRadius="full"
                bg="transparent"
                _hover={{bg: "white", color: "red.600"}}
                icon={<Icon as={XMarkIcon} />}
                onClick={() => delete_(null)}
                isLoading={deleting.inProgress && deleting.lastArgs?.[0] === null}
                isDisabled={deleting.inProgress}
              />
            </Flex>
          )}
          {documentation.linked_questions.map(q => (
            <Flex
              bg="gray.50"
              py={1}
              px={3}
              borderRadius="md"
              align="center"
              justifyContent="space-between"
              key={q.question_id}
            >
              <Link
                as={RouterLink}
                to={`../questions/${q.question_id}`}
                noOfLines={1}
                color="gray.600"
                fontWeight="600"
                fontSize="sm"
              >
                {questionMap.get(q.question_id)?.text}
              </Link>
              <IconButton
                aria-label="Delete"
                w="4"
                minW="4"
                h="4"
                p="0"
                borderRadius="full"
                bg="transparent"
                _hover={{bg: "white", color: "red.600"}}
                icon={<Icon as={XMarkIcon} />}
                onClick={() => delete_(q.question_id)}
                isLoading={deleting.inProgress && deleting.lastArgs?.[0] === q.question_id}
                isDisabled={deleting.inProgress}
              />
            </Flex>
          ))}
        </Stack>
      </Td>
      <Td textAlign="right">
        <ButtonGroup isAttached variant="outline">
          <IconButton
            aria-label="Download"
            icon={<Icon as={ArrowDownTrayIcon} />}
            isDisabled={downloading.inProgress}
            isLoading={downloading.inProgress}
            onClick={download}
          />
          <IconButton
            aria-label="Delete"
            icon={<Icon as={TrashIcon} />}
            onClick={() => delete_()}
            isDisabled={deleting.inProgress}
            isLoading={deleting.inProgress && deleting.lastArgs?.length === 0}
          />
        </ButtonGroup>
      </Td>
    </Tr>
  );
};

const DocumentationPage = withSuspense(() => {
  const {questionnaire} = useOutletContext<TabOutletContext>();
  const [layerType] = useLayerType();
  const documentation = useQueryData({
    queryKey: ["vendorToolkit", "questionnaireDocumentation", questionnaire.questionnaire_id, layerType],
  });

  return (
    <>
      <ActionBar>
        <AttachSupportingDocumentation
          target={{type: "Questionnaire", questionnaireId: questionnaire.questionnaire_id}}
          attachedDocs={documentation}
        />
      </ActionBar>
      <Table style={{borderCollapse: "separate", borderSpacing: "0"}} size="lg" layout="fixed">
        <Thead bg="white" borderBottom="10px solid" borderBottomColor="red.100">
          <Tr>
            <Th width="20%" color="gray.500">
              File name
            </Th>
            <Th width="15%" color="gray.500">
              Type
            </Th>
            <Th width="10%" color="gray.500">
              Format
            </Th>
            <Th width="15%" color="gray.500">
              Sharing
            </Th>
            <Th width="25%" color="gray.500">
              Linked to
            </Th>
            <Th />
          </Tr>
        </Thead>
        <Tbody fontSize="md">
          {documentation.map(document => (
            <DocumentationRow key={document.documentation_id} questionnaire={questionnaire} documentation={document} />
          ))}
        </Tbody>
      </Table>
    </>
  );
});

export default DocumentationPage;
