import {createContext} from "react";

export type AnchorMap = {[id: string]: HTMLDivElement};

export type ScrollAnchorContextType = {
  activeId?: string;
  anchorMap: AnchorMap;
};

const scrollAnchorContext = createContext<ScrollAnchorContextType>({
  activeId: undefined,
  anchorMap: {},
});

export default scrollAnchorContext;
