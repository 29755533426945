import {HStack, Img, Link, Text} from "@chakra-ui/react";
import Iso27001Icon from "../../../assets/new_features/iso27001.png";
import SocIcon from "../../../assets/new_features/soc.png";
import {Feature} from ".";

export default {
  heading: "Showcase your certifications",
  text: (
    <>
      <Text>We've added new features to Showcase, making it easier to display and manage your certifications.</Text>
      <Link
        href="https://platformed.com/blog/platformed-enhances-certification-displays-in-trust-center-offering-platformed-showcase"
        target="_blank"
        color="blue.500"
        fontWeight="semibold"
      >
        Find out more in our blog post.
      </Link>
    </>
  ),
  image: (
    <HStack bg="pink.300" bgGradient="linear(165deg, pink.200, purple.300)" justifyContent="space-evenly" py={12}>
      <Img src={Iso27001Icon} h={28} />
      <Img src={SocIcon} h={28} />
    </HStack>
  ),
  publishDate: new Date(Date.parse("9 Jul 2024")),
  actionText: "Go to certifications",
  url: "/vendor-toolkit/library/certifications",
} satisfies Feature;
