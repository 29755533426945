import {Box, Button, Flex, Heading, Link, LinkBox, Stack, Text} from "@chakra-ui/react";

import {Link as RouterLink, useLoaderData} from "react-router-dom";
import AuthError from "./AuthError";
import api, {HTTPError} from "../api";
import {getRedirectParams} from "../utils/auth";
import {CreateLoginMethod, LoginMethod, UnauthorizedError} from "../Types";
import LinkOverlay from "../components/LinkOverlay";
import {LoginMethodConfigurator} from "../User/Settings/components/LoginMethodConfigurator";
import {usePromiseState} from "../hooks/promiseState";
import {TokenData} from ".";
import {ignoreAsyncResult} from "../utils/typescript";

const NewUserHeading = () => {
  return (
    <Box>
      <Heading fontSize="32" fontWeight="600">
        Welcome to Platformed
      </Heading>
    </Box>
  );
};

const ExistingUserHeading = () => {
  return (
    <Box>
      <Heading fontSize="32" fontWeight="600">
        Login reset
      </Heading>
    </Box>
  );
};

const ResetLogin = () => {
  const data = useLoaderData() as TokenData | null;

  const [resettingLogin, resetLogin] = usePromiseState(
    async (loginMethod: LoginMethod, payload: CreateLoginMethod) => {
      if (data) {
        try {
          await api.loginMethod.reset(data.token, loginMethod, payload);
          return true;
        } catch (ex) {
          if (ex instanceof UnauthorizedError) {
            throw new Error("Failed to change password: invalid or expired link");
          } else if (ex instanceof HTTPError && ex.response.status === 422) {
            throw new Error("Password was insufficiently complex");
          } else {
            throw new Error("An unknown error occurred. Please contact support@platformed.com if this persists");
          }
        }
      }
    },
    [data],
  );

  if (!data) {
    return (
      <Box w="full">
        <Heading fontSize="32" fontWeight="600">
          Invalid or expired link
        </Heading>

        <Flex alignItems="center" mt={8}>
          <Box>
            <LinkBox as={Button} colorScheme="blue">
              <LinkOverlay as={RouterLink} to={`/request-reset?${getRedirectParams()}`} />
              Request a new link
            </LinkBox>
            <LinkBox as={Button} ml={4}>
              <LinkOverlay as={RouterLink} to={`/login?${getRedirectParams()}`} />
              Login
            </LinkBox>
          </Box>
        </Flex>
      </Box>
    );
  }

  return (
    <Box>
      {data.info.is_new_user ? <NewUserHeading /> : <ExistingUserHeading />}
      <AuthError error={resettingLogin.lastError?.toString()} mt={12} />

      <Stack spacing={8} mt="8">
        {resettingLogin.lastResult ? (
          <Box py="6" px="4" pb="8">
            <Text fontSize="md">Login method successfully updated. You can now log in normally.</Text>
          </Box>
        ) : (
          <LoginMethodConfigurator
            isLoggedIn={false}
            enabled2fa={data.info.requires_2fa}
            recommendedMethods={data.info.recommended_login_methods}
            onUpsert={ignoreAsyncResult(resetLogin)}
          />
        )}

        <Flex alignItems="center">
          <Box>
            <Link as={RouterLink} fontSize="md" color="blue.500" to={`/login?${getRedirectParams()}`}>
              Back to login
            </Link>
          </Box>
        </Flex>
      </Stack>
    </Box>
  );
};

export default ResetLogin;
