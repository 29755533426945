import {AssetId, EntityId, GraphOperation, KnownEntityProperty, SubGraph} from "../../../../Types";
import {GraphOperationsBuilder} from "../operationsBuilder";
import {getChildEntities, getRelationshipEntity} from "../util";
import {unreachableCase} from "../../../../utils/typescript";

export const ORGANIZATION_RELATIONSHIPS = [
  "beneficial_owner",
  "senior_leadership",
  "board_member",
] as const satisfies readonly KnownEntityProperty[];
export type OrganizationRelationship = (typeof ORGANIZATION_RELATIONSHIPS)[number];

export function formatOrganizationRelationship(relationship: OrganizationRelationship): string {
  switch (relationship) {
    case "beneficial_owner":
      return "Beneficial owner";
    case "board_member":
      return "Board member";
    case "senior_leadership":
      return "Senior leadership";
    default:
      unreachableCase(relationship);
  }
}

export type PersonDescription = {
  name: string;
  jobTitle: string;
  websiteUrl: string;

  organizationRelationships: OrganizationRelationship[];
  photoAssetId?: AssetId;
};

export type Person = {id: EntityId} & PersonDescription;

export const PERSON_PROPERTIES = [
  "name",
  "job_title",
  "website_url",
  "photo",
] as const satisfies readonly KnownEntityProperty[];

const PERSON_STRING_FIELD_PROPERTY_MAP = [
  ["name", "name"],
  ["jobTitle", "job_title"],
  ["websiteUrl", "website_url"],
] as const satisfies readonly (readonly [keyof PersonDescription, (typeof PERSON_PROPERTIES)[number]])[];

/**
 * Given an entity subgraph, get all the related people for a parent entity.
 */
export function getPeopleFromEntityGraph(graph: SubGraph, parentEntityId: EntityId): Person[] {
  const people: {[k: string]: Person} = {};
  const childEntitiesAndRelationships = getChildEntities(graph, [...ORGANIZATION_RELATIONSHIPS], parentEntityId);

  for (const {relationship, childEntity} of childEntitiesAndRelationships) {
    if (childEntity.properties.legal_entity_type !== "person") {
      continue;
    }

    let person = people[childEntity.id];

    if (person === undefined) {
      person = {
        id: childEntity.id,

        name: childEntity.properties.name ?? "",
        jobTitle: childEntity.properties.job_title ?? "",
        websiteUrl: childEntity.properties.website_url ?? "",

        organizationRelationships: [],

        photoAssetId: childEntity.properties.photo,
      };

      people[childEntity.id] = person;
    }

    person.organizationRelationships.push(relationship as OrganizationRelationship);
  }

  return Object.values(people);
}

/**
 * Given a person object, generate the list of operations required to create the
 * subprocessor entity and attach it to a parent.
 */
export function generateCreateOperationsForPerson(
  parentEntityId: EntityId,
  person: PersonDescription,
): GraphOperation[] {
  const builder = new GraphOperationsBuilder();

  const ref = builder.createEntity();

  builder.upsertStringProperty(ref, "legal_entity_type", "person");

  // String properties
  for (const [field, property] of PERSON_STRING_FIELD_PROPERTY_MAP) {
    if (person[field].trim().length !== 0) {
      builder.upsertStringProperty(ref, property, person[field]);
    }
  }

  // Photo asset
  if (person.photoAssetId !== undefined) {
    builder.upsertAssetProperty(ref, "photo", person.photoAssetId);
  }

  // Relationships
  for (const relationship of person.organizationRelationships) {
    const relationshipRef = builder.createEntity();
    builder.upsertRelationshipProperty(relationshipRef, relationship, parentEntityId, ref);
  }

  return builder.build();
}

/**
 * Given an old and new person, generate the graph update operations required to
 * update the person.
 */
export function generateUpdateOperationsForPerson(
  graph: SubGraph,
  parentEntityId: EntityId,
  person: Person,
  newPersonState: PersonDescription,
): GraphOperation[] {
  const builder = new GraphOperationsBuilder();
  const ref = person.id;

  // String properties
  for (const [field, property] of PERSON_STRING_FIELD_PROPERTY_MAP) {
    const value = newPersonState[field];

    if (value !== person[field]) {
      if (value.trim().length === 0) {
        builder.deleteProperty(ref, property);
      } else {
        builder.upsertStringProperty(ref, property, newPersonState[field]);
      }
    }
  }

  // Photo asset
  if (person.photoAssetId !== newPersonState.photoAssetId) {
    if (newPersonState.photoAssetId === undefined) {
      builder.deleteProperty(ref, "photo");
    } else {
      builder.upsertAssetProperty(ref, "photo", newPersonState.photoAssetId);
    }
  }

  // Create relationship entities for newly added relationships
  for (const relationship of newPersonState.organizationRelationships) {
    if (!person.organizationRelationships.includes(relationship)) {
      const relationshipRef = builder.createEntity();
      builder.upsertRelationshipProperty(relationshipRef, relationship, parentEntityId, person.id);
    }
  }

  // Delete relationship entities for removed relationships
  for (const relationship of person.organizationRelationships) {
    if (!newPersonState.organizationRelationships.includes(relationship)) {
      const relationshipEntity = getRelationshipEntity(graph, relationship, parentEntityId, person.id);

      if (relationshipEntity !== undefined) {
        builder.deleteEntity(relationshipEntity.id);
      }
    }
  }

  return builder.build();
}
