import {Flex, Text} from "@chakra-ui/react";
import {useEffect, useRef} from "react";
import {NavLink} from "react-router-dom";

function scrollToQuestion(elem: HTMLElement) {
  let scrollParent = elem.parentElement!;
  while (scrollParent && getComputedStyle(scrollParent).overflowY !== "auto") {
    scrollParent = scrollParent.parentElement!;
  }
  if (!scrollParent) {
    return;
  }
  const parentBounds = scrollParent.getBoundingClientRect();
  const elemBounds = elem.getBoundingClientRect();
  if (elemBounds.top < parentBounds.top || elemBounds.bottom > parentBounds.bottom) {
    const offsetToCenter = (elemBounds.top + elemBounds.bottom - parentBounds.top - parentBounds.bottom) / 2;
    scrollParent.scrollBy(0, offsetToCenter);
  }
}

export const QuestionNavItem = ({
  children,
  to,
  selected,
}: {
  children: React.ReactNode;
  to: string;
  selected: boolean;
}) => {
  const ref = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    if (selected && ref.current) {
      const elem = ref.current;
      setTimeout(() => scrollToQuestion(elem), 100);
    }
  }, [selected]);
  return (
    <Flex
      ref={ref}
      as={NavLink}
      alignItems="center"
      borderRadius="2"
      to={to}
      transitionDuration="200ms"
      _activeLink={{
        bg: "blue.50",
        borderColor: "blue.500",
      }}
      _hover={{
        bg: "gray.100",
      }}
      borderLeft="4px solid"
      borderColor="transparent"
      fontSize="md"
      align="center"
      mb={1}
      py={2}
      px={4}
    >
      <Text as="span" overflow="hidden" whiteSpace="nowrap" textOverflow="ellipsis">
        {children}
      </Text>
    </Flex>
  );
};
