import api from "../../api";
import {param, typedQuery} from "../typing";
import {ResolveOwner, OwnerId} from "../../Types.ts";

export default [
  typedQuery(["resolvedOwner", param<ResolveOwner>()], async context => {
    const owners = await api.owners.resolve([context.queryKey[1]]);
    return owners[0];
  }),
  typedQuery(["owner", param<OwnerId>("ownerId")], async context => {
    return await api.owners.get(context.queryKey[1]);
  }),
];
