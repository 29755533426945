import {useQueryData} from "../../../../state/index.ts";
import {HTTPError} from "../../../../api/index.ts";
import {FactClusterId} from "../../../../Types.ts";

export function useSubClusterTitle(clusterId: FactClusterId): string {
  const clusters = useQueryData({queryKey: ["vendorToolkit", "topLevelFactClusters"]});
  const foundPair = clusters
    .flatMap(cluster => cluster.children?.map(subCluster => [cluster, subCluster]) ?? [])
    .find(([_cluster, subCluster]) => subCluster.cluster_id === clusterId);
  if (!foundPair) {
    throw new HTTPError(new Response(null, {status: 404}));
  }
  const [cluster, subCluster] = foundPair;
  return `${cluster.title}: ${subCluster.title}`;
}
