import {Select, SelectProps} from "@chakra-ui/react";

function SelectFallback(props: SelectProps) {
  return (
    <Select value="null" {...props} isDisabled>
      <option value="null">Loading...</option>
    </Select>
  );
}

export default SelectFallback;
