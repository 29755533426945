import api from "../../api";
import {typedQuery} from "../typing";

export default [
  typedQuery(["loginMethod"], async () => {
    return await api.loginMethod.get();
  }),
  typedQuery(["loginMethods"], async () => {
    return await api.loginMethod.list();
  }),
];
