import {TrustCenterAccess} from "../../../../Types";

type TrustCenterAccessInfo = {
  text: string;
  color: string;
};

export type TrustCenterAccessMap = {[access: string]: TrustCenterAccessInfo};

export const TRUST_CENTER_VISIBILITY_MAP: TrustCenterAccessMap = {
  [TrustCenterAccess.Allowed]: {
    text: "Show publicly in Showcase",
    color: "green",
  },
  [TrustCenterAccess.Denied]: {
    text: "Not shown in Showcase",
    color: "red",
  },
};

export const TRUST_CENTER_READABILITY_MAP: TrustCenterAccessMap = {
  [TrustCenterAccess.Allowed]: {
    text: "Publicly available",
    color: "green",
  },
  [TrustCenterAccess.Denied]: {
    text: "Require access request",
    color: "red",
  },
};
