import {BackgroundTaskId} from "../../Types";
import api, {HTTPError} from "../../api";
import {param, typedQuery} from "../typing";

export default [
  typedQuery(["backgroundTask", param<BackgroundTaskId>("backgroundTaskId")], async context => {
    try {
      return await api.backgroundTasks.get(context.queryKey[1]);
    } catch (ex) {
      if (ex instanceof HTTPError && ex.response.status === 404) {
        // Task is complete
        return null;
      }
      throw ex;
    }
  }),
];
