import {ActionBar} from "../../../../../components/ActionBar.tsx";
import {NewCrmConnectionButton} from "./NewCrmConnection.tsx";
import {useQueriesData} from "../../../../../state/index.ts";
import {withSuspense} from "../../../../../state/withSuspense.tsx";
import {Box, Button, ButtonGroup, Flex, Icon, Img, Table, Tbody, Td, Text, Th, Thead, Tr} from "@chakra-ui/react";
import {CrmConnectionMin} from "../../../../../Types.ts";
import groupBy from "../../../../../utils/groupBy.tsx";
import {AdjustmentsHorizontalIcon, ArrowPathIcon} from "@heroicons/react/20/solid";
import {useBackgroundTask} from "../../../../../hooks/backgroundTask.ts";
import {usePromiseState} from "../../../../../hooks/promiseState.ts";
import api from "../../../../../api/index.ts";
import {Link as RouterLink} from "react-router-dom";

const CrmConnectionsList = ({crmConnections}: {crmConnections: CrmConnectionMin[]}) => {
  return (
    <Table>
      <Thead>
        <Tr>
          <Th w="20%">Connection</Th>
          <Th w="20%">Name</Th>
          <Th>Actions</Th>
        </Tr>
      </Thead>
      {groupBy(crmConnections, crm => crm.connector.connector_id).map(g => (
        <Tbody key={g.key}>
          {g.items.map(crm => (
            <CrmConnectionCard key={crm.crm_connection_id} crmConnection={crm} />
          ))}
        </Tbody>
      ))}
    </Table>
  );
};

const CrmConnectionCard = ({
  crmConnection: {sync, crm_connection_id, connector, name},
}: {
  crmConnection: CrmConnectionMin;
}) => {
  useBackgroundTask(sync, [{queryKey: ["vendorToolkit", "crmConnections"]}]);
  const [syncingNow, syncNow] = usePromiseState(async () => {
    if (crm_connection_id) {
      await api.vendorToolkit.crmConnections.sync(crm_connection_id);
    }
  }, [crm_connection_id]);
  return (
    <Tr>
      <Td h="20">
        <Flex align="center" fontSize="md">
          <Img src={connector.logo_url} h={4} aspectRatio={1} mr={2} />
          {connector.name}
        </Flex>
      </Td>
      <Td fontSize="md" fontWeight="500" color="gray.700">
        {name}
      </Td>
      <Td>
        <ButtonGroup isAttached variant="outline" shadow="sm">
          <Button
            as={RouterLink}
            leftIcon={<Icon as={AdjustmentsHorizontalIcon} />}
            to={`/vendor-toolkit/configuration/integrations/crm-connections/configure/${crm_connection_id}`}
          >
            Configure
          </Button>
          <Button
            leftIcon={<Icon as={ArrowPathIcon} />}
            onClick={syncNow}
            isDisabled={syncingNow.inProgress || !!sync}
            isLoading={syncingNow.inProgress || !!sync}
          >
            Resync
          </Button>
        </ButtonGroup>
        {sync && (
          <Text display="inline-flex" ml={4} verticalAlign="middle" fontSize="sm" fontWeight="600" color="gray.500">
            {(sync.progress * 100).toFixed(0)}%
          </Text>
        )}
      </Td>
    </Tr>
  );
};

const CrmConnectionsPage = withSuspense(() => {
  const [connectors, connections] = useQueriesData({
    queries: [{queryKey: ["vendorToolkit", "crmConnectors"]}, {queryKey: ["vendorToolkit", "crmConnections"]}],
  });
  return (
    <>
      <ActionBar>
        <NewCrmConnectionButton connectors={connectors} />
      </ActionBar>
      <Box h="full">
        <CrmConnectionsList crmConnections={connections} />
      </Box>
    </>
  );
});

export default CrmConnectionsPage;
