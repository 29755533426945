import {Flex, Button, Icon, MenuButton, Box, Menu} from "@chakra-ui/react";
import {ChevronDownIcon} from "@heroicons/react/20/solid";
import ModifiersMenuList, {ModifiersMenuProps} from "./ModifiersMenuList";
import * as _ from "lodash-es";

const ModifiersButton = ({isLoading, ...props}: ModifiersMenuProps & {isLoading: boolean}) => {
  const effectiveValue = {...props.defaultValue, ...props.value};
  const changed = props.usedValue && !_.isEqual(effectiveValue, props.usedValue);
  return (
    <Menu closeOnSelect={false} placement="bottom-end">
      <MenuButton
        as={Button}
        size="sm"
        w="65px"
        rightIcon={<Icon as={ChevronDownIcon} />}
        borderLeft="1px solid"
        borderColor="purple.700"
        isLoading={isLoading}
      >
        <Flex position="relative">
          <Flex
            fontSize="xs"
            ml={1}
            bg="purple.100"
            color="purple.500"
            w={4}
            h={4}
            rounded="full"
            align="center"
            justify="center"
          >
            {Object.values(props.value).filter(Boolean).length}
          </Flex>
          {changed && <Box position="absolute" w="6px" h="6px" bg="red.500" rounded="full" right="-1px" top="-1px" />}
        </Flex>
      </MenuButton>
      <ModifiersMenuList {...props} />
    </Menu>
  );
};

export default ModifiersButton;
