import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Stack,
  FormControl,
  FormLabel,
  HStack,
  ModalFooter,
  Button,
  Textarea,
} from "@chakra-ui/react";
import {AccountFeature, CoreResponse} from "../../../../Types";
import {useModalState} from "../../../../hooks/modalState";
import {useValidatedPromiseState} from "../../../../hooks/validationState";
import api from "../../../../api";
import {ScopeMultiSelector} from "../../components/Scope";
import RequireAccountFeature from "../../../../components/RequireAccountFeature";

type AddOrEditCoreResponseModalProps = {
  isOpen: boolean;
  onClose: () => void;
  coreResponse?: CoreResponse;
};

export function AddOrEditCoreResponseModal({isOpen, onClose, coreResponse}: AddOrEditCoreResponseModalProps) {
  const add = coreResponse == null;

  const [questionText, setQuestionText] = useModalState(isOpen, coreResponse?.question_text ?? "");
  const [responseText, setResponseText] = useModalState(isOpen, coreResponse?.response_text ?? "");
  const [limitedToScopes, setLimitedToScopes] = useModalState(isOpen, coreResponse?.limited_to_scopes ?? null);

  const [submitting, submit] = useValidatedPromiseState(async () => {
    const limitedToScopeIds = limitedToScopes?.map(s => s.scope_id);

    if (add) {
      await api.vendorToolkit.coreResponses.create({
        question_text: questionText,
        response_text: responseText,
        limited_to_scope_ids: limitedToScopeIds,
      });
    } else {
      await api.vendorToolkit.coreResponses.updateQuestionText(coreResponse.core_response_id, questionText);
      await api.vendorToolkit.coreResponses.updateResponseText(coreResponse.core_response_id, responseText);

      await api.vendorToolkit.coreResponses.updateLimitedToScopes(
        coreResponse.core_response_id,
        limitedToScopeIds ?? null,
      );
    }

    onClose();
  }, [add, coreResponse, limitedToScopes, onClose, questionText, responseText]);

  // Render

  const title = add ? "Create core response" : "Edit core response";
  const submitButtonText = add ? "Create" : "Save changes";

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent as="form" onSubmit={submit}>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing="8">
            <FormControl isRequired>
              <FormLabel>Question text</FormLabel>
              <Textarea value={questionText} onChange={e => setQuestionText(e.target.value)} autoFocus={add} />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Response text</FormLabel>
              <Textarea value={responseText} onChange={e => setResponseText(e.target.value)} />
            </FormControl>
            <RequireAccountFeature feature={AccountFeature.Scopes}>
              <FormControl>
                <FormLabel>Limited to scopes</FormLabel>
                <ScopeMultiSelector value={limitedToScopes} onChange={setLimitedToScopes} />
              </FormControl>
            </RequireAccountFeature>
          </Stack>
        </ModalBody>

        <ModalFooter>
          <HStack spacing="3">
            <Button variant="ghost" onClick={onClose} isDisabled={submitting.inProgress}>
              Cancel
            </Button>
            <Button colorScheme="blue" type="submit" isLoading={submitting.inProgress}>
              {submitButtonText}
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
