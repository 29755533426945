import {Counterparty} from "../../../Types";

import {
  Button,
  Flex,
  Icon,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItemOption,
  MenuOptionGroup,
  Text,
} from "@chakra-ui/react";
import {ChevronDownIcon, UsersIcon} from "@heroicons/react/20/solid";
import {Counts} from "../../../hooks/search";
import Only from "./Only";
import PortalMenuList from "../../../components/PortalMenuList";

const FilterCounterparties = ({
  counts,
  allCounterparties,
  counterparties,
  setCounterparties,
}: {
  counts?: Counts;
  allCounterparties: Counterparty[];
  counterparties: string[];
  setCounterparties: (a: string[]) => void;
}) => {
  const hasAll = counterparties.includes("ALL");
  const value = hasAll ? [...counterparties, ...allCounterparties.map(c => c.counterparty_id)] : counterparties;
  const count = hasAll ? value.length - 1 : value.length;

  const onChangeOwners = (v: string | string[]) => {
    const arr = typeof v === "string" ? [v] : v;
    let newCounterparties;
    // If a normal user was unchecked while "ALL" is checked
    if (arr.length < value.length && arr.includes("ALL")) {
      // Switch to precise filtering
      newCounterparties = arr.filter(item => item !== "ALL");
    } else if (arr.includes("ALL") || value.includes("ALL")) {
      // If ALL is or was checked, then filter out normal users
      newCounterparties = arr.filter(item => item === "ALL");
    } else {
      newCounterparties = arr;
    }
    if (newCounterparties.length !== 0) {
      setCounterparties(newCounterparties);
    }
  };

  const selectCount = (counts: Counts, counterparty: string): number => {
    if (counterparty === "ALL") {
      return Object.values(counts).reduce((a, b) => a + b, 0);
    }
    return counts[counterparty];
  };

  const only = (user: string) => (e: any) => {
    e.stopPropagation();
    setCounterparties([user]);
  };

  return (
    <Menu placement="bottom-end" closeOnSelect={false}>
      <MenuButton
        as={Button}
        colorScheme="blue"
        rightIcon={<Icon as={ChevronDownIcon} />}
        leftIcon={<Icon as={UsersIcon} />}
      >
        Counterparties ({count})
      </MenuButton>
      <PortalMenuList fontSize="md" maxH="50vh" overflowY="auto">
        <MenuOptionGroup type="checkbox" value={value} onChange={onChangeOwners}>
          <MenuItemOption value="ALL" _focus={{".only": {visibility: "visible"}, bg: "gray.100"}} transition="0">
            <Flex align="center">
              <Text color="orange.600" flex={1}>
                All
              </Text>
              {counts && <Text color="gray.400">{selectCount(counts, "ALL")}</Text>}
            </Flex>
          </MenuItemOption>
          <MenuDivider />
          {allCounterparties.map(({counterparty_id, name}) => (
            <MenuItemOption
              value={counterparty_id}
              key={counterparty_id}
              _focus={{".only": {visibility: "visible"}, bg: "gray.100"}}
              transition="0"
            >
              <Flex align="center">
                <Text flex={1} mr={5}>
                  {name}
                </Text>
                <Only onClick={only(counterparty_id)} />
                {counts && <Text color="gray.400">{selectCount(counts, counterparty_id)}</Text>}
              </Flex>
            </MenuItemOption>
          ))}
        </MenuOptionGroup>
      </PortalMenuList>
    </Menu>
  );
};

export default FilterCounterparties;
