import {EsignatureProviderConnectorType, UpdateEsignatureProvider} from "../../../../Types.ts";
import {DocusignEsignatureProvider} from "./DocusignEsignatureProvider.tsx";

export type EsignatureProviderProps = {
  connectorType: EsignatureProviderConnectorType;
  payload: UpdateEsignatureProvider | null;
  setPayload: (payload: UpdateEsignatureProvider | null) => void;
};

export const AnyEsignatureProvider = (props: EsignatureProviderProps) => {
  switch (props.connectorType) {
    case EsignatureProviderConnectorType.Docusign:
      return <DocusignEsignatureProvider {...props} />;
    default:
      return null;
  }
};
