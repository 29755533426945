import {useCallback, useEffect, useState} from "react";
import {Json} from "../api/jsonApi";

// List of callbacks to call when session storage is changed to allow
// components to synchronize with each other
const SESSION_STORAGE_WATCHERS = new Map<string, Set<() => void>>();

// Helper function to deserialize a state from session storage, and
// return a default value if the state is unset or otherwise fails
// to deserialize.
function readSession<S>(storageKey: string, defaultValue: S): S {
  const storedValue = sessionStorage.getItem(storageKey);
  try {
    if (storedValue) {
      return JSON.parse(storedValue);
    } else {
      return defaultValue;
    }
  } catch (ex) {
    return defaultValue;
  }
}

// Similar to `useState`, except that state is persisted in `sessionStorage` under
// the provided `storageKey`. State must be JSON serializable.
export function useSessionState<S extends Json>(
  storageKey: string,
  defaultValue: S,
): [S, (newValue: S | ((oldValue: S) => S)) => void] {
  // On state initialization, attempt to read the value from `sessionStorage`.
  const [state, setState] = useState<S>(() => readSession(storageKey, defaultValue));

  // Register a session storage watcher for this storage key so we get notified
  // if another component modifies the `sessionStorage`.
  useEffect(() => {
    // Define a callback which updates our state based on the session storage
    const cb = () => {
      setState(readSession(storageKey, defaultValue));
    };
    // When the effect runs, we add the watcher
    if (!SESSION_STORAGE_WATCHERS.has(storageKey)) {
      SESSION_STORAGE_WATCHERS.set(storageKey, new Set());
    }
    SESSION_STORAGE_WATCHERS.get(storageKey)!.add(cb);

    // In the effect destructor, we remove the watcher
    return () => {
      SESSION_STORAGE_WATCHERS.get(storageKey)!.delete(cb);
    };
  }, [storageKey, defaultValue]);

  // This is the function we return to the caller to allow them to set the state
  const wrappedSetState = useCallback(
    // Mirroring `useState`, the argument can be either a state value or a state-updating function
    (newValue: S | ((oldValue: S) => S)) => {
      // If the caller provided a function, then apply the function to the freshly-read state
      if (typeof newValue === "function") {
        newValue = (newValue as (oldValue: S) => S)(readSession(storageKey, defaultValue));
      }
      // Save the new state
      sessionStorage.setItem(storageKey, JSON.stringify(newValue));
      // Run any watchers (including our own) to ensure all components receive the new state
      const watchers = SESSION_STORAGE_WATCHERS.get(storageKey);
      if (watchers) {
        for (const watcher of watchers) {
          watcher();
        }
      }
    },
    [storageKey, defaultValue],
  );

  // Finally, return the state and state setter
  return [state, wrappedSetState];
}
