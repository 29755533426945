import {Box, Divider, Link, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr} from "@chakra-ui/react";
import CodeSpan from "../../../../../../components/CodeSpan.tsx";
import HelpDrawer from "../../../../../../components/HelpDrawer.tsx";

const ImportHelp = () => {
  return (
    <HelpDrawer
      open={<Link color="blue.500">Click here for documentation on the CSV format</Link>}
      title="Importing a CSV"
    >
      <Text fontSize="md">
        You can import a simple questionnaire by uploading a file with just three columns, <CodeSpan>Question</CodeSpan>
        , <CodeSpan>ResponseText</CodeSpan> and <CodeSpan>SectionTitle</CodeSpan>. Leave the response blank for
        questions you would like Platformed's AI to complete.
      </Text>
      <TableContainer paddingTop="8">
        <Table size="sm">
          <Thead fontSize="md">
            <Tr>
              <Th textTransform="none">SectionTitle</Th>
              <Th textTransform="none">Question</Th>
              <Th textTransform="none">ResponseText</Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td>Section A</Td>
              <Td>First sample question</Td>
              <Td>Sample response</Td>
            </Tr>
            <Tr>
              <Td>Section B</Td>
              <Td>Second sample question</Td>
              <Td></Td>
            </Tr>
            <Tr>
              <Td>Section C</Td>
              <Td>Third sample question</Td>
              <Td></Td>
            </Tr>
          </Tbody>
        </Table>
      </TableContainer>
      <Box paddingTop="4">
        <Link href="/static/simple-sample.csv" fontSize="md" color="blue.500">
          Download a sample CSV
        </Link>
      </Box>

      <Divider marginY="8" />

      <Text as="span" fontSize="xl" fontWeight="semibold">
        Advanced import
      </Text>
      <Text paddingTop="4" fontSize="md">
        Several additional columns are accepted in an uploaded CSV, allowing you to represent more complex
        questionnaires and question types.
      </Text>
      <TableContainer paddingTop="8" whiteSpace="normal">
        <Table size="sm">
          <Thead fontSize="md">
            <Tr>
              <Th>Column</Th>
              <Th>Description</Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td>SectionTitle</Td>
              <Td>
                Name of a section or group of questions, rows without a section title will continue to use the last
                value in this column
              </Td>
            </Tr>
            <Tr>
              <Td>Number</Td>
              <Td>Question number, for reference</Td>
            </Tr>
            <Tr>
              <Td>Question *</Td>
              <Td>Question text</Td>
            </Tr>
            <Tr>
              <Td>Guidance</Td>
              <Td>
                Longer form information about how to complete the question, for example describing the circumstances
                under which N/A would be an appropriate answer. Use this field to include very long text, like
                contractual language, in your questionnaire. This text is not used by the AI.
              </Td>
            </Tr>
            <Tr>
              <Td>Parts</Td>
              <Td>
                Newline-separated list of question parts, for example:
                <br />
                <CodeSpan>
                  text
                  <br />
                  yesno
                </CodeSpan>
                <br />
                Valid question parts are <CodeSpan>text</CodeSpan>, <CodeSpan>yesno</CodeSpan>,{" "}
                <CodeSpan>file</CodeSpan>, <CodeSpan>single-select</CodeSpan>, <CodeSpan>multi-select</CodeSpan>. You
                can only include one kind of '-select' in a single question.
              </Td>
            </Tr>
            <Tr>
              <Td>SelectOptions</Td>
              <Td>
                A newline separated list of options in a single or multi-select question. For example:
                <br />
                <CodeSpan>
                  Apples
                  <br />
                  Badgers
                  <br />
                  Plutonium
                </CodeSpan>
              </Td>
            </Tr>
            <Tr>
              <Td>SelectMax</Td>
              <Td>A number specifying how many options may be selected in a multi-select</Td>
            </Tr>
            <Tr>
              <Td>ResponseText</Td>
              <Td>Text part of a response</Td>
            </Tr>
            <Tr>
              <Td>ResponseYesNo</Td>
              <Td>Yes/No part of a response</Td>
            </Tr>
            <Tr>
              <Td>ResponseSelect</Td>
              <Td>Newline separated selected option(s) in a single or multi-select part of a response</Td>
            </Tr>
          </Tbody>
        </Table>
        <Text fontSize="sm" fontStyle="italic" padding="3">
          * Required column
        </Text>
      </TableContainer>
      <Box paddingTop="4">
        <Text fontSize="md">
          <Link href="/static/complex-sample.csv" color="blue.500">
            Download a sample CSV
          </Link>{" "}
          that includes example usage of all the columns.
        </Text>
      </Box>
      <Divider marginY="8" />
      <Box>
        <Text as="span" fontSize="xl" fontWeight="semibold">
          Single and multi-select configuration
        </Text>
        <Text fontSize="md" paddingTop="4">
          Single and multi-select options and responses can be further customised, allowing you to add more detailed
          'Label' text for an option, or permit an 'Other' option with an accompanying free-text field.
        </Text>
        <Text fontSize="md" fontWeight="semibold" paddingTop="2">
          Labels
        </Text>
        <Text fontSize="md" paddingTop="2">
          Add a label to an option, to be displayed instead of the value, by including a broken vertical bar, followed
          by the label text. For example:
          <br />
          <CodeSpan>other-option¦Select this option if you aren't sure</CodeSpan>
        </Text>
        <Text fontSize="md" fontWeight="semibold" paddingTop="2">
          'Other' text in options
        </Text>
        <Text fontSize="md" paddingTop="2">
          If you'd like to permit a free-text response in addition to a selected option, include an asterisk (
          <CodeSpan>*</CodeSpan>) as the last character in your option value, for example:
          <br />
          <CodeSpan>other-option*¦Another choice not in this list</CodeSpan>
        </Text>
        <Text fontSize="md" fontWeight="semibold" paddingTop="2">
          'Other' text in responses
        </Text>
        <Text fontSize="md" paddingTop="2">
          To supply such a free-text response for a question, include a broken vertical bar, followed by the text value,
          after the selected option. For example:
          <CodeSpan>other-option¦Pineapple</CodeSpan>
        </Text>
      </Box>
    </HelpDrawer>
  );
};

const PopulateStep = () => {
  return (
    <>
      <Text>
        Open the template and replace the example questions with the questions you'd like to import into Platformed.
      </Text>
      <ImportHelp />
      <Text>When you're done, save your CSV and continue.</Text>
    </>
  );
};

export default PopulateStep;
