import {
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from "@chakra-ui/react";
import api from "../../../../../api";
import {CoreResponse, Question} from "../../../../../Types";
import {useLayerType} from "../../../../../hooks/layerType";
import {useValidatedPromiseState} from "../../../../../hooks/validationState";

type Props = {
  isOpen: boolean;
  onClose: () => void;

  question: Question;
  coreResponse: CoreResponse;
};

function ApplyCoreResponseModal({isOpen, onClose, question, coreResponse}: Props) {
  const [layerType] = useLayerType();

  const [updating, update] = useValidatedPromiseState(async () => {
    await api.vendorToolkit.questions.updateLinkedCoreResponse(
      question.question_id,
      layerType,
      coreResponse.core_response_id,
    );

    onClose();
  }, [coreResponse.core_response_id, layerType, onClose, question.question_id]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent as="form" onSubmit={update}>
        <ModalHeader>Apply core response</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing={4}>
            <Text fontSize="md">
              Are you sure you want to apply this core response? This action will overwrite the existing response text.
            </Text>
          </Stack>
        </ModalBody>
        <ModalFooter>
          <HStack spacing="3">
            <Button variant="outline" onClick={onClose} isDisabled={updating.inProgress}>
              Cancel
            </Button>
            <Button colorScheme="blue" type="submit" isDisabled={updating.inProgress} isLoading={updating.inProgress}>
              Apply core response
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default ApplyCoreResponseModal;
