import {Text} from "@chakra-ui/react";

function NoReviewItemsSnippet() {
  return (
    <Text p={6} fontSize="md" color="gray.500">
      There are no review items.
    </Text>
  );
}

export default NoReviewItemsSnippet;
