import {DocumentSourceConnector, DocumentSourceMin} from "../../../../../Types";
import {ActionBar} from "../../../../../components/ActionBar";
import {Button, Flex, Img, Box, Icon, Table, Thead, Tbody, Th, Td, Tr, Text, ButtonGroup} from "@chakra-ui/react";
import {NewDocumentSourceButton} from "./NewDocumentSource";
import api from "../../../../../api";
import {usePromiseState} from "../../../../../hooks/promiseState";
import groupBy from "../../../../../utils/groupBy";
import {AdjustmentsHorizontalIcon, ArrowPathIcon, ExclamationCircleIcon} from "@heroicons/react/24/solid";
import {useBackgroundTask} from "../../../../../hooks/backgroundTask";
import {useQueriesData} from "../../../../../state";
import {withSuspense} from "../../../../../state/withSuspense";
import {useTime} from "../../../../../hooks/time";
import {relativeDateView} from "../../../../../utils/time";
import {Link as RouterLink} from "react-router-dom";

export type DocumentSourcesData = {
  connectors: DocumentSourceConnector[];
  sources: DocumentSourceMin[];
};

const DocumentSourcesList = ({documentSources}: {documentSources: DocumentSourceMin[]}) => (
  <Table>
    <Thead>
      <Tr>
        <Th w="18%">Source</Th>
        <Th w="20%">Name</Th>
        <Th w="15%">Count</Th>
        <Th w="15%">Last synced</Th>
        <Th>Actions</Th>
        <Th />
      </Tr>
    </Thead>
    {groupBy(documentSources, ds => ds.connector.connector_id).map(g => (
      <Tbody key={g.key}>
        {g.items.map(documentSource => (
          <DocumentSourceCard key={documentSource.document_source_id ?? "platformed"} documentSource={documentSource} />
        ))}
      </Tbody>
    ))}
  </Table>
);

const DocumentSourceCard = ({
  documentSource: {sync, document_source_id, connector, name, num_documents, last_sync_at, last_error_catalog},
}: {
  documentSource: DocumentSourceMin;
}) => {
  useBackgroundTask(sync, [{queryKey: ["vendorToolkit", "documentSources"]}]);
  const [syncingNow, syncNow] = usePromiseState(async () => {
    if (document_source_id) {
      await api.vendorToolkit.documentSources.sync(document_source_id);
    }
  }, [document_source_id]);
  const lastSyncAt = useTime(relativeDateView(last_sync_at), [last_sync_at]);
  const lastSyncAtMessage = lastSyncAt ? `${lastSyncAt}` : "Not synced";
  return (
    <Tr>
      <Td h="20">
        <Flex align="center" fontSize="md">
          <Img src={connector.logo_url} h={4} mr={2} aspectRatio={1} />
          {connector.name}
        </Flex>
      </Td>
      <Td fontSize="md" fontWeight="500" color="gray.700">
        {name}
      </Td>
      <Td fontSize="md" color={num_documents > 0 ? "gray.700" : "orange.500"}>
        {num_documents} document{num_documents === 1 ? "" : "s"}
      </Td>
      <Td fontSize="md">{!!document_source_id && lastSyncAtMessage}</Td>
      <Td>
        {!!document_source_id && (
          <>
            <ButtonGroup isAttached variant="outline" shadow="sm">
              <Button
                leftIcon={<Icon as={AdjustmentsHorizontalIcon} />}
                as={RouterLink}
                to={`/vendor-toolkit/configuration/integrations/document-sources/configure/${document_source_id}`}
                borderEndRadius={0}
              >
                Configure
              </Button>
              <Button
                leftIcon={<Icon as={ArrowPathIcon} />}
                onClick={syncNow}
                isDisabled={syncingNow.inProgress || !!sync}
                isLoading={syncingNow.inProgress || !!sync}
                borderStartRadius={0}
              >
                Resync
              </Button>
            </ButtonGroup>
            {sync && (
              <Text display="inline-flex" ml={4} verticalAlign="middle" fontSize="sm" fontWeight="600" color="gray.500">
                {(sync.progress * 100).toFixed(0)}%
              </Text>
            )}
          </>
        )}
      </Td>
      <Td>
        <ButtonGroup isAttached variant="outline" shadow="sm" colorScheme="red">
          {last_error_catalog && (
            <Button
              as={RouterLink}
              to={`/vendor-toolkit/configuration/integrations/document-sources/diagnose/${document_source_id}`}
              leftIcon={<Icon as={ExclamationCircleIcon} fontSize="lg" />}
            >
              {last_error_catalog.num_errors}
            </Button>
          )}
        </ButtonGroup>
      </Td>
    </Tr>
  );
};

const DocumentSourcesPage = withSuspense(() => {
  const [connectors, sources] = useQueriesData({
    queries: [
      {
        queryKey: ["vendorToolkit", "documentSourceConnectors"],
      },
      {
        queryKey: ["vendorToolkit", "documentSources"],
      },
    ],
  });

  return (
    <>
      <ActionBar>
        <NewDocumentSourceButton connectors={connectors} />
      </ActionBar>
      <Box h="full">
        <DocumentSourcesList documentSources={sources} />
      </Box>
    </>
  );
});

export default DocumentSourcesPage;
