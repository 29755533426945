import {NewQuestionSource} from "./NewQuestionSource.tsx";
import {ChatBubbleLeftRightIcon} from "@heroicons/react/20/solid";
import {Route} from "react-router-dom";
import {ConfigureQuestionSource} from "./ConfigureQuestionSource.tsx";
import QuestionSourcesPage from "./QuestionSourcesPage.tsx";
import Crumb from "./Crumb.tsx";

export const questionSourceUrlFromUri = (uri: string) => {
  if (uri.startsWith("slack")) {
    return uri.replace("slack://", "https://app.slack.com/");
  }
  return null;
};

export default {
  title: "Question sources",
  path: "question-sources",
  icon: ChatBubbleLeftRightIcon,
  routes: (
    <>
      <Route path="" element={<QuestionSourcesPage />} />
      <Route path="new/:connectorId" element={<NewQuestionSource />} />
      <Route path="configure/:questionSourceId" element={<ConfigureQuestionSource />} handle={{Crumb}} />
    </>
  ),
};
