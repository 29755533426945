import {Button, Center, Grid, Heading, Spinner} from "@chakra-ui/react";
import {useQueryData} from "../../state";
import {withSuspense} from "../../state/withSuspense";
import {useCallback, useEffect} from "react";
import {redirectToAccountSelector} from "../../utils/auth";
import {OperationInProgressError, usePromiseState} from "../../hooks/promiseState";
import api from "../../api";

const ConsentScreenPage = withSuspense(() => {
  const whoami = useQueryData({queryKey: ["whoAmI"]});
  const externalRedirect = new URL(window.location.href).searchParams.get("external_redirect")!;
  const consent = useQueryData({queryKey: ["consent", externalRedirect]});

  const [authorizing, authorize] = usePromiseState(async () => {
    const accessToken = await api.accessTokens.create({
      client_account_id: consent.client_account.account_id,
      scope: consent.scope,
      user_id: whoami.user.user_id,
      affiliated_account_id: whoami.account?.account_id,
    });
    const redirectUrl = new URL(externalRedirect);
    redirectUrl.searchParams.set("access_token", accessToken.token);
    redirectUrl.searchParams.set("client_account_id", accessToken.client_account_id);
    window.location.replace(redirectUrl);
  }, [whoami, consent, externalRedirect]);

  const cancel = useCallback(() => {
    window.location.replace(externalRedirect);
  }, [externalRedirect]);

  useEffect(() => {
    // Do nothing if we're already authorizing...
    if (authorizing.inProgress) {
      return;
    }
    // If a user has 2+ accounts an no account is selected, make them pick an account
    if (whoami.multi_account && !whoami.account) {
      redirectToAccountSelector().follow();
    }
    if (consent.client_account.account_id === whoami.account?.account_id) {
      authorize().catch(e => {
        // Ignore OperationInProgress errors
        if (!(e instanceof OperationInProgressError)) {
          throw e;
        }
      });
    }
  });

  return consent.client_account.account_id !== whoami.account?.account_id ? (
    <>
      <Heading fontSize="32" fontWeight="600" mb="12">
        User consent
      </Heading>
      <p>
        Authorize {consent.client_account.display_name} to access basic information about you
        {whoami.account ? ` and ${whoami.account.display_name}` : ""}?
      </p>

      <Grid mt="12" gap={4} templateColumns="repeat(2, 1fr)">
        <Button variant="outline" onClick={cancel} isDisabled={authorizing.inProgress}>
          Cancel
        </Button>
        <Button
          colorScheme="blue"
          onClick={authorize}
          isLoading={authorizing.inProgress}
          isDisabled={authorizing.inProgress}
        >
          Allow
        </Button>
      </Grid>
    </>
  ) : (
    <>
      <Heading fontSize="32" fontWeight="600" mb="12">
        Authorizing
      </Heading>
      <Center>
        <Spinner size="xl" />
      </Center>
    </>
  );
});

export default ConsentScreenPage;
