import {FetchStatus, QueryFunctionContext, QueryKey} from "@tanstack/react-query";

// Used as a placeholder for dynamic parts of the query key
export const ParamSymbol = Symbol("<param>");
const TypeBrand = Symbol("_type");

// Type of a placeholder for a specific type
export type Param<T> = typeof ParamSymbol & {[TypeBrand]: T};

// Constructs a placeholder of a specific type
// `name` parameter is for documentation purposes only.
export function param<T = string>(_name?: string): Param<T> {
  return ParamSymbol as Param<T>;
}

// Given an array type representing a query key pattern, replaces placeholder
// types with the concrete types for those placeholders.
// eg. `Param<string> => string`
type ConcreteQueryKey<TQueryKeyPattern> = {
  readonly [I in keyof TQueryKeyPattern]: TQueryKeyPattern[I] extends Param<infer K> ? K : TQueryKeyPattern[I];
};

export type TypedQuery<
  TQueryKeyPattern extends QueryKey = QueryKey,
  TPageParam = never,
  R = unknown,
  TQueryKey extends QueryKey = any,
> = {
  readonly queryKeyPattern: TQueryKeyPattern;
  readonly query: (context: QueryFunctionContext<TQueryKey, TPageParam>) => Promise<R>;
};

export function typedQuery<const TQueryKeyPattern extends QueryKey, TPageParam, R>(
  queryKeyPattern: TQueryKeyPattern,
  query: (context: QueryFunctionContext<ConcreteQueryKey<TQueryKeyPattern>, TPageParam>) => Promise<R>,
): TypedQuery<TQueryKeyPattern, TPageParam, R, ConcreteQueryKey<TQueryKeyPattern>> {
  return {queryKeyPattern, query};
}

export type AnyQueryReturnType<TAnyQuery, TQueryKey extends QueryKey> = TQueryKey extends any
  ? TAnyQuery extends TypedQuery<any, any, infer R, TQueryKey>
    ? R
    : never
  : never;

export type AnyQueryPageParam<TAnyQuery, TQueryKey extends QueryKey> = TAnyQuery extends TypedQuery<
  infer TQueryKeyPattern,
  infer TPageParam,
  any
>
  ? TQueryKey extends ConcreteQueryKey<TQueryKeyPattern>
    ? TPageParam
    : never
  : never;

export type AnyQueryKey<TAnyQuery> = TAnyQuery extends TypedQuery<infer TQueryKeyPattern, any, any>
  ? ConcreteQueryKey<TQueryKeyPattern>
  : never;

type RecursivePartial<T> = T extends object
  ? {
      [K in keyof T]?: RecursivePartial<T[K]>;
    }
  : T;

type RecursivePartialMap<T> = {
  [K in keyof T]: RecursivePartial<T[K]>;
};

export type AnyQueryKeyFilter<TQueryKey> = TQueryKey extends readonly [...infer P, any]
  ? RecursivePartialMap<TQueryKey> | AnyQueryKeyFilter<Readonly<P>>
  : RecursivePartialMap<TQueryKey>;

export type AnyQueryFilters<TAnyQuery> = {
  type?: "active" | "inactive" | "all";
  predicate?: (query: {queryKey: AnyQueryKey<TAnyQuery>}) => boolean;
  stale?: boolean;
  fetchStatus?: FetchStatus;
} & (
  | {exact?: false; queryKey?: AnyQueryKeyFilter<AnyQueryKey<TAnyQuery>>}
  | {exact: true; queryKey?: AnyQueryKey<TAnyQuery>}
);
