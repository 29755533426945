import {Navigate, Route, useMatch} from "react-router-dom";
import {ButtonGroup, Flex, Heading, HStack, useDisclosure} from "@chakra-ui/react";
import Page from "../../../../components/Page.tsx";
import {memo, useEffect} from "react";
import metaTab from "./Meta/index.tsx";
import questionsTab from "./Questions/index.tsx";
import progressTab from "./Progress/index.tsx";
import exportTab from "./Export/index.tsx";
import documentationTab from "./Documentation/index.tsx";
import ArchiveModal from "./ArchiveModal.tsx";
import AutoCompleteButton from "./AutoCompleteButton.tsx";
import {router} from "../../../../router/index.tsx";
import {useQueriesData, useQueryData} from "../../../../state/index.ts";
import {PromptModifiers, Questionnaire, QuestionnaireId, QuestionnaireStatus} from "../../../../Types.ts";
import api from "../../../../api/index.ts";
import {
  ExportReadyBanner,
  QuestionnaireAutomatingBanner,
  QuestionnaireCreatedBanner,
  QuestionnaireImportingBanner,
} from "./QuestionnaireBanners.tsx";
import {RouteTab, tabbedRoute} from "../../../../components/RouterTabs/index.tsx";
import Question from "../Question/index.tsx";
import {usePromiseState} from "../../../../hooks/promiseState.ts";
import {withSuspense} from "../../../../state/withSuspense.tsx";
import {DeleteModalIconButton} from "../../../../components/DeleteModalButton.tsx";
import TabsOutlet from "../../../../components/RouterTabs/Outlet.tsx";
import AnsweringActionBar from "../../../components/AnsweringActionBar.tsx";
import CopyBackButton from "./CopyBackButton.tsx";
import ModifiersButton from "../../components/PromptModifiers/ModifiersButton.tsx";
import {Type} from "../../../../typing";
import {withTypedProps} from "../../../../typing/typedProps";
import {useTypedParams} from "../../../../hooks/typedParams";
import {Internal} from "../../../../components/InternalMode";

export type TabOutletContext = {questionnaire: Questionnaire};

export const DeleteQuestionnaireButton = ({questionnaire_id}: {questionnaire_id: QuestionnaireId}) => {
  const {isOpen, onOpen, onClose} = useDisclosure();
  const [deletingQuestionnaire, deleteQuestionnaire] = usePromiseState(async () => {
    await api.vendorToolkit.questionnaires.deleteQuestionnaire(questionnaire_id);
    router!.navigate("../../");
  }, [questionnaire_id]);

  return (
    <DeleteModalIconButton
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      delete_={deleteQuestionnaire}
      deleting={deletingQuestionnaire.inProgress}
      modalHeader="Delete questionnaire"
      modalBody="Are you sure you want to delete this questionnaire?"
    />
  );
};

const QuestionnaireViewer = withSuspense(
  memo(() => {
    const {questionnaireId} = useTypedParams(QuestionnaireParams);
    const possible_match = useMatch("/vendor-toolkit/questionnaires/:questionnaireId/:tab");
    const tab = possible_match ? possible_match.params.tab : "";

    const [questionnaire, whoami] = useQueriesData({
      queries: [{queryKey: ["vendorToolkit", "questionnaire", questionnaireId]}, {queryKey: ["whoAmI"]}],
    });

    // Redirect to the historical questionnaires pane if the archived_at property becomes set
    useEffect(() => {
      if (questionnaire.archived_at) {
        router!.navigate(`/vendor-toolkit/library/historical-questionnaires/${questionnaire.questionnaire_id}/meta`, {
          replace: true,
        });
      }
    }, [questionnaire.questionnaire_id, questionnaire.archived_at]);

    const [updatingPromptModifiers, updatePromptModifiers] = usePromiseState(
      async (newValue: PromptModifiers) => {
        await api.vendorToolkit.questionnaires.updatePromptModifiers(questionnaireId, newValue);
      },
      [questionnaireId],
    );

    const question_stats = questionnaire.question_stats;
    const banner =
      questionnaire.status === QuestionnaireStatus.Importing ? (
        <QuestionnaireImportingBanner />
      ) : questionnaire.status === QuestionnaireStatus.Automating ? (
        <QuestionnaireAutomatingBanner />
      ) : question_stats.count === 0 ? (
        <QuestionnaireCreatedBanner questionnaire={questionnaire} />
      ) : questionnaire.status === QuestionnaireStatus.Complete && tab != "export" ? (
        <ExportReadyBanner />
      ) : null;

    return (
      <Page title={questionnaire.name} display="flex" flexDirection="column">
        <AnsweringActionBar>
          <HStack justifyContent={"space-between"}>
            <Internal>
              <CopyBackButton
                target="Questionnaire"
                questionnaireId={questionnaire.questionnaire_id}
                buttonProps={{size: "sm"}}
              />
            </Internal>
            <ButtonGroup isAttached colorScheme="purple" ml="auto">
              <AutoCompleteButton
                target="Questionnaire"
                questionnaireId={questionnaire.questionnaire_id}
                isDisabled={questionnaire.status === QuestionnaireStatus.Complete}
                buttonProps={{size: "sm"}}
                tooltipLabel={'Only questions with status "Requires Response" will be answered.'}
              />
              <ModifiersButton
                value={questionnaire.prompt_modifiers}
                defaultValue={whoami.account!.prompt_modifiers}
                parentLabel="Account"
                onChange={updatePromptModifiers}
                isLoading={updatingPromptModifiers.inProgress}
                isDisabled={updatingPromptModifiers.inProgress}
              />
            </ButtonGroup>
          </HStack>
        </AnsweringActionBar>
        <Flex px={8} pt="6" pb="4" justifyContent="space-between" align="center">
          <Heading size="md" p="0" color={questionnaire.is_internal ? "red.600" : undefined}>
            {questionnaire.name}
          </Heading>
          <Flex ml="auto" gap="4">
            <ArchiveModal questionnaire={questionnaire} />
            <DeleteQuestionnaireButton questionnaire_id={questionnaire.questionnaire_id} />
          </Flex>
        </Flex>
        <TabsOutlet banner={banner} context={{questionnaire}} />
      </Page>
    );
  }),
);

const tabs: RouteTab[] = [metaTab, questionsTab, progressTab, documentationTab, exportTab];

export const QuestionnaireParams = Type.Object({questionnaireId: Type.QuestionnaireId()});

const Crumb = withTypedProps(QuestionnaireParams, ({questionnaireId}) => {
  return useQueryData({queryKey: ["vendorToolkit", "questionnaire", questionnaireId]}).name;
});

function RedirectToQuestions() {
  const {id} = useTypedParams(Type.Object({id: Type.Optional(Type.QuestionId())}));
  const path = id === undefined ? "../questions" : `../questions/${id}`;
  return <Navigate replace to={path} />;
}

export default (
  <Route path=":questionnaireId" handle={{Crumb}}>
    {/* The main questionnaire tabs */}
    {tabbedRoute({tabs, element: <QuestionnaireViewer />})}
    <Route path="questions" handle={{crumb: "Questions"}}>
      {Question}
    </Route>

    {/* We used to have to manage, respond and review paths, but they have been removed.
        We want to maintain these paths so we don't break old links.
    */}
    <Route path="manage" element={<RedirectToQuestions />} />
    <Route path="respond" element={<RedirectToQuestions />} />
    <Route path="review" element={<RedirectToQuestions />} />
    <Route path="manage/:id" element={<RedirectToQuestions />} />
    <Route path="respond/:id" element={<RedirectToQuestions />} />
    <Route path="review/:id" element={<RedirectToQuestions />} />
  </Route>
);
