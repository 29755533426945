import {withSuspense} from "../../../../state/withSuspense";
import {useQueryData} from "../../../../state";
import {Flex, FormControl, FormLabel, HStack, Heading, useDisclosure} from "@chakra-ui/react";
import {useCallback} from "react";
import api from "../../../../api";
import CommitInput from "../../../../components/CommitInput";
import CommitTextarea from "../../../../components/CommitTextarea";
import {DeleteModalIconButton} from "../../../../components/DeleteModalButton";
import {usePromiseState} from "../../../../hooks/promiseState";
import {router} from "../../../../router";
import {ContentId} from "../../../../Types";
import {Type} from "../../../../typing";
import {useTypedParams} from "../../../../hooks/typedParams";

const DeleteContentButton = ({contentId}: {contentId: ContentId}) => {
  const {isOpen, onOpen, onClose} = useDisclosure();
  const [deletingContent, deleteContent] = usePromiseState(async () => {
    await api.vendorToolkit.trustCenter.deleteContent(contentId);
    onClose();
    router!.navigate("/vendor-toolkit/trust-center/contents");
  }, [contentId, onClose]);

  return (
    <DeleteModalIconButton
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      delete_={deleteContent}
      deleting={deletingContent.inProgress}
      modalHeader="Delete content"
      modalBody="Are you sure you want to delete this content?"
    />
  );
};

export const ContentParams = Type.Object({contentId: Type.ContentId()});

const ContentPage = withSuspense(() => {
  const {contentId} = useTypedParams(ContentParams);
  const content = useQueryData({queryKey: ["vendorToolkit", "trustCenterContent", contentId]});
  const updateTitle = useCallback(
    async (value: string) => {
      await api.vendorToolkit.trustCenter.updateContentTitle(contentId, value);
    },
    [contentId],
  );
  const updateContent = useCallback(
    async (value: string) => {
      await api.vendorToolkit.trustCenter.updateContentContent(contentId, value);
    },
    [contentId],
  );

  return (
    <Flex direction="column" flex={1} p={8} gap={4}>
      <HStack justifyContent="space-between">
        <Heading flex="1" size="lg">
          {content.title}
        </Heading>
        <DeleteContentButton contentId={contentId} />
      </HStack>
      <FormControl>
        <FormLabel>Title</FormLabel>
        <CommitInput onCommit={updateTitle} value={content.title} />
      </FormControl>

      <FormControl>
        <FormLabel>Content</FormLabel>
        <CommitTextarea onCommit={updateContent} value={content.content} rows={20} />
      </FormControl>
    </Flex>
  );
});

export default ContentPage;
