import {Menu, MenuItemOption, MenuOptionGroup, TagLabel, Text} from "@chakra-ui/react";
import {usePromiseState} from "../../../../hooks/promiseState";
import {useQueryData} from "../../../../state";
import {EsignatureProviderId, EsignatureProviderMin, EsignatureTemplateId} from "../../../../Types";
import {unreachableCase} from "../../../../utils/typescript";
import React, {useMemo} from "react";
import _ from "lodash";
import TagMenuButton from "../../../../components/TagMenuButton";
import PortalMenuList from "../../../../components/PortalMenuList";

type Props = {
  selectedTemplateId: EsignatureTemplateId | null;
  onChangeSelectedTemplateId?: (templateId: EsignatureTemplateId | null) => void;
};

type RenderTemplate = {
  id: EsignatureTemplateId;
  name: string;
};

type RenderProvider = {
  id: EsignatureProviderId;
  name: string;
  templates: RenderTemplate[];
};

function renderProviderFullName(provider: EsignatureProviderMin) {
  const suffix = (() => {
    switch (provider.config.type) {
      case "Docusign":
        return provider.config.content.account_name;
      default:
        unreachableCase(provider.config.type);
    }
  })();

  return provider.name + (suffix ? ` (${suffix})` : "");
}

function MenuGroupHeader({children}: {children: React.ReactNode}) {
  return (
    <Text my="2" marginInlineStart="4" fontSize="sm" fontWeight="semibold">
      {children}
    </Text>
  );
}

function EsignatureTemplateSelector({selectedTemplateId, onChangeSelectedTemplateId}: Props) {
  const templates = useQueryData({queryKey: ["vendorToolkit", "esignatureTemplates"]});

  const [updating, update] = usePromiseState(
    async (raw: string | string[]) => {
      // `MenuOptionGroup` is of type `radio`, so `raw` is always a single string.
      const newValue = raw !== "none" ? (raw as EsignatureTemplateId) : null;

      await onChangeSelectedTemplateId?.(newValue);
    },
    [onChangeSelectedTemplateId],
  );

  // Convert templates from the API into a structure that maps to the UI.
  const renderProviders = useMemo(
    () =>
      _.map(
        _.groupBy(templates, t => t.provider.esignature_provider_id),
        (templates, providerId): RenderProvider => {
          const [{provider}] = templates;

          return {
            id: providerId as EsignatureProviderId,
            name: renderProviderFullName(provider),
            templates: templates.map(t => ({
              id: t.template_id,
              name: t.name,
            })),
          };
        },
      ).sort((a, b) => a.name.localeCompare(b.name)),
    [templates],
  );

  const selectedValue = selectedTemplateId ?? "none";

  const selectedName = useMemo(() => {
    if (selectedValue === "none") {
      return "None";
    }

    const template = templates.find(t => t.template_id === selectedValue);

    if (!template) {
      return "<Unknown>";
    }

    return template.name;
  }, [templates, selectedValue]);

  return (
    <Menu isLazy>
      <TagMenuButton colorScheme="gray" isLoading={updating.inProgress} isDisabled={!onChangeSelectedTemplateId}>
        <TagLabel>{selectedName}</TagLabel>
      </TagMenuButton>
      <PortalMenuList fontSize="md" onClick={e => e.stopPropagation()}>
        <MenuOptionGroup type="radio" value={selectedValue} onChange={update}>
          <MenuItemOption value="none">None</MenuItemOption>
          {renderProviders.map(p =>
            [
              <MenuGroupHeader key={p.id}>{p.name}</MenuGroupHeader>,
              p.templates.map(t => (
                <MenuItemOption key={t.id} value={t.id}>
                  {t.name}
                </MenuItemOption>
              )),
            ].flat(),
          )}
        </MenuOptionGroup>
      </PortalMenuList>
    </Menu>
  );
}

export default EsignatureTemplateSelector;
