import {
  DocumentSourceConnector,
  DocumentSourceMin,
  DocumentSource,
  CreateDocumentSource,
  UpdateDocumentSource,
  DeleteDocumentSourceArgs,
  DocumentSourceId,
} from "../../Types";
import {invalidateQueries} from "../../state";
import jsonApi from "../jsonApi";

export async function listConnectors(): Promise<DocumentSourceConnector[]> {
  return await jsonApi.get<DocumentSourceConnector[]>("/vendor_toolkit/document_source_connectors");
}

export async function getConnector(connectorId: string): Promise<DocumentSourceConnector> {
  return await jsonApi.get<DocumentSourceConnector>(`/vendor_toolkit/document_source_connectors/${connectorId}`);
}

export async function list(): Promise<DocumentSourceMin[]> {
  return await jsonApi.get<DocumentSourceMin[]>("/vendor_toolkit/document_sources");
}

export async function get(documentSourceId: DocumentSourceId): Promise<DocumentSource> {
  return await jsonApi.get<DocumentSource>(`/vendor_toolkit/document_sources/${documentSourceId}`);
}

export async function create(document: CreateDocumentSource): Promise<DocumentSource> {
  const res = await jsonApi.post<DocumentSource>("/vendor_toolkit/document_sources", document);
  await invalidateQueries([{queryKey: ["vendorToolkit", "documentSources"]}]);
  return res;
}

export async function delete_(documentSourceId: DocumentSourceId, args: DeleteDocumentSourceArgs): Promise<"ok"> {
  const res = await jsonApi.delete_<"ok">(
    `/vendor_toolkit/document_sources/${documentSourceId}?${jsonApi.encodeQuery(args)}`,
  );
  await invalidateQueries([
    {queryKey: ["vendorToolkit", "documentSource", documentSourceId]},
    {queryKey: ["vendorToolkit", "documentSources"]},
    {queryKey: ["vendorToolkit", "documents"]},
  ]);
  return res;
}

export async function sync(documentSourceId: DocumentSourceId) {
  const res = await jsonApi.post<"ok">(`/vendor_toolkit/document_sources/${documentSourceId}/sync`);
  await invalidateQueries([
    {queryKey: ["vendorToolkit", "documentSource", documentSourceId]},
    {queryKey: ["vendorToolkit", "documentSources"]},
  ]);
  return res;
}

export async function update(documentSourceId: DocumentSourceId, update: UpdateDocumentSource) {
  const res = await jsonApi.post<"ok">(`/vendor_toolkit/document_sources/${documentSourceId}`, update);
  await invalidateQueries([
    {queryKey: ["vendorToolkit", "documentSource", documentSourceId]},
    {queryKey: ["vendorToolkit", "documentSources"]},
  ]);
  return res;
}
