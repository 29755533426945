import {useEffect} from "react";

export function useDebouncedEffect(fn: () => void, deps: React.DependencyList, time: number) {
  const dependencies = [...deps, time];
  useEffect(() => {
    const timeout = setTimeout(fn, time);
    return () => {
      clearTimeout(timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);
}
