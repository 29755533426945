import {Avatar, HStack, Link, LinkBox, LinkOverlay, Stack, Text} from "@chakra-ui/react";
import {KybEntry, PersonT} from "../config";
import {RedactedAvatar, RedactedText} from "./Redacted";
import {Fragment} from "react";

const Person = ({redact, name, src, role, url}: {redact?: boolean} & PersonT) => {
  const isLink = !redact && url;

  const A = redact ? RedactedAvatar : Avatar;
  const T = redact ? RedactedText : Text;
  const L = isLink ? LinkBox : Fragment;

  return (
    <L>
      <HStack
        fontSize="md"
        bg="white"
        py={2}
        px={4}
        rounded="md"
        border="1px"
        borderColor="gray.100"
        _hover={isLink ? {borderColor: "brand.300"} : {}}
      >
        <A src={src} name={name} size="sm" />
        <Stack spacing={0}>
          <T fontWeight="bold" color="gray.600" as="strong">
            {isLink && <LinkOverlay href={url} target="_blank" />}
            {name}
          </T>
          {role && (
            <T fontSize="sm" color="gray.500">
              {role}
            </T>
          )}
        </Stack>
      </HStack>
    </L>
  );
};

export function LegalStringEntry({value}: {value?: string}) {
  return value ?? "n/a";
}

export function LegalUrlEntry({value}: {value?: string[]}) {
  return value ? (
    <Stack flexWrap="wrap">
      {value.map((w, j) => (
        <Link display="block" href={w} key={j} target="_blank" color="blue.500" alignSelf="flex-start">
          {w}
        </Link>
      ))}
    </Stack>
  ) : (
    "n/a"
  );
}

export function LegalPeopleEntry({value}: {value: PersonT[]}) {
  return value.length > 0 ? (
    <HStack flexWrap="wrap">
      {value.map((p, j) => (
        <Person {...p} key={j} />
      ))}
    </HStack>
  ) : (
    "n/a"
  );
}

export function LegalEntry({entry}: {entry: KybEntry}) {
  switch (entry.type) {
    case "STRING":
      return <LegalStringEntry value={entry.value} />;
    case "URL":
      return <LegalUrlEntry value={entry.value} />;
    case "ENTITIES":
      return <LegalPeopleEntry value={entry.value} />;
  }
}
