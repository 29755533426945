export type Group<K, T> = {
  key: K;
  items: T[];
};

const groupBy = <T, K extends keyof any>(list: T[], getKey: (item: T) => K) => {
  const map = new Map<K, number>();
  const result: Group<K, T>[] = [];
  for (const item of list) {
    const key = getKey(item);
    if (map.has(key)) {
      result[map.get(key)!].items.push(item);
    } else {
      map.set(key, result.length);
      result.push({
        key,
        items: [item],
      });
    }
  }
  return result;
};

export default groupBy;
