import {redirect, Route} from "react-router-dom";
import Layout from "./Layout.tsx";
import MailingList from "./pages/MailingList.tsx";
import PendingUpdates from "./pages/PendingUpdates.tsx";
import SentUpdates from "./pages/SentUpdates.tsx";

export default (
  <Route id="Notification center" path="notification-center" handle={{crumb: "Notification Center"}} Component={Layout}>
    <Route index loader={() => redirect("pending-updates")} />
    <Route path="pending-updates" element={<PendingUpdates />} />
    <Route path="sent-updates" element={<SentUpdates />} />
    <Route path="mailing-list" element={<MailingList />} />
  </Route>
);
