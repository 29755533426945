import {LayerType} from "../../../../Types.ts";

import {Button, HStack, Icon, Menu, MenuButton, MenuItemOption, MenuOptionGroup, Text} from "@chakra-ui/react";
import {useLayerType} from "../../../../hooks/layerType.ts";
import {useCallback} from "react";
import {Internal} from "../../../../components/InternalMode.tsx";
import {ChevronDownIcon} from "@heroicons/react/20/solid";
import {LAYER_SELECTOR_MAP} from "./index.tsx";
import PortalMenuList from "../../../../components/PortalMenuList.tsx";

const LayerSelector = () => {
  const [layerType, setLayerType] = useLayerType();
  const {text} = LAYER_SELECTOR_MAP[layerType];

  const change = useCallback(
    async (val: string | string[]) => {
      setLayerType(val as LayerType);
    },
    [setLayerType],
  );

  return (
    <Internal>
      <Menu isLazy>
        <MenuButton
          as={Button}
          fontSize="sm"
          textTransform="uppercase"
          h="18px"
          borderRadius={2}
          p={0}
          px={1}
          borderLeftRadius="0"
        >
          <HStack spacing="1" justifyContent="space-between">
            <Text>{text}</Text>
            <Icon as={ChevronDownIcon} />
          </HStack>
        </MenuButton>
        <PortalMenuList fontSize="md" onClick={e => e.stopPropagation()}>
          <MenuOptionGroup type="radio" onChange={change} value={layerType}>
            {Object.keys(LayerType).map(c => (
              <MenuItemOption key={c} value={c}>
                {LAYER_SELECTOR_MAP[c].text}
              </MenuItemOption>
            ))}
          </MenuOptionGroup>
        </PortalMenuList>
      </Menu>
    </Internal>
  );
};

export default LayerSelector;
