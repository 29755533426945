import {AccordionPanel, AccordionPanelProps, Avatar, AvatarProps, Icon, Text, TextProps} from "@chakra-ui/react";
import {LockClosedIcon} from "@heroicons/react/20/solid";
import {useTrustCenterContext} from "../hooks/trustCenterContext";

export const RedactedAccordionPanel = (props: AccordionPanelProps) => {
  const {loggedIn} = useTrustCenterContext();
  return loggedIn ? <AccordionPanel {...props} /> : null;
};

export const RedactedText = (props: TextProps) => {
  const {loggedIn} = useTrustCenterContext();

  return !loggedIn ? (
    <Text {...props}>
      <Icon as={LockClosedIcon} mb="-1px" color="gray.500" />
      <Text as="span" display="inline" ml={2} color="transparent" textShadow="0 0 10px #333">
        {props.children}
      </Text>
    </Text>
  ) : (
    <Text {...props} />
  );
};

export const RedactedAvatar = ({name, src, ...props}: AvatarProps) => {
  const {loggedIn} = useTrustCenterContext();

  return !loggedIn ? <Avatar {...props} /> : <Avatar name={name} src={src} {...props} />;
};
