import {Icon, IconButton, useDisclosure} from "@chakra-ui/react";
import {AddOrEditPersonModal} from "./AddOrEditPersonModal";
import {PencilIcon} from "@heroicons/react/24/solid";
import {SubGraph, EntityId} from "../../../../../Types";
import {Person} from "../../../graph/types/person";

type Props = {
  graph: SubGraph;
  person: Person;

  /** Parent entity to add or remove relationships */
  parentEntityId: EntityId;
};

export const EditPersonButton = (props: Props) => {
  const {isOpen, onOpen, onClose} = useDisclosure();

  return (
    <>
      <IconButton
        aria-label="Edit"
        icon={<Icon as={PencilIcon} />}
        onClick={onOpen}
        bgColor="white"
        variant="outline"
        color="gray.500"
      />
      <AddOrEditPersonModal
        kind="edit"
        person={props.person}
        isOpen={isOpen}
        onClose={onClose}
        graph={props.graph}
        parentEntityId={props.parentEntityId}
      />
    </>
  );
};
