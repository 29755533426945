import {SharingClassification} from "../../../../Types";

type SharingClassificationInfo = {
  text: string;
  color: string;
};

export const SHARING_CLASSIFICATION_MAP: {[sharing: string]: SharingClassificationInfo} = {
  [SharingClassification.Internal]: {
    text: "Internal only",
    color: "red",
  },
  [SharingClassification.NDA]: {
    text: "Under NDA",
    color: "orange",
  },
  [SharingClassification.External]: {
    text: "Free to share",
    color: "green",
  },
};
