import {ScopeAxisId, ScopeMin, nominate} from "../../../../Types";
import {
  Box,
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  HStack,
  Select,
  useToken,
} from "@chakra-ui/react";
import {memo} from "react";
import api from "../../../../api";
import {useModalState} from "../../../../hooks/modalState";
import {useModalValidationState, useValidatedPromiseState} from "../../../../hooks/validationState";
import {useQueryData} from "../../../../state";

const NewScopeModal = memo(
  ({
    onCreate,
    isOpen,
    onClose,
    initialAxisId,
  }: {
    onCreate?: (c: ScopeMin) => void;
    isOpen: boolean;
    onClose: () => void;
    initialAxisId?: ScopeAxisId;
  }) => {
    const [textGray, textDefault] = useToken("colors", ["gray.400", "gray.800"]);
    const scopeAxes = useQueryData({queryKey: ["vendorToolkit", "scopeAxes"]});
    const [name, setName] = useModalState(isOpen, "");
    const [axisId, setAxisId] = useModalState<ScopeAxisId | null>(isOpen, initialAxisId ?? null);
    const [validationErrors, setValidationErrors] = useModalValidationState(isOpen, {name});
    const [creating, create] = useValidatedPromiseState(
      async () => {
        const scope = await api.vendorToolkit.scopes.create({
          name,
          axis_id: axisId!,
        });
        onClose();
        if (onCreate) {
          onCreate(scope);
        }
      },
      [name, axisId, onClose, onCreate],
      setValidationErrors,
    );

    return (
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent as="form" onSubmit={create}>
          <ModalHeader>Create new scope</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing="8" mb="4">
              <FormControl isRequired isInvalid={validationErrors.name !== undefined}>
                <FormLabel>Type</FormLabel>
                <Select
                  value={axisId ?? "<none>"}
                  onChange={e =>
                    setAxisId(e.target.value === "<none>" ? null : nominate("scopeAxisId", e.target.value))
                  }
                  style={axisId ? undefined : {color: textGray}}
                >
                  <option value="<none>" style={{color: textGray}}>
                    Select a type...
                  </option>
                  {scopeAxes.map(scopeAxis => (
                    <option key={scopeAxis.axis_id} value={scopeAxis.axis_id} style={{color: textDefault}}>
                      {scopeAxis.name}
                    </option>
                  ))}
                </Select>
              </FormControl>
              <FormControl isRequired isInvalid={validationErrors.name !== undefined}>
                <FormLabel>Name</FormLabel>
                <Input onChange={e => setName(e.target.value)} value={name} placeholder="Example Product" autoFocus />
                {validationErrors.name && validationErrors.name[0] && (
                  <FormErrorMessage>{validationErrors.name[0].message}</FormErrorMessage>
                )}
              </FormControl>

              {creating.lastError ? (
                <Alert status="error" mt="12">
                  <AlertIcon boxSize="40px" />
                  <Box>
                    <AlertTitle fontSize="md">Error creating scope</AlertTitle>
                    <AlertDescription fontSize="md">{`${creating.lastError}`}</AlertDescription>
                  </Box>
                </Alert>
              ) : null}
            </Stack>
          </ModalBody>

          <ModalFooter>
            <HStack spacing="3">
              <Button variant="ghost" onClick={onClose} isDisabled={creating.inProgress}>
                Cancel
              </Button>
              <Button
                colorScheme="blue"
                type="submit"
                isLoading={creating.inProgress}
                isDisabled={!axisId || creating.inProgress}
              >
                Create
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  },
);

export default NewScopeModal;
