import {GraphEntity, KnownEntityPropertyOfType, SubGraph, Relationship, EntityId} from "../../../Types";

/**
 * Get the first root entity in a graph.
 */
export function getFirstRootEntity(graph: SubGraph): GraphEntity | undefined {
  const id = graph.root_entity_ids[0];

  if (id === undefined) {
    return undefined;
  }

  const entity = graph.entities[id];

  if (entity === undefined) {
    return undefined;
  }

  return entity;
}

export type ChildAndRelationshipEntities = {
  relationship: string;
  relationshipEntity: GraphEntity;
  childEntity: GraphEntity;
};

/**
 * Get all child entities of a parent entity related by any of the given relationship names.
 */
export function getChildEntities(
  graph: SubGraph,
  relationships: KnownEntityPropertyOfType<"Relationship">[],
  parentEntityId: EntityId,
): ChildAndRelationshipEntities[] {
  const ret: ChildAndRelationshipEntities[] = [];
  const rootEntity = graph.entities[parentEntityId];

  if (rootEntity === undefined) {
    throw new Error(`
getChildEntities: \`parentEntityId\` ${parentEntityId} does not exist in
\`graph.entities\`.
    `);
  }

  for (const relationshipEntityId of rootEntity.link_ids) {
    const relationshipEntity = graph.entities[relationshipEntityId];

    if (relationshipEntity === undefined) {
      throw new Error(`
getChildEntities: tried to follow \`link_id\` ${relationshipEntityId} on entity ${parentEntityId},
but that entity does not exist in \`graph.entities\`.
          `);
    }

    for (const relationship of relationships) {
      const value = relationshipEntity.properties[relationship];

      if (value === undefined) {
        continue;
      }

      if (value.parent_entity_id !== parentEntityId) {
        continue;
      }

      const childEntity = graph.entities[value.child_entity_id];

      if (childEntity === undefined) {
        throw new Error(`
getChildEntities: tried to follow \`child_entity_id\` ${value.child_entity_id}
on relationship entity ${relationshipEntityId}, but that entity does not exist
in \`graph.entities\`.
        `);
      }

      ret.push({
        relationship,
        relationshipEntity: relationshipEntity,
        childEntity,
      });
    }
  }

  return ret;
}

export type ParentAndRelationshipEntities = {
  relationship: string;
  relationshipEntity: GraphEntity;
  parentEntity: GraphEntity;
};

/**
 * Get all child entities of a parent entity related by any of the given relationship names.
 */
export function getParentEntities(
  graph: SubGraph,
  relationships: KnownEntityPropertyOfType<"Relationship">[],
  childEntityId: EntityId,
): ParentAndRelationshipEntities[] {
  const ret: ParentAndRelationshipEntities[] = [];
  const rootEntity = graph.entities[childEntityId];

  if (rootEntity === undefined) {
    throw new Error(`
    getParentEntities: \`childEntityId\` ${childEntityId} does not exist in
\`graph.entities\`.
    `);
  }

  for (const relationshipEntityId of rootEntity.link_ids) {
    const relationshipEntity = graph.entities[relationshipEntityId];

    if (relationshipEntity === undefined) {
      throw new Error(`
      getParentEntities: tried to follow \`link_id\` ${relationshipEntityId} on entity ${childEntityId},
but that entity does not exist in \`graph.entities\`.
          `);
    }

    for (const relationship of relationships) {
      const value = relationshipEntity.properties[relationship];

      if (value === undefined) {
        continue;
      }

      if (value.child_entity_id !== childEntityId) {
        continue;
      }

      const parentEntity = graph.entities[value.parent_entity_id];

      if (parentEntity === undefined) {
        throw new Error(`
getChildEntities: tried to follow \`parent_entity_id\` ${value.parent_entity_id}
on relationship entity ${relationshipEntityId}, but that entity does not exist
in \`graph.entities\`.
        `);
      }

      ret.push({
        relationship,
        relationshipEntity: relationshipEntity,
        parentEntity,
      });
    }
  }

  return ret;
}

/**
 * Given a relationship name and parent and child entity IDs, return the
 * associated relationship entity, or `undefined` if the relationship does not
 * exist.
 */
export function getRelationshipEntity(
  graph: SubGraph,
  relationshipName: KnownEntityPropertyOfType<"Relationship">,
  parentEntityId: EntityId,
  childEntityId: EntityId,
): GraphEntity | undefined {
  const parentEntity = graph.entities[parentEntityId];

  if (parentEntity === undefined) {
    return undefined;
  }

  for (const relationshipEntityId of parentEntity.link_ids) {
    const relationshipEntity = graph.entities[relationshipEntityId];

    if (relationshipEntity === undefined) {
      throw new Error(`
Tried to follow \`link_id\` ${relationshipEntityId} on entity ${parentEntityId},
but that entity does not exist in \`graph.entities\`.
          `);
    }

    const property = relationshipEntity.properties[relationshipName];

    if (property === undefined) {
      continue;
    }

    if (property.parent_entity_id !== parentEntityId || property.child_entity_id !== childEntityId) {
      continue;
    }

    return relationshipEntity;
  }

  return undefined;
}

export function getRelationship(
  entity: GraphEntity,
  relationships: KnownEntityPropertyOfType<"Relationship">[],
): Relationship | undefined {
  const relationship = relationships.find(relationship => relationship in entity.properties);
  return relationship && entity.properties[relationship];
}
