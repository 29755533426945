import {useMemo} from "react";
import {EntityId, nominate} from "../../../Types";
import {Select} from "@chakra-ui/react";
import {useSubprocessors} from "../graph/types/subprocessor";
import {withSuspense} from "../../../state/withSuspense";
import SelectFallback from "../../../components/SelectFallback";

type Props = {
  value: EntityId | null;
  onChange: (entityId: EntityId | null) => void;
};

const NONE_OPTION_KEY = "<none>";

function toOption(value: EntityId | null): string {
  if (value === null) return NONE_OPTION_KEY;
  return value;
}

function fromOption(option: string): EntityId | null {
  if (option === NONE_OPTION_KEY) return null;
  return nominate("entityId", option);
}

const SubprocessorSelector = withSuspense(
  ({value, onChange}: Props) => {
    const subprocessors = useSubprocessors();

    const options = useMemo(
      () => [
        <option key={NONE_OPTION_KEY} value={NONE_OPTION_KEY}>
          None
        </option>,
        ...subprocessors.map(s => (
          <option key={s.id} value={s.id}>
            {s.name}
          </option>
        )),
      ],
      [subprocessors],
    );

    return (
      <Select value={toOption(value)} onChange={e => onChange(fromOption(e.target.value))}>
        {options}
      </Select>
    );
  },
  <SelectFallback />,
);

export default SubprocessorSelector;
