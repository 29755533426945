import Page from "../../../../components/Page";
import {Outlet} from "react-router-dom";
import SideBar from "./SideBar";

function Layout() {
  return (
    <Page title="Notification center" display="flex" flexDirection="row" position="relative">
      <SideBar />
      <Outlet />
    </Page>
  );
}

export default Layout;
