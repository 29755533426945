import {Box, Flex, Icon, MenuItem, MenuItemProps} from "@chakra-ui/react";
import {NavLinkProps, Link as RouterLink} from "react-router-dom";

const StyledMenuItem = ({
  children,
  iconComp,
  help,
  ...props
}: MenuItemProps & NavLinkProps & {iconComp: React.FC; help: string}) => (
  <MenuItem
    as={RouterLink}
    w="full"
    _hover={{textDecoration: "none", bg: "blue.50", color: "blue.500", svg: {color: "blue.400"}}}
    _focus={{textDecoration: "none", bg: "blue.50", color: "blue.500", svg: {color: "blue.400"}}}
    {...props}
    fontSize="md"
    fontWeight="500"
  >
    <Flex py="1">
      <Icon as={iconComp} h="6" mr="2" color="gray.400" />
      <Box>
        <Box>{children}</Box>
        <Box fontWeight="400" fontSize="sm" color="gray.500" pr="4">
          {help}
        </Box>
      </Box>
    </Flex>
  </MenuItem>
);

export default StyledMenuItem;
