import {Badge} from "@chakra-ui/react";
import {Internal} from "./InternalMode";

type Props = {
  distance: number;

  /** A distance less than this is considered a "close match". */
  closeThreshold?: number;

  /** A distance less than this is considered a "near match". */
  nearThreshold?: number;
};

function CosineDistanceBadge({distance, closeThreshold = 0.25, nearThreshold = 0.3}: Props) {
  return distance < closeThreshold ? (
    <Badge as="span" color="white" bg="green.500">
      Close match<Internal> ({distance.toFixed(2)})</Internal>
    </Badge>
  ) : distance < nearThreshold ? (
    <Badge as="span" color="white" bg="yellow.500">
      Near match<Internal> ({distance.toFixed(2)})</Internal>
    </Badge>
  ) : (
    <Internal>
      <Badge as="span" color="white" bg="gray.500">
        {distance.toFixed(2)}
      </Badge>
    </Internal>
  );
}

export default CosineDistanceBadge;
