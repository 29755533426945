import {Button, Icon, useDisclosure} from "@chakra-ui/react";
import {AddOrEditCoreResponseModal} from "./AddOrEditCoreResponseModal";
import {PlusIcon} from "@heroicons/react/20/solid";

export function CreateCoreResponseButton() {
  const modal = useDisclosure();

  return (
    <>
      <Button leftIcon={<Icon as={PlusIcon} />} colorScheme="green" alignSelf="flex-start" onClick={modal.onOpen}>
        Create core response
      </Button>
      {modal.isOpen && <AddOrEditCoreResponseModal isOpen={modal.isOpen} onClose={modal.onClose} />}
    </>
  );
}
