import {
  Box,
  Button,
  Flex,
  Heading,
  Highlight,
  Icon,
  LinkBox,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import {Link as RouterLink} from "react-router-dom";
import {OwnerT, QuestionnaireMin} from "../../../../Types.ts";
import Page from "../../../../components/Page.tsx";
import {ChevronRightIcon, PlusIcon} from "@heroicons/react/20/solid";
import DueDate from "../../../../components/DueDate.tsx";
import Owner from "../../../../components/Owner.tsx";
import {ActionBar, ActionBarSearch} from "../../../../components/ActionBar.tsx";
import api from "../../../../api/index.ts";
import {memo, useCallback} from "react";
import Counterparty from "../../components/Counterparty.tsx";
import {computeProgress} from "../../../../utils/progress.tsx";
import {
  facetedSearchQuestionnaires,
  getQuestionnaireRelevantCounterparties,
  getQuestionnaireRelevantOwners,
  QuestionnaireFilters,
} from "../../../components/Filters/index.tsx";
import {useFacetedSearch} from "../../../../hooks/search.ts";
import QuestionnaireStatus from "../../components/QuestionnaireStatus/QuestionnaireStatus.tsx";
import {useFilterState} from "../../../../hooks/filterState.ts";
import LinkOverlay from "../../../../components/LinkOverlay.tsx";
import {useQueriesData, useQueryData} from "../../../../state/index.ts";
import {router} from "../../../../router/index.tsx";
import {withSuspense} from "../../../../state/withSuspense.tsx";
import FilterOwners from "../../../components/Filters/FilterOwners.tsx";
import FilterCounterparties from "../../../components/Filters/FilterCounterparties.tsx";
import FilterBanner from "../../../components/Filters/FilterBanner.tsx";

const QuestionnaireRow = ({
  questionnaire: {name, counterparty, owner, questionnaire_id, question_stats, due_date, status, is_internal},
  queries,
}: {
  questionnaire: QuestionnaireMin;
  queries: string[];
}) => {
  const onReassign = useCallback(
    async (owner?: OwnerT) => {
      await api.vendorToolkit.questionnaires.assign(questionnaire_id, owner?.owner_id ?? null);
    },
    [questionnaire_id],
  );

  const onNudge = useCallback(async () => {
    await api.vendorToolkit.questionnaires.nudge(questionnaire_id);
  }, [questionnaire_id]);

  return (
    <LinkBox as={Tr} transform="scale(1)" _hover={{bg: "gray.50", cursor: "pointer"}}>
      <Td fontSize="md" fontWeight="600" color={is_internal ? "red.600" : undefined}>
        <LinkOverlay as={RouterLink} to={`/vendor-toolkit/questionnaires/${questionnaire_id}`} />
        <Highlight query={queries} styles={{bg: "yellow.200"}}>
          {name}
        </Highlight>
      </Td>
      <Td>
        <Counterparty counterparty={counterparty} />
      </Td>
      <Td>
        <Owner owner={owner} onReassign={onReassign} onNudge={onNudge} />
      </Td>
      <Td>
        <DueDate dueDate={due_date} complete={status === "Complete"} />
      </Td>
      <Td>
        <QuestionnaireStatus status={status} />
      </Td>
      <Td>
        <Box>{computeProgress((question_stats.completed + question_stats.in_review) / question_stats.count)}</Box>
        <Flex h="4px" mt="1">
          <Box flex={question_stats.completed} bgColor="green.600" />
          <Box flex={question_stats.in_review} bgColor="green.300" />
          <Box flex={question_stats.count - question_stats.completed - question_stats.in_review} bgColor="gray.300" />
        </Flex>
      </Td>
      <Td>
        <Icon as={ChevronRightIcon} fontSize="24" color="gray.500" />
      </Td>
    </LinkBox>
  );
};

const DEFAULT_FILTERS = {
  owners: ["ASSIGNED_TO_ME", "ASSIGNED_TO_OTHERS", "UNASSIGNED"],
  counterparties: ["ALL"],
};

const QuestionnaireList = withSuspense(
  memo(() => {
    const [whoami, questionnaires] = useQueriesData({
      queries: [{queryKey: ["whoAmI"]}, {queryKey: ["vendorToolkit", "questionnaires", {historical: false}]}],
    });
    const owner = useQueryData({queryKey: ["resolvedOwner", {type: "User", content: whoami.user.user_id}]});
    const relevantOwners = getQuestionnaireRelevantOwners(questionnaires, owner);
    const relevantCounterparties = getQuestionnaireRelevantCounterparties(questionnaires);

    const {filters, setFilter, clearFilters, filterCount} = useFilterState<QuestionnaireFilters>(
      "questionnaireFilters",
      DEFAULT_FILTERS,
    );

    const {query, queries, setQuery, filter} = useFacetedSearch(
      q => q.name,
      facetedSearchQuestionnaires(filters, whoami.associated_owners),
      [filters, whoami.associated_owners],
    );
    const {result, counts} = filter(questionnaires);

    return (
      <Page title="Questionnaires">
        <Stack spacing={0}>
          <Heading size="md" p="8">
            Questionnaires
          </Heading>
          <ActionBar
            actionButtons={
              <>
                <FilterOwners
                  owners={filters.owners}
                  setOwners={newOwners => setFilter("owners", newOwners)}
                  relevantOwners={relevantOwners}
                  counts={counts.owner}
                />
                <FilterCounterparties
                  counterparties={filters.counterparties}
                  setCounterparties={newCounterparties => setFilter("counterparties", newCounterparties)}
                  allCounterparties={relevantCounterparties}
                  counts={counts.counterparty}
                />
                <Button
                  colorScheme="green"
                  leftIcon={<Icon as={PlusIcon} />}
                  onClick={() => router!.navigate("/vendor-toolkit/questionnaires/new")}
                >
                  Create questionnaire
                </Button>
              </>
            }
          >
            <ActionBarSearch value={query} onChange={e => setQuery(e.target.value)} />
          </ActionBar>
          <FilterBanner filterCount={filterCount} clearFilters={clearFilters} />
          <TableContainer maxWidth="100%" whiteSpace="normal" flex="1">
            <Table style={{borderCollapse: "separate", borderSpacing: "0"}} size="lg" layout="fixed">
              <Thead bg="white" borderBottom="10px solid" borderBottomColor="red.100">
                <Tr>
                  <Th width="15%" color="gray.500">
                    Name
                  </Th>
                  <Th width="13%" color="gray.500">
                    Counterparty
                  </Th>
                  <Th width="16%" color="gray.500">
                    Owner
                  </Th>
                  <Th width="11%" color="gray.500">
                    Due
                  </Th>
                  <Th width="18%" color="gray.500">
                    Status
                  </Th>
                  <Th width="10%" color="gray.500">
                    Progress
                  </Th>
                  <Th width="6%" color="gray.500" />
                </Tr>
              </Thead>
              <Tbody>
                {result.map((questionnaire, i) => (
                  <QuestionnaireRow questionnaire={questionnaire} queries={queries} key={i} />
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        </Stack>
      </Page>
    );
  }),
);

export default QuestionnaireList;
