import {Box, Flex, Image, LinkBox, HStack, Button} from "@chakra-ui/react";
import {Link as RouterLink, Outlet, useLocation} from "react-router-dom";
import UserMenu from "../Header/TopRightNav/UserMenu";
import {useQueryData} from "../state";
import LogoTextSVG from "../../assets/logo/logo-text.svg";
import {getRedirectParams} from "../utils/auth";

const UserFrame = () => {
  const whoami = useQueryData({queryKey: ["whoAmI"]});
  const location = useLocation();
  return (
    <Flex>
      <Flex flex="2" minH="100vh" bg="white" borderLeft="1px solid blue.700" maxW="1000px" px={[8, 16]} py={[8, 16]}>
        <Box w="full" data-testid="left-styled-frame">
          <Flex direction="row" justifyContent="space-between">
            <LinkBox as={RouterLink} to="/">
              <Image h="6" w="auto" mb="8" flexShrink="0" src={LogoTextSVG} alt="Platformed" />
            </LinkBox>
            <Box>
              <HStack spacing="4">
                {location.pathname !== "/account-selector" && (
                  <Button as={RouterLink} to={`/?${getRedirectParams()}`} color="blue.500" variant="outline">
                    <Box maxW="250px" textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap">
                      {whoami.account ? `Back to ${whoami.account.display_name}` : `Select account`}
                    </Box>
                  </Button>
                )}
                <UserMenu />
              </HStack>
            </Box>
          </Flex>
          <Outlet />
        </Box>
      </Flex>
      <Box flex={[0, 0, 0, 1, 2]} minH="100vh" bgGradient="linear(135deg, teal.400, cyan.600, blue.600, purple.700)" />
    </Flex>
  );
};

export default UserFrame;
