import {Stack} from "@chakra-ui/react";
import {AdHocQuestion, QuestionId} from "../../../Types";
import FilterBanner from "../../components/Filters/FilterBanner";
import {withSuspense} from "../../../state/withSuspense";
import {QuestionNavItem} from "../components/QuestionNavItem";
import {StepNav} from "../../components/StepNav";
import QuestionNumber from "../Questionnaires/components/QuestionNumber";

type QuestionNavProps = {
  questionId: QuestionId;
  clearFilters: () => void;
  filterCount: number;
  adhocQuestions: AdHocQuestion[];
  next?: AdHocQuestion;
  prev?: AdHocQuestion;
};

const QuestionNav = withSuspense(
  ({questionId, clearFilters, filterCount, adhocQuestions, next, prev}: QuestionNavProps) => {
    return (
      <Stack overflow="hidden" h="full" spacing={0}>
        <StepNav next={next?.question} previous={prev?.question} />
        <FilterBanner
          clearFilters={clearFilters}
          filterCount={filterCount}
          borderBottom="1px solid"
          borderColor="orange.200"
        />
        <Stack spacing="1" overflowY="auto" flex={1} pb={4}>
          {adhocQuestions.map(inner => (
            <QuestionNavItem
              key={inner.question.question_id}
              to={`../${inner.question.question_id}`}
              selected={questionId === inner.question.question_id}
            >
              <QuestionNumber question={inner.question} />
              {inner.question.text.trim()}
            </QuestionNavItem>
          ))}
        </Stack>
      </Stack>
    );
  },
);

export default QuestionNav;
