import {type Account} from "../Types";

import {Link as RouterLink} from "react-router-dom";
import {Box, Divider, Flex, HStack, Image, LinkBox} from "@chakra-ui/react";

import Search from "./Search";
import TopRightNav from "./TopRightNav/TopRightNav";
import BreadcrumbNav from "./BreadcrumbNav";
import {Fragment, ReactNode} from "react";
import {STICKY_NAV_HEIGHT, STICKY_NAV_OFFSET} from "../utils/sticky";
import {useHeaderBgColor} from "./headerColor";
import LogoWhite from "../../assets/logo/logo-white.svg";

export default function Header({
  account,
  children,
  bgBaseColor,
}: {
  account: Account;
  children?: ReactNode;
  bgBaseColor: string;
}) {
  const bgColor = `${bgBaseColor}.800`;
  const lowerBgColor = useHeaderBgColor(bgColor);
  return (
    <Fragment>
      <Box as="nav" zIndex="banner" bg={bgColor} position="relative">
        <Box mx="auto" maxW="7xl">
          <Flex mx="auto" maxW="7xl" h="16" alignItems="center" justify="space-between" px="8">
            <Flex alignItems="center">
              <LinkBox as={RouterLink} to="/product-selector">
                <Image h="5" w="auto" flexShrink="0" src={LogoWhite} alt="Platformed" />
              </LinkBox>
              <HStack ml="6">{children}</HStack>
            </Flex>

            <Flex flex="1" justify="end" px="2" ml="6" display="none">
              <Search />
            </Flex>
            <TopRightNav account={account} bgColor={bgColor} />
          </Flex>
          <Divider borderColor={`${bgBaseColor}.700`} />
        </Box>
      </Box>
      <Box
        as="nav"
        zIndex="sticky"
        borderBottom="1px solid"
        borderBottomColor={lowerBgColor}
        bg={lowerBgColor}
        position="sticky"
        top={`${STICKY_NAV_OFFSET}px`}
        // Must specify exact height because browsers will *#$! with 1px borders and make them fractional
        // on HiDPI displays, which will mess with the automatically determined height, which will in turn
        // add a sub-pixel gap between this header and the sticky elements.
        h={`${STICKY_NAV_HEIGHT}px`}
      >
        <Box mx="auto" maxW="7xl">
          <BreadcrumbNav />
        </Box>
      </Box>
    </Fragment>
  );
}
