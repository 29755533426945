import {DocumentCategory} from "../../../../Types";

type DocumentCategoryInfo = {
  text: string;
  color: string;
};

export const DOCUMENT_CATEGORY_MAP: {[category: string]: DocumentCategoryInfo} = {
  [DocumentCategory.Policy]: {
    text: "Policy",
    color: "gray",
  },
  [DocumentCategory.Certification]: {
    text: "Certification",
    color: "gray",
  },
  [DocumentCategory.Other]: {
    text: "Other",
    color: "gray",
  },
};
