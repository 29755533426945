import {Box, Button, Icon, Portal} from "@chakra-ui/react";
import {ChevronDoubleUpIcon} from "@heroicons/react/20/solid";
import {AnimatePresence, motion} from "framer-motion";
import {useCallback, useState} from "react";
import {useEventListener} from "usehooks-ts";

type Props = {
  /** Show only when the window has scrolled this far. (Default: `1000`) */
  minWindowScrollY?: number;
};

function BackToTopButton(props: Props) {
  const minWindowScrollY = props.minWindowScrollY ?? 1000;

  const [visible, setVisible] = useState(false);

  const handleClick = () => {
    // Using behaviour `instant` because `smooth` does not behave well with our
    // virtual lists.
    window.scrollTo({top: 0, behavior: "instant"});
  };

  const handleScroll = useCallback(() => {
    setVisible(window.scrollY > minWindowScrollY);
  }, [minWindowScrollY]);

  useEventListener("scroll", handleScroll);

  return (
    <Portal appendToParentPortal={false}>
      <AnimatePresence>
        {visible ? (
          <Box
            as={motion.div}
            position="fixed"
            bottom={0}
            right={0}
            m={8}
            zIndex="99999"
            initial={{opacity: 0, translateY: "8px"}}
            animate={{opacity: 1, translateY: "0px"}}
            exit={{opacity: 0, translateY: "8px"}}
          >
            <Button onClick={handleClick} leftIcon={<Icon as={ChevronDoubleUpIcon} />} colorScheme="purple">
              Back to top
            </Button>
          </Box>
        ) : null}
      </AnimatePresence>
    </Portal>
  );
}

export default BackToTopButton;
