import {ContentId} from "../../../Types";
import api from "../../../api";
import {param, typedQuery} from "../../typing";

export default [
  typedQuery(["vendorToolkit", "trustCenterConfig"], async () => {
    return await api.vendorToolkit.trustCenter.getConfig();
  }),
  typedQuery(["vendorToolkit", "trustCenterContents"], async () => {
    return await api.vendorToolkit.trustCenter.listContents();
  }),
  typedQuery(["vendorToolkit", "trustCenterContent", param<ContentId>("contentId")], async context => {
    return await api.vendorToolkit.trustCenter.getContent(context.queryKey[2]);
  }),
  typedQuery(["vendorToolkit", "trustCenterMailingList"], async () => {
    return await api.vendorToolkit.trustCenter.getMailingList();
  }),
];
