import {Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text} from "@chakra-ui/react";

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

function CantCreateScopeModal({isOpen, onClose}: Props) {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Create new scope</ModalHeader>
        <ModalBody fontSize="md">
          <Text as="span">To get started with scopes, contact </Text>
          <Text as="span" fontWeight="500">
            support@platformed.com
          </Text>
          <Text as="span"> to set up your scope types.</Text>
        </ModalBody>
        <ModalFooter>
          <Button variant="outline" colorScheme="blue" onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default CantCreateScopeModal;
