import {CreateLoginMethod, LoginMethod} from "../../../Types";
import {OAuthLoginMethod} from "./OAuthLoginMethod";
import {UsernamePasswordLoginMethod} from "./UsernamePasswordLoginMethod";

export type LoginMethodProps = {
  loginMethod: LoginMethod;
  isLoggedIn: boolean;
  isSelected: boolean;
  isDisabled: boolean;
  isRecommended: boolean;
  isConfigured: boolean;
  onUpsert: (loginMethod: LoginMethod, payload: CreateLoginMethod) => Promise<void>;
  onDelete?: (loginMethod: LoginMethod) => Promise<void>;
  onSelect?: (loginMethod: LoginMethod) => Promise<void>;
};

export function AnyLoginMethod(props: LoginMethodProps) {
  switch (props.loginMethod) {
    case LoginMethod.UsernamePassword:
      return <UsernamePasswordLoginMethod {...props} />;
    case LoginMethod.GoogleOAuth:
    case LoginMethod.MicrosoftOAuth:
      return <OAuthLoginMethod {...props} />;
  }
}
