import {QuestionSourceId} from "../../../../../Types";
import {useQueryData} from "../../../../../state";
import {Type} from "../../../../../typing";
import {withTypedProps} from "../../../../../typing/typedProps";

export const QuestionSourceParams = Type.Object({questionSourceId: Type.QuestionSourceId()});

const Crumb = withTypedProps(QuestionSourceParams, ({questionSourceId}: {questionSourceId: QuestionSourceId}) => {
  return useQueryData({queryKey: ["vendorToolkit", "questionSource", questionSourceId]}).name;
});

export default Crumb;
