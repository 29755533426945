import externalApi from "../../../api/external";
import {GetGraphQuery, ContentId, AccountId} from "../../../Types";
import {param, typedQuery} from "../../typing";

export default [
  typedQuery(["external", "trustCenterDocuments", param<AccountId>("accountId")], async context => {
    return await externalApi.trustCenters.listDocuments(context.queryKey[2]);
  }),
  typedQuery(["external", "trustCenterConfig", param<AccountId>("accountId")], async context => {
    return await externalApi.trustCenters.getConfig(context.queryKey[2]);
  }),
  typedQuery(["external", "trustCenterFaq", param<AccountId>("accountId")], async context => {
    return await externalApi.trustCenters.getFaq(context.queryKey[2]);
  }),
  typedQuery(["external", "trustCenterAiFaq", param<AccountId>("accountId")], async context => {
    return await externalApi.trustCenters.getAiFaq(context.queryKey[2]);
  }),
  typedQuery(
    ["external", "trustCenterContent", param<AccountId>("accountId"), param<ContentId>("contentId")],
    async context => {
      return await externalApi.trustCenters.getContent(context.queryKey[2], context.queryKey[3]);
    },
  ),
  typedQuery(
    ["external", "trustCenterGraph", param<AccountId>("accountId"), param<GetGraphQuery>("query")],
    async context => {
      return await externalApi.trustCenters.getGraph(context.queryKey[2], context.queryKey[3]);
    },
  ),
  typedQuery(["external", "trustCenterSchema", param<AccountId>("accountId")], async context => {
    return await externalApi.trustCenters.getSchema(context.queryKey[2]);
  }),
];
