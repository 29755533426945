import {ReactNode, useCallback, useMemo, useState} from "react";
import {LoginCredentials, UnauthorizedError} from "../Types";
import {FormControl, FormErrorMessage, FormLabel, HStack, PinInput, PinInputField} from "@chakra-ui/react";
import PasswordInput from "../components/PasswordInput";
import {useValidatedField} from "./validatedField";

export function useCredentials(overrideEmailOrPasswordError?: string): {
  credentials: LoginCredentials;
  credentialFields: ReactNode;
  handleCredentialsError: (e: unknown) => boolean;
} {
  const passwordField = useValidatedField("");
  const totpField = useValidatedField("");
  const [requiresTotp, setRequiresTotp] = useState(false);
  const credentials: LoginCredentials = useMemo(
    () => ({
      password: passwordField.value,
      totp_code: requiresTotp ? totpField.value : undefined,
    }),
    [passwordField.value, totpField.value, requiresTotp],
  );
  const handleCredentialsError = useCallback(
    (e: unknown) => {
      if (e instanceof UnauthorizedError) {
        switch (e.data.type) {
          case "Requires2FA":
            setRequiresTotp(e.data.content.totp);
            return true;
          case "IncorrectEmailOrPassword":
            passwordField.setError.call(
              null,
              overrideEmailOrPasswordError || "Your current password was entered incorrectly",
            );
            return true;
          case "Failed2FA":
            if (requiresTotp) {
              totpField.setError.call(null, "Incorrect 2FA code");
              return true;
            }
            break;
        }
      }
      return false;
    },
    [overrideEmailOrPasswordError, requiresTotp, passwordField.setError, totpField.setError],
  );

  const credentialFields = (
    <>
      <FormControl isInvalid={!!passwordField.error}>
        <FormLabel>Password</FormLabel>
        <PasswordInput
          placeholder="Password"
          value={passwordField.value}
          onChange={e => passwordField.setValue(e.target.value)}
          autoComplete="current-password password"
          autoFocus
        />
        <FormErrorMessage>{passwordField.error}</FormErrorMessage>
      </FormControl>

      {requiresTotp && (
        <FormControl isInvalid={!!totpField.error}>
          <FormLabel>Authenticator code</FormLabel>
          <HStack>
            <PinInput otp value={totpField.value} onChange={totpField.setValue} autoFocus>
              <PinInputField />
              <PinInputField />
              <PinInputField />
              <PinInputField />
              <PinInputField />
              <PinInputField />
            </PinInput>
          </HStack>
          <FormErrorMessage>{totpField.error}</FormErrorMessage>
        </FormControl>
      )}
    </>
  );

  return {
    credentials,
    credentialFields,
    handleCredentialsError,
  };
}
