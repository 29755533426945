import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Textarea,
  Text,
  HStack,
  Input,
  FormErrorMessage,
  Checkbox,
} from "@chakra-ui/react";
import {memo, useCallback} from "react";
import {DocumentExternal} from "../../Types";
import {useModalValidationState, useValidatedPromiseState} from "../../hooks/validationState";
import externalApi from "../../api/external";
import {useSessionState} from "../../hooks/sessionState";
import {useClientAccount} from "../hooks/clientAccount";
import {useModalState} from "../../hooks/modalState";

type RequestDocumentAccessModalProps = {
  isOpen: boolean;
  onClose: () => void;
  document: DocumentExternal;
};

const RequestDocumentAccessModalSuccess = ({onClose, nda}: {onClose: () => void; nda: boolean}) => {
  let title = "Document request sent";

  if (nda) {
    title += " - NDA required";
  }

  return (
    <ModalContent>
      <ModalHeader>{title}</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <Stack spacing={4}>
          <Alert status="success">
            <AlertIcon />
            <Box>
              <AlertDescription fontSize="lg">Your document request has been sent successfully.</AlertDescription>
            </Box>
          </Alert>
          {nda ? (
            <>
              <p>Access to this document requires signing an NDA. You should receive an email with the NDA shortly.</p>
              <p>Once you have signed the NDA, we will review your request.</p>
            </>
          ) : (
            <p>You will receive the requested documents via email once your request has been approved.</p>
          )}
        </Stack>
      </ModalBody>
      <ModalFooter>
        <Button variant="outline" onClick={onClose}>
          Close
        </Button>
      </ModalFooter>
    </ModalContent>
  );
};

type RequestDocumentAccessModalFormProps = RequestDocumentAccessModalProps & {
  onSuccess: (data: {alreadySignedNda: boolean}) => void;
};

const RequestDocumentAccessModalForm = ({
  isOpen,
  onSuccess,
  onClose,
  document,
}: RequestDocumentAccessModalFormProps) => {
  const account = useClientAccount();
  const [email, setEmail] = useSessionState("requestDocumentAccessModalEmail", "");
  const [name, setName] = useSessionState("requestDocumentAccessModalName", "");
  const [phone, setPhone] = useSessionState("requestDocumentAccessModalPhone", "");
  const [pointOfContact, setPointOfContact] = useSessionState("requestDocumentAccessModalPointOfContact", "");
  const [message, setMessage] = useSessionState("requestDocumentAccessModalMessage", "");
  const [alreadySignedNda, setAlreadySignedNda] = useSessionState("requestDocumentAccessModalAlreadySignedNda", false);

  const [validationErrors, setValidationErrors] = useModalValidationState(isOpen, {email, name});
  const [sending, send] = useValidatedPromiseState(
    async () => {
      await externalApi.trustCenters.requestDocumentAccess(account.account_id, document.document_id, {
        email,
        name,
        phone,
        point_of_contact: pointOfContact,
        message,
        already_signed_nda: alreadySignedNda,
      });

      onSuccess({alreadySignedNda});
    },
    [
      account.account_id,
      document.document_id,
      email,
      name,
      phone,
      pointOfContact,
      message,
      alreadySignedNda,
      onSuccess,
    ],
    setValidationErrors,
  );

  return (
    <ModalContent as="form" onSubmit={send}>
      <ModalHeader>Request access to {document.name}</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <Stack spacing={4}>
          {document.requires_esignature && (
            <>
              <Alert status="info">
                <AlertIcon boxSize="40px" />
                <Box>
                  <AlertTitle fontSize="md">Requires NDA</AlertTitle>
                  <AlertDescription fontSize="md">
                    To access this document, an NDA must be signed. After submitting this form, you will receive the NDA
                    by email.
                  </AlertDescription>
                </Box>
              </Alert>
              <FormControl>
                <Checkbox
                  isChecked={alreadySignedNda}
                  onChange={e => setAlreadySignedNda(e.target.checked)}
                  fontWeight={500}
                >
                  I have already signed an NDA
                </Checkbox>
              </FormControl>
            </>
          )}
          <Text fontSize="md" fontStyle="italic">
            You will receive the requested documents via email once your request has been approved.
          </Text>
          <FormControl isRequired>
            <FormLabel>Your email</FormLabel>
            <Input value={email} onChange={e => setEmail(e.target.value)} autoFocus />
            {validationErrors.email && validationErrors.email[0] && (
              <FormErrorMessage>{validationErrors.email[0].message}</FormErrorMessage>
            )}
          </FormControl>
          <FormControl isRequired>
            <FormLabel>Your name</FormLabel>
            <Input value={name} onChange={e => setName(e.target.value)} />
            {validationErrors.name && validationErrors.name[0] && (
              <FormErrorMessage>{validationErrors.name[0].message}</FormErrorMessage>
            )}
          </FormControl>
          <FormControl>
            <FormLabel>Your phone number</FormLabel>
            <Input value={phone} onChange={e => setPhone(e.target.value)} />
          </FormControl>
          <FormControl>
            <FormLabel>Point of Contact at {account.display_name}</FormLabel>
            <Input value={pointOfContact} onChange={e => setPointOfContact(e.target.value)} />
          </FormControl>
          <FormControl>
            <FormLabel>Additional information</FormLabel>
            <Textarea value={message} onChange={e => setMessage(e.target.value)} rows={6} />
          </FormControl>
          {sending.lastError ? (
            <Alert status="error">
              <AlertIcon boxSize="40px" />
              <Box>
                <AlertTitle fontSize="md">Error sending request</AlertTitle>
                <AlertDescription fontSize="md">{`${sending.lastError}`}</AlertDescription>
              </Box>
            </Alert>
          ) : null}
        </Stack>
      </ModalBody>
      <ModalFooter>
        <HStack spacing="3">
          <Button variant="outline" onClick={onClose}>
            Cancel
          </Button>
          <Button
            type="submit"
            colorScheme="blue"
            isLoading={sending.inProgress}
            isDisabled={sending.inProgress || !email.trim() || !name.trim()}
          >
            Send request
          </Button>
        </HStack>
      </ModalFooter>
    </ModalContent>
  );
};

export const RequestDocumentAccessModal = memo((props: RequestDocumentAccessModalProps) => {
  type Stage = {type: "form"} | {type: "success"; nda: boolean};

  const [stage, setStage] = useModalState<Stage>(props.isOpen, {type: "form"});

  const handleSuccess = useCallback(
    (data: {alreadySignedNda: boolean}) => {
      setStage({type: "success", nda: props.document.requires_esignature && !data.alreadySignedNda});
    },
    [props.document.requires_esignature, setStage],
  );

  return (
    <>
      <Modal isOpen={props.isOpen} onClose={props.onClose} size="xl">
        <ModalOverlay />
        {props.isOpen && (
          <>
            {stage.type === "form" && <RequestDocumentAccessModalForm {...props} onSuccess={handleSuccess} />}
            {stage.type === "success" && <RequestDocumentAccessModalSuccess onClose={props.onClose} nda={stage.nda} />}
          </>
        )}
      </Modal>
    </>
  );
});
