import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Icon,
  Input,
  Stack,
} from "@chakra-ui/react";
import Page from "../../../../components/Page.tsx";
import {Counterparty} from "../../../../Types.ts";
import {useState} from "react";
import {PlusIcon} from "@heroicons/react/20/solid";
import api from "../../../../api/index.ts";
import {CounterpartyHeading, CounterpartySelector} from "../../components/Counterparty.tsx";
import {router} from "../../../../router/index.tsx";
import {useValidatedPromiseState, useValidationState} from "../../../../hooks/validationState.ts";
import {MultiFileUpload} from "../../../../components/fileUploads/MultiFileUpload.tsx";
import {FileUpload, useFileUploads} from "../../../../hooks/fileUpload.ts";
import {useAbortableState} from "../../../../hooks/asyncOperation.ts";
import {useQueryData} from "../../../../state/index.ts";

const NewHistoricalQuestionnaire = () => {
  const {user} = useQueryData({queryKey: ["whoAmI"]});
  const [name, setName] = useState("");
  const [counterparty, setCounterparty] = useState<Counterparty | undefined>(undefined);
  const [validationErrors, setValidationErrors] = useValidationState({name});
  const [fileUploads, setFileUploads] = useAbortableState<FileUpload[]>([]);
  const [uploads, isUploading] = useFileUploads(fileUploads);

  const [creating, create] = useValidatedPromiseState(
    async () => {
      const {questionnaire_id} = await api.vendorToolkit.questionnaires.create({
        name,
        counterparty_id: counterparty!.counterparty_id,
        archived: true,
      });

      for (const upload of uploads) {
        await api.vendorToolkit.questionnaires.createOriginalFile(questionnaire_id, upload.file_id);
      }

      const body = `${user.name} requested a historical questionnaire import on ${questionnaire_id}.\n`;
      await api.supportEmails.send({
        action: "import",
        subject: "Historical questionnaire import request",
        body,
        attachments: uploads.map(u => u.file_id),
        cc_user: false,
      });
      await router!.navigate(`/vendor-toolkit/library/historical-questionnaires/${questionnaire_id}`);
    },
    [name, counterparty, uploads, user],
    setValidationErrors,
  );

  return (
    <Page title="New questionnaire">
      <Heading size="md" p="8">
        Import historical questionnaire
      </Heading>
      <Box as="form" onSubmit={create} p={8}>
        <Stack maxW="96" spacing={8}>
          <FormControl isInvalid={validationErrors.name !== undefined} isRequired>
            <FormLabel>Questionnaire name</FormLabel>
            <Input value={name} onChange={e => setName(e.target.value)} autoFocus />
            {validationErrors.name && validationErrors.name[0] && (
              <FormErrorMessage>{validationErrors.name[0].message}</FormErrorMessage>
            )}
          </FormControl>
          <FormControl isRequired>
            <FormLabel>
              <CounterpartyHeading />
            </FormLabel>
            <CounterpartySelector value={counterparty} onChange={async e => setCounterparty(e)} />
          </FormControl>
          <FormControl isRequired>
            <FormLabel>Files</FormLabel>
            <MultiFileUpload Cls={FileUpload} value={fileUploads} onChange={setFileUploads} />
          </FormControl>

          {creating.lastError ? (
            <Alert status="error" mt="12">
              <AlertIcon boxSize="40px" />
              <Box>
                <AlertTitle fontSize="md">Error creating questionnaire</AlertTitle>
                <AlertDescription fontSize="md">{`${creating.lastError}`}</AlertDescription>
              </Box>
            </Alert>
          ) : null}
        </Stack>
        <Box pt={8}>
          <Button
            colorScheme="green"
            leftIcon={<Icon as={PlusIcon} />}
            type="submit"
            isLoading={creating.inProgress}
            isDisabled={name.length === 0 || !counterparty || isUploading || fileUploads.length === 0}
          >
            Import historical questionnaire
          </Button>
        </Box>
      </Box>
    </Page>
  );
};

export default NewHistoricalQuestionnaire;
