import {AccountFeature} from "../Types";
import {useQueryData} from "../state";

export type AccountFeatures = {
  [feature in AccountFeature]: true | undefined;
};

export function useAccountFeatures(): {enabledFeatures: AccountFeatures} {
  const whoami = useQueryData({queryKey: ["whoAmI"]});
  return {
    enabledFeatures: Object.fromEntries(
      whoami.account!.enabled_features.map(feature => [feature, true]),
    ) as AccountFeatures,
  };
}
