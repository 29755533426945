import {useCallback, useEffect, useRef, useState} from "react";
import {useEventCallback} from "usehooks-ts";

export abstract class AsyncOperation<TState> {
  #state: TState;
  #subscribers: (() => void)[];

  protected constructor(state: TState) {
    this.#state = state;
    this.#subscribers = [];
    setTimeout(async () => {
      try {
        await this.run();
      } catch (error) {
        this.state = this.errorState(error);
      }
    }, 0);
  }

  get state(): TState {
    return this.#state;
  }

  protected set state(state: TState) {
    this.#state = state;
    this.#notify();
  }

  #notify() {
    for (const subscriber of this.#subscribers) {
      setTimeout(subscriber, 0);
    }
  }

  subscribe(cb: () => void): () => void {
    this.#subscribers.push(cb);
    return () => {
      const idx = this.#subscribers.indexOf(cb);
      this.#subscribers.splice(idx, 1);
    };
  }

  protected abstract run(): Promise<void>;
  protected abstract errorState(error: unknown): TState;
}

export interface IAbortable {
  abort(reason?: any): void;
}

export abstract class AbortableAsyncOperation<TState> extends AsyncOperation<TState> implements IAbortable {
  #controller: AbortController;
  protected constructor(state: TState, controller?: AbortController) {
    super(state);
    this.#controller = controller ?? new AbortController();
  }
  protected get signal(): AbortSignal {
    return this.#controller.signal;
  }
  abort(reason?: any): void {
    this.#controller.abort(reason);
  }
}

export function useAsyncOperation<TState>(op: AsyncOperation<TState>): TState;
export function useAsyncOperation<TState>(op: AsyncOperation<TState> | null): TState | null;
export function useAsyncOperation<TState>(op: AsyncOperation<TState> | null): TState | null {
  const [state, setState] = useState(op?.state);
  useEffect(() => {
    setState(op?.state);
    return op?.subscribe(() => setState(op?.state));
  }, [op]);
  return state ?? null;
}

export function useAsyncOperations<TState>(ops: readonly AsyncOperation<TState>[]): TState[] {
  const [states, setStates] = useState(ops.map(op => op.state));
  useEffect(() => {
    setStates(ops.map(op => op.state));
    const update = () => {
      setStates(ops.map(op => op.state));
    };
    const unsubscribes = ops.map(op => op.subscribe(update));
    return () => {
      unsubscribes.forEach(unsubscribe => unsubscribe());
    };
  }, [ops]);
  return states;
}

function defaultListAbortable(state: any): IAbortable[] {
  return state ? (Array.isArray(state) ? state : [state]) : [];
}

// This monstrosity allows a component to "own" state which contains
// objects which should be explicitly aborted when they are no longer
// needed.

// Special-case for when the state itself is abortable
export function useAbortableState<T extends IAbortable | null | undefined>(
  initialState: T,
): [T, React.Dispatch<React.SetStateAction<T>>];

// Special-case for when the state is a list of abortables
export function useAbortableState<T extends IAbortable>(
  initialState: T[],
): [T[], React.Dispatch<React.SetStateAction<T[]>>];

// General case, requires list function
export function useAbortableState<T>(
  initialState: T,
  // Returns the a list of "abortable" objects in the provided state object
  listAbortables?: (state: T) => IAbortable[],
): [T, React.Dispatch<React.SetStateAction<T>>];

export function useAbortableState<T>(
  initialState: T,
  listAbortables: (state: T) => IAbortable[] = defaultListAbortable,
): [T, React.Dispatch<React.SetStateAction<T>>] {
  // Manage the state as normal
  const [state, setState] = useState(initialState);

  // Use a ref to keep track of the currently owned "abortable" objects
  // extracted from the state.
  const abortables = useRef(listAbortables(state));

  // Helper function to update the list, and "abort()" any objects that
  // were removed.
  const setAbortables = useCallback((newAbortables: IAbortable[]) => {
    for (const abortable of abortables.current) {
      if (newAbortables.indexOf(abortable) === -1) {
        abortable.abort();
      }
    }
    abortables.current = newAbortables;
  }, []);

  // `listAbortables` is intended to be a pure function, so we don't
  // want to include that in the dependencies.
  const cachedListAbortables = useEventCallback(listAbortables);

  // When the user calls `setState`, we also want to update the list
  // of abortables.
  const setStateWrapper = useCallback(
    (newState: React.SetStateAction<T>) => {
      if (newState instanceof Function) {
        // User passed a function to generate the new state based on the old
        setState(oldValue => {
          const newValue = newState(oldValue);
          setAbortables(cachedListAbortables(newValue));
          return newValue;
        });
      } else {
        // User passed a simple value
        setAbortables(cachedListAbortables(newState));
        setState(newState);
      }
    },
    [cachedListAbortables, setAbortables],
  );

  // When the component is unmounted, we also want to abort
  // everything owned by the component.
  useEffect(() => () => setAbortables([]), [setAbortables]);

  return [state, setStateWrapper];
}

// Special-case for when the state itself is abortable
export function useAbortableModalState<T extends IAbortable | null | undefined>(
  isOpen: boolean,
  initialState: T,
): [T, React.Dispatch<React.SetStateAction<T>>];

// Special-case for when the state is a list of abortables
export function useAbortableModalState<T extends IAbortable>(
  isOpen: boolean,
  initialState: T[],
): [T[], React.Dispatch<React.SetStateAction<T[]>>];

// General case, requires list function
export function useAbortableModalState<T>(
  isOpen: boolean,
  initialState: T,
  // Returns the a list of "abortable" objects in the provided state object
  listAbortables?: (state: T) => IAbortable[],
): [T, React.Dispatch<React.SetStateAction<T>>];

export function useAbortableModalState<T>(
  isOpen: boolean,
  initialState: T,
  listAbortables: (state: T) => IAbortable[] = defaultListAbortable,
): [T, React.Dispatch<React.SetStateAction<T>>] {
  const [wasOpen, setWasOpen] = useState(isOpen);
  const result = useAbortableState(initialState, listAbortables);
  if (isOpen != wasOpen) {
    setWasOpen(isOpen);
    result[1](initialState);
  }
  return result;
}
