import api from "../../../api";
import {DocumentId} from "../../../Types";
import {param, typedQuery} from "../../typing";

export default [
  typedQuery(["vendorToolkit", "document", param<DocumentId>("documentId")], async context => {
    return await api.vendorToolkit.documents.get(context.queryKey[2]);
  }),
  typedQuery(["vendorToolkit", "documentFacts", param<DocumentId>("documentId")], async context => {
    return await api.vendorToolkit.documents.getFacts(context.queryKey[2]);
  }),
  typedQuery(["vendorToolkit", "documents"], async () => {
    return await api.vendorToolkit.documents.list();
  }),
];
