import {useCallback} from "react";
import ResponseSelect from "../../../components/QuestionResponses/ResponseSelect.tsx";
import ResponseTextarea from "../../../components/QuestionResponses/ResponseTextarea.tsx";
import ResponseYesNo from "../../../components/QuestionResponses/ResponseYesNo.tsx";
import {getResponseLayer, useLayerType} from "../../../../../hooks/layerType.ts";
import {AccountFeature, Question, QuestionnaireId, QuestionStatus, ResponseOption} from "../../../../../Types.ts";
import AttachSupportingDocumentsButton from "../../components/AttachSupportingDocumentationButton.tsx";
import AttachSupportingDocumentsInline from "../../components/AttachSupportingDocumentationInline.tsx";
import CommentIndicator from "../../components/CommentIndicator.tsx";
import api from "../../../../../api/index.ts";
import {withSuspense} from "../../../../../state/withSuspense.tsx";
import {Stack} from "@chakra-ui/react";
import RequireAccountFeature from "../../../../../components/RequireAccountFeature.tsx";
import CoreResponseMinimal from "./components/CoreResponses/CoreResponseMinimal.tsx";

type Props = {
  questionnaireId: QuestionnaireId;
  question: Question;
};

const ResponseCell = withSuspense(({questionnaireId, question}: Props) => {
  const [layerType] = useLayerType();

  const {question_id, parts} = question;

  const {response_yes_no, response_text, response_select, status, activity_stream, linked_core_response} =
    getResponseLayer(question.response_layers, layerType);

  const saveResponseYesNo = useCallback(
    async (response: boolean | undefined) => {
      await api.vendorToolkit.questions.updateResponseYesNo(question_id, layerType, response);
    },
    [question_id, layerType],
  );

  const saveResponseSelect = useCallback(
    async (response: ResponseOption[] | undefined) => {
      await api.vendorToolkit.questions.updateResponseSelect(question_id, layerType, response);
    },
    [question_id, layerType],
  );

  const saveResponseText = useCallback(
    async (response: string) => {
      await api.vendorToolkit.questions.updateResponseText(question_id, layerType, response || null);
    },
    [question_id, layerType],
  );

  const responseDisabled = status === QuestionStatus.Automating || linked_core_response != null;

  return (
    <>
      <Stack spacing="4">
        {parts.yes_no.enabled.content && (
          <ResponseYesNo value={response_yes_no} onCommit={saveResponseYesNo} isDisabled={responseDisabled} />
        )}
        {parts.select.enabled.content && (
          <ResponseSelect
            value={response_select}
            config={parts.select.config}
            onCommit={saveResponseSelect}
            isDisabled={responseDisabled}
          />
        )}
        {parts.files.enabled.content && !parts.text.enabled.content && (
          <AttachSupportingDocumentsButton question={question} />
        )}
        {parts.text.enabled.content && (
          <ResponseTextarea
            value={response_text ?? ""}
            onCommit={saveResponseText}
            wordCountLimit={parts.text.config.word_count_limit}
            bottomAttachedComponent={
              parts.files.enabled.content && <AttachSupportingDocumentsInline question={question} />
            }
            isDisabled={responseDisabled}
            bottomLeftContent={
              <RequireAccountFeature feature={AccountFeature.CoreResponses}>
                {status !== QuestionStatus.Automating && (
                  <CoreResponseMinimal questionnaireId={questionnaireId} question={question} />
                )}
              </RequireAccountFeature>
            }
          />
        )}
        {activity_stream?.summary && <CommentIndicator activitySummary={activity_stream.summary} />}
      </Stack>
    </>
  );
});

export default ResponseCell;
