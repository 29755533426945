import {param, typedQuery} from "../../typing.ts";
import api from "../../../api";
import {CoreResponseId} from "../../../Types.ts";

export default [
  typedQuery(["vendorToolkit", "coreResponses"], async () => {
    return await api.vendorToolkit.coreResponses.list();
  }),
  typedQuery(["vendorToolkit", "coreResponse", param<CoreResponseId>("coreResponseId")], async context => {
    return await api.vendorToolkit.coreResponses.get(context.queryKey[2]);
  }),
];
