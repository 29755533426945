import {CertificationType} from "../../../../Types";

export function formatCertificationType(certificationType: CertificationType): string {
  switch (certificationType) {
    case CertificationType.Crest:
      return "CREST";
    case CertificationType.CsaStar:
      return "CSA STAR";
    case CertificationType.CyberEssentials:
      return "Cyber Essentials";
    case CertificationType.Iso27001:
      return "ISO 27001";
    case CertificationType.Iso27017:
      return "ISO 27017";
    case CertificationType.Iso27018:
      return "ISO 27018";
    case CertificationType.Iso27701:
      return "ISO 27701";
    case CertificationType.Soc2:
      return "SOC 2";
    case CertificationType.Soc3:
      return "SOC 3";
  }
}

export const CERTIFICATION_TYPE_OPTIONS = [
  ...Object.keys(CertificationType)
    .map(value => ({
      value,
      label: formatCertificationType(value as CertificationType),
    }))
    .sort((a, b) => a.label.localeCompare(b.label)),
  {value: "<null>", label: "Other"},
] as const satisfies {label: string; value: string}[];

export function fromCertificationTypeOptionValue(value: string): CertificationType | undefined {
  if (value === "<null>") {
    return undefined;
  }

  return value as CertificationType;
}

export function toCertificationTypeOptionValue(value: CertificationType | undefined): string {
  if (value === undefined) {
    return "<null>";
  }

  return value;
}
