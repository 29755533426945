import {
  InvalidateOptions,
  InvalidateQueryFilters,
  QueryClient,
  UseSuspenseQueryOptions,
  useSuspenseQueries,
  useSuspenseQuery,
} from "@tanstack/react-query";
import {buildRouterQuery} from "./router";
import queries from "./queries";
import {AnyQueryFilters, AnyQueryKey, AnyQueryKeyFilter, AnyQueryPageParam, AnyQueryReturnType} from "./typing";
import {RedirectError} from "../api/jsonApi";
import {HTTPError} from "../api";

type PlatformedQuery = (typeof queries)[number];
export type QueryKey = AnyQueryKey<PlatformedQuery>;
export type QueryPageParam<TQueryKey extends QueryKey> = AnyQueryPageParam<PlatformedQuery, TQueryKey>;
export type QueryReturnType<TQueryKey extends QueryKey> = AnyQueryReturnType<PlatformedQuery, TQueryKey>;
export type QueryKeyFilter = AnyQueryKeyFilter<QueryKey>;
export type QueryFilters = AnyQueryFilters<PlatformedQuery>;

const defaultQueryFn = buildRouterQuery(queries);

function shouldRetry(failureCount: number, error: any) {
  if (failureCount >= 3) {
    return false;
  } else if (error instanceof RedirectError) {
    return false;
  } else if (error instanceof HTTPError && error.response.status < 500) {
    return false;
  } else {
    return true;
  }
}

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      queryFn: defaultQueryFn,
      retry: shouldRetry,
    },
  },
});

export function useQueryData<TQueryKey extends QueryKey, TData = QueryReturnType<TQueryKey>>(
  options: Omit<UseSuspenseQueryOptions<QueryReturnType<TQueryKey>, unknown, TData, TQueryKey>, "initialData"> & {
    initialData?: () => undefined;
  },
) {
  return useSuspenseQuery({...options}).data as TData;
}

type QueryDataOptions<TQueryKey extends QueryKey = QueryKey, TData = QueryReturnType<TQueryKey>> = Omit<
  UseSuspenseQueryOptions<QueryReturnType<TQueryKey>, unknown, TData, TQueryKey>,
  "initialData"
> & {
  initialData?: () => undefined;
  queryKey: TQueryKey;
};

export function useQueriesData<const T extends readonly QueryDataOptions[]>({
  queries,
}: {
  queries: T;
}): {[K in keyof T]: QueryReturnType<T[K]["queryKey"]>} {
  return useSuspenseQueries({
    queries: queries.map(options => ({...options})) as unknown[],
  }).map(item => item.data) as any;
}

// Marks active queries matching the filter to be re-fetched, but leaves the existing data in the cache until
// the re-fetch has completed.
export async function invalidateQueries(filters?: QueryFilters[], options?: InvalidateOptions) {
  await Promise.all(
    (filters ?? [undefined]).map(filter => queryClient.invalidateQueries(filter as InvalidateQueryFilters, options)),
  );
}

// Resets all the queries matching the filter to their initial state, clearing matching cache keys.
export async function resetQueries(filters?: QueryFilters[], options?: InvalidateOptions) {
  await Promise.all(
    (filters ?? [undefined]).map(filter => queryClient.resetQueries(filter as InvalidateQueryFilters, options)),
  );
}

// Directly store data in the cache under the given key.
export async function setQueryData<const TQueryKey extends QueryKey>(
  queryKey: TQueryKey,
  updater:
    | QueryReturnType<TQueryKey>
    | undefined
    | ((oldData: QueryReturnType<TQueryKey> | undefined) => QueryReturnType<TQueryKey> | undefined),
) {
  queryClient.setQueryData<QueryReturnType<TQueryKey>>(queryKey, updater);
}
