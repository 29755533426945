import {
  CreateEsignatureProvider,
  EsignatureProvider,
  EsignatureProviderConnector,
  EsignatureProviderConnectorType,
  EsignatureProviderId,
  EsignatureProviderMin,
  UpdateEsignatureProvider,
} from "../../Types.ts";
import jsonApi from "../jsonApi.ts";
import {invalidateQueries} from "../../state/index.ts";

export async function listConnectors(): Promise<EsignatureProviderConnector[]> {
  return await jsonApi.get<EsignatureProviderConnector[]>("/vendor_toolkit/esignature_provider_connectors");
}

export async function getConnector(
  connectorType: EsignatureProviderConnectorType,
): Promise<EsignatureProviderConnector> {
  return await jsonApi.get<EsignatureProviderConnector>(
    `/vendor_toolkit/esignature_provider_connectors/${connectorType}`,
  );
}

export async function create(payload: CreateEsignatureProvider): Promise<EsignatureProvider> {
  const res = await jsonApi.post<EsignatureProvider>("/vendor_toolkit/esignature_providers", payload);
  await invalidateQueries([{queryKey: ["vendorToolkit", "esignatureProviders"]}]);
  return res;
}

export async function update(providerId: EsignatureProviderId, payload: UpdateEsignatureProvider) {
  const res = await jsonApi.post<"ok">(`/vendor_toolkit/esignature_providers/${providerId}`, payload);
  await invalidateQueries([
    {queryKey: ["vendorToolkit", "esignatureProviders"]},
    {queryKey: ["vendorToolkit", "esignatureProvider", providerId]},
  ]);
  return res;
}

export async function delete_(providerId: EsignatureProviderId) {
  const res = await jsonApi.delete_<"ok">(`/vendor_toolkit/esignature_providers/${providerId}`);
  await invalidateQueries([
    {queryKey: ["vendorToolkit", "esignatureProviders"]},
    {queryKey: ["vendorToolkit", "esignatureProvider", providerId]},
  ]);
  return res;
}

export async function updateName(providerId: EsignatureProviderId, name: string) {
  const res = await jsonApi.post<"ok">(`/vendor_toolkit/esignature_providers/${providerId}/name`, name);
  await invalidateQueries([
    {queryKey: ["vendorToolkit", "esignatureProviders"]},
    {queryKey: ["vendorToolkit", "esignatureProvider", providerId]},
  ]);
  return res;
}

export async function list(): Promise<EsignatureProviderMin[]> {
  return await jsonApi.get<EsignatureProviderMin[]>("/vendor_toolkit/esignature_providers");
}

export async function get(providerId: EsignatureProviderId): Promise<EsignatureProvider> {
  return await jsonApi.get<EsignatureProvider>(`/vendor_toolkit/esignature_providers/${providerId}`);
}

export async function sync(providerId: EsignatureProviderId) {
  const res = await jsonApi.post<"ok">(`/vendor_toolkit/esignature_providers/${providerId}/sync`);
  await invalidateQueries([
    {queryKey: ["vendorToolkit", "esignatureProvider", providerId]},
    {queryKey: ["vendorToolkit", "esignatureProviders"]},
  ]);
  return res;
}
