import {useModalState} from "../../../../../hooks/modalState.ts";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  Textarea,
  useDisclosure,
} from "@chakra-ui/react";
import {QuestionnaireId, RegisteredUser} from "../../../../../Types.ts";
import api from "../../../../../api/index.ts";
import {useModalValidationState, useValidatedPromiseState} from "../../../../../hooks/validationState.ts";
import {ImportConfirmationModal} from "./ImportConfirmationModal.tsx";
import {AnsweringMode} from "./components/AnsweringMode/index.ts";
import SelectAnsweringMode from "./components/AnsweringMode/Select.tsx";

const ImportPortalModal = ({
  isOpen,
  onClose,
  user,
  questionnaireId,
}: {
  isOpen: boolean;
  onClose: () => void;
  user: RegisteredUser;
  questionnaireId: QuestionnaireId;
}) => {
  const {isOpen: isConfirmationOpen, onClose: closeConfirmation, onOpen: openConfirmation} = useDisclosure();
  const [url, setUrl] = useModalState(isOpen, "");
  const [username, setUsername] = useModalState(isOpen, "");
  const [password, setPassword] = useModalState(isOpen, "");
  const [notes, setNotes] = useModalState(isOpen, "");
  const [validationErrors, setValidationErrors] = useModalValidationState(isOpen, {url});
  const [answeringMode, setAnsweringMode] = useModalState(isOpen, AnsweringMode.Considered);

  const [sending, send] = useValidatedPromiseState(
    async () => {
      const subject = "Import questionnaire request";
      const body = `${user.name} requested to import a questionnaire from a portal.\n\nURL: ${url}\nLogin: ${username}\n${password}\nNotes: ${notes}\n\nReference: ${questionnaireId}\n\nAnswering mode: ${answeringMode}`;

      await api.supportEmails.send({
        action: "import",
        subject,
        body,
        attachments: [],
        cc_user: false,
        express: false,
      });
      await api.vendorToolkit.questionnaires.createOriginalSource(questionnaireId, url);
      openConfirmation();
      onClose();
    },
    [user.name, url, username, password, notes, questionnaireId, answeringMode, openConfirmation, onClose],
    setValidationErrors,
  );

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent as="form" onSubmit={send}>
          <ModalHeader>Upload from Portal</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing={4}>
              <Text fontSize="md" fontStyle="italic">
                Enter the URL to the portal for the questionnaire you want to import, along with the credentials
                required to access it.
              </Text>
              <FormControl isRequired isInvalid={validationErrors.url !== undefined}>
                <FormLabel>Portal URL</FormLabel>
                <Input type="url" onChange={e => setUrl(e.target.value)} />
                {validationErrors.url && validationErrors.url[0] && (
                  <FormErrorMessage>{validationErrors.url[0].message}</FormErrorMessage>
                )}
              </FormControl>
              <FormControl>
                <FormLabel>Login username or email</FormLabel>
                <Input type="text" onChange={e => setUsername(e.target.value)} />
              </FormControl>
              <FormControl>
                <FormLabel>Password</FormLabel>
                <Input type="password" onChange={e => setPassword(e.target.value)} />
              </FormControl>
              <FormControl>
                <FormLabel mb={1}>Notes</FormLabel>
                <Text mb={1} fontSize="sm" fontStyle="italic">
                  Provide any additional information required to access the portal here.
                </Text>
                <Textarea onChange={e => setNotes(e.target.value)} />
              </FormControl>
              <FormControl>
                <SelectAnsweringMode value={answeringMode} onChange={setAnsweringMode} />
              </FormControl>
              {sending.lastError ? (
                <Alert status="error" mt="12">
                  <AlertIcon boxSize="40px" />
                  <Box>
                    <AlertTitle fontSize="md">Error sending upload request</AlertTitle>
                    <AlertDescription fontSize="md">{`${sending.lastError}`}</AlertDescription>
                  </Box>
                </Alert>
              ) : null}
            </Stack>
          </ModalBody>
          <ModalFooter>
            <HStack spacing={3}>
              <Button variant="outline" onClick={onClose}>
                Cancel
              </Button>
              <Button
                colorScheme="blue"
                type="submit"
                isLoading={sending.inProgress}
                isDisabled={sending.inProgress || validationErrors.url !== undefined}
              >
                Submit
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <ImportConfirmationModal isOpen={isConfirmationOpen} onClose={closeConfirmation} answeringMode={answeringMode} />
    </>
  );
};

export default ImportPortalModal;
