import {Box} from "@chakra-ui/react";
import {ExternalAuthorizationProvider} from "../../../../../../Types";
import {useCallback, useState} from "react";
import {DocumentSourceProps} from "./AnyDocumentSource";
import AgreeToTerms from "../components/AgreeToTerms";
import {GenericOauthOptions, useGenericOauth} from "../../../../../../hooks/genericOauth";
import {getPublicCredentials} from "../../../../../../utils/environment";
import {SelectedFolder} from "../components/SelectedFolder";
import {useQueryData} from "../../../../../../state";

const NOTION_OAUTH_OPTIONS: GenericOauthOptions = {
  authUrl: "https://api.notion.com/v1/oauth/authorize",
  clientId: getPublicCredentials().notion_client_id,
  provider: ExternalAuthorizationProvider.Notion,
  purpose: "Sync documents from Notion",
  scope: "",
  extra: {},
};

const NotionDocumentSource = ({payload, setPayload}: DocumentSourceProps) => {
  const whoami = useQueryData({queryKey: ["whoAmI"]});
  const [agreedToTerms, setAgreedToTerms] = useState(!!payload);

  const [requestingAuth, requestAuth] = useGenericOauth();

  const pickNotionWorkspace = useCallback(async () => {
    const auth = await requestAuth(NOTION_OAUTH_OPTIONS);
    if (!auth.ok) {
      return;
    }
    setPayload({
      config: {type: "Notion", content: {default_owner_id: whoami.user_owner!.owner_id}!},
      external_authorization_id: auth.result.external_authorization_id,
    });
  }, [requestAuth, setPayload, whoami.user_owner]);

  const config = payload?.config;
  if (config && config.type !== "Notion") {
    return null;
  }

  return (
    <Box w="full">
      <AgreeToTerms agreedToTerms={agreedToTerms} setAgreedToTerms={setAgreedToTerms} isDisabled={!!config} />
      <SelectedFolder
        folder={config ? {name: "Selected Notion pages"} : null}
        placeholder="No workspace configured"
        onClick={pickNotionWorkspace}
        colorScheme="blue"
        isDisabled={!agreedToTerms}
        isLoading={requestingAuth.inProgress}
      >
        Configure Notion workspace...
      </SelectedFolder>
    </Box>
  );
};

export default NotionDocumentSource;
