import {NavLink, useOutlet} from "react-router-dom";
import {withSuspense} from "../../../../state/withSuspense.tsx";
import {useQueryData} from "../../../../state/index.ts";
import {Box, Stack, Heading, Flex, GridItem, LinkBox, Highlight} from "@chakra-ui/react";
import Page from "../../../../components/Page.tsx";
import SidePane from "../../../../components/SidePane.tsx";
import SearchableList from "../../../../components/VerticalNavList.tsx";
import {TrustCenterContentMin} from "../../../../Types.ts";
import NewContent from "./NewContent.tsx";

const Empty = () => (
  <GridItem colSpan={8} p="6">
    <Heading size="md">No content selected</Heading>
  </GridItem>
);

const ListItem = ({item, highlight}: {item: TrustCenterContentMin; highlight: string[]}) => (
  <LinkBox
    as={NavLink}
    to={item.content_id}
    display="flex"
    alignItems="center"
    _hover={{bg: "gray.50"}}
    px="6"
    py="5"
    _activeLink={{bg: "gray.50", color: "blue.500"}}
  >
    <Box flex="1">
      <Heading size="sm" noOfLines={1}>
        <Highlight query={highlight} styles={{bg: "yellow.100"}}>
          {item.title}
        </Highlight>
      </Heading>
    </Box>
  </LinkBox>
);

const ContentsPage = withSuspense(() => {
  const outlet = useOutlet() || <Empty />;
  const items = useQueryData({queryKey: ["vendorToolkit", "trustCenterContents"]});
  return (
    <Page title="Contents">
      <Flex direction="row" alignItems="stretch">
        <SidePane>
          <SearchableList
            title="Contents"
            items={items}
            ItemComp={ListItem}
            getName={d => d.title}
            getKey={d => d.content_id}
            actionButtons={<NewContent />}
          />
        </SidePane>
        <Stack flex="1">{outlet}</Stack>
      </Flex>
    </Page>
  );
});

export default ContentsPage;
