import {MultiSelectTheme} from "chakra-multiselect";

const baseTheme = {
  config: {
    initialColorMode: "light",
    useSystemColorMode: false,
  },
  fontSizes: {
    xs: "0.6rem",
    sm: "0.75rem",
    md: "0.875rem",
    lg: "1rem",
    xl: "1.125rem",
    "2xl": "1.25rem",
    "3xl": "1.5rem",
    "4xl": "1.875rem",
    "5xl": "2.25rem",
    "6xl": "3rem",
    "7xl": "3.75rem",
    "8xl": "4.5rem",
    "9xl": "6rem",
  },
  fonts: {
    heading: `'Inter var', sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
    body: `'Inter var', sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
  },
  zIndices: {
    hide: -1,
    auto: "auto",
    base: 0,
    docked: 10,
    // Default theme puts "dropdown" too low
    // Presumably Chakra copied this from Bootstrap, which made the same mistake...
    // https://github.com/twbs/bootstrap/issues/31747
    sticky: 1000,
    banner: 1100,
    dropdown: 1200,
    overlay: 1300,
    modal: 1400,
    popover: 1500,
    skipLink: 1600,
    toast: 1700,
    tooltip: 1800,
  },
  components: {
    MultiSelect: MultiSelectTheme,
    Tag: {
      baseStyle: {
        label: {
          lineHeight: 2,
        },
      },
    },
    Menu: {
      baseStyle: {
        list: {
          zIndex: "dropdown",
        },
      },
    },
  },
};

export default baseTheme;
