import {BoxProps, Flex, Text, TextProps} from "@chakra-ui/react";
import * as datefns from "date-fns";
import {ApiDateTime} from "../Types";
import {parseISO} from "../utils/date";
import {DateUI} from "./Date.tsx";

export const TimeRemaining = ({dueDate, ...props}: {dueDate: Date} & TextProps) => {
  dueDate = datefns.add(dueDate, {days: 0});
  let days = datefns.differenceInCalendarDays(Date.now(), dueDate);
  const overdue = days > 0;
  days = Math.abs(days);

  const color = overdue ? "red" : days === 0 ? "orange" : days <= 7 ? "yellow" : "gray";
  const s = days > 1 ? "s" : "";

  return (
    <Text as="span" fontSize="sm" color={`${color}.500`} whiteSpace="nowrap" {...props}>
      {days === 0 ? "Due today" : overdue ? `${days} day${s} overdue` : `${days} day${s} until due`}
    </Text>
  );
};

const DueDate = ({
  dueDate,
  complete,
  showTextPrefix,
  onDateChange,
  ...props
}: {
  dueDate?: ApiDateTime;
  complete: boolean;
  showTextPrefix?: boolean;
  onDateChange?: (date: ApiDateTime) => Promise<void>;
} & BoxProps) => {
  const date = parseISO(dueDate);
  return (
    <Flex columnGap={2} flexWrap="wrap" align="center" {...props}>
      <DateUI
        date={dueDate}
        onDateChange={onDateChange}
        textPrefix={showTextPrefix ? "Due:" : undefined}
        missingDateText="No due date"
      />
      {dueDate && !complete && <TimeRemaining dueDate={date!} />}
    </Flex>
  );
};

export default DueDate;
