import {withSuspense} from "../../../../state/withSuspense.tsx";
import {ActionBar} from "../../../../components/ActionBar.tsx";
import {Box, Button, ButtonGroup, Flex, Icon, Img, Table, Tbody, Td, Th, Thead, Tr} from "@chakra-ui/react";
import {useQueriesData} from "../../../../state/index.ts";
import {NewNotificationChannelButton} from "./NewNotificationChannel.tsx";
import {NotificationChannelMin} from "../../../../Types.ts";
import groupBy from "../../../../utils/groupBy.tsx";
import {AdjustmentsHorizontalIcon, UserIcon} from "@heroicons/react/20/solid";
import {Link as RouterLink} from "react-router-dom";

const NotificationChannelCard = ({
  channel: {notification_channel_id, name, connector},
}: {
  channel: NotificationChannelMin;
}) => {
  return (
    <Tr>
      <Td w="20%" h="20">
        <Flex align="center" fontSize="md">
          <Img src={connector.logo_url} h={4} aspectRatio={1} mr={2} />
          {connector.name}
        </Flex>
      </Td>
      <Td w="20%" fontSize="md" fontWeight="500" color="gray.700">
        {name}
      </Td>
      <Td>
        <ButtonGroup isAttached variant="outline" shadow="sm">
          <Button
            as={RouterLink}
            leftIcon={<Icon as={AdjustmentsHorizontalIcon} />}
            to={`/vendor-toolkit/configuration/integrations/notification-channels/configure/${notification_channel_id}`}
          >
            Configure workspace
          </Button>
          <Button as={RouterLink} leftIcon={<Icon as={UserIcon} />} color="green" to="/user-settings/notifications">
            Set up personal notifications
          </Button>
        </ButtonGroup>
      </Td>
    </Tr>
  );
};

const NotificationChannelsList = ({channels}: {channels: NotificationChannelMin[]}) => {
  return (
    <Table>
      <Thead>
        <Tr>
          <Th>Channel</Th>
          <Th>Name</Th>
          <Th>Actions</Th>
        </Tr>
      </Thead>
      {groupBy(channels, channel => channel.connector.connector_type).map(g => (
        <Tbody key={g.key}>
          {g.items.map(channel => (
            <NotificationChannelCard channel={channel} key={channel.notification_channel_id} />
          ))}
        </Tbody>
      ))}
    </Table>
  );
};

const NotificationChannelsPage = withSuspense(() => {
  const [connectors, channels] = useQueriesData({
    queries: [{queryKey: ["notificationChannelConnectors"]}, {queryKey: ["notificationChannels"]}],
  });
  return (
    <>
      <ActionBar>
        <NewNotificationChannelButton connectors={connectors} />
      </ActionBar>
      <Box h="full">
        <NotificationChannelsList channels={channels} />
      </Box>
    </>
  );
});

export default NotificationChannelsPage;
