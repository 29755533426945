import {
  AccountId,
  AssetId,
  ContentId,
  DocumentAccessRequest,
  DocumentExternal,
  DocumentId,
  GetGraphQuery,
  GraphProperty,
  SubGraph,
  SubscribeRequest,
  TrustCenterConfigMin,
  TrustCenterContent,
  TrustCenterFaq,
} from "../../Types";
import jsonApi, {API_BASE} from "./jsonApi";

export async function listDocuments(accountId: AccountId) {
  return await jsonApi.get<DocumentExternal[]>(`/trust_centers/${accountId}/documents`);
}

export const downloadDocument = async (accountId: AccountId, documentId: DocumentId) => {
  const a = document.createElement("a");
  a.href = `${API_BASE}/trust_centers/${accountId}/documents/${documentId}/download`;
  a.click();
};

export async function requestDocumentAccess(
  accountId: AccountId,
  documentId: DocumentId,
  documentRequest: DocumentAccessRequest,
) {
  return await jsonApi.post<"ok">(
    `/trust_centers/${accountId}/documents/${documentId}/request_access`,
    documentRequest,
  );
}

export const getAssetUrl = (accountId: AccountId, assetId: AssetId) => {
  return `${API_BASE}/trust_centers/${accountId}/assets/${assetId}/download`;
};

export const getContent = async (accountId: AccountId, contentId: ContentId) => {
  return await jsonApi.get<TrustCenterContent>(`/trust_centers/${accountId}/contents/${contentId}`);
};

export const getConfig = async (accountId: AccountId) => {
  return await jsonApi.get<TrustCenterConfigMin>(`/trust_centers/${accountId}/config`);
};

export const getFaq = async (accountId: AccountId) => {
  return await jsonApi.get<TrustCenterFaq>(`/trust_centers/${accountId}/faq`);
};

export const getAiFaq = async (accountId: AccountId) => {
  return await jsonApi.get<TrustCenterFaq>(`/trust_centers/${accountId}/ai_faq`);
};

export const getGraph = async (accountId: AccountId, query: GetGraphQuery) => {
  return await jsonApi.get<SubGraph>(`/trust_centers/${accountId}/graph?${new URLSearchParams(query as any)}`);
};

export const getSchema = async (accountId: AccountId) => {
  return await jsonApi.get<{[k: string]: GraphProperty}>(`/trust_centers/${accountId}/schema`);
};

export async function subscribe(subscribeRequest: SubscribeRequest) {
  const res = await jsonApi.post<"ok">(`/trust_centers/subscribe`, subscribeRequest);
  return res;
}
