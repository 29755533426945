import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Icon,
  Input,
  Stack,
  Text,
} from "@chakra-ui/react";
import Page from "../../../../components/Page.tsx";
import {
  AccountFeature,
  ApiDateTime,
  Counterparty,
  OwnerT,
  OwnerType,
  QuestionnaireId,
  ScopeMatrix,
} from "../../../../Types.ts";
import {useState} from "react";
import Owner from "../../../../components/Owner.tsx";
import DueDate from "../../../../components/DueDate.tsx";
import {PlusIcon} from "@heroicons/react/20/solid";
import api from "../../../../api/index.ts";
import {CounterpartyHeading, CounterpartySelector} from "../../components/Counterparty.tsx";
import {router} from "../../../../router/index.tsx";
import {useValidatedPromiseState, useValidationState} from "../../../../hooks/validationState.ts";
import {useQueryData} from "../../../../state/index.ts";
import RequireAccountFeature from "../../../../components/RequireAccountFeature.tsx";
import ScopeMatrixEditor from "../../components/ScopeMatrixEditor.tsx";
import {TooltipWithIcon} from "../../../../components/TooltipWithIcon.tsx";
import {Internal} from "../../../../components/InternalMode.tsx";

export const NewQuestionnaireInner = ({redirect}: {redirect: (questionnaireId: QuestionnaireId) => void}) => {
  const whoami = useQueryData({queryKey: ["whoAmI"]});
  const [name, setName] = useState("");
  const ownerInitial = useQueryData({
    queryKey: ["resolvedOwner", {type: OwnerType.User, content: whoami.user.user_id}],
  });
  const [owner, setOwner] = useState<OwnerT | undefined>(ownerInitial);
  const [dueDate, setDueData] = useState<ApiDateTime | undefined>(undefined);
  const [counterparty, setCounterparty] = useState<Counterparty | undefined>(undefined);
  const [scopeMatrix, setScopeMatrix] = useState<ScopeMatrix>({rows: [{scope_ids: []}]});
  const [isInternal, setIsInternal] = useState(false);
  const [validationErrors, setValidationErrors] = useValidationState({name});

  const [creating, create] = useValidatedPromiseState(
    async () => {
      const {questionnaire_id} = await api.vendorToolkit.questionnaires.create({
        name,
        counterparty_id: counterparty!.counterparty_id,
        owner_id: owner?.owner_id,
        due_date: dueDate,
        sections: [],
        scope_matrix: scopeMatrix,
        is_internal: isInternal,
      });
      redirect(questionnaire_id);
    },
    [name, owner, scopeMatrix, dueDate, counterparty, isInternal, redirect],
    setValidationErrors,
  );

  return (
    <Box as="form" onSubmit={create} p={8}>
      <Stack maxW="96" spacing={8}>
        <FormControl isInvalid={validationErrors.name !== undefined}>
          <FormLabel>Questionnaire name</FormLabel>
          <Input value={name} onChange={e => setName(e.target.value)} autoFocus />
          {validationErrors.name && validationErrors.name[0] && (
            <FormErrorMessage>{validationErrors.name[0].message}</FormErrorMessage>
          )}
        </FormControl>
        <FormControl>
          <FormLabel>
            <CounterpartyHeading />
          </FormLabel>
          <CounterpartySelector value={counterparty} onChange={async e => setCounterparty(e)} />
        </FormControl>
        <RequireAccountFeature feature={AccountFeature.Scopes}>
          <FormControl>
            <FormLabel>
              <TooltipWithIcon
                placement="top"
                label="Include facts from these scopes in addition to global facts when answering this questionnaire."
                fontSize="md"
              >
                <Text as="span">Scopes</Text>
              </TooltipWithIcon>
            </FormLabel>
            <ScopeMatrixEditor value={scopeMatrix} onChange={async newValue => setScopeMatrix(newValue)} />
          </FormControl>
        </RequireAccountFeature>
        <FormControl>
          <FormLabel>Owner</FormLabel>
          <Owner owner={owner} onReassign={async user => setOwner(user)} />
        </FormControl>
        <FormControl>
          <FormLabel>Due date</FormLabel>
          <DueDate dueDate={dueDate} complete={false} onDateChange={async dueDate => setDueData(dueDate)} />
        </FormControl>
        <Internal>
          <FormControl>
            <FormLabel>Internal only</FormLabel>
            <Checkbox isChecked={isInternal} onChange={e => setIsInternal(e.target.checked)}>
              Only show this questionnaire internally
            </Checkbox>
          </FormControl>
        </Internal>
        {creating.lastError ? (
          <Alert status="error" mt="12">
            <AlertIcon boxSize="40px" />
            <Box>
              <AlertTitle fontSize="md">Error creating questionnaire</AlertTitle>
              <AlertDescription fontSize="md">{`${creating.lastError}`}</AlertDescription>
            </Box>
          </Alert>
        ) : null}
      </Stack>
      <Box pt={8}>
        <Button
          colorScheme="green"
          leftIcon={<Icon as={PlusIcon} />}
          type="submit"
          isLoading={creating.inProgress}
          isDisabled={name.length === 0 || !counterparty}
        >
          Create questionnaire
        </Button>
      </Box>
    </Box>
  );
};

const NewQuestionnaire = () => {
  const redirect = (questionnaireId: QuestionnaireId) =>
    router!.navigate(`/vendor-toolkit/questionnaires/${questionnaireId}`);
  return (
    <Page title="New questionnaire">
      <Heading size="md" p="8">
        Create questionnaire
      </Heading>
      <NewQuestionnaireInner redirect={redirect} />
    </Page>
  );
};

export default NewQuestionnaire;
