import {Text, TextProps} from "@chakra-ui/react";

const Only = (props: TextProps) => (
  <Text
    className="only"
    visibility="hidden"
    fontSize="xs"
    fontWeight="600"
    mr="4"
    color="blue.500"
    borderRadius="sm"
    bg="blue.50"
    p="1"
    textTransform="uppercase"
    _hover={{bg: "blue.100"}}
    {...props}
  >
    Only
  </Text>
);

export default Only;
