// eslint-disable-next-line no-restricted-imports
import {MenuList, MenuListProps, Portal} from "@chakra-ui/react";

export default function PortalMenuList(props: Omit<MenuListProps, "zIndex">) {
  return (
    <Portal>
      <MenuList {...props} />
    </Portal>
  );
}
