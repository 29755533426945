import {BoxProps, HStack, Icon, Input, InputGroup, InputLeftElement, InputProps, StackProps} from "@chakra-ui/react";
import {MagnifyingGlassIcon} from "@heroicons/react/20/solid";
import {STICKY_ACTION_HEIGHT, STICKY_ACTION_OFFSET} from "../utils/sticky";
import {memo} from "react";

const ActionBarLeft = (props: BoxProps) => <HStack flex="1" display="flex" spacing="4" {...props} />;

const ActionBarRight = (props: StackProps) => <HStack display="flex" spacing="4" justify="flex-end" {...props} />;

export const ActionBarSearch = (props: InputProps) => (
  <InputGroup flex="1" maxW="500">
    <InputLeftElement pointerEvents="none">
      <Icon as={MagnifyingGlassIcon} color="gray.500" />
    </InputLeftElement>
    <Input placeholder="Search..." bg="white" {...props} />
  </InputGroup>
);

export const ActionBar = memo(
  ({actionButtons, children, ...props}: {actionButtons?: React.ReactElement} & StackProps) => (
    <HStack
      data-testid="action-bar"
      position="sticky"
      top={`${STICKY_ACTION_OFFSET}px`}
      // Must specify exact height because browsers will *#$! with 1px borders and make them fractional
      // on HiDPI displays, which will mess with the automatically determined height, which will in turn
      // add a sub-pixel gap between this bar and any sticky elements below.
      height={`${STICKY_ACTION_HEIGHT}px`}
      zIndex="sticky"
      display="flex"
      justifyContent="space-between"
      spacing="32"
      background="gray.50"
      borderBottom="1px solid"
      borderColor="gray.100"
      px="6"
      py="4"
      {...props}
    >
      <ActionBarLeft>{children}</ActionBarLeft>
      {actionButtons && <ActionBarRight>{actionButtons}</ActionBarRight>}
    </HStack>
  ),
);
