import {withSuspense} from "../../../state/withSuspense.tsx";
import {useQueryData} from "../../../state/index.ts";
import {CreateEditTeamModal} from "./CreateEditTeamModal.tsx";
import {ActionBar} from "../../../components/ActionBar.tsx";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionItemProps,
  AccordionPanel,
  Box,
  Button,
  ButtonGroup,
  Flex,
  Heading,
  HStack,
  Icon,
  IconButton,
  Stack,
  StackDivider,
  Tag,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import {Team} from "../../../Types.ts";
import groupBy from "../../../utils/groupBy.tsx";
import {usePromiseState} from "../../../hooks/promiseState.ts";
import api from "../../../api/index.ts";
import {DeleteModalIconButton} from "../../../components/DeleteModalButton.tsx";
import {ArrowPathIcon, PencilIcon, PlusIcon} from "@heroicons/react/20/solid";
import {AddMemberModal} from "./AddMemberModal.tsx";
import MembersList from "./MembersList.tsx";
import TeamAvatar from "../../../components/TeamAvatar.tsx";

export const EditTeamButton = ({team}: {team: Team}) => {
  const {isOpen, onOpen, onClose} = useDisclosure();

  return (
    <>
      <Tooltip label="Edit team">
        <IconButton
          flex="0 0 auto"
          icon={<Icon as={PencilIcon} />}
          onClick={onOpen}
          aria-label="edit-team"
          bgColor={"white"}
          variant={"outline"}
          color="gray.500"
        />
      </Tooltip>
      <CreateEditTeamModal type={"Edit"} isOpen={isOpen} onClose={onClose} team={team} />
    </>
  );
};

export const DeleteTeamButton = ({team}: {team: Team}) => {
  const {isOpen, onOpen, onClose} = useDisclosure();
  const [deletingTeam, deleteTeam] = usePromiseState(async () => {
    await api.teams.delete_(team.team_id);
    onClose();
  }, [onClose, team.team_id]);

  return (
    <DeleteModalIconButton
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      delete_={deleteTeam}
      deleting={deletingTeam.inProgress}
      modalHeader="Delete team"
      modalBody={`Are you sure you want to delete ${team.name}?`}
      tooltip={"Delete team"}
    />
  );
};

export const RestoreTeamButton = ({team}: {team: Team}) => {
  const [disablingTeam, disableTeam] = usePromiseState(async () => {
    await api.teams.restore(team.team_id);
  }, [team.team_id]);

  return (
    <Tooltip label={"Restore team"}>
      <IconButton
        flex="0 0 auto"
        icon={<Icon as={ArrowPathIcon} />}
        onClick={disableTeam}
        aria-label="restore-team"
        isDisabled={disablingTeam.inProgress}
        isLoading={disablingTeam.inProgress}
        bgColor={"white"}
        variant={"outline"}
        color="gray.500"
      />
    </Tooltip>
  );
};

const TeamRow = ({
  team,
  actionButtons,
  activeMember,
  ...props
}: {team: Team; actionButtons?: React.ReactElement; activeMember?: boolean} & AccordionItemProps) => {
  return (
    <AccordionItem {...props}>
      <HStack
        color="gray.700"
        _hover={{color: "black"}}
        transitionProperty="color"
        transitionDuration="200ms"
        gap={6}
        py="2"
        px="6"
      >
        <AccordionButton flex="1" fontSize="md" _hover={{}} display="flex">
          <Flex flex={1} align="center" gap={6}>
            <AccordionIcon mt="1px" />
            <TeamAvatar isMember={activeMember} />
            <HStack gap={4}>
              <Heading size="sm" noOfLines={1}>
                {team.name}
              </Heading>
              <Tag fontWeight="semibold">{team.member_count}</Tag>
              {activeMember && <Tag colorScheme="green">Member</Tag>}
            </HStack>
          </Flex>
          <Box flex={2} color="gray.600" whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
            {team.description}
          </Box>
        </AccordionButton>
        {actionButtons}
      </HStack>
      <AccordionPanel bg="gray.50" p={0} borderTop="1px solid" borderTopColor="gray.200">
        <MembersList team={team} />
      </AccordionPanel>
    </AccordionItem>
  );
};

const TeamsList = ({teams, userTeamIds}: {teams: Team[]; userTeamIds: string[]}) => {
  const groupedTeams = groupBy(teams, t => t.name.toUpperCase()[0]).sort((a, b) => a.key.localeCompare(b.key));

  return (
    <Box as="nav" minH="0" flex="1">
      {groupedTeams.map(group => (
        <Box key={group.key} position="relative">
          <Heading
            size="sm"
            borderTop="1px solid"
            borderBottom="1px solid"
            borderColor="gray.200"
            px="6"
            py="1"
            color="gray.500"
            bg="gray.50"
          >
            {group.key}
          </Heading>
          <Stack divider={<StackDivider borderColor="gray.200" />}>
            <Accordion allowMultiple>
              {group.items.map(team => (
                <TeamRow
                  key={team.team_id}
                  team={team}
                  activeMember={userTeamIds.includes(team.team_id)}
                  actionButtons={
                    <ButtonGroup isAttached>
                      <AddMemberModal team={team} />
                      <EditTeamButton team={team} />
                      <DeleteTeamButton team={team} />
                    </ButtonGroup>
                  }
                />
              ))}
            </Accordion>
          </Stack>
        </Box>
      ))}
    </Box>
  );
};

const DeletedTeamsList = ({teams, userTeamIds}: {teams: Team[]; userTeamIds: string[]}) => {
  const groupedTeams = groupBy(teams, t => t.name.toUpperCase()[0]).sort((a, b) => a.key.localeCompare(b.key));

  if (groupedTeams.length === 0) {
    return null;
  }

  return (
    <Box as="nav" minH="0" flex="1">
      <Heading fontSize={"medium"} padding={6} borderY="1px solid" borderColor="gray.200" backgroundColor={"gray.50"}>
        Deleted teams
      </Heading>
      {groupedTeams.map(group => (
        <Box key={group.key} position="relative">
          <Stack divider={<StackDivider borderColor="gray.200" />}>
            <Accordion allowMultiple>
              {group.items.map(team => (
                <TeamRow
                  key={team.team_id}
                  team={team}
                  activeMember={userTeamIds.includes(team.team_id)}
                  actionButtons={<RestoreTeamButton team={team} />}
                />
              ))}
            </Accordion>
          </Stack>
        </Box>
      ))}
    </Box>
  );
};

const TeamsPage = withSuspense(() => {
  const teams = useQueryData({queryKey: ["teams"]});
  const deletedTeams = useQueryData({queryKey: ["deletedTeams"]});
  const {isOpen, onOpen, onClose} = useDisclosure();

  const {user} = useQueryData({queryKey: ["whoAmI"]});
  const userTeams = useQueryData({queryKey: ["userTeams", user.user_id]});
  const userTeamIds = userTeams.map(t => t.team_id);
  return (
    <>
      <ActionBar position="static">
        <Button leftIcon={<Icon as={PlusIcon} />} onClick={onOpen} colorScheme="green">
          Create team
        </Button>
        <CreateEditTeamModal type={"Create"} isOpen={isOpen} onClose={onClose} />
      </ActionBar>
      <Stack gap={200}>
        <TeamsList teams={teams} userTeamIds={userTeamIds} />
        <DeletedTeamsList teams={deletedTeams} userTeamIds={userTeamIds} />
      </Stack>
    </>
  );
});

export default TeamsPage;
