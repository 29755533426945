import {MagnifyingGlassIcon} from "@heroicons/react/20/solid";
import {
  Box,
  Flex,
  HStack,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Stack,
  StackDivider,
} from "@chakra-ui/react";
import groupBy, {Group} from "../utils/groupBy";
import useSearch from "../hooks/search";

type ItemListComp<T> = React.ComponentType<{item: T; highlight: string[]}>;

type ItemListProps<T> = {
  groupedItems: Group<string, T>[];
  highlight: string[];
  ItemComp: ItemListComp<T>;
  getKey: (item: T) => string;
};

function ItemList<T>({groupedItems, highlight, ItemComp, getKey}: ItemListProps<T>) {
  return (
    <Box as="nav" minH="0" flex="1">
      {groupedItems.map(group => (
        <Box key={group.key} position="relative">
          <Heading size="sm" borderY="1px solid" borderColor="gray.200" px="6" py="1" color="gray.500" bg="gray.50">
            {group.key}
          </Heading>
          <Stack divider={<StackDivider borderColor="gray.200" />} spacing={0}>
            {group.items.map(item => (
              <ItemComp key={getKey(item)} item={item} highlight={highlight} />
            ))}
          </Stack>
        </Box>
      ))}
    </Box>
  );
}

type SearchableListProps<T> = {
  title: string;
  items: T[];
  ItemComp: ItemListComp<T>;
  actionButtons?: React.ReactNode;
  getName: (item: T) => string;
  getKey: (item: T) => string;
};

export default function SearchableList<T>({title, items, actionButtons, getName, ...props}: SearchableListProps<T>) {
  const {query, queries, setQuery, search} = useSearch(getName);
  const filteredItems = items.filter(search);
  const groupedItems = groupBy(filteredItems, i => (getName(i)[0] || "-").toUpperCase()).sort((a, b) =>
    a.key.localeCompare(b.key),
  );

  return (
    <Flex direction="column" borderRight="1px solid" borderRightColor="gray.100" bg="white" h="full" overflow="auto">
      <Box px="6" pt="6" pb="4">
        <Heading size="md">{title}</Heading>
        <HStack mt="6">
          <InputGroup flex="1">
            <InputLeftElement>
              <Icon as={MagnifyingGlassIcon} />
            </InputLeftElement>
            <Input
              name="search"
              type="search"
              placeholder="Search"
              value={query}
              onChange={e => setQuery(e.target.value)}
            />
          </InputGroup>
          {actionButtons}
        </HStack>
      </Box>
      <ItemList groupedItems={groupedItems} highlight={queries} {...props} />
    </Flex>
  );
}
