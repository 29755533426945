import {
  Box,
  CloseButton,
  Divider,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  HStack,
  Icon,
  IconButton,
  Img,
  LinkBox,
  LinkBoxProps,
  Stack,
  useDisclosure,
} from "@chakra-ui/react";
import {useState} from "react";
import {NavLink} from "react-router-dom";
import Pages, {PageT} from "../Pages";
import {ChevronDownIcon, ChevronUpIcon, MagnifyingGlassIcon} from "@heroicons/react/24/solid";
import {AuthButton} from "./Auth";
import {useTrustCenterConfig, useTrustCenterState} from "../config";
import {Bars3Icon} from "@heroicons/react/20/solid";
import {useTrustCenterContext} from "../hooks/trustCenterContext";

const B = ({to, ...props}: {to: string} & LinkBoxProps) => (
  <LinkBox
    as={NavLink}
    display="flex"
    py={2}
    px={4}
    mx={4}
    fontWeight="semibold"
    _hover={{
      bg: "gray.50",
    }}
    rounded="lg"
    color="gray.700"
    alignItems="center"
    to={to}
    {...props}
  />
);

const NavButton = ({
  text,
  path,
  subpages,
  isOpen,
  toggle,
  closeNav,
}: PageT & {isOpen: boolean; toggle: (e: any) => void; closeNav: () => void}) => {
  const config = useTrustCenterConfig();
  const actualSubpages = subpages ? subpages(config) : undefined;
  return actualSubpages?.length ? (
    <>
      <B to={`./${path}`} py={0} px={0} onClick={closeNav}>
        <Box flex={1} py={2} px={4}>
          {text}
        </Box>
        <Flex flex={1} justifyContent="flex-end" onClick={toggle} py={2} px={4}>
          <Icon as={isOpen ? ChevronUpIcon : ChevronDownIcon} />
        </Flex>
      </B>
      {isOpen &&
        actualSubpages.map(s => (
          <B key={s.path} to={`./${s.path}`} fontSize="md" color="gray.600" pl={8} onClick={closeNav}>
            {s.text}
          </B>
        ))}
    </>
  ) : (
    <B to={`./${path}`} onClick={closeNav}>
      {text}
    </B>
  );
};

const NarrowNav = ({onClickSearch}: {onClickSearch: () => void}) => {
  const state = useTrustCenterState();
  const {loggedIn} = useTrustCenterContext();
  const {isOpen, onOpen, onClose} = useDisclosure();
  const [expandedNav, _setExpandedNav] = useState<null | number>(null);

  const setExpandedNav = (i: number) => (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    if (i == expandedNav) {
      _setExpandedNav(null);
    } else {
      _setExpandedNav(i);
    }
  };

  return (
    <>
      <HStack>
        <IconButton
          onClick={onClickSearch}
          aria-label="Search"
          variant="mobile-nav"
          icon={<Icon as={MagnifyingGlassIcon} fontSize="3xl" />}
        />
        <IconButton
          onClick={onOpen}
          aria-label="Navigation"
          variant="mobile-nav"
          icon={<Icon as={Bars3Icon} fontSize="3xl" />}
        />
      </HStack>
      <Drawer isOpen={isOpen} placement="right" size="full" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader px={8} py={8} as={HStack} justify="space-between" align="center">
            <Img src={state.config.logo_url} maxH={5} />
            <CloseButton h={6} onClick={onClose} />
          </DrawerHeader>

          <DrawerBody as={Stack} gap={2} p={0}>
            {Pages.filter(p => p.visible?.(state) ?? true).map((page, i) => (
              <NavButton key={i} closeNav={onClose} isOpen={i == expandedNav} toggle={setExpandedNav(i)} {...page} />
            ))}
            {false && (
              <>
                <Divider />

                {loggedIn ? (
                  <Box />
                ) : (
                  <Stack gap={3} py={2}>
                    <AuthButton
                      buttonType="Login"
                      mx={4}
                      justifyContent="flex-start"
                      size="lg"
                      fontSize="lg"
                      px={4}
                      leftIcon={undefined}
                    >
                      Login
                    </AuthButton>
                    <AuthButton
                      buttonType="Register"
                      colorScheme="green"
                      mx={4}
                      size="lg"
                      justifyContent="flex-start"
                      fontSize="lg"
                      px={4}
                      leftIcon={undefined}
                    >
                      Register
                    </AuthButton>
                  </Stack>
                )}
              </>
            )}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default NarrowNav;
