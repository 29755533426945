import {ListBulletIcon} from "@heroicons/react/20/solid";
import QuestionsPage from "./QuestionsPage";
import {RouteTab} from "../../../../../components/RouterTabs";

export default {
  title: "Questions",
  path: "questions",
  icon: ListBulletIcon,
  element: <QuestionsPage />,
} satisfies RouteTab;
