import {ReactNode} from "react";
import {useEventListener} from "usehooks-ts";
import {useQueryData} from "../state/index.ts";
import api from "../api/index.ts";
import {useToast} from "@chakra-ui/react";
import {useLayerType} from "../hooks/layerType.ts";
import {LayerType} from "../Types.ts";

export function InternalModeProvider({children}: {children?: ReactNode}) {
  const whoami = useQueryData({queryKey: ["whoAmI"]});
  const [layerType, setLayerType] = useLayerType();
  const toast = useToast();
  useEventListener("keydown", async e => {
    if (e.key === "`" && e.ctrlKey) {
      await api.auth.updateInternalMode(!whoami.internal_mode);
      if (layerType != LayerType.External && whoami.internal_mode) {
        setLayerType(LayerType.External);
      }
      toast({description: `Internal mode: ${whoami.internal_mode ? "Disabled" : "Enabled"}`, status: "warning"});
    }
  });
  return children;
}

export function Internal({children}: {children?: ReactNode}) {
  const whoami = useQueryData({queryKey: ["whoAmI"]});
  if (whoami.internal_mode) {
    return children;
  } else {
    return [];
  }
}
