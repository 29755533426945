import {useGoogleLogin} from "@react-oauth/google";
import {MsalContext} from "./ms";
import {OAuthProvider} from "../Types";
import {useContext, useRef} from "react";
import {useEventCallback} from "usehooks-ts";
import {handleImportError} from "../utils/import";

type PromiseResolver<T> = {
  resolve: (value: T) => void;
  reject: (err: unknown) => void;
};

type OauthLoginInfo = {
  isDisabled: {[provider in OAuthProvider]: boolean};
  acquireToken: (provider: OAuthProvider) => Promise<string>;
};

export class LoginCancelledError extends Error {
  constructor() {
    super("Login cancelled by user");
  }
}

export function useOauthLogin(): OauthLoginInfo {
  // Google can't manage to just return a promise...
  const googleLoginResolver = useRef<null | PromiseResolver<string>>(null);
  const loginWithGoogle = useGoogleLogin({
    flow: "implicit",
    prompt: "select_account",
    onSuccess: tokenResponse => {
      googleLoginResolver.current?.resolve(tokenResponse.access_token);
    },
    onError: e => googleLoginResolver.current?.reject(e),
    onNonOAuthError: e => {
      if (e.type === "popup_closed") {
        googleLoginResolver.current?.reject(new LoginCancelledError());
      } else {
        googleLoginResolver.current?.reject(e);
      }
    },
  });

  const msApp = useContext(MsalContext);

  const acquireToken = useEventCallback(async (provider: OAuthProvider) => {
    switch (provider) {
      case OAuthProvider.Google: {
        const promise = new Promise<string>((resolve, reject) => (googleLoginResolver.current = {resolve, reject}));
        loginWithGoogle();
        return await promise;
      }
      case OAuthProvider.Microsoft: {
        if (!msApp) throw new Error("Microsoft client not loaded");
        if (!window.crypto.subtle) throw new Error("Microsoft authentication can only be used over HTTPS");
        try {
          const tokenResponse = await msApp.acquireTokenPopup({
            scopes: ["openid", "email", "profile", "https://graph.microsoft.com/User.Read"],
            prompt: "select_account",
          });
          return tokenResponse.accessToken;
        } catch (ex) {
          // Load MSAL lazily to reduce bundle size. In practice, it will already have been loaded
          // by the time we hit this code path, since `msApp` must have been initialized.
          const {BrowserAuthError, BrowserAuthErrorMessage} = await import("@azure/msal-browser").catch(
            handleImportError,
          );

          if (ex instanceof BrowserAuthError) {
            if (ex.errorCode === BrowserAuthErrorMessage.userCancelledError.code) {
              throw new LoginCancelledError();
            }
          }
          throw ex;
        }
      }
    }
  });

  return {
    isDisabled: {
      google: false,
      microsoft: !msApp,
    },
    acquireToken,
  };
}
