import {Route} from "react-router-dom";
import productSelectorRoutes from "./ProductSelector";
import configurationRoutes from "./Settings";
import productRoutes from "../Products";
import {redirectRoute} from "../utils/redirect";
import RequireAccount from "./RequireAccount";
import LandingPage from "./LandingPage";
import AccountFrame from "./AccountFrame";

const accountRoutes = (
  <Route path="/" element={<RequireAccount />}>
    {productRoutes}

    <Route element={<AccountFrame />}>
      {productSelectorRoutes}
      {configurationRoutes}
    </Route>

    {redirectRoute("tasks", "vendor-toolkit/tasks")}
    {redirectRoute("questionnaires", "vendor-toolkit/questionnaires")}
    {redirectRoute("configuration", "vendor-toolkit/configuration")}
    {redirectRoute("library", "vendor-toolkit/library")}
    {redirectRoute("questions", "vendor-toolkit/questions")}
    {redirectRoute("trust-center", "vendor-toolkit/trust-center")}
    <Route index element={<LandingPage />} />
  </Route>
);

export default accountRoutes;
