import {
  Badge,
  Box,
  Button,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Icon,
  ListItem,
  Stack,
  Switch,
  Text,
  Tooltip,
  UnorderedList,
} from "@chakra-ui/react";
import {Link as RouterLink, useNavigate} from "react-router-dom";
import {useQueryData} from "../../../../../state";
import {useCallback, useMemo} from "react";
import {canResolveResolution, formatResolutionReason, resolutionRenderInfo} from "../utility/review";
import {useTime} from "../../../../../hooks/time";
import {relativeDateView} from "../../../../../utils/time";
import {usePromiseState} from "../../../../../hooks/promiseState";
import {ReviewItemFactTable} from "../components/Review/ReviewItemFactTable";
import api from "../../../../../api";
import {CheckIcon, ChevronLeftIcon, ChevronRightIcon} from "@heroicons/react/20/solid";
import {Resolution} from "../../../../../Types";
import {Internal} from "../../../../../components/InternalMode";
import {Type} from "../../../../../typing";
import {useTypedParams} from "../../../../../hooks/typedParams";

type ActionBarProps = {
  prevResolutionId?: string;
  nextResolutionId?: string;
  parent: "review-items" | "ignored-review-items" | "resolved-review-items";
};

function ActionBar({prevResolutionId, nextResolutionId, parent}: ActionBarProps) {
  const description =
    parent === "review-items" ? "unresolved" : parent === "ignored-review-items" ? "ignored" : "recently resolved";
  return (
    <HStack p={4} bg="gray.50" justifyContent="space-between">
      <Button as={RouterLink} leftIcon={<Icon as={ChevronLeftIcon} />} size="sm" to={`../../${parent}`} relative="path">
        Back to {description} review items
      </Button>
      <HStack>
        <Button
          as={RouterLink}
          leftIcon={<Icon as={ChevronLeftIcon} />}
          size="sm"
          relative="path"
          to={prevResolutionId !== undefined ? `../${prevResolutionId}` : undefined}
          isDisabled={prevResolutionId === undefined}
        >
          Previous
        </Button>
        <Button
          as={RouterLink}
          rightIcon={<Icon as={ChevronRightIcon} />}
          size="sm"
          relative="path"
          to={nextResolutionId !== undefined ? `../${nextResolutionId}` : undefined}
          isDisabled={nextResolutionId === undefined}
        >
          Next
        </Button>
      </HStack>
    </HStack>
  );
}

type HeaderProps = {
  resolution: Resolution;
};

function Header({resolution}: HeaderProps) {
  const time = useTime(relativeDateView(resolution.created_at), [resolution.created_at]);
  const r = resolutionRenderInfo(resolution);
  const reason = formatResolutionReason(resolution.reason);

  return (
    <Stack p={8} spacing={4}>
      <HStack flexGrow={1} spacing={4}>
        <Text fontSize="sm" fontWeight="semibold">
          {resolution.review_necessity * 100}%
        </Text>
        <Badge fontSize="sm" colorScheme={r.badge.color}>
          {r.badge.label}
        </Badge>
        <Heading flexGrow={1} size="sm">
          {r.description}
        </Heading>
        <Text fontSize="md" color="gray.500">
          {time}
        </Text>
      </HStack>
      <Text fontSize="md">{reason}</Text>
      <Text fontSize="sm" fontStyle="italic" whiteSpace="pre-wrap">
        {resolution.review_explanation}
      </Text>
    </Stack>
  );
}

type MainActionsProps = {
  prevResolutionId?: string;
  nextResolutionId?: string;
  resolution: Resolution;
};

function MainActions({prevResolutionId, nextResolutionId, resolution}: MainActionsProps) {
  const navigate = useNavigate();

  const advance = useCallback(async () => {
    const nextId = nextResolutionId ?? prevResolutionId;
    await navigate(nextId !== undefined ? `../${nextId}` : `..`, {relative: "path"});
  }, [navigate, nextResolutionId, prevResolutionId]);

  const [ignoring, ignore] = usePromiseState(async () => {
    await api.vendorToolkit.facts.ignoreResolution(resolution.resolution_id);
    await advance();
  }, [advance, resolution.resolution_id]);

  const [resolving, resolve] = usePromiseState(async () => {
    await api.vendorToolkit.facts.resolveResolution(resolution.resolution_id);
    await advance();
  }, [advance, resolution.resolution_id]);

  const [reverting, revert] = usePromiseState(async () => {
    await api.vendorToolkit.facts.revertResolution(resolution.resolution_id);
  }, [resolution.resolution_id]);

  const [togglingIsInternal, toggleIsInternal] = usePromiseState(async () => {
    await api.vendorToolkit.facts.updateResolutionIsInternal(resolution.resolution_id, !resolution.is_internal);
  }, [resolution.resolution_id, resolution.is_internal]);

  const resolvedOrIgnored = !!(resolution.ignored || resolution.resolved);
  const isPending = ignoring.inProgress || resolving.inProgress;
  const canResolve = canResolveResolution(resolution);

  return (
    <Box bg="gray.50" p={8}>
      <Internal>
        <FormControl display="flex" alignItems="center" justifyContent="left" gap={2} pb={4}>
          <Switch
            isChecked={resolution.is_internal}
            isDisabled={togglingIsInternal.inProgress}
            onChange={toggleIsInternal}
            id="resolution-is-internal"
          />
          <FormLabel htmlFor="resolution-is-internal" mb="0" flex="1" fontWeight="400">
            Internal only
          </FormLabel>
        </FormControl>
      </Internal>
      <HStack>
        {resolution.ignored ? (
          <>
            <Stack fontSize="md" flexGrow={1}>
              <Text>This item has already been ignored.</Text>
            </Stack>
            <Button
              onClick={revert}
              colorScheme="red"
              variant="outline"
              isDisabled={reverting.inProgress}
              isLoading={reverting.inProgress}
            >
              Revert
            </Button>
          </>
        ) : resolution.resolved ? (
          <>
            <Stack fontSize="md" flexGrow={1}>
              <Text>This item has already been resolved.</Text>
            </Stack>
            <Button
              onClick={revert}
              colorScheme="red"
              variant="outline"
              isDisabled={reverting.inProgress}
              isLoading={reverting.inProgress}
            >
              Revert
            </Button>
          </>
        ) : (
          <>
            <Stack fontSize="md" flexGrow={1}>
              <Text>You may:</Text>
              <UnorderedList>
                <ListItem>Resolve this contradiction by disabling incorrect facts, or</ListItem>
                <ListItem>Ignore this contradiction if it is incorrect</ListItem>
              </UnorderedList>
            </Stack>
            <Button
              onClick={ignore}
              colorScheme="blue"
              variant="outline"
              isDisabled={isPending || resolvedOrIgnored}
              isLoading={ignoring.inProgress}
            >
              Ignore
            </Button>
            <Tooltip label="You must first disable at least one incorrect fact.">
              <Button
                onClick={resolve}
                colorScheme="green"
                isDisabled={!canResolve || isPending || resolvedOrIgnored}
                isLoading={resolving.inProgress}
                leftIcon={<Icon as={CheckIcon} />}
              >
                Resolve
              </Button>
            </Tooltip>
          </>
        )}
      </HStack>
    </Box>
  );
}

export const ResolutionParams = Type.Object({resolutionId: Type.ResolutionId()});

function ReviewItemPage() {
  const {resolutionId} = useTypedParams(ResolutionParams);
  const resolution = useQueryData({queryKey: ["vendorToolkit", "resolution", resolutionId]});
  const resolutions = useQueryData({
    queryKey: resolution.resolved
      ? ["vendorToolkit", "resolvedResolutions"]
      : resolution.ignored
      ? ["vendorToolkit", "ignoredResolutions"]
      : ["vendorToolkit", "resolutions"],
  });
  const parent = resolution.resolved
    ? "resolved-review-items"
    : resolution.ignored
    ? "ignored-review-items"
    : "review-items";

  const resolutionIdx = useMemo(
    () => resolutions.findIndex(r => r.resolution_id === resolutionId),
    [resolutionId, resolutions],
  );

  const prevResolutionId = (resolutionIdx !== -1 && resolutions[resolutionIdx - 1]?.resolution_id) || undefined;
  const nextResolutionId = (resolutionIdx !== -1 && resolutions[resolutionIdx + 1]?.resolution_id) || undefined;

  return (
    <Stack flexGrow={1} spacing={0}>
      <ActionBar prevResolutionId={prevResolutionId} nextResolutionId={nextResolutionId} parent={parent} />
      <Header resolution={resolution} />
      <MainActions resolution={resolution} prevResolutionId={prevResolutionId} nextResolutionId={nextResolutionId} />
      <ReviewItemFactTable involvedFacts={resolution.involved_facts} />
    </Stack>
  );
}

export default ReviewItemPage;
