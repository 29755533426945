import {ChevronDownIcon, PaperClipIcon, PlusIcon, TrashIcon} from "@heroicons/react/24/solid";
import {
  Box,
  Button,
  Flex,
  HStack,
  Icon,
  IconButton,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  useDisclosure,
} from "@chakra-ui/react";
import {Link as RouterLink} from "react-router-dom";
import SharingClassification from "../../components/SharingClassification/Selector";
import {AttachSupportingDocumentationModal} from "./AttachSupportingDocumentation";
import api from "../../../../api";
import {Documentation, Question, QuestionId} from "../../../../Types";
import {extractDocumentationDetails} from "../Question/Documentation";
import {usePromiseState} from "../../../../hooks/promiseState";
import {withSuspense} from "../../../../state/withSuspense";
import {useQueryData} from "../../../../state";
import {getResponseLayer, useLayerType} from "../../../../hooks/layerType";
import {useFriendlyMimes} from "../../../../hooks/import";
import PortalMenuList from "../../../../components/PortalMenuList";

const DocumentationMenuItem = ({documentation, questionId}: {documentation: Documentation; questionId: QuestionId}) => {
  const [layerType] = useLayerType();
  const {resolveMime} = useFriendlyMimes();
  const {type, name, sharing, url, downloadFn} = extractDocumentationDetails(documentation, resolveMime);
  const [deleting, delete_] = usePromiseState(async () => {
    await api.vendorToolkit.questions.deleteDocumentation(questionId, layerType, documentation.documentation_id);
  }, [questionId, layerType, documentation.documentation_id]);
  return (
    <MenuItem as={HStack} align="center" spacing={2} closeOnSelect={false}>
      <Box flex={1}>
        <Link
          as={RouterLink}
          to={url}
          onClick={() => {
            if (!url) {
              downloadFn();
            }
          }}
          fontWeight="600"
          noOfLines={1}
        >
          {name}
        </Link>
        <Box fontSize="sm" color="gray.600">
          {type}
        </Box>
      </Box>
      <SharingClassification sharing={sharing} />
      <IconButton
        aria-label="Delete"
        size="sm"
        bg="transparent"
        color="gray.500"
        _hover={{bg: "white", color: "red.600"}}
        icon={<Icon as={TrashIcon} />}
        onClick={delete_}
        isLoading={deleting.inProgress}
        isDisabled={deleting.inProgress}
      />
    </MenuItem>
  );
};

const AttachSupportingDocumentsInlineMenuItems = withSuspense(({questionId}: {questionId: QuestionId}) => {
  const [layerType] = useLayerType();
  const documents = useQueryData({queryKey: ["vendorToolkit", "questionDocumentation", questionId, layerType]});

  return documents.map(doc => (
    <DocumentationMenuItem key={doc.documentation_id} documentation={doc} questionId={questionId} />
  ));
});

const AttachSupportingDocumentsInline = ({question}: {question: Question}) => {
  const [layerType] = useLayerType();
  const {isOpen, onOpen, onClose} = useDisclosure();
  const responseLayer = getResponseLayer(question.response_layers, layerType);
  const documents = useQueryData({
    queryKey: ["vendorToolkit", "questionDocumentation", question.question_id, layerType],
  });
  const numDocuments = responseLayer.documentation_count;

  const color = numDocuments > 0 ? "purple" : "gray";
  const s = numDocuments === 1 ? "" : "s";

  return (
    <>
      <Menu isLazy lazyBehavior="keepMounted" placement="top-end">
        <MenuButton as={Flex} cursor="pointer">
          <Flex
            bg={`${color}.100`}
            color={`${color}.500`}
            py="1"
            px="4"
            borderBottomRadius="md"
            border="1px solid"
            borderColor={`${color}.200`}
            fontWeight="600"
            fontSize="sm"
            align="center"
          >
            <Icon as={PaperClipIcon} mr={2} />
            <Box flex={1}>{numDocuments === 0 ? "Attach file" : `Attached (${numDocuments} file${s})`}</Box>
            <Icon as={ChevronDownIcon} ml={2} />
          </Flex>
        </MenuButton>
        <PortalMenuList fontSize="md" w="400px" pb="0">
          <AttachSupportingDocumentsInlineMenuItems questionId={question.question_id} />
          {numDocuments === 0 && (
            <Box textAlign="center" color="gray.400" pb={2} fontSize="md">
              No files attached
            </Box>
          )}
          <Button
            w="full"
            colorScheme="green"
            color="white"
            fontSize="md"
            mt={numDocuments > 0 ? 2 : 0}
            borderTopRadius="none"
            leftIcon={<Icon as={PlusIcon} />}
            onClick={onOpen}
          >
            Attach files
          </Button>
        </PortalMenuList>
      </Menu>
      <AttachSupportingDocumentationModal
        isOpen={isOpen}
        onClose={onClose}
        target={{type: "Question", questionId: question.question_id}}
        attachedDocs={documents}
      />
    </>
  );
};

export default AttachSupportingDocumentsInline;
