import {useOutletContext} from "react-router-dom";

export type ContextT = {
  loggedIn: boolean;
  logout: () => void;
  permissions: string[];
};

export function useTrustCenterContext() {
  return useOutletContext<ContextT>();
}
