import api from "../../../api";
import {FactClusterId, FactSearchArgs, ResolutionId} from "../../../Types";
import {param, typedQuery} from "../../typing";

export default [
  typedQuery(["vendorToolkit", "facts", param<FactSearchArgs>()], async context => {
    return await api.vendorToolkit.facts.search(context.queryKey[2]);
  }),
  typedQuery(["vendorToolkit", "topLevelFactClusters"], async () => {
    return await api.vendorToolkit.facts.listTopLevelClusters();
  }),
  typedQuery(["vendorToolkit", "factEquivalences", param<FactClusterId>("clusterId")], async context => {
    return await api.vendorToolkit.facts.listEquivalences(context.queryKey[2]);
  }),
  typedQuery(["vendorToolkit", "factsInCluster", param<FactClusterId>("clusterId")], async context => {
    return await api.vendorToolkit.facts.listFactsInCluster(context.queryKey[2]);
  }),
  typedQuery(["vendorToolkit", "resolutions"], async () => {
    return await api.vendorToolkit.facts.listResolutions();
  }),
  typedQuery(["vendorToolkit", "resolutionsCount"], async () => {
    return await api.vendorToolkit.facts.countResolutions();
  }),
  typedQuery(["vendorToolkit", "resolvedResolutions"], async () => {
    return await api.vendorToolkit.facts.listResolvedResolutions();
  }),
  typedQuery(["vendorToolkit", "ignoredResolutions"], async () => {
    return await api.vendorToolkit.facts.listIgnoredResolutions();
  }),
  typedQuery(["vendorToolkit", "resolution", param<ResolutionId>("resolutionId")], async context => {
    return await api.vendorToolkit.facts.getResolution(context.queryKey[2]);
  }),
];
