import {Box, Flex, Heading, Icon, Stack, Text} from "@chakra-ui/react";
import {QuestionId, Questionnaire, Section} from "../../../../Types";
import {useLayoutEffect, useState} from "react";
import {ChevronDownIcon, ChevronUpIcon} from "@heroicons/react/24/outline";
import FilterBanner from "../../../components/Filters/FilterBanner";
import {withSuspense} from "../../../../state/withSuspense";
import QuestionNumber from "../components/QuestionNumber";
import {QuestionNavItem} from "../../components/QuestionNavItem";

const SectionItem = ({text, selected, children}: {text: string; selected: boolean; children: React.ReactNode}) => {
  const [dropdownOpen, setDropdownOpen] = useState(selected);
  useLayoutEffect(() => {
    setDropdownOpen(selected);
  }, [selected]);
  return (
    <Flex flexDir="column" alignItems="start" fontSize="md" fontWeight="medium" align="center">
      <Box
        width="100%"
        _hover={{
          bg: "gray.100",
        }}
        py={4}
        px={4}
        borderRadius="2"
        transitionDuration="200ms"
        display="flex"
        justifyContent="space-between"
        fontWeight="700"
        color="gray.600"
        cursor="pointer"
        onClick={() => {
          setDropdownOpen(!dropdownOpen);
        }}
      >
        <Text pl="1" as="span" overflow="hidden" whiteSpace="nowrap" textOverflow="ellipsis">
          {text}
        </Text>
        <Icon as={dropdownOpen ? ChevronUpIcon : ChevronDownIcon} boxSize="5" ml={2} />
      </Box>
      <Stack width="100%" display={dropdownOpen ? "block" : "none"}>
        {children}
      </Stack>
    </Flex>
  );
};

type QuestionNavProps = {
  questionId: QuestionId;
  questionnaire: Questionnaire;
  clearFilters: () => void;
  filterCount: number;
  sections: Section[];
};

const QuestionNav = withSuspense(
  ({questionId, questionnaire, clearFilters, filterCount, sections}: QuestionNavProps) => {
    const thisSectionId = questionnaire.sections.find(section =>
      section.questions.some(question => question.question_id === questionId),
    )?.section_id;

    return (
      <Stack overflow="hidden" h="full" spacing={0} borderRight="1px solid" borderColor="gray.200">
        <Heading as="h2" size="md" padding="6">
          Questions
        </Heading>
        <FilterBanner
          clearFilters={clearFilters}
          filterCount={filterCount}
          borderBottom="1px solid"
          borderColor="orange.200"
        />
        <Stack spacing="1" overflowY="auto" flex={1} pb={4}>
          {sections
            .filter(section => section.questions.length > 0)
            .map(section => (
              <SectionItem
                key={section.section_id}
                text={section.title}
                selected={section.section_id === thisSectionId}
              >
                {section.questions.map(inner => (
                  <QuestionNavItem
                    key={inner.question_id}
                    to={`../${inner.question_id}`}
                    selected={questionId === inner.question_id}
                  >
                    <QuestionNumber question={inner} />
                    {inner.text.trim()}
                  </QuestionNavItem>
                ))}
              </SectionItem>
            ))}
        </Stack>
      </Stack>
    );
  },
);

export default QuestionNav;
