import Page from "../../../../components/Page";
import {Outlet} from "react-router-dom";
import SideBar from "./components/SideBar/SideBar";

function Layout() {
  return (
    <Page title="Fact library" display="flex" flexDirection="row" position="relative">
      <SideBar />
      <Outlet />
    </Page>
  );
}

export default Layout;
