import api from "../../../../../api";
import {usePromiseState} from "../../../../../hooks/promiseState";
import {DeleteModal} from "../../../../../components/DeleteModalButton";
import {SectionNode} from "../utility/hierarchy";

type Props = {
  section: SectionNode;

  isOpen: boolean;
  onClose: () => void;
};

function DeleteSectionModal({section, isOpen, onClose}: Props) {
  const [deleting, delete_] = usePromiseState(async () => {
    await api.vendorToolkit.librarySections.deleteLibrarySection(section.obj.library_section_id);
    onClose();
  }, [section.obj.library_section_id, onClose]);

  return (
    <DeleteModal
      isOpen={isOpen}
      onClose={onClose}
      delete_={delete_}
      deleting={deleting.inProgress}
      modalHeader={`Delete ${section.obj.name}`}
      modalBody={`Are you sure you want to delete the section ${section.obj.name}?`}
    />
  );
}

export default DeleteSectionModal;
