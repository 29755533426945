import api from "../../api";
import {RoleId} from "../../Types";
import {param, typedQuery} from "../typing";

export default [
  typedQuery(["roles"], async () => {
    return await api.roles.list();
  }),
  typedQuery(["roleMembers", param<RoleId>("roleId")], async context => {
    return await api.roles.listMembers(context.queryKey[1]);
  }),
];
