import {ScopeAxisMin, ScopeMin} from "../../../../Types.ts";
import api, {HTTPError} from "../../../../api/index.ts";
import {
  Box,
  Button,
  Flex,
  HStack,
  Heading,
  Icon,
  IconButton,
  Link,
  Stack,
  StackDivider,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import {ActionBar} from "../../../../components/ActionBar.tsx";
import {useCallback, useMemo} from "react";
import CommitInput from "../../../../components/CommitInput.tsx";
import {useQueryData} from "../../../../state/index.ts";
import {withSuspense} from "../../../../state/withSuspense.tsx";
import Page from "../../../../components/Page.tsx";
import {FolderPlusIcon, PencilSquareIcon, PlusIcon} from "@heroicons/react/20/solid";
import EditScopeAxisModal from "./EditScopeAxisModal.tsx";
import NewScopeModal from "./NewScopeModal.tsx";
import {GLOBAL_COLOR} from "./index.tsx";

const ScopeRow = ({scope}: {scope: ScopeMin | null}) => {
  const updateName = useCallback(
    async (newName: string) => {
      try {
        await api.vendorToolkit.scopes.updateName(scope!.scope_id, newName);
      } catch (ex) {
        if (ex instanceof HTTPError) {
          switch (ex.response.status) {
            case 422:
              throw new Error("Name must be between 1 and 256 characters");
            case 409:
              throw new Error(await ex.response.json());
          }
        }
        throw ex;
      }
    },
    [scope],
  );
  return (
    <HStack align="center" px="6" py="5" fontSize="md" spacing={8}>
      <Box flex={3}>
        <CommitInput value={scope?.name ?? "Global"} isDisabled={!scope} onCommit={updateName} />
      </Box>
    </HStack>
  );
};

function ScopeAxisGroup({axis}: {axis?: ScopeAxisMin}) {
  const {isOpen: isAddOpen, onClose: onAddClose, onOpen: onAddOpen} = useDisclosure();
  const {isOpen: isEditOpen, onClose: onEditClose, onOpen: onEditOpen} = useDisclosure();
  const scopes = useQueryData({queryKey: ["vendorToolkit", "scopes"]});
  const filteredScopes = useMemo(
    () => (axis?.axis_id ? scopes.filter(scope => scope.axis.axis_id === axis.axis_id) : [null]),
    [scopes, axis],
  );
  return (
    <>
      <HStack borderTop="1px solid" borderBottom="1px solid" borderColor="gray.200" px="6" py="2" bg="gray.50" h="48px">
        <Box w={4} h={4} background={axis?.color ?? GLOBAL_COLOR} borderRadius="2px" />
        <Heading size="sm" color="gray.500">
          {axis?.name ?? "Global"}
        </Heading>
        {axis && (
          <IconButton
            aria-label="edit"
            variant="ghost"
            size="sm"
            fontSize="16px"
            icon={<Icon as={PencilSquareIcon} />}
            onClick={onEditOpen}
          />
        )}
        {axis && (
          <IconButton
            ml="auto"
            aria-label="add"
            variant="ghost"
            size="sm"
            fontSize="16px"
            icon={<Icon as={PlusIcon} />}
            onClick={onAddOpen}
          />
        )}
      </HStack>
      <Stack divider={<StackDivider borderColor="gray.200" />} spacing={0}>
        {filteredScopes.map(scope => (
          <ScopeRow key={scope?.scope_id ?? "global"} scope={scope} />
        ))}
      </Stack>
      <NewScopeModal isOpen={isAddOpen} onClose={onAddClose} initialAxisId={axis?.axis_id} />
      <EditScopeAxisModal isOpen={isEditOpen} onClose={onEditClose} initialValue={axis} />
    </>
  );
}

function ScopeAxisList() {
  const scopeAxes = useQueryData({queryKey: ["vendorToolkit", "scopeAxes"]});
  return (
    <>
      <ScopeAxisGroup />
      {scopeAxes.map(axis => (
        <ScopeAxisGroup axis={axis} />
      ))}
    </>
  );
}

const ScopesPage = withSuspense(() => {
  const {isOpen, onClose, onOpen} = useDisclosure();

  return (
    <Page title="Scopes" display="flex" flexDirection="column">
      <Flex p="8" pb="6" justifyContent="space-between">
        <Heading size="md" p="0">
          Scopes
        </Heading>
      </Flex>
      <ActionBar
        actionButtons={
          <Text fontSize="md" color="gray.500" fontStyle="italic" maxWidth="300px" textAlign="right">
            Scopes can be removed by sending a ticket to{" "}
            <Link href="mailto:support@platformed.com" color="blue.500">
              support@platformed.com
            </Link>
          </Text>
        }
      >
        <Button
          leftIcon={<Icon as={FolderPlusIcon} />}
          onClick={onOpen}
          colorScheme="green"
          children="New scope type"
        />
        <EditScopeAxisModal isOpen={isOpen} onClose={onClose} />
      </ActionBar>
      <ScopeAxisList />
    </Page>
  );
});

export default ScopesPage;
