import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionItemProps,
  AccordionPanel,
  Flex,
  HStack,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  Text,
  Tooltip,
  useClipboard,
  useDisclosure,
} from "@chakra-ui/react";
import {memo} from "react";
import {Fact} from "../../../../Types.ts";
import {FactSources} from "./FactSources.tsx";
import {EllipsisVerticalIcon} from "@heroicons/react/20/solid";
import {usePromiseState} from "../../../../hooks/promiseState.ts";
import api from "../../../../api/index.ts";
import FirstLineCenter from "../../../components/FirstLineCenter.tsx";
import MoveFactModal from "../FactLibrary/modals/MoveFact.tsx";
import {Hierarchy, SectionParentNode} from "../FactLibrary/utility/hierarchy.ts";
import PortalMenuList from "../../../../components/PortalMenuList.tsx";
import {ClipboardDocumentIcon} from "@heroicons/react/24/outline";
import {ScopeBadge} from "../../components/ScopeBadge.tsx";
import CosineDistanceBadge from "../../../../components/CosineDistanceBadge.tsx";

export type FactLibraryContext = {
  hierarchy: Hierarchy;
  parent: SectionParentNode;
  recurse: boolean;
};

export const FactRow = memo(
  ({
    fact,
    libraryContext,
    cosine_distance,
    ...props
  }: {fact: Fact; libraryContext?: FactLibraryContext; cosine_distance?: number} & AccordionItemProps) => {
    const [togglingFactEnabled, toggleFactEnabled] = usePromiseState(async () => {
      await api.vendorToolkit.facts.updateDisabled(fact.fact_id, !fact.disabled);
    }, [fact]);

    const moveFactModal = useDisclosure();
    const {onCopy, hasCopied} = useClipboard(fact.representative_text.content, 1000);

    return (
      <AccordionItem {...props} role="group">
        <HStack
          color="gray.700"
          _hover={{color: "black"}}
          transitionProperty="color"
          transitionDuration="200ms"
          alignItems="start"
          gap="0"
          py={3}
        >
          <AccordionButton display="flex" alignItems="start" flex="1" gap="2" py="0" userSelect="text" _hover={{}}>
            <FirstLineCenter>
              <AccordionIcon mt="1px" />
            </FirstLineCenter>
            <Text
              flex="1"
              textAlign="left"
              whiteSpace="pre-line"
              textDecoration={fact.disabled ? "line-through" : undefined}
            >
              {fact.representative_text.content}
            </Text>
            <FirstLineCenter>
              <Flex mt="1px" justifyContent="end" flexWrap="wrap" maxW="140px" gap="1">
                {cosine_distance !== undefined && <CosineDistanceBadge distance={cosine_distance} />}
                <ScopeBadge scope={fact.library_section.scope} />
              </Flex>
            </FirstLineCenter>
          </AccordionButton>
          <FirstLineCenter>
            <Tooltip label={hasCopied ? "Copied!" : "Copy to clipboard"} placement="top">
              <IconButton
                icon={<Icon as={ClipboardDocumentIcon} />}
                minW={4}
                _hover={{color: "blue.500"}}
                variant="link"
                aria-label="Copy to clipboard"
                onClick={e => {
                  // The accordion causes the whole FactRow to collapse if we don't capture the click here
                  e.preventDefault();
                  onCopy();
                }}
              />
            </Tooltip>
            <Menu placement="bottom-end">
              <MenuButton
                isLoading={togglingFactEnabled.inProgress}
                isDisabled={togglingFactEnabled.inProgress}
                as={IconButton}
                aria-label="Actions"
                variant="link"
                icon={<Icon as={EllipsisVerticalIcon} />}
              />
              <PortalMenuList fontSize="md">
                {libraryContext !== undefined && (
                  <MenuItem onClick={moveFactModal.onOpen}>Move within scope...</MenuItem>
                )}
                <MenuItem color={fact.disabled ? "green.500" : "red.500"} onClick={toggleFactEnabled}>
                  {fact.disabled ? "Start" : "Stop"} using this fact
                </MenuItem>
              </PortalMenuList>
            </Menu>
          </FirstLineCenter>
        </HStack>
        <AccordionPanel bg="gray.50" p={0}>
          <FactSources fact={fact} />
        </AccordionPanel>

        {libraryContext !== undefined && moveFactModal.isOpen && (
          <MoveFactModal
            fact={fact}
            {...moveFactModal}
            libraryContext={libraryContext}
            defaultParent={libraryContext.parent}
          />
        )}
      </AccordionItem>
    );
  },
);
