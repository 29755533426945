import {useRef, useState} from "react";

import {NotificationFeedContainer, NotificationFeedPopover, UnseenBadge} from "@knocklabs/react";
import {BellIcon} from "@heroicons/react/24/outline";
import {Icon} from "@chakra-ui/react";
import {RoundButton} from "./TopRightNav/RoundButton";

export default function Notifications() {
  const [notificationsVisible, setNotificationsVisible] = useState(false);
  const notificationButtonRef = useRef(null);

  // We need the below pair of lint/compiler ignores because Knock's type definition files somehow
  // omit the fact that KnockFeedContainer takes child elements as a prop.
  return (
    <>
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/*
      // @ts-ignore */}
      <NotificationFeedContainer>
        <NotificationFeedPopover
          buttonRef={notificationButtonRef}
          isVisible={notificationsVisible}
          onClose={() => setNotificationsVisible(false)}
        />
        <RoundButton
          data-testid="notifications-button"
          ref={notificationButtonRef}
          onClick={() => setNotificationsVisible(!notificationsVisible)}
        >
          <Icon as={BellIcon} h="6" w="6" />
          <UnseenBadge />
        </RoundButton>
      </NotificationFeedContainer>
    </>
  );
}
