import * as _ from "lodash-es";
import {OptionalResponseLayer} from "../../../../../hooks/layerType";

export enum AiResult {
  GoodAnswer = "Good answer",
  GoodOmission = "Good omission",
  ShouldHaveAnswered = "Should have answered",
  ShouldNotHaveAnswered = "Should not have answered",
  BadAnswer = "Bad answer",
  NotAttempted = "Not attempted",
}

export function categorizeQuestionAiResult({
  currentLayer,
  aiLayer,
}: {
  currentLayer: OptionalResponseLayer;
  aiLayer: OptionalResponseLayer;
}): AiResult {
  if (!currentLayer.ai_attempted) {
    return AiResult.NotAttempted;
  }
  const hasCurrentAnswer =
    currentLayer.response_text ||
    currentLayer.response_yes_no != null ||
    (currentLayer.response_select && currentLayer.response_select.length > 0);
  const hasAiAnswer =
    aiLayer.response_text ||
    aiLayer.response_yes_no != null ||
    (aiLayer.response_select && aiLayer.response_select.length > 0);
  const eqAnswer =
    currentLayer.response_text === aiLayer.response_text &&
    currentLayer.response_yes_no === aiLayer.response_yes_no &&
    _.isEqual(currentLayer.response_select, aiLayer.response_select);
  return eqAnswer
    ? hasCurrentAnswer
      ? AiResult.GoodAnswer
      : AiResult.GoodOmission
    : hasCurrentAnswer
    ? hasAiAnswer
      ? AiResult.BadAnswer
      : AiResult.ShouldHaveAnswered
    : AiResult.ShouldNotHaveAnswered;
}
