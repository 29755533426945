import {Box} from "@chakra-ui/react";

import Page from "../../../components/Page.tsx";
import {withSuspense} from "../../../state/withSuspense.tsx";
import {useQueriesData} from "../../../state/index.ts";
import SingleQuestionView from "../Questionnaires/QuestionnaireViewer/Questions/SingleQuestionView.tsx";
import {QuestionSidePane} from "./QuestionSidePane.tsx";
import {useMemo} from "react";
import {useTypedParams} from "../../../hooks/typedParams";
import {QuestionParams} from "./Crumb";

const QuestionViewer = withSuspense(() => {
  const {questionId} = useTypedParams(QuestionParams);
  const [adhocQuestion, whoami] = useQueriesData({
    queries: [
      {
        queryKey: ["vendorToolkit", "adhocQuestion", questionId],
      },
      {
        queryKey: ["whoAmI"],
      },
    ],
  });
  const {question_number, text} = adhocQuestion.question;
  const account = whoami.account!;

  const title = `${question_number ?? ""} ${text}`.trim();
  const parent = useMemo(
    () =>
      ({
        type: "Account",
        promptModifiers: account.prompt_modifiers,
      }) as const,
    [account.prompt_modifiers],
  );

  return (
    <Page title={title}>
      <Box display="flex" flexDir="row">
        <QuestionSidePane questionId={questionId} />
        <SingleQuestionView key={questionId} question={adhocQuestion.question} parent={parent} />
      </Box>
    </Page>
  );
});

export default QuestionViewer;
