import {ExternalAuthorizationId, CrmConnectionId} from "../../../Types";
import api from "../../../api";
import {param, typedQuery} from "../../typing";

export default [
  typedQuery(["vendorToolkit", "crmConnectors"], async () => {
    return await api.vendorToolkit.crmConnections.listConnectors();
  }),
  typedQuery(["vendorToolkit", "crmConnector", param("crmConnectorId")], async context => {
    return await api.vendorToolkit.crmConnections.getConnector(context.queryKey[2]);
  }),
  typedQuery(["vendorToolkit", "crmConnections"], async () => {
    return await api.vendorToolkit.crmConnections.list();
  }),
  typedQuery(["vendorToolkit", "crmConnection", param<CrmConnectionId>("crmConnectionId")], async context => {
    return await api.vendorToolkit.crmConnections.get(context.queryKey[2]);
  }),
  typedQuery(
    [
      "vendorToolkit",
      "crmAccountInfo",
      param("crmConnectorId"),
      param<ExternalAuthorizationId>("externalAuthorizationId"),
    ],
    async context => {
      return await api.vendorToolkit.crmConnections.getAccountInfo(context.queryKey[2], context.queryKey[3]);
    },
  ),
];
