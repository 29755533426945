import {ReactNode} from "react";
import {AccountFeature} from "../Types";
import {useAccountFeatures} from "../hooks/accountFeatures";
import {useQueryData} from "../state";

type AccountFeatureProps = {
  feature: AccountFeature | AccountFeature[];
  showInternally?: boolean;
  children?: ReactNode;
};

export default function RequireAccountFeature({feature, showInternally, children}: AccountFeatureProps) {
  const {enabledFeatures} = useAccountFeatures();
  const whoami = useQueryData({queryKey: ["whoAmI"]});
  const show =
    (Array.isArray(feature) ? feature.every(f => enabledFeatures[f]) : enabledFeatures[feature]) ||
    (showInternally && whoami.internal_mode);
  return show ? children : null;
}
