import {useLayoutEffect, useRef} from "react";

export default function useLayoutUnmount(f: () => void) {
  const ref = useRef(f);
  ref.current = f;
  // `useEffect` destructor runs *after* the element has been removed from the page.
  // Callers of this hook expect the callback to run just *before* the element has been removed.
  // For this we need `useLayoutEffect`.
  useLayoutEffect(() => () => ref.current(), []);
}
