import {VariableIcon} from "@heroicons/react/20/solid";
import FactsPage from "./FactsPage";
import {RouteTab} from "../../../../../components/RouterTabs";

export default {
  title: "Facts",
  path: "facts",
  icon: VariableIcon,
  element: <FactsPage />,
} satisfies RouteTab;
