import {Flex, Heading} from "@chakra-ui/react";
import Page from "../Page.tsx";
import TabsOutlet from "./Outlet.tsx";

const DefaultPage = ({title}: {title?: string}) => {
  return (
    <Page title={title ?? ""} display="flex" flexDirection="column">
      <Flex p="8" pb="6">
        <Heading size="md" p="0">
          {title}
        </Heading>
      </Flex>
      <TabsOutlet />
    </Page>
  );
};

export default DefaultPage;
