import {ApiDateTime} from "../Types.ts";
import {useTime} from "../hooks/time.ts";
import {relativeDateView} from "../utils/time.ts";
import {Tooltip, Text, TextProps} from "@chakra-ui/react";
import {format} from "date-fns";
import {parseISO} from "../utils/date";

function RelativeTimeText({dateTime, ...props}: {dateTime: ApiDateTime} & TextProps) {
  const timeText = useTime(relativeDateView(dateTime), [dateTime]);
  return (
    <Tooltip label={format(parseISO(dateTime), "PPPpp")} placement="top">
      <Text whiteSpace="nowrap" {...props}>
        {timeText}
      </Text>
    </Tooltip>
  );
}

export default RelativeTimeText;
