import {Badge, BadgeProps} from "@chakra-ui/react";
import {Account, AccountType} from "../Types";

const COLOR: {[v in AccountType]: string} = {
  Sandbox: "blue",
  Live: "orange",
};

const AccountTypeBadge = ({account, ...props}: {account: Account} & BadgeProps) => (
  <Badge colorScheme={COLOR[account.type_]} {...props}>
    {account.type_}
  </Badge>
);

export default AccountTypeBadge;
