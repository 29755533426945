import {File as FileT} from "../../Types";

import StyledFile from "./StyledFile";
import {FileUploadBase} from "../../hooks/fileUpload";
import {useAsyncOperation} from "../../hooks/asyncOperation";
import {useCallback} from "react";

type FileUploaderProps = {
  fileUpload: FileUploadBase;
  onDelete: (fileUpload: FileUploadBase) => void;
};

export const UploadingFile = ({fileUpload, onDelete}: FileUploaderProps) => {
  const state = useAsyncOperation(fileUpload);
  const delete_ = useCallback(() => onDelete(fileUpload), [onDelete, fileUpload]);
  return (
    <StyledFile
      file={fileUpload.file}
      onDelete={delete_}
      progress={state.id === "Uploading" ? state.progress : undefined}
      error={state.id === "Error" ? state.error : undefined}
    />
  );
};

export const UploadedFile = ({file, onDelete}: {file: FileT; onDelete?: () => void}) => {
  return <StyledFile file={{type: file.mime_type, ...file}} onDelete={onDelete} />;
};
