import {nominate} from "../../../../../Types";
import {
  findScopeById,
  findSectionById,
  Hierarchy,
  ScopeNode,
  SectionNode,
  SectionParentNode,
  getNodeKey,
  getSectionFullyQualifiedName,
  getNodeName,
} from "./hierarchy";

export type Option = {
  label: string;
  value: string;
};

/**
 * Convert a scope or section to an option value to use in the "Parent"
 * dropdown.
 */
export function toOptionValue(s: SectionParentNode): string {
  return `${s.type}:${getNodeKey(s)}`;
}

/**
 * Convert an option value used in the "Parent" dropdown to a scope or section
 * node. Returns `null` for the global scope.
 */
export function fromOptionValue(hierarchy: Hierarchy, optionValue: string): SectionParentNode {
  const [prefix, id] = optionValue.split(":");

  if (prefix === "scope") {
    const node = findScopeById(hierarchy, nominate("scopeId", id));

    if (node === undefined) {
      throw new Error("Scope not found");
    }

    return node;
  } else if (prefix === "section") {
    const node = findSectionById(hierarchy, nominate("librarySectionId", id));

    if (node === undefined) {
      throw new Error("Section not found");
    }

    return node;
  }

  throw new Error(`Unexpected prefix ${prefix}`);
}

export type FlattenToOptionsOpts = Partial<{
  scopeLabelSuffix?: string;
}>;

export function flattenHierarchyToOptions(hierarchy: Hierarchy, opts?: FlattenToOptionsOpts): Option[] {
  return hierarchy.axes.flatMap(axis => axis.children.flatMap(scope => flattenScopeToOptions(scope, opts)));
}

export function flattenScopeToOptions(scope: ScopeNode, opts?: FlattenToOptionsOpts): Option[] {
  function recurseSections(sections: SectionNode[]): Option[] {
    return sections.flatMap(section => [
      {
        label: getSectionFullyQualifiedName(section),
        value: toOptionValue(section),
      },
      ...recurseSections(section.children),
    ]);
  }

  let scopeLabel = getNodeName(scope);

  if (opts?.scopeLabelSuffix !== undefined) {
    scopeLabel += ` ${opts.scopeLabelSuffix}`;
  }

  return [{label: scopeLabel, value: toOptionValue(scope)}, ...recurseSections(scope.children)];
}
