import {createContext} from "react";

// Type of callbacks for receiving pusher events
export type PusherCallback = (eventName: string, data: any) => void;

// Public interface we want to expose from `PusherContext`
export interface Pusher {
  // Subscribe to the provided channel
  // If `eventName` is not null, only events with that name will be received
  // The return value is a function for unsubscribing again
  // It is safe to subscribe to the same channel/event multiple times
  subscribe(channelName: string, eventName: string | null, cb: PusherCallback): () => void;
  trigger(channelName: string, eventName: string, data: any): void;
}

// Dummy interface when we don't have a live connection
export const DUMMY_PUSHER = {
  subscribe: () => {
    return () => {};
  },
  trigger: () => {},
};

export const pusherContext = createContext<Pusher>(DUMMY_PUSHER);
