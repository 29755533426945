import {HStack, Img, Text} from "@chakra-ui/react";
import AccountPromptModifiersScreenshot from "../../../assets/new_features/account_prompt_modifiers.png";
import {Feature} from ".";

export default {
  heading: "Configure your questionnaire answering preferences",
  text: (
    <>
      <Text>
        You can now configure how you’d like the AI to fill out responses to your questionnaires. Choose between
        American or British English, first or third person, and the answering length.
      </Text>
    </>
  ),
  image: (
    <HStack
      bg="orange.200"
      bgGradient="linear(165deg, cyan.200, green.200, green.300)"
      alignItems="center"
      justifyContent="center"
      p={4}
    >
      <Img src={AccountPromptModifiersScreenshot} rounded="md" />
    </HStack>
  ),
  publishDate: new Date(Date.parse("29 Jul 2024")),
  actionText: "Go to Configuration",
  url: "/vendor-toolkit/configuration/organisation-profile",
} satisfies Feature;
