import {useQueryData} from "../../../../../state/index.ts";
import {Link as RouterLink} from "react-router-dom";
import {
  Heading,
  HStack,
  Icon,
  IconButton,
  Stack,
  StackProps,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
} from "@chakra-ui/react";
import {ChevronRightIcon} from "@heroicons/react/20/solid";
import Page from "../../../../../components/Page.tsx";
import {OwnerT, Resolution} from "../../../../../Types.ts";
import * as _ from "lodash-es";
import {RESOLUTION_TYPE_MAP} from "./index.tsx";
import ResolutionBadge from "./ResolutionBadge.tsx";
import {useCallback} from "react";
import api from "../../../../../api/index.ts";
import Owner from "../../../../../components/Owner.tsx";

const ScopeLabel = ({name, ...props}: {name: string} & StackProps) => (
  <HStack
    border="1px solid"
    borderColor="gray.200"
    display="inline-flex"
    px={2}
    rounded="md"
    gap={2}
    color="gray.700"
    {...props}
  >
    <Text fontWeight="semibold" fontSize="md">
      {name}
    </Text>
  </HStack>
);

const ResolutionItem = ({
  resolution: {resolution_id, resolution_type, scope, reason, involved_facts, owner, review_necessity},
}: {
  resolution: Resolution;
}) => {
  const info = RESOLUTION_TYPE_MAP[resolution_type];
  const reassign = useCallback(
    async (owner: OwnerT | undefined) => {
      api.vendorToolkit.facts.assignResolution(resolution_id, owner?.owner_id ?? null);
    },
    [resolution_id],
  );
  return (
    <Tr>
      <Td>{review_necessity * 100}%</Td>
      <Td>
        <ResolutionBadge type={resolution_type} />
      </Td>
      <Td>{scope ? <ScopeLabel name={scope.name} /> : <ScopeLabel name="Global" color="gray.500" />}</Td>
      <Td fontSize="md">
        <Tooltip label={reason}>{info.description(involved_facts)}</Tooltip>
      </Td>
      <Td>
        <Owner owner={owner} onReassign={reassign} />
      </Td>
      <Td>
        <HStack>
          <IconButton
            as={RouterLink}
            to={resolution_id}
            variant="ghost"
            aria-label="View"
            icon={<Icon fontSize="xl" as={ChevronRightIcon} />}
          />
        </HStack>
      </Td>
    </Tr>
  );
};

const ResolutionsPage = () => {
  const result = useQueryData({queryKey: ["vendorToolkit", "resolutions"]});

  return (
    <Page title="Facts" display="flex" flexDirection="column" position="relative" pb={20}>
      <Stack flex="1" spacing={0}>
        <Heading size="md" p="8">
          Resolutions
        </Heading>
        <TableContainer maxWidth="100%" whiteSpace="normal" flex="1">
          <Table style={{borderCollapse: "separate", borderSpacing: "0"}} size="lg" layout="fixed">
            <Thead bg="white" borderBottom="10px solid" borderBottomColor="red.100">
              <Tr>
                <Th width="13%" color="gray.500">
                  Likelihood
                </Th>
                <Th width="9%" color="gray.500">
                  Type
                </Th>
                <Th width="13%" color="gray.500">
                  Scope
                </Th>
                <Th width="24%" color="gray.500">
                  Description
                </Th>
                <Th width="21%" color="gray.500">
                  Owner
                </Th>
                <Th width="8%" color="gray.500" />
              </Tr>
            </Thead>
            <Tbody>
              {result.map(resolution => (
                <ResolutionItem key={resolution.resolution_id} resolution={resolution} />
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Stack>
    </Page>
  );
};

export default ResolutionsPage;
