import {
  TrustCenterConfig,
  TrustCenterContent,
  TrustCenterContentMin,
  CreateTrustCenterContent,
  IObject,
  TrustCenterConfigMeta,
  ContentId,
  MailingList,
} from "../../Types";
import {invalidateQueries} from "../../state";
import jsonApi from "../jsonApi";

export async function getConfig() {
  return await jsonApi.get<TrustCenterConfig>("/vendor_toolkit/trust_center/config");
}

export async function updateIsPublic(isPublic: boolean) {
  const res = await jsonApi.post<"ok">(`/vendor_toolkit/trust_center/config/is_public`, isPublic);
  await invalidateQueries([{queryKey: ["vendorToolkit", "trustCenterConfig"]}]);
  return res;
}

export async function updateConfigDocumentRequestEmail(email: string) {
  const res = await jsonApi.post<"ok">(`/vendor_toolkit/trust_center/config/document_request_email`, email);
  await invalidateQueries([{queryKey: ["vendorToolkit", "trustCenterConfig"]}]);
  return res;
}

export async function updateConfigTheme(theme: IObject) {
  const res = await jsonApi.post<"ok">(`/vendor_toolkit/trust_center/config/theme`, theme);
  await invalidateQueries([{queryKey: ["vendorToolkit", "trustCenterConfig"]}]);
  return res;
}

export async function updateConfigMeta(meta: TrustCenterConfigMeta) {
  const res = await jsonApi.post<"ok">(`/vendor_toolkit/trust_center/config/meta`, meta);
  await invalidateQueries([{queryKey: ["vendorToolkit", "trustCenterConfig"]}]);
  return res;
}

export async function listContents() {
  return await jsonApi.get<TrustCenterContentMin[]>("/vendor_toolkit/trust_center/contents");
}

export async function getContent(contentId: ContentId) {
  return await jsonApi.get<TrustCenterContent>(`/vendor_toolkit/trust_center/contents/${contentId}`);
}

export async function createContent(content: CreateTrustCenterContent) {
  const res = await jsonApi.post<TrustCenterContent>("/vendor_toolkit/trust_center/contents", content);
  await invalidateQueries([{queryKey: ["vendorToolkit", "trustCenterContents"]}]);
  return res;
}

export async function updateContentTitle(contentId: ContentId, title: string) {
  const res = await jsonApi.post<"ok">(`/vendor_toolkit/trust_center/contents/${contentId}/title`, title);
  await invalidateQueries([
    {queryKey: ["vendorToolkit", "trustCenterContents"]},
    {queryKey: ["vendorToolkit", "trustCenterContent", contentId]},
  ]);
  return res;
}

export async function updateContentContent(contentId: ContentId, content: string) {
  const res = await jsonApi.post<"ok">(`/vendor_toolkit/trust_center/contents/${contentId}/content`, content);
  await invalidateQueries([{queryKey: ["vendorToolkit", "trustCenterContent", contentId]}]);
  return res;
}

export async function deleteContent(contentId: ContentId) {
  const res = await jsonApi.delete_<"ok">(`/vendor_toolkit/trust_center/contents/${contentId}`);
  await invalidateQueries([{queryKey: ["vendorToolkit", "trustCenterContents"]}]);
  return res;
}

export async function getMailingList() {
  return await jsonApi.get<MailingList>("/vendor_toolkit/trust_center/mailing_list");
}
