import {FormControl, FormLabel} from "@chakra-ui/react";
import {ChangeEvent, useCallback} from "react";
import {DocumentSourceProps} from "./AnyDocumentSource";
import {AutoResizingTextarea} from "../../../../../../components/AutoResizingTextarea";

const WebDocumentSource = ({payload, setPayload}: DocumentSourceProps) => {
  const change = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement>) => {
      setPayload({
        config: {type: "Web", content: {urls: e.target.value.split("\n")}},
      });
    },
    [setPayload],
  );

  const config = payload?.config;
  if (config && config.type !== "Web") {
    return null;
  }

  return (
    <FormControl w="full">
      <FormLabel>Enter one URL per line</FormLabel>
      <AutoResizingTextarea
        value={config?.content.urls.join("\n") ?? ""}
        onChange={change}
        minRows={5}
        whiteSpace="pre"
      />
    </FormControl>
  );
};

export default WebDocumentSource;
