import {OwnerId, OwnerT, ResolveOwner} from "../Types";
import jsonApi from "./jsonApi";

export async function resolve(resolveOwners: ResolveOwner[]) {
  return await jsonApi.post<OwnerT[]>(`/owners/resolve`, resolveOwners);
}

export async function get(ownerId: OwnerId) {
  return await jsonApi.get<OwnerT>(`/owners/${ownerId}`);
}
