import {Checkbox, Link, Text} from "@chakra-ui/react";

const AgreeToTerms = ({
  agreedToTerms,
  setAgreedToTerms,
  isDisabled,
}: {
  agreedToTerms: boolean;
  setAgreedToTerms: (value: boolean) => void;
  isDisabled?: boolean;
}) => (
  <Checkbox
    alignItems="start"
    my={4}
    isChecked={agreedToTerms}
    onChange={e => setAgreedToTerms(e.target.checked)}
    isDisabled={isDisabled}
  >
    <Text mt="-2px">
      I have read and understand Platformed's{" "}
      <Link href="https://platformed.com/terms" fontWeight="600" target="_blank">
        Terms of Service
      </Link>{" "}
      and{" "}
      <Link fontWeight="600" href="https://platformed.com/privacy" target="_blank">
        Privacy Policy
      </Link>{" "}
      regarding the use of data shared with Platformed.
    </Text>
  </Checkbox>
);

export default AgreeToTerms;
