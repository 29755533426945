import {Box, Flex, Image} from "@chakra-ui/react";
import {Outlet} from "react-router-dom";
import LogoTextSVG from "../../assets/logo/logo-text.svg";

const AuthFrame = () => {
  return (
    <Flex>
      <Flex
        flex="2"
        minH="100vh"
        bg="white"
        alignItems="center"
        borderLeft="1px solid blue.700"
        maxW="1000px"
        px={[8, 16, 32]}
        py={[8, 16]}
      >
        <Box w="full" data-testid="left-styled-frame">
          <Image h="6" w="auto" mb="8" flexShrink="0" src={LogoTextSVG} alt="Platformed" />
          <Outlet />
        </Box>
      </Flex>
      <Box flex={[0, 0, 0, 1, 2]} minH="100vh" bgGradient="linear(135deg, teal.400, cyan.600, blue.600, purple.700)" />
    </Flex>
  );
};

export default AuthFrame;
