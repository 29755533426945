import {Route} from "react-router-dom";
import assetRoutes from "./Asset.tsx";
import AssetsPage from "./AssetsPage.tsx";
import {FileRejection} from "react-dropzone";
import {ReactNode} from "react";
import {Link} from "@chakra-ui/react";

export const SUPPORTED_ASSET_FORMATS = {
  "image/jpeg": [".jpg", ".jpeg"],
  "image/png": [".png"],
  "image/svg+xml": [".svg"],
  "font/woff": [".woff"],
  "font/woff2": [".woff2"],
};

export const INVALID_FONT_EXTENSIONS = [".otf", ".ttf", ".eot"];

export function onDropRejected(files: FileRejection[]): ReactNode {
  if (files.some(({file}) => INVALID_FONT_EXTENSIONS.some(ext => file.name.toLowerCase().endsWith(ext)))) {
    return (
      <Link href="https://fonts.google.com/knowledge/glossary/web_font" fontSize="sm" color="blue.500" target="_blank">
        Web fonts should be uploaded in WOFF or WOFF2 format.
      </Link>
    );
  }
  return null;
}

export default (
  <Route id="Assets" path="assets" handle={{crumb: "Assets"}} Component={AssetsPage}>
    {assetRoutes}
  </Route>
);
