import {
  Alert,
  AlertIcon,
  Box,
  Flex,
  Hide,
  HStack,
  Icon,
  Img,
  Link,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  Show,
  Stack,
  Text,
  useStyleConfig,
} from "@chakra-ui/react";
import {UserIcon} from "@heroicons/react/24/outline";
import ContentWrapper from "../components/ContentWrapper";

import {AuthButton} from "./Auth";
import NarrowNav from "./NarrowNav";
import {useTrustCenterConfig} from "../config";
import {useTrustCenterContext} from "../hooks/trustCenterContext";
import PortalMenuList from "../../components/PortalMenuList";
import {getFrontendEnvironment} from "../../utils/environment";
import {useClientAccount} from "../hooks/clientAccount";
import SubscribeButton from "./SubscribeButton.tsx";

const NotLiveBanner = () => {
  const account = useClientAccount();
  const env = getFrontendEnvironment();

  const configPageUrl = `${env.app_url}/trust-center/config?account_id=${account.account_id}`;

  return (
    <Alert status="warning" justifyContent="center">
      <AlertIcon />
      <Box>
        <Text as="span" fontWeight="600">
          Your trust center is not publicly accessible.{" "}
        </Text>
        <Text as="span">
          Go live from your{" "}
          <Link href={configPageUrl} color="blue.500">
            trust center configuration
          </Link>
          .
        </Text>
      </Box>
    </Alert>
  );
};

const LoggedIn = ({logout}: {logout: () => void}) => (
  <Menu>
    <MenuButton
      rounded="full"
      border="1px solid"
      borderColor="transparent"
      color="blue.600"
      _hover={{color: "blue.600", bg: "blue.50", borderColor: "blue.100"}}
      _active={{color: "blue.800", bg: "blue.100", outline: "none", borderColor: "blue.200"}}
    >
      <Flex h={8} w={8} align="center" justify="center">
        <Icon as={UserIcon} fontSize="3xl" />
      </Flex>
    </MenuButton>
    <PortalMenuList fontSize="md">
      <MenuGroup>
        <MenuItem fontSize="md" cursor="auto" _focus={{bg: "transparent"}} _hover={{bg: "transparent"}}>
          <Stack spacing={1}>
            <Text fontWeight="medium">henry.irish@platformed.com</Text>
            <Text
              fontSize="sm"
              fontWeight="medium"
              textOverflow="ellipsis"
              maxW="250px"
              whiteSpace="nowrap"
              overflow="hidden"
              color="gray.500"
            >
              Platformed
            </Text>
          </Stack>
        </MenuItem>
      </MenuGroup>
      <MenuDivider />
      <MenuItem onClick={logout}>Sign out</MenuItem>
    </PortalMenuList>
  </Menu>
);

const WideNav = () => {
  const {loggedIn, logout} = useTrustCenterContext();
  return (
    <HStack gap={4}>
      {loggedIn ? (
        <LoggedIn logout={logout} />
      ) : (
        <>
          <AuthButton buttonType="Login">Login</AuthButton>
          <AuthButton buttonType="Register" colorScheme="green">
            Register
          </AuthButton>
        </>
      )}
    </HStack>
  );
};

const TopNav = ({onClickSearch}: {onClickSearch: () => void}) => {
  const {logo_url, homepage_url, is_public, subscribe, privacy_policy, name} = useTrustCenterConfig();
  const styles = useStyleConfig("TopBar");

  return (
    <>
      {!is_public && <NotLiveBanner />}
      <Box __css={styles} w="full">
        <ContentWrapper h="full" py={4}>
          <HStack h="full" align="stretch" justify="space-between">
            {homepage_url ? (
              <Link href={homepage_url} target="_blank" h="full">
                <Img src={logo_url} h="full" />
              </Link>
            ) : (
              <Img src={logo_url} h="full" />
            )}
            <Flex align="center">
              {
                <>
                  {false && (
                    <Show above="md">
                      <WideNav />
                    </Show>
                  )}
                  <Hide above="md">
                    <NarrowNav onClickSearch={onClickSearch} />
                  </Hide>
                </>
              }
            </Flex>
            {subscribe && subscribe.enabled && <SubscribeButton privacyPolicy={privacy_policy} displayName={name} />}
          </HStack>
        </ContentWrapper>
      </Box>
    </>
  );
};

export default TopNav;
