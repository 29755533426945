import Users from "../../../Account/Settings/Users";
import SelfEnrollment from "../../../Account/Settings/SelfEnrollment";
import {RouteTab, tabbedRoute} from "../../../components/RouterTabs";
import Roles from "../../../Account/Settings/Roles";
import Teams from "../../../Account/Settings/Teams";

const tabs: RouteTab[] = [Users, Roles, SelfEnrollment, Teams];

const routes = tabbedRoute({path: "user-management", title: "User management", tabs});

export default routes;
