import {Route} from "react-router-dom";
import SubprocessorsPage from "./SubprocessorsPage";

export default (
  <Route
    id="Subprocessors"
    path="subprocessors"
    handle={{crumb: "Subprocessors"}}
    Component={SubprocessorsPage}
  ></Route>
);
