import {DependencyList, useState} from "react";
import {useModalState} from "./modalState";
import {usePromiseState} from "./promiseState";
import {HTTPError} from "../api";

type ValidationState<S extends object> = {
  [Key in keyof S]?: {message: string}[];
};

// Returns the current validation state (map of field names to list of
// validation errors) and a setter function to set the validation errors.
// The validation error(s) for a field will be automatically cleared
// when the value for that field (in the `values` parameter) changes.
export function useValidationState<const S extends object>(values: S) {
  const [prevValues, setPrevValues] = useState(values);
  const result = useState<ValidationState<S>>({});
  let valuesChanged = false;
  const newValidationState = {...result[0]};
  for (const k of Object.keys(values) as (keyof S)[]) {
    if (values[k] !== prevValues[k]) {
      valuesChanged = true;
      delete newValidationState[k];
    }
  }

  if (valuesChanged) {
    result[1](newValidationState);
    setPrevValues(values);
  }

  return result;
}

// Like `useValidationState`, but also clears the validation state when the modal
// is opened or closed, similarly to `useModalState`.
export function useModalValidationState<const S extends object>(isOpen: boolean, values: S) {
  const [prevValues, setPrevValues] = useModalState(isOpen, values);
  const result = useModalState<ValidationState<S>>(isOpen, {});
  let valuesChanged = false;
  const newValidationState = {...result[0]};
  for (const k of Object.keys(values) as (keyof S)[]) {
    if (values[k] !== prevValues[k]) {
      valuesChanged = true;
      delete newValidationState[k];
    }
  }

  if (valuesChanged) {
    result[1](newValidationState);
    setPrevValues(values);
  }

  return result;
}

// Wrapper around `usePromiseState` which automatically catches validation
// errors from the backend and stores them using the provided `setValidationErrors`
// state setter. Returns a callback to be used as `onSubmit` handlers.
export function useValidatedPromiseState<R, P extends unknown[]>(
  cb: (...args: P) => Promise<R>,
  inputs: DependencyList,
  setValidationErrors?: (validationErrors: any) => void,
) {
  return usePromiseState<R | undefined, [React.FormEvent<HTMLFormElement>, ...P]>(
    async (e, ...args) => {
      e.stopPropagation();
      e.preventDefault();
      try {
        return await cb(...args);
      } catch (ex) {
        if (ex instanceof HTTPError) {
          switch (ex.response.status) {
            case 409:
              throw new Error(await ex.response.json());
            case 422:
              if (setValidationErrors) {
                setValidationErrors(await ex.response.json());
                return;
              } else {
                throw ex;
              }
          }
        }
        throw ex;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setValidationErrors, ...inputs],
  );
}
