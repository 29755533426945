import BaseFactsPage from "../components/BaseFactsPage";
import {findSectionById, getSectionFullyQualifiedName, useHierarchy} from "../utility/hierarchy";
import {HTTPError} from "../../../../../api";
import {Type} from "../../../../../typing";
import {useTypedParams} from "../../../../../hooks/typedParams";

export type SectionPageProps = {
  /**
   * - If `true`, show all facts in the scope and its descendent sections.
   * - If `false`, only show facts at the top level (uncategorized)
   */
  recurse?: boolean;
};

export const SectionParams = Type.Object({id: Type.LibrarySectionId()});

function SectionPage(props: SectionPageProps) {
  const {id} = useTypedParams(SectionParams);
  const recurse = props.recurse ?? true;

  const hierarchy = useHierarchy();

  const section = findSectionById(hierarchy, id!);

  if (section === undefined) {
    throw new HTTPError(new Response(null, {status: 404}));
  }

  const sectionName = getSectionFullyQualifiedName(section);

  return <BaseFactsPage title={sectionName} libraryContext={{hierarchy, parent: section, recurse}} />;
}

export default SectionPage;
