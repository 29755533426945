import {Route} from "react-router-dom";
import ProfilePage from "../../../Account/Settings/Profile/ProfilePage.tsx";
import Page from "../../../components/Page.tsx";
import {Flex, Heading} from "@chakra-ui/react";
import FeaturesPage from "../../../Account/Settings/Features/FeaturesPage.tsx";
import {Internal} from "../../../components/InternalMode.tsx";

export default (
  <Route
    path="organisation-profile"
    element={
      <Page title="Organisation profile" display="flex" flexDirection="column">
        <Flex p="8" pb="6" justifyContent="space-between">
          <Heading size="md" p="0">
            Organisation profile
          </Heading>
        </Flex>
        <Flex px={4} direction="column" gap={4}>
          <ProfilePage />
          <Internal>
            <FeaturesPage />
          </Internal>
        </Flex>
      </Page>
    }
    handle={{crumb: "Organisation profile"}}
  />
);
