import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  FormControl,
  FormLabel,
  GridItem,
  SimpleGrid,
  Switch,
} from "@chakra-ui/react";
import {useKnockFeed} from "@knocklabs/react";
import {ChannelType} from "@knocklabs/types";
import React from "react";
import {usePromiseState} from "../../../hooks/promiseState.ts";
import {invalidateQueries, useQueriesData, useQueryData} from "../../../state";
import {byRef} from "../../../state/byRef.ts";
import {withSuspense} from "../../../state/withSuspense.tsx";
import {withErrorBoundary} from "react-error-boundary";
import LinkedChannels from "./LinkedChannels.tsx";

const workflowLabels = [
  ["questionnaire-assigned", "Questionnaire assigned to you"],
  ["question-assigned", "Question assigned to you"],
  ["questionnaire-nudge", "You were nudged about a questionnaire"],
  ["question-nudge", "You were nudged about a question"],
];

const channelTypeLabels: readonly [ChannelType, string][] = [
  ["in_app_feed", "In-app feed"],
  ["email", "Email"],
  ["chat", "Connected apps"],
];

const Notifications = withErrorBoundary(
  withSuspense(() => {
    const {knock} = useKnockFeed();
    const preferences = useQueryData({queryKey: ["knock", "preferences", byRef(knock)]});
    const [updatingPreference, updatePreference] = usePromiseState(
      async (workflowKey: string, channelType: ChannelType, enabled: boolean) => {
        await knock.preferences.setWorkflow(workflowKey, {
          channel_types: {
            [channelType]: enabled,
          },
        });
        await invalidateQueries([{queryKey: ["knock", "preferences"]}]);
      },
      [knock],
    );
    const getPreference = (workflowKey: string, channelType: ChannelType) => {
      if (preferences.workflows !== null) {
        const workflowPreferences = preferences.workflows[workflowKey];
        if (typeof workflowPreferences === "boolean") {
          return workflowPreferences;
        }
        if (workflowPreferences !== undefined) {
          const channelPreference = workflowPreferences.channel_types[channelType];
          if (channelPreference !== undefined) {
            return channelPreference;
          }
        }
      }
      if (preferences.channel_types !== null) {
        return preferences.channel_types[channelType] ?? true;
      }
      return true;
    };

    const [availableChannels, linkedChannels] = useQueriesData({
      queries: [{queryKey: ["availableNotificationChannels"]}, {queryKey: ["linkedNotificationChannels"]}],
    });

    return (
      <Box py="6" px="4" pb="8">
        <SimpleGrid templateColumns="1fr" autoColumns="auto" my="6" spacing="6">
          <GridItem as={FormControl}>
            <FormLabel fontWeight="bold">Event</FormLabel>
          </GridItem>
          {channelTypeLabels.map(([channelType, channelLabel], col) => (
            <GridItem key={channelType} as={FormControl} gridColumn={col + 2}>
              <FormLabel fontWeight="bold">{channelLabel}</FormLabel>
            </GridItem>
          ))}
          {workflowLabels.map(([workflowKey, workflowLabel]) => (
            <React.Fragment key={workflowKey}>
              <GridItem as={FormControl}>
                <FormLabel>{workflowLabel}</FormLabel>
              </GridItem>
              {channelTypeLabels.map(([channelType]) => (
                <GridItem key={channelType} as={FormControl} textAlign="center">
                  <Switch
                    isChecked={getPreference(workflowKey, channelType)}
                    onChange={e => updatePreference(workflowKey, channelType, e.target.checked)}
                    isDisabled={updatingPreference.inProgress}
                  />
                </GridItem>
              ))}
            </React.Fragment>
          ))}
        </SimpleGrid>
        <LinkedChannels available={availableChannels} linked={linkedChannels} />
      </Box>
    );
  }),
  {
    fallbackRender: ({error}) => (
      <Alert status="error" mt="12">
        <AlertIcon boxSize="40px" />
        <Box>
          <AlertTitle fontSize="md">Failed to load preferences</AlertTitle>
          <AlertDescription fontSize="md">{error?.toString()}</AlertDescription>
        </Box>
      </Alert>
    ),
  },
);

export default Notifications;
