import {useQueryData} from "../../../../state";
import {Type} from "../../../../typing";
import {withTypedProps} from "../../../../typing/typedProps";

export const QuestionParams = Type.Object({questionnaireId: Type.QuestionnaireId(), questionId: Type.QuestionId()});

const Crumb = withTypedProps(QuestionParams, ({questionId}) => {
  return useQueryData({queryKey: ["vendorToolkit", "question", questionId]}).text;
});

export default Crumb;
