import {Counterparty} from "../../../../Types.ts";
import api, {HTTPError} from "../../../../api/index.ts";
import {
  Avatar,
  Box,
  Flex,
  HStack,
  Heading,
  InputLeftAddon,
  Link,
  Stack,
  StackDivider,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import {ActionBar} from "../../../../components/ActionBar.tsx";
import groupBy from "../../../../utils/groupBy.tsx";
import NewCounterparty from "./NewCounterparty.tsx";
import {useCallback} from "react";
import CommitInput from "../../../../components/CommitInput.tsx";
import {useQueryData} from "../../../../state/index.ts";
import {withSuspense} from "../../../../state/withSuspense.tsx";
import {CrmButton} from "../../components/CrmButton.tsx";
import Page from "../../../../components/Page.tsx";

const ReadOnlyTooltip = ({readOnly, children}: {readOnly: boolean; children: React.ReactNode}) => {
  return readOnly ? <Tooltip label="This counterparty is from a linked CRM">{children}</Tooltip> : <>{children}</>;
};

const CounterpartyRow = ({counterparty: {counterparty_id, name, url, uri}}: {counterparty: Counterparty}) => {
  const readOnly = !!uri;
  const updateName = useCallback(
    async (newName: string) => {
      try {
        await api.vendorToolkit.counterparties.updateName(counterparty_id, newName);
      } catch (ex) {
        if (ex instanceof HTTPError) {
          switch (ex.response.status) {
            case 422:
              throw new Error("Name must be between 1 and 256 characters");
            case 409:
              throw new Error(await ex.response.json());
          }
        }
        throw ex;
      }
    },
    [counterparty_id],
  );
  const updateUrl = useCallback(
    async (newUrl: string) => {
      await api.vendorToolkit.counterparties.updateUrl(counterparty_id, newUrl);
    },
    [counterparty_id],
  );
  return (
    <HStack key={counterparty_id} align="center" px="6" py="5" fontSize="md" spacing={8}>
      <Avatar name={name} size="sm" src={`https://logo.clearbit.com/${url}`} />
      <Box flex={3}>
        <ReadOnlyTooltip readOnly={readOnly}>
          <CommitInput value={name} onCommit={updateName} isDisabled={readOnly} />
        </ReadOnlyTooltip>
      </Box>
      <Box flex={3} color="gray.700">
        <ReadOnlyTooltip readOnly={readOnly}>
          <CommitInput
            value={url}
            onCommit={updateUrl}
            leftAddon={<InputLeftAddon>https://</InputLeftAddon>}
            isDisabled={readOnly}
          />
        </ReadOnlyTooltip>
      </Box>
      <Box width="144px">{uri ? <CrmButton uri={uri} /> : null}</Box>
    </HStack>
  );
};

const CounterpartyList = ({counterparties}: {counterparties: Counterparty[]}) => {
  const groupedCounterparties = groupBy(counterparties, c => c.name.toUpperCase()[0]).sort((a, b) =>
    a.key.localeCompare(b.key),
  );
  return (
    <Box as="nav" minH="0" flex="1">
      {groupedCounterparties.map(group => (
        <Box key={group.key} position="relative">
          <Heading
            size="sm"
            borderTop="1px solid"
            borderBottom="1px solid"
            borderColor="gray.200"
            px="6"
            py="1"
            color="gray.500"
            bg="gray.50"
          >
            {group.key}
          </Heading>
          <Stack divider={<StackDivider borderColor="gray.200" />} spacing={0}>
            {group.items.map(counterparty => (
              <CounterpartyRow key={counterparty.counterparty_id} counterparty={counterparty} />
            ))}
          </Stack>
        </Box>
      ))}
    </Box>
  );
};

const CounterpartiesPage = withSuspense(() => {
  const counterparties = useQueryData({queryKey: ["vendorToolkit", "counterparties"]});

  return (
    <Page title="Counterparties" display="flex" flexDirection="column">
      <Flex p="8" pb="6" justifyContent="space-between">
        <Heading size="md" p="0">
          Counterparties
        </Heading>
      </Flex>
      <ActionBar
        actionButtons={
          <Text fontSize="md" color="gray.500" fontStyle="italic" maxWidth="300px" textAlign="right">
            Counterparties can be removed by sending a ticket to{" "}
            <Link href="mailto:support@platformed.com" color="blue.500">
              support@platformed.com
            </Link>
          </Text>
        }
      >
        <NewCounterparty />
      </ActionBar>
      <CounterpartyList counterparties={counterparties} />
    </Page>
  );
});

export default CounterpartiesPage;
