import {QuestionSourceConnectionProps} from "./AnyQuestionSource.tsx";
import {GenericOauthOptions, useGenericOauth} from "../../../../../hooks/genericOauth.ts";
import {ExternalAuthorizationProvider, QuestionSourceConfig} from "../../../../../Types.ts";
import {useCallback} from "react";
import {Box, Button, Flex, Img, Text} from "@chakra-ui/react";
import {getPublicCredentials} from "../../../../../utils/environment.ts";

import Logo from "../../../../../../assets/slack.svg";

const SLACK_OAUTH_OPTIONS: GenericOauthOptions = {
  authUrl: "https://slack.com/oauth/v2/authorize",
  clientId: getPublicCredentials().slack_client_id,
  scope: "app_mentions:read,chat:write,im:history,im:read,team:read,users:read,users:read.email",
  provider: ExternalAuthorizationProvider.Slack,
  extra: {},
  purpose: "Receive questions from Slack",
};

export const SlackQuestionSource = ({payload, setPayload}: QuestionSourceConnectionProps) => {
  const [requestingAuth, requestAuth] = useGenericOauth();

  const doAuth = useCallback(async () => {
    const auth = await requestAuth(SLACK_OAUTH_OPTIONS);
    if (!auth.ok) {
      return;
    }
    const config: QuestionSourceConfig = {
      type: "Slack",
      content: {},
    };
    setPayload({
      config: config,
      external_authorization_id: auth.result.external_authorization_id,
    });
  }, [requestAuth, setPayload]);

  const config = payload?.config;
  if (config && config.type !== "Slack") {
    return null;
  }

  return (
    <Box w="full">
      <Flex w="full">
        {config ? (
          <Flex
            flex={1}
            display="inline-flex"
            align="center"
            border="1px solid"
            borderRight="none"
            borderColor="gray.200"
            borderLeftRadius="md"
            px={4}
            gap={2}
          >
            <Img src={Logo} height="24px" aspectRatio={1} />
            <Text fontSize="md" fontWeight={500}>
              Connected
            </Text>
          </Flex>
        ) : (
          <Flex
            flex={1}
            display="inline-flex"
            align="center"
            border="1px solid"
            borderRight="none"
            borderColor="gray.200"
            borderLeftRadius="md"
            px={4}
            gap={2}
            fontSize="md"
            fontWeight={500}
            color="gray.500"
          >
            No account selected
          </Flex>
        )}
        <Button
          isLoading={requestingAuth.inProgress}
          isDisabled={requestingAuth.inProgress}
          onClick={doAuth}
          borderLeftRadius="none"
          colorScheme="blue"
        >
          Select Slack account...
        </Button>
      </Flex>
    </Box>
  );
};
