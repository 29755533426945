import {CodeBracketSquareIcon} from "@heroicons/react/20/solid";
import TemplatePage from "./TemplatePage";
import {RouteTab} from "../../../../../components/RouterTabs";

export default {
  title: "Template",
  path: "template",
  icon: CodeBracketSquareIcon,
  element: <TemplatePage />,
} satisfies RouteTab;
