import {Button, ButtonProps, Icon, Tooltip, useToast} from "@chakra-ui/react";
import {ArrowUturnUpIcon} from "@heroicons/react/20/solid";
import {LayerType, QuestionId, QuestionnaireId} from "../../../../Types";
import {useLayerType} from "../../../../hooks/layerType";
import api from "../../../../api";
import {usePromiseState} from "../../../../hooks/promiseState";
import {unreachableCase} from "../../../../utils/typescript";
import {LAYER_SELECTOR_MAP} from "../../components/LayerSelector";

function getPrevLayerType(layerType: LayerType): LayerType | undefined {
  switch (layerType) {
    case LayerType.AI:
      return LayerType.Internal;
    case LayerType.Internal:
      return LayerType.External;
    case LayerType.External:
      return undefined;
    default:
      unreachableCase(layerType);
  }
}

type Props = {
  buttonProps: ButtonProps;
} & ({target: "Questionnaire"; questionnaireId: QuestionnaireId} | {target: "Question"; questionId: QuestionId});

function CopyBackButton(props: Props) {
  const [layerType] = useLayerType();
  const targetLayer = props.target === "Questionnaire" ? LayerType.External : getPrevLayerType(layerType);
  const {color, text} = LAYER_SELECTOR_MAP[targetLayer ?? LayerType.External];

  const toast = useToast();

  const [copying, copy] = usePromiseState(async () => {
    await (props.target === "Question"
      ? api.vendorToolkit.questions.copyBack(props.questionId, layerType)
      : api.vendorToolkit.questionnaires.copyBack(props.questionnaireId));

    toast({description: `${props.target} copied back successfully.`, status: "success"});
  }, [props, layerType, toast]);

  const enabled =
    (props.target === "Question" && targetLayer !== undefined) ||
    (props.target === "Questionnaire" && layerType === LayerType.Internal);

  const label = `Copy to ${text}`;

  const tooltipLabel = (() => {
    if (enabled) {
      switch (targetLayer) {
        case LayerType.Internal:
          return "Copy the AI answers to the Internal layer.";
        case LayerType.External:
          return "Return the Internal answers to the customer.";
      }
    }

    return props.target === "Questionnaire"
      ? "Must be on the Internal layer."
      : "You are already on the external layer.";
  })();

  return (
    <Tooltip label={tooltipLabel}>
      <Button
        leftIcon={<Icon as={ArrowUturnUpIcon} />}
        colorScheme={color}
        onClick={copy}
        isDisabled={!enabled}
        isLoading={copying.inProgress}
        {...props.buttonProps}
      >
        {label}
      </Button>
    </Tooltip>
  );
}

export default CopyBackButton;
