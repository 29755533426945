import {
  Button,
  Text,
  Box,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Stack,
  Select,
  InputGroup,
  InputRightElement,
  Spinner,
  Img,
  FormControl,
  FormLabel,
  Heading,
  SimpleGrid,
  HStack,
} from "@chakra-ui/react";
import {
  ExternalAuthorizationProvider,
  ConfluenceDocumentSourceConfig,
  ExternalAuthorization,
  DocumentCategory,
  ConfluenceLabel,
} from "../../../../../../Types";
import {ChangeEvent, Fragment, useCallback, useState} from "react";
import {DocumentSourceProps} from "./AnyDocumentSource";
import AgreeToTerms from "../components/AgreeToTerms";
import {GenericOauthOptions, useGenericOauth} from "../../../../../../hooks/genericOauth";
import {getPublicCredentials} from "../../../../../../utils/environment";
import {useQueries} from "@tanstack/react-query";
import {HTTPError} from "../../../../../../api";
import {SelectedFolder} from "../components/SelectedFolder";

const CONFLUENCE_OAUTH_OPTIONS: GenericOauthOptions = {
  authUrl: "https://auth.atlassian.com/authorize",
  clientId: getPublicCredentials().atlassian_client_id,
  scope:
    "offline_access read:confluence-content.all read:confluence-space.summary search:confluence readonly:content.attachment:confluence read:space:confluence read:attachment:confluence read:content-details:confluence read:confluence-content.summary read:confluence-content.permission read:content:confluence read:page:confluence read:space-details:confluence read:custom-content:confluence read:label:confluence read:content.property:confluence read:content.metadata:confluence",
  extra: {
    audience: "api.atlassian.com",
    prompt: "consent",
  },
  provider: ExternalAuthorizationProvider.Atlassian,
  purpose: "Sync documents from Confluence",
};

type AtlassianSite = {
  id: string;
  name: string;
  url: string;
  scopes: string[];
  avatarUrl: string;
};

enum ConfluenceSpaceType {
  Personal = "personal",
  Global = "global",
}

enum ConfluenceSpaceStatus {
  Current = "current",
  Archived = "archived",
}

type ConfluenceSpaceFull = {
  authorId: string;
  homepageId: string;
  icon: {path: string} | null;
  name: string;
  key: string;
  id: string;
  type: ConfluenceSpaceType;
  description: null;
  status: ConfluenceSpaceStatus;
  createdAt: string;
};

type ConfluenceMultiEntityResult<T> = {
  results: T[];
  _links: {
    next?: string;
  };
};

async function jsonFetch<T>(input: RequestInfo | URL, init?: RequestInit & {bearer?: string}): Promise<T> {
  const headers: HeadersInit = {
    Accept: "application/json",
  };
  if (init?.bearer) {
    headers["Authorization"] = `Bearer ${init.bearer}`;
  }
  const resp = await fetch(
    new Request(input, {
      headers,
    }),
    init,
  );
  if (!resp.ok) {
    throw new HTTPError(resp);
  }
  return await resp.json();
}

async function jsonFetchPaginated<T>(
  urlPrefix: string,
  urlPath: string,
  init?: RequestInit & {bearer?: string},
): Promise<T[]> {
  const result = [];
  let nextPath: string | undefined = urlPath;
  do {
    const url: string = `${urlPrefix}${nextPath}`;
    const page = await jsonFetch<ConfluenceMultiEntityResult<T>>(url, init);
    result.push(...page.results);
    nextPath = page._links.next;
  } while (nextPath);
  return result;
}

const DOCUMENT_CATEGORIES = [DocumentCategory.Policy, DocumentCategory.Certification, DocumentCategory.Other];

function accessToken(auth: ExternalAuthorization) {
  if (auth.payload.type !== "Oauth") {
    throw new Error("Must use Oauth");
  }
  return auth.payload.content.access_token;
}

const ConfluencePicker = ({
  config,
  setConfig,
  auth,
}: {
  config: ConfluenceDocumentSourceConfig | null;
  setConfig: (config: ConfluenceDocumentSourceConfig) => void;
  auth: ExternalAuthorization;
}) => {
  const [sites, spaces, labels] = useQueries({
    queries: [
      // List all the accessible confluence sites
      {
        queryKey: ["atlassian", "sites", auth.external_authorization_id],
        queryFn: async () => {
          const sites: AtlassianSite[] = await jsonFetch("https://api.atlassian.com/oauth/token/accessible-resources", {
            bearer: accessToken(auth),
          });
          return sites.filter(site => site.scopes.find(scope => scope.includes("confluence")));
        },
      },
      // List all the spaces in the site
      {
        enabled: !!config,
        queryKey: ["atlassian", "spaces", auth.external_authorization_id, config?.site.id],
        queryFn: async () => {
          return await jsonFetchPaginated<ConfluenceSpaceFull>(
            `https://api.atlassian.com/ex/confluence/${config!.site.id}`,
            "/wiki/api/v2/spaces",
            {
              bearer: accessToken(auth),
            },
          );
        },
      },
      // List all the labels in the site
      {
        enabled: !!config,
        queryKey: ["atlassian", "labels", auth.external_authorization_id, config?.site.id],
        queryFn: async () => {
          return (
            await jsonFetchPaginated<ConfluenceLabel>(
              `https://api.atlassian.com/ex/confluence/${config!.site.id}`,
              "/wiki/api/v2/labels?prefix=global",
              {
                bearer: accessToken(auth),
              },
            )
          ).sort((a, b) => a.name.localeCompare(b.name));
        },
      },
    ],
  });

  const changeSite = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => {
      if (!sites.data) {
        return;
      }
      const site = sites.data.find(site => site.id === e.target.value);
      if (!site) {
        return;
      }
      setConfig({
        site: {id: site.id, name: site.name, url: site.url, avatar_url: site.avatarUrl},
        labels: {},
      });
    },
    [sites.data, setConfig],
  );

  const changeSpace = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => {
      if (!spaces.data || !config) {
        return;
      }
      const space = spaces.data.find(space => space.id === e.target.value);
      setConfig({
        ...config,
        space: space && {id: space.id, name: space.name},
      });
    },
    [spaces.data, config, setConfig],
  );

  const changeLabel = useCallback(
    (category: DocumentCategory, e: ChangeEvent<HTMLSelectElement>) => {
      if (!labels.data || !config) {
        return;
      }
      const label = labels.data.find(label => label.id === e.target.value);
      setConfig({
        ...config,
        labels: {
          ...config.labels,
          [category]: label,
        },
      });
    },
    [labels.data, config, setConfig],
  );

  const usedLabelIds = new Map<string, DocumentCategory>(
    DOCUMENT_CATEGORIES.flatMap(category => {
      const label = config?.labels[category];
      return label ? [[label.id, category]] : [];
    }),
  );

  return (
    <Stack spacing={4} mt="4">
      <InputGroup>
        <Img src={config?.site.avatar_url} aspectRatio="1" height="40px" mr={2} />
        <Select isDisabled={!sites.data} value={config?.site.id} onChange={changeSite}>
          <option disabled selected value={undefined}>
            {!sites.data || sites.data.length > 0 ? "Select a site" : "No sites available"}
          </option>
          {sites.data?.map(site => (
            <option key={site.id} value={site.id}>
              {site.name}
            </option>
          ))}
        </Select>
        {sites.isLoading && (
          <InputRightElement mr={6}>
            <Spinner color="gray.500" size="sm" />
          </InputRightElement>
        )}
      </InputGroup>
      <InputGroup>
        <Select isDisabled={!spaces.data} value={config?.space?.id} onChange={changeSpace}>
          <option selected value={undefined}>
            All spaces
          </option>
          {spaces.data?.map(space => (
            <option key={space.id} value={space.id}>
              {space.name}
            </option>
          ))}
        </Select>
        {spaces.isLoading && (
          <InputRightElement mr={6}>
            <Spinner color="gray.500" size="sm" />
          </InputRightElement>
        )}
      </InputGroup>
      {DOCUMENT_CATEGORIES.map(category => (
        <FormControl key={category}>
          <FormLabel>{category} document label</FormLabel>
          <Select
            isDisabled={!labels.data}
            value={config?.labels[category]?.id}
            onChange={e => changeLabel(category, e)}
          >
            <option selected value={undefined}>
              None
            </option>
            {labels.data
              ?.filter(label => {
                const cat = usedLabelIds.get(label.id);
                return cat == null || cat === category;
              })
              .map(label => (
                <option key={label.id} value={label.id}>
                  {label.name}
                </option>
              ))}
          </Select>
        </FormControl>
      ))}
    </Stack>
  );
};

const ConfluenceDocumentSource = ({payload, setPayload}: DocumentSourceProps) => {
  const [agreedToTerms, setAgreedToTerms] = useState(!!payload);
  const {isOpen, onOpen, onClose} = useDisclosure();
  const [newConfig, setNewConfig] = useState<ConfluenceDocumentSourceConfig | null>(
    payload?.config.type === "Confluence" ? payload.config.content : null,
  );
  const [newExternalAuth, setNewExternalAuth] = useState<ExternalAuthorization | null>(null);

  const [requestingAuth, requestAuth] = useGenericOauth();

  const pickConfluenceSite = useCallback(async () => {
    if (newExternalAuth == null) {
      const auth = await requestAuth(CONFLUENCE_OAUTH_OPTIONS);
      if (!auth.ok) {
        return;
      }
      setNewExternalAuth(auth.result);
    }

    onOpen();
  }, [newExternalAuth, onOpen, requestAuth]);

  const config = payload?.config;
  if (config && config.type !== "Confluence") {
    return null;
  }

  return (
    <Box w="full">
      <AgreeToTerms agreedToTerms={agreedToTerms} setAgreedToTerms={setAgreedToTerms} isDisabled={!!config} />
      <SelectedFolder
        folder={config ? {name: config.content.site.name, iconUrl: config.content.site.avatar_url} : null}
        placeholder="No site configured"
        onClick={pickConfluenceSite}
        colorScheme="blue"
        isDisabled={!agreedToTerms}
      >
        Configure Confluence site...
      </SelectedFolder>
      {config && (
        <Box>
          <Heading size="sm" mt={8} mb={2}>
            Details
          </Heading>
          <SimpleGrid columns={2}>
            <Text>Site:</Text>
            <Text whiteSpace="nowrap">{config.content.site.url}</Text>
            <Text>Space:</Text>
            <Text>{config.content.space?.name ?? "All spaces"}</Text>
            {DOCUMENT_CATEGORIES.map(category => (
              <Fragment key={category}>
                <Text whiteSpace="nowrap">{category} document label:</Text>
                <Text>{config.content.labels[category]?.name ?? "None"}</Text>
              </Fragment>
            ))}
          </SimpleGrid>
        </Box>
      )}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Configure Confluence site</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {requestingAuth.lastResult && (
              <ConfluencePicker auth={requestingAuth.lastResult} config={newConfig} setConfig={setNewConfig} />
            )}
          </ModalBody>
          <ModalFooter>
            <HStack spacing="3">
              <Button variant="outline" onClick={onClose}>
                Cancel
              </Button>
              <Button
                colorScheme="blue"
                isDisabled={!newExternalAuth || !newConfig || Object.values(newConfig.labels).every(v => v == null)}
                onClick={() => {
                  setPayload({
                    config: {type: "Confluence", content: newConfig!},
                    external_authorization_id: newExternalAuth!.external_authorization_id,
                  });
                  onClose();
                }}
              >
                Configure
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default ConfluenceDocumentSource;
