import {useCallback} from "react";
import {GraphOperationsBuilder} from "./operationsBuilder";
import api from "../../../api";
import {GraphEntity, KnownEntityPropertyOfType} from "../../../Types";

export function useGraphStringProperty(
  entity: GraphEntity,
  propertyName: KnownEntityPropertyOfType<"String">,
): [string | undefined, (value: string | undefined) => Promise<void>] {
  const update = useCallback(
    async (value: string | undefined) => {
      const operations = new GraphOperationsBuilder();

      if (value === undefined || value.trim().length === 0) {
        operations.deleteProperty(entity.id, propertyName);
      } else {
        operations.upsertStringProperty(entity.id, propertyName, value);
      }

      await api.vendorToolkit.graph.update(operations.build());
    },
    [entity.id, propertyName],
  );

  const property = entity.properties[propertyName] as string | undefined;

  return [property, update];
}

export function useGraphIntegerProperty(
  entity: GraphEntity,
  propertyName: KnownEntityPropertyOfType<"Integer">,
): [number | undefined, (value: number | undefined) => Promise<void>] {
  const update = useCallback(
    async (value: number | undefined) => {
      const operations = new GraphOperationsBuilder();

      if (value === undefined) {
        operations.deleteProperty(entity.id, propertyName);
      } else {
        operations.upsertIntegerProperty(entity.id, propertyName, value);
      }

      await api.vendorToolkit.graph.update(operations.build());
    },
    [entity.id, propertyName],
  );

  const property = entity.properties[propertyName] as number | undefined;

  return [property, update];
}
