import {
  ApiDateTime,
  BackgroundTask,
  CounterpartyId,
  CreateQuestionnaire,
  CreateQuestionnaireDocumentation,
  Documentation,
  DocumentationId,
  FileId,
  ImportQuestions,
  LayerType,
  OwnerId,
  Questionnaire,
  QuestionnaireId,
  QuestionnaireMin,
  ScopeMatrix,
  SectionId,
  TrustCenterAccess,
  DealId,
  QuestionId,
  PromptModifiers,
} from "../../Types";
import {invalidateQueries} from "../../state";
import jsonApi, {addAccountId} from "../jsonApi";

export async function get(questionnaireId: QuestionnaireId) {
  return await jsonApi.get<Questionnaire>(`/vendor_toolkit/questionnaires/${questionnaireId}`);
}

export async function list() {
  return await jsonApi.get<QuestionnaireMin[]>("/vendor_toolkit/questionnaires");
}

export async function listHistorical() {
  return await jsonApi.get<QuestionnaireMin[]>("/vendor_toolkit/historical_questionnaires");
}

export async function create(questionnaire: CreateQuestionnaire) {
  const res = await jsonApi.post<Questionnaire>("/vendor_toolkit/questionnaires", questionnaire);
  await invalidateQueries([
    {queryKey: ["vendorToolkit", "questionnaires"]},
    {queryKey: ["vendorToolkit", "tasks"]},
    {queryKey: ["vendorToolkit", "gettingStartedChecklist"]},
  ]);
  return res;
}

export async function assign(questionnaireId: QuestionnaireId, ownerId: OwnerId | null) {
  const res = await jsonApi.post<"ok">(`/vendor_toolkit/questionnaires/${questionnaireId}/assign`, ownerId);
  await invalidateQueries([
    {queryKey: ["vendorToolkit", "questionnaire", questionnaireId]},
    {queryKey: ["vendorToolkit", "questionnaires"]},
  ]);
  return res;
}

export async function nudge(questionnaireId: QuestionnaireId) {
  return await jsonApi.post<"ok">(`/vendor_toolkit/questionnaires/${questionnaireId}/nudge`);
}

export async function updateDueDate(questionnaireId: QuestionnaireId, dueDate: ApiDateTime | null) {
  const res = await jsonApi.post<"ok">(`/vendor_toolkit/questionnaires/${questionnaireId}/due_date`, dueDate);
  await invalidateQueries([
    {queryKey: ["vendorToolkit", "questionnaire", questionnaireId]},
    {queryKey: ["vendorToolkit", "questionnaires"]},
  ]);
  return res;
}

export async function updateQuestionnaireDate(questionnaireId: QuestionnaireId, questionnaireDate: ApiDateTime | null) {
  const res = await jsonApi.post<"ok">(
    `/vendor_toolkit/questionnaires/${questionnaireId}/questionnaire_date`,
    questionnaireDate,
  );
  await invalidateQueries([
    {queryKey: ["vendorToolkit", "questionnaire", questionnaireId]},
    {queryKey: ["vendorToolkit", "questionnaires"]},
  ]);
  return res;
}

export async function updateName(questionnaireId: QuestionnaireId, name: string) {
  const res = await jsonApi.post<"ok">(`/vendor_toolkit/questionnaires/${questionnaireId}/name`, name);
  await invalidateQueries([
    {queryKey: ["vendorToolkit", "questionnaire", questionnaireId]},
    {queryKey: ["vendorToolkit", "questionnaires"]},
  ]);
  return res;
}

export async function updateCounterparty(questionnaireId: QuestionnaireId, counterpartyId: CounterpartyId) {
  const res = await jsonApi.post<"ok">(
    `/vendor_toolkit/questionnaires/${questionnaireId}/counterparty`,
    counterpartyId,
  );
  await invalidateQueries([
    {queryKey: ["vendorToolkit", "questionnaire", questionnaireId]},
    {queryKey: ["vendorToolkit", "questionnaires"]},
  ]);
  return res;
}

export async function updateScopeMatrix(questionnaireId: QuestionnaireId, scopeMatrix: ScopeMatrix) {
  const res = await jsonApi.post<"ok">(`/vendor_toolkit/questionnaires/${questionnaireId}/scope_matrix`, scopeMatrix);
  await invalidateQueries([
    {queryKey: ["vendorToolkit", "questionnaire", questionnaireId]},
    {queryKey: ["vendorToolkit", "questionnaires"]},
  ]);
  return res;
}

export async function updateDeal(questionnaireId: QuestionnaireId, dealId: DealId | null) {
  const res = await jsonApi.post<"ok">(`/vendor_toolkit/questionnaires/${questionnaireId}/deal`, dealId);
  await invalidateQueries([
    {queryKey: ["vendorToolkit", "questionnaire", questionnaireId]},
    {queryKey: ["vendorToolkit", "questionnaires"]},
  ]);
  return res;
}

export async function updateArchived(questionnaireId: QuestionnaireId, archived: boolean) {
  const res = await jsonApi.post<"ok">(`/vendor_toolkit/questionnaires/${questionnaireId}/archived`, archived);
  await invalidateQueries([
    {queryKey: ["vendorToolkit", "questionnaire", questionnaireId]},
    {queryKey: ["vendorToolkit", "questionnaires"]},
    {queryKey: ["vendorToolkit", "gettingStartedChecklist"]},
  ]);
  return res;
}

export async function updatePromptModifiers(questionnaireId: QuestionnaireId, promptModifiers: PromptModifiers) {
  const res = await jsonApi.post<"ok">(
    `/vendor_toolkit/questionnaires/${questionnaireId}/prompt_modifiers`,
    promptModifiers,
  );
  await invalidateQueries([
    {queryKey: ["vendorToolkit", "questionnaire", questionnaireId]},
    {queryKey: ["vendorToolkit", "questionnaires"]},
    {queryKey: ["vendorToolkit", "gettingStartedChecklist"]},
  ]);
  return res;
}

export async function updateTrustCenterVisibility(questionnaireId: QuestionnaireId, visibility: TrustCenterAccess) {
  const res = await jsonApi.post<"ok">(
    `/vendor_toolkit/questionnaires/${questionnaireId}/trust_center_visibility`,
    visibility,
  );
  await invalidateQueries([
    {queryKey: ["vendorToolkit", "questionnaire", questionnaireId]},
    {queryKey: ["vendorToolkit", "questionnaires"]},
  ]);
  return res;
}

export const importQuestions = async (questionnaireId: QuestionnaireId, import_: ImportQuestions) => {
  const res = await jsonApi.post<QuestionId[]>(`/vendor_toolkit/questionnaires/${questionnaireId}/import`, import_);
  await invalidateQueries([
    {queryKey: ["vendorToolkit", "questionnaire", questionnaireId]},
    {queryKey: ["vendorToolkit", "questionnaires"]},
    {queryKey: ["vendorToolkit", "tasks"]},
  ]);
  return res;
};

export const createDocumentation = async (
  questionnaireId: QuestionnaireId,
  documentation: CreateQuestionnaireDocumentation[],
) => {
  const res = await jsonApi.post<DocumentationId[]>(
    `/vendor_toolkit/questionnaires/${questionnaireId}/documentation`,
    documentation,
  );
  await invalidateQueries([
    {queryKey: ["vendorToolkit", "questionDocumentation"]},
    {queryKey: ["vendorToolkit", "question"]},
    {queryKey: ["vendorToolkit", "questionnaireDocumentation", questionnaireId]},
    {queryKey: ["vendorToolkit", "questionnaire", questionnaireId]},
  ]);
  return res;
};

export const listDocumentation = async (questionnaireId: QuestionnaireId, layerType: LayerType) => {
  return await jsonApi.get<Documentation[]>(
    `/vendor_toolkit/questionnaires/${questionnaireId}/questionnaire_layer/${layerType}/documentation`,
  );
};

export const deleteDocumentation = async (
  questionnaireId: QuestionnaireId,
  layer_type: LayerType,
  documentationId: DocumentationId,
) => {
  const res = await jsonApi.delete_<"ok">(
    `/vendor_toolkit/questionnaires/${questionnaireId}/questionnaire_layer/${layer_type}/documentation/${documentationId}`,
  );
  await invalidateQueries([
    {queryKey: ["vendorToolkit", "questionDocumentation"]},
    {queryKey: ["vendorToolkit", "question"]},
    {queryKey: ["vendorToolkit", "questionnaireDocumentation", questionnaireId]},
    {queryKey: ["vendorToolkit", "questionnaire", questionnaireId]},
  ]);
  return res;
};

export const updateDocumentationLinkedToQuestionnaire = async (
  questionnaireId: QuestionnaireId,
  documentationId: DocumentationId,
  linkedToQuestionnaire: boolean,
) => {
  const res = await jsonApi.post<"ok">(
    `/vendor_toolkit/questionnaires/${questionnaireId}/documentation/${documentationId}/linked_to_questionnaire`,
    linkedToQuestionnaire,
  );
  await invalidateQueries([
    {queryKey: ["vendorToolkit", "questionDocumentation"]},
    {queryKey: ["vendorToolkit", "questionnaireDocumentation", questionnaireId]},
  ]);
  return res;
};

export const downloadSupportingDocumentation = async (questionnaireId: QuestionnaireId) => {
  const a = document.createElement("a");
  a.href = addAccountId(`/api/vendor_toolkit/questionnaires/${questionnaireId}/download_documentation`);
  a.click();
};

export const createOriginalFile = async (questionnaireId: QuestionnaireId, fileId: FileId) => {
  const res = await jsonApi.put<"ok">(`/vendor_toolkit/questionnaires/${questionnaireId}/original_files/${fileId}`);
  await invalidateQueries([{queryKey: ["vendorToolkit", "questionnaire", questionnaireId]}]);
  return res;
};

export const deleteOriginalFile = async (questionnaireId: QuestionnaireId, fileId: FileId) => {
  const res = await jsonApi.delete_<"ok">(`/vendor_toolkit/questionnaires/${questionnaireId}/original_files/${fileId}`);
  await invalidateQueries([{queryKey: ["vendorToolkit", "questionnaire", questionnaireId]}]);
  return res;
};

export const updateOriginalFileImportedAt = async (
  questionnaireId: QuestionnaireId,
  fileId: FileId,
  importedAt: ApiDateTime | null,
) => {
  const res = await jsonApi.post<"ok">(
    `/vendor_toolkit/questionnaires/${questionnaireId}/original_files/${fileId}/imported_at`,
    importedAt,
  );
  await invalidateQueries([{queryKey: ["vendorToolkit", "questionnaire", questionnaireId]}]);
  return res;
};

export const createOriginalSource = async (questionnaireId: QuestionnaireId, sourceUrl: string) => {
  const res = await jsonApi.put<"ok">(
    `/vendor_toolkit/questionnaires/${questionnaireId}/original_sources/${encodeURIComponent(sourceUrl)}`,
  );
  await invalidateQueries([{queryKey: ["vendorToolkit", "questionnaire", questionnaireId]}]);
  return res;
};

export const deleteOriginalSource = async (questionnaireId: QuestionnaireId, sourceUrl: string) => {
  const res = await jsonApi.delete_<"ok">(
    `/vendor_toolkit/questionnaires/${questionnaireId}/original_sources/${encodeURIComponent(sourceUrl)}`,
  );
  await invalidateQueries([{queryKey: ["vendorToolkit", "questionnaire", questionnaireId]}]);
  return res;
};

export const updateOriginalSourceImportedAt = async (
  questionnaireId: QuestionnaireId,
  sourceUrl: string,
  importedAt: ApiDateTime | null,
) => {
  const res = await jsonApi.post<"ok">(
    `/vendor_toolkit/questionnaires/${questionnaireId}/original_sources/${encodeURIComponent(sourceUrl)}/imported_at`,
    importedAt,
  );
  await invalidateQueries([{queryKey: ["vendorToolkit", "questionnaire", questionnaireId]}]);
  return res;
};

export async function automaticallyAnswer(questionnaireId: QuestionnaireId) {
  const res = await jsonApi.post<BackgroundTask>(`/vendor_toolkit/questionnaires/${questionnaireId}/auto_complete`);
  await invalidateQueries([{queryKey: ["vendorToolkit", "questionnaires"]}]);
  return res;
}

export async function copyBack(questionnaireId: QuestionnaireId) {
  const res = await jsonApi.post<"ok">(`/vendor_toolkit/questionnaires/${questionnaireId}/copy_back`);
  await invalidateQueries([
    {queryKey: ["vendorToolkit", "questionnaire", questionnaireId]},
    {queryKey: ["vendorToolkit", "questionnaires"]},
  ]);
  return res;
}

export async function deleteQuestionnaire(questionnaireId: QuestionnaireId) {
  const res = await jsonApi.delete_<"ok">(`/vendor_toolkit/questionnaires/${questionnaireId}`);
  await invalidateQueries([
    {queryKey: ["vendorToolkit", "questionnaires"]},
    {queryKey: ["vendorToolkit", "facts"]},
    {queryKey: ["vendorToolkit", "questionUsedFacts"]},
    {queryKey: ["vendorToolkit", "questionGeneratedFacts"]},
  ]);
  return res;
}

export async function updateSectionTitle(questionnaireId: QuestionnaireId, sectionId: SectionId, title: string) {
  const res = await jsonApi.post<"ok">(
    `/vendor_toolkit/questionnaires/${questionnaireId}/sections/${sectionId}/title`,
    title,
  );
  await invalidateQueries([{queryKey: ["vendorToolkit", "questionnaires"]}, {queryKey: ["vendorToolkit", "question"]}]);
  return res;
}
