import {Outlet} from "react-router-dom";
import {Invalidation} from "../Types";
import {RedirectError} from "../api/jsonApi";
import {useSubscription} from "../hooks/pusher";
import {QueryFilters, invalidateQueries, useQueryData} from "../state";
import {redirectToAccountSelector} from "../utils/auth";
import {withSuspense} from "../state/withSuspense";

function invalidationToQueryFilters(invalidation: Invalidation): QueryFilters[] {
  switch (invalidation.type) {
    case "Question":
      return [
        {queryKey: ["vendorToolkit", "question", invalidation.content]},
        {queryKey: ["vendorToolkit", "adhocQuestion", invalidation.content]},
      ];
    case "Questionnaire":
      return [
        {queryKey: ["vendorToolkit", "questionnaire", invalidation.content]},
        {queryKey: ["vendorToolkit", "questionnaires"]},
      ];
  }
}

const RequireAccount = withSuspense(() => {
  const whoami = useQueryData({queryKey: ["whoAmI"]});
  if (!whoami.account) {
    throw new RedirectError(redirectToAccountSelector());
  }

  // Watch for updates to this account
  useSubscription(whoami.account.invalidation_channel_name, null, (eventName, data) => {
    switch (eventName) {
      case "invalidate":
        invalidateQueries(invalidationToQueryFilters(data));
        break;
    }
  });

  return <Outlet />;
});

export default RequireAccount;
