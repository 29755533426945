import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Icon,
  Stack,
  Textarea,
} from "@chakra-ui/react";
import Page from "../../../components/Page.tsx";
import {QuestionStatus, QuestionId} from "../../../Types.ts";
import {useState} from "react";
import {PlusIcon} from "@heroicons/react/20/solid";
import api from "../../../api/index.ts";
import {router} from "../../../router/index.tsx";
import {useValidatedPromiseState, useValidationState} from "../../../hooks/validationState.ts";
import {useQueryData} from "../../../state/index.ts";
import {withSuspense} from "../../../state/withSuspense.tsx";

export const NewQuestionInner = ({redirect}: {redirect: (questionId: QuestionId) => void}) => {
  const whoami = useQueryData({queryKey: ["whoAmI"]});
  const [questionText, setQuestionText] = useState("");
  const [validationErrors, setValidationErrors] = useValidationState({questionText});

  const [creating, create] = useValidatedPromiseState(
    async () => {
      const {
        question: {question_id},
      } = await api.vendorToolkit.adhocQuestions.create({
        question: {
          text: questionText,
          response_layer: {
            status: QuestionStatus.Automating,
          },
        },
        asker_id: whoami.user.user_id,
      });
      redirect(question_id);
    },
    [questionText, whoami.user.user_id, redirect],
    setValidationErrors,
  );

  return (
    <Box as="form" onSubmit={create} p={8}>
      <Stack maxW="96" spacing={8}>
        <FormControl isInvalid={validationErrors.questionText !== undefined}>
          <FormLabel>Question text</FormLabel>
          <Textarea
            data-testid="new-question-text"
            value={questionText}
            onChange={e => setQuestionText(e.target.value)}
          />
          {validationErrors.questionText && validationErrors.questionText[0] && (
            <FormErrorMessage>{validationErrors.questionText[0].message}</FormErrorMessage>
          )}
        </FormControl>
        {creating.lastError ? (
          <Alert status="error" mt="12">
            <AlertIcon boxSize="40px" />
            <Box>
              <AlertTitle fontSize="md">Error creating question</AlertTitle>
              <AlertDescription fontSize="md">{`${creating.lastError}`}</AlertDescription>
            </Box>
          </Alert>
        ) : null}
      </Stack>
      <Box pt={8}>
        <Button
          colorScheme="green"
          leftIcon={<Icon as={PlusIcon} />}
          type="submit"
          isLoading={creating.inProgress}
          isDisabled={questionText.length === 0}
        >
          Create question
        </Button>
      </Box>
    </Box>
  );
};

const NewQuestion = withSuspense(() => {
  const redirect = (questionId: QuestionId) => router!.navigate(`/vendor-toolkit/questions/${questionId}`);
  return (
    <Page title="New questionnaire">
      <Heading size="md" p="8">
        Create question
      </Heading>
      <NewQuestionInner redirect={redirect} />
    </Page>
  );
});

export default NewQuestion;
