import {Box, FormControl, FormLabel, GridItem, SimpleGrid} from "@chakra-ui/react";
import CommitInput from "../../components/CommitInput";
import {useCallback} from "react";
import api from "../../api";
import {useQueryData} from "../../state";

const Profile = () => {
  const whoami = useQueryData({queryKey: ["whoAmI"]});
  const changeName = useCallback(async (name: string) => {
    await api.auth.changeName(name);
  }, []);
  return (
    <Box py="6" px="4" pb="8">
      <SimpleGrid columns={12} mt="6" spacing="6">
        <GridItem as={FormControl} colSpan={6}>
          <FormLabel>Name</FormLabel>
          <CommitInput type="text" autoComplete="name" value={whoami.user.name} onCommit={changeName} />
        </GridItem>
      </SimpleGrid>
    </Box>
  );
};

export default Profile;
