import {Route, Navigate} from "react-router-dom";
import {Icon, Menu, MenuButton} from "@chakra-ui/react";
import {
  ChevronDownIcon,
  DocumentTextIcon,
  PaintBrushIcon,
  PhotoIcon,
  WrenchIcon,
  BuildingLibraryIcon,
} from "@heroicons/react/24/solid";

import configurationRoutes from "./Config/index.tsx";
import contentsRoutes from "./Contents/index.tsx";
import assetRoutes from "./Assets/index.tsx";
import themeRoutes from "./Theme/index.tsx";
import libraryRoutes from "./Library/index.tsx";
import notificationRoutes from "./NotificationCenter/index.tsx";
import StyledNavLink from "../../../Header/components/StyledNavLink.tsx";
import StyledMenuItem from "../../../Header/components/StyledMenuItem.tsx";
import {Internal} from "../../../components/InternalMode.tsx";
import PortalMenuList from "../../../components/PortalMenuList.tsx";
import {InboxIcon} from "@heroicons/react/24/outline";
import {useQueryData} from "../../../state";

export const TrustCenterNav = () => {
  const config = useQueryData({queryKey: ["vendorToolkit", "trustCenterConfig"]});

  return (
    <Menu placement="bottom-start">
      <MenuButton>
        <StyledNavLink to="trust-center">
          Showcase <Icon as={ChevronDownIcon} ml="1" mt="1px" />
        </StyledNavLink>
      </MenuButton>
      <PortalMenuList py="0" overflow="hidden">
        <StyledMenuItem to="trust-center/config" iconComp={WrenchIcon} help="How your trust center appears to visitors">
          Configuration
        </StyledMenuItem>
        <StyledMenuItem
          to="trust-center/contents"
          iconComp={DocumentTextIcon}
          help="The text that appears on your trust center"
        >
          Contents
        </StyledMenuItem>
        <StyledMenuItem
          to="trust-center/assets"
          iconComp={PhotoIcon}
          help="The images and files used by your trust center"
        >
          Assets
        </StyledMenuItem>
        <Internal>
          <StyledMenuItem
            to="trust-center/theme"
            iconComp={PaintBrushIcon}
            help="The look and feel of your trust center"
          >
            Theme
          </StyledMenuItem>
        </Internal>
        <StyledMenuItem
          to="trust-center/library"
          iconComp={BuildingLibraryIcon}
          help="Published information from your library"
        >
          Library
        </StyledMenuItem>
        <Internal>
          {config.meta.subscribe?.enabled && (
            <StyledMenuItem
              to="trust-center/notification-center"
              iconComp={InboxIcon}
              help="View and publish updates to your mailing list"
            >
              Notification center
            </StyledMenuItem>
          )}
        </Internal>
      </PortalMenuList>
    </Menu>
  );
};

export default (
  <Route path="trust-center" handle={{crumb: "Trust center"}}>
    {configurationRoutes}
    {contentsRoutes}
    {assetRoutes}
    {themeRoutes}
    {libraryRoutes}
    {notificationRoutes}
    <Route index element={<Navigate to="config" replace />} />
  </Route>
);
