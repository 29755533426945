import {
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputProps,
  InputRightElement,
  Spinner,
} from "@chakra-ui/react";
import {MagnifyingGlassIcon} from "@heroicons/react/24/outline";
import {forwardRef, useCallback, useImperativeHandle, useRef, useState} from "react";
import {useDebouncedEffect} from "../hooks/debouncedEffect";
import {XMarkIcon} from "@heroicons/react/20/solid";

type Props = {
  query: string;
  onChange: (query: string) => void;
  placeholder?: string;
  debounceTime?: number;
  pending?: boolean;
  disabled?: boolean;
  inputProps?: InputProps;
};

const Search = forwardRef<HTMLInputElement, Props>(function Search(
  {
    query,
    onChange,
    placeholder = "Search...",
    debounceTime = 0,
    pending = false,
    disabled = false,
    inputProps = {},
  }: Props,
  ref,
) {
  // If the query is debounced, we need to store the intermediate value
  // internally.
  const [innerQuery, setInnerQuery] = useState(query);
  const innerRef = useRef<HTMLInputElement>(null);
  useImperativeHandle(ref, () => innerRef.current!);

  const isDebounced = debounceTime > 0;
  const showSpinner = pending || (isDebounced && innerQuery !== query);

  useDebouncedEffect(
    () => {
      if (isDebounced) {
        onChange(innerQuery);
      }
    },
    [innerQuery, isDebounced],
    debounceTime,
  );

  const innerOnChange = useCallback(
    (query: string) => {
      if (isDebounced) {
        // If we're debounced, we should set the inner state. The
        // `useDebouncedEffect` will handle calling `onChange` after the
        // debounce period.
        setInnerQuery(query);
      } else {
        // If not, we can notify the parent immediately.
        onChange(query);
      }
    },
    [isDebounced, onChange],
  );

  const value = isDebounced ? innerQuery : query;

  return (
    <InputGroup>
      <InputLeftElement pointerEvents="none">
        <Icon as={MagnifyingGlassIcon} h={4} w={4} />
      </InputLeftElement>
      <Input
        placeholder={placeholder}
        value={value}
        onChange={ev => innerOnChange(ev.target.value)}
        ref={innerRef}
        isDisabled={disabled}
        {...inputProps}
      />
      {showSpinner || value.length > 0 ? (
        <InputRightElement pointerEvents="none" w="auto">
          {showSpinner && <Spinner size="sm" color="blue.500" flex="0 0 auto" mr={3} />}
          {value.length > 0 && (
            <IconButton
              icon={<Icon as={XMarkIcon} />}
              onClick={() => {
                innerOnChange("");
                innerRef.current?.focus();
              }}
              isDisabled={disabled}
              aria-label="Clear"
              variant="ghost"
              pointerEvents="auto"
              width="7"
              height="7"
              mr="1.5"
              minW="unset"
              flex="0 0 auto"
            />
          )}
        </InputRightElement>
      ) : null}
    </InputGroup>
  );
});

export default Search;
