import {useReadLocalStorage} from "usehooks-ts";
import {AccountId, nominate} from "../../Types";

const url = new URL(window.location.href);
if (url.searchParams.has("access_token")) {
  setAccessToken(
    nominate("accountId", url.searchParams.get("client_account_id")!),
    url.searchParams.get("access_token"),
  );
  url.searchParams.delete("access_token");
  url.searchParams.delete("client_account_id");
  window.history.replaceState(null, "", url.toString());
}

function storageKey(accountId: AccountId): string {
  return `p4daccount-${accountId}-accesstoken`;
}

function setAccessToken(clientAccountId: AccountId, accessToken: string | null) {
  const key = storageKey(clientAccountId);
  if (accessToken) {
    localStorage.setItem(key, JSON.stringify(accessToken));
  } else {
    localStorage.removeItem(key);
  }
  window.dispatchEvent(new StorageEvent("local-storage", {key}));
}

export function useAccessToken(clientAccountId: AccountId): string | null {
  const key = storageKey(clientAccountId);
  return useReadLocalStorage(key);
}

export function clearAccessToken(clientAccountId: AccountId) {
  setAccessToken(clientAccountId, null);
}
