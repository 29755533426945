import {
  Button,
  ButtonProps,
  HStack,
  Icon,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import {memo} from "react";
import {SparklesIcon} from "@heroicons/react/24/solid";
import api from "../../../../api/index.ts";
import {useBackgroundTaskState} from "../../../../hooks/backgroundTask.ts";
import {ArrowTopRightOnSquareIcon} from "@heroicons/react/20/solid";
import {QuestionId, QuestionnaireId} from "../../../../Types.ts";
import {useQueryData} from "../../../../state";

type AutoCompleteButtonProps = {
  isDisabled: boolean;
  tooltipLabel?: string;
  buttonProps?: ButtonProps;
} & ({target: "Questionnaire"; questionnaireId: QuestionnaireId} | {target: "Question"; questionId: QuestionId});

const AutoCompleteButton = memo((props: AutoCompleteButtonProps) => {
  const {internal_mode} = useQueryData({queryKey: ["whoAmI"]});
  const {isOpen, onOpen, onClose} = useDisclosure();
  const [autoCompleting, autoComplete] = useBackgroundTaskState(
    async () => {
      return props.target === "Question"
        ? await api.vendorToolkit.questions.automaticallyAnswer(props.questionId)
        : await api.vendorToolkit.questionnaires.automaticallyAnswer(props.questionnaireId);
    },
    [props],
    {
      description: "AI completion has finished.",
      isClosable: true,
    },
  );

  const progress = autoCompleting.data ? `${(autoCompleting.data.progress * 100).toFixed(0)}%` : null;
  const onClick = internal_mode && props.target === "Questionnaire" ? onOpen : autoComplete;

  return (
    <>
      <Tooltip isDisabled={props.tooltipLabel === undefined} label={props.tooltipLabel}>
        <Button
          leftIcon={<Icon as={SparklesIcon} />}
          isLoading={autoCompleting.inProgress}
          onClick={onClick}
          overflow="hidden"
          loadingText={progress}
          isDisabled={autoCompleting.inProgress || props.isDisabled}
          {...props.buttonProps}
        >
          <Text overflow="hidden" textOverflow="ellipsis">
            Answer with AI
          </Text>
        </Button>
      </Tooltip>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Check AI text</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing={4} alignItems="start">
              <Text fontSize="md">
                Have you checked the AI text dashboard for this account - making sure that no documents need their text
                updated?
              </Text>
              <Button
                as={Link}
                variant="link"
                color="blue.500"
                fontSize="md"
                target="_blank"
                href="https://platformed.retool.com/apps/f2bb5ce0-bc17-11ee-b2ab-8bf9872f26f4/Admin/Documents%20Missing%20AI%20Text"
                rightIcon={<Icon as={ArrowTopRightOnSquareIcon} boxSize={4} />}
              >
                Retool dashboard
              </Button>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <HStack spacing={3}>
              <Button variant="ghost" onClick={onClose} isDisabled={autoCompleting.inProgress}>
                Cancel
              </Button>
              <Button
                colorScheme="purple"
                onClick={() => {
                  autoComplete();
                  onClose();
                }}
                isDisabled={autoCompleting.inProgress}
              >
                Continue with AI
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
});

export default AutoCompleteButton;
