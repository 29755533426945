import {Heading, Stack} from "@chakra-ui/react";
import {useQueryData} from "../../../../../state";
import * as _ from "lodash-es";
import ReviewItemRow from "../components/Review/ReviewItemRow";
import NoReviewItemsSnippet from "../components/Review/NoReviewItemsSnippet";

function ReviewItemsPage() {
  const resolutions = useQueryData({queryKey: ["vendorToolkit", "resolutions"]});

  return (
    <Stack flexGrow={1} gap={0}>
      <Heading size="md" px={8} pt={8} pb={8}>
        Review items
      </Heading>
      {resolutions.length > 0 ? (
        resolutions.map(r => <ReviewItemRow key={r.resolution_id} resolution={r} />)
      ) : (
        <NoReviewItemsSnippet />
      )}
    </Stack>
  );
}

export default ReviewItemsPage;
