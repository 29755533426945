import {Box, Button, Flex, Icon, Menu, MenuButton, MenuItem, MenuItemProps} from "@chakra-ui/react";
import {ChevronDownIcon, DocumentIcon, PlusIcon} from "@heroicons/react/20/solid";
import {router} from "../../../../router";
import PortalMenuList from "../../../../components/PortalMenuList";

const StyledMenuItem = ({
  icon,
  children,
  description,
  ...props
}: {icon: React.FC; description: string} & Omit<MenuItemProps, "icon">) => (
  <MenuItem {...props}>
    <Flex py="1">
      <Icon as={icon} h="6" mr="2" color="gray.400" />
      <Box>
        <Box fontWeight={500}>{children}</Box>
        <Box fontWeight={400} fontSize="sm" color="gray.500" pr="4" maxW={220}>
          {description}
        </Box>
      </Box>
    </Flex>
  </MenuItem>
);

const ImportButton = () => {
  return (
    <>
      <Menu placement="bottom-end">
        <MenuButton
          as={Button}
          rightIcon={<Icon as={ChevronDownIcon} />}
          colorScheme="green"
          leftIcon={<Icon as={PlusIcon} />}
        >
          Import historical questionnaire
        </MenuButton>
        <PortalMenuList fontSize="md">
          <StyledMenuItem
            icon={DocumentIcon}
            description="Import your questionnaire from a file"
            onClick={() => router!.navigate("/vendor-toolkit/library/historical-questionnaires/new")}
          >
            From file
          </StyledMenuItem>
        </PortalMenuList>
      </Menu>
    </>
  );
};

export default ImportButton;
