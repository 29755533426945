import {useLocalStorage} from "usehooks-ts";
import {Json} from "../api/jsonApi";
import {useQueryData} from "../state";

export function useAccountLocalStorage<S extends Json>(
  storageKey: string,
  defaultValue: S,
): [S, (newValue: S | ((oldValue: S) => S)) => void] {
  const whoAmI = useQueryData({queryKey: ["whoAmI"]});
  const accountId = whoAmI.account?.account_id;
  const k = `p4daccountid-${accountId}:${storageKey}`;
  return useLocalStorage(k, defaultValue);
}
