import {param, typedQuery} from "../typing.ts";
import api from "../../api";
import {NotificationChannelConnectorType, NotificationChannelId} from "../../Types.ts";

export default [
  typedQuery(["notificationChannelConnectors"], async () => {
    return await api.notificationChannels.listConnectors();
  }),
  typedQuery(
    ["notificationChannelConnector", param<NotificationChannelConnectorType>("notificationChannelConnectorType")],
    async context => {
      return await api.notificationChannels.getConnector(context.queryKey[1]);
    },
  ),
  typedQuery(["notificationChannels"], async () => {
    return await api.notificationChannels.list();
  }),
  typedQuery(["notificationChannel", param<NotificationChannelId>("notificationChannelId")], async context => {
    return await api.notificationChannels.get(context.queryKey[1]);
  }),
  typedQuery(["availableNotificationChannels"], async () => {
    return await api.notificationChannels.listAvailable();
  }),
  typedQuery(["linkedNotificationChannels"], async () => {
    return await api.notificationChannels.listLinked();
  }),
];
