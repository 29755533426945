import {Editable, EditablePreview, EditableProps, EditableTextarea, HStack, Icon, Spinner} from "@chakra-ui/react";
import {Question} from "../../../../Types.ts";
import api from "../../../../api/index.ts";
import {usePromiseState} from "../../../../hooks/promiseState.ts";
import {useEffect, useState} from "react";
import {AutoResizingTextarea} from "../../../../components/AutoResizingTextarea.tsx";

const QuestionText = ({question, ...props}: {question: Question} & EditableProps) => {
  const [text, setText] = useState(question.text);

  useEffect(() => {
    setText(question.text);
  }, [question.text]);

  const [editingText, editText] = usePromiseState(
    async (newText: string) => {
      if (newText !== question.text) {
        await api.vendorToolkit.questions.updateText(question.question_id, newText);
      }
    },
    [question],
  );

  return text ? (
    <Editable
      {...props}
      value={text}
      onChange={e => setText(e)}
      onSubmit={e => editText(e)}
      isDisabled={editingText.inProgress}
      flex={1}
    >
      <HStack align="flex-start" justify="space-between">
        <EditablePreview data-testid="question-text" whiteSpace={"pre-wrap"} />
        {editingText.inProgress && <Icon as={Spinner} color="gray.300" mt={1} />}
      </HStack>
      <EditableTextarea as={AutoResizingTextarea} p={2} border="none" mx={-2} my={-1} />
    </Editable>
  ) : null;
};
export default QuestionText;
