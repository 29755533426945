import {Box, Button, Flex, Heading, Icon} from "@chakra-ui/react";
import {ArchiveBoxArrowDownIcon} from "@heroicons/react/20/solid";

import Page from "../../../../../components/Page.tsx";
import Counterparty from "../../../components/Counterparty.tsx";
import {usePromiseState} from "../../../../../hooks/promiseState.ts";
import api from "../../../../../api/index.ts";
import {useEffect} from "react";
import {router} from "../../../../../router/index.tsx";
import {withSuspense} from "../../../../../state/withSuspense.tsx";
import {useQueryData} from "../../../../../state/index.ts";
import TabsOutlet from "../../../../../components/RouterTabs/Outlet.tsx";
import {QuestionnaireStatus} from "../../../../../Types.ts";
import {
  ArchivedQuestionnaireBanner,
  HistoricalQuestionnaireImportingBanner,
} from "../../../Questionnaires/QuestionnaireViewer/QuestionnaireBanners.tsx";
import LayerSelector from "../../../components/LayerSelector/Selector.tsx";
import {useTypedParams} from "../../../../../hooks/typedParams";
import {QuestionnaireParams} from "./Crumb";

const QuestionnaireViewerPage = withSuspense(() => {
  const {questionnaireId} = useTypedParams(QuestionnaireParams);
  const questionnaire = useQueryData({queryKey: ["vendorToolkit", "questionnaire", questionnaireId]});
  const [restoring, restore] = usePromiseState(async () => {
    await api.vendorToolkit.questionnaires.updateArchived(questionnaire.questionnaire_id, false);
  }, [questionnaire.questionnaire_id]);
  useEffect(() => {
    if (!questionnaire.archived_at) {
      router!.navigate(`/vendor-toolkit/questionnaires/${questionnaire.questionnaire_id}/meta`, {replace: true});
    }
  }, [questionnaire.questionnaire_id, questionnaire.archived_at]);

  return (
    <Page title={questionnaire.name} display="flex" flexDirection="column">
      <ArchivedQuestionnaireBanner />
      <Flex p="8" pb="6" justifyContent="space-between">
        <Box>
          <Heading size="md" p="0">
            {questionnaire.name}
          </Heading>
          <Counterparty counterparty={questionnaire.counterparty} mt={2} />
        </Box>
        <Flex ml="auto" gap={4}>
          <LayerSelector />
          <Button leftIcon={<Icon as={ArchiveBoxArrowDownIcon} />} onClick={restore} isLoading={restoring.inProgress}>
            Restore
          </Button>
        </Flex>
      </Flex>
      <TabsOutlet
        context={{questionnaire}}
        banner={questionnaire.status === QuestionnaireStatus.Importing && <HistoricalQuestionnaireImportingBanner />}
      />
    </Page>
  );
});

export default QuestionnaireViewerPage;
