import {
  Box,
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  useDisclosure,
  HStack,
} from "@chakra-ui/react";
import {UserPlusIcon} from "@heroicons/react/24/outline";
import {memo} from "react";
import api from "../../../api";
import PasswordInput from "../../../components/PasswordInput";
import ComplexityGauge from "../../../components/ComplexityGauge";
import {LoginMethod} from "../../../Types";
import {useModalState} from "../../../hooks/modalState";
import {useModalValidationState, useValidatedPromiseState} from "../../../hooks/validationState";

const CreateUser = memo(() => {
  const {isOpen, onOpen, onClose} = useDisclosure();

  const [name, setName] = useModalState(isOpen, "");
  const [email, setEmail] = useModalState(isOpen, "");
  const [password, setPassword] = useModalState(isOpen, "");
  const [validationErrors, setValidationErrors] = useModalValidationState(isOpen, {name, email, password});

  const [creating, create] = useValidatedPromiseState(
    async () => {
      await api.users.createRegistered({
        name,
        email,
        password,
        login_method: LoginMethod.UsernamePassword,
      });
      onClose();
    },
    [name, email, password, onClose],
    setValidationErrors,
  );

  return (
    <>
      <Button leftIcon={<Icon as={UserPlusIcon} />} onClick={onOpen}>
        Create user
      </Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent as="form" onSubmit={create}>
          <ModalHeader>Create new user</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing="8" mb="4">
              <FormControl isRequired>
                <FormLabel>Name</FormLabel>
                <Input onChange={e => setName(e.target.value)} value={name} placeholder="Jane Smith" autoFocus />
              </FormControl>
              <FormControl isRequired isInvalid={validationErrors.email !== undefined}>
                <FormLabel>Email</FormLabel>
                <Input onChange={e => setEmail(e.target.value)} value={email} placeholder="jane@example.com" />
                {validationErrors.email && validationErrors.email[0] && (
                  <FormErrorMessage>{validationErrors.email[0].message}</FormErrorMessage>
                )}
              </FormControl>
              <FormControl isRequired isInvalid={validationErrors.password !== undefined}>
                <FormLabel>Password</FormLabel>
                <Stack spacing={3}>
                  <PasswordInput value={password} onChange={e => setPassword(e.target.value)}></PasswordInput>
                  <ComplexityGauge value={password} />
                  {validationErrors.password && validationErrors.password[0] && (
                    <FormErrorMessage>{validationErrors.password[0].message}</FormErrorMessage>
                  )}
                </Stack>
              </FormControl>

              {creating.lastError ? (
                <Alert status="error" mt="12">
                  <AlertIcon boxSize="40px" />
                  <Box>
                    <AlertTitle fontSize="md">Error creating user</AlertTitle>
                    <AlertDescription fontSize="md">{`${creating.lastError}`}</AlertDescription>
                  </Box>
                </Alert>
              ) : null}
            </Stack>
          </ModalBody>

          <ModalFooter>
            <HStack spacing="3">
              <Button variant="ghost" onClick={onClose} isDisabled={creating.inProgress}>
                Cancel
              </Button>
              <Button colorScheme="blue" type="submit" isLoading={creating.inProgress}>
                Create
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
});

export default CreateUser;
