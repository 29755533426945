import {FactLibraryContext} from "../../Facts/FactRow";
import {getNodeName} from "./hierarchy";

export function getSearchPlaceholderText(libraryContext?: FactLibraryContext) {
  if (libraryContext === undefined) {
    return `Search all facts...`;
  }

  const label = getNodeName(libraryContext.parent);
  const nodeType = libraryContext.parent.type === "section" ? "section" : "scope";
  const suffix = libraryContext.recurse === false ? " (uncategorized)" : "";

  return `Search within ${nodeType} "${label}"${suffix}...`;
}
