import {Badge, HStack, Icon, Spinner, Stack, Text, Tooltip} from "@chakra-ui/react";
import {Fact, FactTextMin, SourcedFact} from "../../../Types";
import {ClockIcon} from "@heroicons/react/20/solid";
import {useTime} from "../../../hooks/time";
import {relativeDateView} from "../../../utils/time";

const FactTextRow = ({factText}: {factText: FactTextMin}) => {
  const age = useTime(relativeDateView(factText.created_at), [factText.created_at]);
  return (
    <HStack fontSize="md" py={1} px={4} gap={8}>
      <Tooltip label="This fact is still being processed for incorporation into your fact library.">
        <Spinner color="gray.300" />
      </Tooltip>
      <Stack flex={1}>
        <Text fontWeight="medium" color="gray.400">
          {factText.content}
        </Text>
        <HStack>
          <Badge>
            <Icon mb="-1px" as={ClockIcon} color="gray.500" /> {age}
          </Badge>
        </HStack>
      </Stack>
    </HStack>
  );
};

const FactRow = ({fact}: {fact: Fact}) => {
  const age = useTime(relativeDateView(fact.representative_text.created_at), [fact.representative_text.created_at]);
  return (
    <HStack fontSize="md" py={1} px={4} gap={8}>
      <Stack flex={1}>
        <Text fontWeight="medium" color="gray.600">
          {fact.representative_text.content}
        </Text>
        <HStack>
          <Badge>
            <Icon mb="-1px" as={ClockIcon} color="gray.500" /> {age}
          </Badge>
        </HStack>
      </Stack>
    </HStack>
  );
};

export default function SourcedFactRow({sourcedFact}: {sourcedFact: SourcedFact}) {
  switch (sourcedFact.type) {
    case "Fact":
      return <FactRow fact={sourcedFact.content} />;
    case "FactText":
      return <FactTextRow factText={sourcedFact.content} />;
  }
}
