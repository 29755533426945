import api from "../../../api";
import {DocumentSourceId} from "../../../Types";
import {param, typedQuery} from "../../typing";

export default [
  typedQuery(["vendorToolkit", "documentSource", param<DocumentSourceId>("documentSourceId")], async context => {
    return await api.vendorToolkit.documentSources.get(context.queryKey[2]);
  }),
  typedQuery(["vendorToolkit", "documentSources"], async () => {
    return await api.vendorToolkit.documentSources.list();
  }),
  typedQuery(["vendorToolkit", "documentSourceConnector", param("documentSourceConnectorId")], async context => {
    return await api.vendorToolkit.documentSources.getConnector(context.queryKey[2]);
  }),
  typedQuery(["vendorToolkit", "documentSourceConnectors"], async () => {
    return await api.vendorToolkit.documentSources.listConnectors();
  }),
];
