import {useCallback, useEffect} from "react";
import {useSessionState} from "./sessionState";
import * as _ from "lodash-es";

// Get any filter params from the URL, set them on the filterState and remove from the URL
function applyFiltersFromURL<F>(setFilters: (newValue: F | ((oldValue: F) => F)) => void) {
  const url = new URL(window.location.href);
  const filterParam = url.searchParams.get("filters");

  if (filterParam) {
    try {
      const filterObject: F = JSON.parse(filterParam);
      setFilters(filterObject);
    } catch (_) {
      // Invalid filter array in the URL should just be dropped
    }
    url.searchParams.delete("filters");
    window.history.replaceState({}, document.title, url);
  }
}

// Type returned by `useFilterState`.
export type FilterState<F> = {
  // Current state of the filters (an object mapping from a filter key to an unordered array of values)
  filters: F;
  // A function to update the values for one of the filters by name
  setFilter: <K extends keyof F>(name: K, value: F[K]) => void;
  // A function to reset the filters to their defaults
  clearFilters: () => void;
  /// Total number of non-default filters being applied
  filterCount: number;
  ///  A function to set or reset the whole filterState in one go
  setFilters: (newValue: F | ((oldValue: F) => F)) => void;
};

export function useFilterState<F extends {[key: string]: unknown[]}>(
  // Key under which to store the filter state in `sessionStorage`
  storageKey: string,
  // The default filters to apply
  defaultFilters: F,
): FilterState<F> {
  // Store our filter state in session storage
  const [filters, setFilters] = useSessionState(storageKey, defaultFilters);

  // Define a function to update a filter
  const setFilter = useCallback(
    <K extends keyof F>(name: K, value: F[K]) => {
      setFilters((oldFilters: F) => ({
        ...oldFilters,
        [name]: value,
      }));
    },
    [setFilters],
  );

  // Define a function to reset the filters to their defaults
  const clearFilters = useCallback(() => {
    setFilters(defaultFilters);
  }, [setFilters, defaultFilters]);

  // Count how many filters are not using their default values
  const filterCount = Object.entries(filters).filter(
    // Compute the symmetric difference between the filter value array and the default value array.
    // If the result is non-empty, then the filter is not a default filter and should be counted.
    ([k, v]) => _.xor(v, defaultFilters[k as keyof F]).length > 0,
  ).length;

  useEffect(() => {
    applyFiltersFromURL(setFilters);
  }, [setFilters]);

  return {
    filters,
    setFilter,
    clearFilters,
    filterCount,
    setFilters,
  };
}

export function encodeFiltersParam<F extends {[key: string]: unknown[]}>(filters: F) {
  return encodeURIComponent(JSON.stringify(filters));
}
