import {router} from "../router";
import {NewQuestionnaireInner} from "../Products/VendorToolkit/Questionnaires/NewQuestionnaire";
import {QuestionnaireId} from "../Types";

const ExtensionNewQuestionnaire = () => {
  const redirect = (questionnaireId: QuestionnaireId) =>
    router!.navigate(`/extension/questionnaires/${questionnaireId}`);

  return <NewQuestionnaireInner redirect={redirect} />;
};

export default ExtensionNewQuestionnaire;
