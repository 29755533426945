// Element which should be navigable via the URL anchor. Should be a descendant of a

import {ReactNode, useContext, useEffect, useRef} from "react";
import scrollAnchorContext from "./context";
import {useStickyOffset} from "../StickyHeader";
import {Box} from "@chakra-ui/react";

// When scrolling to an element, some extra margin to add so that it's not
// pressed against the top border (in px).
const EXTRA_SCROLL_MARGIN = 32;

// ScrollAnchorContainer.
export default function ScrollAnchor({id, children}: {id: string; children?: ReactNode}) {
  const {anchorMap} = useContext(scrollAnchorContext);
  const stickyOffset = useStickyOffset();
  const ref = useRef(null);

  // Register ourselves with the scroll anchor container
  useEffect(() => {
    anchorMap[id] = ref.current!;
    return () => {
      delete anchorMap[id];
    };
  }, [id, anchorMap]);

  return (
    <Box id={id} ref={ref} scrollMarginTop={`${stickyOffset + EXTRA_SCROLL_MARGIN}px`}>
      {children}
    </Box>
  );
}
