import {Button, Icon, Menu, MenuButton, MenuGroup, MenuItem, useDisclosure} from "@chakra-ui/react";
import {
  ArrowDownOnSquareIcon,
  Bars3Icon,
  CalendarIcon,
  ChevronDownIcon,
  TrashIcon,
  UserMinusIcon,
} from "@heroicons/react/24/solid";
import {memo} from "react";
import {BulkQuestionAction, QuestionId, QuestionStatus} from "../../../../../Types.ts";
import {usePromiseState} from "../../../../../hooks/promiseState.ts";
import {ReassignUserModal} from "../../../../components/ReassignUserModal.tsx";
import api from "../../../../../api/index.ts";
import {DueDateModal} from "../../../../../components/DueDateModal.tsx";
import {QUESTION_STATUS_MAP} from "../../../components/QuestionStatus/index.tsx";
import {useQueryData} from "../../../../../state/index.ts";
import {useLayerType} from "../../../../../hooks/layerType.ts";
import {DeleteModal} from "../../../../../components/DeleteModalButton.tsx";
import {ReassignTeamModal} from "../../../../components/ReassignTeamModal.tsx";
import PortalMenuList from "../../../../../components/PortalMenuList.tsx";

export const BulkActions = memo(({selectedIds}: {selectedIds: Set<QuestionId>}) => {
  const [layerType] = useLayerType();
  const {isOpen, onOpen, onClose} = useDisclosure();
  const whoami = useQueryData({queryKey: ["whoAmI"]});
  const [updating, update] = usePromiseState(
    async (action: BulkQuestionAction) => {
      await api.vendorToolkit.questions.bulkUpdate({
        items: [...selectedIds].map(question_id => ({question_id, action})),
        layer_type: layerType,
      });
      onClose();
    },
    [onClose, selectedIds, layerType],
  );
  return (
    <>
      <Menu isLazy lazyBehavior="keepMounted">
        <MenuButton
          as={Button}
          rightIcon={<Icon as={ChevronDownIcon} />}
          colorScheme="green"
          leftIcon={<Icon as={Bars3Icon} />}
          isDisabled={selectedIds.size === 0}
          isLoading={updating.inProgress}
          title="Bulk actions"
        >
          {selectedIds.size > 0 ? `Update ${selectedIds.size} question${selectedIds.size === 1 ? "" : "s"}...` : ""}
        </MenuButton>
        <PortalMenuList fontSize="md">
          <MenuGroup title="Owner">
            <MenuItem onClick={() => update({type: "Assign", content: whoami.user_owner!.owner_id})}>
              <Icon as={ArrowDownOnSquareIcon} h="6" mr="2" color="gray.500" />
              Assign to me
            </MenuItem>
            <ReassignUserModal
              onReassign={owner => update({type: "Assign", content: owner?.owner_id})}
              isReassigning={updating.inProgress}
              text={"Assign to user"}
            />
            <ReassignTeamModal
              onReassign={owner => update({type: "Assign", content: owner?.owner_id})}
              isReassigning={updating.inProgress}
              text={"Assign to team"}
            />
            <MenuItem onClick={() => update({type: "Assign"})}>
              <Icon as={UserMinusIcon} h="6" mr="2" color="gray.500" />
              Unassign
            </MenuItem>
          </MenuGroup>
          <MenuGroup title="Set status...">
            {Object.keys(QuestionStatus)
              .filter(s => !QUESTION_STATUS_MAP[s].disableSetting)
              .map(s => (
                <MenuItem key={s} onClick={() => update({type: "SetStatus", content: s as QuestionStatus})}>
                  <Icon as={QUESTION_STATUS_MAP[s].icon} h="6" mr="2" color="gray.500" />
                  {QUESTION_STATUS_MAP[s].longText}
                </MenuItem>
              ))}
          </MenuGroup>
          <MenuGroup title="Due date">
            <MenuItem onClick={() => update({type: "SetDueDate"})}>
              <Icon as={CalendarIcon} h="6" mr="2" color="gray.500" />
              Clear
            </MenuItem>
            <DueDateModal onPick={dueDate => update({type: "SetDueDate", content: dueDate})} />
          </MenuGroup>
          <MenuGroup title="Other">
            <MenuItem onClick={onOpen}>
              <Icon as={TrashIcon} h="6" mr="2" color="gray.500" />
              Delete
              <DeleteModal
                isOpen={isOpen}
                onClose={onClose}
                delete_={() => update({type: "Delete"})}
                deleting={updating.inProgress}
                modalHeader="Delete questions"
                modalBody="Are you sure you want to delete these questions?"
              />
            </MenuItem>
          </MenuGroup>
        </PortalMenuList>
      </Menu>
    </>
  );
});
