import {
  Button,
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import {useModalState} from "../../hooks/modalState.ts";
import externalApi from "../../api/external";
import {useModalValidationState, useValidatedPromiseState} from "../../hooks/validationState.ts";

type SubscribeModalProps = {
  isOpen: boolean;
  onClose: () => void;
  privacyPolicy?: string;
  displayName?: string;
};

const SubscribeModal = ({isOpen, onClose, privacyPolicy, displayName}: SubscribeModalProps) => {
  const [name, setName] = useModalState(isOpen, "");
  const [company, setCompany] = useModalState(isOpen, "");
  const [email, setEmail] = useModalState(isOpen, "");
  const [agreePrivacy, setAgreePrivacy] = useModalState(isOpen, false);
  const [validationErrors, setValidationErrors] = useModalValidationState(isOpen, {email, agreePrivacy});

  const [subscribing, subscribe] = useValidatedPromiseState(
    async () => {
      await externalApi.trustCenters.subscribe({
        email,
        metadata: {
          name: name === "" ? undefined : name,
          company: company === "" ? undefined : company,
        },
      });
      onClose();
    },
    [onClose, email, name, company],
    setValidationErrors,
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent as="form" onSubmit={subscribe}>
        <ModalHeader>Subscribe to mailing list </ModalHeader>
        <ModalCloseButton />
        <ModalBody fontSize="md">
          <Stack spacing={4}>
            <Text fontSize="md">Subscribe to be notified of changes to our trust center.</Text>
            <FormControl isRequired>
              <FormLabel>Business email</FormLabel>
              <Input type="email" onChange={e => setEmail(e.target.value)} />
              {validationErrors.email && validationErrors.email[0] && (
                <FormErrorMessage>{validationErrors.email[0].message}</FormErrorMessage>
              )}
            </FormControl>
            <FormControl>
              <FormLabel>Name</FormLabel>
              <Input type="text" onChange={e => setName(e.target.value)} />
            </FormControl>
            <FormControl>
              <FormLabel>Company</FormLabel>
              <Input type="text" onChange={e => setCompany(e.target.value)} />
            </FormControl>
            <FormControl isRequired>
              <Checkbox
                isChecked={agreePrivacy}
                onChange={e => setAgreePrivacy(e.target.checked)}
                fontWeight={500}
                alignItems={"top"}
              >
                <Stack mt={-0.5} ml={1}>
                  <Text>{"I agree to the processing of personal data"}</Text>
                  {privacyPolicy && (
                    <Link href={privacyPolicy} target="_blank" color="blue.500" fontWeight="semibold">
                      {" "}
                      {displayName ?? "The company's"}'s privacy policy
                    </Link>
                  )}
                  <Link href={"https://platformed.com/privacy"} target="_blank" color="blue.500" fontWeight="semibold">
                    Platformed's privacy policy
                  </Link>
                </Stack>
              </Checkbox>
            </FormControl>
          </Stack>
        </ModalBody>
        <ModalFooter>
          <HStack spacing="3">
            <Button variant="ghost" onClick={onClose}>
              Cancel
            </Button>
            <Button
              colorScheme="green"
              isDisabled={subscribing.inProgress}
              type={"submit"}
              isLoading={subscribing.inProgress}
            >
              Subscribe
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const SubscribeButton = ({privacyPolicy, displayName}: {privacyPolicy?: string; displayName?: string}) => {
  const {isOpen, onOpen, onClose} = useDisclosure();

  return (
    <>
      <Button aria-label="Subscribe" onClick={onOpen} colorScheme={"green"}>
        Subscribe
      </Button>
      <SubscribeModal isOpen={isOpen} onClose={onClose} privacyPolicy={privacyPolicy} displayName={displayName} />
    </>
  );
};

export default SubscribeButton;
