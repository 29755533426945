// TanStack Query keys are compared structurally. Sometimes we want to pass something in
// by reference (so the query is only re-run if the reference changes). This class
// serves that purpose.
export class ByRef<T extends object> {
  // IDs are allocated sequentially - this static tracks our progress
  static #nextId: number = 1;
  // We want the same object to always get the same ID, so use a WeakMap
  static #valueMap = new WeakMap<object, number>();

  // The `ByRef` object must be serializable (compatible with JSON.stringify(...)),
  // so the only public field can by this numeric ID.
  id: number;

  // A private field stores the actual instance. Private fields are not enumerable properties
  // and so won't affect serialization.
  #value: T;

  constructor(value: T) {
    // Find the ID if this value already exists in the map, otherwise generate a new ID
    // and store it in the map.
    this.id = ByRef.#valueMap.get(value) ?? (ByRef.#valueMap.set(value, ByRef.#nextId), ByRef.#nextId++);
    this.#value = value;
  }

  // Getters are also not enumerable properties, so we can use this to expose the
  // value without affecting serialization.
  get value() {
    return this.#value;
  }
}

// Convenience function for constructing a new `ByRef<T>`
export function byRef<T extends object>(value: T): ByRef<T> {
  return new ByRef(value);
}
