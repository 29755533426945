import {useCallback, useEffect, useMemo, useState} from "react";
import {useQueryData} from "../state";
import {Box, FormControl, HStack, Icon, Input, Stack, Text} from "@chakra-ui/react";
import {withSuspense} from "../state/withSuspense";
import {PlusIcon} from "@heroicons/react/20/solid";
import {TeamMin} from "../Types.ts";
import TeamAvatar from "./TeamAvatar.tsx";

export type TeamPickerValue =
  | {
      type: "Team";
      team: TeamMin;
    }
  | {
      type: "Create";
      name: string;
    };

function valueEq(a?: TeamPickerValue | null, b?: TeamPickerValue | null): boolean {
  if (a && b) {
    if (a.type === "Team" && b.type === "Team") {
      return a.team.team_id === b.team.team_id;
    } else if (a.type === "Create" && b.type === "Create") {
      return a.name === b.name;
    } else {
      return false;
    }
  } else {
    return !a && !b;
  }
}

export const TeamPicker = withSuspense(
  ({
    value,
    onSelect,
    autoFocus,
    onSubmit,
  }: {
    value?: TeamPickerValue | null;
    onSelect: (user: TeamPickerValue | null) => void;
    autoFocus?: boolean;
    onSubmit?: () => void;
  }) => {
    const [name, setName] = useState((value?.type === "Team" ? value?.team.name : value?.name) ?? "");
    const team = useQueryData({queryKey: ["teams"]});

    const {user} = useQueryData({queryKey: ["whoAmI"]});
    const userTeams = useQueryData({queryKey: ["userTeams", user.user_id]});
    const userTeamIds = userTeams.map(t => t.team_id);

    const items: TeamPickerValue[] = useMemo(() => {
      const lowerName = name.toLowerCase();
      const filteredTeams = team
        .filter(team => team.name.toLowerCase().includes(lowerName))
        .sort((a, b) => {
          const score = b.name.length / (lowerName.length + 1) - a.name.length / (lowerName.length + 1);
          if (score === 0) {
            return a.name.localeCompare(b.name);
          } else {
            return score;
          }
        });
      const extraItems: TeamPickerValue[] = filteredTeams.some(team => team.name.toLowerCase() === lowerName)
        ? []
        : [
            {
              type: "Create",
              name: name,
            },
          ];
      return [...extraItems, ...filteredTeams.map(team => ({type: "Team", team: team}) as const)];
    }, [team, name]);

    const selectedItem = items.find(item => valueEq(item, value));

    useEffect(() => {
      if (!valueEq(selectedItem, value)) {
        onSelect(selectedItem ?? null);
      }
      if (!selectedItem && (items.length === 1 || (items.length === 2 && items[0].type === "Create"))) {
        onSelect(items[items.length - 1]);
      }
    }, [items, selectedItem, value, onSelect]);

    const keyDown = useCallback(
      (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter" && selectedItem && onSubmit) {
          e.preventDefault();
          onSubmit();
        }
      },
      [onSubmit, selectedItem],
    );

    return (
      <FormControl>
        <Input
          placeholder="Search..."
          value={name}
          onChange={e => setName(e.target.value)}
          borderBottomRadius={0}
          autoFocus={autoFocus}
          onKeyDown={keyDown}
          borderBottom="none"
        />
        <Stack
          overflowY="auto"
          maxH="200px"
          gap={0}
          borderColor="gray.200"
          borderWidth="1px"
          role="listbox"
          roundedBottom="md"
        >
          {items.map(item => {
            const key = item.type === "Team" ? item.team.team_id : item.name;
            const selected = valueEq(item, selectedItem);
            return (
              <Box
                key={key}
                _hover={selected ? {} : {bg: "gray.50"}}
                bg={selected ? "gray.100" : "white"}
                onClick={() => onSelect(item)}
                p={2}
                cursor="default"
                role="option"
              >
                {item.type === "Team" ? (
                  <HStack>
                    <TeamAvatar size="xs" isMember={userTeamIds.includes(item.team.team_id)} />
                    <Text>{item.team.name}</Text>
                  </HStack>
                ) : (
                  <HStack>
                    <Icon as={PlusIcon} />
                    <Text>
                      Create new team{" "}
                      <Text as="span" fontWeight="semibold">
                        {item.name}
                      </Text>
                    </Text>
                  </HStack>
                )}
              </Box>
            );
          })}
        </Stack>
      </FormControl>
    );
  },
);
