import {Outlet} from "react-router-dom";
import {useTrustCenterConfig} from "./config.ts";
import {clearAccessToken, useAccessToken} from "./hooks/accessToken";
import {withSuspense} from "../state/withSuspense";
import {useFavicon} from "../hooks/favicon";
import {useCallback} from "react";
import {useClientAccount} from "./hooks/clientAccount";
import {ContextT} from "./hooks/trustCenterContext";
import ExtendTheme from "../theme/ExtendTheme";
import baseTheme from "./baseTheme";
import {AuthButton} from "./TopNav/Auth.tsx";
import {Center, Text} from "@chakra-ui/react";
import DocumentTitle from "../components/DocumentTitle.tsx";

const LoadClientConfig = withSuspense(() => {
  const clientAccount = useClientAccount();
  const config = useTrustCenterConfig();
  const accessToken = useAccessToken(clientAccount.account_id);
  const permissions = ["ISO27001"];
  const logout = useCallback(() => clearAccessToken(clientAccount.account_id), [clientAccount]);

  const title = `${config.name} Trust Center | Powered by Platformed`.trim();

  useFavicon(config.favicon_url);

  const context: ContextT = {loggedIn: !!accessToken, permissions, logout};

  return (
    <>
      <DocumentTitle title={title} platformedPrefix={false} />
      {config.is_public || context.loggedIn ? (
        <ExtendTheme extension={baseTheme}>
          <ExtendTheme extension={config.chakraTheme ?? {}}>
            <Outlet context={context} />
          </ExtendTheme>
        </ExtendTheme>
      ) : (
        <Center>
          <Text>Your trust center is not live. Please log in to view it.</Text>
          <AuthButton
            buttonType="Login"
            mx={4}
            justifyContent="flex-start"
            size="lg"
            fontSize="lg"
            px={4}
            leftIcon={undefined}
          >
            Login
          </AuthButton>
        </Center>
      )}
    </>
  );
});

export default LoadClientConfig;
