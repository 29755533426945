import api from "../../../api";
import {typedQuery} from "../../typing";

export default [
  typedQuery(["vendorToolkit", "tasks"], async () => {
    return await api.vendorToolkit.tasks.list();
  }),
  typedQuery(["vendorToolkit", "gettingStartedChecklist"], async () => {
    return await api.vendorToolkit.tasks.getGettingStartedChecklist();
  }),
];
