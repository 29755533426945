import PusherClient, {PresenceChannel, Channel as PusherChannel} from "pusher-js";
import {PusherCallback} from "./context";

// Wrapper around `PusherChannel` which allows multiple components to subscribe to the same
// channel without them interfering with each other. Only used internally to this file.
export class RealChannel {
  #refCount: number;
  #channel: PusherChannel;
  #unsubscribe: () => void;

  constructor(channel: PusherChannel, unsubscribe: () => void) {
    this.#refCount = 1;
    this.#channel = channel;
    this.#unsubscribe = unsubscribe;
  }

  clone(): RealChannel {
    this.#refCount++;
    return this;
  }

  unsubscribe() {
    if (--this.#refCount === 0) {
      this.#unsubscribe();
    }
  }

  bind(eventName: string | null, cb: PusherCallback): () => void {
    // If the subscription was already established, send a fake "subscription_succeeded" message
    // so that callers can rely on receiving this message first.
    const shouldSendSubscriptionSucceeded =
      this.#channel.subscribed && (eventName == null || eventName === "pusher:subscription_succeeded");
    if (shouldSendSubscriptionSucceeded) {
      const data = this.#channel instanceof PresenceChannel ? this.#channel.members : {};
      cb("pusher:subscription_succeeded", data);
    }
    if (eventName != null) {
      const wrappedCb = (data: any) => cb(eventName, data);
      this.#channel.bind(eventName, wrappedCb);
      return () => {
        this.#channel.unbind(eventName, wrappedCb);
      };
    } else {
      const wrappedCb = (eventName: string, data: any) => cb(eventName, data);
      this.#channel.bind_global(wrappedCb);
      return () => {
        this.#channel.unbind_global(wrappedCb);
      };
    }
  }

  trigger(eventName: string, data: any): void {
    this.#channel.trigger(eventName, data);
  }
}

// A real connection to pusher - wraps a `PusherClient`.
export class RealPusher {
  #client: PusherClient;
  #channels = new Map<string, RealChannel>();

  constructor(appKey: string, cluster: string) {
    this.#client = new PusherClient(appKey, {
      cluster,
    });
    this.#client.signin();
  }

  // Subscribe to a channel, but if we're already subscribed, just increase the
  // reference count.
  #subscribeToChannel(channelName: string): RealChannel {
    let channel = this.#channels.get(channelName);
    if (channel) {
      // Already subscribed
      return channel.clone();
    } else {
      // Need a new subscription
      channel = new RealChannel(this.#client.subscribe(channelName), () => {
        this.#client.unsubscribe(channelName);
        this.#channels.delete(channelName);
      });
      this.#channels.set(channelName, channel);
      return channel;
    }
  }

  // Directly subscribe to a channel and event
  subscribe(channelName: string, eventName: string | null, cb: PusherCallback): () => void {
    const channel = this.#subscribeToChannel(channelName);
    const unbind = channel.bind(eventName, cb);
    return () => {
      unbind();
      channel.unsubscribe();
    };
  }

  trigger(channelName: string, eventName: string, data: any): void {
    const channel = this.#channels.get(channelName);
    if (channel) {
      channel.trigger(eventName, data);
    }
  }

  // Disconnect the entire client
  disconnect() {
    this.#client.disconnect();
  }
}
