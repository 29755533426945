import {StaticDecode, TObject} from "@sinclair/typebox";
import {Value} from "@sinclair/typebox/value";
import {ComponentType} from "react";

export function withTypedProps<const T extends TObject>(schema: T, Component: ComponentType<StaticDecode<T>>) {
  return (props: object) => {
    try {
      const typedProps: StaticDecode<T> & object = Value.Decode(schema, props);
      return <Component {...typedProps} />;
    } catch {
      // Spin forever
      throw new Promise(() => {});
    }
  };
}
