import {Text} from "@chakra-ui/react";
import {ResolutionType, Resolution, Fact} from "../../../../../Types.ts";
import {ReactNode} from "react";
import * as _ from "lodash-es";
import Strong from "./Strong.tsx";

export type ResolutionInfo = {
  badgeColor: string;
  label: string;
  description: (facts: Fact[]) => ReactNode;
  canResolve?: (resolution: Resolution) => boolean;
};

export const RESOLUTION_TYPE_MAP: {[type in ResolutionType]: ResolutionInfo} = {
  [ResolutionType.Contradiction]: {
    badgeColor: "red",
    label: "Contradiction",
    description: facts => (
      <Text>
        <Strong>{facts.length}</Strong> contradicting fact{facts.length != 1 ? "s" : ""}
      </Text>
    ),
    canResolve(resolution) {
      return resolution.involved_facts.some(fact => fact.disabled || fact.deleted);
    },
  },
  [ResolutionType.Recurrent]: {
    badgeColor: "blue",
    label: "Recurrent",
    description: facts => (
      <Text>
        <Strong>{facts.length}</Strong> potentially recurrent fact{facts.length != 1 ? "s" : ""}
      </Text>
    ),
  },
  [ResolutionType.Sensitive]: {
    badgeColor: "purple",
    label: "Sensitive",
    description: facts => (
      <Text>
        <Strong>{facts.length}</Strong> sensitive fact{facts.length != 1 ? "s" : ""}
      </Text>
    ),
  },
  [ResolutionType.Stale]: {
    badgeColor: "orange",
    label: "Stale",
    description: facts => (
      <Text>
        <Strong>{facts.length}</Strong> stale fact{facts.length != 1 ? "s" : ""} expired <Strong>3 months</Strong> ago.
      </Text>
    ),
  },
};
