import {useOutletContext} from "react-router-dom";
import {PasswordPolicyConfig} from "../../../../../Types";

import PasswordPolicy from "./PasswordPolicy";
import {useCallback} from "react";
import {TabOutletContext} from "../Actions/Actions";

const EditorPage = () => {
  const {config, setConfig} = useOutletContext<TabOutletContext>();
  const setPasswordPolicyConfig = useCallback(
    (content: PasswordPolicyConfig) => setConfig({type: "Password", content}),
    [setConfig],
  );

  return config?.type === "Password" ? (
    <PasswordPolicy setConfig={setPasswordPolicyConfig} config={config!.content} />
  ) : null;
};

export default EditorPage;
