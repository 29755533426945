import {LayerType} from "../Types";
import {useLayerType} from "../hooks/layerType";
import {useQueryData} from "../state";

export const useLayerColor = () =>
  ({
    [LayerType.External]: useQueryData({queryKey: ["whoAmI"]}).internal_mode ? "green" : "gray",
    [LayerType.Internal]: "red",
    [LayerType.AI]: "purple",
  })[useLayerType()[0]];

export const useHeaderBgColor = (bgColor: string) => {
  const {internal_mode} = useQueryData({queryKey: ["whoAmI"]});
  const color = useLayerColor();
  return internal_mode ? `${color}.600` : bgColor;
};
