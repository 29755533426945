import {Alert, AlertDescription, AlertIcon, AlertTitle, Box, Spinner, Stack, Text} from "@chakra-ui/react";
import CommitTextarea from "../../../../../components/CommitTextarea.tsx";
import {useOutletContext} from "react-router-dom";
import {TabOutletContext} from "../Actions/Actions.tsx";
import {useCallback} from "react";
import api from "../../../../../api/index.ts";
import {useBackgroundTask} from "../../../../../hooks/backgroundTask.ts";

export default function AiTextPage() {
  const {document} = useOutletContext<TabOutletContext>();
  useBackgroundTask(document.extract, [{queryKey: ["vendorToolkit", "document", document.document_id]}]);
  const updateNonAuthoritativeMarkdown = useCallback(
    async (value: string) => {
      await api.vendorToolkit.documents.updateNonAuthoritativeMarkdown(document.document_id, value);
    },
    [document.document_id],
  );

  return (
    <>
      {document.extract ? (
        <Stack h="full" minH="400px" p={4}>
          <Alert status="info" borderRadius="md">
            <Box paddingRight="12px">
              <Spinner color="blue.500" boxSize="40px" />
            </Box>
            <Box>
              <AlertTitle fontSize="md">Extracting AI text</AlertTitle>
              <AlertDescription fontSize="md">
                <Text>Platformed's AI is extracting a textual representation of your file.</Text>
                <Text>This usually only takes a few seconds.</Text>
              </AlertDescription>
            </Box>
          </Alert>
        </Stack>
      ) : (
        <Stack h="full" minH="400px" p={4}>
          <Alert status="info" borderRadius="md">
            <AlertIcon boxSize="40px" />
            <Box>
              <AlertTitle fontSize="md">Internal representation of your file</AlertTitle>
              <AlertDescription fontSize="md">
                <Text>This is a textual representation of your file which helps to power Platformed's AI.</Text>
                <Text>You can make manual edits below to improve it.</Text>
              </AlertDescription>
            </Box>
          </Alert>
          <CommitTextarea
            value={document.markdown}
            onCommit={updateNonAuthoritativeMarkdown}
            fontFamily="monospace !important"
            p={4}
            formControlProps={{display: "flex", alignItems: "stretch", flex: 1}}
            h="full"
          />
        </Stack>
      )}
    </>
  );
}
