import {QuestionSourceConnector, UpdateQuestionSource} from "../../../../../Types.ts";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Icon,
  Img,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  Stack,
  Text,
} from "@chakra-ui/react";
import {ChevronDownIcon} from "@heroicons/react/20/solid";
import {Link as RouterLink} from "react-router-dom";
import {useQueryData} from "../../../../../state/index.ts";
import {useState} from "react";
import {usePromiseState} from "../../../../../hooks/promiseState.ts";
import {router} from "../../../../../router/index.tsx";
import api, {HTTPError} from "../../../../../api/index.ts";
import {AnyQuestionSource} from "./AnyQuestionSource.tsx";
import {withSuspense} from "../../../../../state/withSuspense.tsx";
import DocumentTitle from "../../../../../components/DocumentTitle.tsx";
import PortalMenuList from "../../../../../components/PortalMenuList.tsx";
import {Type} from "../../../../../typing";
import {useTypedParams} from "../../../../../hooks/typedParams";

export const NewQuestionSourceButton = ({connectors}: {connectors: QuestionSourceConnector[]}) => {
  return (
    <Menu placement="bottom-end" closeOnSelect={false}>
      <MenuButton as={Button} colorScheme="green" rightIcon={<Icon as={ChevronDownIcon} />}>
        Add question source
      </MenuButton>
      <PortalMenuList fontSize="md" maxH="50vh" overflowY="auto">
        {connectors.map(({connector_id, name, logo_url}) => (
          <MenuItem
            key={name}
            as={RouterLink}
            w="full"
            _hover={{textDecoration: "none"}}
            to={`new/${connector_id}`}
            gap={4}
          >
            <Img w={8} aspectRatio={1} src={logo_url} />
            <Text fontSize="md">{name}</Text>
          </MenuItem>
        ))}
      </PortalMenuList>
    </Menu>
  );
};

export const QuestionSourceConnectorParams = Type.Object({connectorId: Type.String()});

export const NewQuestionSource = withSuspense(() => {
  const {connectorId} = useTypedParams(QuestionSourceConnectorParams);
  const connector = useQueryData({queryKey: ["vendorToolkit", "questionSourceConnector", connectorId]});
  const [name, setName] = useState(connector.name);
  const [payload, setPayload] = useState<UpdateQuestionSource | null>(null);
  const [validationErrors, setValidationErrors] = useState<{name?: {message: string}[]}>({});

  const handleSetName = (e: React.ChangeEvent<HTMLInputElement>) => {
    delete validationErrors.name;
    setValidationErrors(validationErrors);
    setName(e.target.value);
  };

  const [creating, create] = usePromiseState(async () => {
    if (!payload) return;
    try {
      await api.vendorToolkit.questionSources.create({
        connector_id: connector.connector_id,
        name,
        ...payload,
      });

      router!.navigate("/vendor-toolkit/configuration/integrations/question-sources");
    } catch (ex) {
      if (ex instanceof HTTPError) {
        switch (ex.response.status) {
          case 422:
            setValidationErrors(await ex.response.json());
            return;
          case 409:
            throw new Error(await ex.response.json());
        }
      }
    }
  }, [connector.connector_id, payload, name]);

  return (
    <>
      <DocumentTitle title="New CRM connection" />
      <Stack p={8} spacing={8} align="flex-start">
        <Heading size="md" p="0" display="flex" alignItems="center" gap={2}>
          New CRM connection
        </Heading>
        <Stack spacing={4} align="flex-start" w="500px">
          <FormControl isRequired isInvalid={validationErrors.name !== undefined}>
            <FormLabel>CRM connection name</FormLabel>
            <Input onChange={handleSetName} value={name} isDisabled={false} placeholder={connector.name} />
            {validationErrors.name && validationErrors.name[0] && (
              <FormErrorMessage>{validationErrors.name[0].message}</FormErrorMessage>
            )}
          </FormControl>

          <AnyQuestionSource connectorId={connector.connector_id} payload={payload} setPayload={setPayload} />

          {creating.lastError ? (
            <Alert status="error" mt="12">
              <AlertIcon boxSize="40px" />
              <Box>
                <AlertTitle fontSize="md">Error creating CRM connection</AlertTitle>
                <AlertDescription fontSize="md">{`${creating.lastError}`}</AlertDescription>
              </Box>
            </Alert>
          ) : null}
        </Stack>
        <Button
          colorScheme="green"
          onClick={create}
          isLoading={creating.inProgress}
          isDisabled={validationErrors.name !== undefined || !payload}
        >
          Create
        </Button>
      </Stack>
    </>
  );
});
