import {Route} from "react-router-dom";

import viewerRoute from "./Viewer/index.tsx";
import NewHistoricalQuestionnaire from "./New.tsx";
import HistoricalQuestionnairesPage from "./HistoricalQuestionnairesPage.tsx";

export default (
  <Route path="historical-questionnaires" handle={{crumb: "Historical questionnaires"}}>
    <Route index Component={HistoricalQuestionnairesPage} />
    <Route
      id="NewHistoricalQuestionnaire"
      handle={{crumb: "New"}}
      path="new"
      element={<NewHistoricalQuestionnaire />}
    />
    {viewerRoute}
  </Route>
);
