import {Button} from "@chakra-ui/react";
import {extensionClient} from "./provider";
import {usePromiseState} from "../hooks/promiseState";
import {withSuspense} from "../state/withSuspense";
import {useQueryData} from "../state";

const LoginError = withSuspense(() => {
  useQueryData({queryKey: ["unauthenticated"]});
  const url = new URL(window.location.href);
  url.pathname = "";
  const [openingApp, openApp] = usePromiseState(async () => {
    await extensionClient.request("openApp");
  }, []);
  return (
    <Button onClick={openApp} isLoading={openingApp.inProgress} m="4" colorScheme="blue">
      Click here to log in
    </Button>
  );
});

export default LoginError;
