import {
  Box,
  ButtonGroup,
  Icon,
  IconButton,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import {ArrowDownTrayIcon} from "@heroicons/react/24/outline";
import {OriginalFile, QuestionnaireId} from "../../../../Types.ts";
import api from "../../../../api/index.ts";
import {usePromiseState} from "../../../../hooks/promiseState.ts";
import {DeleteOriginalFileModal} from "./modals.tsx";
import {formatISO} from "../../../../utils/date.ts";
import {CheckIcon} from "@heroicons/react/24/solid";
import {ImportStatus} from "./ImportStatus.tsx";
import {Internal} from "../../../../components/InternalMode.tsx";
import {captureException} from "@sentry/react";
import {useFriendlyMimes} from "../../../../hooks/import.ts";

const ToggleImportedButton = ({file, questionnaireId}: {file: OriginalFile; questionnaireId: QuestionnaireId}) => {
  const [toggling, toggle] = usePromiseState(async () => {
    const value = file.imported_at ? null : formatISO(new Date());
    await api.vendorToolkit.questionnaires.updateOriginalFileImportedAt(questionnaireId, file.file.file_id, value);
  }, [file, questionnaireId]);

  const color = file.imported_at ? "green.400" : "white";

  return (
    <IconButton
      aria-label="Imported"
      bgColor={color}
      variant="outline"
      icon={<Icon as={CheckIcon} />}
      isDisabled={toggling.inProgress}
      isLoading={toggling.inProgress}
      onClick={toggle}
    />
  );
};

const OriginalFileRow = ({file, questionnaireId}: {file: OriginalFile; questionnaireId: QuestionnaireId}) => {
  const [downloading, download] = usePromiseState(
    async () => await api.files.download(file.file.file_id),
    [file.file.file_id],
  );

  const {resolveMime} = useFriendlyMimes();
  let mime = null;
  try {
    mime = resolveMime(file.file.mime_type);
  } catch (ex) {
    captureException(ex);
  }

  return (
    <>
      <Tr>
        <Td overflow="hidden" text-overflow="ellipsis" fontWeight="600">
          <Box maxWidth="600px" overflow="hidden" text-overflow="ellipsis">
            {file.file.name}
          </Box>
        </Td>
        {mime ? <Td title={mime.name}>{mime.fileType.slice(1).toUpperCase()}</Td> : <Td title="Unknown">Unknown</Td>}
        <Td textAlign="center">
          <ImportStatus importedAt={file.imported_at} />
        </Td>
        <Td textAlign="center">
          <ButtonGroup bgColor="white" isAttached variant="outline">
            <Internal>
              <ToggleImportedButton file={file} questionnaireId={questionnaireId} />
            </Internal>
            <IconButton
              aria-label="Download"
              bgColor="white"
              variant="outline"
              icon={<Icon as={ArrowDownTrayIcon} />}
              isDisabled={downloading.inProgress}
              isLoading={downloading.inProgress}
              onClick={download}
            />
            <DeleteOriginalFileModal file={file.file} questionnaireId={questionnaireId} />
          </ButtonGroup>
        </Td>
      </Tr>
    </>
  );
};

const OriginalFiles = ({files, questionnaireId}: {files: OriginalFile[]; questionnaireId: QuestionnaireId}) => {
  return (
    <>
      <Stack mb="3" alignItems="flex-start" overflow="hidden">
        {files.length && (
          <TableContainer bgColor="gray.50" borderColor="gray.200" borderWidth="1px" borderRadius="md" w="full">
            <Table layout="auto" style={{borderCollapse: "separate", borderSpacing: "0"}} size="lg">
              <Thead bgColor="white">
                <Tr>
                  <Th color="gray.500">File name</Th>
                  <Th color="gray.500">Type</Th>
                  <Th color="gray.500" textAlign="center">
                    Status
                  </Th>
                  <Th color="gray.500" textAlign="center">
                    Actions
                  </Th>
                </Tr>
              </Thead>
              <Tbody fontSize="md">
                {files
                  .sort((a, b) => a.file.name.localeCompare(b.file.name))
                  .map(file => (
                    <OriginalFileRow key={file.file.file_id} file={file} questionnaireId={questionnaireId} />
                  ))}
              </Tbody>
            </Table>
          </TableContainer>
        )}
      </Stack>
    </>
  );
};

export default OriginalFiles;
