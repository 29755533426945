import {ActionBar} from "../../../../../components/ActionBar.tsx";
import {NewQuestionSourceButton} from "./NewQuestionSource.tsx";
import {useQueriesData} from "../../../../../state/index.ts";
import {withSuspense} from "../../../../../state/withSuspense.tsx";
import {Box, Button, ButtonGroup, Flex, Icon, Img, Table, Tbody, Td, Th, Thead, Tr} from "@chakra-ui/react";
import {QuestionSourceMin} from "../../../../../Types.ts";
import groupBy from "../../../../../utils/groupBy.tsx";
import {AdjustmentsHorizontalIcon} from "@heroicons/react/20/solid";
import {Link as RouterLink} from "react-router-dom";

const QuestionSourcesList = ({questionSources}: {questionSources: QuestionSourceMin[]}) => {
  return (
    <Table>
      <Thead>
        <Tr>
          <Th>Connection</Th>
          <Th>Name</Th>
          <Th>Actions</Th>
        </Tr>
      </Thead>
      {groupBy(questionSources, questionSource => questionSource.connector.connector_id).map(g => (
        <Tbody key={g.key}>
          {g.items.map(questionSource => (
            <QuestionSourceCard key={questionSource.question_source_id} questionSource={questionSource} />
          ))}
        </Tbody>
      ))}
    </Table>
  );
};

const QuestionSourceCard = ({
  questionSource: {question_source_id, connector, name},
}: {
  questionSource: QuestionSourceMin;
}) => {
  return (
    <Tr>
      <Td w="20%" h="20">
        <Flex align="center" fontSize="md">
          <Img src={connector.logo_url} h={4} aspectRatio={1} mr={2} />
          {connector.name}
        </Flex>
      </Td>
      <Td w="20%" fontSize="md" fontWeight="500" color="gray.700">
        {name}
      </Td>
      <Td>
        <ButtonGroup isAttached variant="outline" shadow="sm">
          <Button
            as={RouterLink}
            leftIcon={<Icon as={AdjustmentsHorizontalIcon} />}
            to={`/vendor-toolkit/configuration/integrations/question-sources/configure/${question_source_id}`}
          >
            Configure
          </Button>
        </ButtonGroup>
      </Td>
    </Tr>
  );
};

const QuestionSourcesPage = withSuspense(() => {
  const [connectors, connections] = useQueriesData({
    queries: [
      {queryKey: ["vendorToolkit", "questionSourceConnectors"]},
      {queryKey: ["vendorToolkit", "questionSources"]},
    ],
  });
  return (
    <>
      <ActionBar>
        <NewQuestionSourceButton connectors={connectors} />
      </ActionBar>
      <Box h="full">
        <QuestionSourcesList questionSources={connections} />
      </Box>
    </>
  );
});

export default QuestionSourcesPage;
