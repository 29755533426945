import React, {useCallback} from "react";
import {useSearchParams} from "react-router-dom";

/**
 * Like `useState`, but syncs the state with a URL search param with the given
 * key.
 */
export function useSearchParam(key: string): [string | null, React.Dispatch<React.SetStateAction<string | null>>] {
  const [searchParams, setSearchParams] = useSearchParams();

  const value = searchParams.get(key);

  const setValue = useCallback(
    (valueOrUpdater: React.SetStateAction<string | null>) => {
      const newValue = typeof valueOrUpdater === "function" ? valueOrUpdater(value) : valueOrUpdater;

      setSearchParams(
        s => {
          if (newValue === null || newValue === "") {
            s.delete(key);
          } else {
            s.set(key, newValue);
          }

          return s;
        },
        {replace: true},
      );
    },
    [key, setSearchParams, value],
  );

  return [value, setValue];
}
