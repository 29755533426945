import api from "../../../api";
import {LibrarySectionId} from "../../../Types";
import {param, typedQuery} from "../../typing";

export default [
  typedQuery(["vendorToolkit", "librarySections"], async () => {
    return await api.vendorToolkit.librarySections.list();
  }),
  typedQuery(["vendorToolkit", "librarySection", param<LibrarySectionId>("id")], async context => {
    return await api.vendorToolkit.librarySections.get(context.queryKey[2]);
  }),
];
