import {Button, Image} from "@chakra-ui/react";
import {useGenericOauth} from "../../../hooks/genericOauth.ts";
import {SLACK_NOTIFICATION_OAUTH_OPTIONS} from "../../../Products/VendorToolkit/Configuration/NotificationChannels/index.tsx";
import {usePromiseState} from "../../../hooks/promiseState.ts";
import api from "../../../api";
import {Channel} from "./LinkedChannels.tsx";

export const LinkSlackButton = ({channel}: {channel: Channel}) => {
  const [_requestingAuth, requestAuth] = useGenericOauth();
  const [linking, link] = usePromiseState(async () => {
    // Forces the user to only auth against the Slack workspace that this channel is configured for
    const opts = {
      ...SLACK_NOTIFICATION_OAUTH_OPTIONS,
      extra: {
        team: channel.config.content.team_id,
      },
    };
    const auth = await requestAuth(opts);
    if (!auth.ok) {
      return;
    }

    await api.notificationChannels.linkChannel({
      notification_channel_id: channel.notification_channel_id,
      external_user_id: auth.result.metadata.external_user_id,
    });
  }, [requestAuth, channel]);
  return (
    <Button
      py={1}
      variant="ghost"
      borderWidth="1px"
      borderColor="grey.700"
      leftIcon={<Image boxSize={5} src={channel.connector.logo_url} />}
      onClick={link}
      isLoading={linking.inProgress}
      isDisabled={linking.inProgress}
    >
      Link to Slack
    </Button>
  );
};
