import {ActionBar} from "../../../../components/ActionBar.tsx";
import {withSuspense} from "../../../../state/withSuspense.tsx";
import {NewESignatureProviderButton} from "./NewESignatureProvider.tsx";
import {useQueriesData} from "../../../../state/index.ts";
import {Box, Button, ButtonGroup, Flex, Icon, Img, Table, Tbody, Td, Th, Thead, Tr} from "@chakra-ui/react";
import groupBy from "../../../../utils/groupBy.tsx";
import {EsignatureProviderMin} from "../../../../Types.ts";
import {AdjustmentsHorizontalIcon, ArrowPathIcon} from "@heroicons/react/20/solid";
import {Link as RouterLink} from "react-router-dom";
import api from "../../../../api/index.ts";
import {usePromiseState} from "../../../../hooks/promiseState.ts";
import {relativeDateView} from "../../../../utils/time.ts";
import {useTime} from "../../../../hooks/time.ts";

const EsignatureProviderCard = ({
  provider: {esignature_provider_id, name, connector, last_sync_at},
}: {
  provider: EsignatureProviderMin;
}) => {
  const [syncingNow, syncNow] = usePromiseState(async () => {
    if (esignature_provider_id) {
      await api.vendorToolkit.esignatureProviders.sync(esignature_provider_id);
    }
  }, [esignature_provider_id]);

  const lastSyncAt = useTime(relativeDateView(last_sync_at), [last_sync_at]);
  const lastSyncAtMessage = lastSyncAt ? `${lastSyncAt}` : "Not synced";

  return (
    <Tr>
      <Td w="20%" h="20">
        <Flex align="center" fontSize="md">
          <Img src={connector.logo_url} h={4} aspectRatio={1} mr={2} />
          {connector.name}
        </Flex>
      </Td>
      <Td w="20%" fontSize="md" fontWeight="500" color="gray.700">
        {name}
      </Td>
      <Td fontSize="md">{lastSyncAtMessage}</Td>
      <Td>
        <ButtonGroup isAttached variant="outline" shadow="sm">
          <Button
            as={RouterLink}
            leftIcon={<Icon as={AdjustmentsHorizontalIcon} />}
            to={`/vendor-toolkit/configuration/integrations/esignature-providers/configure/${esignature_provider_id}`}
          >
            Configure
          </Button>
          <Button
            leftIcon={<Icon as={ArrowPathIcon} />}
            onClick={syncNow}
            isDisabled={syncingNow.inProgress}
            isLoading={syncingNow.inProgress}
          >
            Resync
          </Button>
        </ButtonGroup>
      </Td>
    </Tr>
  );
};

const EsignatureProvidersList = ({providers}: {providers: EsignatureProviderMin[]}) => {
  return (
    <Table>
      <Thead>
        <Tr>
          <Th>Provider</Th>
          <Th>Name</Th>
          <Th w="15%">Last synced</Th>
          <Th>Actions</Th>
        </Tr>
      </Thead>
      {groupBy(providers, provider => provider.connector.connector_type).map(g => (
        <Tbody key={g.key}>
          {g.items.map(provider => (
            <EsignatureProviderCard provider={provider} key={provider.esignature_provider_id} />
          ))}
        </Tbody>
      ))}
    </Table>
  );
};

const ESignatureProvidersPage = withSuspense(() => {
  const [connectors, providers] = useQueriesData({
    queries: [
      {queryKey: ["vendorToolkit", "esignatureProviderConnectors"]},
      {queryKey: ["vendorToolkit", "esignatureProviders"]},
    ],
  });

  return (
    <>
      <ActionBar>
        <NewESignatureProviderButton connectors={connectors} />
      </ActionBar>
      <Box h="full">
        <EsignatureProvidersList providers={providers} />
      </Box>
    </>
  );
});

export default ESignatureProvidersPage;
