import {withSuspense} from "../../../../state/withSuspense.tsx";
import {useQueriesData} from "../../../../state/index.ts";
import {
  Heading,
  Flex,
  Text,
  Box,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  LinkBox,
  Td,
  LinkOverlay,
  Icon,
} from "@chakra-ui/react";
import Page from "../../../../components/Page.tsx";
import {
  TrustCenterAccess,
  DocumentMin,
  QuestionnaireMin,
  DocumentCategory,
  GraphEntity,
  GetGraphQuery,
} from "../../../../Types.ts";
import {Link as RouterLink} from "react-router-dom";
import {getFirstRootEntity} from "../../graph/util.ts";
import {useMemo} from "react";
import {TrustCenterReadabilityComp} from "../../components/TrustCenterAccess/Selector.tsx";
import {ChevronRightIcon} from "@heroicons/react/24/solid";
import {Subprocessor, getSubprocessorsFromEntityGraph} from "../../graph/types/subprocessor.ts";

type LibraryRowType =
  | "Policy"
  | "Certification"
  | "Other document"
  | "Report"
  | "Questionnaire"
  | "Historical questionnaire"
  | "Legal entity"
  | "Subprocessor";

type LibraryRow = {
  key: string;
  type: LibraryRowType;
  name: string;
  url: string;
  readability?: TrustCenterAccess;
};

function Row({row}: {row: LibraryRow}) {
  return (
    <LinkBox as={Tr} transform="scale(1)" _hover={{bg: "gray.50", cursor: "pointer"}}>
      <Td fontSize="md" fontWeight="600">
        <LinkOverlay as={RouterLink} to={row.url} />
        {row.type}
      </Td>
      <Td>{row.name}</Td>
      <Td>{row.readability ? <TrustCenterReadabilityComp access={row.readability} /> : "-"}</Td>
      <Td>
        <Icon as={ChevronRightIcon} fontSize="24" color="gray.500" />
      </Td>
    </LinkBox>
  );
}

function documentToRow(doc: DocumentMin): LibraryRow {
  let url;
  let type: LibraryRowType;
  switch (doc.category) {
    case DocumentCategory.Policy:
      type = "Policy";
      url = `/vendor-toolkit/library/policies/${doc.document_id}/meta`;
      break;
    case DocumentCategory.Certification:
      type = "Certification";
      url = `/vendor-toolkit/library/certifications/${doc.document_id}/meta`;
      break;
    case DocumentCategory.Report:
      type = "Report";
      url = `/vendor-toolkit/library/reports/${doc.document_id}/meta`;
      break;
    case DocumentCategory.Other:
      type = "Other document";
      url = `/vendor-toolkit/library/other-documents/${doc.document_id}/meta`;
      break;
  }

  return {
    key: doc.document_id,
    type,
    name: doc.name,
    url,
    readability: doc.trust_center_readability,
  };
}

function questionnaireToRow(q: QuestionnaireMin): LibraryRow {
  return {
    key: q.questionnaire_id,
    type: q.archived_at ? "Historical questionnaire" : "Questionnaire",
    name: q.name,
    url: `/vendor-toolkit/questionnaires/${q.questionnaire_id}`,
  };
}

function legalEntityToRow(l: GraphEntity): LibraryRow {
  return {
    key: l.id,
    type: "Legal entity",
    name: l.properties.name ?? "Unnamed",
    url: `/vendor-toolkit/library/company`,
  };
}

function subprocessorToRow(s: Subprocessor): LibraryRow {
  return {
    key: s.id,
    type: "Subprocessor",
    name: s.name ?? "Unnamed",
    url: `/vendor-toolkit/library/subprocessors`,
  };
}

type LibraryTable = {
  title: string;
  rows: LibraryRow[];
};

function SubTable({title, rows}: LibraryTable) {
  return (
    <Box>
      <Heading size="md" px={8} py={4}>
        {title}
      </Heading>
      <TableContainer maxWidth="100%" whiteSpace="normal" flex="1">
        <Table
          style={{borderCollapse: "separate", borderSpacing: "0"}}
          size="lg"
          layout="fixed"
          borderTop="1px solid"
          borderColor="gray.100"
        >
          <Thead bg="white">
            <Tr>
              <Th width="15%" color="gray.500">
                Type
              </Th>
              <Th width="13%" color="gray.500">
                Name
              </Th>
              <Th width="16%" color="gray.500">
                Access rules
              </Th>
              <Th width="6%" color="gray.500" />
            </Tr>
          </Thead>
          <Tbody>
            {rows.map(row => (
              <Row key={row.key} row={row} />
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );
}

const SELECT_PROPERTIES = ["legal_entity_type", "name", "subprocessor"];
const FILTER_PROPERTIES = ["is_root_legal_entity"];
const GRAPH_QUERY = {
  filter_properties: FILTER_PROPERTIES,
  selected_properties: SELECT_PROPERTIES,
} satisfies GetGraphQuery;

const LibraryPage = withSuspense(() => {
  const [documents, questionnaires, historicalQuestionnaires, graph] = useQueriesData({
    queries: [
      {queryKey: ["vendorToolkit", "documents"]},
      {queryKey: ["vendorToolkit", "questionnaires", {historical: false}]},
      {queryKey: ["vendorToolkit", "questionnaires", {historical: true}]},
      {queryKey: ["vendorToolkit", "graph", GRAPH_QUERY]},
    ],
  });
  const legalEntity = getFirstRootEntity(graph);
  const subprocessors = useMemo(
    () => (legalEntity ? getSubprocessorsFromEntityGraph(graph, legalEntity.id) : []),
    [graph, legalEntity],
  );

  const tables = useMemo(() => {
    return [
      {
        title: "Documents",
        rows: documents.filter(doc => doc.trust_center_visibility !== TrustCenterAccess.Denied).map(documentToRow),
      },
      {
        title: "Questionnaires",
        rows: questionnaires
          .concat(historicalQuestionnaires)
          .filter(q => q.trust_center_visibility !== TrustCenterAccess.Denied)
          .map(questionnaireToRow),
      },
      {title: "Legal entities", rows: legalEntity ? [legalEntityToRow(legalEntity)] : []},
      {title: "Subprocessors", rows: subprocessors.map(subprocessorToRow)},
    ] satisfies LibraryTable[];
  }, [documents, questionnaires, historicalQuestionnaires, legalEntity, subprocessors]);

  return (
    <Page title="Library">
      <Flex direction="column" flex={1} gap={8} py={8}>
        <Box px={8}>
          <Heading flex="1" size="lg">
            Library
          </Heading>
          <Text color="gray.500">The following items from your library are visible in your trust center</Text>
        </Box>
        {tables
          .filter(table => table.rows.length > 0)
          .map(table => (
            <SubTable key={table.title} {...table} />
          ))}
      </Flex>
    </Page>
  );
});

export default LibraryPage;
