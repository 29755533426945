import {Text} from "@chakra-ui/react";
import {Mention, RichText, RichTextAttributes, RichTextElement} from "../../Types";

const TextElement = ({text, attributes}: {text: string; attributes?: RichTextAttributes}) => {
  return (
    <Text
      as="span"
      fontWeight={attributes?.bold ? "600" : undefined}
      fontStyle={attributes?.italic ? "italic" : undefined}
      whiteSpace="pre-wrap"
    >
      {text}
    </Text>
  );
};

const MentionElement = ({mention}: {mention: Mention}) => {
  return (
    <Text as="span" fontWeight="600">
      @{mention.content?.name ?? "Deleted user"}
    </Text>
  );
};

const RichTextElementView = ({elem, mentions}: {elem: RichTextElement; mentions: Mention[]}) => {
  switch (elem.type) {
    case "Text":
      return <TextElement text={elem.content.text} attributes={elem.content.attributes} />;
    case "Mention":
      return <MentionElement mention={mentions[elem.content.index]} />;
  }
};

export const RichTextView = ({message, mentions}: {message: RichText; mentions: Mention[]}) => {
  return (
    <>
      {message.elements.map((elem, key) => (
        <RichTextElementView key={key} elem={elem} mentions={mentions} />
      ))}
    </>
  );
};
