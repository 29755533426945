import {useDisclosure} from "@chakra-ui/react";
import {usePromiseState} from "../../../../../hooks/promiseState";
import {DeleteModalIconButton} from "../../../../../components/DeleteModalButton";
import api from "../../../../../api";
import {GraphOperationsBuilder} from "../../../graph/operationsBuilder";
import {Person} from "../../../graph/types/person";

export const DeletePersonButton = ({person}: {person: Person}) => {
  const {isOpen, onOpen, onClose} = useDisclosure();

  const [deletingPerson, deletePerson] = usePromiseState(async () => {
    const operations = new GraphOperationsBuilder().deleteEntity(person.id, {recursive: true}).build();

    await api.vendorToolkit.graph.update(operations);

    if (person.photoAssetId !== undefined) {
      try {
        await api.vendorToolkit.assets.deleteAsset(person.photoAssetId);
      } catch (err: unknown) {
        // Do nothing
      }
    }

    onClose();
  }, [person, onClose]);

  return (
    <DeleteModalIconButton
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      delete_={deletePerson}
      deleting={deletingPerson.inProgress}
      modalHeader={`Delete ${person.name}`}
      modalBody={`Are you sure you want to delete ${person.name}?`}
    />
  );
};
