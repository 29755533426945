import {Subscriber} from "../../../../Types.ts";
import {Box, Flex, Heading, Tag, Text} from "@chakra-ui/react";

const SubscribedTag = ({subscribed}: {subscribed: boolean}) => (
  <Tag colorScheme={subscribed ? "green" : "gray"}>{subscribed ? "Subscribed" : "Not subscribed"}</Tag>
);

const VerifiedTag = ({verified}: {verified: boolean}) => (
  <Tag colorScheme={verified ? "green" : "yellow"}>{verified ? "Verified" : "Unverified"}</Tag>
);

const SubscriberRow = ({subscriber: {subscribed_at, metadata, verified_at, email}}: {subscriber: Subscriber}) => {
  return (
    <Flex align="center" px="6" py="5" fontSize="md" gap={6}>
      <Flex w="25%" align="center" gap={6}>
        <Heading size="sm" noOfLines={1}>
          {email}
        </Heading>
      </Flex>
      <Box flex={2} color="gray.600" whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
        {metadata.name}
      </Box>
      <Box flex={2} color="gray.600" whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
        {metadata.company}
      </Box>
      <Box flex={1} color="gray.600" whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
        <VerifiedTag verified={!!verified_at} />
      </Box>
      <Flex align="center" gap={2}>
        <SubscribedTag subscribed={!!verified_at && !!subscribed_at} />
      </Flex>
    </Flex>
  );
};

const SubscriberList = ({subscribers}: {subscribers: Subscriber[]}) => {
  return (
    <Box as="nav" minH="0" flex="1">
      {subscribers.length === 0 && (
        <Text align={"center"} mt={4}>
          You don't have any subscribers yet
        </Text>
      )}
      {subscribers.map(subscriber => (
        <SubscriberRow key={subscriber.mail_subscription_id} subscriber={subscriber} />
      ))}
    </Box>
  );
};

export default SubscriberList;
