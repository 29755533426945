import {AccountFeature, Questionnaire} from "../../../../../Types.ts";

import {
  Box,
  Button,
  ButtonProps,
  Flex,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuItemProps,
  useDisclosure,
} from "@chakra-ui/react";
import {
  ChatBubbleBottomCenterTextIcon,
  ChevronDownIcon,
  DocumentIcon,
  PlusIcon,
  CloudArrowDownIcon,
} from "@heroicons/react/20/solid";

import ImportCsvModal from "./ImportCsv/Modal.tsx";
import AddQuestionModal from "./AddQuestionModal.tsx";
import {useQueryData} from "../../../../../state/index.ts";
import ImportPortalModal from "./ImportPortalModal.tsx";
import UploadQuestionnaireModal from "./UploadQuestionnaireModal.tsx";
import RequireAccountFeature from "../../../../../components/RequireAccountFeature.tsx";
import PortalMenuList from "../../../../../components/PortalMenuList.tsx";

const StyledMenuItem = ({
  icon,
  children,
  description,
  ...props
}: {icon: React.FC; description: string} & Omit<MenuItemProps, "icon">) => (
  <MenuItem {...props}>
    <Flex py="1">
      <Icon as={icon} h="6" mr="2" color="gray.400" />
      <Box>
        <Box fontWeight={500}>{children}</Box>
        <Box fontWeight={400} fontSize="sm" color="gray.500" pr="4" maxW={220}>
          {description}
        </Box>
      </Box>
    </Flex>
  </MenuItem>
);

const AddQuestion = ({
  questionnaire,
  onCreate,
  ...props
}: {
  questionnaire: Questionnaire;
  onCreate?: (questionIds: string[]) => void;
} & ButtonProps) => {
  const {user} = useQueryData({queryKey: ["whoAmI"]});
  const {isOpen: textIsOpen, onOpen: textOnOpen, onClose: textOnClose} = useDisclosure();
  const {isOpen: fileIsOpen, onOpen: fileOnOpen, onClose: fileOnClose} = useDisclosure();
  const {isOpen: portalIsOpen, onOpen: portalOnOpen, onClose: portalOnClose} = useDisclosure();
  const {isOpen: csvIsOpen, onOpen: csvOnOpen, onClose: csvOnClose} = useDisclosure();

  return (
    <>
      <Menu placement="bottom-end" isLazy>
        <MenuButton
          as={Button}
          rightIcon={<Icon as={ChevronDownIcon} />}
          colorScheme="green"
          leftIcon={<Icon as={PlusIcon} />}
          {...props}
        >
          Add questions
        </MenuButton>
        <PortalMenuList fontSize="md">
          <StyledMenuItem icon={DocumentIcon} description="Import your questions from a file" onClick={fileOnOpen}>
            From file
          </StyledMenuItem>
          <StyledMenuItem
            icon={CloudArrowDownIcon}
            description="Import your questions from a web portal"
            onClick={portalOnOpen}
          >
            From portal
          </StyledMenuItem>
          <StyledMenuItem
            icon={ChatBubbleBottomCenterTextIcon}
            description="Add a single question"
            onClick={textOnOpen}
          >
            From text
          </StyledMenuItem>
          <RequireAccountFeature feature={AccountFeature.CsvImport} showInternally>
            <StyledMenuItem
              icon={DocumentIcon}
              description="Import questions from a Platformed-compatible CSV"
              onClick={csvOnOpen}
            >
              From CSV
            </StyledMenuItem>
          </RequireAccountFeature>
        </PortalMenuList>
      </Menu>

      <ImportCsvModal questionnaire={questionnaire} isOpen={csvIsOpen} onClose={csvOnClose} onCreate={onCreate} />
      <AddQuestionModal questionnaire={questionnaire} isOpen={textIsOpen} onClose={textOnClose} onCreate={onCreate} />
      <ImportPortalModal
        isOpen={portalIsOpen}
        onClose={portalOnClose}
        user={user}
        questionnaireId={questionnaire.questionnaire_id}
      />
      <UploadQuestionnaireModal
        isOpen={fileIsOpen}
        onClose={fileOnClose}
        user={user}
        questionnaireId={questionnaire.questionnaire_id}
      />
    </>
  );
};

export default AddQuestion;
