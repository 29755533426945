import {Box, Flex, FlexProps, Stack, StackProps, Text} from "@chakra-ui/react";

export const FormQuestion = ({
  title,
  description,
  children,
  ...props
}: {
  title: string;
  description?: string;
  children: React.ReactNode;
} & StackProps) => (
  <Stack spacing="1" {...props}>
    <Text fontSize="md" fontWeight="600">
      {title}
    </Text>
    {children}
    {description && (
      <Text fontSize="sm" color="gray.500">
        {description}
      </Text>
    )}
  </Stack>
);

const FormSection = ({
  title,
  description,
  children,
  ...props
}: {
  title: string;
  description: string;
  children: React.ReactNode;
} & FlexProps) => (
  <Flex {...props}>
    <Box flex="1" flexShrink={0} p="4" mr="8" maxW="300px">
      <Text fontWeight="600" fontSize="md" color="gray.800" mb="1">
        {title}
      </Text>
      <Text fontSize="md" color="gray.500">
        {description}
      </Text>
    </Box>
    <Stack flex="2" overflowX="hidden" p="4" spacing="12" w="full">
      {children}
    </Stack>
  </Flex>
);

export default FormSection;
