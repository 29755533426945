// Implements a react-compatible API around the OneDrive/SharePoint file picker tool

import {useCallback, useContext, useEffect, useRef} from "react";
import {useEventListener} from "usehooks-ts";
import {MsalContext} from "../oauth/ms";
import {UseQueryResult, useQuery} from "@tanstack/react-query";
import type {AccountInfo, IPublicClientApplication} from "@azure/msal-browser";

// Where we want to choose files from.
// If `account` is not specified, user will be asked to choose an account to use.
export type MicrosoftFileSource =
  | {
      type: "OneDrive";
      account?: AccountInfo;
    }
  | {
      type: "SharePoint";
      account?: AccountInfo;
      url: string;
    };

// A SharePoint domain (but there might be multiple sites on the same domain)
// It's unclear if a single tenant can have multiple domains, but the code assumes that is possible.
export type SharePointSiteCollection = {
  name: string;
  url: string;
};

// Configuration for the file picker
export type MicrosoftFilePickerOptions = {
  search?: {
    enabled: boolean;
  };
  typesAndSources?: {
    mode?: "files" | "folders" | "all";
    filters?: string[];
    pivots?: {
      recent?: boolean;
      oneDrive?: boolean;
      sharedLibraries?: boolean;
    };
  };
  selection?: {
    mode?: "single" | "multiple" | "pick";
  };
  commands?: {
    pick?: {
      label?: string;
    };
    close?: {label?: string};
  };
  accessibility?: {
    enableFocusTrap?: boolean;
    trapFocusOnLoad?: boolean;
    showFocusOnLoad?: boolean;
  };
  tray?: {
    prompt?: "selection-summary" | "save-as";
    saveAs?: {
      fileName?: string;
    };
  };
};

// A single item returned from the file picker
export type MicrosoftFilePickerItem = {
  name: string;
  webUrl: string;
  size: number;
  id: string;
  parentReference: {
    driveId: string;
  };
};

// The overall "pick" result
export type MicrosoftFilePickerResult = {
  account: AccountInfo;
  items: MicrosoftFilePickerItem[];
  authority: string;
};

// Internal file picker state
type PickerState = {
  // MSAL app reference for auth
  app: IPublicClientApplication;
  // The picker browser window
  wnd: WindowProxy;
  // Where we're picking from
  source: MicrosoftFileSource;
  // Picker promise callbacks
  resolve: (picked: MicrosoftFilePickerResult) => void;
  reject: () => void;
};

// Generate the authority URL for a specific tenant
function authority(tenantId: string): string {
  return `https://login.microsoftonline.com/${tenantId}`;
}

// The special "consumers" tenant allows any personal account to be used
const CONSUMERS_AUTHORITY = authority("consumers");
// The special "organizations" tenant allows any organization account to be used
const ORGANIZATIONS_AUTHORITY = authority("organizations");

// Helper function for getting an access token using the OAuth SPA client-type via MSAL
async function getToken(
  app: IPublicClientApplication,
  authority: string,
  scopes: string[],
  account?: AccountInfo,
): Promise<[AccountInfo, string]> {
  // If the caller already specified an account, try to get an access token without
  // prompting the user.
  if (account) {
    try {
      const resp = await app.acquireTokenSilent({scopes, account, authority});
      return [resp.account, resp.accessToken];
    } catch (e) {
      // Could not get an access token silently, fall back to popup
    }
  }
  // Get an access token via an authorization popup
  const resp = await app.acquireTokenPopup({
    scopes,
    account,
    authority,
    // If no account was specified by the caller, make sure the user is able
    // to pick any account they want rather than defaulting to the most
    // recently used one...
    prompt: account ? undefined : "select_account",
  });

  // Return the access token and information on the account it is for
  return [resp.account, resp.accessToken];
}

// Get a list of SharePoint domains accessible to the user
export function useSharePointSiteCollections(): UseQueryResult<{
  account: AccountInfo;
  siteCollections: SharePointSiteCollection[];
}> {
  const app = useContext(MsalContext)!;
  return useQuery({
    queryKey: ["transient", "sharePointSites"],
    queryFn: async () => {
      // Allow any organization account to be used for SharePoint. Pre-emptively ask for file read permission to
      // try to avoid the need for further consent popups.
      const [account, accessToken] = await getToken(app, ORGANIZATIONS_AUTHORITY, ["Sites.Read.All", "Files.Read.All"]);

      // Search for sites using a wildcard. This endpoint works via delegated permissions whereas the "List Sites" endpoint
      // only works for application-level permissions, which we don't have.
      const sitesResp = await fetch("https://graph.microsoft.com/v1.0/sites?search=*", {
        headers: {Authorization: `Bearer ${accessToken}`},
      });
      const sites = await sitesResp.json();

      return {
        account,
        // Only return sites which are top-level SharePoint domains. The file picker component only exists at the "top level"
        // so we'd get a 404 if we tried to show the file picker for a nested site.
        siteCollections: sites.value
          .filter((site: any) => site.webUrl.endsWith(".sharepoint.com"))
          .map((site: any) => ({name: site.displayName, url: site.webUrl})),
      };
    },
    // Refetching will re-prompt the user to pick an account, which we don't want
    refetchOnWindowFocus: false,
  });
}

// Get the user to pick an account
export function useOneDriveAccount(): UseQueryResult<{
  account: AccountInfo;
}> {
  const app = useContext(MsalContext)!;
  return useQuery({
    queryKey: ["transient", "oneDriveAccount"],
    queryFn: async () => {
      // Allow any organization account to be used for SharePoint. Pre-emptively ask for file read permission to
      // try to avoid the need for further consent popups.
      const [account, _accessToken] = await getToken(app, CONSUMERS_AUTHORITY, ["OneDrive.ReadOnly"]);

      return {
        account,
      };
    },
    // Refetching will re-prompt the user to pick an account, which we don't want
    refetchOnWindowFocus: false,
  });
}

// Get the URL of the file picker for a particular source.
function pickerUrl(source: MicrosoftFileSource): string {
  switch (source.type) {
    case "OneDrive":
      // This is the full URL for OneDrive, the documentation lies.
      return "https://onedrive.live.com/picker";
    case "SharePoint":
      return `${source.url}/_layouts/15/FilePicker.aspx`;
  }
}

// Get the scopes we need to request in order to first show the file picker.
function pickerInitialScopes(source: MicrosoftFileSource): string[] {
  switch (source.type) {
    case "OneDrive":
      return ["OneDrive.ReadOnly"];
    case "SharePoint":
      return ["Sites.Read.All", "Files.Read.All"];
  }
}

// Get the scopes we need to request in response to a follow-up "authenticate" command from the file picker.
// The file picker tells us which "resource" we need to request access to.
function pickerAuthenticateScopes(source: MicrosoftFileSource, resource: string): string[] {
  switch (source.type) {
    case "OneDrive":
      return ["OneDrive.ReadOnly"];
    case "SharePoint":
      return [`${resource}/.default`];
  }
}

// Determine which authority we need to use for a particular source.
function pickerAuthority(source: MicrosoftFileSource): string {
  switch (source.type) {
    case "OneDrive":
      // Documentation claims that the "common" authority can be used to allow personal or organization
      // accounts to log in, but the "common" authority rejects OneDrive-specific scopes, so we need
      // to explicitly use the "consumers" authority!
      return CONSUMERS_AUTHORITY;
    case "SharePoint":
      // If the user already selected an account, we should use the authority for the specific
      // tenant containing that account. Otherwise we can use the generic "organizations" authority.
      return source.account ? authority(source.account.tenantId) : ORGANIZATIONS_AUTHORITY;
  }
}

// Command received from the file picker
type PickerEventPayload = {
  type: "command";
  id: string;
  data: PickerCommand;
};

type PickerCommand =
  | {
      command: "authenticate";
      resource: string;
    }
  | {
      command: "close";
    }
  | {
      command: "pick";
      items: MicrosoftFilePickerItem[];
    };

// Handle a message from the file picker
async function handleMessage(state: PickerState, port: MessagePort, payload: PickerEventPayload) {
  switch (payload.type) {
    case "command":
      // All commands must be acknowledged
      port.postMessage({
        type: "acknowledge",
        id: payload.id,
      });

      switch (payload.data.command) {
        // The file picker needs an access token for a specific resource
        case "authenticate":
          {
            const scopes = pickerAuthenticateScopes(state.source, payload.data.resource);
            const [_account, token] = await getToken(
              state.app,
              pickerAuthority(state.source),
              scopes,
              state.source.account,
            );

            port.postMessage({
              type: "result",
              id: payload.id,
              data: {
                result: "token",
                token,
              },
            });
          }
          break;
        // The user clicked the "close" button
        case "close":
          state.wnd.close();
          state.reject();
          break;
        // The user picked a file
        case "pick":
          port.postMessage({
            type: "result",
            id: payload.id,
            data: {
              result: "success",
            },
          });
          state.wnd.close();
          state.resolve({
            account: state.source.account!,
            items: payload.data.items,
            authority: pickerAuthority(state.source),
          });
          break;
      }
      break;
  }
}

// React hook to use the file picker
export function useMicrosoftFilePicker() {
  const app = useContext(MsalContext)!;
  const pickerState = useRef<PickerState | null>(null);

  // We need to listen to messages on the global window object from the picker
  useEventListener("message", e => {
    // Check if we got an "initialize" message from the picker
    if (pickerState.current && e.source && e.source === pickerState.current.wnd && e.data.type === "initialize") {
      const state = pickerState.current;
      // The "initialize" message contains a Port over which future communication will occur
      const port = e.ports[0];
      port.addEventListener("message", async (e: MessageEvent) => {
        await handleMessage(state, port, e.data);
      });
      port.start();
      // Tell the file picker to activate
      port.postMessage({
        type: "activate",
      });
    }
  });

  // Return a callback that can be used to open the file picker
  const open = useCallback(
    async (source: MicrosoftFileSource, options: MicrosoftFilePickerOptions): Promise<MicrosoftFilePickerResult> => {
      // Create a blank window of the recommended dimensions
      // Do this first so that the browser sees this as a direct response to a user action and
      // doesn't try to block our popup.
      const wnd = window.open("", undefined, "width=1080,height=680");
      if (!wnd) {
        // User needs to enable popups for our site
        throw new Error("Popup blocked");
      }

      // We need an access token in order to open the file picker
      const [account, accessToken] = await getToken(
        app,
        pickerAuthority(source),
        pickerInitialScopes(source),
        source.account,
      );

      // Create a promise which will be resolved when the user picks a file or cancels
      const promise = new Promise<MicrosoftFilePickerResult>((resolve, reject) => {
        // Initialize the internal picker state
        pickerState.current = {
          app,
          wnd,
          source: {...source, account},
          resolve,
          reject,
        };
      });

      // Construct the query string using the configuration we want to provide to
      // the file picker.
      const queryString = new URLSearchParams({
        filePicker: JSON.stringify({
          // These options are controlled by us
          sdk: "8.0",
          entry:
            source.type === "OneDrive"
              ? {
                  oneDrive: {
                    files: {},
                  },
                }
              : {
                  sharePoint: {},
                },
          authentication: {},
          messaging: {
            origin: window.location.origin,
            channelId: window.crypto.randomUUID(),
          },
          // These are the options that can be customized by the caller
          ...options,
        }),
      });

      // The window URL we want to load is the picker URL combined with the query string
      const url = `${pickerUrl(source)}?${queryString}`;

      // The access token needs to be sent via POST data, so we create a form in the empty
      // window and then immediately submit it...
      const form = wnd.document.createElement("form");
      form.setAttribute("action", url);
      form.setAttribute("method", "POST");

      const tokenInput = wnd.document.createElement("input");
      tokenInput.setAttribute("type", "hidden");
      tokenInput.setAttribute("name", "access_token");
      tokenInput.setAttribute("value", accessToken);
      form.appendChild(tokenInput);
      wnd.document.body.append(form);
      form.submit();

      // Wait for the promise to resolve
      return await promise;
    },
    [app],
  );

  // If the component is unmounted, close the file picker window
  useEffect(() => () => {
    if (pickerState.current?.wnd) {
      pickerState.current.wnd.close();
    }
  });

  return open;
}
